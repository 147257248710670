export default {
  install(Vue) {
    Vue.directive('fixZoom', {
      inserted: function (element, binding, vnode) {
        element.getBoundingClientRectBak = element.getBoundingClientRect;
        element.getBoundingClientRect = function () {
          const rect = element.getBoundingClientRectBak();
          var zoom = window.getComputedStyle(document.getElementById('homeIndex')).zoom;
          return new DOMRect(rect.x, rect.y * zoom)
        }
      }
    })
  }
}
