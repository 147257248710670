import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// --危化品种类--

// 获取危化品种类列表
export const getList = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/dmitem/list`,
    method: 'get',
    params
  })
}
// 新增或编辑危化品种类列表
export const addList = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/dmitem/submit`,
    method: 'post',
    data
  })
}
// 查询详情
export const listDetail = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/dmitem/detail`,
      method: 'get',
      params
    })
}
// 删除
export const removeList = (data) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/dmitem/remove?ids=${data.ids}`,
      method: 'post',
      data
    })
}
// // 导出
// export const exoprtList = (params) => {
//     return request({
//       url: `${ServerNameEnum.SERVER_FX}/dmstorage/export`,
//       method: 'get',
//       params
//     })
// }

// --危化品储位表--


// 获取列表
export const getcwList = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/dmwarehouse/pageList`,
      method: 'get',
      params
    })
  }
  // 新增或编辑列表
  export const addcwList = (data) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/dmwarehouse/submit`,
      method: 'post',
      data
    })
  }
  // alllist
  export const getcwAllList = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/dmwarehouse/list`,
      method: 'get',
      params
    })
  }

  // 查询详情
  export const listcwDetail = (params) => {
      return request({
        url: `${ServerNameEnum.SERVER_FX}/dmwarehouse/detail`,
        method: 'get',
        params
      })
  }
  // 删除
  export const removecwList = (data) => {
      return request({
        url: `${ServerNameEnum.SERVER_FX}/dmwarehouse/remove?ids=${data.ids}`,
        method: 'post',
        data
      })
  }

//   获取编码 /dmwarehouse/getCode
export const getcwCode = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/dmwarehouse/getCode`,
      method: 'get',
      params
    })
}

//   库存批次表(含供应商信息) 主页右下角的表格
export const getkcList = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/dmstorage/list`,
      method: 'get',
      params
    })
  }
  // 新增或编辑危化品种类列表
  export const addkcList = (data) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/dmstorage/submit`,
      method: 'post',
      data
    })
  }
  // 查询详情
  export const listkcDetail = (params) => {
      return request({
        url: `${ServerNameEnum.SERVER_FX}/dmstorage/detail`,
        method: 'get',
        params
      })
  }
  // 删除
  export const removekcList = (data) => {
      return request({
        url: `${ServerNameEnum.SERVER_FX}/dmstorage/remove?ids=${data.ids}`,
        method: 'post',
        data
      })
  }

  // 出入库记录表
  // 出入库列表
  export const getcgList = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/dmitemrecpt/page`,
      method: 'get',
      params
    })
  }
    // 采购新增获取编码
    export const getcgCode = (params) => {
      return request({
        url: `${ServerNameEnum.SERVER_FX}/dmitemrecpt/getCode`,
        method: 'get',
        params
      })
    }
    // 采购批次号
  export const getcgBatchCode = (params) => {
      return request({
        url: `${ServerNameEnum.SERVER_FX}/dmitemrecpt/getBatchCode`,
        method: 'get',
        params
      })
    }
  
  // 新增
  export const addcgList = (data) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/dmitemrecpt/submit`,
      method: 'post',
      data
    })
  }

  // 查询详情
  export const listcgDetail = (params) => {
      return request({
        url: `${ServerNameEnum.SERVER_FX}/dmitemrecpt/detail`,
        method: 'get',
        params
      })
  }
  // 删除
  export const removecgList = (data) => {
      return request({
        url: `${ServerNameEnum.SERVER_FX}/dmitemrecpt/remove?ids=${data.ids}`,
        method: 'post',
        data
      })
  }

  //处置表
 // 新增
 export const addczList = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/dmitemdisposal/submit`,
    method: 'post',
    data
  })
}
// code
export const getczCode = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/dmitemdisposal/getCode`,
    method: 'get',
    params
  })
}
// 查询详情+
export const listczDetail = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/dmitemdisposal/detail`,
      method: 'get',
      params
    })
}
// 归还表
 // 新增
 export const addghList = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/dmitemrt/submit`,
    method: 'post',
    data
  })
}
// 归还code
export const getghCode = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/dmitemrt/getCode`,
    method: 'get',
    params
  })
}

// 查询详情
export const listghDetail = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/dmitemrt/detail`,
      method: 'get',
      params
    })
}

// 领用表

 // 新增
 export const addlyList = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/dmitemissue/submit`,
    method: 'post',
    data
  })
}
// 领用code
export const getlyCode = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/dmitemissue/getCode`,
    method: 'get',
    params
  })
}
// 查询详情
export const listlyDetail = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/dmitemissue/detail`,
      method: 'get',
      params
    })
}
