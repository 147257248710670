import Layout from '@/page/index/'

export default [

  //安全环境

  {
    path: '/riskIdentification',
    component: Layout,
    name:'风险信息',
    children: [

      {
        path: '/business/safetyTtandard/riskIdentification/add',
        name: '风险信息',
        component: () => import('@/views/business/safetyTtandard/riskIdentification/form.vue'),
        meta: {
          title: '风险信息',
          icon: ''
        }
      },
    ]
  }
]
