import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";

export const add = (indexData, indexValueHeader) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/indexValue/submit",
    method: "post",
    data: indexData,
    params: {
      indexValueHeader: indexValueHeader
    }
  });
};

export const updateHeader = (updateHeader) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/indexValue/updateHeader",
    method: "post",
    data: updateHeader,
  });
};

export const updateHeaderById = (updateHeader) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/indexValue/updateHeaderById",
    method: "post",
    data: updateHeader,
  });
};
export const updateReportDept = (reportDept) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/indexValue/getReportDeptName?reportDept=" + reportDept,
    method: "get",

  });
};

export const updateHeaderByIdAndReportZGcxmzbtb = (updateHeader) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/indexValue/updateHeaderByIdAndReportZGcxmzbtb",
    method: "post",
    data: updateHeader,
  });
};

export const updateHeaderByIdAndReportZYdzjzctb = (updateHeader) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/indexValue/updateHeaderByIdAndReportZYdzjzctb",
    method: "post",
    data: updateHeader,
  });
};

export const updateHeaderByIdAndReportZGylzbtb = (updateHeader) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/indexValue/updateHeaderByIdAndReportZGylzbtb",
    method: "post",
    data: updateHeader,
  });
};

export const updateHeaderByIdAndReportZKyzbtb = (updateHeader) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/indexValue/updateHeaderByIdAndReportZKyzbtb",
    method: "post",
    data: updateHeader,
  });
};


export const initAdd = (indexData, indexValueHeader) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/indexValue/initSubmit",
    method: "post",
    data: indexData,
    params: {
      indexValueHeader: indexValueHeader
    }
  });
};

export const getIndexData = (indexData) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/indexSystemInfo/getIndexData",
    method: "post",
    data: indexData
  });
};

export const getHeader = (id, flag) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/indexValue/getHeader?id=" + id + '&flag=' + flag,
    method: "get"
  });
};

export const getHeaderByProcessInsId = (processInsId) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/indexValue/getHeaderByProcessInsId?processInsId=" + processInsId,
    method: "get"
  });
};

//提交206模板数据
export const submitModuleData206 = (params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/reportTableDetail/submit206",
    method: "post",
    data: params
  });
};
//获取206模板数据
export const getModuleData206 = (params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/reportTableDetail/detail206?id=${params.id}&userId=${params.userId}`,
    method: "get"
  });
};

//获取上海模板数据
export const getModuleDataShangHai = (params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/reportTableDetail/detail_shanghai?id=${params.id}&userId=${params.userId}`,
    method: "get"
  });
};
//提交模板数据
export const submitModuleData = (params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/reportTableDetail/submit",
    method: "post",
    data: params
  });
};
//获取模板数据
export const getModuleData = (params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/reportTableDetail/detail`,
    method: "post",
    data: params
  });
};
//获取模板提示
export const getTips = (params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/field-info/getFieldInfo?billType=${params.billType}&fieldKey=${params.fieldKey}`,
    method: "get",
  });
};
// export const getTips = (params) => {
//   return request({
//     url: ServerNameEnum.SERVER_REPORT_ + "/indexSystemInfo/detailByIndexCode",
//     method: "post",
//     data: params
//   });
// };


//获取Excel列表保存提示
export const excelSave = (params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/ExcelList/save",
    method: "post",
    data: params
  });
};

export const excelList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/ExcelList/list",
    method: "get",
    params: {
      ...params,
      current,
      size
    }
  });
}

export const getProjectList = (current,size,params)=>{
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/investmentSituation206/item-list",
    method: "post",
    params: {
      ...params,
      current,
      size
    }
  })
}

export const selectProjectItem = (params)=>{
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/investmentSituation206/selectProjectItem",
    method: "post",
    params: {
      ...params,
    }
  })
}
//206模版自动取数
export const automaticData = (params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + "/investmentSituation206/automaticData",
    method: "post",
    params: {
      ...params,
    }
  });
};


//修改模板停用启用
export const pammodelStatus = (params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/pamodel/updateStatus`,
    method: "post",
    data: params
  });
};

//通过查询单条模板数据
export const pammodelInfo = (id) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/pamodel/info?id=${id}`,
    method: "get",
  });
};

//新的模板查询详情
export const listTemplatePageData = (id) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_  + `/template/listTemplatePageData?modelId=${id}`,
    method: "get",
  });
};
