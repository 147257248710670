import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

/**
 * 数据库名称下拉
 * @param params
 * @returns {*}
 */
export const dbListAll = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/report-server/db/list-all',
    method: 'get',
    data:params
  })
}
export const getAiModelList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/db-table-column/get-ai-model-list',
    method: 'post',
    data:params
  })
}
//根据表查询所有字段
export const getColumn = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/db-table-column/get-db-table-column-list-datax-web',
    method: 'post',
    data:params
  })
}
//构建
export const dataXBuildJson = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/report-server/datax-json/build-json',
    method: 'post',
    data:params
  })
}
//todo 解析json
export const getColumnsByQuerySql = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/report-server/datax-json/get-columns-by-query-sql',
    method: 'post',
    data:params
  })
}
//todo 最后一步开始按钮接口
export const syncDb = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/report-server/datax-json/sync-db',
    method: 'post',
    data:params
  })
}
/**
 * 数据主题列表 树解构
 */

export const getDataDirectoryAllByPid = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/data-directory/get-data-directory-all-by-pid',
    method: 'post',
    data:params
  })
}

//保存离线采集信息

export const SaveOffline = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/offline_data_collect/save_offline_data_collect',
    method: 'post',
    data:params
  })
}

//离线采集信息分页
export const OfflineDataList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/offline_data_collect/page_offline_data_collect',
    method: 'post',
    data:params
  })
}

//离线采集信息编辑回显信息
export const relationEnum= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/offline_data_collect/get_offline_data_collect_by_id',
    method: 'get',
    params
  })
}

// 编辑保存离线采集信息
export const UpdateOffline = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/offline_data_collect/update_offline_data_collect',
    method: 'post',
    data:params
  })
}
//删除离线采集信息
export const DelOffline= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/offline_data_collect/delete_offline_data_collect',
    method: 'get',
    params
  })
}
// 暂停或者启动离线
export const stopOffline = (params) => {
  // return request({
  //   url: ServerNameEnum.SERVER_ZT_+'/offline_data_collect/firing_offline_data_collect',
  //   method: 'post',
  //   data:params
  // })

  return request({
    url: ServerNameEnum.SERVER_ZT_+'/offline_data_collect/firing_offline_data_collect',
    method: 'get',
    params
  })
}


//手动执行按钮离线采
export const zxOffline= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/offline_data_collect/execute_offline_data_collect',
    method: 'get',
    params
  })
}



// 手动执行按钮离线采2
export const zxOffline2 = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/offline_data_collect/execute_offline_data_collect_time',
    method: 'post',
    data:params
  })
}



//离线接口采集执行日志
export const getOfflinelog = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/offline_data_collect/page_offline_data_collect_exe_log',
    method: 'post',
    data:params
  })
}