var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-badge", { attrs: { hidden: _vm.isRead, value: _vm.value } }, [
        _c(
          "div",
          {
            staticClass: "suitem",
            on: {
              click: function ($event) {
                return _vm.handleClick(_vm.item)
              },
            },
          },
          [
            _c("img", {
              attrs: {
                height: "36",
                src: require("../../../../public/img/top/notice.png"),
                width: "36",
              },
            }),
            _c("span", [_vm._v(_vm._s(_vm.$t("navbar.notice")))]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }