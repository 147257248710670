import request from '@/router/axios';

export const getList = (params) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/hdrectify/page',
    method: 'get',
    params
  })
}
export const List = (params) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/hdrectify/history/list',
    method: 'get',
    params
  })
}
export const getPage = (params) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/hdhiddendanger/accept/page',
    method: 'get',
    params
  })
}
export const remove = (data) => { //删除
  return request({
    url: '/api/sinoma-hse-prj/hdrectify/remove',
    method: 'POST',
    data
  })
}
export const detail = (params) => { //详情
  return request({
    url: '/api/sinoma-hse-prj/hdrectify/detail',
    method: 'get',
    params
  })
}
export const save = (data) => { //保存
  return request({
    url: '/api/sinoma-hse-prj/hdrectify/save',
    method: 'post',
    data
  })
}
export const update = (data) => { //编辑
  return request({
    url: '/api/sinoma-hse-prj/hdrectify/update',
    method: 'post',
    data
  })
}

export const HD_SOURCE_TYPE = (key) => {
  return request({
    url: `/api/sinoma-system/dict-biz/dictionary?code=${key}`,
    method: 'get',
  })
}
export const hd_level = () => {
  return request({
    url: `/api/sinoma-system/dict-biz/dictionary?code=hd_level`,
    method: 'get',
  })
}
