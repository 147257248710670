import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

//  getList, //获取计划列表
// getListDetail, //获取计划详情
// removeList,// 删除计划
// submitList, // 新增计划表单
// getTreeList, // 检查项列表
// submitTreeList, // 检查项提交
// removeTreeList, // 检查项移除
// getTreeListDetail,// 检查项详情
// getTree, // 获取检查项分类
// submitTree, //新增修改检查项分类
// removeTree, //删除检查项分类
// getTreeDetail, // 检查项分类详情

// 计划列表相关接口
export const getList = (current,size,params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckplan/page',
    method: 'get',
    params:{
      current,
      size,
      ...params
    }
  })
}
export const getListDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckplan/detail',
    method: 'get',
    params
  })
}
export const removeList = (data) => {
    return request({
      url: ServerNameEnum.SERVER_FX + `/hdcheckplan/remove?ids=${data.ids}`,
      method: 'post',
      data
    })
}
export const startEndServe = (data) => {
    return request({
      url: ServerNameEnum.SERVER_FX + `/hdcheckplan/startOrStop?ids=${data.ids}&enableFlag=${data.enableFlag}`,
      method: 'post',
      data
    })
  }

// 新增弹窗里面列表相关接口

// 提交表单信息
export const submitList = (data) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/hdcheckplan/save',
      method: 'post',
      data
    })
  }
 
export const getCode = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/hdcheckplan/getCode',
      method: 'get',
      params
    })
}
// 检查项列表
export const getTreeList = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/hdcheckplanindex/page',
      method: 'get',
      params:{
        ...params
      }
    })
  }
// 检查项提交
  export const submitTreeList = (data) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/hdcheckplanindex/save',
      method: 'post',
      data
    })
  }
  // 检查项移除
  export const removeTreeList = (data) => {
    return request({
      url: ServerNameEnum.SERVER_FX + `/hdcheckplanindex/remove`,
      method: 'post',
      data
    })
}
// 检查项详情
export const getTreeListDetail = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/hdcheckplanindex/detail',
      method: 'get',
      params
    })
  }

  // 获取检查项分类 
  // getTreeNodes
  export const getTreeTypes = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/hdcheckplanindextype/typeTree',
      method: 'get',
      params
    })
  }
// 获取检查项分类(树结构)
  export const getTree = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/hdcheckplanindextype/tree',
      method: 'get',
      params
    })
  }
  // 新增检查项分类
  export const submitTree = (data) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/hdcheckplanindextype/save',
      method: 'post',
      data
    })
  }
  // 移除检查分类节点
  export const removeTree = (data) => {
    return request({
      url: ServerNameEnum.SERVER_FX + `/hdcheckplanindextype/remove?ids=${data.ids}`,
      method: 'post',
      data
    })
}
// 检查项分类详情
export const getTreeDetail = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/hdcheckplanindextype/detail',
      method: 'get',
      params
    })
  }

// 检查人员相关接口

export const submitCheckPersonList = (data) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/hdcheckplanperson/save',
      method: 'post',
      data
    })
  }

export const getCheckPersonList = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/hdcheckplanperson/page',
      method: 'get',
      params:{
        ...params
      }
    })
  }
  export const getCheckPersonListDetail = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/hdcheckplanperson/detail',
      method: 'get',
      params
    })
  }
  export const removeCheckPersonList = (data) => {
      return request({
        url: ServerNameEnum.SERVER_FX + `/hdcheckplanperson/remove`,
        method: 'post',
        data
      })
  }

// 检查资料相关接口

export const submitInformation = (data) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/hdcheckplanmaterial/save',
      method: 'post',
      data
    })
  }
  export const submitInformationList = (data) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/hdcheckplanmaterial/saveList',
      method: 'post',
      data
    })
  }

export const getInformationList = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/hdcheckplanmaterial/list',
      method: 'get',
      params:{
        ...params
      }
    })
  }
  export const getExportIndexData = (params)=>{
    return request({
      url: ServerNameEnum.SERVER_FX + `/hdcheckplanindex/export`,
      method: 'get',
      params
    })
  }
  export const getInformationDetail = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/hdcheckplanmaterial/detail',
      method: 'get',
      params
    })
  }
  export const removeInformationList = (data) => {
      return request({
        url: ServerNameEnum.SERVER_FX + `/hdcheckplanmaterial/remove?ids=${data.ids}`,
        method: 'post',
        data
      })
  }
  
  //项目风险应对措施表接口
export const pmriskTreatment = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/pmriskTreatment/checkImportList',
    method: 'GET',
    params
  })
}
//风险应对措施表接口
export const riskTreatment = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/riskTreatment/checkImportList',
    method: 'GET',
    params
  })
}
export const hdcheckindexsaveList = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckplanindex/saveList',
    method: 'post',
    data
  })
}
// 引用接口
export const quoteInfo=(params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckplan/createPlanByAct',
    method: 'get',
    params
  })
}
// 新增检查对象
export const hdcheckindexObj = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckplanobj/submit',
    method: 'post',
    data
  })
}

// 新增检查对象
export const hdcheckindexObjUpdate = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckplanobj/update',
    method: 'post',
    data
  })
}
export const hdcheckindexSaveList = (data) => {
  return request({
    // /hdcheckplanobj/save
    url: ServerNameEnum.SERVER_FX + '/hdcheckplanindextype/saveBatch',
    method: 'post',
    data
  })
}
export const hdcheckindexRemoveList = (data) => {
  return request({
    // /hdcheckplanobj/save
    url: ServerNameEnum.SERVER_FX + `/hdcheckplanobj/remove?ids=${data.ids}`,
    method: 'post',
    data
  })
}
