import Layout from '@/page/index/'
export default [{
  path: '/generator',
  component: Layout,
  children: [
    {
      path: '/webForm/index',
      name: '功能表单',
      component: () => import('@/views/generator/webForm/index'),
      meta: {
        title: '功能表单',
        icon: '',
      }
    },
  ]
}]

