import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_scheme/list_for_page',
    method: 'post',
    data: {
      ...params,
      query:{
        current,
        size
      }
    }
  })
}
export const save = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_scheme/save',
    method: 'post',
    data:params
  })
}

export const remove = (id) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_scheme/deleted_scheme',
    method: 'post',
    data: {
      id
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+`/data_quality/data_quality_scheme/${id}`,
    method: 'get'
  })
}

export const changeState = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_scheme/execute_scheme',
    method: 'post',
    data:params
  })
}

export const getTreeList = (params={}) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/data-directory/get-data-directory-all-by-pid',
    method: 'post',
    data:params
  })
}
