var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-color-picker", {
    staticClass: "theme-picker",
    staticStyle: { "padding-top": "18px" },
    attrs: { size: "mini", "popper-class": "theme-picker-dropdown" },
    model: {
      value: _vm.themeVal,
      callback: function ($$v) {
        _vm.themeVal = $$v
      },
      expression: "themeVal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }