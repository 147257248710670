import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
// 新增修改
export const createOrUpdate = (data) => {
    return request({
        url: ServerNameEnum.SERVER_FX + '/appliedtatistics/createOrUpdate',
        method: 'post',
        data
    })
}

// 查询
export const epeventPage = (params) => {
    return request({
        url: ServerNameEnum.SERVER_FX + '/appliedtatistics/page',
        method: 'get',
        params
    })
}

// 删除
export const remove = (data) => {
    return request({
        url: ServerNameEnum.SERVER_FX + '/appliedtatistics/remove',
        method: 'post',
        data
    })
}
//自定义删除
export const removeByIds = (ids) => {
    return request({
        url: ServerNameEnum.SERVER_FX + `/appliedtatistics/delete/${ids}`,
        method: 'delete'
    })
}

// 展示数据接口

export const contrastData = (data) => {
    return request({
        url: ServerNameEnum.SERVER_FX + '/appliedtatistics/getSearchViewPage',
        method: 'post',
        data
    })
}

// 展示数据接口

export const importViewPage = (data) => {
    return request({
        url: ServerNameEnum.SERVER_FX + '/appliedtatistics/importViewPage',
        method: 'post',
        responseType: 'blob',
        data
    })
}