import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduquestions/page`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getListSync = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduquestions/pageSync`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const againSync = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduquestions/againSync`,
    method: 'get',
    params: {
      ids
    }
  })
}
export const listAll = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduquestions/listAll`,
    method: 'get',
  })
}

export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduquestions/detail`,
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduquestions/remove`,
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduquestions/submit`,
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduquestions/submit`,
    method: 'post',
    data: row
  })
}

export const getEduList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduquestions/eduList`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const checkNameExist = (subjectName,id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduquestions/checkNameExist`,
    method: 'post',
    params: {
      subjectName,
      id
    }
  })
}


export const getAnswers = (questionId,paperId) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduquestions/getAnswers`,
    method: 'get',
    params: {
      questionId,
      paperId
    }
  })
}
