import request from '@/router/axios';
import ServerNameEnum from "@/util/ServerNameEnum";

// 编辑表
export function getDictionaryTypeSelector() {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dict-biz/parent-list?current=1&size=500',
    method: "get",
  });
};
