import Layout from "@/page/index/";

export default [
  // 项目预警处置措施
  {
    path: "/dbtEarlyWarningDisposal",
    component: Layout,
    name: "项目预警处置措施",
    children: [
      {
        path: "/dbtEarlyWarningDisposal/edit",
        name: "项目预警处置措施",
        component: () => import('@/views/dbtEarlyWarningDisposal/edit'),
        meta: {
          i18n: '项目预警处置措施'
        }
      },
    ],
  },
];