//数据中台英文
export default {

  datacenter: {
    btn: {
      next: "next",
      prev: "prev"
    },
    form: {
      notEmpty: "Can not be empty"
    },
    tips: {
      del: "The selected data will be permanently deleted, continue?",
      copy: "Confirm to copy the current interface, continue?",
      copySuccess: "copy successfully",
      apiPublishedSuccess: "The interface is published successfully",
      apiLogoutSuccess: "Interface logout succeeded",
      connectionSuccess: "connection succeeded",
      saveSuccess: "Saved successfully",
      saveError: "save failed",
      saving: "saving...",
      parsedSuccess: "The parsing is successful, please proceed to the next step"
    },
    //数据质量
    dataQuality: {
      index: 'index',
      programName: "program name",
      status: "status",
      //监测任务
      monitoringTask: {
        title: "Quality Monitoring Tasks",
        remark: "remark",
        implement: "implement",
        pause: "pause",
        label: "label",
        cycleSetting: "cycle setting",
        select: "select",
        selectRuleTem: "Choose a rule template",
        selectDataObj: "select data object",
        selectJudgeMethod: "Select a judgment method",
        accuracyVerif: "Verification of accuracy",
        consistencyVerif: "Consistency Verification",
        timelinessVerif: "Timeliness verification",
        testNum: "Measurement point data value",
        tooBig: "is too big",
        tooSmall: "too small",
        b1: "Broken mining detection",
        b2: "DCS measurement point data collection",
        b3: "Energy/one card and other business data cut off",
        c1: "Comparing the sum of hourly output data with daily output data",
        c2: "Comparison of the index value of the platform in the data and the index value of production management",
        conditionName: "condition name",
        alarmLevel: "Alarm level",
        setRule: "set rules",
        isExecute: "Whether to execute immediately",
        executeImme: "execute immediately",
        manualExecution: "Manual execution",
        addTestParams: "Add detection parameters",
        judgmentType: "Judgment type",
        dataComparison: "Data comparison",
        outlierJudgment: "Outlier judgment",
        intervalRangeValue: "interval range value",
        low: "low",
        middle: "middle",
        high: "high",
        numericalJudgment: "Numerical judgment",
        intervalJudgment: "Interval judgment",
        inProgress: "in execution",
        paused: "Paused",
        confirmExecution: "Are you sure you want to execute the current plan?",
        confirmSuspension: "Are you sure you want to suspend the current plan?",
        updateSuccessTip: "Status modified successfully",
        programExecutionDate: "Program execution date"
      },
      //监测记录
      monitoringRecord: {
        title: "Quality Monitoring Record",
        templateName: "template name",
        executeBeginTime: "Program execution start time",
        executeEndTime: "Program execution end time",
        executionDetails: "Execution Details",
        ruleName: "rule name",
        dataObjectLabel: "Data Object Identifier",
        judgMethodName: "Judgment method name",
        executeResult: "Judgment method execution result",
        executeStatus: "Judgment Method Execution Status",
        originalDataDetail: "data object raw data"
      }
    },
    //数据源
    datasource: {
      title: "Data source management",
      name: "data source name",
      refreshCache: "refresh cache",
      syncStatus: "sync status",
      status: "status",
      createTime: 'creation time',
      refreshSuccess: "Refresh the cache successfully",
      refreshFailed: "Refresh cache failed",
      notSync: "not synchronized",
      synchronizing: "synchronizing",
      synced: "synced",
      sjyxx: "Data source information",
      ljxx: "connection information",
      datasourceType: "data source type",
      remark: "remark",
      host: "host",
      port: "port",
      sid: "Service Name",
      dbName: "database",
      username: "username",
      password: "password",
      ltxcs: "Connectivity hiddenTrouble",
      sjyxz: "New data source",
      sjybj: "Data source editor",
      ysjtb: "metadata synchronization",
      sjyxq: "Data source details",
      ysjtbTip: "The metadata is being synchronized in the background, please go to the metadata management to view the results",
      fileName: "database design document.doc"
    },
    //元数据
    datacolumn: {
      title: "MetadataManagement",
      fieldName: "Field Name",
      load: "Loading...",
      fieldComment: "field comment",
      isPrimaryKey: "Whether primary key",
      isEmpty: "Is it allowed to be empty",
      dataType: "type of data",
      dataLength: "Data length",
      dataPrecision: "Data Accuracy",
      dataScale: "Data decimal places",
      dataDefault: "data default",
      sourceName: "data source",
      tableName: "Database Table",
      updateField: "change field",
      version: "version",
      fieldOldValue: "original value",
      fieldNewValue: "latest value",
      status: "status",
      remark: "remark",
      ysjbg: "metadata changes"
    },
    //数据服务接口
    dataapi: {
      title: "Data service interface",
      apiName: "API name",
      apiVersion: "API version",
      apiUrl: "API path",
      reqMethod: "request type",
      resType: "return format",
      status: "status",
      createTime: 'createTime',
      attrConfig: "attribute configuration",
      executeConfig: "execute configuration",
      paramsConfig: "parameter configuration",
      deny: "IP blacklist",
      rateLimit: "Whether to limit current",
      limiting: "Current limiting configuration",
      every: "Every",
      second: "Limit requests in seconds",
      times: "Second-rate",
      remark: "remark",
      configType: "configuration method",
      sourceName: "data source",
      fieldList: "field list",
      columnName: "column name",
      columnDataDefault: "column default",
      columnComment: "column comment",
      reqable: "Whether as a request parameter",
      resable: "Whether as a return parameter",
      sqlParsing: "SQL parsing",
      reqParams: "request parameters",
      paramName: "parameter name",
      nullable: "Is it allowed to be empty",
      paramComment: "describe",
      paramType: "Parameter Type",
      whereType: "operator",
      exampleValue: "example value",
      defaultValue: "Defaults",
      returnField: "return field",
      fieldComment: "field description",
      apiAdd: "New data API",
      apiDetail: "Data API Details",
      apiEdit: "Data APIEdit",
      denyPlaceholder: "Please enter multiple IP blacklists in English, separated",
      apiDocument: "interface documentation",
      apiExample: "interface example",
      fileName: "interface documentation.doc",
      interfaceCall: "interface call",
      reqHead: "request header",
      reqData: "request data",
      KEY: "key",
      VALUE: "value",
      DESCRIPTION: "describe",
      noData: "No data",
      paramValue: "parameter value"
    }
  },
  //项目列表
  projectlist: {
    name: "ProjectList",
    listedit: {
      dispatch: "dispatch",
      see: "see",
      release: "release",
      TaskManagement: "TaskManagement",
      edit: "edit",
      delete: "delete"
    },
    seetabs: {
      Joblog: "Joblog",
      flowChart: "flowChart",
      taskList: "taskList",
      ProcessLog: "ProcessLog",
      InformationService: "InformationService",
      taskListlabel: {
        name: "name",
        type: "type",
        startTime: "startTime",
        Endtime: "Endtime",
        timeConsuming: "timeConsuming",
        state: "state",
        details: "details",
      },
      editlabel: {
        Englishname: "Englishname",
        Chinesename: "Chinesename",
        Contentdescription: "Contentdescription",
        EditItem: "EditItem",
        NewProject: "NewProject"
      },
      addrule: {
        ename: "Please enter English name",
        cname: "Please enter a Chinese name",
        text: "Please enter content description"
      },
    },
    msg: {
      msg1: "Do you want to delete this task?",
      msg2: "Run each component of this experiment in turn",
      msg3: "Stop running test",
    },
    btn: {
      deploy: "deploy",
      run: "run",
      stop: "stop",
      Taskbuilding: "Taskbuilding",
      name: "name",
      save: "save",
      DocumentContent: "DocumentContent",
      Navigator: "Navigator",
      ParameterSetting: "ParameterSetting",
      back: "back",
      sure: "sure",
      close: "close",
      canvas: "canvas",
      Gridalignment: "Gridalignment",
      revoke: "revoke",
      redo: "redo",
      delete: "delete",
      enlarge: "enlarge",
      narrow: "narrow",
      Fittocanvas: "Fittocanvas",
      Actualsize: "Actualsize",
      Postlevel: "Postlevel",
      Levelleading: "Levelleading",
      Multiplechoice: "Multiplechoice",
      Group: "Group",
      Ungroup: "Ungroup",
    }
  },
  taskScheduling: {
    name: "taskScheduling",
    tabs: {
      Projectlist: "Projectlist",
      Historylist: "Historylist",
      Scheduledtasklist: "Scheduledtasklist",
      Runninglist: "Runninglist",
    },
    btn: {
      Scheduledscheduling: "Scheduledscheduling",
      dispatch: "dispatch",
      see: "see",
      kill: "kill",
    },
    msg: {
      msg1: "Dispatched successfully",
      msg2: "Delete or not",
      msg3: "Whether to kill this task"
    },
    title: {
      Tips: "Tips",
      InformationService: "InformationService",
      flowchart: "flowchart",
      tasklist: "tasklist",
      Processlog: "Processlog",
      Joblog: "Joblog",
    },
    status: {
      success: "success",
      fail: "fail",
      Running: "Running",
    },

    label1: {
      name: "name",
      Chinesename: "Chinesename",
      describe: "describe",
      Lastmodificationtime: "Lastmodificationtime",
      Modifiedby: "Modifiedby",
      operation: "operation",
      type: "type",
    },
    label2: {
      Executionid: "Executionid",
      flow: "flow",
      engineering: "engineering",
      user: "user",
      starttime: "starttime",
      Endtime: "Endtime",
      timeconsuming: "timeconsuming",
      state: "state",
    },
    label3: {
      ID: "ID",
      Taskname: "Taskname",
      project: "project",
      Firstscheduledrun: "Firstscheduledrun",
      Nextexecutiontime: "Nextexecutiontime",
      expression: "expression",
      operation: "operation",
    },
    label4: {
      ID: "ID",
      ExecutorID: "ExecutorID",
      flow: "flow",
      engineering: "engineering",
      user: "user",
      jurisdiction: "jurisdiction",
      starttime: "starttime",
      Endtime: "Endtime",
      Losstime: "Losstime",
      state: "state",
      operation: "operation",
    },

  },
  //数据主题
  dataSubject: {
    name: "dataSubject",
    addForm: {
      name: "name",
      Englishname: "Englishname",
      Parent: "Parent",
    },
    msg: {
      Tips: "Tips",
      msg1: "Are you sure you want to delete?",
      msg2: "Delete succeeded",
    },
    rules: {
      name: "Please enter a name",
      ename: "Please enter English name"
    },
    btn: {
      AddChild: "AddChild",
    }
  },
  //数据目录
  DataDirectory: {
    name: "DataDirectory",
    btn: {
      Description: "Description",
      BrowseData: "BrowseData",
    },
    label: {
      Dataname: "Dataname",
      datadescription: "datadescription",
      Datadirectory: "Datadirectory",
      datatype: "datatype",
      metadata: "metadata",
      Columnname: "Columnname",
      explain: "explain",
    },
    addForm: {
      Datasource: "Datasource",
      Tablename: "Tablename",
      name: "name",
      catalogue: "catalogue",
      explain: "explain",
    },

    rules: {
      rules1: "Please select a data table name",
      rules2: "Please select a data source",
    },
    msg: {
      msg1: "Operation succeeded!",
      msg2: "Please select data on the left",
      msg3: "Saved successfully",
    },
  },
  //数据模型
  datamodel: {
    name: "datamodel",
    label: {
      ChineseNameOfData: "ChineseNameOfData",
      Dataname: "Dataname",
      datatype: "datatype",
      Creationtime: "Creationtime",
      form: 'form',
      relationship: 'relationship',
      Fieldname: 'Fieldname',
      FieldName: "FieldName",
      Chinesename: 'Chinesename',
      type: 'type',
      length: 'length',
      accuracy: 'accuracy',
      Primarykeyornot: 'Primarykeyornot',
      Isitempty: 'Isitempty',
      Currenttablename: "Currenttablename",
      Associatedtable: "Associatedtable",
      Associatedfield: "Associatedfield",
      is: "is",
      no: "no",
    },
    addForm: {
      newtable: "newtable",
      Modelname: "Modelname",
      datatype: "datatype",
      datasource: "datasource",
      Pleaseselect: "Pleaseselect",
      Tablename: "Tablename",
      notes: "notes",
    },
    rules: {
      rules1: "Please enter a table name",
      rules2: "Please select a data source",
      rules3: "Please enter comments",
    },
    msg: {
      msg1: "Edit succeeded",
      msg2: "Save succeeded",

    }
  },
  AiSuanfa: {
    title: 'Algorithm management',
    btn: 'whole',
    label: 'Algorithm name',
    search: 'search',
    itemBtn: 'Training model',
    sfFl: 'Algorithm classification',
    btnSave: 'define',
    btnCen: 'cancel',
    gf: 'authority',
    btns: {
      yuce: 'calculate',
    },
    form: {
      name: 'Algorithm name',
      dbtype: 'Algorithm attribution',
      source: 'Algorithm classification',
      describe: 'Algorithm description',
      file: 'Select File'
    },
    detail: {
      xl: 'train',
      placeholder: 'input data',
      modeName: 'Model name',
      modePath: 'Model path',
      AlgorithName: 'Algorithm name',
      x: 'X-axis',
      y: 'y-axis',
      x1: 'Please select the X-axis property',
      y1: 'Please select the Y-axis property',
      zh: 'Chinese language',
      field: 'field',
      dataType: 'data type',
      mxpg: 'Model evaluation',
      ycjg: 'Forecast results'
    },

  },
  AiModel: {
    placeholder: 'Please enter a model name',
    name: 'Model list',
    label: {
      id: 'Model ID',
      model_name: "Model name",
      algorithm_name: 'Algorithm name',
      model_input: 'Model input',
      model_path: 'Model path',
      model_evaluation: 'Model evaluation',
    }
  },
  AiResult: {
    placeholder: 'Please enter a model name',
    name: 'Model list',
    label: {
      predictId: 'predict Id',
      modelId: "Model ID",
      predict_name: 'predict name',
      algorithm_name: 'algorithm name',
      model_name: 'Model name',
    },
    title: '模型详情',
  },
  Primordial: {
    name: 'DataConsanguinity',
    tabData: {
      number: 'number',
      tableName: "tableName",
      nodeName: 'nodeName',
      remarks: "remarks",
      operate: 'operate',
      addRelationship: 'addRelationship'

    },
    form: {
      tip: 'tip',
      deleteOrNot: 'deleteOrNot',
      NewNode: "NewNode",
      editNode: "editNode"
    },
    fun: {
      submission: 'Data without blood relationship will be deleted after submission. Do you want to continue?',
      successfully: 'Submitted successfully',
      Canceled: 'Canceled',
      ConfirmClose: 'ConfirmClose',
      bloodAdd: 'Can only be added on the blood node',
      Parent: 'Parent',
      TreeDirectory: 'TreeDirectory',
      relationship: "Cannot create a new directory and blood relationship under data blood relationship!",
      deleteTheDetails: 'Please edit and delete the data lineage on the right!',
      directoryToModify: "Please select the directory to modify"


    }


  },
  shunt: {
    name: "Data diversion",
    tip: 'point out',
    confirmButtonText: 'define',
    cancelButtonText: 'cancel',
    LoadingDesperately: 'Loading desperately',
    table: {
      ShuntCoding: 'Shunt coding',
      InterfaceCode: 'Interface code',
      Transcoding: 'Transcoding',
      sfqy: 'Enable or not',
      zxtj: 'conditions for execution',
      describe: 'describe',
      conditionsForExecution: 'conditionsForExecution',
      isEnable: 'Enable or not',
      startUsing: 'start using',
      forbidden: 'forbidden',
      address: 'operate',
      edit: "edit"
    },
    placeholder: {
      diversionCode: 'Please enter diversion code/interface code/conversion code/description',
      interfaceCode: 'Please enter the interface code',
      conversionCode: 'Please enter conversion code',
      description: 'Please enter a description',
      executionConditions: 'Please enter execution conditions',
      diversionEditing: 'Data diversion editing',
      diversionAdd: 'Add data diversion'
    },
    fun: {
      isDelect: 'Are you sure you want to delete?',
      DeleteSucceeded: 'Delete succeeded',
      ModifiedSuccessfully: 'Modified successfully',
      SuccessfullyAdded: 'SuccessfullyAdded',

    }
  },


  msg3: "Please enter a table name",
  //全量接口采集
  FullInterfaceAcquisition: {
    name: "FullInterfaceAcquisition",
    name2: "AddFullInterfaceAcquisition",
    label: {
      InterfaceName: "InterfaceName",
      InterfaceAddress: "InterfaceAddress",
      RequestMethod: "RequestMethod",
      ReturnStatusCode: "ReturnStatusCode",
      ReturnStatusValue: "ReturnStatusValue",
      PrimaryKeyName: "PrimaryKeyName",
      ResultConfiguration: "ResultConfiguration",
      InterfaceCode: "InterfaceCode",
      TopicConfiguration: "TopicConfiguration",
      SystemSourceID: "SystemSourceID",
      ForeignKeyCode: "ForeignKeyCode",
      CreationTime: "CreationTime",
      ModificationTime: "ModificationTime",
      InterfaceDescription: "InterfaceDescription",
    },
    msg: {
      msg1: "Request parameters",
      msg2: "Please select type",
    }
  },

  //增量接口采集
  zljkcj: {
    name: "IncrementalInterfaceAcquisition",
    name2: "AddIncrementalInterfaceAcquisition",
    label: {
      InterfaceCode: "InterfaceCode",
      InterfaceName: "InterfaceName",
      InterfaceAddress: "InterfaceAddress",
      RequestMethod: "RequestMethod",
      AccessMode: "AccessMode",
      dataType: "dataType",
      StartTimeField: "StartTimeField",
      EndTimeField: "EndTimeField",
      IncrementalTimestamp: "IncrementalTimestamp",
      IncreaseStepLengthTime: "IncreaseStepLengthTime",
      IncrementalTimeType: "IncrementalTimeType",
      IncrementalTimeTypeFormat: "IncrementalTimeTypeFormat",
      StepLengthdays: "StepLengthdays",
      ReturnDataItem: "ReturnDataItem",
      ReturnStatusCode: "ReturnStatusCode",
      ReturnDescription: "ReturnDescription",
      PrimarykeyField: "PrimarykeyField",
      SourceSystem: "SourceSystem",
      TokenField: "TokenField",
      SourceDatabaseInformation: "SourceDatabaseInformation",
    },
    addForm: {
      RequestHeader: "RequestHeader",
      AddRequestHeader: "AddRequestHeader",
      TimeTypeFormat: "TimeTypeFormat",
      increment: "increment",
      Addsteptimestamp: 'Addsteptimestamp',
      Interfaceconfigurationinformation: "Interfaceconfigurationinformation",
      Endtimefield: "Endtimefield",
      Updatetimefield: "Updatetimefield",
      Timetype: "Timetype",
      Timestamptype: "Timestamptype",
      Maximumtimestamp: "Maximumtimestamp",
      Sourcedatabase: "Sourcedatabase",
      constant: "constant",
      parameter: "parameter",
      Requestor: "Requestor",
      type: "type",
      startTime: 'startTime',
      endTime: "endTime",
      Updatetime: "Updatetime",




    },
    msg: {
      msg1: "请输入接口编码/接口名称Please enter interface code/interface name"
    }
  },

  //离线数据采集
  OfflinedataCollection: {
    name: "OfflinedataCollection",
    label: {
      step: "step",
      structure: "structure",
      FieldMapping: "FieldMapping",
      Datasynchronization: "Datasynchronization",
      Databasename: "Databasename",
      Datatablename: "Datatablename",
      SQLstatement: "SQLstatement",
      Resolvefield: "Resolvefield",
      Shardfield: "Shardfield",
      TableAllFields: "TableAllFields",
      SelectAll: "SelectAll",
      Wherecondition: "Wherecondition",
      PrecedingSQLstatement: "PrecedingSQLstatement",
      Sourcefield: "Sourcefield",
      Targetfield: "Targetfield",
      Startsynchronization: "Startsynchronization",
      start: "start",
      fill: "fill",
      Previousstep: "Previousstep",
      nextstep: "nextstep",
    }
  },

  transformation: {
    name: 'data conversion',
    tip: 'point out',
    confirmButtonText: 'define',
    cancelButtonText: 'cancel',
    placeholder: "Please enter data interface/field name",
    LoadingDesperately: 'Loading desperately',
    table: {
      infCode: 'data interface',
      fieldName: "Field name",
      targetTable: 'Target table name',
      srcField: 'Source field',
      targetField: "Target field",
      unitCode: "Unit conversion code",
      mapCode: 'Encoding mapping',
      groupNo: 'divide into groups',
      defaultValue: "Default",
      dimension: 'dimension',
      dimensionSed: 'Dimension ID',
      edit: "edit",
      aggregationCode: 'Please select the aggregation code',
      targetTagName: 'Please enter the target tag name',
      sourceField: "Please enter the source field",
      PleasetargetField: 'Please enter the target field',
      conversionEditing: 'Data conversion editing',
      DataConversionAdd: 'Data conversion add'
    },
    fun: {
      isDelect: 'Are you sure you want to delete?',
      DeleteSucceeded: 'Delete succeeded',
      ModifiedSuccessfully: 'Modified successfully',
      SuccessfullyAdded: 'Successfully added',

    }

  },
  injection: {
    tip: 'point out',
    confirmButtonText: 'define',
    cancelButtonText: 'cancel',
    name: "Data injection configuration",
    placeholder: "Please enter the target table name",
    table: {
      loading: "Loading desperately",
      tablename: 'Target table name',
      keycolumn: 'Primary key splicing',
      address: 'operate',
      edit: "edit",
    },
    form: {
      tablename: 'Target table name:',
      aggregationCode: 'Please enter the aggregation code',
      keycolumn: 'Primary key splicing:',
      groupingField: 'Please enter grouping field'
    },
    fun: {
      PleaseSelect: 'Please select to delete data',
      ModifiedSuccessfully: 'Modified successfully',
      SuccessfullyAdded: 'Successfully added',
      isDelect: 'Are you sure you want to delete?',
      DeleteSucceeded: 'Delete succeeded',
      configurationEditing: 'Data injection configuration editing',
      configurationAddition: 'Data injection configuration addition'
    }


  },


  //数据字典
  dataDictionary: {
    name: "dataDictionary",
    name2: "AdddataDictionary",
    label: {
      code: "code",
      name: "name",
      operate: "operate"
    },
    addForm: {
      FieldCode: "FieldCode",
      DictionaryName: "DictionaryName",
      DictionaryType: "DictionaryType",
      Datasourcecode: "Datasourcecode",
      Datasourcename: "Datasourcename",
      Databasename: "Databasename",
      list: "list",
      tree: "tree"
    },
    rules: {
      rules1: "Please enter data source code",
      rules2: "Please enter data source type ",
      rules3: "Please enter the database name",
      rules4: "Please enter the data source connection configuration",
      rules5: "Please select a type",
    },
    msg: {
      msg1: "Please select to delete data"
    }
  },

  //数据汇聚
  DataAggregation: {
    name: "DataAggregation",
    msg: "Save success",
    btn: {
      add: "add",
      remove: "add",
      edit: "edit",

    },
    tabs: {
      DataAggregationGrouping: "DataAggregationGrouping",
      DataAggregationconfiguration: "DataAggregationconfiguration",
      DataAggregationoutput: "DataAggregationoutput",
    },
    addtitle: {
      AddDataAggregationGrouping: "AddDataAggregationGrouping",
      AddDataAggregationconfiguration: "AddDataAggregationconfiguration",
      AddDataAggregationoutput: "AddDataAggregationoutput",
    },
    table1: {
      AggregationCoding: "AggregationCoding",
      Groupfields: "Groupfields",
      remarks: "remarks",
      operation: "operation",
    },
    table2: {
      Convergencetime: "Convergencetime",
      Convergencedimension: "Convergencedimension",
      Aggregationsourcetable: "Aggregationsourcetable",
      Taskgrouping: "Taskgrouping",
      Executionorder: "Executionorder",
    },
    table3: {
      Aggregateflag: "Aggregateflag",
      Sourcefield: "Sourcefield",
      Targetfield: "Targetfield",
      Targettable: "Targettable",
    }





  },

  //Token刷新配置
  Token: {
    msg: "Save sucess",
    name: "Tokenrefreshconfiguration",
    Addname: "AddTokenrefreshconfiguration",
    table: {
      Interfacecode: "Interfacecode",
      Url: "Url",
      Requestinformation: "Requestinformation",
      Requestmethod: "Requestmethod",
      Splicinginformation: "Splicinginformation",
      Fieldname: "Fieldname",
      Datasourceid: "Datasourceid",
      Tokensourceid: "Tokensourceid",
      operation: "operation",
      Requestheader: "Requestheader",
      Addrequestheader: "Addrequestheader",
      edit: "edit",
      Interfaceaddress: "Interfaceaddress",
    },


    omit:{
      Normal:"Normal",
      anomaly:"Anomaly",
      executioning:"Executing",
      paused:"Paused",
      firing:"Triggering",
      scheduledSuccess:"Scheduled Success",
      hours:"Hours",
      minutes:"Minutes",
      seconds:"Seconds",
      reqUrl:"Request URL",
      successmsg:"Success Message",
      dataInput:"Data Input",
      dataOutput:"Data Output",
      Computing:"Computing",
      dataobj:"Data Object",
      dataSheet:"Data Sheet",
      variance:"Variance",
      Boundarylimitation:"Boundary Limitation",
      Topicclassification:"Topic Classification",
      database:"Database",
      apiinterface:"API Interface",
      index:"Index",
      queryCriteria:"Query Criteria",
      select:"Please Select",
      type:"Type",
      start:"Start",
      end:"End",
      input:"Please Input Value",
      },



  },

}
