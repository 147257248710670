import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export function runSql (data) {
  return request({
    url: '/data/console/sql/run',
    method: 'post',
    data: data
  })
}

export function stopSql (data) {
  return request({
    url: '/data/console/sql/stop',
    method: 'post',
    data: data
  })
}
