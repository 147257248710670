import Layout from "@/page/index/";

export default [
  //环境保护
  {
    path: "/dbtMonitorAccount",
    component: Layout,
    name: "项目监控账号管理",
    children: [
      {
        path: "/dbtMonitorAccount/edit",
        name: "项目监控账号管理",
        component: () => import('@/views/dbtMonitorAccount/edit'),
        meta: {
          i18n: '项目监控账号管理'
        }
      },
    ],
  },
];
