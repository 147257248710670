import request from "@/router/axios";

// 项目预警处置措施列表
export const dbtearlywarningdisposalPage = params => {
    return request({
      url: `/api/sinoma-hse-prj/dbtearlywarningdisposal/page`,
      method: "get",
      params
    });
  };

 // 项目气象预警项目
  export const dbtmeteorologicalwarningPage = params => {
    return request({
      url: `/api/sinoma-hse-prj/dbtmeteorologicaldisasterwarning/page`,
      method: "get",
      params
    });
  };

  // 项目气象预警项目 添加或修改
  export const dbtwarningSumbit = data => {
    return request({
      url: `/api/sinoma-hse-prj/dbtearlywarningdisposal/submit`,
      method: "post",
      data
    });
  };

  // 获取预警处置详细信息
  export const dbtwarningDetail = (params) => {
    return request({
      url: `/api/sinoma-hse-prj/dbtearlywarningdisposal/detail`,
      method: 'get',
      params
    })
  }   

  // 删除预警处置信息
  export const dbtwarningRemove = (ids) => {
    return request({
      url: `/api/sinoma-hse-prj/dbtearlywarningdisposal/remove?ids=${ids}`,
      method: 'post'
    })
  }

