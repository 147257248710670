var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "avue-contail", class: _vm.classObj }, [
    _c(
      "div",
      {
        ref: "avueLayout",
        staticClass: "avue-layout",
        class: _vm.layoutType,
        style: _vm.hideMenu ? "padding-left:0px" : "",
      },
      [
        _vm.layoutType != "functional" && !_vm.hideMenu
          ? _c(
              "div",
              {
                ref: "avueLeft",
                staticClass: "avue-left",
                class: _vm.isCollapse == true ? "leftWide" : "leftNarrow",
              },
              [
                !_vm.isIframeIn
                  ? _c("div", { staticClass: "logo-content" }, [
                      _vm.keyCollapse
                        ? _c("img", { attrs: { src: _vm.path } })
                        : _vm._e(),
                      !_vm.keyCollapse && _vm.language == "zh"
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/sidder-logo.png"),
                            },
                          })
                        : _vm._e(),
                      !_vm.keyCollapse && _vm.language == "en"
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/sidder-logo-en.png"),
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _c("sidebar", { ref: "sidebar" }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "avue-main" },
          [
            !_vm.isIframeIn && !_vm.hideMenu
              ? _c("top", { ref: "top" })
              : _vm._e(),
            !_vm.hideMenu ? _c("tags") : _vm._e(),
            !_vm.isIframeIn && !_vm.hideMenu ? _c("shortcutMenu") : _vm._e(),
            !_vm.isIframeIn && !_vm.hideMenu
              ? _c(
                  "transition",
                  { attrs: { name: "fade-scale" } },
                  [
                    _c("search", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isSearch,
                          expression: "isSearch",
                        },
                      ],
                      staticClass: "avue-view",
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isSearch,
                    expression: "!isSearch",
                  },
                ],
                ref: "avueView",
                style: _vm.dragStyle,
                attrs: { id: "avue-view" },
              },
              [
                _c(
                  "keep-alive",
                  { attrs: { include: _vm.cachedViews } },
                  [_c("router-view", { staticStyle: { height: "100%" } })],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _c("div", { staticClass: "avue-shade", on: { click: _vm.showCollapse } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }