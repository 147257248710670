var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-autocomplete", {
        staticClass: "top-search",
        attrs: {
          "popper-class": "my-autocomplete",
          "fetch-suggestions": _vm.querySearch,
          placeholder: _vm.$t("search"),
        },
        on: { select: _vm.handleSelect },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ item }) {
              return [
                _c("i", { class: [item[_vm.iconKey], "icon"] }),
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(item[_vm.labelKey])),
                ]),
                _c("div", { staticClass: "addr" }, [
                  _vm._v(_vm._s(item[_vm.pathKey])),
                ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }