import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/region/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getLazyTree = (parentCode, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/region/lazy-tree',
    method: 'get',
    params: {
      ...params,
      parentCode
    }
  })
}
// 获取指定节点下所有组织树
export const getAllTree = (parentCode, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/region/treeList',
    method: 'post',
    data: {
      parentCode: "10",
    }
    // params: {
    //   ...params,
    //   parentCode
    // }
  })
}

export const getDetail = (code) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/region/detail',
    method: 'get',
    params: {
      code
    }
  })
}

export const remove = (code) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/region/remove',
    method: 'post',
    params: {
      code,
    }
  })
}

export const submit = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/region/submit',
    method: 'post',
    data: row
  })
}

export const getAreaData1 = (parentCode, areaDic) => { 
  let dic = [];
  try {
    let t = sessionStorage.getItem("sinoma-AreaData");
    
    if (!t) {
      getAreaData(parentCode, areaDic);
    } else {
      dic = JSON.parse(t);
    }
  } catch {
    getAreaData(parentCode, areaDic);
  }finally{
    while(areaDic.length>0){
      areaDic.pop();
    }
    for(let i = 0; dic.length > i; ++i){
      areaDic.push(dic[i]);
    }
  }
}

function generateOptions(params){
  var result = []
  for (const param of params) {
    if (param.parentId == '10') {  // 判断是否为顶层节点
      var parent = {
        'id': param.id,
        'label': param.title,
        'value': param.id,
        'title': param.title
      }
      parent.children = getchilds(param.id, params)  // 获取子节点
      result.push(parent)
    }
  }
  return result
}

function getchilds(id, array) {
  const childs = []
  for (const arr of array) {  // 循环获取子节点
    if (arr.parentId === id) {
      childs.push({
        'id': arr.id,
        'label': arr.title,
        'value': arr.id,
        'title': arr.title
      })
    }
  }
  for (const child of childs) { // 获取子节点的子节点
    const childscopy = getchilds(child.id, array)// 递归获取子节点
    if (childscopy.length > 0) {
      child.children = childscopy
    }
  }
  return childs
}

function getAreaData(parentCode,areaDic ){
  getLazyTree(parentCode).then(res => {
    if (res.data.data && res.data.data.length != 0) {
      let dic = generateOptions(res.data.data)
      sessionStorage.setItem("sinoma-AreaData", JSON.stringify(dic));
      while(areaDic.length>0){
        areaDic.pop();
      }
      for(let i = 0; dic.length > i; ++i){
        areaDic.push(dic[i]);
      }
    }
  })
}

export const updateAreaData = ()=>{
  getLazyTree().then(res => {
    if (res.data.data && res.data.data.length != 0) {
      let dic = generateOptions(res.data.data)
      sessionStorage.setItem("sinoma-AreaData", JSON.stringify(dic));
    }
  })
}

