import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_NAME = "/index/themeCuboid";

/**
 * 获取CuboidId
 * @param params
 * @returns {*}
 */
export const getCuboidId = () => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/getCuboidId`,
    method: 'get',
  })
}
/**
 * 保存CuboidId
 * @param data
 * @returns {*}
 */
export const saveCuboid = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/saveCuboid`,
    method: 'post',
    data: data,
  })
}
/**
 * 查询cuboid列表
 * @param params
 * @returns {*}
 */
export const listByThemeId = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/listByThemeId`,
    method: 'get',
    params: {
      ...params,
    }
  })
}
/**
 * 查询cuboid列表
 * @param params
 * @returns {*}
 */
export const detail = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/detail`,
    method: 'get',
    params: {
      ...params,
    }
  })
}

