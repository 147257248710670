import request from '@/router/axios'
import ServerNameEnum from "@/util/ServerNameEnum";

// 获取门户列表
export function getpage(current, size, params) {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + '/portal/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  })
}
export function getPortalType() {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dict-biz/dictionary?code=portal_type',
    method: 'get'
  })
}
// 获取门户信息
export function getPortalInfo(id) {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + `/portal/${id}`,
    method: 'get'
  })
}
// 删除门户
export function remove(ids) {
  return request({
    url: ServerNameEnum.SERVER_DESK_ +  `/portal/remove`,
    method: 'get',
    params: {
      ids
    }
  })
}
// 新建门户
export function Submit(data) {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + '/portal/submit',
    method: 'post',
    data
  })
}
// 复制门户
export function Copy(id) {
  return request({
    url:  ServerNameEnum.SERVER_DESK_ `/Portal/${id}/Actions/Copy`,
    method: 'POST'
  })
}
// 获取门户展示数据(权限)
export function getAuthPortal(id) {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + `/Portal/${id}/auth`,
    method: 'GET'
  })
}

export const getEnablePortal = (roleId) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + '/portal/getEnablePortal?roleId=' + roleId,
    method: 'GET'
  })
}

export const getEnablePortalById = (portalId) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + '/portal/getEnablePortalById?portalId=' + portalId,
    method: 'GET'
  })
}

// 获取天气
export const getTodayWeather = (data) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + '/portal/getWeather?location=' + data,
    method: 'GET'
  })
}

// 获取角色
export const getRoles = (portalId) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + '/portal/getRoles?portalId=' + portalId,
    method: 'GET'
  })
}


// 获取角色
export const getUserPortal = (userId) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + '/userPortal/detail?userId=' + userId,
    method: 'GET'
  })
}

// 保存用户关联
export function userSubmit(userId,roleId, portalId) {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + '/userPortal/submit',
    method: 'post',
    params: {
      userId,
      roleId,
      portalId
    }
  })
}

// 保存角色
export function roleHandle(portalId, roleIds) {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + '/portal/roleHandle',
    method: 'post',
    params: {
      portalId,
      roleIds
    }
  })
}
