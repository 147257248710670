import Layout from '@/page/index/'
export default [{
  path: '/index',
  component: Layout,
  children: [

    {
      path: '/business/index/appIndex/index',
      name: '指标定义',
      meta: {
        title: '指标定义',
        i18n: 'IndicatorDefinition'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/business/index/appIndex/index'),
    },

    {
      path: '/business/index/appIntf/index',
      name: '指标接口',
      meta: {
        title: '指标接口',
        i18n: 'IndicatorInterface'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/business/index/appIntf/index'),
    },
    {
      path: '/business/index/appCategory/view',
      name: '指标查看',
      meta: {
        i18n: 'appCategoryView'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/business/index/appCategory/view'),
    },
    {
      path: '/business/index/appCategory/index',
      name: '指标体系',
      meta: {
        i18n: 'appCategoryIndex'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/business/index/appCategory/index'),
    },

    {
      path: '/index/appIndex/add',
      name: '指标新增',
      meta: {
        title: '指标新增',
        i18n: 'indexAdd'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/business/index/appIndex/edit'),
    },
    {
      path: '/index/appIndex/edit',
      name: '指标编辑',
      meta: {
        title: '指标编辑'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/business/index/appIndex/edit'),
    },
    {
      path: '/index/appIndex/view',
      name: '指标查看',
      meta: {
        title: '指标查看',
        i18n: 'appIndexView'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/business/index/appIndex/edit'),
    },
    {
      path: '/index/appIntf/edit',
      name: '指标接口编辑',
      meta: {
        title: '指标接口编辑',
        i18n: 'appIntfEdit'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/business/index/appIntf/edit'),
    },
    {
      path: '/index/appIntf/view',
      name: '指标接口查看',
      meta: {
        title: '指标接口查看',
        i18n: 'appIntfView'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/business/index/appIntf/edit'),
    },
  ]
}]
