import Layout from '@/page/index/'
//生产
export default [{
  path: '/msg',
  component: Layout,
  children: [
    {
      path: '/message/channel/list',
      name: '通道管理',
      meta: {
        i18n: 'channel'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/message/channel/list'),
    },
    {
      path: '/message/channel/add',
      name: '通道新增',
      meta: {
        i18n: 'channelAdd'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/message/channel/add'),
    },
    {
      path: '/message/channel/edit',
      name: '通道编辑',
      meta: {
        i18n: 'channelEdit'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/message/channel/add'),
    },
    {
      path: '/message/channel/view',
      name: '通道查看',
      meta: {
        i18n: 'channelView'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/message/channel/add'),
    },

    {
      path: '/system/interface/interfaceList',
      name: '接口管理',
      meta: {
        i18n: 'interfaceList'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/system/interface/interfaceList'),
    },
    {
      path: '/interface/interfaceListEdit',
      name: '接口管理新增',
      meta: {
        i18n: 'interfaceListEdit'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/system/interface/interfaceListEdit.vue'),
    },

    {
      path: '/message/template/add',
      name: '模板新增',
      meta: {
        i18n: 'templatesAdd'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/message/template/edit'),
    },
    {
      path: '/message/template/list',
      name: '消息模板管理',
      meta: {
        i18n: 'templates'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/message/template/list'),
    },
    {
      path: '/message/template/edit',
      name: '模板修改',
      meta: {
        i18n: 'templatesEdit'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/message/template/edit'),
    },
    {
      path: '/message/template/view',
      name: '模板查看',
      meta: {
        title: '模板查看'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/message/template/edit'),
    },
    {
      path: '/message/sendInfo/details',
      name: '消息基本信息',
      meta: {
        i18n: 'messageBasicInformation'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/message/sendInfo/details'),
    },
    {
      path: '/message/sendInfo/common/detailInfo',
      name: '消息发送记录详情',
      meta: {
        title: '消息发送记录详情'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/message/sendInfo/common/detailInfo')
    },
    {
      path: '/message/sendInfo/list',
      name: '发送消息',
      meta: {
        i18n: 'sendInfo'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/message/sendInfo/list')
    },
    {
      path: '/message/sendInfo/add',
      name: '发送消息',
      meta: {
        i18n: 'sendInfoEdit'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/message/sendInfo/add')
    },
    {
      path: '/message/list',
      alias: '/b',
      name: '我的消息',
      meta: {
        i18n: 'messageList'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/message/list')
    }

  ]
}]
