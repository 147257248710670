import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_scheme/monitor-record',
    method: 'post',
    data: {
      ...params,
      query:{
        current,
        size
      }
    }
  })
}