import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
const CONTROLLER_NAME = "msgTemplateRule";


export const selectById = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/${CONTROLLER_NAME}/selectById`,
    method: 'get',
    params: {
      id
    }
  })
}
export const saveOrUpdate = (rows) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/${CONTROLLER_NAME}/saveOrUpdate`,
    method: 'post',
    data: rows
  })
}
