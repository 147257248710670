export default {
  qm: {
    business: {
      md: {
        codeRule: {
          field: {
            ruleCode: "ruleCode",
            ruleName: "ruleName",
            resettingCycle: "resettingCycle",
            num: "num",
            curVal: "curVal",
            initVal: "initVal",
            isEnable: "isEnable",
            codeRuleBusiType: "codeRuleBusiType",
            formtable: {
              paramType: "paramType",
              paramValType: "paramValType",
              paramVal: "paramVal",
              seq: "seq",
              auto: "automatically",
              param: "parameter input",
            }
          },
          title: {
            indexHeadTitle: "Coding Rules",
            addHeadTitle: "Coding RulesAdd",
            editHeadTitle: "Coding RulesEdit",
            viewHeadTitle: "Coding RulesView",
            formtableTitle: "Encoding composition",
          },
          msg: {
            paramList: "The saved parameter list is empty!",
            param: "The parameter value cannot be empty",
            paramNotRepeated: "The parameter value cannot be empty",
            paramType: "The parameter type cannot be duplicate",
            order: "Order cannot be 0",
            orderNotRepeated: "The order cannot be repeated",
          }
        },
        cusCar: {
          field: {
            cusCar: "cusCar",
            carNo: "carNo",
            remark: "remark",
            gpsSupplier: "gpsSupplier",
            falseness: "falseness",
            black_name: "black_name",
            companyName: "companyName",
            userName: "userName/userName",
            blackRemark: "blackRemark",
            createUser: "createUser",
            createTime: "createTime",
            blackUpdateUser: "blackUpdateUser",
            updateTime: "updateTime",
            lastModifiedTime: "lastModifiedTime",
          },
          title: {
            headTitle: "cusCar",
          }

        },
        class: {
          field: {
            classCode: "classCode",
            className: "className",
            classCatCode: "classCatCode",
            classCatName: "classCatName",
            isEnable: "isEnable",
            remark: "remark",
            afterTip: {
              firstSaveData: "Please save the data in the team members first",
            }
          },
          title: {
            indexHeadTitle: "teams and groups",
            addHeadTitle: "teams and groupsAdd",
            editHeadTitle: "teams and groupsEdit",
            viewHeadTitle: "teams and groupsView",
          }
        },
        supplier: {
          field: {
            headTitle: "Supplier Basic Information",
            supplierCode: "supplierCode",
            supplierName: "supplierName",
            contactPerson: "contactPerson",
            address: "address",
            tel: "tel",
            isEnable: "isEnable",
            remark: "remark",
          },
          title: {
            indexHeadTitle: "supplier",
            addHeadTitle: "supplierAdd",
            viewHeadTitle: "supplierView",
            editHeadTitle: "supplierEdit",
          }
        },
        warehouseCfg: {
          field: {
            warehouseCode: "warehouseCode",
            warehouseName: "warehouseName",
            itemCode: "itemCode",
            itemName: "itemName",
            isEnable: "isEnable",
            itemCodeAndName: "itemCodeAndName",
            warehouseCodeAndName: "warehouseCodeAndName",
          },
          title: {
            indexHeadTitle: "One Card Warehouse Configuration",
          }
        },
        itemWarehouse: {
          field: {
            warehouseCodeAndName: "warehouseCodeAndName",
            warehouseName: "warehouseName",
            warehouseCode: "warehouseCode",
            stockInOut: "stockInOut",
            stockDataSource: "stockDataSource",
            stockDcs: "stockDcs",
            remark: "remark",
            isEnable: "isEnable",
            itemWarehouseHeadTitle: "itemWarehouseHeadTitle",
          },
          title: {
            indexHeadTitle: "material warehouse",
          }
        },
        extItemCate: {
          field: {
            headTitle: "ERP Material Management",
            headCatTitle: "ERP material classification",
            pleaseInput: "pleaseInput",
            parentCode: "parentCode",
            parentName: "parentName",
            extItemName: "extItemName",
            extItemCode: "extItemCode",
            extItemCatCode: "extItemCatCode",
            extItemCatName: "extItemCatName",
            isEnable: "isEnable",
            remark: "remark",
            itemCateId: "itemCateId",
            unitCode: "unitCode",
            unitName: "unitName",
            hasChildren: "hasChildren!",
            afterTip: {
              unitError: "Error in querying unit information！",
            }
          },
          title: {
            indexHeadTitle: "ERP Material Management",
            addHeadTitle: "ERP Material ManagementAdd",
            viewHeadTitle: "ERP Material ManagementView",
            editHeadTitle: "ERP Material ManagementEdit",
          },
        },
        deviceFaultCat: {
          field: {
            headTitle: "Shutdown Reason Management",
            pleaseInput: "Please Input",
            parentCode: "parentCode",
            deviceFaultDetailName: "deviceFaultDetailName",
            deviceFaultDetailCode: "deviceFaultDetailCode",
            deviceFaultCatCode: "deviceFaultCatCode",
            deviceFaultCatName: "deviceFaultCatName",
            isEnable: "isEnable",
            remark: "remark",
            hasChildren: "hasChildren",
            afterTip: {
              unitError: "unitError",
            }
          },
          title: {
            indexHeadTitle: "Shutdown Reason",
            addHeadTitle: "Shutdown Reason Add",
            viewHeadTitle: "Shutdown Reason View",
            editHeadTitle: "Shutdown Reason Edit",
            headCatTitle: "Shutdown Reason Type",
          },
        },
        customer: {
          field: {
            customerName: "customerName",
            customerCode: "customerCode",
            address: "address",
            contactPerson: "contactPerson",
            tel: "tel",
            isEnable: "isEnable",
            remark: "remark",
            tip_determine_del: "Netty confirms to delete the selected data?",
          },
          title: {
            indexHeadTitle: "custom",
            headTitle: "custom",
            addHeadTitle: "customAdd",
            viewHeadTitle: "customView",
            editHeadTitle: "customEdit",
          },
        },
        item: {
          field: {
            cateHeadTitle: "Production Material Classification",
            parentCode: "parentCode",
            parentName: "parentName",
            labelText: "labelText",
            superiorName: "superiorName",
            list: "list",
            name: "name",
            code: "code",
            norms: "norms",
            model: "model",
            charNo: "charNo",
            unit: "unit",
            uname: "uname",
            idCard: "idCard",
            black_name: "black_name",
            black_name_remark: "black_name_remark",
            phone: "phone",
            wechat: "wechat",
            itemName: "itemName",
            itemCode: "itemCode",
            itemCatId: "itemCatId",
            itemCatCode: "itemCatCode",
            itemCatName: "itemCatName",
            processName: "processName",
            processCode: "processCode",
            isEnable: "isEnable",
            isProduct: "isProduct",
            remark: "remark",
            itemCateId: "itemCateId",
            unitCode: "unitCode",
            unitName: "unitName",
          },
          title: {
            indexHeadTitle: "Production materials",
            addHeadTitle: "Production materialsAdd",
            viewHeadTitle: "Production materialsView",
            editHeadTitle: "Production materialsEdit"
          },
          msg: {
            itemErr: "Material cannot be duplicate, please reselect!",
          }
        },
      },
      qm: {
        dispatch: {
          title: {
            indexHeadTitle: 'Quality dispatch Form',
            headTitleEdit: 'Quality dispatch Form Edit',
            headTitleDetail: 'Quality dispatch Form Detail',
            receive: 'receive',
            hedaTitleAdd: 'Quality dispatch Form Add',
            headTitleSubTitle: 'Dispatch Form Detail',
            selectItem: 'Select Item',
            copyTitle:'Confirm to copy this notification',
          },
          tip: 'Please select the data in preparation',
          tipRequired: 'Please fill in the required information',
          columns: {
            dispatchCode: 'dispatchCode',
            startSendTimeQuery: 'sendTimeQueryStart',
            endSendTimeQuery: 'sendTimeQueryEnd',
            startAssertTimeQuery: 'assertTimeQueryStart',
            endAssertTimeQuery: 'assertTimeQueryEnd',
            dispatchStatus: 'dispatchStatus',
            sendTime: 'send Time',
            assertTime: 'assert Time',
            sendName: 'send Name',
            reviceDept: 'revice Dept',
            reviceName: 'revice Name',
            confirmTime: 'confirm Time',
            Automatically: 'Automatically generate after saving',
            SchedulingContent: 'Scheduling Content',
          },
          discharge: {
            extItemName: ' Material name ',
            dischargePosition: 'Discharge position ',
            controlExplain: ' Control Description'
          },
          factoryMixRatio: {
            headTitle: 'Product indicator Control ',
            itemName: ' Product ',
            outboundProportion: ' Outbound proportion ',
            controlInstruct: ' Control Description ',
          },
          incomingMaterials: {
            supplierName: ' Supplier ',
            timeStr: ' Entry time ',
            batchCode: ' Batch number ',
            totalWeight: ' incoming volume '
          },
          MaterialRatio: {
            chargeRatio:'Charge Ratio',
            itemName: 'raw material',
            measuringScale:'Measuring Scale',
            feedingproportion: 'proportion',
            feedingInstruct: 'Feeding instructions'
          },
          qualityControl: {
            productIndex: 'Product Indicator Control ',
            indexName: ' Indicator name ',
            indexCode: ' Indicator code ',
            teamName: ' Quality Control team ',
            indexClass: ' index classification ',
            conditionValue: ' condition ',
            targetValue: ' target value ',
            floatingRange: ' float range ',
            controlInstruct: ' Control Description ',
            deviceCode: ' Device number ',
            itemCode: ' Production material ',
            storageLocation: ' storage location '
          }
        },
        dispatchModel: {
          title: {
            indexHeadTitle: 'Dispatch Form Template',
            hedaTitleAdd: 'Adding a dispatch order template',
            headTitleEdit: 'Schedule template editing',
            headTitleDetail: 'Schedule template details',
          },
          columns: {
            modelName: 'Template name',
            modelCode: 'Template Code',
            dispatchType: 'Dispatch order type',
            updateBy: "Last modified Time",
            basicInfo: 'Basic information',
            detailInfo: 'Details',
            materialRatio: 'Material ratio'
          }
        },
        param: {
          field: {
            indexParamCode: "indexParamCode",
            indexParamName: "indexParamName",
            indexDataType: "indexDataType",
            indexDecimalDigit: "indexDecimalDigit",
            unitCode: "unitCode",
            unitName: "unitName",
            isEnable: "isEnable",
            remark: "remark",
          },
          title: {
            indexHeadTitle: "Indicator parameters",
            title: "index formulas",
            addHeadTitle: "Indicator parametersAdd",
            editHeadTitle: "Indicator parametersEdit",
            viewHeadTitle: "Indicator parametersView",
          }
        },
        indexFormula: {
          field: {
            formulaCode: "formulaCode",
            formulaName: "formulaName",
            formulaDesc: "formulaDesc",
            remark: "remark",
            paramCode: "paramCode",
            paramName: "paramName",
            paramDefaultVal: "paramDefaultVal",
            formulaParam: "formulaParam",
            add: "add",
            indexCode: "indexCode",
            indexName: "indexName",
            indexFormulaCode: "indexFormulaCode",
            indexFormulaName: "indexFormulaName",
            indexFormulaDesc: "indexFormulaDesc",
            indexFormulaButton: "indexFormulaButton",
          },
          title: {
            indexHeadTitle: "index formulas",
            addHeadTitle: "index formulasAdd",
            editHeadTitle: "index formulasEdit",
            viewHeadTitle: "index formulasView",
          }
        },
        templateIndex: {
          field: {
            templateCode: "templateCode",
            templateName: "templateName",
            teamCode: "teamCode",
            teamName: "teamName",
            indexCode: "indexCode",
            indexName: "indexName",
            unitCode: "unitCode",
            unitName: "unitName",
            limitRange: "limitRange",
            sortNum: "sortNum",
            indexFormulaCode: "indexFormulaCode",
            indexFormulaName: "indexFormulaName",
            indexFormulaDesc: "indexFormulaDesc",
            remark: "remark",
            select_warning: "select_warning",
            afterTip: {
              defaultFormulaExist: "defaultFormulaExist",
            }
          },
          title: {
            indexHeadTitle: "Quality inspection template indicators",
            addHeadTitle: "Quality inspection template indicatorsAdd",
            editHeadTitle: "Quality inspection template indicatorsEdit",
            viewHeadTitle: "Quality inspection template indicatorsView",
          }
        },
        index: {
          field: {
            indexCode: "indexCode",
            indexName: "indexName",
            unitCode: "unitCode",
            unitName: "unitName",
            teamCode: "teamCode",
            indexColumnCode: 'indexColumnCode',
            teamName: "teamName",
            enableFormula: "enableFormula",
            isEnable: "isEnable",
            remark: "remark",
          },
          title: {
            headTitle: "project information",
            indexHeadTitle: "Quality inspection indicators",
            addHeadTitle: "Quality inspection indicatorsAdd",
            editHeadTitle: "Quality inspection indicatorsEdit",
            viewHeadTitle: "Quality inspection indicatorsView",
          }
        },
        origIndex: {
          field: {
            indexCode: "indexCode",
            indexName: "indexName",
            indexFormulaCode: "indexFormulaCode",
            indexFormulaName: "indexFormulaName",
            paramCode: "paramCode",
            paramName: "paramName",
            origDataType: "origDataType",
            remark: "remark",
          },
          title: {
            indexHeadTitle: "Template parameters",
            addHeadTitle: "Template parametersAdd",
            editHeadTitle: "Template parametersEdit",
            viewHeadTitle: "Template parametersView",
          }
        },
        qmTeamMember: {
          field: {
            teamCode: "teamCode",
            teamName: "teamName",
            userCode: "userCode",
            userName: "userName",
            isGroupLeader: "isGroupLeader",
            message: "Cannot select repeatedly",
          },
          title: {
            indexHeadTitle: "Quality control team",
            headTitle: "Quality Inspection Personnel List",
            teamIndexTitle: "Quality inspection indicators",
            teamMemberTitle: "Quality inspection personnel",
          }
        },
        qmTeam: {
          field: {
            teamCode: "teamCode",
            teamName: "teamName",
            parentName: "teamName",
            isEnable: "isEnable",
            remark: "remark",
            team: "Please select a group first",
            tip_determine_del: "This quality inspection group has associated information. Are you sure to delete this quality inspection group and associated information?",
          },
          title: {
            indexHeadTitle: "Quality inspection team",
            addHeadTitle: "Quality inspection teamAdd",
            editHeadTitle: "Quality inspection teamEdit",
            viewHeadTitle: "Quality inspection teamView",
            teamMemberTitle: "Quality inspection personnel",
          }
        },
        qmTeamIndex: {
          field: {
            teamCode: "teamCode",
            teamName: "teamName",
            indexCode: "indexCode",
            indexName: "indexName",
            unitCode: "unitCode",
            unitName: "unitName",
          },
          title: {
            indexHeadTitle: "List of quality inspection indicators",
            teamIndexTitle: "Quality inspection indicators",
          }
        },
        point: {
          field: {
            labelText: "Add sampling point classification",
            parentCode: "parentCode",
            parentName: "parentName",
            pointCode: "pointCode",
            pointName: "pointName",
            pointCatCode: "pointCatCode",
            pointCatName: "pointCatName",
            isEnable: "isEnable",
            remark: "remark",
            equipCode: "equipCode",
            equipName: "equipName",
            select_warning: "Please save the basic information first",
          },
          title: {
            indexHeadTitle: "Sampling point",
            pointClass: "pointClass",
            addHeadTitle: "Sampling pointAdd",
            editHeadTitle: "Sampling pointEdit",
            viewHeadTitle: "Sampling pointView",
          },
        },
        recordObj: {
          field: {
            recordObjCode: "recordObjCode",
            recordObjName: "recordObjName",
            qualityType: "qualityType",
            itemName: "itemName",
            templateName: "templateName",
            pointName: "pointName",
            pointCatName: "pointCatName",
            add: "add",
            sampleCodeRule: "sampleCodeRule",
            recordCodeRule: "recordCodeRule",
            batchRule: "batchRule",
            targetType: "targetType",
            packageFlag: "packageFlag",
            batchSplitName: "batchSplitName",
            batchCodeRule: "batchCodeRule",
            objIndex: "objIndex",
            standardType: "standardType",
            formulaName: "formulaName",
            isMajorIndex: "isMajorIndex",
            objIndexConfig: "objIndexConfig",
            splitSidesType: "splitSidesType",
            color: "color",
            copyRecordObj: 'copyRecordObj',
            isObjYesNo: "isNeedQualityIns",
          },
          title: {
            indexDispatch:'Index Dispatch',
            headTitle: "Production materials",
            indexHeadTitle: "Quality inspection object",
            addHeadTitle: "Quality inspection objectAdd",
            editHeadTitle: "Quality inspection objectEdit",
            viewHeadTitle: "Quality inspection objectView",
          },
          msg: {
            conflict: "Batch cutting method cannot be repeated!",
          }
        },
        itemIndex: {
          field: {
            recordCode: "recordCode",
            item: "item",
            itemCode: "itemCode",
            itemName: "itemName",
            indexCode: "indexCode",
            indexName: "indexName",
            teamCode: "teamCode",
            teamName: "teamName",
            unitName: "unitName",
            isVisiable:'isVisiable',
            itemIndexFormula: "itemIndexFormula",
            itemIndexStandard: "itemIndexStandard",
            isDefault: "isDefault",
            standardType: "standardType",
            standardContent: "standardContent",
            indexClass: "indexClass",
            standardTypeOnly: "standardTypeOnly",
            sortNum: 'sortNum',
            warnCfg: "warnCfg",
          },
          title: {
            indexHeadTitle: "Material indicator information",
          },
        },
        warnCfg: {
          field: {
            consecutiveNum: 'consecutiveNum',
            upperLimit: "upperLimit",
            lowerLimit: "lowerLimit",
            isLimit: "isLimit",
            qualifiedRange: 'qualifiedRange',
            warningScope: 'warningScope',
            isConsecutive: "isConsecutive",
            isDefault: "isDefault",
          },
        },

        batchSplit: {
          field: {
            baseInfo: "baseInfo",
            batchSplitCode: "batchSplitCode",
            batchSplitName: "batchSplitName",
            isQualityCfg: "isQualityCfg",
          },
          title: {
            indexHeadTitle: "Batch cutting rule settings",
            addHeadTitle: "Batch cutting rule settingsAdd",
            editHeadTitle: "Batch cutting rule settingsEdit",
            viewHeadTitle: "Batch cutting rule settingsView",
          }
        },
        batchSplitParam: {
          field: {
            modeType: "modeType",
            modeType2: "modeType2",
            param: "param",
            errorModeType: "Cycle (team hours), cut a batch every XX hours and cannot exceed 24",
            tip: {
              notNull: "notNull!",
              nonRepeatable: "nonRepeatable!"
            }
          },
          title: {
            title: "Batch cutting rule",
            title2: "Sampling rules",
          }
        },
        batch: {
          field: {
            baseInfo: "baseInfo",
            batchCode: "batchCode",
            qualityType: "qualityType",
            customerCode: "customerCode",
            customerName: "customerName",
            miningName: "miningName",
            itemCode: "itemCode",
            itemName: "itemName",
            extItemCode: "extItemCode",
            extItemName: "extItemName",
            sumWeight: "sumWeight",
            sumNum: "sumNum",
            sumSampleNum: "sumSampleNum",
            firstAcceptDate: "firstAcceptDate",
            lastAcceptDate: "lastAcceptDate",
            remark: "remark",
            status: "status",
          },
          title: {
            indexHeadTitle: "Batch Information",
            addHeadTitle: "Batch InformationAdd",
            editHeadTitle: "Batch InformationEdit",
            viewHeadTitle: "Batch InformationView",
            oldBatchCode: "Current batch code",
            newBatchCode: "New batch code",
            newBatchCodeBlank: "The new batch code cannot be empty",
            editBatchCode: "Edit batch code",
          }
        },
        sampleLine: {
          field: {
            bzCode: "一卡通编码",
            batchCode: "批次编号",
            sampleCode: "sampleCode",
            sampleBatchCode: "sampleBatchCode",
            carNo: "carNo",
            extItemName: "extItemName",
            extItemCode: "extItemCode",
          },
          title: {
            indexHeadTitle: "One Card Information",
          }
        },
        sample: {
          field: {
            deblockingDate: "releaseTime",
            processingTime: "processing time",
            sealStatus: 'sealStatus',
            sealDate: 'sealDate',
            itemCodeAndName: "itemCodeAndName",
            pointCodeAndName: "pointCodeAndName",
            sampleCode: "sampleCode",
            qualityType: "qualityType",
            batchDtlCode: "batchDtlCode",
            inspectionType: "inspectionType",
            recordObjCode: "recordObjCode",
            status: "status",
            sampleDate: "sampleDate",
            userName: "userName",
            sampleType: "sampleType",
            pointCode: "pointCode",
            pointName: "pointName",
            equipCode: "equipCode",
            equipName: "equipName",
            itemCode: "itemCode",
            isRecord: "isRecord",
            itemName: "itemName",
            remark: "remark",
            bzCode: "oneCardCode",
            batchCode: "batchCode",
            carNo: "carNo",
            extItemCode: "extItemCode",
            extItemName: "extItemName",
            customerCode: "customerCode",
            customerName: "customerName",
            recordObj: "recordObj",
            projectName: "projectName",
            periodicSpline: "periodicSpline",
            picture: "picture",
            proportionScheme: "proportion Scheme",
            materialCode: "material Code",
            rawMaterial: "material Name",
            ratio: "ratio",
            pictureType: "picture Type",
            literalness: "literalness",
            pictureUpload: "picture Upload",
            sumWeight: 'sumWeight',
            inputType:"Operational state",
            sampleAddress:"sampleAddress",
            sampleName:"sampleName",
            afterTip: {
              rawMaterial: "Please select the raw material",
              ratio: "Please select a ratio",
              sample: "Please save the sample information first",
              pictureUpload: "Please select an image to upload",
              literalness: "Please enter a text description",
              pictureType: "Please select an image type",
              qualityTypeExist: "Select the inspection type first",
              qmRecordObjUnExist: "The quality inspection object does not exist",
              qmRecordObjIndexUnExist: "Quality inspection indicator does not exist",
              qmRecordObjFormulaUnExist: "The quality inspection indicator formula does not exist",
              qmRecordObjStandardUnExist: "Indicator standard does not exist",
              isNotPeriodType: "The current quality inspection object is not a cycle sample",
              dataHasExist: "The quality inspection record has been generated",
              mdItemUnExist: "Production material does not exist",
            }
          },
          title: {
            save: "Save the information first",
            selectBzCode: "Please select the card information",
            selectRecordObj: "Select the inspection type",
            indexHeadTitle: "Sample Management",
            recordHeadTitle: "Quality inspection report sheet",
            sampleRecondTitle: "Sample Record",
            labelText: "Add sampling point classification",
            viewHeadTitle: "Sample Management View",
            addHeadTitle: "Sample Management Add",
            editHeadTitle: "Sample Management Edit",
            sampleRecondViewTitle: "Sample Record View",
            sampleRecondAddTitle: "Sample Record Add",
            sampleRecondEditTitle: "Sample Record Edit",
            oneCardInfoTitle: "One Card Information",
            ProportioningScheme: "ProportioningScheme",
            issue: " This status has been issued and cannot be changed",
            oneCardTitle: "One Card Materials",
          },
          btn: {
            nonQualityInspectionBtn: "nonQualityInspection",
            issuingQualityInspectionBtn: "issuingQualityInspection",
            reportedBtn: "filling",
            filledBtn: "examine and approve",
            auditedBtn: "print",
          },
          msg: {
            batchCodeOne: "The batch number must be consistent",
            customerName:"Merchant names need to be consistent",
            erpCodeOne:"erp materials need to be consistent",
            nonDraft: "Sample records containing non draft status cannot be deleted",
          },
        },
        record: {
          field: {
            device: "QI Equipment",
            time: "time",
            recordDate: "recordDate",
            recordStartDate: "recordStartDate",
            recordEndDate: "recordEndDate",
            equipCode: "equipCode",
            equipName: "equipName",
            batchCode: "batchCode",
            status: "status",
            operate: "operate",
            recordCode: "recordCode",
            sampleCode: "sampleCode",
            templateCode: "templateCode",
            sampleBatchCode: "sampleBatchCode",
            sampleType: "sampleType",
            samplePointCode: "samplePointCode",
            samplePointCatName: "samplePointCatName",
            itemCode: "itemCode",
            itemName: "itemName",
            pointName: "pointName",
            qualityType: "qualityType",
            recordObjCode: "recordObjCode",
            teamName: "teamName",
            remark: "remark",
            teamCode: "teamCode",
            toBeInspected: "toBeInspected",
            inProgress: "InProgress",
            submitted: "Submitted",
            recordIndex: 'recordIndex',
            picture: 'Quality inspection picture',
            recordStartYear: "Summary start time",
            recordEndYear: "Summary end time",
            recordEndHour: "Summary closing hour",
            recordEndAndrecordStart: "The start time or end time of the summary cannot be empty",
            recordEndAndrecordDate: "The start time or end time of the summary cannot be later than the time of the current inspection object",
            CollectRecordCode: "Quality inspection code",
            collect: "collect",
            sampleDate: "horizon",
            notCollect: "notCollect",
            tip: {
              noUpdate: "Quality inspection indicator information not modified!",
            }
          },
          title: {
            seal: "seal",
            handle: "处理",
            indexHeadTitle: "Quality inspection records",
            addHeadTitle: "Quality inspection recordsAdd",
            editHeadTitle: "Quality inspection recordsEdit",
            viewHeadTitle: "Quality inspection recordsView",
          },
          msg: {
            prompt: "Please click on the tree on the left to select a specific category",
          }
        },
        stockCheckLine: {
          field: {
            locationName: "locationName",
            formulaText: "formulaText",
            stockCheckQty: "stockCheckQty",
            cal: "cal",
            index: "index",
            code: "code",
            name: "name",
            defVal: "defVal",
            value: "value",
            paramCode: "paramCode",
            paramName: "paramName",
            paramValue: "paramValue",
          }
        },
        // 周期质检
        periodQualityTest: {
          field: {
            teamCode: "teamCode",
            teamName: "teamName",
          },
        },
        recordIndex: {
          field: {
            sampleCode: "sampleCode",
            indexCode: "indexCode",
            indexName: "indexName",
            indexValue: "indexValue",
            standardType: "standardType",
            standardContent: "standardContent",
            temIndexStandard: "temIndexStandard",
            indexAdjValue: "indexAdjValue",
            limitRange: "limitRange",
            isQualified: "isQualified",
            select_warning: "select_warning",
            indexFormulaDesc: "indexFormulaDesc",
            indexFormulaName: "indexFormulaName",
            indexValueCal: "indexValueCal",
            userName: "userName",
            updateTime: "updateTime",
          },
          title: {
            infoTitle: "Quality inspection indicator information",
            listTitle: "List of quality inspection indicators",
          }
        },
        device: {
          file: {
            deviceName: "Device name",
            deviceCode: "Device code",
            deviceInfo: "Remark"
          },
          title: {
            deviceTitle: "Quality inspection equipment",
            add: "Quality inspection equipment add",
            edit: "Quality inspection equipment edit"
          }
        },
        recordV2: {
          tip: {
            del_success: "Successfully deleted",
            back_success: "Successfully returned",
            submit_success: "Submitted successfully",
            save_success: "Successfully saved",
            no_update: "The quality inspection record has not been modified!",
          },
          confirm: {
            del: "Delete quality inspection records?",
            back: "Whether to return the quality inspection record?",
            submit: "Whether to submit quality inspection records?",
          }
        },
        stockQyt: {
          file: {
            itemCode: "itemCode",
            itemName: "itemName",
            unitCode: "unitCode",
            unitName: "unitName",
            warehouseCode: "warehouseCode",
            warehouseName: "warehouseName",
            stockQty: "stockQty",
          },
          title: {
            title: "Warehouse Information",
            headTitle: "Quality inspection equipment",
            headListTitle: "Newly added quality inspection equipment",
          }
        },
        template: {
          field: {
            templateCode: "templateCode",
            templateName: "templateName",
            itemCode: "itemCode",
            itemName: "itemName",
            isEnable: "isEnable",
            remark: "remark",
            isShi: "isShi",
            isFou: "isFou"
          },
          title: {
            indexHeadTitle: "Quality inspection template",
          }
        },
        arrivalLine: {
          field: {
            itemCode: "itemCode",
            itemName: "itemName",
            norms: "norms",
            model: "model",
            chartNo: "chartNo",
            unit: "unit",
            deliveryQuantity: "deliveryQuantity",
            arrivalQuantity: "arrivalQuantity",
            batchNo: "batchNo",
            arrivalDate: "arrivalDate",
            stockOrgName: "stockOrgName",
            warehouseName: "warehouseName",
            locationName: "locationName",
            poundNo: "poundNo",
            tareWeight: "tareWeight",
            grossWeight: "grossWeight",
            originPlace: "originPlace",
            carNo: "carNo",
          },
        },
      }
    }
  },
}
