import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
/** 证照管理- 职员 */
/** 头表接口 */
export const employeesHeadPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/grcertificatecontroluserhead/page`,
    method: 'get',
    params: {
      current,
      size,
      ...params
    }
  })
}

export const employeesHeadSubmit = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/grcertificatecontroluserhead/submit`,
    method: 'post',
    data: params
  })
}

export const employeesHeadDetail = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/grcertificatecontroluserhead/detail`,
    method: 'get',
    params
  })
}
export const employeesHeadUserId = (id,type) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/grcertificatecontroluserhead/getUserId?id=` + id+"&type="+type,
    method: 'post',
  })
}
// 批量新增/修改
export const employeesHeadSubmitBatch = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/grcertificatecontroluserhead/submitBatch`,
    method: 'post',
    data: params
  })
}

/** 行表 */
export const employeesLineList = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/grcertificatecontroluserline/list`,
    method: 'get',
    params
  })
}

export const employeesLineSubmit = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/grcertificatecontroluserline/submit`,
    method: 'post',
    data: params
  })
}

export const employeesLineRemove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/grcertificatecontroluserline/remove`,
    method: 'post',
    params: {ids}
  })
}

/** 获取字典 */
export const getDict = (code) => {
  return request({
    url: `/api/sinoma-system/dict-biz/dictionary?code=${code}`,
    method: 'get'
  })
}
