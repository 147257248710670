//数据中台中文
export default {
    datacenter: {
        btn:{
            next: "下一步",
            prev: "上一步"
        },
        form:{
            notEmpty:"不能为空"
        },
        tips:{
            del: "选中数据将被永久删除, 是否继续？",
            copy: "确认拷贝当前接口, 是否继续？",
            copySuccess: "拷贝成功",
            apiPublishedSuccess: "接口发布成功",
            apiLogoutSuccess:"接口注销成功",
            connectionSuccess:"连接成功",
            saveSuccess:"保存成功",
            saveError:"保存失败",
            saving:"保存中...",
            parsedSuccess:"解析成功，请进行下一步"
        },
        //数据质量
        dataQuality: {
            index: '序号',
            programName: "方案名称",
            status: "状态",
            //监测任务
            monitoringTask: {
                title: "质量监测任务",
                remark: "备注",
                implement: "执行",
                pause: "暂停",
                label: "标签",
                cycleSetting: "周期设置",
                select:"选择",
                selectRuleTem: "选择规则模版",
                selectDataObj: "选择数据对象",
                selectJudgeMethod: "选择判定方法",
                accuracyVerif: "准确性验证",
                consistencyVerif: "一致性验证",
                timelinessVerif: "及时性验证",
                testNum: "测点数据值",
                tooBig: "过大",
                tooSmall: "过小",
                b1: "断采检测",
                b2: "DCS测点数据断采",
                b3: "能源/一卡通等业务数据断采",
                c1: "小时产量数据之和与日产量数据比对",
                c2: "数据中台的指标值与生产管理的指标数值比对",
                conditionName: "条件名称",
                alarmLevel: "告警等级",
                setRule: "设置规则",
                isExecute: "是否立即执行",
                executeImme: "立即执行",
                manualExecution: "手动执行",
                addTestParams: "添加检测参数",
                judgmentType: "判定类型",
                dataComparison: "数据对比",
                outlierJudgment: "异常值判断",
                intervalRangeValue: "区间范围值",
                low: "低",
                middle: "中",
                high: "高",
                numericalJudgment: "数值判定",
                intervalJudgment: "区间判定",
                inProgress: "执行中",
                paused: "已暂停",
                confirmExecution: "确定要执行当前方案？",
                confirmSuspension: "确定要暂停当前方案？",
                updateSuccessTip: "状态修改成功",
                programExecutionDate: "方案执行日期"
            },
            //监测记录
            monitoringRecord: {
                title: "质量监测记录",
                templateName: "模版名称",
                executeBeginTime: "方案执行开始时间",
                executeEndTime: "方案执行结束时间",
                executionDetails: "执行详情",
                ruleName: "规则名称",
                dataObjectLabel: "数据对象标识",
                judgMethodName: "判定方法名称",
                executeResult: "判定方法执行结果",
                executeStatus: "判定方法执行状态",
                originalDataDetail: "数据对象原始数据"
            }
        },
        //数据源
        datasource:{
            title:"数据源管理",
            name: "数据源名称",
            refreshCache:"刷新缓存",
            syncStatus:"同步状态",
            status:"状态",
            createTime: '创建时间',
            refreshSuccess:"刷新缓存成功",
            refreshFailed:"刷新缓存失败",
            notSync:"未同步",
            synchronizing: "同步中",
            synced:"已同步",
            sjyxx:"数据源信息",
            ljxx:"连接信息",
            datasourceType:"数据源类型",
            remark: "备注",
            host:"主机",
            port:"端口",
            sid:"服务名",
            dbName:"数据库",
            username:"用户名",
            password:"密码",
            ltxcs:"连通性检测",
            sjyxz:"数据源新增",
            sjybj:"数据源编辑",
            ysjtb:"元数据同步",
            sjyxq:"数据源详情",
            ysjtbTip:"元数据正在后台同步中，请到元数据管理中查看结果",
            fileName:"数据库设计文档.doc"
        },
        //元数据
        datacolumn: {
            title:"元数据管理",
            fieldName: "字段名称",
            load:"加载中，请稍后",
            fieldComment:"字段注释",
            isPrimaryKey: "是否主键",
            isEmpty:"是否允许为空",
            dataType:"数据类型",
            dataLength:"数据长度",
            dataPrecision:"数据精度",
            dataScale:"数据小数位",
            dataDefault:"数据默认值",
            sourceName:"数据源",
            tableName:"数据库表",
            updateField:"变更字段",
            version:"版本号",
            fieldOldValue:"原来的值",
            fieldNewValue:"最新的值",
            status:"状态",
            remark: "备注",
            ysjbg:"元数据变更"
        },
        //数据服务接口
        dataapi: {
            title:"数据服务接口",
            apiName:"API名称",
            apiVersion: "API版本",
            apiUrl:"API路径",
            reqMethod:"请求类型",
            resType:"返回格式",
            status:"状态",
            createTime: '创建时间',
            attrConfig:"属性配置",
            executeConfig:"执行配置",
            paramsConfig:"参数配置",
            deny:"IP黑名单",
            rateLimit:"是否限流",
            limiting:"限流配置",
            every:"每",
            second:"秒内限制请求",
            times:"次",
            remark: "备注",
            configType:"配置方式",
            sourceName:"数据源",
            fieldList:"字段列表",
            columnName:"列名",
            columnDataDefault:"列默认值",
            columnComment:"列注释",
            reqable:"是否作为请求参数",
            resable:"是否作为返回参数",
            sqlParsing:"SQL解析",
            reqParams:"请求参数",
            paramName:"参数名称",
            nullable:"是否允许为空",
            paramComment:"描述",
            paramType:"参数类型",
            whereType:"操作符",
            exampleValue:"示例值",
            defaultValue:"默认值",
            returnField:"返回字段",
            fieldComment:"字段描述",
            apiAdd:"数据API新增",
            apiDetail:"数据API详情",
            apiEdit:"数据API编辑",
            denyPlaceholder:"请输入IP黑名单多个用英文,隔开",
            apiDocument:"接口文档",
            apiExample:"接口示例",
            fileName:"接口文档.doc",
            interfaceCall:"接口调用",
            reqHead:"请求头",
            reqData:"请求数据",
            KEY:"键",
            VALUE:"值",
            DESCRIPTION:"描述",
            noData:"暂无数据",
            paramValue:"参数值"
        }
    },
  //项目列表
  projectlist: {
    name: "项目列表",
    listedit: {
      dispatch: "调度",
      see: "查看",
      release: "发布",
      TaskManagement: "任务管理",
      edit: "编辑",
      delete: "删除"
    },
    seetabs: {
      Joblog: "作业日志",
      flowChart: "流程图",
      taskList: "任务列表",
      ProcessLog: "流程日志",
      InformationService: "信息查询",
      taskListlabel: {
        name: "名称",
        type: "类型",
        startTime: "开始时间",
        Endtime: "结束时间",
        timeConsuming: "耗时",
        state: "状态",
        details: "详情",
      },
      editlabel: {
        Englishname: "英文名称",
        Chinesename: "中文名称",
        Contentdescription: "内容描述",
        EditItem: "编辑项目",
        NewProject: "新建项目"
      },
      addrule: {
        ename: "请输入英文名称",
        cname: "请输入中文名称",
        text: "请输入内容描述"
      },
    },
    msg: {
      msg1: "是否删除这个任务？",
      msg2: "依次运行本实验的每个组件",
      msg3: "停止运行试验",
    },
    btn: {
      deploy: "部署",
      run: "运行",
      stop:"停止",
      Taskbuilding: "任务构建",
      name: "名称",
      save: "保存",
      DocumentContent: "文件内容",
      Navigator: "导航器",
      ParameterSetting: "参数设置",
      back: "返回",
      sure: "确定",
      close: "关闭",
      canvas: "画布",
      Gridalignment: "网格对齐",
      revoke: "撤销",
      redo: "重做",
      delete: "删除",
      enlarge: "放大",
      narrow: "缩小",
      Fittocanvas: "适应画布",
      Actualsize: "实际尺寸",
      Postlevel: "层级后置",
      Levelleading: "层级前置",
      Multiplechoice: "多选",
      Group: "成组",
      Ungroup: "解组",


    }
  },
  //数据血缘
  Primordial:{
      name:'数据血缘',
      tabData:{
        number:'编号',
        tableName:"表名",
        nodeName:'数据源名称',
        remarks:"备注",
        operate:'操作',
        addRelationship:'添加关系'

      },
      form:{
        tip:'提示',
        deleteOrNot:'是否删除',
        NewNode:"新增节点",
        editNode:"编辑节点"
      },
      fun:{
        submission:'没有血缘关系的数据提交后将被删除, 是否继续?',
        successfully:'提交成功',
        Canceled:'已取消',
        ConfirmClose:'确认关闭',
        bloodAdd:'只能在血缘节点上新增',
        Parent:'父级',
        TreeDirectory:'树目录',
        relationship:"数据血缘下不能新建目录和血缘!",
        deleteTheDetails:'数据血缘请在右侧详情编辑和删除!',
        directoryToModify:"请选择要修改的目录"


      }

  },
  //任务调度
  taskScheduling:{
    name:"任务调度",
    tabs:{
        Projectlist:"项目列表",
  Historylist:"历史列表",
  Scheduledtasklist:"定时任务列表",
  Runninglist:"正在运行列表",
      },
    btn:{
        Scheduledscheduling:"定时调度",
        dispatch:"调度",
        see:"查看",
        kill:"kill",
    },
    msg:{
        msg1:"调度成功",
        msg2:"是否删除",
        msg3:"是否kill这个任务"
    },
    title:{
        Tips:"提示",
        InformationService:"信息查询",
        flowchart:"流程图",
        tasklist:"任务列表",
        Processlog:"流程日志",
        Joblog:"作业日志",
    },
    status:{
      success:"成功",
      fail:"失败",
      Running:"正在运行",
    },

    label1:{
        name:"名称",
        Chinesename:"中文名称",
        describe:"描述",
        Lastmodificationtime:"最后修改时间",
        Modifiedby:"修改人",
        operation:"操作",
        type:"类型",
    },
    label2:{
        Executionid:"执行id",
        flow:"流量",
        engineering:"工程",
        user:"用户",
        starttime:"开始时间",
        Endtime:"结束时间",
        timeconsuming:"耗时",
        state:"状态",
    },
    label3:{
        ID:"ID",
        Taskname:"任务名称",
        project:"项目",
        Firstscheduledrun:"第一次计划运行",
        Nextexecutiontime:"下次执行时间",
        expression:"表达式",
        operation:"操作",
    },
    label4:{
        ID:"ID",
        ExecutorID:"执行人ID",
        flow:"流量",
        engineering:"工程",
        user:"用户",
        jurisdiction:"权限",
        starttime:"开始时间",
        Endtime:"结束时间",
        Losstime:"流失时间",
        state:"状态",
        operation:"操作",
    },

  },
   //数据主题
   dataSubject:{
    name:"数据主题",
    addForm:{
      name:"名称",
      Englishname:"英文名称",
      Parent:"父级",
    },
    msg:{
      Tips:"提示",
      msg1:"确定删除, 是否继续?",
      msg2:"删除成功",
    },
    rules:{
      name:"请输入名称",
      ename:"请输入英文名称"
    },
    btn:{
      AddChild:"添加子项",
    }
  },
  //数据目录
  DataDirectory: {
    name: "数据目录",
    btn: {
      Description: "描述",
      BrowseData: "浏览数据",
    },
    label: {
      Dataname: "数据名称",
      datadescription: "数据描述",
      Datadirectory: "数据目录",
      datatype: "数据类型",
      metadata: "元数据",
      Columnname: "列名",
      explain: "说明",
    },
    addForm: {
      Datasource: "数据源",
      Tablename: "表名",
      name: "名称",
      catalogue: "目录",
      explain: "说明",
    },

    rules: {
      rules1: "请选择数据表名Please select a data table name",
      rules2: "请选择数据源Please select a data source",
    },
    msg: {
      msg1: "操作成功！",
      msg2: "请选择左侧数据",
      msg3: "保存成功",
    },
  },
  //数据模型
  datamodel: {
    name: "数据模型",
    label: {
      ChineseNameOfData: "数据中文名称",
      Dataname: "数据名称",
      datatype: "数据类型",
      Creationtime: "创建时间",
      form: '表',
      relationship: '关系',
      Fieldname: '字段名',
      FieldName: "字段名称",
      Chinesename: '中文名',
      type: '类型',
      length: '长度',
      accuracy: '精度',
      Primarykeyornot: '是否主键',
      Isitempty: '是否为空',
      Currenttablename: "当前表名",
      Associatedtable: "关联表",
      Associatedfield: "关联字段",
      is: "是",
      no: "否",
    },
    addForm: {
      newtable: "新建表",
      Modelname: "模型名称",
      datatype: "数据类型",
      datasource: "数据源",
      Pleaseselect: "请选择",
      Tablename: "表名",
      notes: "注释",
    },
    rules: {
      rules1: "请输入表名",
      rules2: "请选择数据源",
      rules3: "请输入注释",
    },
    msg: {
      msg1: "编辑成功",
      msg2: "保存成功",

     }
   },
  shunt:{
      name:"数据分流",
      tip:'提示',
    confirmButtonText:'确定',
    cancelButtonText:'取消',
      LoadingDesperately:'拼命加载中',
    table:{
      ShuntCoding:'分流编码',
      InterfaceCode:'接口编码',
      Transcoding:'转换编码',
      sfqy:'是否启用',
      zxtj:'执行条件',
      describe:'描述',
      conditionsForExecution:'执行条件',
      isEnable:'是否启用',
      startUsing:'启用',
      forbidden:'禁用',
      address:'操作',
      edit:"编辑",
      yes:'是',
      no:'否'
    },
    placeholder:{
        diversionCode:'请输入分流编码/接口编码/转换编码/描述',
      interfaceCode:'请输入接口编码',
      conversionCode:'请输入转换编码',
      description:'请输入描述',
      executionConditions:'请输入执行条件',
      diversionEditing:'数据分流编辑',
      diversionAdd:'数据分流添加'
      },
    fun:{
        isDelect:'确定删除, 是否继续?',
        DeleteSucceeded:'删除成功',
      ModifiedSuccessfully:'修改成功',
      SuccessfullyAdded:'新增成功',

    }
  },
  transformation:{
      name:'数据转换',
    tip:'提示',
    confirmButtonText:'确定',
    cancelButtonText:'取消',
    placeholder:"请输入数据接口/字段名称",
    LoadingDesperately:'拼命加载中',
    table:{
      infCode:'数据接口',
      fieldName:"字段名称",
      targetTable:'目标表名称',
      srcField:'来源字段',
      targetField:"目标字段",
      unitCode:"单位转换编码",
      mapCode:'编码映射',
      groupNo:'分组',
      defaultValue:"默认值",
      dimension:'维度',
      dimensionSed:'维度标识',
      edit:"编辑",
      aggregationCode:'请选择汇聚编码',
      targetTagName:'请输入目标标名',
      sourceField:"请输入来源字段",
      PleasetargetField:'请输入目标字段',
      conversionEditing:'数据转换编辑',
      DataConversionAdd:'数据转换添加'

    },
    fun:{
      isDelect:'确定删除, 是否继续?',
      DeleteSucceeded:'删除成功',
      ModifiedSuccessfully:'修改成功',
      SuccessfullyAdded:'新增成功',

    }

  },
  injection:{
        tip:'提示',
      confirmButtonText:'确定',
      cancelButtonText:'取消',
      name:"数据注入配置",
      placeholder:"请输入目标表名称",
    table:{
        loading:"拼命加载中",
        tablename:'目标表名称',
        keycolumn:'主键拼接',
      address:'操作',
      edit:"编辑",
    },
    form:{
      tablename:'目标表名称:',
      aggregationCode:'请输入汇聚编码',
      keycolumn:'主键拼接:',
      groupingField:'请输入分组字段'
    },
    fun:{
      PleaseSelect:'请选择删除数据',
      ModifiedSuccessfully:'修改成功',
      SuccessfullyAdded:'新增成功',
      isDelect:'确定删除, 是否继续?',
      DeleteSucceeded:'删除成功',
      configurationEditing:'数据注入配置编辑',
      configurationAddition:'数据注入配置添加'
    }


  },

  //Ai算法
  AiSuanfa:{
    title:'算法管理',
    btn:'全部',
    label:'算法名称',
    search:'搜索',
    itemBtn:'训练模型',
    sfFl:'算法分类',
    btnSave:'确定',
    btnCen:'取消',
    gf:'官方',
    btns:{
      yuce:'预测',
    },
    form:{
      name:'算法名称',
      dbtype:'算法归属',
      source:'算法分类',
      describe:'算法描述',
      file:'选择文件'
    },
    detail:{
      xl:'训练',
      placeholder:'输入数据',
      modeName:'模型名称',
      modePath:'模型路径',
      AlgorithName:'算法名称',
      x:'x轴',
      y:'y 轴',
      x1:'请选择X轴特性',
      y1:'请选择y轴特性',
      zh:'中文',
      field:'字段',
      dataType:'数据类型',
      mxpg:'模型评估',
      ycjg:'预测结果'
    },

  },
  AiModel: {
    placeholder: '请输入接口名称',
    name: '模型列表',
    label: {
      id: '模型ID',
      model_name: '模型名称',
      algorithm_name: '算法名称',
      model_input: '模型输入',
      model_path: '模型路径',
      model_evaluation: '模型评估',
    }
  },
  AiResult: {
    placeholder: '请输入模型名称',
    name: '预测结果',
    label: {
      predictId: '预测ID',
      modelId: "模型Id",
      predict_name: '预测标识',
      algorithm_name: '算法名称',
      model_name: '模型名称',
    },
    title:'model Detail',
  },
  trainingModel: {
    label: {
      msg3: "请输入表名",
    }
  },

   //全量接口采集
   FullInterfaceAcquisition:{
    name:"全量接口采集",
    name2:"全量接口采集新增",
    label:{
      InterfaceName:"接口名称",
      InterfaceAddress:"接口地址",
      RequestMethod:"请求方式",
      ReturnStatusCode:"返回状态码",
      ReturnStatusValue:"返回状态值",
      PrimaryKeyName:"主键名称",
      ResultConfiguration:"结果配置",
      InterfaceCode:"接口编码",
      TopicConfiguration:"topic配置",
      SystemSourceID:"系统来源ID",
      ForeignKeyCode:"外键code",
      CreationTime:"创建时间",
      ModificationTime:"修改时间",
      InterfaceDescription:"接口功能描述",
    },
    msg:{
      msg1:"请求参数",
      msg2:"请选择类型",
    }
   },
   //离线数据采集
   OfflinedataCollection:{
     name:"离线数据采集",
     label:{
      step:"步骤",
      structure:"构建",
      FieldMapping:"字段映射",
      Datasynchronization:"数据同步",
      Databasename:"数据库名称",
      Datatablename:"数据表名称",
      SQLstatement:"SQL语句",
      Resolvefield:"解析字段",
      Shardfield:"切分字段",
      TableAllFields:"表所有字段",
      SelectAll:"全选",
      Wherecondition:"where条件",
      PrecedingSQLstatement:"前置SQL语句",
      Sourcefield:"源端字段",
      Targetfield:"目标字段",
      Startsynchronization:"开始同步",
      start:"开始",
      fill:"填充",
      Previousstep:"上一步",
      nextstep:"下一步",
     }
   },
  //增量接口采集
  zljkcj: {
    name: "增量接口采集",
    name2: "新增增量接口采集",
    label: {
      InterfaceCode: "接口编码",
      InterfaceName: "接口名称",
      InterfaceAddress: "接口地址",
      RequestMethod: "请求方式",
      AccessMode: "接入方式",
      dataType: "数据类型",
      StartTimeField: "开始时间字段",
      EndTimeField: "结束时间字段",
      IncrementalTimestamp: "增量时间戳",
      IncreaseStepLengthTime: "加步长时间",
      IncrementalTimeType: "增量时间类型",
      IncrementalTimeTypeFormat: "增量时间类型格式化",
      StepLengthdays: "步长(天)",
      ReturnDataItem: "返回数据项",
      ReturnStatusCode: "返回状态码",
      ReturnDescription: "返回描述",
      PrimarykeyField: "主键字段",
      SourceSystem: "来源系统",
      TokenField: "token字段",
      SourceDatabaseInformation: "来源数据库信息",
    },
    addForm: {
      RequestHeader: "请求头",
      AddRequestHeader: "添加请求头",
      TimeTypeFormat: "时间类型格式",
      increment: "增量",
      Addsteptimestamp: '加步长时间戳',
      Interfaceconfigurationinformation: "接口配置信息",
      Endtimefield: "结束时间字段",
      Updatetimefield: "更新时间字段",
      Timetype: "时间类型",
      Timestamptype: "时间戳类型",
      Maximumtimestamp: "最大时间戳",
      Sourcedatabase: "来源数据库",
      constant: "常量",
      parameter: "参数",
      Requestor: "请求体",
      type: "类型",
      startTime: '开始时间',
      endTime: "结束时间",
      Updatetime: "更新时间",


    },
    msg: {
      msg1: "请输入接口编码/接口名称"
    }
  },
    //数据字典
    dataDictionary:{
      name:"数据字典",
      name2:"数据字典新增",
      label:{
        code:"编码",
        name:"名称",
        operate:"操作"
      },
      addForm:{
        FieldCode:"字段编码",
        DictionaryName:"字典名称",
        DictionaryType:"字典类型",
        Datasourcecode:"数据源编码",
        Datasourcename:"数据源名称",
        Databasename:"数据库名称",
        list:"列表",
        tree:"树"
      },
      rules:{
        rules1:"请输入数据源编码",
        rules2:"请输入数据源类型",
        rules3:"请输入数据库名称",
        rules4:"请输入数据源连接配置",
        rules5:"请选择类型",
      },
      msg:{
        msg1:"请选择删除数据"
      }
     },
  //数据汇聚
  DataAggregation:{
    name:"数据汇聚",
    msg:"保存成功",
    btn:{
      add:"新增",
      remove:"删除",
      edit:"编辑",

    },
    tabs:{
      DataAggregationGrouping:"数据汇聚分组",
      DataAggregationconfiguration:"数据汇聚配置",
      DataAggregationoutput:"数据汇聚输出",
    },
    addtitle:{
      AddDataAggregationGrouping:"新增数据汇聚分组",
      AddDataAggregationconfiguration:"新增数据汇聚配置",
      AddDataAggregationoutput:"新增数据汇聚输出",
    },
    table1:{
    AggregationCoding:"汇聚编码",
     Groupfields:"分组字段",
     remarks:"备注",
     operation:"操作",
    },
    table2:{
      Convergencetime:"汇聚时间",
Convergencedimension:"汇聚维度",
Aggregationsourcetable:"汇聚源表",
Taskgrouping:"任务分组",
Executionorder:"执行顺序",
    },
    table3:{
      Aggregateflag:"聚合标志",
Sourcefield:"源字段",
Targetfield:"目标字段",
Targettable:"目标表",
    }


  },

  //Token刷新配置
  Token:{
    msg:"保存成功",
    name:"Token刷新配置",
    Addname:"Token刷新配置新增",
    table:{
Interfacecode:"接口编码",
Url:"地址",
Requestinformation:"请求信息",
Requestmethod:"请求方式",
Splicinginformation:"拼接信息",
Fieldname:"字段名称",
Datasourceid:"数据源id",
Tokensourceid:"Token来源id",
operation:"操作",
Requestheader:"请求头",
Addrequestheader:"添加请求头",
edit:"编辑",
Interfaceaddress:"接口地址",
    },



  },
//遗漏de 
  omit:{
    Normal:"正常",
    anomaly:"异常",
    executioning:"执行中",
    paused:"已暂停",
    firing:"启动",
    scheduledSuccess:"调度成功",
    hours:"小时",
    minutes:"分钟",
    seconds:"秒",
    reqUrl:"请求地址",
    successmsg:"操作成功",
    dataInput:"数据输入",
    dataOutput:"数据输出",
    Computing:"计算组件",
    dataobj:"数据对象",
    dataSheet:"数据表",
    variance:"方差",
    Boundarylimitation:"边界限定",
    Topicclassification:"主题分类",
    database:"数据库",
    apiinterface:"API接口",
    index:"指标",
    queryCriteria:"查询条件",
    select:"请选择",
    type:"类型",
    start:"开始",
    end:"结束",
    input:"请输入值",
  }


}




