import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';


export const wtLimitSave = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseLimit/saveOrUpdate',
    method: 'post',
    data
  })
}

export const Detail = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseLimit/detail',
    method: 'get',
    params:{
      id:data
    }
  })
}
export const retransmission = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseLimit/retransmission',
    method: 'post',
    data
  })
}
export const removeWtSecurityPrevention = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtSecurityPrevention/remove?ids=' + data,
    method: 'post',
  })
}
export const removeWtLimitGas = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtLimitGas/remove?ids=' + data,
    method: 'post',
  })
}
