// /api/sinoma-hse-prj/dbtbeforeclass/page?current=1&size=5&createDept=1722789594114084866 
import request from '@/router/axios';

//获取班前会list
export const getPreClassList = (params) => {
    return request({
      url: '/api/sinoma-hse-prj/dbtbeforeclass/page',
      method: 'get',
      params:{
        ...params
      }
    })
}
// 获取项目统计信息 4，5，7，8
export const getProjectStatistical = (params) => {
    return request({
      url: '/api/sinoma-hse-prj/accrecord/getProjectStatistical',
      method: 'get',
      params:{
        ...params
      }
    })
}
// 获取安全培训率
export const getStatis = (params) => {
    return request({
      url: '/api/sinoma-training/education/getStatis',
      method: 'get',
      params:{
        ...params
      }
    })
}

// 获取安全巡检统计数据
export const getSafeStatis = (data) => {
    return request({
      url: '/api/sinoma-hse-prj/securityinspection/getProjectNum',
      method: 'post',
      data:{
        ...data
      }
    })
}