import request from '@/router/axios';
import ServerNameEnum from "@/util/ServerNameEnum";

// 编辑表
export const DataModelFieldList = (id,data) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + `/dbtable/getInfo/${id}`,
    method: "get",
    params: {
      ...data,
    },
  });
};

// 获取数据库表列表
export function DataModelList(id) {
  return request({
    url: ServerNameEnum.SERVER_DESK_+ `/dblink/getAllTable/${id}`,
    method: 'get'
  })
}
// 添加数据表
export function DataModelCreate(linkId, data) {
  return request({
    url: `/api/system/DataModel/${linkId}/Table`,
    method: 'post',
    data
  })
}
// 预览数据库表
export function DataModelData(linkId, table, data) {
  return request({
    url: `/api/system/DataModel/${linkId}/Table/${table}/Preview`,
    method: 'get',
    data
  })
}
// 删除数据表
export function DataModelDelete(linkId, id) {
  return request({
    url: `/api/system/DataModel/${linkId}/Table/${id}`,
    method: 'delete',
  })
}
//  获取数据表
export const DataModelInfo = (id,data) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + `/dbtable/getInfo/${id}`,
    method: "get",
    params: {
      ...data,
    },
  });
};
// 修改数据表
export function DataModelUpdate(linkId, data) {
  return request({
    url: `/api/system/DataModel/${linkId}/Table`,
    method: 'put',
    data
  })
}
// 修改表
export function addTableFields(id,data) {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + `/dbtable/updateTable/${id}`,
    method: 'post',
    data
  })
}
// 导出
export function exportTpl(linkId, id) {
  return request({
    url: `/api/system/DataModel/${linkId}/Table/${id}/Action/Export`,
    method: 'get'
  })
}
