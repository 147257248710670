import request from '@/router/axios';
import website from "@/config/website";
import ServerNameEnum from '@/util/ServerNameEnum';

export const loginByUsername = (tenantId, deptId, roleId, username, password, type, key, code,sinoma_code,wx_code,grant_type) => request({
  url: ServerNameEnum.SERVER_AUTH_ + '/oauth/token',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId,
    'Dept-Id': (website.switchMode ? deptId : ''),
    'Role-Id': (website.switchMode ? roleId : ''),
    'Captcha-Key': key,
    'Captcha-Code': code,
  },
  // grant_type: (website.captchaMode ? "captcha" : "password"),
  params: {
    tenant_id: tenantId,
    username,
    password,
    grant_type,sinoma_code,wx_code,
    scope: "all",
    type
  }
});

export const loginBySocial = (tenantId, source, code, state) => request({
  url: ServerNameEnum.SERVER_AUTH_ + '/oauth/token',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId
  },
  params: {
    tenantId,
    source,
    code,
    state,
    grant_type: "social",
    scope: "all",
  }
})

export const refreshToken = (refresh_token, tenantId, deptId, roleId) => request({
  url: ServerNameEnum.SERVER_AUTH_ + '/oauth/token',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId,
    'Dept-Id': (deptId || ''),
    'Role-Id': (roleId || '')
  },
  params: {
    tenant_id: tenantId,
    refresh_token,
    grant_type: "refresh_token",
    scope: "all",
  }
});

export const registerGuest = (form, oauthId) => request({
  url: ServerNameEnum.SERVER_USER_ + '/register-guest',
  method: 'post',
  params: {
    tenantId: form.tenantId,
    name: form.name,
    account: form.account,
    password: form.password,
    oauthId
  }
});

export const getButtons = () => request({
  url: ServerNameEnum.SERVER_SYSTEM_ + '/menu/buttons',
  method: 'get'
});

export const getCaptcha = () => request({
  url: ServerNameEnum.SERVER_AUTH_ + '/oauth/captcha',
  method: 'get'
});

export const logout = () => request({
  url: ServerNameEnum.SERVER_AUTH_ + '/oauth/logout',
  method: 'get'
});

export const getUserInfo = () => request({
  url: ServerNameEnum.SERVER_AUTH_ + '/oauth/user-info',
  method: 'get'
});

export const sendLogs = (list) => request({
  url: ServerNameEnum.SERVER_AUTH_ + '/oauth/logout',
  method: 'post',
  data: list
});

export const clearCache = () => request({
  url: ServerNameEnum.SERVER_AUTH_ + '/oauth/clear-cache',
  method: 'get'
});

export const getUserSetting = (userId) => request({
  url: ServerNameEnum.SERVER_USER_ + '/userSettings/list',
  method: 'get',
  params: {
    userId: userId
  }
});

export const submitUserSetting = (setIds) => request({
  url: ServerNameEnum.SERVER_USER_ + '/userSettings/submit',
  method: 'post',
  params: {
    setIds: setIds
  }
});

export const getMsgSetting = (userId, msgTypeId) => request({
  url: ServerNameEnum.SERVER_USER_ + '/userSettings/getMsgSetting',
  method: 'get',
  params: {
    userId: userId,
    msgTypeId: msgTypeId
  }
});

export const getBcstSetting = (userId) => request({
  url: ServerNameEnum.SERVER_USER_ + '/userBcstSettings/list',
  method: 'get',
  params: {
    userId: userId
  }
});

export const submitBcstSetting = (setIds) => request({
  url: ServerNameEnum.SERVER_USER_ + '/userBcstSettings/submit',
  method: 'post',
  params: {
    setIds: setIds
  }
});

export const getBcstSettings = (userId, msgTypeId) => request({
  url: ServerNameEnum.SERVER_USER_ + '/userBcstSettings/getBcstSetting',
  method: 'get',
  params: {
    userId: userId,
    msgTypeId: msgTypeId
  }
});

export const iframeLogin = (userName) => request({
  url: ServerNameEnum.SERVER_AUTH_ + '/oauth/callback/other',
  method: 'post',
  params: {
    userName: userName
  }
})

export const getIframeUser = (data) => request({
  url: ServerNameEnum.SERVER_IFRAME,
  method: 'post',
  data: data
})

// 获取用户系统配置
export const userStyleDetail = (userId) => request({
  url: ServerNameEnum.SERVER_SYSTEM_ + '/sys/userStyle/detail?userId=' + userId,
  method: 'get'
})

// 存储用户系统配置
export const userStyleSave = (data) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/sys/userStyle/saveORupdate',
    method: 'post',
    data
  })
}

// 指标接口
export const appIndex = (data) => request({
  url: ServerNameEnum.SERVER_SYSTEM_ + '/mockIndicatorValue1/listIndex',
  method: 'get',
  params: data
})

// 指标下钻接口
export const appIndexChild = (data) => request({
  url: ServerNameEnum.SERVER_SYSTEM_ + '/mockIndicatorValue1/listIndexChildren',
  method: 'get',
  params: data
})

// code参数登录
export const loginByCode = (params)=>{
  return request({
    url: 'api/sinoma-auth/portal/getCode',
    method: 'get',
    params
  })
}

// 获取第三方token
export const cnbmAuth = (data)=>{
  return request({
    url: 'api/sinoma-auth/cnbm/auth?token='+data,
    method: 'get',
  })
}
export const authorize = (data)=>{
  return request({
    url: 'https://home.sinoma.com.cn/cas/oauth2.0/profile?access_token=' + data,
    method: 'get'
  })
}

// 获取用户当前所有部门列表
export const nowUserDept = (data) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/listByUserId',
    method: 'get',
    params: data
  })
}
