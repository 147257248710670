import httpService from "@/router/axios";

// 获取 安全会议 表格数据
export const $_getList = (data) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/meeting/page",
    method: "post",
    data,
  });
};

// 会议交底   删除
export const meetingRemove = (params) => {
  return httpService({
    url: '/api/ivystar-wisdom-security/meeting/remove',
    method: 'post',
    params
  })
}

// 获取 项目管理 表格数据
export const $_getComList = (params) => {
  return httpService({
    url: "/api/sinoma-sync/videoScreen/listProjectByParentId",
    method: "get",
    params,
  });
};

// 获取 项目组织架构 表格数据
export const $_getItemsdeptList = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/itemsdept/list2",
    method: "get",
    params,
  });
};

// 获取 项目组织架构 表格数据
export const $_gettree = (projectId) => {
  return httpService({
    url: `/api/ivystar-wisdom-security/itemsdept/tree/${projectId}`,
    method: "get",
  });
};

// 获取  区域管理--不分页
export const $_getpage_list = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/itemsarea/list2",
    method: "get",
    params,
  });
};

//获取项目数据 不分页 无公司项目权限限制
export const getNoLimitAll = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/itemslist/noLimitAll",
    method: "get",
    params,
  });
};

