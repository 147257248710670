var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popupSelect-container" },
    [
      _c(
        "div",
        {
          staticClass: "el-select",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.openDialog.apply(null, arguments)
            },
          },
        },
        [
          _vm.multiple
            ? _c(
                "div",
                {
                  ref: "tags",
                  staticClass: "el-select__tags",
                  style: {
                    "max-width": _vm.inputWidth - 32 + "px",
                    width: "100%",
                    cursor: "pointer",
                  },
                },
                [
                  _vm.collapseTags && _vm.tagsList.length
                    ? _c(
                        "span",
                        [
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                closable: !_vm.selectDisabled,
                                size: _vm.collapseTagSize,
                                type: "info",
                                "disable-transitions": "",
                              },
                              on: {
                                close: function ($event) {
                                  return _vm.deleteTag($event, 0)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "el-select__tags-text" },
                                [_vm._v(_vm._s(_vm.tagsList[0].fullName))]
                              ),
                            ]
                          ),
                          _vm.tagsList.length > 1
                            ? _c(
                                "el-tag",
                                {
                                  attrs: {
                                    closable: false,
                                    type: "info",
                                    "disable-transitions": "",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-select__tags-text" },
                                    [
                                      _vm._v(
                                        "+ " + _vm._s(_vm.tagsList.length - 1)
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.collapseTags
                    ? _c(
                        "transition-group",
                        { on: { "after-leave": _vm.resetInputHeight } },
                        _vm._l(_vm.tagsList, function (item, i) {
                          return _c(
                            "el-tag",
                            {
                              key: item.id,
                              attrs: {
                                size: _vm.collapseTagSize,
                                closable: !_vm.selectDisabled,
                                type: "info",
                                "disable-transitions": "",
                              },
                              on: {
                                close: function ($event) {
                                  return _vm.deleteTag($event, i)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "el-select__tags-text" },
                                [_vm._v(_vm._s(item.fullName))]
                              ),
                            ]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-input",
            {
              ref: "reference",
              attrs: {
                type: "text",
                placeholder: _vm.currentPlaceholder,
                disabled: _vm.selectDisabled,
                readonly: "",
                "validate-event": false,
                tabindex: _vm.multiple ? "-1" : null,
              },
              nativeOn: {
                mouseenter: function ($event) {
                  _vm.inputHovering = true
                },
                mouseleave: function ($event) {
                  _vm.inputHovering = false
                },
              },
              model: {
                value: _vm.innerValue,
                callback: function ($$v) {
                  _vm.innerValue = $$v
                },
                expression: "innerValue",
              },
            },
            [
              _c("template", { slot: "suffix" }, [
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showClose,
                      expression: "!showClose",
                    },
                  ],
                  class: [
                    "el-select__caret",
                    "el-input__icon",
                    "el-icon-arrow-up",
                  ],
                }),
                _vm.showClose
                  ? _c("i", {
                      staticClass:
                        "el-select__caret el-input__icon el-icon-circle-close",
                      on: { click: _vm.handleClearClick },
                    })
                  : _vm._e(),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center transfer-dialog",
          attrs: {
            title: "分组选择",
            "close-on-click-modal": false,
            visible: _vm.visible,
            "lock-scroll": "",
            "append-to-body": "",
            width: "800px",
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            close: _vm.onClose,
          },
        },
        [
          _c("div", { staticClass: "transfer__body" }, [
            _c("div", { staticClass: "transfer-pane" }, [
              _c(
                "div",
                { staticClass: "transfer-pane__tools" },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "search-input",
                      attrs: { placeholder: "请输入关键词查询", clearable: "" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.search.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.keyword,
                        callback: function ($$v) {
                          _vm.keyword = $$v
                        },
                        expression: "keyword",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: { click: _vm.search },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "transfer-pane__body" },
                [
                  _c("el-tree", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "tree",
                    staticClass: "SNOWS-common-el-tree",
                    attrs: {
                      data: _vm.treeData,
                      props: _vm.props,
                      "check-on-click-node": "",
                      "expand-on-click-node": false,
                      "default-expand-all": "",
                      "node-key": "id",
                      "filter-node-method": _vm.filterNode,
                    },
                    on: { "node-click": _vm.handleNodeClick },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ node, data }) {
                          return _c(
                            "span",
                            { staticClass: "custom-tree-node" },
                            [
                              _c("i", { class: data.icon }),
                              _c("span", { staticClass: "text" }, [
                                _vm._v(_vm._s(node.label)),
                              ]),
                            ]
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "transfer-pane" }, [
              _c(
                "div",
                { staticClass: "transfer-pane__tools" },
                [
                  _c("span", [_vm._v("已选")]),
                  _c(
                    "el-button",
                    {
                      staticClass: "removeAllBtn",
                      attrs: { type: "text" },
                      on: { click: _vm.removeAll },
                    },
                    [_vm._v("清空列表")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "transfer-pane__body shadow right-pane" },
                [
                  _vm._l(_vm.selectedData, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "selected-item" },
                      [
                        _c("span", [_vm._v(_vm._s(item.fullName))]),
                        _c("i", {
                          staticClass: "el-icon-delete",
                          on: {
                            click: function ($event) {
                              return _vm.removeData(index)
                            },
                          },
                        }),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setDefault(), (_vm.visible = false)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }