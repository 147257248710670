import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";
export const getList = (offset, limit, params) => {
  return request({
    url: ServerNameEnum.SERVER_SINOMA_INTERFACE_ + "/intflog/list",
    method: "get",
    params: {
      ...params,
      offset,
      limit,
    },
  });
};
export const getTree = (params) => {
  return request({
    url: ServerNameEnum.SERVER_SINOMA_INTERFACE_ + "/intflog/tree",
    method: "get",
    params: {
      ...params,
    },
  });
};
