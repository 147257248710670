import Mock from 'mockjs'
const top = [{
  label: "首页",
  path: "/cip/index",
  icon: 'el-icon-menu',
  meta: {
    i18n: 'dashboard',
  },
  parentId: 0
},
  {
    label: "",
    icon: 'el-icon-document',
    meta: {
      i18n: 'website',
    },
    path: " http://sooow.com/#/",
    parentId: 1
  },
  {
    label: "avuex官网",
    icon: 'el-icon-document',
    meta: {
      i18n: 'avuexwebsite',
    },
    path: "https://avuex.avue.top",
    parentId: 2
  },
  {
    label: "测试",
    icon: 'el-icon-document',
    path: "/fieldUniform/index",
    meta: {
      i18n: 'test',
    },
    parentId: 3
  }]
export default ({mock}) => {
  if (!mock) return;
  Mock.mock('/user/getTopMenu', 'get', () => {
    return {
      data: top
    }
  })

}
