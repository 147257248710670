import Layout from '@/page/index/'

export default [
  {
    path: '/hazardousChemicals',
    component: Layout,
    name: '危化品管理',
    children: [
      {
        path: '/hazardousChemicals/purchase',
        name: '危化品采购入库',
        component: () =>
          import('@/views/business/hazardousChemicals/purchase'),
        meta: {
          i18n: '危化品采购入库'
        }
      },
      {
        path: '/hazardousChemicals/useAdd',
        name: '危化品领用申请',
        component: () =>
          import('@/views/business/hazardousChemicals/useAdd'),
        meta: {
          i18n: '领用申请'
        }
      },
      {
        path: '/hazardousChemicals/return',
        name: '危化品归还申请',
        component: () =>
          import('@/views/business/hazardousChemicals/return'),
        meta: {
          i18n: '归还申请'
        }
      },
      {
        path: '/hazardousChemicals/disposition',
        name: '危化品处置申请',
        component: () =>
          import('@/views/business/hazardousChemicals/disposition'),
        meta: {
          i18n: '归还申请'
        }
      }
      
    ]
  },

]
