import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const CONTROLLER_NAME = "/user/";

/**
 *  获取任务列表
 */
export const pageList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_JOB_}${CONTROLLER_NAME}pageList`,
    method: 'post',
    params:{
      ...params,
      start:current - 1,
      length:size
    }
  })
}

export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_JOB_}${CONTROLLER_NAME}remove`,
    method: "post",
    params: {
      ids,
    },
  });
};
