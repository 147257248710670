import datacenter from "./datacenter/datacenter_zh";
import indexManage from "./index/indexManage_zh";
import mdModule from "./md/md_zh";
import pmModule from "./pm/pm_zh";
import qmModule from "./qm/qm_zh";
import cpsModule from "./cps/cps_zh";
import platModule from "./platform/platform_zh";
// import formFillingPlan from "./formFillingPlan/formFillingPlan_zh";

export default {
  cip: {
    cmn: {
      rule: {
        inputWarning: "请输入",
        selectWarning: "请选择",
        inputErrorMessage: "请输入正确的",
        deleteWarning: "请删除前后空格",
        removeSpacesWarning: "请删除空格",
        noEmptyWarning: "不能为空",
        nameLength2Warning: "长度不能大于20",
        nameLength3Warning: "长度不能大于30",
        nameLength4Warning: "长度不能大于40",
        nameLength5Warning: "长度不能大于50",
        nameLength6Warning: "长度不能大于60",
        nameLength10Warning: "长度不能大于100",
        nameLength20Warning: "长度在 0 到 200 个字符",
        number: "请输入数字",
        ten: "10位以内的数字",
        orderNum: "排序",
        photograph: "只能上传jpg/png格式,且不超过10M",
        identityCardWarning: "清输入正确的身份证号",
        autoValue: "自动生成",
      },
      btn: {
        nextstep:"下一步",
        batchConfig: "批量配置",
        roleConfig: "角色配置",
        reportConfig: "报表配置",
        send: "发送",
        print: "打印",
        modelEdit: "模板编辑",
        handel: "操作",
        execute: "执行",
        configuration: "配置下发",
        choice: "选择",
        logout: "注销",
        batchInspection: "质检规则",
        batchRule: "批次规则",
        batchNumRule: "批次号规则",
        relieveBtn: "解除",
        shieldingBtn: "拉黑",
        BloodRelationshipBtn: "查看血缘",
        saveLineBtn: "新增一行",
        viewProcessBtn: "流程查看",
        processBtn: "流程进度",
        rolePermission: "权限",
        addBtn: "新增",
        publishBtn: "发布",
        editBtn: "编辑",
        configureFormula: "配置公式",
        saveBackBtn: "保存并返回",
        saveBtn: "保存",
        delBtn: "删除",
        viewBtn: "查看",
        defBtn: "确定",
        celBtn: "取消",
        clearBtn: "清空",
        revBtn: "修改",
        addChildBtn: "新增子项",
        validBtn: "启用",
        invalidBtn: "失效",
        historyBtn: "历史记录",
        chartBtn: "实时图表",
        earlyWarningBtn: "测点预警",
        exportBtn: "导出",
        exportTemplateBtn: "导出模版",
        initiateBtn: "发起",
        importBtn: "导入",
        viewDataBtn: "查看详情",
        dicConfigBtn: "字典配置",
        permissionsBtn: "权限设置",
        enable: "启用",
        disable: "禁用",
        disableBtn: "停用",
        detailBtn: "明细",
        submitBtn: "提交",
        unSubmitBtn: "撤回",
        returnLoginBtn: "返回登录页",
        copyBtn: "复制",
        configBtn: "配置",
        selIndexBtn: "选择指标",
        dealBtn: "处理",
        tipDetermineGenRecordBtn: "确认生成",
        backBtn: "取回",
        calBtn: "计算",
        goBackBtn: "返回",
        FillingBtn: "填报",
        chooseItemBtn: "选择物料",
        autoCollctionBtn: "自动采集",
        initializationBtn: "初始化",
        chooseData: "数据采集",
        generateBtn: "生成",
        audit: "审核",
        approve: "审批",
        confirm: "确认",
        startBtn: "启动",
        stopBtn: "停止",
        resetCacheBtn: "重置缓存",
        dispatchBtn: "调度",
        addPeopleBtn:'添加人员',
      },
      msg: {
        success: {
          addSuccess: "新增成功",
          updataSuccess: "修改成功",
          delSuccess: "删除成功",
          operateSuccess: "操作成功",
          backSuccess: "取回成功",
          enableSuccess: "启用成功",
          setSuccess: "配置成功",
          importSuccess: "导入成功",
          isStatus: "此班组已被提交,无法修改",
          sumbError: "请勿重复提交",
          deptError:"只能选择一个主组织",
          selectARole:"请确保每个部门中至少勾选一个角色",
          isDefaultDept:"请勾选其中一个部门为主组织",
          department:"部门不能为空"
        },
        error: {
          addError: "新增失败",
          updataError: "修改失败",
          delError: "删除失败",
          nodeError: "节点自身不能自选或下级",
          operateError: "操作失败",
          saveError: "保存失败",
          serviceError: "调用服务异常！",
          generateError: "数据生成失败",
          dataError: "系统数据有误，请联系管理员处理",
          importError: "导入失败",
          // 后端国际化
          queryServiceError: "查询期间的服务调用失败",
          periodDateIsNullError: "对应的会计期间日期不存在",
          periorYearError: "统计年份转换失败",
          submitError: "提交失败",
          unitError: "查询单位信息失败！",
        },
        warning: {
          checkDept: "请在左侧树选择组织",
          addWarning: "请先保存数据",
          updataWarning: "确定修改数据？",
          delWarning: "确定删除数据？",
          tipDetermineNoSelectCate: "请先选中分类",
          retWarning: "是否返回列表页面？",
          selectWarning: "请选择数据",
          selectRightData: "请选择可删除的数据",
          defaultCarry: "默认自动带出",
          tipDetermineNoSelect: "请先选中",
          chooseAtLeastOne: "请选择至少一条数据",
          tipDetrmineSelectDel: "请选择要删除的数据",
          startusingWarning: "确定将选择数据启用?",
          loseefficacyWarning: "确定将选择数据失效?",
          determineDelWarning: "确定将选择数据删除?",
          determineUnsubmitWarning: "确定将选择数据撤回?",
          tipDetermineNoSaveHead: "请先保存头表数据!",
          confirmWarning: "是否返回列表页面",
          filterateWarning: "输入关键字进行过滤",
          editWarning: "请选择要编辑的数据",
          deleteWarning: "请选择要删除的数据",
          selectEditNodeWarning: "请选择要编辑的节点",
          selectDeleteNodeWarning: "请选择要删除的节点",
          hasChildrenWarning: "有子项未删除!",
          hasIndicators: "节点下有指标未删除",
          determineRemoveWarning: "确定将选择数据移除?",
          selectOneWarning: "只能选择一条数据",
          saveHeadWarning: "请先保存头表数据!",
          hasChildren: "有子项未删除!",
          onlyHaveOneYes: "必须且只能有一条数据是默认的",
          tipDetermineHaveFinishIn: "当前物料已入库！",
          tipDetermineHaveFinishInStatus: "存在已经入库的数据，无法删除!",
          tipDetermineHaveFinishOutStatus: "存在已经出库的数据，无法删除!",
          tipDetermineHaveFinishInStatusRow: "当前数据已入库，无法删除！",
          tipDetermineHaveFinishOutStatusRow: "当前数据已出库，无法删除！",
          tipDetermineHaveFinishOut: "当前物料已出库！",
          startDateMustLessThanEndDate: "开始时间不能大于结束时间",
          validWarning: "是否启用该条数据？",
          invalidWarning: "是否禁用该条数据？",
          generateWarning: "是否生成数据?",
          hadGenerateCannotDelete: "已经生成质检记录，无法删除!",
          selectDateAndTeamCode: "请选择质检日期和质检组名称!",
          noCheckedRecordObj: "请选择质检对象!",
          noDataYet: "暂无数据",
          modifiedWarning: "数据未作修改",
          qualityInspection: "是否提交质检记录?",
          submittedWarning: "该质检对象无法重复提交",
          leastOneWarning: "请至少选择一条数据",
          oneSubmitted: "请至少选中一条已提交的数据",
          outInspection: "是否取回质检记录?",
          unsubmittedWarning: "请至少选中一条未提交的数据",
          classificationWarning: "请选择取样点分类",
          dontRepeatAbolish: "该单据已作废，不要重复操作!",
          warehouseNoExist: "仓库不存在或仓库未启用",
          stockInItemUnMatch: "入库物料与仓库默认物料不匹配，无法入库！",
          parameterMissing: "参数缺失！",
          codeNotExist: "编码缺失！",
          dataNotExist: "数据不存在",
          repetition: "有多个编码相同，请联系管理员处理!",
          conflict: "编码已存在!",
          submitData: "提交后数据将无法更改？",
          unSubmitData: "确认撤回选择的记录？",
          headRecordWarning: "该类型无法新增",
          dataExist: "该数据已存在，无法重复设置!",
          idNotNull: "id不能为空!",
          enableNotNull: "isEnable参数缺失!",
          parentIdsWarning: "请选择上级菜单!",
          unerasable: "该数据不可删除",
          nameWarning: "名称已存在",
          empowerWarning: "租户没有授权配置",
          ruleTimeIsEmpty: "无权配置管理员角色!",
          lineIsEmpty: "无权配置超管角色!",
          saveStatus: "还未保存，请先保存！",
          dataNotNull: "数据不能为空",
          unchangeable: "数据不可修改",
          paramFormatError: "参数格式不正确!",
          qmRecordHasExist: "当前时间的质检记录已经生成 !",
          isSubmitNoRemove: '无法删除已提交的数据!',
        },
      },
      title: {
        SerialNumber: "序号",
        typeTitle: "类型分类",
        promptTitle: "提示",
        more: "查看更多",
      },
      components: {
        SNOWSIconBox: {
          title: {
            indexHeadTitle: "图标选择",
            ymIconHeadTitle: "ymIcon 图标",
            elementUIHeadTitle: "Element-UI 图标",
            ymCustomHeadTitle: "ymCustom 图标",
          },
          msg: {
            iconName: "请输入图标名称",
          },
        },
        internationalizedData: {
          field: {
            en: "英文:",
            zh: "中文:",
          },
          title: {
            internationalizedDataTitle: "国际化数据",
          },
          msg: {
            required: "必填项",
          },
        },
        login: {
          msg: {
            loggingIn: "登录中,请稍后...",
          },
        },
        chooseLangue: {
          field: {
            en: "英文",
            zh: "中文",
          },
          title: {
            chooseLangueTitle: "选择语言",
          },
        },
        // 门户设计的组件
        basicControl: {
          field: {
            functionDesign: "功能设计",
            processDesign: "流程设计",
            rightManagement: "权限管理",
            reportDesign: "报表设计",
            portalDesign: "门户设计",
            custom: "新增客户（个）",
            business: "新增商机（个）",
            contract: "合同金额（元）",
            collection: "回款金额（元）",
            January: "一月",
            February: "二月",
            March: "三月",
            April: "四月",
            May: "五月",
            June: "六月",
            salesVolume: "销售量",
            weatherStatistics: "天气情况统计",
            fictitiousData: "虚构数据",
            houLiang: "西凉",
            yiZhou: "益州",
            yanZhou: "兖州",
            jingZhou: "荆州",
            youZhou: "幽州",
            email: "邮件营销",
            allianceAdvertising: "联盟广告",
            videoAD: "视频广告",
            directAccess: "直接访问",
            searchEngines: "搜索引擎",
            Monday: "周一",
            Tuesday: "周二",
            Wednesday: "周三",
            Thursday: "周四",
            Friday: "周五",
            Saturday: "周六",
            Sunday: "周日",
            total: "总量",
            referrer: "访问来源",
            allocatedBudget: "预算分配",
            actualSpending: "实际开销",
            sales: "销售",
            administration: "管理",
            informationTechology: "信息技术",
            customerSupport: "客服",
            development: "研发",
            marketing: "市场",
            vs: "预算 vs 开销",
          },
          title: {
            myToDoListTitle: "我的待办",
            commonFunctionTitle: "常用功能",
            noticeTitle: "公告通知",
            weatherForecastTitle: "天气预报",
            todoListTitle: "待办事项",
            dataPanelTitle: "数据面板",
            histogramTitle: "柱状图",
            pieTitle: "饼图",
            lineChartTitle: "折线图",
            circularDiagramTitle: "环形图",
            areaTitle: "面积图",
            radarChartTitle: "雷达图",
          },
        },
        // 首页相关的一系列组件
        index: {
          field: {},
          title: {
            menuSearchTitle: "菜单搜索",
          },
          msg: {
            esc: "你可以使用快捷键esc关闭",
            clear: "是否需要清除缓存?",
          },
        },
      },
      token: {
        user: "用户名或密码错误",
        tenant: "租户ID未找到",
        authorization: "令牌授权已过期",
        information: "未获得用户的角色信息",
        obtained: "未获得用户的租户信息",
        tenantAuthorization: "租户授权已过期,请联系管理员",
        login: "登录错误次数过多,请稍后再试",
        captcha: "验证码不正确",
        Authentication: "缺少令牌，身份验证失败",
        token: "令牌无效",
        disable: "当前账号已被封禁,请联系管理员",
      },
    },
    ...mdModule,
    ...pmModule,
    ...qmModule,
    ...platModule,
    ...cpsModule,

    //数据中台
    dc: {
      //接口系统配置
      InterfaceSystem: {
        title: {
          InterfaceSystemConfiguration: "接口系统配置",
          NewInterfaceSystemConfiguration: "接口系统配置新增",
        },
        field: {
          isToken: "是否维护token信息",
          isToken2: "是否维护token",
          TokenInformation: "token信息",
          AddRequestBody: "添加请求体",
          yes: "是",
          no: "否"


        }
      },
      //全量接口采集
      FullInter: {
        title: {
          FullInterfaceAcquisition: "全量接口采集",
          AddFullInterfaceAcquisition: "全量接口采集新增",
        },

        field: {
          InterfaceName: "接口名称",
          InterfaceAddress: "接口地址",
          RequestMethod: "请求方式",
          ReturnStatusCode: "返回状态码",
          ReturnStatusValue: "返回状态值",
          PrimaryKeyName: "主键名称",
          ResultConfiguration: "结果配置",
          InterfaceCode: "接口编码",
          TopicConfiguration: "topic配置",
          SystemSourceID: "系统来源ID",
          ForeignKeyCode: "外键code",
          CreationTime: "创建时间",
          ModificationTime: "修改时间",
          InterfaceDescription: "接口功能描述",
        },
        msg: {
          msg1: "请求参数",
          msg2: "请选择类型",
        }
      },
      //增量接口采集
      zljkcj: {
        title: {
          name: "增量接口采集",
          name2: "新增增量接口采集",
        },

        field: {
          InterfaceCode: "接口编码",
          InterfaceName: "接口名称",
          InterfaceAddress: "接口地址",
          RequestMethod: "请求方式",
          AccessMode: "接入方式",
          dataType: "数据类型",
          StartTimeField: "开始时间字段",
          EndTimeField: "结束时间字段",
          IncrementalTimestamp: "增量时间戳",
          IncreaseStepLengthTime: "加步长时间",
          IncrementalTimeType: "增量时间类型",
          IncrementalTimeTypeFormat: "增量时间类型格式化",
          StepLengthdays: "步长(天)",
          ReturnDataItem: "返回数据项",
          ReturnStatusCode: "返回状态码",
          ReturnDescription: "返回描述",
          PrimarykeyField: "主键字段",
          SourceSystem: "来源系统",
          TokenField: "token字段",
          SourceDatabaseInformation: "来源数据库信息",

          RequestHeader: "请求头",
          AddRequestHeader: "添加请求头",
          TimeTypeFormat: "时间类型格式",
          increment: "增量",
          Addsteptimestamp: "加步长时间戳",
          Interfaceconfigurationinformation: "接口配置信息",
          Endtimefield: "结束时间字段",
          Updatetimefield: "更新时间字段",
          Timetype: "时间类型",
          Timestamptype: "时间戳类型",
          Maximumtimestamp: "最大时间戳",
          Sourcedatabase: "来源数据库",
          constant: "常量",
          parameter: "参数",
          Requestor: "请求体",
          type: "类型",
          startTime: "开始时间",
          endTime: "结束时间",
          Updatetime: "更新时间",


        },
        msg: {
          msg1: "请输入接口编码/接口名称"
        }
      },

      //Token刷新配置
      Token: {
        msg: "保存成功",
        title: {
          name: "Token刷新配置",
          Addname: "Token刷新配置新增",
        },
        field: {
          Interfacecode: "接口编码",
          Url: "地址",
          Requestinformation: "请求信息",
          RequestMethod: "请求方式",
          Splicinginformation: "拼接信息",
          Fieldname: "字段名称",
          Datasourceid: "数据源id",
          Tokensourceid: "Token来源id",
          operation: "操作",
          RequestHeader: "请求头",
          Addrequestheader: "添加请求头",
          edit: "编辑",
          Interfaceaddress: "接口地址",
          baseInfo: "基础信息"
        },
      },
      //离线数据采集
      OffCollection: {
        title: {
          name: "离线数据采集",
        },
        field: {
          step: "步骤",
          structure: "构建",
          FieldMapping: "字段映射",
          Datasynchronization: "数据同步",
          Databasename: "数据库名称",
          Datatablename: "数据表名称",
          SQLstatement: "SQL语句",
          Resolvefield: "解析字段",
          Shardfield: "切分字段",
          TableAllFields: "表所有字段",
          SelectAll: "全选",
          Wherecondition: "where条件",
          PrecedingSQLstatement: "前置SQL语句",
          PostSQLstatement: "后置SQL语句",
          Sourcefield: "源端字段",
          Targetfield: "目标字段",
          Startsynchronization: "开始同步",
          start: "开始",
          fill: "填充",
          Previousstep: "上一步",
          nextstep: "下一步",
        },
        msg: {
          msg1: "请选择数据库名称！"
        },
      },
      // EXCEL数据导入
      ExcelDataImport: {
        btn: {
          SelectFile: "选择文件",
          SetStartLine: "设置起始行",
          ConfigureImportTable: "配置导入表",
          SetImportFields: "设置导入字段",
          SetImportMode: "设置导入模式",
          StartImporting: "开始导入",

        },
        field: {
          PleaseSelectDatabase: "请选择数据库",
          ImportFrom: "导入从",
          ClickToUpload: "点击上传",
          Surface: "表",
          ColumnNameLine: "栏位名行",
          FirstDataRow: "第一个数据行",
          LastDataRow: "最后一个数据行",
          SourceTable: "源表",
          TargetTable: "目标表",
          NewTable: "新建表",
          TargetField: "目标栏位",
          Type: "类型",
          length: "长度",
          Proportion: "比例",
          PrimaryKey: "主键",
          ImportMode: "导入模式",
          StartSynchronization: "开始同步",
        },
        msg: {
          msg1: "你可以为源定义一些附加的选项。",
          msg2: "你可以定义栏位对应,设置对应指定的源栏位和目的栏位之间的对应关系。",
          msg3: "添加：添加记录到目标表",
          msg4: "复制：删除目标全部记录，并从源重新导入",
        },
      },
      //数据源
      datasource: {
        title: {
          name1: "数据源管理",
        },
        field: {
          index: "序号",
          name: "数据源名称",
          refreshCache: "刷新缓存",
          syncStatus: "同步状态",
          status: "状态",
          createTime: "创建时间",
          refreshSuccess: "刷新缓存成功",
          refreshFailed: "刷新缓存失败",
          notSync: "未同步",
          synchronizing: "同步中",
          synced: "已同步",
          sjyxx: "数据源信息",
          ljxx: "连接信息",
          datasourceType: "数据源类型",
          remark: "备注",
          host: "主机",
          port: "端口",
          sid: "服务名",
          dbName: "数据库",
          username: "用户名",
          password: "密码",
          ltxcs: "连通性检测",
          sjyxz: "数据源新增",
          sjybj: "数据源编辑",
          ysjtb: "元数据同步",
          sjyxq: "数据源详情",

          fileName: "数据库设计文档.doc"
        },
        msg: {
          ysjtbTip: "元数据正在后台同步中，请到元数据管理中查看结果",
        },

      },
      //元数据
      datacolumn: {
        title: "元数据管理",
        field: {
          fieldName: "字段名称",
          load: "加载中，请稍后",
          fieldComment: "字段注释",
          isPrimaryKey: "是否主键",
          isEmpty: "是否允许为空",
          dataType: "数据类型",
          dataLength: "数据长度",
          dataPrecision: "数据精度",
          dataScale: "数据小数位",
          dataDefault: "数据默认值",
          sourceName: "数据源",
          tableName: "数据库表",
          updateField: "变更字段",
          version: "版本号",
          fieldOldValue: "原来的值",
          fieldNewValue: "最新的值",
          status: "状态",
          remark: "备注",
          ysjbg: "元数据变更"
        },

      },

      //数据主题
      dataSubject: {
        title: "数据主题",
        field: {
          name: "名称",
          Englishname: "英文名称",
          Parent: "父级",
          codeName: "编码名称",
        },
        msg: {
          Tips: "提示",
          msg1: "确定删除, 是否继续?",
          msg2: "删除成功",
        },
        rules: {
          name: "请输入名称",
          ename: "请输入英文名称"
        },
        btn: {
          AddChild: "添加子项",
        }
      },

      //数据目录
      DataDirectory: {
        btn: {
          Description: "描述",
          BrowseData: "浏览数据",
        },
        field: {
          Dataname: "数据名称",
          datadescription: "数据描述",
          Datadirectory: "数据目录",
          datatype: "数据类型",
          metadata: "元数据",
          Columnname: "列名",
          explain: "说明",
          Datasource: "数据源",
          Tablename: "表名",
          name: "名称",
          catalogue: "目录",
        },

        rules: {
          rules1: "请选择数据表名",
          rules2: "请选择数据源",
        },
        msg: {
          msg1: "操作成功！",
          msg2: "请选择左侧数据",
          msg3: "保存成功",
        },
      },
      //数据对象
      dataobject: {

        msg: {
          delmsg1: "请选择要删除的数据对象",
          delmsg2: "请单个操作",
          delmsg3: "确定要删除当前数据对象？",
          p1: "请选择类型",
          p2: "请输入编码",
          p3: "请输入标识",
          p4: "请输入备注",
        },

        title: {
          tip: "提示",
          tip2: "预览",
          name: "数据对象"
        },
        field: {
          code: "编码",
          identification: "标识",
          type: "类型",
          edit: "操作",
          remark: "备注",
          databank: "数据库",
          Apiinterface: "API接口",
          tree: "目录树",
          tableName: "表名",
          saveField: "保存字段",
          ConditionField: "条件字段",
          comparisonField: "对比字段",
          sortField: "排序字段",
          fieldName: "字段名",
          fieldComments: "字段注释",
          enterValue: "输入值",
          default: "默认值",
          sort: "排序",
          AscendingOrder: "升序",
          DescendingOrder: "降序",
          BeEqualTo: "等于",
          NotEqualTo: "不等于",
          section: "区间",
          greaterThan: "大于",
          lessThan: "小于",
          GreaterThanOrEqualTo: "大于等于",
          LessThanOrEqualTo: "小于等于",
          contain: "包含",
          Excluding: "不包含",
          FixedTimeRange: "固定时间范围",
          DynamicTimeRange: "动态时间范围",
        }
      },

      //数据血缘
      Primordial: {
        title: "数据血缘",
        field: {
          number: "编号",
          tableName: "表名",
          nodeName: "数据源名称",
          remarks: "备注",
          operate: "操作",
          addRelationship: "添加关系"

        },
        msg: {
          tip: "提示",
          deleteOrNot: "是否删除",
          NewNode: "新增节点",
          editNode: "编辑节点",
          submission: "没有血缘关系的数据提交后将被删除, 是否继续?",
          successfully: "提交成功",
          Canceled: "已取消",
          ConfirmClose: "确认关闭",
          bloodAdd: "只能在血缘节点上新增",
          Parent: "父级",
          TreeDirectory: "树目录",
          relationship: "数据血缘下不能新建目录和血缘!",
          deleteTheDetails: "数据血缘请在右侧详情编辑和删除!",
          directoryToModify: "请选择要修改的目录"


        }

      },

      //数据字典
      dataDictionary: {
        title: {
          name: "数据字典",
          name2: "数据字典新增",
        },

        field: {
          code: "编码",
          name: "名称",
          operate: "操作",
          FieldCode: "字段编码",
          DictionaryName: "字典名称",
          DictionaryType: "字典类型",
          Datasourcecode: "数据源编码",
          Datasourcename: "数据源名称",
          Databasename: "数据库名称",
          InterfaceName: "接口名称",
          InterfaceAddress: "接口地址",
          list: "列表",
          tree: "树"
        },
        rules: {
          rules1: "请输入数据源编码",
          rules2: "请输入数据源类型",
          rules3: "请输入数据库名称",
          rules4: "请输入数据源连接配置",
          rules5: "请选择类型",
        },
        msg: {
          msg1: "请选择删除数据",
          Tips: "提示",
          msg2: "删除成功",
          msg3: "列表里有空值！",
        }
      },
      //数据分流
      shunt: {
        title: {
          name: "数据分流",
        },
        btn: {
          tip: "提示",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          startUsing: "启用",
          forbidden: "禁用",
          address: "操作",
          edit: "编辑",
          yes: "是",
          no: "否"
        },

        field: {
          ShuntCoding: "分流编码",
          InterfaceCode: "接口编码",
          Transcoding: "转换编码",
          sfqy: "是否启用",
          zxtj: "执行条件",
          describe: "描述",
          conditionsForExecution: "执行条件",
          isEnable: "是否启用",

        },
        msg: {
          LoadingDesperately: "拼命加载中",
          diversionCode: "请输入分流编码/接口编码/转换编码/描述",
          interfaceCode: "请输入接口编码",
          conversionCode: "请输入转换编码",
          description: "请输入描述",
          executionConditions: "请输入执行条件",
          diversionEditing: "数据分流编辑",
          diversionAdd: "数据分流添加",
          isDelect: "确定删除, 是否继续?",
          DeleteSucceeded: "删除成功",
          ModifiedSuccessfully: "修改成功",
          SuccessfullyAdded: "新增成功",

        }
      },

      //数据转化
      transformation: {
        title: {
          name: "数据转换",
        },
        msg: {
          placeholder: "请输入数据接口/字段名称",

        },
        field: {
          infCode: "数据接口",
          fieldName: "字段名称",
          targetTable: "目标表名称",
          srcField: "来源字段",
          targetField: "目标字段",
          unitCode: "单位转换编码",
          mapCode: "编码映射",
          groupNo: "分组",
          defaultValue: "默认值",
          dimension: "维度",
          dimensionSed: "维度标识",
          edit: "编辑",
          aggregationCode: "请选择汇聚编码",
          targetTagName: "请输入目标标名",
          sourceField: "请输入来源字段",
          PleasetargetField: "请输入目标字段",
          conversionEditing: "数据转换编辑",
          DataConversionAdd: "数据转换添加"

        },


      },

      //数据注入
      injection: {
        title: {
          name: "数据注入配置",
        },


        field: {
          loading: "拼命加载中",
          tablename: "目标表名称",
          keycolumn: "主键拼接",
          aggregationCode: "请输入汇聚编码",
          groupingField: "请输入分组字段"
        },
        msg: {
          placeholder: "请输入目标表名称",
          PleaseSelect: "请选择删除数据",
          ModifiedSuccessfully: "修改成功",
          SuccessfullyAdded: "新增成功",
          isDelect: "确定删除, 是否继续?",
          DeleteSucceeded: "删除成功",
          configurationEditing: "数据注入配置编辑",
          configurationAddition: "数据注入配置添加"
        }


      },


      //数据汇聚
      DataAggregation: {

        title: {
          name: "数据汇聚",
          DataAggregationGrouping: "数据汇聚分组",
          DataAggregationconfiguration: "数据汇聚配置",
          DataAggregationoutput: "数据汇聚输出",
          AddDataAggregationGrouping: "新增数据汇聚分组",
          AddDataAggregationconfiguration: "新增数据汇聚配置",
          AddDataAggregationoutput: "新增数据汇聚输出",
        },
        field: {
          AggregationCoding: "汇聚编码",
          Groupfields: "分组字段",
          Convergencetime: "汇聚时间",
          Convergencedimension: "汇聚维度",
          Aggregationsourcetable: "汇聚源表",
          Taskgrouping: "任务分组",
          Executionorder: "执行顺序",
          Aggregateflag: "聚合标志",
          Sourcefield: "源字段",
          Targetfield: "目标字段",
          Targettable: "目标表",
          remarks: "备注"
        }


      },


      //项目列表
      projectlist: {
        title: {
          name: "项目列表",
        },

        listedit: {
          dispatch: "调度",
          see: "查看",
          release: "发布",
          TaskManagement: "任务管理",
          edit: "编辑",
          delete: "删除"
        },
        seetabs: {
          Joblog: "作业日志",
          flowChart: "流程图",
          taskList: "任务列表",
          ProcessLog: "流程日志",
          InformationService: "信息查询",
          taskListlabel: {
            name: "名称",
            type: "类型",
            startTime: "开始时间",
            Endtime: "结束时间",
            timeConsuming: "耗时",
            state: "状态",
            details: "详情",
          },
          editlabel: {
            Englishname: "英文名称",
            Chinesename: "中文名称",
            Contentdescription: "内容描述",
            EditItem: "编辑项目",
            NewProject: "新建项目"
          },
          addrule: {
            ename: "请输入英文名称",
            cname: "请输入中文名称",
            text: "请输入内容描述"
          },
        },
        msg: {
          msg1: "是否删除这个任务？",
          msg2: "依次运行本实验的每个组件",
          msg3: "停止运行试验",
        },
        btn: {
          deploy: "部署",
          run: "运行",
          stop: "停止",
          Taskbuilding: "任务构建",
          name: "名称",
          save: "保存",
          DocumentContent: "文件内容",
          Navigator: "导航器",
          ParameterSetting: "参数设置",
          back: "返回",
          sure: "确定",
          close: "关闭",
          canvas: "画布",
          Gridalignment: "网格对齐",
          revoke: "撤销",
          redo: "重做",
          delete: "删除",
          enlarge: "放大",
          narrow: "缩小",
          Fittocanvas: "适应画布",
          Actualsize: "实际尺寸",
          Postlevel: "层级后置",
          Levelleading: "层级前置",
          Multiplechoice: "多选",
          Group: "成组",
          Ungroup: "解组",


        }
      },

      //任务调度
      taskScheduling: {
        name: "任务调度",
        tabs: {
          Projectlist: "项目列表",
          Historylist: "历史列表",
          Scheduledtasklist: "定时任务列表",
          Runninglist: "正在运行列表",
        },
        btn: {
          Scheduledscheduling: "定时调度",
          dispatch: "调度",
          see: "查看",
          kill: "kill",
        },
        msg: {
          msg1: "调度成功",
          msg2: "是否删除",
          msg3: "是否kill这个任务"
        },
        title: {
          Tips: "提示",
          InformationService: "信息查询",
          flowchart: "流程图",
          tasklist: "任务列表",
          Processlog: "流程日志",
          Joblog: "作业日志",
        },
        status: {
          success: "成功",
          fail: "失败",
          Running: "正在运行",
        },

        label1: {
          name: "名称",
          Chinesename: "中文名称",
          describe: "描述",
          Lastmodificationtime: "最后修改时间",
          Modifiedby: "修改人",
          operation: "操作",
          type: "类型",
        },
        label2: {
          Executionid: "执行id",
          flow: "流量",
          engineering: "工程",
          user: "用户",
          starttime: "开始时间",
          Endtime: "结束时间",
          timeconsuming: "耗时",
          state: "状态",
        },
        label3: {
          ID: "ID",
          Taskname: "任务名称",
          project: "项目",
          Firstscheduledrun: "第一次计划运行",
          Nextexecutiontime: "下次执行时间",
          expression: "表达式",
          operation: "操作",
        },
        label4: {
          ID: "ID",
          ExecutorID: "执行人ID",
          flow: "流量",
          engineering: "工程",
          user: "用户",
          jurisdiction: "权限",
          starttime: "开始时间",
          Endtime: "结束时间",
          Losstime: "流失时间",
          state: "状态",
          operation: "操作",
        },

      },
      // 班组指标
      TeamIndicators: {
        title: "班组指标"
      },

      //数据服务接口
      dataapi: {
        title: "数据服务接口",
        field: {
          apiName: "API名称",
          apiVersion: "API版本",
          apiUrl: "API路径",
          reqMethod: "请求类型",
          resType: "返回格式",
          status: "状态",
          createTime: "创建时间",
          attrConfig: "属性配置",
          executeConfig: "执行配置",
          paramsConfig: "参数配置",
          deny: "IP黑名单",
          rateLimit: "是否限流",
          limiting: "限流配置",
          every: "每",
          second: "秒内限制请求",
          times: "次",
          remark: "备注",
          configType: "配置方式",
          sourceName: "数据源",
          fieldList: "字段列表",
          columnName: "列名",
          columnDataDefault: "列默认值",
          columnComment: "列注释",
          reqable: "是否作为请求参数",
          resable: "是否作为返回参数",
          sqlParsing: "SQL解析",
          reqParams: "请求参数",
          paramName: "参数名称",
          nullable: "是否允许为空",
          paramComment: "描述",
          paramType: "参数类型",
          whereType: "操作符",
          exampleValue: "示例值",
          defaultValue: "默认值",
          returnField: "返回字段",
          fieldComment: "字段描述",
          apiAdd: "数据API新增",
          apiDetail: "数据API详情",
          apiEdit: "数据API编辑",
          denyPlaceholder: "请输入IP黑名单多个用英文,隔开",
          apiDocument: "接口文档",
          apiExample: "接口示例",
          fileName: "接口文档.doc",
          interfaceCall: "接口调用",
          reqHead: "请求头",
          reqData: "请求数据",
          KEY: "键",
          VALUE: "值",
          DESCRIPTION: "描述",
          noData: "暂无数据",
          paramValue: "参数值"
        },


      },

      // 质量检测模版
      QTemplate: {
        title: "质量检测模版",
        field: {
          TemplateType: "模板类型",
          whole: "全部",
          testing: "检测",
          TaskName: "任务名称",
          inputData: "输入数据",
          DataTheme: "数据主题",
          DataObject: "数据对象",
          LimitTheNumberOfEntries: "限制条数",
          CompareFields: "对比字段",
          sortField: "排序字段",
          field: "字段",
          dataType: "数据类型",
          sort: "排序",
        }
      },
      //检测任务管理
      DTManagement: {
        title: {
          DTManagement: "检测任务管理",
          NewDetectionTask: "检测任务新增",
        },
        field: {
          TaskName: "任务名称",
          TemplateName: "模板名称",
          TemplateClassification: "模板分类",
          Creator: "创建人",
          CreationTime: "创建时间",
          Integrity: "完整性",
          accuracy: "准确性",
        },

      },

      // 检测任务记录
      DTRecord: {
        title: "检测任务记录",
        field: {
          ThemeDomain: "主题域",
          theme: "主题",
          resultsOfEnforcement: "执行结果",
          TriggerType: "触发类型",
          DeterminationMethodName: "判定方法名称",
          executionTime: "执行时间",
          Manual: "手动",
          JudgmentMethod: "判定方法",
        },
      },
      //配置判定任务
      CJudgmentTask: {
        title: {
          CJudgmentTask: "配置判定任务",
          AddConfigurationJudgmentTask: "配置判定任务新增",
          JudgmentTask: "判定任务",
        },
        field: {
          state: "状态",
          remarks: "备注",
          QualityMonitoringTasks: "质量监测任务",
          CycleSetting: "周期设置",
          AndOrRelationship: "且或关系",
          TaskID: "任务ID",
          JudgmentRelationshipEncoding: "判定关系编码",
          DeterminationType: "判定类型",
          DetermineInputValues: "判定输入值",
          condition: "条件",
          DoYouWantToExecuteImmediately: "是否立即执行",
          ExecuteImmediately: "立即执行",
          ManualExecution: "手动执行",
        },
        btn: {
          AddParent: "新增父级",
          AddChild: "新增子项",
          choice: "选择",
          FixedValue: "固定值",
          Task: "任务",
          And: "且",
          Or: "或",
        },
        msg: {
          msg1: "是否执行此任务?"
        }

      },

      // 判定结果管理
      MResults: {
        title: {
          MResults: "判定结果管理",
        },
        field: {
          resultsOfEnforcement: "执行结果",
          executionTime: "执行时间",
          JudgmentResults: "判定结果",
        },
      },

      //AI
      //Ai算法
      AiSuanfa: {
        title: {
          suafa: "算法管理",
        },
        btn: {
          all: "全部",
          search: "搜索",
          itemBtn: "训练模型",
          gf: "官方",
          yuce: "预测",
        },


        field: {
          label: "算法名称",
          sfFl: "算法分类",
          name: "算法名称",
          dbtype: "算法归属",
          source: "算法分类",
          describe: "算法描述",
          file: "选择文件",
          xl: "训练",
          placeholder: "输入数据",
          modeName: "模型名称",
          modePath: "模型路径",
          AlgorithName: "算法名称",
          x: "x轴",
          y: "y 轴",
          x1: "请选择X轴特性",
          y1: "请选择y轴特性",
          zh: "中文",
          field: "字段",
          dataType: "数据类型",
          mxpg: "模型评估",
          ycjg: "预测结果",
          NumberOfClassClusters: "类簇数",
          TrainingResults: "训练结果",
        },

      },

      AiModel: {
        msg: "请输入接口名称",
        name: "模型列表",
        field: {
          id: "模型ID",
          model_name: "模型名称",
          algorithm_name: "算法名称",
          model_input: "模型输入",
          model_path: "模型路径",
          model_evaluation: "模型评估",
        }
      },
      AiResult: {
        msg: "请输入模型名称",
        name: "预测结果",
        field: {
          predictId: "预测ID",
          modelId: "模型Id",
          predict_name: "预测标识",
          algorithm_name: "算法名称",
          model_name: "模型名称",
        },
        title: "模型详情",
      },
      // 补充的
      DComission:{
        InterfaceCollection:"接口采集",
        InterfaceCollectionAdd:"接口采集新增",
        run:"执行中",
        stop:"已暂停",
        Execute:"启动",
       id:"主键ID",
       tip:"提示",
       false:"取 消",
       sure:"确 定",
       to:"至",
       startdate:"开始日期",
       enddate:"结束日期",
       startTime:"开始时间",
       endTime:"结束时间",
       tsField:"时间标识",
       tenRun:"近10次运行时间",
       State:"状态",
       reqbody:"请求体",
       Addreqbody:"添加请求体",
       ConversionInfo:"转换信息",
       AddConversionInfo:"添加转换信息",
       fieldName:"字段名称",
       targetTable:"目标表名称",
       srcField:"来源字段",
       targetField:"目标字段",
       toInfo:"注入信息",
       keycolumn:"主键拼接",
       seeText1:"查看",
       seeText2:"折叠",
       field:"字段",



       msg1:"是否启动此任务？",
       msg2:"是否暂停此任务？",
       msg3:"执行成功！",
       msg4:"启动成功！",
       msg5:"暂停成功！",
       runtime:"执行时间",
       selectPlaceholder:"请选择",


      },

      //kf补充
      KFDComission:{
        name:"名称",
        kftitle:"kafKa数据列表",
        brokerList:"集群地址",
      },
      //离线采集补充
      offlineDComission:{
        atuoMatching:"匹配对应关系",
        modelState:"是否建模",
        is:"是",
        no:"否",
        ysj:"元数据同步",
        readerDatasourceId:"读取数据源ID",
        writerDatasourceId:"写入数据源ID",
        readerTable:"读取表",
        writerTable:"写入表",
        runState:"状态",
         delmsg:"删除成功",
         deltip:"请选择删除数据",
          yuaninfo:"源信息",
          sjkid:"数据库接口",
          bmid:"表名接口",
          incrTimeFeild:"增量时间字段",
          chooseTime:"选择日期时间",
          incrTimeFormat:"时间格式",
          incrTimeFormat2:"其他时间格式",
          mbinfo:"目标信息",
          postSql:"后置SQL语句",
          yuan:"源",
          filedzs:"字段注释",
          type:"类型",
          gdtime:"固定时间范围",
          dttime:"动态时间范围",
          star:"开始",
          end:"结束",
          dygx:"对应关系",
          see:"查看",
          other:"其他",
          rule1:"请输入名称",
          rule2:"请选择周期",
          dengyu:"等于",
          budengyu:"不等于",
          qujian:"区间",
          dayu:"大于",
          xiaoyu:"小于",
          dadengyu:"大于等于",
          xiaodengyu:"小于等于",
          baohan:"包含",
          bubaohan:"不包含",
          zhedie:"折叠",
          getinfo:"点击开始获取信息",
          savetip:"表所有字段请选择对应关系",
          editmsg:"修改成功",
          savemsg:"保存成功",
          czmsg:"操作成功",
          jieximsg:"解析成功",
        //  this.$t('cip.dc.offlineDComission.modelState')

      },
      EXCELDComission:{
        title:"EXCEL数据导入列表",
        title2:"EXCEL数据导入",
        yuanname:"源名称",
        sourceName:"数据源",
        tableName:"表名称",
        createUser:"创建人",
        createTime:"创建时间",
        choosefile:"选择文件",
        //  this.$t('cip.dc.EXCELDComission.title')
      },
      datamapDComission:{
         all:"总计",
         tableCount:"数据表",
         dataObjectCount:"数据对象",
         indexCount:"数据指标",
         indexzt:"指标主题",
         yes:"是",
         no:"否",
         //  this.$t('cip.dc.datamapDComission.all')
      },
      dataobjDComission:{
        searchtj:"查询条件",
        filedname:"字段名称",
        type:"类型",
        caozuo:"操作",
        searchres:"查询结果",
        del:"删除",
        basicinfo:"基础信息",
        indexCode:"指标接口",
        placeholder1:"请输入关键词",
        sortField:"排序字段",
        sort:"排序",
        upsort:"升序",
        downsort:"降序",
        msg1:"请填写完整时间",
        shujuku:"数据库",
        api:"API接口",
        indexzb:"指标",
        search:"查询",
        //  this.$t('cip.dc.dataobjDComission.searchtj')
      },
      PrimordialDComission:{
        title:"添加血缘",
        msg1:"请选择要删除的目录!",
        back:"返回",
        //  this.$t('cip.dc.PrimordialDComission.title')
      },
      apiDComission:{
       title:"数据API编辑",
       save:"保存",
       caozuo:"操作",
       del:"删除",
//  this.$t('cip.dc.apiDComission.title')
      },
      qualityDComission:{
        rule1:"请输入任务名称",
        rule2:"请选择主题",
        rule3:"请选择数据对象",
        rule4:"请输入限制条数",
        rule5:"请选择对比字段",
        title1:"检测任务管理",
        title2:"判定结果管理",
        msg1:"是否删除此任务？",
        placeholder1:"请选择关系",
        inputTaskValue:"输出值",
        judgeCompareResultName:"判断结果",
        caozuo:"操作",
        see:"查看",
        zhedie:"折叠",
        judgeName:"方法名称",
        judgeResult:"执行结果",
        zhengchang:"正常",
        ychang:"异常",
        createTime:"执行时间",
        //  this.$t('cip.dc.qualityDComission.rule1')
      },
      AIDComission:{
       title1:"算法配置",
       title2:"算法编辑配置",
       placeholder1:"请输入名称",
       name:"名称",
       code:"编码",
       url:"地址",
       sort:"排序",
       createby:"更新人",
       createDate:"更新时间",
       danxuan:"单选",
       duoxuan:"多选",
       buxuan:"不选",
       tiaocan:"调参",
       add:"添加",
       argName:"参数名称",
       argCode:"参数编码",
       argSort:"参数排序",
       chongzhi:"重置",
       choosezhou:"选择轴特性",
       isX:"是否为x轴",
       isY:"是否为y轴",
       xmsg:"此模型X轴限制单选！",
       ymsg:"此模型Y轴限制单选！",
       noy:"此模型Y轴限制不选！",
      // this.$t('cip.dc.AIDComission.title1')
      },
      largeBlock:{
        title:"大块识别展示",
        recordTime:"时间",
      //  this.$t('cip.dc.largeBlock.title')
      }


    },
    ...indexManage
  },
  tip: "提示",
  title: "中材国际智能安全平台",
  logoutTip: "退出系统, 是否继续?",
  submitText: "确定",
  cancelText: "取消",
  search: "请输入搜索内容",
  menuTip: "没有发现菜单",
  moreMenu: "更多",
  wel: {
    agency: "代办事宜",
    more: "查看更多",
    myreport: "我的报表",
    info: "早安，Avuex一款超乎你想象的框架！",
    dept: "我是avue团队",
    team: "团队内排名",
    project: "项目访问",
    count: "项目数",
    todo: "我的代办",
    knowledge: "知识库",
  },
  route: {
    classificationRole:"角色配置",
    yearProducePlan: "年度生产计划",
    yearProducePlanAdd: "年度生产计划新增",
    yearProducePlanEdit: "年度生产计划编辑",
    yearProducePlanView: "年度生产计划查看",
    yearPurchasePlan: "年度采购计划",
    yearPurchasePlanAdd: "年度采购计划新增",
    yearPurchasePlanEdit: "年度采购计划编辑",
    yearPurchasePlanView: "年度采购计划查看",
    yearSalePlan: "年度销售计划",
    yearSalePlanAdd: "年度销售计划新增",
    yearSalePlanEdit: "年度销售计划编辑",
    yearSalePlanView: "年度销售计划查看",
    monthProducePlan: "月度生产计划",
    monthProducePlanAdd: "月度生产计划新增",
    monthProducePlanEdit: "月度生产计划编辑",
    monthProducePlanView: "月度生产计划查看",
    monthPurchasePlan: "月度采购计划",
    monthPurchasePlanAdd: "月度采购计划新增",
    monthPurchasePlanEdit: "月度采购计划编辑",
    monthPurchasePlanView: "月度采购计划查看",
    monthSalePlan: "月度销售计划",
    monthSalePlanPlanAdd: "月度销售计划新增",
    monthSalePlanPlanEdit: "月度销售计划编辑",
    monthSalePlanPlanView: "月度销售计划查看",
    indexCfg: "指标配置",
    recordV3: "质检记录(列表)",
    deviceFaultAdd: "停机原因新增编辑",
    deviceFault: "停机原因",
    inspectionReport: "质检检样报告单",
    periodDate: "期间日期管理",
    sampleView: "样品查看",
    params: "参数管理",
    paramsAdd: "参数管理新增",
    paramsEdit: "参数管理编辑",
    paramsView: "参数管理查看",
    period: "会计期间",
    powerFgp: "峰谷平配置管理",
    powerFgpAdd: "峰谷平配置管理新增",
    powerFgpEdit: "峰谷平配置管理编辑",
    powerFgpView: "峰谷平配置管理查看",
    qualityScheduling: "质量调度",
    unit: "单位管理",
    unitAdd: "单位管理新增",
    unitEdit: "单位管理编辑",
    unitView: "单位管理查看",
    extItem: "ERP物料",
    extItemAdd: "ERP物料新增",
    extItemEdit: "ERP物料编辑",
    extItemView: "ERP物料查看",
    supplier: "供应商基本信息",
    supplierAdd: "供应商基本信息新增",
    supplierEdit: "供应商基本信息编辑",
    supplierView: "供应商基本信息查看",
    customer: "客户基本信息",
    customerAdd: "客户基本信息新增",
    customerEdit: "客户基本信息编辑",
    customerView: "客户基本信息查看",
    batch: "批次信息",
    record: "质检记录(卡片)",
    periodQualityTest: "过程质检",
    sample: "样品管理",
    sampleRecord: "样品记录",
    sampleRecordEdit: "样品记录编辑",
    sampleRecordAdd: "样品记录新增",
    sampleRecordView: "样品记录查看",
    batchDetails: "批次明细",
    itemPurchaseAdd: "一卡通入厂新增",
    itemPurchaseEdit: "一卡通入厂编辑",
    itemPurchaseView: "一卡通入厂查看",
    productSaleAdd: "一卡通出厂新增",
    productSaleEdit: "一卡通出厂编辑",
    productSaleView: "一卡通出厂查看",
    recordObj: "质检对象",
    recordObjAdd: "质检对象新增",
    recordObjEdit: "质检对象编辑",
    recordObjView: "质检对象查看",
    batchSplit: "切批规则设置",
    batchSplitView: "切批规则查看",
    batchSplitEdit: "切批规则编辑",
    batchSplitAdd: "切批规则新增",
    itemBalance: "物料平衡",
    itemBalanceAdd: "物料平衡新增",
    itemBalanceEdit: "物料平衡编辑",
    itemBalanceView: "物料平衡查看",
    point: "取样点",
    pointAdd: "取样点新增",
    pointEdit: "取样点编辑",
    pointView: "取样点查看",
    noticeModel: "通知单模板",
    noticeModelEdit: "通知单模板编辑",
    dischargingNotice: "卸料通知单",
    incomingMaterialUsage: "进厂物资启用通知单",
    qualityControlAdjustmentNotice: "质量控制调整通知单",
    factoryControlNotice: "出厂控制通知单",
    otherNotices: "其他通知单",
    qualityNotice: "质量通知单",
    qmTeam: "质检组",
    qmIndex: "质检指标",
    qmIndexAdd: "质检指标新增",
    qmIndexEdit: "质检指标编辑",
    qmIndexView: "质检指标查看",
    deviceIndex: "质检设备",
    deviceIndexDetail: "质检设备详情",
    deviceData: "质检设备数据采集",
    indexFormula: "指标公式",
    indexFormulaAdd: "指标公式新增",
    indexFormulaEdit: "指标公式编辑",
    indexFormulaView: "指标公式查看",
    qmParam: "指标参数",
    qmParamAdd: "指标参数新增",
    qmParamView: "指标参数查看",
    qmParamEdit: "指标参数编辑",
    codeRuleEdit: "编码规则编辑",
    codeRuleAdd: "编码规则新增",
    codeRule: "编码规则",
    codeRuleView: "编码规则查看",
    feedback: "意见反馈",
    feedbackView: "意见反馈查看",
    functionRecord: "功能记录",
    functionFrequency: "功能频次",
    buttonRecord: "按钮记录",
    buttonFrequency: "按钮频次",
    dictbizConfig: "业务字典",
    dictConfig: "系统字典",
    config: "字典配置",
    international: "国际化修改",
    serviceType: "接口信息",
    dataLink: "数据连接",
    dataLinkEdit: "数据连接新增",
    dataInterface: "数据接口",
    visualPortal: "门户设计",
    dataModel: "数据建模",
    abilityDesign: "功能设计",
    guration: "组态列表",
    monitorAdd: "区域信息新增",
    monitor: "区域信息",
    attendance: "打卡设置",
    clockingRecord: "打卡记录",
    attendanceAdd: "打卡设置新增",
    aboutUs: "关于我们",
    aboutUsEdit: "关于我们新增",
    JobTask: "任务列表",
    JobTaskEdit: "任务列表编辑",
    JobTaskAdd: "任务列表新增",
    dispatch: "调度日志",
    dispatchEdit: "调度日志新增",
    actuator: "执行器管理",
    actuatorEdit: "执行器管理新增",
    oss: "对象存储",
    attach: "附件管理",
    folderManage: "文件夹管理",
    info: "个人信息",
    website: "",
    avuexwebsite: "",
    dashboard: "首页",
    more: "更多",
    tags: "标签",
    store: "本地存储",
    logs: "日志监控",
    table: "表格",
    form: "表单",
    top: "返回顶部",
    data: "数据展示",
    permission: "权限",
    test: "测试页面",
    apiLog: "接口日志",
    usualLog: "通用日志",
    errorLog: "错误日志",
    localErrorLog: "本地错误日志",
    error: "异常页面",
    api: "全局函数",
    report: "报表管理",
    myReport: "我的报表",
    knowledge: "知识库",
    user: "用户管理",
    post: "岗位管理",
    postEdit: "岗位管理新增",
    userAdd: "用户管理新增",
    userEdit: "用户管理编辑",
    dict: "系统字典",
    dictEdit: "系统字典新增",
    dictbiz: "业务字典",
    dictbizEdit: "业务字典新增",
    menu: "菜单管理",
    menuEdit: "菜单管理新增",
    topmenu: "顶部菜单",
    param: "参数管理",
    paramEdit: "参数管理新增",
    tenant: "租户管理",
    tenantEdit: "租户管理新增",
    client: "应用管理",
    clientEdit: "应用管理新增",
    serialNumber: "编码规则",
    serialNumberEdit: "编码规则新增",
    index: "运维调度",
    role: "角色管理",
    roleEdit: "角色管理新增",
    datascope: "数据权限",
    apiscope: "接口权限",
    model: "模型设计",
    process: "模型设计新增",
    forms: "表单设计",
    formsEdit: "表单设计新增",
    deployment: "部署管理",
    buttonEdit: "流程按钮新增",
    button: "流程按钮",
    categoryEdit: "流程分类新增",
    category: "流程分类",
    formDefaultValues: "表单默认值",
    formDefaultValuesEdit: "表单默认值新增",
    conditionEdit: "流程表达式新增",
    condition: "流程表达式",
    done: "办结流程",
    list: "流程列表",
    proxy: "流程代理",
    proxyEdit: "流程代理新增",
    adInfo: "轮播管理",
    broadcast: "广播管理",
    usual: "通用日志",
    apis: "接口日志",
    errors: "错误日志",
    appMenu: "手机端菜单管理",
    appMenuAdd: "手机端菜单管理新增",
    appMenuEdit: "手机端菜单管理编辑",
    appVersion: "手机端版本",
    appVersionadd: "手机端版本新增",
    bottomMenu: "手机端底部菜单管理",
    bottomMenuEdit: "手机端底部菜单管理新增",
    interfaceList: "接口管理",
    interfaceListEdit: "接口管理新增",
    interfaceLogEdit: "接口日志新增",
    interfaceLog: "接口日志",
    channel: "通道管理",
    channelAdd: "通道新增",
    channelEdit: "通道编辑",
    channelView: "通道查看",
    templates: "消息模板管理",
    templatesAdd: "模板新增",
    templatesEdit: "模板编辑",
    sendInfoEdit: "消息新增",
    sendInfo: "消息清单",
    messageBasicInformation: "消息基本信息",
    mediumscreen: "中屏管理",
    onineUser: "在线用户",
    mediumscreenmanageAdd: "中屏管理新增",
    mediumscreenmanageEdit: "中屏管理编辑",
    mediumscreenmanageView: "中屏管理查看",
    parameter: "系统参数",
    meterEdit: "系统参数新增",
    noticeAdd: "系统公告新增",
    noticeEdit: "系统公告编辑",
    notices: "系统公告",
    processStart: "新建流程",
    processTodo: "待办事宜",
    processSend: "我的请求",
    processMyDone: "我的已办",
    processDone: "办结事宜",
    processClaim: "待签事务",
    processCopy: "抄送事宜",
    paramAdd: "参数管理",
    adInfoEdit: "轮播管理新增",
    broadcastEdit: "广播管理新增",
    knowledgeAdd: "知识库新增",
    knowledgeEdit: "知识库编辑",
    knowledgeView: "知识库查看",
    reportAdd: "报表管理新增",
    reportEdit: "报表管理编辑",
    reportView: "报表管理查看",
    messageList: "我的消息",
    dispatchStart: "生产调度",
    demandPlan: "需求计划",
    salePlan: "销售计划",
    producePlan: "生产计划",
    operatePlan: "经营计划",
    operatePlanDetail: "经营计划详情",
    stockIn: "物料入库",
    oneCardStorageAdd: "一卡通入库新增",
    oneCardStorageEdit: "一卡通入库编辑",
    oneCardStorageView: "一卡通入库查看",
    dcsAdd: "DCS入库新增",
    dcsEdit: "DCS入库编辑",
    dcsView: "DCS入库查看",
    manualAdd: "手工入库新增",
    manualEdit: "手工入库编辑",
    manualView: "手工入库查看",
    stockOut: "物料出库",
    logisticsAddTitle: "一卡通出库新增",
    logisticsEditTitle: "一卡通出库编辑",
    logisticsViewTitle: "一卡通出库查看",
    dcsAddTitle: "DCS出库新增",
    dcsEditTitle: "DCS出库编辑",
    dcsViewTitle: "DCS出库查看",
    manualAddTitle: "手工出库新增",
    manualEditTitle: "手工出库编辑",
    manualViewTitle: "手工出库查看",
    stockCheck: "物料盘点",
    materialInventoryAdd: "物料盘点新增",
    materialInventoryEdit: "物料盘点编辑",
    materialInventoryView: "物料盘点查看",
    stock: "库存查询",
    centralControl: "测点订阅",
    classReport: "交接班记录",
    classReportAdd: "交接班记录新增",
    classReportEdit: "交接班记录编辑",
    classReportView: "交接班记录查看",
    deviceQuery: "开停机查询",
    deviceStatus: "设备状态",
    deviceStatusAdd: "设备状态新增",
    deviceStatusEdit: "设备状态编辑",
    deviceStatusView: "设备状态查看",
    classReportDtlHour: "生产采集修改",
    deviceRecord: "设备开停机记录",
    deviceRecordAdd: "设备开停机记录新增",
    deviceRecordEdit: "设备开停机记录编辑",
    deviceRecordView: "设备开停机记录查看",
    classReportDtl: "交接班记录修改",
    device: "设备管理",
    deviceAdd: "设备管理新增",
    deviceView: "设备管理查看",
    deviceEdit: "设备管理编辑",
    modelManagement: "模型管理",
    modelAdd: "模型管理新增",
    modelView: "模型管理查看",
    modelEdit: "模型管理编辑",
    protocol: "协议管理",
    protocolAdd: "协议管理新增",
    protocolView: "协议管理查看",
    protocolEdit: "协议管理编辑",
    workRecord: "上班签到",
    projectTc: "项目管理",
    projectTcAdd: "项目新增",
    projectTcEdit: "项目编辑",
    projectTcView: "项目查看",
    processSetting: "工艺配置",
    processSettingAdd: "工艺配置新增",
    processSettingEdit: "工艺配置编辑",
    class: "班组",
    classAdd: "班组新增",
    classEdit: "班组编辑",
    classView: "班组查看",
    classShift: "班次",
    classShiftEdit: "班次编辑",
    classShiftView: "班次查看",
    classShiftRule: "排班规则",
    classShiftRuleAdd: "排班规则新增",
    classShiftRuleEdit: "排班规则编辑",
    classShiftRuleView: "排班规则查看",
    classCalendar: "排班日历",
    classCalendarView: "排班日历查看",
    productionMaterial: "生产物料",
    productionMaterialAdd: "生产物料新增",
    productionMaterialEdit: "生产物料编辑",
    productionMaterialView: "生产物料查看",
    warehouseManagement: "仓库管理",
    warehouseAdd: "仓库新增",
    warehouseEdit: "仓库编辑",
    warehouseView: "仓库查看",
    physicalWarehouseManagement: "物理仓库管理",
    physicalWarehouseAdd: "物理仓库新增",
    physicalWarehouseEdit: "物理仓库编辑",
    physicalWarehouseView: "物理仓库查看",
    itemFormula: "生产配方",
    itemFormulaEdit: "生产配方编辑",
    itemFormulaView: "生产配方查看",
    processManagement: "工序管理",
    processAdd: "工序新增",
    processEdit: "工序编辑",
    regionalMaintenance: "区域维护",
    regionalMaintenanceAdd: "区域维护新增",
    regionalMaintenanceEdit: "区域维护编辑",
    pointsConfiguration: "点位配置",
    cameraAdd: "摄像头信息新增",
    cameraEdit: "摄像头信息编辑",
    RMPAdd: "区域监控点位新增",
    RMPEdit: "区域监控点位编辑",
    EMPAdd: "设备监控点位新增",
    EMPEdit: "设备监控点位编辑",
    videoAdd: "大屏视频配置新增",
    videoEdit: "大屏视频配置编辑",
    deviceManagement: "设备管理",
    mainDeviceCateAdd: "主设备分类新增",
    mainDeviceCateEdit: "主设备分类编辑",
    subDeviceCateAdd: "子设备分类新增",
    subDeviceCateEdit: "子设备分类编辑",
    mainDeviceAdd: "主设备新增",
    mainDeviceEdit: "主设备编辑",
    subDeviceAdd: "子设备新增",
    subDeviceEdit: "子设备编辑",
    sparePartAdd: "备件清单新增",
    sparePartEdit: "备件清单编辑",
    postManagement: "机构管理",
    rankAdd: "职级新增",
    rankEdit: "职级编辑",
    sectionAdd: "管理工段新增",
    sectionEdit: "管理工段编辑",
    majorAdd: "管理专业新增",
    majorEdit: "管理专业编辑",
    responsibilityAdd: "职责新增",
    responsibilityEdit: "职责编辑",
    shiftModeAdd: "轮班方式新增",
    shiftModeEdit: "轮班方式编辑",
    waterRate: "水份系数",
    monitoring: "数采监控",
    monitoringEdit: "数采监控编辑",
    pointConfiguration: "缓存配置",
    cacheData: "缓存查询",
    modelManager: "模型管理",
    indexCfgEdit: "指标配置编辑",
    indexCfgAdd: "指标配置新增",
    indexCfgView: "指标配置查看",
    //index
    appCategoryView: "指标查看",
    appCategoryIndex: "指标体系",

  },
  login: {
    title: "登录 ",
    info: "中材国际智能安全平台",
    tenantId: "请输入租户ID",
    username: "请输入账号",
    password: "请输入密码",
    wechat: "微信",
    qq: "QQ",
    github: "github",
    gitee: "码云",
    phone: "请输入手机号",
    code: "请输入验证码",
    submit: "登录",
    userLogin: "账号密码登录",
    phoneLogin: "手机号登录",
    thirdLogin: "第三方系统登录",
    msgText: "发送验证码",
    msgSuccess: "秒后重发",
    titleText: "登录系统"
  },
  navbar: {
    home: "首页",
    mail: "消息通知",
    logOut: "退出登录",
    userinfo: "个人信息",
    switchDept: "部门切换",
    dashboard: "首页",
    lock: "锁屏",
    bug: "没有错误日志",
    bugs: "条错误日志",
    bugSet: "错误日志",
    language: "中英文",
    notice: "消息通知",
    theme: "主题",
    themes: "设置主题",
    color: "换色",
    apiLog: "接口日志",
    errorLog: "错误日志",
    usualLog: "通用日志编辑",
    feedback: "反馈意见"

  },
  userinfo: {
    userConfig: "配置人员",
    weakPassword: "弱口令",
    openWeakPassword: "开启弱口令",
    closeWeakPassword: "关闭弱口令",
    personalDetails: "个人信息",
    avatar: "头像",
    userinfotip: "只能上传jpg/png用户头像，且不超过500kb",
    name: "姓名",
    username: "用户名",
    phone: "手机号",
    isTenant: "租户状态",
    defaultConfig: "默认配置",
    themeColor: "主题颜色",
    theme: "请检查主题颜色字典配置",
    openTenant: "开启租户",
    closeTenant: "关闭租户",
    email: "邮箱",
    editPasswd: "修改密码",
    oldpasswd: "原密码",
    newPasswd: "新密码",
    confirmPasswd: "确认密码",
    chooseLangue: "选择语言",
    passwdMsg: "密码应当至少8位且含有数字、大小写字母及特殊字符",
    updataPassWd: "密码修改",
    selectionUserInfo: "选择用户",
    differentPassword: "两次密码不一致",
    oldPasswordNotNull: "原密码不能为空",
    newPasswordNotNull: "新密码不能为空",
    title1: "* 注意",
    title2: "：首次登陆请重置密码",
    sysConfig: "系统配置",
    sysIcon: "系统图标",
    LoginBJ: "登录页背景图片",
    loginIcon: "登录图标",
    NavigationIcon: "导航图标",
    LOGOIcon: "LOGO图标",
    AppIcon: "APP图标",
    SysName: "系统名称",
    title: "标题",
    Sysedition: "平台名称",
    companyName: "公司名称",
    copyright: "版权信息",
    companyCode: "公司简称",
    companyAddress: "公司地址",
    companyContacts: "公司法人",
    companyTelePhone: "公司电话",
    companyEmail: "公司邮箱",
    sysDescription: "系统描述",
    save: "提 交",
    clear: "清 空",
  },
  tagsView: {
    add: "添加",
    search: "搜索",
    shortcutMenu: "快捷菜单",
    menu: "更多",
    clearCache: "清除缓存",
    closeOthers: "关闭其它",
    closeAll: "关闭所有",
    addShortcutTip: "请选择下级菜单",
    language: "语言",
    document: "在线帮助文档",
    setup: "设置",
    chat: "聊天",
    appqrcode: "App二维码",
    feedback: "意见反馈",
    screenfullF: "退出全屏",
    screenfull: "全屏",
    mobiledownload: "手机版下载",
    messageSend:"我的消息",
  },
  gateway:{
    todo:"待办"
  },
  ...datacenter,
  ...mdModule,
  ...pmModule,
  ...qmModule,
  ...platModule,
  ...cpsModule,
  // ...formFillingPlan
}
