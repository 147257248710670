var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.file.fileExtension && _vm.imgTypeList.includes(_vm.file.fileExtension)
        ? _c("el-image", {
            ref: "imageRef",
            staticStyle: { width: "0", height: "0" },
            attrs: {
              src: _vm.define.comUrl + _vm.file.url,
              "preview-src-list": [_vm.define.comUrl + _vm.file.url],
            },
          })
        : _c(
            "el-dialog",
            _vm._g(
              _vm._b(
                {
                  staticClass: "SNOWS-full-dialog",
                  attrs: {
                    "close-on-click-modal": false,
                    "modal-append-to-body": false,
                    fullscreen: "",
                    "lock-scroll": "",
                    "append-to-body": "",
                    "show-close": false,
                    modal: false,
                  },
                },
                "el-dialog",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            ),
            [
              _c("div", { staticClass: "SNOWS-full-dialog-header" }, [
                _c("div", { staticClass: "header-title" }, [
                  _c("p", { staticClass: "header-txt" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "options" },
                  [
                    _c(
                      "el-form",
                      [
                        _vm.showDownload
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.handleDownload },
                              },
                              [_vm._v("下载")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { disabled: false },
                            on: {
                              click: function ($event) {
                                return _vm.goBack()
                              },
                            },
                          },
                          [_vm._v("取消")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "main" }, [
                _c("div", { staticClass: "content" }, [
                  _c("iframe", {
                    attrs: {
                      width: "100%",
                      height: "100%",
                      src: _vm.url,
                      frameborder: "0",
                    },
                  }),
                ]),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }