import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getLineList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduexamuser/page`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduexamuser/detail`,
    method: 'get',
    params: {
      id
    }
  })
}

export const removeLine = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduexamuser/remove`,
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduexamuser/submit`,
    method: 'post',
    data: row
  })
}
export const eduexamuser = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduexamuser/submit`,
    method: 'post',
    data: row
  })
}
export const update = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduexamuser/submit`,
    method: 'post',
    data: row
  })
}

