import httpService from "@/router/axios";

// 安全审计配置综合审计结果-   分页
export const $_getPageList = (params) => {
    return httpService({
      url: "/api/ivystar-wisdom-security/workAuditSingleResultConfig/page",
      params,
    });
  };
// 安全审计配置综合审计结果-   新增
export const $_addSaveList = (params) => {
    return httpService({
      url: "/api/ivystar-wisdom-security/workAuditSingleResultConfig/save",
      method: 'post',
      data: params,
    });
  };

// 安全审计配置综合审计结果-   编辑
export const $_updateData = (params) => {
    return httpService({
      url: "/api/ivystar-wisdom-security/workAuditSingleResultConfig/update",
      method: 'post',
      data: params,
    });
  };
// 安全审计配置综合审计结果-   删除
export const $_removeData = (params) => {
    return httpService({
      url: "/api/ivystar-wisdom-security/workAuditSingleResultConfig/remove",
      method: 'post',
      params,
    });
  };
