import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 查询
export const getSignList = (current, size, params) => {
    return request({
        url: ServerNameEnum.SERVER_SYSTEM_ + '/bssign/list',
        method: 'get',
        params: {
            ...params,
            current,
            size,
        }
    })
}

// 查询详情
export const getSignDetail = (id) => {
    return request({
        url: ServerNameEnum.SERVER_SYSTEM_ + '/bssign/detail',
        method: 'get',
        params: {
            id
        }
    })
}

// 新增 修改
export const addSigns = (row) => {
    return request({
        url: ServerNameEnum.SERVER_SYSTEM_ + '/bssign/submit',
        method: 'post',
        data: row
    })
}

// 删除
export const remove = (ids) => {
    return request({
        url: ServerNameEnum.SERVER_SYSTEM_ + '/bssign/remove',
        method: 'post',
        params: {
            ids
        }
    })
}