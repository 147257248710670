import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';///api/sinoma-hse-prj


export const page = (current,size,params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/dangerousprojectlist/page',
      method: 'get',
      params:{
        current,
        size,
        ...params
      }
    })
  }

export const submit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/dangerousprojectlist/submit',
    method: 'post',
    data
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/dangerousprojectlist/remove',
    method: 'post',
    params: {ids}
  })
}

export const detail = (id) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/dangerousprojectlist/detail',
      method: 'get',
      params: {id}
    })
  }