var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    { staticClass: "groupTitle", style: { "text-align": _vm.contentPosition } },
    [_vm._v(_vm._s(_vm.content))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }