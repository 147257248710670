import Layout from '@/page/index/'

export default [
  {
    path: '/safeworkInspection',
    component: Layout,
    children: [
      {
        path: 'add',
        name: '安全工作管理',
        meta: {
        },
        component: () =>
          import('@/views/business/safeworkInspection/add')
      },
      {
        path: 'edit',
        name: '安全工作管理',
        meta: {
        },
        component: () =>
          import('@/views/business/safeworkInspection/add')
      },
      {
        path: 'detail',
        name: '安全工作管理',
        meta: {
        },
        component: () =>
          import('@/views/business/safeworkInspection/add')
      },
    ]
  }
]
