import Layout from '@/page/index/'

export default [
  {
    path: '/training',
    component: Layout,
    redirect: '/training/paper',
    children: [
      {
        path: 'paper/edupaperView',
        name: '试卷查看',
        meta: {
        },
        component: () =>
          import(  '@/views/business/training/paper/edupaperView')
      },
      {
        path: 'paper/edupaperAdd',
        name: '试卷新增',
        meta: {
        },
        component: () =>
          import(  '@/views/business/training/paper/edupaperEdit')
      },
      {
        path: 'paper/edupaperEdit',
        name: '试卷编辑',
        meta: {
        },
        component: () =>
          import(  '@/views/business/training/paper/edupaperEdit')
      },
      {
        path: 'paper/edupaperquestion',
        name: '试卷题库',
        meta: {
        },
        component: () =>
          import(  '@/views/business/training/paper/edupaperquestion')
      },
      {
        path: 'question/eduquestionsView',
        name: '查看题库',
        meta: {
        },
        component: () =>
          import(  '@/views/business/training/question/eduquestionsView')
      },
      {
        path: 'question/eduquestionsEdit',
        name: '编辑题库',
        meta: {
        },
        component: () =>
          import(  '@/views/business/training/question/eduquestionsEdit')
      },
      {
        path: 'exam/eduexamEdit',
        name: '编辑考试',
        meta: {
        },
        component: () =>
          import(  '@/views/business/training/exam/eduexamEdit')
      },
      {
        path: 'exam/eduexamView',
        name: '查看考试',
        meta: {
        },
        component: () =>
          import(  '@/views/business/training/exam/eduexamView')
      },
      {
        path: 'exam/edupaperselect',
        name: '选择试卷',
        meta: {
        },
        component: () =>
          import(  '@/views/business/training/exam/edupaperselect')
      },
      {
        path: 'exam/eduexampaper',
        name: '自主练习',
        meta: {
        },
        component: () =>
          import(  '@/views/business/training/exam/eduexampaper')
      },
    ]
  },
  {
    path: '/safetyenment/train',
    component: Layout,
    redirect: '/safetyenment/train/myExam',
    children: [
      {
        path: 'myExam/eduexampaper',
        name: '考试',
        meta: {
        },
        component: () =>
          import(  '@/views/business/safetyenment/train/myExam/eduexampaper')
      },
      {
        path: 'myExam/eduuserexam',
        name: '我的考试',
        meta: {
        },
        component: () =>
            import(  '@/views/business/safetyenment/train/myExam/eduuserexam.vue')
      },
      {
        path: 'myExam/eduuserexamParticulars',
        name: '考试记录',
        meta: {
        },
        component: () =>
          import(  '@/views/business/safetyenment/train/myExam/eduuserexamParticulars.vue')
      },
      {
        path: 'myExam/examResults',
        name: '考试',
        meta: {
        },
        component: () =>
          import(  '@/views/business/safetyenment/train/myExam/examResults')
      },
    ]
  },
  {
    path: '/training/onSiteTraining',
    component: Layout,
    redirect: '/training/onSiteTraining',
    children: [
      {
        path: '/business/training/onSiteTraining/edit',
        name: '平台课程',
        meta: {
        },
        component: () =>
          import(  '@/views/business/training/onSiteTraining/edit.vue')
      }
    ]
  }
]
