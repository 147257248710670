import request from '@/router/axios';

export const getList = (params) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/accrecord/list',
    method: 'get',
    params
  })
}
export const submit = (data) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/accrecord/submit',
    method: 'post',
    data
  })
}
export const process = (data) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/accrecord/process',
    method: 'post',
    data
  })
}
export const detail = (params) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/accrecord/detail',
    method: 'get',
    params
  })
}
export const remove = (data) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/accrecord/remove',
    method: 'post',
    data
  })
}
export const getCode = (params) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/accrecord/getCode',
    method: 'get',
    params
  })
}
export const HD_SOURCE_TYPE = (key) => {
  return request({
    url: `/api/sinoma-system/dict-biz/dictionary?code=${key}`,
    method: 'get',
  })
}
