import httpService from "@/router/axios";

// 树  审计要素
export const $_getWorkAuditConfigTree = (params) => {
    return httpService({
        url: `/api/ivystar-wisdom-security/workAuditConfig/tree`,
        method: "get",
        params,
    });
};

// 新增  审计要素
export const $_saveWorkAuditConfig = (params) => {
    return httpService({
        url: "/api/ivystar-wisdom-security/workAuditConfig/save",
        method: "post",
        data: params,
    });
};

// 详情 安全审计计划详情人员
export const $_getwisdomsecurity = (params) => {
    return httpService({
        url: `/api/ivystar-wisdom-security/wisdomsecurity/list2`,
        method: "get",
        params,
    });
};
// 修改  审计要素
export const $_submit = (params) => {
    return httpService({
        url: "/api/ivystar-wisdom-security/workAuditConfig/update",
        method: "post",
        data: params,
    });
};

// 详情 审计要素
export const $_getWorkAuditConfigDetail = (params) => {
    return httpService({
        url: `/api/ivystar-wisdom-security/workAuditConfig/detail`,
        method: "get",
        params,
    });
};

// 删除  审计要素
export const $_removeWorkAuditConfig = (params) => {
    return httpService({
        url: "/api/ivystar-wisdom-security/workAuditConfig/remove",
        method: "post",
        params,
    });
};

// 新增  审计要素  上移、下移
export const $_saveMoveUpOrDown = (params) => {
    return httpService({
        url: "/api/ivystar-wisdom-security/workAuditConfig/moveUpOrDown",
        method: "post",
        params,
    });
};
