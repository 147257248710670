import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/param/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/param/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/param/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/param/submit',
    method: 'post',
    data: row
  })
}
export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/param/selectById`,
    method: 'get',
    params: {
      id,
    }
  })
}
