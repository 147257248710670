import { removeRefreshToken, removeToken, setRefreshToken, setToken } from '@/util/auth'
import { Message } from 'element-ui'
import { getStore, setStore } from '@/util/store'
import { isURL, validatenull } from '@/util/validate'
import { deepClone } from '@/util/util'
import website from '@/config/website'
import { getButtons, getUserInfo, loginBySocial, loginByUsername, logout, refreshToken, cnbmAuth } from '@/api/user'
import { getRoutes, getTopMenu } from '@/api/system/menu'
import { getDeptTree } from '@/api/system/dept'
import md5 from 'js-md5'


function addPath(ele, first) {
  const menu = website.menu;
  const propsConfig = menu.props;
  const propsDefault = {
    label: propsConfig.label || 'name',
    path: propsConfig.path || 'path',
    icon: propsConfig.icon || 'icon',
    children: propsConfig.children || 'children'
  }
  const icon = ele[propsDefault.icon];
  ele[propsDefault.icon] = validatenull(icon) ? menu.iconDefault : icon;
  const isChild = ele[propsDefault.children] && ele[propsDefault.children].length !== 0;
  if (!isChild) ele[propsDefault.children] = [];
  if (!isChild && first && !isURL(ele[propsDefault.path])) {
    // ele[propsDefault.path] = ele[propsDefault.path] + '/index'
    ele[propsDefault.path] = ele[propsDefault.path]
  } else {
    ele[propsDefault.children].forEach(child => {
      addPath(child);
    })
  }

}

const user = {
  state: {
    tenantId: getStore({ name: 'tenantId' }) || '',
    userInfo: getStore({ name: 'userInfo' }) || [],
    permission: getStore({ name: 'permission' }) || {},
    roles: [],
    menuId: {},
    menu: getStore({ name: 'menu' }) || [],
    menuOnly: getStore({ name: 'menuOnly' }) || [],
    menuAll: getStore({ name: 'menuAll' }) || [],
    token: getStore({ name: 'token' }) || '',
    refreshToken: getStore({ name: 'refreshToken' }) || '',
    socket: '',
    topMenuClickIndex: getStore({ name: 'topMenuClickIndex' }) || 0,
    isIframeIn: getStore({ name: 'isIframeIn' }) || false,
    currentUser: [],
    deptTree: getStore({ name: 'deptTree' }) || [],
  },
  actions: {
    //单点登录
    clickLogin({ commit }, data) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', data.access_token);
        commit('SET_REFRESH_TOKEN', data.refresh_token);
        commit('SET_TENANT_ID', data.tenant_id);
        commit('SET_USER_INFO', data);
        commit('DEL_ALL_TAG');
        commit('CLEAR_LOCK');
        resolve()
      })
    },
    //根据用户名登录
    LoginByUsername({ commit }, userInfo) {
      return new Promise(async (resolve, reject) => {
        await loginByUsername(
          userInfo.tenantId,
          userInfo.deptId,
          userInfo.roleId,
          userInfo.username,
          userInfo.password ? md5(userInfo.password) : userInfo.password,
          userInfo.type,
          userInfo.key,
          userInfo.code,
          userInfo.sinoma_code,
          userInfo.wx_code,
          userInfo.grant_type,
        ).then(res => {
          const data = res.data;
          if (data.error_description) {
            Message({
              message: data.error_description,
              type: 'error'
            })
          } else {
            commit('SET_TOKEN', data.access_token);
            commit('SET_REFRESH_TOKEN', data.refresh_token);
            commit('SET_TENANT_ID', data.tenant_id);
            commit('SET_USER_INFO', {
              ...data,
              grant_type: userInfo.sinoma || 'password'
            });
            commit('DEL_ALL_TAG');
            commit('CLEAR_LOCK');
          }
          resolve(res);
        }).catch(error => {
          reject(error);
        })
      })
    },
    //中建材登录
    zjcAuthLogin({ commit,dispatch }, data) {
      return new Promise((resolve, reject) => {
        cnbmAuth(data).then((res) => {
          commit('SET_TOKEN', res.data.data.access_token);
          commit('SET_REFRESH_TOKEN', res.data.data.refresh_token);
          commit('SET_TENANT_ID', res.data.data.tenant_id);
          commit('SET_USER_INFO', res.data.data);
          commit('DEL_ALL_TAG');
          commit('CLEAR_LOCK');
          // dispatch('GetMenu');
          resolve(res);
        }).catch(error => {
          reject(error)
        })
      })
    },
    //根据手机号登录
    LoginByPhone({ commit }, userInfo) {
      return new Promise((resolve) => {
        loginByUsername(userInfo.phone, userInfo.code).then(res => {
          const data = res.data.data;
          commit('SET_TOKEN', data);
          commit('DEL_ALL_TAG');
          commit('CLEAR_LOCK');
          resolve();
        })
      })
    },
    //根据相关方信息登录
    LoginBySocial({ commit }, userInfo) {
      return new Promise((resolve) => {
        loginBySocial(userInfo.tenantId, userInfo.source, userInfo.code, userInfo.state).then(res => {
          const data = res.data;
          if (data.error_description) {
            Message({
              message: data.error_description,
              type: 'error'
            })
          } else {
            commit('SET_TOKEN', data.access_token);
            commit('SET_REFRESH_TOKEN', data.refresh_token);
            commit('SET_USER_INFO', data);
            commit('DEL_ALL_TAG');
            commit('CLEAR_LOCK');
          }
          resolve();
        })
      })
    },
    //获取用户信息
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then((res) => {
          const data = res.data.data;
          commit('SET_ROLES', data.roles);
          resolve(data);
        }).catch(err => {
          reject(err);
        })
      })
    },
    //刷新token
    refreshToken({ state, commit }, userInfo) {
      return new Promise((resolve, reject) => {
        refreshToken(state.refreshToken, state.tenantId,
          !validatenull(userInfo) ? userInfo.deptId : state.userInfo.dept_id,
          !validatenull(userInfo) ? userInfo.roleId : state.userInfo.role_id
        ).then(res => {
          const data = res.data;
          commit('SET_TOKEN', data.access_token);
          commit('SET_REFRESH_TOKEN', data.refresh_token);
          commit('SET_USER_INFO', {
            ...state.userInfo,
            ...data
          });
          resolve();
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          commit('SET_TOKEN', '');
          commit('SET_MENU', []);
          commit('SET_MENU_ALL_NULL', []);
          commit('SET_USER_INFO', []);
          commit('SET_ROLES', []);
          commit('SET_TAG_LIST', []);
          commit('DEL_ALL_TAG');
          commit('CLEAR_LOCK');
          commit('SET_USERINFOOBJ', {});
          sessionStorage.clear();
          localStorage.clear();
          removeToken();
          removeRefreshToken();
          resolve();
        }).catch(error => {
          reject(error)
        })
      })
    },
    //注销session
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '');
        commit('SET_MENU_ALL_NULL', []);
        commit('SET_MENU', []);
        commit('SET_ROLES', []);
        commit('SET_TAG_LIST', []);
        commit('DEL_ALL_TAG');
        commit('CLEAR_LOCK');
        removeToken();
        removeRefreshToken();
        resolve();
      })
    },
    //获取顶部菜单
    GetTopMenu() {
      return new Promise(resolve => {
        getTopMenu().then((res) => {
          const data = res.data.data || [];
          resolve(data)
        })
      })
    },
    GetDeptTree({ commit }) {
      return new Promise(resolve => {
        getDeptTree().then((res) => {
          let data = res.data.data;
          // commit('SET_DEPT_TREE',data);
          resolve(data);
        })
      })
    },
    //获取系统菜单
    /*GetMenu({commit, dispatch}, topMenuId) {
      return new Promise(resolve => {
        getRoutes(topMenuId).then((res) => {
getMenu
          const data = res.data.data
          let menu = deepClone(data);
          menu.forEach(ele => {
            addPath(ele, true);
          });
          commit('SET_MENU_ALL', menu)
          commit('SET_MENU', menu)
          dispatch('GetButtons');
          resolve(menu)
        })
      })
    },*/
    //获取系统菜单
    GetMenu({ commit, dispatch }, topMenuId) {
      return new Promise(async resolve => {
        await getRoutes(topMenuId).then((res) => {
          let data = res.data.data;
          let menu = deepClone(data);
          menu.forEach(ele => {
            addPath(ele, true);
          });
          commit('SET_MENU_ALL', menu)
          commit('SET_MENU', menu)
          dispatch('GetButtons');
          resolve(menu)
        })
      })
    },
    GetMenuOnly({ commit, dispatch }) {
      return new Promise(resolve => {
        getRoutes().then((res) => {
          let data = res.data.data;
          let menu = deepClone(data);
          menu.forEach(ele => {
            addPath(ele, true);
          });
          commit('SET_MENUONLY', menu[0].children);
          dispatch('GetButtons');
          resolve(menu)
        })
      })
    },
    // 保持不变的菜单
    // GetMenuOnly({commit,dispatch}) {
    //   return new Promise(resolve => {
    //     getRoutes().then((res) => {
    //       let data = res.data.data;
    //       let menu = deepClone(data);
    //       menu.forEach(ele => {
    //         addPath(ele, true);
    //       });
    //       localStorage.setItem('moreMenuList',JSON.stringify(menu))
    //       commit('SET_MENU_ALL', menu)
    //       commit('SET_MENU_ONLY', menu);
    //       dispatch('GetButtons');
    //       resolve(menu)
    //     })
    //   })
    // },
    //获取系统按钮
    GetButtons({ commit }) {
      return new Promise((resolve) => {
        getButtons().then(res => {
          const data = res.data.data;
          commit('SET_PERMISSION', data);
          resolve();
        })
      })
    },
  },
  mutations: {
    SET_CURRENT_USER: (state, currentUser) => {
      state.currentUser = currentUser
    },
    SET_DEPT_TREE: (state, deptTree) => {
      state.deptTree = deptTree;
      setStore({
        name: 'deptTree',
        content: state.deptTree
      })
    },
    SET_SOCKET: (state, socket) => {
      state.socket = socket
    },
    SET_TOKEN: (state, token) => {
      setToken(token);
      state.token = token;
      setStore({ name: 'token', content: state.token })
    },
    SET_MENU_ID(state, menuId) {
      state.menuId = menuId;
    },
    SET_MENU_ALL: (state, menuAll) => {
      // 对比浏览器缓存的数据和新获取的数据有哪些不同，将不同的数据push到旧数据里
      // let menu = state.menuAll;
      // menuAll.forEach(ele => {
      //   if (!menu.find(item => item.label === ele.label && item.path === ele.path)) {
      //     menu.push(ele);
      //   }
      // })
      // state.menuAll = menu

      // 直接拿新数据更新到缓存里
      state.menuAll = menuAll
      setStore({ name: 'menuAll', content: state.menuAll })
    },
    SET_MENU_ALL_NULL: (state) => {
      state.menuAll = []
      setStore({ name: 'menuAll', content: state.menuAll })
    },
    SET_MENU: (state, menu) => {
      state.menu = menu
      setStore({ name: 'menu', content: state.menu })
    },
    SET_MENUONLY: (state, menuOnly) => {
      state.menuOnly = menuOnly
      setStore({ name: 'menuOnly', content: menuOnly })
    },
    SET_REFRESH_TOKEN: (state, refreshToken) => {
      setRefreshToken(refreshToken)
      state.refreshToken = refreshToken;
      setStore({ name: 'refreshToken', content: state.refreshToken })
    },
    SET_MENU_CLICK_INDEX: (state, topMenuClickIndex) => {
      state.topMenuClickIndex = topMenuClickIndex
      setStore({ name: 'topMenuClickIndex', content: topMenuClickIndex })
    },
    SET_TENANT_ID: (state, tenantId) => {
      state.tenantId = tenantId;
      setStore({ name: 'tenantId', content: state.tenantId })
    },
    SET_USER_INFO: (state, userInfo) => {
      if (validatenull(userInfo.avatar)) {
        userInfo.avatar = "/img/bg/img-logo.png";
      }
      state.userInfo = { ...userInfo, deptName: userInfo.ancestors_name, dept_id: userInfo.company_id != -1 ? userInfo.company_id : userInfo.dept_id };
      setStore({ name: 'userInfo', content: state.userInfo })
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PERMISSION: (state, permission) => {
      let result = [];

      function getCode(list) {
        list.forEach(ele => {
          if (typeof (ele) === 'object') {
            const chiildren = ele.children;
            const code = ele.code;
            if (chiildren) {
              getCode(chiildren)
            } else {
              result.push(code);
            }
          }
        })
      }

      getCode(permission);
      state.permission = {};
      result.forEach(ele => {
        state.permission[ele] = true;
      });
      setStore({ name: 'permission', content: state.permission })
    },
    SET_ISIFRAME_IN: (state, isIframeIn) => {
      state.isIframeIn = isIframeIn
    }
  }

}
export default user
