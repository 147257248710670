var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "avue-searchs",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.handleEsc.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "avue-searchs__title" }, [
        _vm._v(
          "\n    " +
            _vm._s(this.$t("cip.cmn.components.index.title.menuSearchTitle")) +
            "\n  "
        ),
      ]),
      _c("div", { staticClass: "avue-searchs__content" }, [
        _c(
          "div",
          { staticClass: "avue-searchs__form" },
          [
            _c(
              "el-input",
              {
                attrs: { placeholder: _vm.$t("search") },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape",
                      ])
                    )
                      return null
                    return _vm.handleEsc.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              },
              [
                _c("el-button", {
                  attrs: { slot: "append", icon: "el-icon-search" },
                  slot: "append",
                }),
              ],
              1
            ),
            _c(
              "p",
              [
                _c("el-tag", [
                  _vm._v(_vm._s(this.$t("cip.cmn.components.index.msg.esc"))),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "avue-searchs__list" },
          [
            _c(
              "el-scrollbar",
              { staticClass: "avue-searchs__scrollbar" },
              _vm._l(_vm.menus, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "avue-searchs__item",
                    on: {
                      click: function ($event) {
                        return _vm.handleSelect(item)
                      },
                    },
                  },
                  [
                    _c("i", {
                      class: [item[_vm.iconKey], "avue-searchs__item-icon"],
                    }),
                    _c("span", { staticClass: "avue-searchs__item-title" }, [
                      _vm._v(_vm._s(item[_vm.labelKey])),
                    ]),
                    _c("div", { staticClass: "avue-searchs__item-path" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(item[_vm.pathKey]) +
                          "\n          "
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }