const getters = {
  tag: state => state.tags.tag,
  language: state => state.common.language,
  website: state => state.common.website,
  userInfo: state => state.user.userInfo,
  currentUser: state => state.user.currentUser,
  colorName: state => state.common.colorName,
  themeName: state => state.common.themeName,
  layoutType: state => state.common.layoutType,
  slideClass: state => state.common.slideClass,
  themeClass: state => state.common.themeClass,
  themeColor: state => state.common.themeColor,
  tagsView: state => state.common.tagsView,
  tagsIcon: state => state.common.tagsIcon,
  showLanguage: state => state.common.showLanguage,
  showSearch: state => state.common.showSearch,
  showStripe: state => state.common.showStripe,
  useCache: state => state.common.useCache,
  isShade: state => state.common.isShade,
  allTable: state => state.common.allTable,
  formItemList: state => state.common.formItemList,
  subTable: state => state.common.subTable,
  dataBase: state => state.common.dataBase,
  hasTable: state => state.common.hasTable,
  isCollapse: state => state.common.isCollapse,
  keyCollapse: (state, getters) => getters.screen > 1 ? getters.isCollapse : true,
  screen: state => state.common.screen,
  isLock: state => state.common.isLock,
  isFullScren: state => state.common.isFullScren,
  isMenu: state => state.common.isMenu,
  lockPasswd: state => state.common.lockPasswd,
  tagList: state => state.tags.tagList,
  tagWel: state => state.tags.tagWel,
  token: state => state.user.token,
  roles: state => state.user.roles,
  permission: state => state.user.permission,
  menu: state => state.user.menu,
  menuOnly: state => state.user.menuOnly,
  menuId: state => state.user.menuId,
  menuAll: state => state.user.menuAll,
  logsList: state => state.logs.logsList,
  logsLen: state => state.logs.logsList.length || 0,
  logsFlag: (state, getters) => getters.logsLen === 0,
  flowRoutes: state => state.dict.flowRoutes,
  showShortcutMenu: state => state.common.showShortcutMenu,
  socket: state => state.user.socket,
  topMenuClickIndex: state => state.user.topMenuClickIndex,
  isIframeIn: state => state.user.isIframeIn,
  deptTree: state => state.user.deptTree,
  userData: state => state.common.userData,
  portalId: state => state.common.portalId,
  // oldForm: state => state.common.oldForm,
  // newForm: state => state.common.newForm,
  visualDevData: state => state.common.visualDevData,
  posData: state => state.common.posData,
  roleData: state => state.common.roleData,
  openmenu: state => state.common.openmenu,
  userInfoObj: state => state.common.userInfoObj,
  openChatState: state => state.common.openChatState,
  refresh: state => state.common.refresh,
  report: state => state.common.report
}
export default getters
