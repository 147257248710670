import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingRecording/page`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingRecording/getDetail`,
    method: 'get',
    params: {
      id
    }
  })
}

export const saveOrUpdate = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingRecording/saveOrUpdate`,
    method: 'post',
    data: row
  })
}

export const addPersonnel = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingRecording/addPersonnel`,
    method: 'post',
    data: row
  })
}

export const updatePersonnel = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingRecording/updatePersonnel`,
    method: 'post',
    data: row
  })
}

export const getSubstance = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainGroupScene/detail?id=${id}`,
    method: 'post',
  })
}

export const substance = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/info/getDetail?id=${id}`,
    method: 'get',
  })
}

export const getIsQualifiedDicData = () => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dict-biz/dictionary?code=recording_is_qualified`,
    method: 'get',
  })
}

export const getByProjectName = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/prjbasicinfo/getByProjectName`,
    method: 'get',
    params: {
      ...params,
    }
  })
}

export const prjthirdcompanyPage = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/prjthirdcompany/list`,
    method: 'get',
    params: {
      ...params,
    }
  })
}

export const getDetailBySiteTrainingId = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingRecording/getDetailBySiteTrainingId`,
    method: 'get',
    params: {
      id
    }
  })
}
export const searchTrainingRecords = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingTask/searchTrainingRecords`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}


export const updateByFinish = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingRecording/updateByFinish`,
    method: 'get',
    params: {
      id
    }
  })
}


export const calculateQualification = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingRecording/calculateQualification`,
    method: 'get',
    params: {
      id
    }
  })
}

export const add = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/thirduser/verifyAndSubmit`,
    method: 'post',
    data: row
  })
}

export const saveImage = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingRecording/saveImage`,
    method: 'post',
    data: data
  })
}

