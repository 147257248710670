let ServerNameEnum = {};
ServerNameEnum = {
  SERVER_FLOW: "/api/sinoma-flow",
  SERVER_SYSTEM_: "/api/sinoma-system", //"系统管理服务",
  SERVER_AUTH_: "/api/sinoma-auth", //"鉴权服务",
  SERVER_RESOURCE_: "/api/sinoma-resource", //"系统资源服务",
  SERVER_WORK_FLOW_: "/api/sinoma-workflow", //"工作流服务",
  SERVER_USER_: "/api/sinoma-user",// "系统人员服务",
  SERVER_LOG_: "/api/sinoma-log",// "日志服务",
  SERVER_DESK_: "/api/sinoma-desk",// "桌面服务",
  SERVER_REPORT_: "/api/sinoma-report",// "报表服务",
  SERVER_DEVELOP_: "/api/sinoma-develop",//"系统平台服务"
  SERVER_MD_: "/api/sinoma-md",//"系统平台服务"
  SERVER_QM_: "/api/sinoma-qm",//"质量管理服务"
  SERVER_PM_: "/api/sinoma-pm",//"生产管理服务"
  SERVER_CPS_: "/api/sinoma-cps",//"cps服务"
  SERVER_BATCH_: "/api/sinoma-batch",//"批次管理服务"
  SERVER_MESSAGE_: "/api/sinoma-message",//"消息管理服务"
  SERVER_INDEX_: "/api/sinoma-index",//"指标管理服务"
  SERVER_SINOMA_INTERFACE_: "/api/sinoma-interface",//"指标管理服务"
  SERVER_INTERFACE_: "/inf/interface",//"指标管理服务"
  SERVER_JOB_: '/api/sinoma-xxljob-admin',
  SERVER_ZT_: "/api/sinoma-datacenter",
  SERVER_SOUND_: "/api/sinoma-sound",
  SERVER_WARNING_: "/api/sinoma-warning",//"预警服务"
  SERVER_IFRAME: "/api/sinoma-interface/api/common", //单点登录获取用户信息
  SERVER_TRAINING_: "/api/sinoma-training",
  //知识分类
  SERVER_KNOWLEDGE: "/api/sinoma-knowledge",
  SERVER_FX: '/api/sinoma-hse-prj', // 风险管理
  SERVER_SYNC: '/api/sinoma-sync',
  SERVER_AGENT: '/api/sinoma-agent',


}
export default ServerNameEnum;
