<template>
  <div class="my-card">
    <div class="card-header clearfix">
      <span class="title">{{title}}</span>
      <span class="more-btn" @click="clickMore" v-if="showMore">
        {{ this.$t('cip.cmn.title.more') }}<i class="el-icon-arrow-right"></i>
      </span>
      <span class="set-btn" @click="clickSet" v-if="showSet">
        <i class="el-icon-plus"></i>
      </span>
    </div>
    <div class="card-container">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    showMore:{
      type:Boolean,
      default:true
    },
    showSet:{
      type:Boolean,
      default:false
    }
  },
  methods: {
    clickMore() {
      this.$emit('more')
      /*this.$router.push({
        path: `/system/knowledge`,
      })*/
    },
    clickSet() {
      this.$emit('set')
    }
  }
}
</script>
<style lang="scss">
/* 清除浮动 */
.clearfix::after {
  display: block;
  content: "";
  clear: both;
}
.my-card {
  width: 100%;
  background: #ffffff;
  .card-header {
    padding: 12px 20px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #E5E5E5;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      position: relative;
      padding-left: 10px;
      &::before {
        content: "";
        width: 3px;
        height: 14px;
        background: #0B82FF;
        margin-right: 10px;
        display: inline-block;
        position: absolute;
        top: 50%;
        margin-top: -7px;
        left: 0;
      }
    }
    .more-btn,.set-btn {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      font-weight: normal;
      float: right;
      cursor: pointer;
    }
  }
  .card-container {
  }
}
</style>
