import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";
const CONTROLLER_NAME = "msgTypeItem";
/**
 * 分类树
 * @param params
 * @returns {*}
 */
export const tree = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/${CONTROLLER_NAME}/selectTree`,
    method: 'get',
    params
  })
}
/**
 * 编辑新增
 * @param data
 * @returns {AxiosPromise}
 */
export const saveOrUpdate= (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/${CONTROLLER_NAME}/saveOrUpdate`,
    method: 'post',
    data

  })
}
/**
 * 删除
 * @param ids  删除的id集合
 * @returns AxiosPromise
 */
export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/${CONTROLLER_NAME}/remove`,
    method: 'post',
    params: {
      ids
    }
  })
}
