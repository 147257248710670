import httpService from "@/router/axios";

// 知识库-应急预案-   分页
export const $_getPageList = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/contingencyplan/list",
    method: "get",
    params,
  });
};

// 获取危险源列表
export const $_getHazardList = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/hazards/list2",
    method: "get",
    params,
  });
};

//知识库-应急预案-   新增
export const $_addSave = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/contingencyplan/save",
    method: "post",
    data: params,
  });
};

// 知识库-应急预案-   修改
export const $_updateData = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/contingencyplan/update",
    method: "post",
    data: params,
  });
};

// 知识库-应急预案-   详情
export const $_getDetail = (id) => {
  return httpService({
    url: `/api/ivystar-wisdom-security/contingencyplan/detail/${id}`,
    method: "get",
  });
};

// 临时删除÷
export const $_delRemoveData = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/workemergencydrill/remove",
    method: "post",
    params,
  });
};
