import httpService from '@/router/axios';
// 系统管理-隐藏级别配置接口   分页
export const $_getPageList = (params) => {
    return httpService({
        url: '/api/ivystar-wisdom-security/hazardtype/page',
        method: 'get',
        params
    })
}

// 系统管理-隐藏级别配置接口   新增
export const $_addSaveList = (row) => {
    return httpService({
        url: '/api/ivystar-wisdom-security/hazardtype/save',
        method: 'post',
        data: row
    })
}

// 系统管理-隐藏级别配置接口   修改
export const $_updateData = (row) => {
    return httpService({
        url: '/api/ivystar-wisdom-security/hazardtype/update',
        method: 'post',
        data: row
    })
}
