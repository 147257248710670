export const options = (safe)=> {
  return {
    index: true,
    indexLabel:safe.$t("datacenter.dataQuality.index"),
    viewBtn:false,
    menuWidth: 180,
    column: [
      {
        label: safe.$t("datacenter.dataQuality.programName"),
        prop: "schemeName",
        span: 24
      },
      {
        label: safe.$t("datacenter.dataQuality.status"),
        prop: "state",
        span: 24,
        slot: true
      },
      {
        label: safe.$t("datacenter.dataQuality.monitoringTask.remark"),
        prop: "remark",
        span: 24
      }
    ]
  }
}
export const searchColumns = (safe)=> {
  return [
    {
      prop: "schemeName",
      search: true,
      placeholder: safe.$t('cip.cmn.rule.inputWarning') + safe.$t("datacenter.dataQuality.programName"),
    },
    {
      type: "select",

      placeholder: safe.$t('cip.cmn.rule.selectWarning') + safe.$t("datacenter.dataQuality.status"),
      prop: "state",
      search: true,
      dicData: [
        {
          label:safe.$t('datacenter.dataQuality.monitoringTask.paused'),
          value: 0
        },
        {
          label:safe.$t('datacenter.dataQuality.monitoringTask.inProgress'),
          value: 1
        }
      ]
    }
  ]
}
