import httpService from '@/router/axios';
// 系统管理-日常类型检查接口 树
export const $_getTree = (params) => {
    return httpService({
        url: '/api/ivystar-wisdom-security/dailytypecheck/tree',
        method: 'get',
        data: params
    })
}

// 系统管理-日常检查项目   新增
export const $_addSaveList = (row) => {
    return httpService({
        url: '/api/ivystar-wisdom-security/dailytypecheck/save',
        method: 'post',
        data: row
    })
}

// 系统管理-日常检查项目   修改
export const $_updateData = (row) => {
    return httpService({
        url: '/api/ivystar-wisdom-security/dailytypecheck/update',
        method: 'post',
        data: row
    })
}

// 系统管理-日常检查项目   删除
export const $_removeData = (params) => {
    return httpService({
        url: '/api/ivystar-wisdom-security/dailytypecheck/remove',
        method: 'post',
        params
    })
}
