<template>
  <div class="comSelect-container">
<!--    <SNOWS-TreeSelect :options="treeData" v-model="innerValue" :placeholder="placeholder" clearable-->
<!--      :disabled="disabled" v-on="$listeners" lastLevel>-->
<!--    </SNOWS-TreeSelect>-->
    <el-select v-model="innerValue" filterable :placeholder="placeholder" :disabled="disabled" clearable @change="dictionaryTypeChange">
      <el-option
        v-for="item in treeData"
        :key="item.id"
        :label="item.dictValue"
        :value="item.id">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import {getParentList} from "@/api/system/dictbiz";

export default {
  name: 'dicSelect',
  props: ["value", "disabled", "placeholder"],
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      treeData: [],
      innerValue: this.value
    }
  },
  methods: {
    async getData() {
      getParentList(1,500).then(res => {
        this.treeData = res.data.data.records
      })
    }
  },
  created() {
    this.getData()
  },
  watch: {
    innerValue(val) {
      console.log(val,'45')
      val && this.$emit('change', val)
    },
    value(val) {
      this.innerValue = val
    }
  }
}
</script>
<style lang="scss">
.comSelect-container {
  .el-select {
    width: 100%;
  }
}
</style>
