import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/page',
    method: 'get',
    params: {
      ...params,
      isInclude:params.isInclude || false
    }
  })
}
export const getTableList = (params,url) => {
  return request({
    url: url,
    method: 'get',
    params: params
  })
}
export const roleAllocation = (current, size, params, deptId) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/roleAllocation',
    method: 'get',
    params: {
      ...params,
      current,
      size,
      deptId,
    }
  })
}
export const roleAllocationByList = (current, size, params, deptId) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/roleAllocationByList',
    method: 'get',
    params: {
      ...params,
      current,
      size,
      deptId,
    }
  })
}
export const pageDeptByRole = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/pageDeptByRole',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const roleGetList = (current, size, params, deptId) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/role/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
      deptId,
    }
  })
}
export const listUser = () => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/user-list',
    method: 'get',
    params: {
    }
  })
}
export const addDeptByRole = (row) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/addDeptByRole',
    method: 'post',
    data: row
  })
}
export const removeDeptByRole = (row) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/removeDeptByRole',
    method: 'post',
    data: row
  })
}
export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/submit',
    method: 'post',
    data: row
  })
}

export const grantMultiDept = (row) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/grantMultiDept',
    method: 'post',
    data: row
  })
}
export const theUserModifiesTheRole = (row) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/theUserModifiesTheRole',
    method: 'post',
    data: row
  })
}
export const grantMultiDeptByRole = (row) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/grantMultiDeptByRole',
    method: 'post',
    data: row
  })
}
export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/update',
    method: 'post',
    data: row
  })
}

export const updatePlatform = (userId, userType, userExt) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/update-platform',
    method: 'post',
    params: {
      userId,
      userType,
      userExt,
    }
  })
}

export const getUser = (id) => {
  return request({
    url: ServerNameEnum.SERVER_USER_ + '/detail',
    method: 'get',
    params: {
      id,
    }
  })
}

export const getUserInfoById = (id) => {
  return request({
    url: ServerNameEnum.SERVER_USER_ + '/info',
    method: 'get',
    params: {
      id,
    }
  })
}

export const getUserPlatform = (id) => {
  return request({
    url: ServerNameEnum.SERVER_USER_ + '/platform-detail',
    method: 'get',
    params: {
      id,
    }
  })
}

export const getUserInfo = () => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/info',
    method: 'get',
  })
}

export const resetPassword = (userIds) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/reset-password',
    method: 'post',
    params: {
      userIds,
    }
  })
}

export const updatePassword = (oldPassword, newPassword, newPassword1) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/update-password',
    method: 'post',
    params: {
      oldPassword,
      newPassword,
      newPassword1,
    }
  })
}

export const updateInfo = (row) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/update-info',
    method: 'post',
    data: row
  })
}

export const grant = (userIds, roleIds) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/grant',
    method: 'post',
    params: {
      userIds,
      roleIds,
    }
  })
}

export const unlock = (userIds) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/unlock',
    method: 'post',
    params: {
      userIds,
    }
  })
}
export const saveSysConfig = (data) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/tenant-package/saveSysConfig',
    method: 'post',
    data
  })
}
export const getSysConfig = () => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/tenant-package/getSysConfig',
    method: 'get',
  })
}
export const getSysConfigToUserInfo = () => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/tenant-package/getSysConfigToUserInfo',
    method: 'get',
  })
}
export const updataByFirstLogin = (id) => {
  return request({
    url: ServerNameEnum.SERVER_USER_ + '/updataByFirstLogin',
    method: 'get',
    params: {
      id,
    }
  })
}
export const enable = (userIds) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/enable',
    method: 'post',
    params: {
      userIds,
    }
  })
}
export const disable = (userIds) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/disable',
    method: 'post',
    params: {
      userIds,
    }
  })
}

export const selectUser = (current, size, params, deptId) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/user-select',
    method: 'get',
    params: {
      ...params,
      current,
      size,
      deptId,
    }
  })
}

export const selectUserByIds = (data) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/selectUserByIds',
    method: 'post',
    data
  })
}

export const setUserConfig = (roleId, deptId, userIds) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/setUserConfig',
    method: 'post',
    data: {
      roleId,
      deptId,
      userIds
    }
  })
}

//文件上传
export const putFile = (data) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/oss/endpoint/put-file',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7pwCgXl89czpqmkm'
    },
    data,
  })
}
//图片上传
export const platform = (data) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/oss/endpoint/put-file-attach-name-biz/platform',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7pwCgXl89czpqmkm'
    },
    data,
  })
}
export const updateElectronicSignature = (data) => request({
  url: ServerNameEnum.SERVER_USER_ + '/updateElectronicSignature',
  method: 'post',
  data
});

// 国籍
export const selectByLevel = (data) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/region/selectByLevel',
    method: 'get',
    params: data
  })
}
