import request from '@/router/axios'
import ServerNameEnum from "@/util/ServerNameEnum";

/** 安全技术交底 */

export const prjSafetyHandoverPage = (current, size, params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/prjsafetyhandover/page`,
        method: 'get',
        params: {
            current,
            size,
            ...params
        }
    })
}

export const prjSafetyHandoverSubmit = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/prjsafetyhandover/submit`,
        method: 'post',
        data: params
    })
}

export const prjSafetyHandoverRemove = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/prjsafetyhandover/remove`,
        method: 'post',
        params: { ids }
    })
} 

export const prjSafetyHandoverDetail = (id) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/prjsafetyhandover/detail`,
        method: 'get',
        params: {id}
    })
}