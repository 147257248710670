// 指标管理
export default {
  index: {
    appIndex: {
      theme: {
        treeTitle: "主题",
        listTitle: "指标",
      }
    },
    // 应用
    app: {
      // 体系分类
      category: {
        treeHeader: "体系分类",
        listHeader: "指标列表",
        indexCode: "指标编码",
        indexName: "指标名称",
        alias: "别名",
        unitCode: "指标单位",
        timeDimension: "时间维度",
        indexLevel: "指标级别",
        parentCode: "父类分类",
        categoryCode: "分类编码",
        categoryName: "分类名称",
        sort:'排序',
        errorMsg1: "分类编码不能重复，请重新输入！",
        indexView: "指标查看",
        time: "时间",
      }
    },
    theme: {
      title: "多维模型",
      headTitle: "多维模型管理",
      headCatTitle: "多维模型分类",
      pleaseInput: "请输入",
      parentCode: "父类别",
      parentName: "父类别名称",
      cateGoryCode: "分类编码",
      cateGoryName: "分类名称",
      isEnable: "是否启用",
      remark: "备注说明",
      hasChildren: "有子项无法删除!",
      themeName: "多维模型名称",
      themeCode: "多维模型编码",
      category: "分类",
      factName: "事实表",
      themeDimensionTable: "维度表",
      themeDimension: "维度",
      themeCuboid: "度量",
      themeTime: "时间维度",
      tableName: "表名",
      tableRemark: "表备注",
      modeOfConn: "连接方式",
      conditionOfConn: "连接条件",
      modelName: "模型名称",
      subModelName: "子模型名称",
      fieldName: "字段名",
      fieldType: "字段类型",
      fieldRemark: "字段备注",
      expression: "表达式",
      alias: "别名",
      description: "描述",
      dataType: "数据类型",
      overview: "概览",
      wideTableSql: "宽表SQL",
      cuboid: "cuboid",
      dimenCount: "维度数量",
      cuboidCount: "cuboid数量",
      physicalTableConstruction: "物理建表",
      anotherName: "别名",
      basicInfo: "基础信息",
      modelConfig: "模型配置",
      CuboidConfig: "Cuboid配置",
      done: "完成",
      nextStep: "下一步",
      previousStep: "上一步",
      addThem: "+ 新增主题",
      indexCount: "指标数量",
      edit: "编辑",
      remove: "移除",
      repeatData: "有重复数据，请重新选择！",
      list: "列表",
      dynQuery: "自定义查询",
      error1: "该多维模型已有指标使用，无法进行编辑！",
      select: "选择"
    },
    atomIndex: {
      title: "指标",
      headTitle: "指标配置",
      indexCode: "指标编码",
      indexName: "指标名称",
      theme: "主题",
      confType: "配置方案",
      unitCode: "指标单位",
      indexType: "指标类型",
      dimensionNum: "维度数量",
      dimension: "维度",
      api: "api",
      webSocket: "webSocket",
      cuboidId: "CuboidID",
      isEffective: "是否生效",
      apiMsg: "保存后自动生成",
      checkIndex: "指标维度不同不能添加",
      queryData: "查看数据",
      dynSqlBtn: "自定义指标",
      dynSql: "自定义sql",
      dataSource: "数据源",
      hour: "小时",
      day: "日",
      month: "月",
      year: "年",
      accuracy: "保留精度",
      alias: "指标别名",
      jobCron: "周期设置",
      indexCaliber: "业务口径",
      simulationType: "模拟方式",
      simulationValue: "模拟值",
      compositeFormula: "复合指标公式",
      composite: "复合指标",
      drillDown: "下钻",
      influxdbPoint: "测点值",
      functionType: "函数类型",
      fullDimensionalData: "全维度数据",


    },
    dimension: {
      headTitle: "维度",
      fieldName: "维度编码",
      fieldRemark: "维度名称",
      fieldType: "类型"
    },
    measure: {
      headTitle: "度量",
      expression: "表达式",
      alias: "别名",
      description: "描述",
      dataType: "数据类型",
      auxiliaryCalc: "辅助计算"
    },
    combinatorialMeasure: {
      headTitle: "度量",
      scalarCode: "标量编码",
      scalarName: "标量名称",
      type: "类型",
      calculatingFunction: "计算函数"
    },
    condition: {
      headTitle: "条件",
      dimension: "维度",
      isRequired: "必填",
    },
    scope: {
      headTitle: "范围",
      connectedRelation: "连接关系",
      dimension: "维度",
      computeSign: "计算符号",
      value: "值",
      message: "请先保存头信息",
      testConnect: "测试脚本"
    },
    intf:{
      requestBtn: "请求",
      title: "指标接口",
      intfUrl: "接口URL",
      timeDimension: "时间纬度",
      timeSelect: "时间选择",
      timeSelectStart: "请选择开始时间",
      timeSelectEnd: "请选择结束时间",
      currentPage: "当前页码",
      pageSize: "每页条数",
      intfParams: "请求参数",
      intfResultRows: "返回结果",
      indexClass: "指标体系",
      intfCode: "接口编码",
      intfName: "接口名称",
      simulateCall: "模拟调用",
      message1:"指标编码为空，请选择指标编码后在进行模拟调用！",
      message2:"接口编码已存在，请修改！"
    },
    //数据模型
  datamodel: {
    title: "数据模型",
    field: {
      ChineseNameOfData: "数据中文名称",
      Dataname: "数据名称",
      datatype: "数据类型",
      Creationtime: "创建时间",
      form: '表',
      relationship: '关系',
      Fieldname: '字段名',
      FieldName: "字段名称",
      Chinesename: '中文名',
      type: '类型',
      length: '长度',
      accuracy: '精度',
      Primarykeyornot: '是否主键',
      Isitempty: '是否为空',
      Currenttablename: "当前表名",
      Associatedtable: "关联表",
      Associatedfield: "关联字段",
      is: "是",
      no: "否",
      newtable: "新建表",
      Modelname: "模型名称",
      datasource: "数据源",
      Pleaseselect: "请选择",
      Tablename: "表名",
      notes: "注释",
    },
    rules: {
      rules1: "请输入表名",
      rules2: "请选择数据源",
      rules3: "请输入注释",
    },
    msg: {
      msg1: "编辑成功",
      msg2: "保存成功",

     }
   },
   omission:{
    headTitle1:"指标数据查询",
    headTitle2: "下钻指标查询",
    convert:"转换",
    seedata:"下钻",
    indexLabel:"序号",
    message1:"该指标没有下钻指标",
    original:"原始值",
    analog:"模拟值",
    isCache:"是否缓存",
    indexLevel:"指标级别",
    timeDimension:"时间维度",
    appIndexScopes:"维度映射",
    appIndexMeasures:"度量",
     simulatedData: "模拟数据" ,
     originalData: "原始数据" ,
     lineChart: "折线图" ,
     viewResult: "查看结果" ,

    // this.$t(`cip.index.omission.headTitle2`)
   }

  }
}
