import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getList = (current,size,params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/securitylog/list',
    method: 'get',
    params:{
      current,
      size,
      ...params
    }
  })
}
export const detail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/securitylog/detail',
    method: 'get',
    params
  })
}
export const queryTask = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/securitylog/queryTask',
    method: 'post',
    data
  })
}
export const changeOrPunish = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/securitylog/changeOrPunish',
    method: 'get',
    params
  })
}
export const update = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/securitylog/actFinish',
    method: 'post',
    data
  })
}
export const submit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/securitylog/submit',
    method: 'post',
    data
  })
}
export const remove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/securitylog/remove',
    method: 'post',
    data
  })
}
export const copy = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/securitylog/copy',
    method: 'post',
    data
  })
}
export const getCode = () => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/securitylog/getCode',
    method: 'get',
  })
}