import Layout from "@/page/index/";

export default [
  //设备设施
  {
    path: "/equipmentFacilities",
    component: Layout,
    name: "设备设施台账",
    children: [
      {
        path: "/equipmentFacilities/equipmentLedger/edit",
        name: "设备设施台账新增",
        component: () =>
          import(
            "@/views/equipmentFacilities/equipmentLedger/form"
          ),
        meta: {
          i18n: "设备设施台账新增",
        },
      },
      // {
      //   path: "/equipmentFacilities/equipmentLedger/complain/edit",
      //   name: "环保投诉新增编辑",
      //   component: () =>
      //     import("@/views/equipmentFacilities/equipmentLedger/complain/form"),
      //   meta: {
      //     i18n: "环保投诉新增编辑",
      //   },
      // },
      // {
      //   path: "/equipmentFacilities/environmentalEmergencies/edit",
      //   name: "突发环境事件新增调查",
      //   component: () =>
      //     import("@/views/equipmentFacilities/environmentalEmergencies/form"),
      //   meta: {
      //     i18n: "突发环境事件新增调查",
      //   },
      // },
      // {
      //   path: "/equipmentFacilities/pollutionEmissions/pollutionRecord/edit",
      //   name: "排放记录新增编辑",
      //   component: () =>
      //     import("@/views/equipmentFacilities/pollutionEmissions/pollutionRecord/form"),
      //   meta: {
      //     i18n: "排放记录新增编辑",
      //   },
      // },
      {
        path: "/equipmentFacilities/equipmentMaintenance/maintain/form",
        name: "设备保养记录",
        component: () =>
          import(
            "@/views/equipmentFacilities/equipmentMaintenance/maintain/form"
          ),
        meta: {
          i18n: "设备保养记录",
        },
      },
      {
        path: "/equipmentFacilities/equipmentMaintenance/service/form",
        name: "设备维修记录",
        component: () =>
          import(
            "@/views/equipmentFacilities/equipmentMaintenance/service/form"
          ),
        meta: {
          i18n: "设备维修记录",
        },
      },
    ],
  },
];
