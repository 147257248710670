import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 获取业务字典
export const dictionaryBiz = (code) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dict-biz/dictionary?code=` + code,
    method: 'get'
  })
}

export const dictionary = (code) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dict/dictionary?code=` + code,
    method: 'get'
  })
}

// 任务列表
export const attributeList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/attributeFixation/getPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

// 任务删除
export const attributeRemove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/attributeFixation/remove?ids=` + ids,
    method: 'post'
  })
}

// 任务保存
export const attributeSave = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/attributeFixation/saveAttribute`,
    method: 'post',
    data
  })
}

export const reissue = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/attributeFixation/reissue`,
    method: 'post',
    data
  })
}
// 任务详情
export const attributeDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/attributeFixation/detail?id=` + id,
    method: 'get'
  })
}

export const attributeCode = (templateCode) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/attributeFixation/detail?templateCode=` + templateCode,
    method: 'get'
  })
}
// 任务发布
export const attributePublish = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/attributeFixation/publish`,
    method: 'post',
    data
  })
}

// 获取所有启用的模板
export const templateByEnable = (deptId) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/template/getByEnable?deptId=` + deptId,
    method: 'get'
  })
}

export const mergeListType = () => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/attributeFixation/mergeListType`,
    method: 'get'
  })
}

export const mergeListTypeByAudit = () => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/attributeFixation/mergeListTypeByAudit`,
    method: 'get'
  })
}

export const reportReviewTree = (code, reportCode) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/attributeFixation/reportReviewTree?code=` + code + "&reportCode=" + reportCode,
    method: 'get',
  })
}

export const reportReview = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_+'/acquisition/attributeFixation/reportReview',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
