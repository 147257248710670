import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 功能记录新增
export const functionSave = (params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/function/record/save',
    method: 'post',
    data: {
      ...params,
    }
  })
}

// 功能记录查询
export const getFunctionList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/function/record/listByPage',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

// 功能频次查询
export const getFunctionFrequencyList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/function/frequency/listByPage',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

// 按钮记录新增
export const buttonSave = (params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/function/button/save',
    method: 'post',
    data: {
      ...params,
    }
  })
}

// 按钮记录查询
export const getButtonList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/function/button/listByPage',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

// 按钮频次查询
export const getButtonFrequencyList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/function/buttonFrequency/listByPage',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
