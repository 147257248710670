import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export function pageChangeRecord (data) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/changeRecords/page',
    method: 'get',
    params: data
  })
}

export function getChangeRecord (id) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/changeRecords/' + id,
    method: 'get'
  })
}

export function delChangeRecord (id) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/changeRecords/' + id,
    method: 'delete'
  })
}

export function delChangeRecords (ids) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/changeRecords/batch/' + ids,
    method: 'delete'
  })
}

export function addChangeRecord (data) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/changeRecords',
    method: 'post',
    data: data
  })
}

export function updateChangeRecord (data) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/changeRecords/' + data.id,
    method: 'put',
    data: data
  })
}
