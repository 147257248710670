import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_PROTOCOL = "/protocol";

export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const moistureCoefficient = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/moistureCoefficient',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/update',
    method: 'post',
    data: row
  })
}

export const submit = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/submit',
    method: 'post',
    data: row
  })
}
