import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

/** 健康档案 */
export const ohdocumentPage = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/ohdocument/page`,
      method: 'get',
      params: {
        current:1, 
        size:1, 
        ...params
      }
    })
}

export const ohdocumentDetail = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/ohdocument/detail`,
      method: 'get',
      params,
    })
}

// 新增/编辑
export const ohdocumentSubmit = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/ohdocument/submit`,
      method: 'post',
      data: params
    })
}

// 删除
export const ohdocumentRemove = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/ohdocument/remove`,
        method: 'post',
        params: {ids}
    })
}

// 投保记录
export const ohinsurancePage = (current,size,params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/ohinsurance/page`,
    method: 'get',
    params: {
      current,
      size,
      ...params,
    }
  })
}
export const ohinsuranceSubmit = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/ohinsurance/submit`,
    method: 'post',
    data: params
  })
}
export const ohinsuranceRemove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/ohinsurance/remove`,
    method: 'post',
    params: {ids}
  })
}

// 危害接触记录
export const ohhazardcontactPage = (current,size,params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/ohhazardcontact/page`,
    method: 'get',
    params: {
      current,
      size,
      ...params,
    }
  })
}
export const ohhazardcontactSubmit = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/ohhazardcontact/submit`,
    method: 'post',
    data: params
  })
}
export const ohhazardcontactRemove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/ohhazardcontact/remove`,
    method: 'post',
    params: {ids}
  })
}

// 
export const lazyListUser = (params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/dept/lazy-list-with-user',
    method: 'get',
    params,
  })
}
