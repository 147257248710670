import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_+'/personalSharing/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_+'/personalSharing/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const submit = (row) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_+'/personalSharing/submit',
    method: 'post',
    data: row
  })
}

export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + '/personalSharing/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const topList = (number) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + '/personalSharing/topList',
    method: 'get',
    params: {
      number
    }
  })
}
