/*
 * @Author: absorbedyy@163.com
 * @Date: 2024-05-09 09:23:15
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-05-27 15:24:48
 * @Description: 
 */
import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

/** 环保舆情-分页 */
export const epopinionPage = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epopinion/page',
    method: 'get',
    params
  })
}
/** 环保舆情-详情 */
export const epopinionDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epopinion/detail',
    method: 'get',
    params
  })
}
export const epopinionSubmit= (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epopinion/submit',
    method: 'post',
    data
  })
}
export const epopinionRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epopinion/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

/** 环保舆情记录- end */


/** 环保投诉记录- start */

export const epcomplainPage = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epcomplain/page',
    method: 'get',
    params
  })
}
export const epcomplainDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epcomplain/detail',
    method: 'get',
    params
  })
}
export const epcomplainSubmit= (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epcomplain/submit',
    method: 'post',
    data
  })
}

export const epcomplainRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epcomplain/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

/** 环保投诉记录- end */
