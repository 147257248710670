var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-select",
    {
      ref: "main",
      class: _vm.b(),
      attrs: {
        size: _vm.size,
        "popper-class": _vm.popperClass,
        "popper-append-to-body": _vm.popperAppendToBody,
        loading: _vm.loading,
        "loading-text": _vm.loadingText,
        multiple: _vm.multiple,
        "multiple-limit": _vm.limit,
        "collapse-tags": _vm.tags,
        value: _vm.labelShow,
        clearable: _vm.clearableVal,
        placeholder: _vm.placeholder,
        disabled: _vm.disabled,
      },
      on: {
        "remove-tag": _vm.removeTag,
        focus: _vm.handleFocus,
        blur: _vm.handleBlur,
        clear: _vm.handleClear,
      },
      nativeOn: {
        click: function ($event) {
          return _vm.initScroll.apply(null, arguments)
        },
      },
    },
    [
      _vm.filter
        ? _c(
            "div",
            { staticStyle: { padding: "0 10px", margin: "5px 0 0 0" } },
            [
              _c("el-input", {
                attrs: { size: "mini", placeholder: _vm.filterText },
                model: {
                  value: _vm.filterValue,
                  callback: function ($$v) {
                    _vm.filterValue = $$v
                  },
                  expression: "filterValue",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-option",
        { attrs: { value: _vm.text } },
        [
          _c("el-tree", {
            ref: "tree",
            staticClass: "tree-option",
            class: _vm.b("select"),
            staticStyle: { padding: "10px 0" },
            attrs: {
              data: _vm.dicList,
              lazy: _vm.lazy,
              load: _vm.handleTreeLoad,
              "node-key": _vm.valueKey,
              accordion: _vm.accordion,
              "icon-class": _vm.iconClass,
              "show-checkbox": _vm.multiple,
              "expand-on-click-node": _vm.expandOnClickNode,
              props: _vm.treeProps,
              "check-strictly": _vm.checkStrictly,
              "highlight-current": !_vm.multiple,
              "current-node-key": _vm.multiple ? "" : _vm.text,
              "filter-node-method": _vm.filterNode,
              "default-checked-keys": _vm.keysList,
              "default-expanded-keys": _vm.defaultExpandedKeys
                ? _vm.defaultExpandedKeys
                : _vm.keysList,
              "check-on-click-node": _vm.checkOnClickNode,
              "default-expand-all": _vm.defaultExpandAll,
            },
            on: {
              check: _vm.checkChange,
              "node-click": function ($event) {
                if ($event.target !== $event.currentTarget) return null
                return _vm.handleNodeClick.apply(null, arguments)
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ data }) {
                    return _c(
                      "div",
                      {
                        staticStyle: { width: "100%", "padding-right": "10px" },
                      },
                      [
                        _vm.$scopedSlots.default
                          ? _vm._t("default", null, {
                              label: _vm.labelKey,
                              value: _vm.valueKey,
                              item: data,
                            })
                          : [
                              _c(
                                "span",
                                {
                                  class: {
                                    "avue--disabled": data[_vm.disabledKey],
                                  },
                                },
                                [_vm._v(_vm._s(data[_vm.labelKey]))]
                              ),
                              data[_vm.descKey]
                                ? _c("span", { class: _vm.b("desc") }, [
                                    _vm._v(_vm._s(data[_vm.descKey])),
                                  ])
                                : _vm._e(),
                            ],
                      ],
                      2
                    )
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }