import Layout from '@/page/index/'

export default [
  //数据采集
  {
    path: '/hiddenTrouble',
    component: Layout,
    name: '隐患治理',
    children: [
      {
        path: '/hiddenTrouble/hiddenTrouble/daily/edit',
        name: '项目设置',
        component: () =>
          import('@/views/hiddenTrouble/check/daily/form'),
        meta: {
          i18n: '安全检查'
        }
      }, {
        path: '/hiddenTrouble/riskitemView/checklist/edit',
        name: '隐患清单',
        component: () =>
          import('@/views/hiddenTrouble/riskItemView/checklist/form'),
        meta: {
          i18n: '隐患清单'
        }
      }, {
        path: '/hiddenTrouble/riskitemView/evaluate/edit',
        name: '隐患评估',
        component: () =>
          import('@/views/hiddenTrouble/riskItemView/evaluate/form'),
        meta: {
          i18n: '隐患评估'
        }
      }, {
        path: '/hiddenTrouble/riskitemView/rectify/edit',
        name: '隐患整改',
        component: () =>
          import('@/views/hiddenTrouble/riskItemView/rectify/form'),
        meta: {
          i18n: '隐患整改'
        }
      }, {
        path: '/hiddenTrouble/riskitemView/acceptance/edit',
        name: '隐患验收',
        component: () =>
          import('@/views/hiddenTrouble/riskItemView/acceptance/form'),
        meta: {
          i18n: '隐患验收'
        }
      },

      {
        path: '/hiddenTrouble/civilizedNorm/checklist/edit',
        name: '事项清单',
        component: () =>
          import('@/views/hiddenTrouble/civilizedNorm/checklist/form'),
        meta: {
          i18n: '事项清单'
        }
      }, {
        path: '/hiddenTrouble/civilizedNorm/evaluate/edit',
        name: '事项评估',
        component: () =>
          import('@/views/hiddenTrouble/civilizedNorm/evaluate/form'),
        meta: {
          i18n: '事项评估'
        }
      }, {
        path: '/hiddenTrouble/civilizedNorm/rectify/edit',
        name: '事项整改',
        component: () =>
          import('@/views/hiddenTrouble/civilizedNorm/rectify/form'),
        meta: {
          i18n: '事项整改'
        }
      }, {
        path: '/hiddenTrouble/civilizedNorm/acceptance/edit',
        name: '事项验收',
        component: () =>
          import('@/views/hiddenTrouble/civilizedNorm/acceptance/form'),
        meta: {
          i18n: '事项验收'
        }
      },

      {
        path: '/hiddenTrouble/managementView/edit',
        name: '全息管理详情',
        component: () =>
          import('@/views/hiddenTrouble/managementView/form'),
        meta: {
          i18n: '全息管理详情'
        }
      },{
        path: '/hiddenTrouble/civilizedNormManage/edit',
        name: '文明规范管理试图详情',
        component: () =>
          import('@/views/hiddenTrouble/civilizedNormManage/form'),
        meta: {
          i18n: '文明规范管理试图详情'
        }
      },

      //隐患排查
      {
        path: '/hiddenTrouble/check/daily/edit',
        name: '检查计划编辑',
        component: () =>
          import('@/views/hiddenTrouble/check/daily/form'),
        meta: {
          i18n: '检查详情'
        }
      },
      {
        path: '/hiddenTrouble/check/daily/add',
        name: '检查计划新增',
        component: () =>
          import('@/views/hiddenTrouble/check/daily/form'),
        meta: {
          i18n: '检查详情'
        }
      },
      {
        path: '/hiddenTrouble/check/daily/view',
        name: '检查计划详情',
        component: () =>
          import('@/views/hiddenTrouble/check/daily/form'),
        meta: {
          i18n: '检查详情'
        }
      },
      // 季节性检查
      {
        path: '/hiddenTrouble/check/seasonal/edit',
        name: '季节性检查',
        component: () =>
          import('@/views/hiddenTrouble/check/seasonal/form'),
        meta: {
          i18n: '隐患验收'
        }
      },
      // 节假日检查
      {
        path: '/hiddenTrouble/check/holidays/edit',
        name: '节假日检查',
        component: () =>
          import('@/views/hiddenTrouble/check/holidays/form'),
        meta: {
          i18n: '隐患验收'
        }
      },
      // 不定期检查
      {
        path: '/hiddenTrouble/check/irregularly/edit',
        name: '节假日检查',
        component: () =>
          import('@/views/hiddenTrouble/check/irregularly/form'),
        meta: {
          i18n: '隐患验收'
        }
      },
       //检查任务编辑
      {
        path: '/hiddenTrouble/check/checkTasks/edit',
        name: '检查任务执行',
        component: () =>
          import('@/views/hiddenTrouble/check/checkTasks/form'),
        meta: {
          i18n: '检查任务'
        }
      },
      //检查任务详情
      {
        path: '/hiddenTrouble/check/checkTasks/view',
        name: '检查任务详情',
        component: () =>
          import('@/views/hiddenTrouble/check/checkTasks/form'),
        meta: {
          i18n: '检查任务'
        }
      },
       //检查任务新增
       {
        path: '/hiddenTrouble/check/checkTasks/add',
        name: '检查任务新增',
        component: () =>
          import('@/views/hiddenTrouble/check/checkTasks/form'),
        meta: {
          i18n: '检查任务'
        }
      },
      // 任务配置（隐患排查计划）
      {
        path: '/hiddenTrouble/check/troubleshootingPlan/edit',
        name: '任务配置编辑',
        component: () =>
          import('@/views/hiddenTrouble/check/troubleshootingPlan/form'),
        meta: {
          i18n: '检查任务'
        }
      },
      {
        path: '/hiddenTrouble/check/troubleshootingPlan/detail',
        name: '任务配置详情',
        component: () =>
          import('@/views/hiddenTrouble/check/troubleshootingPlan/form'),
        meta: {
          i18n: '检查任务'
        }
      },
         {
        path: '/hiddenTrouble/check/troubleshootingPlan/add',
        name: '任务配置新增',
        component: () =>
          import('@/views/hiddenTrouble/check/troubleshootingPlan/form'),
        meta: {
          i18n: '检查任务'
        }
      },
      {
        path: '/hiddenTrouble/check/casually/edit',
        name: '随手拍详情',
        component: () =>
          import('@/views/hiddenTrouble/check/casually/form'),
        meta: {
          i18n: '随手拍详情'
        }
      }, {
        path: '/hiddenTrouble/without/edit',
        name: '外部检查详情',
        component: () =>
          import('@/views/hiddenTrouble/without/form'),
        meta: {
          i18n: '外部检查详情'
        }
      },
    ]
  },

]
