import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";
export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/report/page",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

export const getMyReportList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/report/myReport",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

export const getPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/report/page",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/report/detail",
    method: "get",
    params: {
      id,
    },
  });
};

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/report/remove",
    method: "post",
    params: {
      ids,
    },
  });
};

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/report/submit",
    method: "post",
    data: row,
  });
};

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/report/submit",
    method: "post",
    data: row,
  });
};
export const getRoleSetting = (reportId) => request({
  url: ServerNameEnum.SERVER_SYSTEM_ + '/reportSettings/list',
  method: 'get',
  params: {
    reportId: reportId
  }
});

export const submitRoleSetting = (reportId, setIds) => request({
  url: ServerNameEnum.SERVER_SYSTEM_ + '/reportSettings/submit',
  method: 'post',
  params: {
    reportId: reportId,
    setIds: setIds
  }
});
