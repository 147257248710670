import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_NAME = "/index/indexManage";


/**
 * 分页查询
 * @param current 页码
 * @param size  每页数量
 * @param params  查询条件
 */
export const extIndexPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}/${CONTROLLER_NAME}/page`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

/**
 * 复合查询指标列表分页查询
 * @param current 页码
 * @param size  每页数量
 * @param params  查询条件
 */
export const compositePage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}/${CONTROLLER_NAME}/compositePage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/list`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const selectById = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/detail`,
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/remove`,
    method: 'post',
    params: {
      ids,
    }
  })
}

export const saveOrUpdate = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/submit`,
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/submit`,
    method: 'post',
    data: row
  })
}

/**
 * 指标管理 编辑》CuboidID下拉菜单初始值
 * @param params
 * @returns {*}
 */
export const selectCuboid = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}/index/themeCuboid/listByThemeId`,
    method: 'get',
    params: {
      ...params
    }
  })
}

/**
 * 指标管理 编辑》CuboidID下拉菜单初始值
 * @param params
 * @returns {*}
 */
export const selectCuboidList = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}/index/themeCuboid/list`,
    method: 'get',
    params: {
      ...params
    }
  })
}

export const selectTheme = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}/index/themeDimension/listByThemeId`,
    method: 'get',
    params: {
      ...params
    }
  })
}

export const removeCombination = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/removeCombination`,
    method: 'post',
    params: {
      ids,
    }
  })
}
export const saveOrUpdatecombinatorialMeasure = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/saveOrUpdatecombinatorialMeasure`,
    method: 'post',
    data: data
  })
}

export const removeCombinatorialMeasure = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/removeCombinatorialMeasure`,
    method: 'post',
    params: {
      ids,
    }
  })
}

export const testWLp = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}/api/index`,
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const appIndexDataApi = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}/api/appIndex`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const appIndexDataApiIntf = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}/api/appIndexIntf`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const listAllMetadataSource = () => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/listAllMetadataSource`,
    method: 'get',
    params: {}
  })
}

export const checkSqlById = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/checkSql`,
    method: 'get',
    params: {
      id
    }
  })
}

export const getDimensionByCuboid = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}/index/indexManage/getDimensionByCuboid`,
    method: 'get',
    params: {
      ...params
    }
  })
}

export const getDimensionValue = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}/api/dimension`,
    method: 'get',
    params: {
      ...params
    }
  })
}

export const lazyTreeCompositeIndexData = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}/api/lazyTreeCompositeIndexData`,
    method: 'get',
    params: {
      ...params
    }
  })
}
