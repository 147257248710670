<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      ref="crud"
      v-model="form"
      @on-load="onLoad"
    >
      <template slot="menuLeft">
        <el-button
          type="danger"
          size="small"
          icon="el-icon-delete"
          plain
          @click="handleDelete"
          >{{$t("cip.cmn.btn.delBtn")}}
        </el-button>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button
          type="text"
          icon="el-icon-circle-plus-outline"
          size="small"
          v-if="scope.row.disAdd && (scope.row.name != '任务待办' && scope.row.name != '待办事宜' && scope.row.name != '知识检索' && scope.row.name != '我的培训' && scope.row.name != '我的考试' && scope.row.name != '报表填报')"
          @click.stop="handleMenuAdd(scope.row)"
          >{{$t("tagsView.add")}}
        </el-button>
        <!--        <el-button
          type="text"
          icon="el-icon-delete"
          size="small"
          @click.stop="handleMenuDel(scope.row)"
        >删除
        </el-button>-->
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { mapGetters } from "vuex";
import iconList from "@/config/iconList";
import { add, removeByMenuId } from "@/api/system/shortcutMenu";
import { getRoutes } from "@/api/system/topmenu"

export default {
  props: {
    disabledData: {
      type: Array,
      default: []
    },
  },
  data() {
    return {
      form: {},
      query: {},
      loading: true,
      selectionList: [],
      parentId: 0,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      option: {
        columnBtn: false,
        header: false,
        border: true,
        searchShow: false,
        searchMenuPosition: 'right',
        searchMenuSpan: 24,
        dialogWidth: "60%",
        tree: true,
        index: true,
        selection: false,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        menuWidth: 100,
        dialogClickModal: false,
        column: [
          {
            label: this.$t('cip.plat.authority.role.field.name'),
            prop: "name",
            search: true,
          },
          {
            label: this.$t('cip.plat.authority.role.field.path'),
            prop: "path",
          },
          {
            label: this.$t('cip.plat.authority.role.field.parentIds'),
            prop: "parentId",
            type: "tree",
            dicData: [],
            hide: true,
            addDisabled: false,
            props: {
              label: "title"
            },
            rules: [
              {
                required: false,
                message: this.$t("cip.cmn.msg.success.parentIdsWarning"),
                trigger: "click"
              }
            ]
          },
          {
            label: this.$t('cip.plat.authority.role.field.source'),
            prop: "source",
            type: "icon",
            slot: true,
            iconList: iconList,
          },
          {
            label: this.$t('cip.plat.authority.role.field.code'),
            prop: "code",
            search: true,

          },
        ]
      },
      data: []
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
  },
  created() {
    this.onLoad();
  },
  methods: {
    handleMenuAdd(row) {
      if (row.children) {
        this.$message({
          type: "error",
          message: this.$t('tagsView.addShortcutTip')
        });
        return
      }
      let param = {
        "menuId": row.id,
        "menuName": row.name,
        "menuPath": row.path,
        "menuSource": row.source
      }
      this.$set(row, "disAdd", false);
      add(param).then((res) => {
        // 获取新增数据的相关字段
        this.$emit('getNewMenu')
        this.$message({
          type: "success",
          message: this.$t('cip.cmn.msg.success.operateSuccess')
        });
      }, error => {
        window.console.log(error);
      });
    },
    handleMenuDel(row) {
      if (row.children.length > 0) {
        this.$message({
          type: "error",
          message: "请选择下级菜单"
        });
        return
      }
      let param = {
        "menuId": row.id,
        "menuName": row.name,
        "menuPath": row.path,
        "menuSource": row.source
      }
      removeByMenuId(param).then((res) => {
        // 获取新增数据的相关字段
        this.$message({
          type: "success",
          message: this.$t('cip.cmn.msg.success.operateSuccess')
        });
      }, error => {
        window.console.log(error);
      });
    },
    onLoad() {
      this.loading = true;
      getRoutes().then(res=>{
        this.data = res.data.data
        this.setDisabled(this.data, this.disabledData)
      })
      this.data = this.menu;
      this.loading = false;
    },
    setDisabled(data, disabledData) {
      // 创建一个 Map 来快速查找 disabledData 中的 id
      const disabledIdsMap = new Map(disabledData.map(dis => [dis.menuId, true]));
      const setDisabledRecursive = (items) => {
        items.forEach((item) => {
          // 检查当前 item 的 id 是否在 disabledIdsMap 中
          if (disabledIdsMap.has(item.id)) {
            this.$set(item, "disAdd", false);
          } else  if (item.children){
            this.$set(item, "disAdd", false);
          } else {
            this.$set(item, "disAdd", true);
          }

          // 如果 item 有 children 属性，递归调用 setDisabledRecursive
          if (item.children) {
            setDisabledRecursive(item.children);
          }
        });
      };

      // 调用递归函数开始处理 data
      setDisabledRecursive(data);
    },
  }
};
</script>

<style>
</style>
