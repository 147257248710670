import request from '@/router/axios'
import ServerNameEnum from '@/util/ServerNameEnum';
const prefix = ServerNameEnum.SERVER_WORK_FLOW_+'/design/deployment'

export const getList = (current, size, params) => {
  return request({
    url: `${prefix}/list`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const remove = (data) => {
  return request({
    url: `${prefix}/remove`,
    method: 'post',
    data
  })
}

export const changeStatus = (data) => {
  return request({
    url: `${prefix}/changeStatus`,
    method: 'post',
    data
  })
}

export const changeCategory = (data) => {
  return request({
    url: `${prefix}/changeCategory`,
    method: 'post',
    data
  })
}

