import httpService from '@/router/axios';
// 系统管理-专项检查项目接口   分页
export const $_getPageList = (params) => {
    return httpService({
        url: '/api/ivystar-wisdom-security/specialcheck/page',
        method: 'get',
        params
    })
}

// 系统管理-专项检查项目接口   新增
export const $_addSubmitList = (row) => {
    return httpService({
        url: '/api/ivystar-wisdom-security/specialcheck/save',
        method: 'post',
        data: row
    })
}

// 系统管理-专项检查项目接口   详情
export const $_getDetail = (id) => {
    return httpService({
        url: `/api/ivystar-wisdom-security/specialcheck/detail/${id}`,
        method: 'get',
    })
}

// 系统管理-专项检查项目接口   修改
export const $_updateData = (row) => {
    return httpService({
        url: '/api/ivystar-wisdom-security/specialcheck/update',
        method: 'post',
        data: row
    })
}

// 系统管理-专项检查项目————检查项目   新增
export const $_addSave_List = (row) => {
    return httpService({
        url: '/api/ivystar-wisdom-security/specialcheckproject/save',
        method: 'post',
        data: row
    })
}

// 系统管理-专项检查项目————检查项目   分页
export const $_getPage_List = (params) => {
    return httpService({
        url: '/api/ivystar-wisdom-security/specialcheckproject/page',
        method: 'get',
        params
    })
}
