/*
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-06-26 14:26:53
 */
import request from '@/router/axios';
import ServerNameEnum from '../../util/ServerNameEnum'

export const greenFactoryPage = (current,size,params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjgreenfactory/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const greenFactorySubmit = ( params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjgreenfactory/submit',
    method: 'post',
    data: params,
  })
}

export const greenFactoryDetail = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_FX+'/prjgreenfactory/detail',
    method:'get',
    params
  })
}

export const  greenFactoryRemove = ( ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjgreenfactory/remove',
    method: 'post',
    params: {ids},
  })
}

export const greenMinesSubmit = ( params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjgreenmines/submit',
    method: 'post',
    data: params,
  })
}

export const greenMinesDetail = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_FX+'/prjgreenmines/detail',
    method:'get',
    params
  })
}
