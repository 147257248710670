import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getWinCode = (weixin_code) => {
  return request({
    url: ServerNameEnum.SERVER_AUTH_+'/oauth/weixin',
    method: 'get',
    params: {
      weixin_code
    }
  })
}

export const getWeixinSignature = (param,type) => {
  return request({
    url: ServerNameEnum.SERVER_AUTH_+'/oauth/weixinSignature?url=' + param +'&type=' +type,
    method: 'get'
  })
}
