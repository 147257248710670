import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
/** 安全奖惩 */
// 头表
export const decideheadPage = (current, size, params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/grsafetydecidehead/page`,
      method: 'get',
      params: {
        current,
        size,
        ...params
      }
    })
}

export const decideheadSubmit = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grsafetydecidehead/submit`,
        method: 'post',
        data: params
    })
}

export const decideheadRemove = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grsafetydecidehead/remove`,
        method: 'post',
        params: {ids}
    })
}

export const decideheadDetail = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grsafetydecidehead/detail`,
        method: 'get',
        params
    })
}

// 行表
export const decideLinePage = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/grsafetydecideline/page`,
      method: 'get',
      params
    })
}

export const decideLineSubmitBatch = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grsafetydecideline/submitBatch`,
        method: 'post',
        data: params
    })
}

export const decideLineRemove = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grsafetydecideline/remove`,
        method: 'post',
        params: {ids}
    })
}

// export const decideheadDetail = (params) => {
//     return request({
//         url: `${ServerNameEnum.SERVER_FX}/grsafetydecideline/detail`,
//         method: 'get',
//         params
//     })
// }
