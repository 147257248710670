<template>
  <div>
      <avue-form ref="form" v-model="dataForm" :option="option" :upload-after="uploadAfter" :upload-preview="uploadPreview" style="padding:10px;">
        <!--国际化表单输入框-->
        <template v-for="item in globalSlotList" :slot="item.prop" slot-scope="scope">
          <div v-if="item.read === true">
            <el-input :value="getGlobalItemVal(item.prop)" readonly @input="changeGlobalVal(item.prop,$event)">
              <template slot="suffix">
                <i class="iconfont icon-zhongyingwen" style="cursor: pointer;" @click="showGlobalBox(scope.column)"></i>
              </template>
            </el-input>
          </div>
          <div v-else>
            <el-input :value="getGlobalItemVal(item.prop)" @input="changeGlobalVal(item.prop,$event)">
              <template slot="suffix">
                <i class="iconfont icon-zhongyingwen" style="cursor: pointer;" @click="showGlobalBox(scope.column)"></i>
              </template>
            </el-input>
          </div>
        </template>
        <template slot="jobCron" slot-scope="{disabled,size}">
          <el-popover
            v-model="cronPopover"
            placement="bottom"
            trigger="click"
            width="500">
            <vcrontab :expression="dataForm.jobCron" @fill="crontabFill"
                      @hide="handleHide"></vcrontab>
            <el-input
              slot="reference"
              v-model="dataForm.jobCron"
              :placeholder="$t('cip.plat.xxljob.task.field.jobCron')"
            />
          </el-popover>
        </template>

      </avue-form>
    <el-dialog :close-on-click-modal="false" :visible.sync="globalBox" append-to-body width="345px" @close="globalBoxClose"
               :title="this.$t('cip.cmn.components.internationalizedData.title.internationalizedDataTitle')">
      <el-form ref="globalForm" :model="globalForm" label-width="70px">
        <el-form-item
          :rules="[{required: this.language==='en', message: this.$t('cip.cmn.components.internationalizedData.msg.required'), trigger: 'submit' },
          { min: 0, max: 200, message: this.$t('cip.cmn.rule.nameLength20Warning'), trigger: 'blur' }]"
          :label="this.$t('cip.cmn.components.internationalizedData.field.en')" prop="en">
          <el-input v-model="globalForm.en" autocomplete="off" clearable size="small"></el-input>
        </el-form-item>
        <el-form-item :rules="[{required: this.language==='zh', message: this.$t('cip.cmn.components.internationalizedData.msg.required'), trigger: 'submit' },
          { min: 0, max: 200, message: this.$t('cip.cmn.rule.nameLength20Warning'), trigger: 'blur' }]"
          :label="this.$t('cip.cmn.components.internationalizedData.field.zh')" prop="zh">
          <el-input v-model="globalForm.zh" autocomplete="off" clearable size="small"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="globalBox = false">{{$t('cip.cmn.btn.celBtn')}}</el-button>
          <el-button size="mini" type="primary" @click="submitGlobal">{{$t('cip.cmn.btn.defBtn')}}</el-button>
        </span>
    </el-dialog>
    <fileView ref="fileView" title="附件预览"></fileView>
  </div>


</template>

<script>

import vcrontab from "vcrontab";
import {isJSON} from "@/util/util";
import {mapGetters} from "vuex";

export default {
  components: {vcrontab},
  props: {
    column: {
      type: Array,
      default: []
    },
    group: {
      type: Array,
      default: []
    },
    dataForm: Object,
    // option:{
    //   type:Object
    // },
  },
  computed: {
    globalSlotList() {
      let _list = [];
      if (this.group && this.group.length > 0) {
        let columns = [];
        this.group.forEach(item => {
          columns = columns.concat(item.column);
        })
        _list = columns.filter(item => {
          return item.isGlobal === true;
        })
      } else {
        _list = this.column.filter(item => {
          return item.isGlobal === true;
        })
      }
      return _list;
    },
    ...mapGetters(["language"])
  },
  data() {
    return {
      cronPopover: false,
      globalBox: false,
      globalForm: {},
      form: null,
      formHeight: 0,
      sizeValue: 'small',
      globalItem: null,
      option: {
        labelWidth: 100,
        size: this.sizeValue,
        submitBtn: false,
        emptyBtn: false,
        gutter: 10,
        column: [],
        group: [],
        pageType: 'add'
      }
    }
  },
  watch: {
    dataForm: {
      deep: true,
      immediate: true,
      handler(val) {
        // this.$store.commit('SET_NEWFORM', val)
        this.$emit('update:dataForm', val);
      }
    },
    column(val){
      if(val.length > 0){
        this.option.column = this.initColumn(val);
      }
    }
  },
  mounted() {
    // setTimeout(() => {
    //   const data = JSON.parse(JSON.stringify(this.dataForm))
    //   this.$store.commit('SET_OLDFORM', data)
    // },5000)
    this.formHeight = document.getElementsByClassName("el-form").item(0).clientHeight + 200 + "px";
    this.form = this.$refs.form;
    if (document.getElementsByClassName('sampleCode').length != 0) {
      let sampleCode = document.getElementsByClassName('sampleCode')[0].parentNode.parentNode.parentNode.firstChild;
      sampleCode.style.color = "#1890ff";
    }

    // 流程详情内嵌表单将表单占位符置空
    let that = this;
    window.addEventListener('message',function(event) {
      if (event.data.type == 'workFlow') {
        that.$refs.form.propOption.forEach((item) =>{
          item.placeholder = ' ';
        })
        that.$forceUpdate();

        // let formChildren = that.form.$refs.form.$children[0].$children[0].$children[0].$children[0].$children;
        // formChildren.forEach((item) =>{
        //   if (item.$children[0].$children[1] != undefined) {
        //     item.$children[0].$children[1].$children[0].placeholder = '';
        //   }
        // })
      }
    }, false)
  },
  created() {
    this.pageType = this.$route.query.pageType;
    if (this.group && this.group.length > 0) {
      this.group.forEach(item => {
        item.column = this.initColumn(item.column)
      })
      this.option.group = this.group;
    } else {
      this.option.column = this.initColumn(this.column);
    }
  },
  methods: {
    uploadAfter(res, done, loading, column) {
      if (column.prop == "filePath") {
        this.$emit('getFileName', res.originalName)
      }
      done();
    },
    uploadPreview(file,column,done){
      this.$refs.fileView.showFile(file.url)
    },
    init(data) {
      if (data == undefined) {
        this.$refs.form.resetForm()
        this.dataForm = {};
      } else {
        this.dataForm = data;
      }
    },
    getForm() {
      return this.$refs.form;
    },
    //修改组件固定字段宽度为编辑页面动态穿span
    initColumn(column) {
      var _this = this;
      column.forEach(function (item, index) {
        if (_this.pageType == 'detail') {
          item.readonly = true
        }
        if (item.span == undefined || item.span == '') {
          if (item.type == 'textarea') {
            item.span = 16;
            item.minRows = 2;
            item.maxRows = 2;
            if (item.label == '协议配置参数') {
              item.span = 24;
              item.minRows = 20;
              item.maxRows = 20;
            }
          } else if (item.type == 'content') {
            item.span = 24;
          } else {
            item.span = 8;
          }
        }

        //是否国际化
        if (item.isGlobal === true) {
          item.clearable = false;
          item.readonly = true;
        }
      })
      return column;
    },
    //获取国际化字段当前语言的值
    getGlobalItemVal(prop) {
      let v = this.dataForm[prop];
      let l = this.language;
      if (isJSON(v)) {
        return JSON.parse(v)[l];
      } else {
        return v;
      }
    },
    //修改国际化字段
    changeGlobalVal(prop, val) {
      let v = this.dataForm[prop];
      let vObj = isJSON(v) ? JSON.parse(v) : {[this.language]: v};
      this.globalForm = vObj;
      this.globalForm[this.language] = val;
      this.dataForm[prop] = JSON.stringify(this.globalForm);
      if (val.length == 0) {
        this.dataForm[prop] = ''
      }
      this.$emit('change',this.dataForm)
    },
    //显示国际化弹窗
    showGlobalBox(column) {
      let v = this.dataForm[column.prop];
      let vObj = isJSON(v) ? JSON.parse(v) : {[this.language]: v};
      this.globalForm = vObj;
      this.globalBox = true;
      this.globalItem = column;
    },
    //国际化弹窗关闭
    globalBoxClose() {
      this.globalItem = null;
      this.globalForm = {};
      this.$refs['globalForm'].resetFields();
    },
    //国际化弹窗提交
    submitGlobal() {
      this.$refs['globalForm'].validate((valid) => {
        if (valid) {
          this.dataForm[this.globalItem.prop] = JSON.stringify(this.globalForm);
          this.globalBox = false;
        }
      })
    },
    handleHide() {
      this.cronPopover = false
    },
    crontabFill(value) {
      //确定后回传的值
      this.dataForm.jobCron = value;
    },
    showDialog() {
      this.expression = this.input;//传入的 cron 表达式，可以反解析到 UI 上
      this.showCron = true;
    }
  }
}
</script>
