var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c(
        "avue-crud",
        {
          ref: "crud",
          attrs: {
            option: _vm.option,
            "table-loading": _vm.loading,
            data: _vm.data,
          },
          on: { "on-load": _vm.onLoad },
          scopedSlots: _vm._u([
            {
              key: "menu",
              fn: function (scope) {
                return [
                  scope.row.disAdd &&
                  scope.row.name != "任务待办" &&
                  scope.row.name != "待办事宜" &&
                  scope.row.name != "知识检索" &&
                  scope.row.name != "我的培训" &&
                  scope.row.name != "我的考试" &&
                  scope.row.name != "报表填报"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-circle-plus-outline",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleMenuAdd(scope.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("tagsView.add")) + "\n      ")]
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "template",
            { slot: "menuLeft" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    icon: "el-icon-delete",
                    plain: "",
                  },
                  on: { click: _vm.handleDelete },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.delBtn")) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }