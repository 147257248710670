import Layout from '@/page/index/'

export default [
  //安全日志
  {
    path: '/securityLog',
    component: Layout,
    name: '安全日志',
    children: [
      {
        path: '/securityLog/index',
        name: '安全日志',
        component: () =>
          import('@/views/securityLog/index'),
        meta: {
          i18n: '安全日志'
        }
      }, {
        path: '/securityLog/without/edit',
        name: '外部检查详情',
        component: () =>
          import('@/views/securityLog/form'),
        meta: {
          i18n: '外部检查详情'
        }
      },
    ]
  },

]
