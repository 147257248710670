import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

/** 体检记录 */
export const ohhcPage = (current, size, params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/ohhc/page`,
      method: 'get',
      params: {
        current, 
        size, 
        ...params
      }
    })
}

export const ohhcSubmit = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/ohhc/submit`,
        method: 'post',
        data: params
      })
}

// 删除
export const ohhcRemove = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/ohhc/remove`,
        method: 'post',
        params: {ids}
    })
}

export const ohhcDetail = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/ohhc/detail`,
    method: 'get',
    params
  })
}

// 体检明细
export const ohhcDetailList = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/ohhcdetail/list`,
    method: 'get',
    params
  })
}

export const ohhcDetailSubmit = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/ohhcdetail/submit`,
    method: 'post',
    data: params
  })
}

export const ohhcDetailRemove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/ohhcdetail/remove`,
    method: 'post',
    data: ids
  })
}

export const ohhcDetailDetail = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/ohhcdetail/detail`,
    method: 'get',
    params
  })
}
