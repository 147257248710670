<template>
  <div class="avue-sidebar">
    <el-scrollbar style="overflow-x: hidden;height: calc(100vh - 60px);">
      <el-menu :collapse="keyCollapse"
               ref="sidderMenu"
               @select="sidderSelect"
               :default-active="nowTagValue"
               :show-timeout="200"
               mode="vertical"
               unique-opened>
        <sidebar-item :collapse="keyCollapse"
                      :menu="menuList"
                      :props="website.menu.props"
                      :screen="screen"
                      first></sidebar-item>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import logo from "../logo";
import sidebarItem from "./sidebarItem";

export default {
  name: "sidebar",
  components: {sidebarItem, logo},
  inject: ["index"],
  data() {
    return {
      blendMenu: []
    };
  },
  created() {
  },
  computed: {
    ...mapGetters(["website", "tag", "menu", "isCollapse", "screen", "menuId", "layoutType", "language", "menuOnly", "keyCollapse"]),
    nowTagValue() {
      return this.$router.$avueRouter.getValue(this.$route);
    },
    menuList() {
      if (this.layoutType == 'blend') {
        return this.menuOnly;
      } else {
        return this.menu
      }
    }
  },
  methods: {
    // 确保window.open打开新页面时不会高亮
    sidderSelect() {
      this.$nextTick(() => {
        const path = this.$route.path;
        this.$refs.sidderMenu.activeIndex = path;
      })
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-submenu .el-menu-item {
  height: 48px;
  line-height: 48px;
  font-size: 16px;
}

::v-deep .el-submenu__title {
  font-size: 16px;
}
</style>

