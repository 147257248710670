import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export function getApiCall(url, header, data) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/' + url,
    method: 'get',
    headers: header,
    params: data
  })
}

export function postApiCall(url, header, data) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/' + url,
    method: 'post',
    headers: header,
    data: data
  })
}
