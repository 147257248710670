import Layout from '@/page/index/'

export default [
  // 作业票表单模式
  {
    path: '/jobSlip',
    component: Layout,
    name: '作业票管理',
    children: [
      {
        path: '/jobSlip/jobTicketList/index',
        name: '作业票清单',
        component: () => import('@/views/business/jobSlip/jobTicketList/index'),
        meta: {
          i18n: '作业票清单'
        }
      },
      {
        path: '/jobSlip/jobTicketList/hotWork',
        name: '动火作业票',
        component: () => import('@/views/business/jobSlip/jobTicketList/hotWork/index'),
        meta: {
          i18n: '动火作业票'
        }
      },
      {
        path: '/jobSlip/jobTicketList/electricWork',
        name: '临时用电安全作业票',
        component: () => import('@/views/business/jobSlip/jobTicketList/electricWork/index'),
        meta: {
          i18n: '临时用电安全作业票'
        }
      },
      {
        path: '/jobSlip/jobTicketList/wtBaseDirt',
        name: '动土作业票',
        component: () => import('@/views/business/jobSlip/jobTicketList/wtBaseDirt/index'),
        meta: {
          i18n: '动土作业票'
        }
      },
      {
        path: '/jobSlip/jobTicketList/heightWork',
        name: '高处作业票',
        component: () => import('@/views/business/jobSlip/jobTicketList/heightWork/index'),
        meta: {
          i18n: '高处作业票'
        }
      },
      {
        path: '/jobSlip/jobTicketList/wtBaseBpp',
        name: '盲板抽堵作业票',
        component: () => import('@/views/business/jobSlip/jobTicketList/wtBaseBpp/index'),
        meta: {
          i18n: '盲板抽堵作业票'
        }
      },
      {
        path: '/jobSlip/jobTicketList/openCircuitWork',
        name: '断路作业票',
        component: () => import('@/views/business/jobSlip/jobTicketList/openCircuitWork/index'),
        meta: {
          i18n: '断路作业票'
        }
      },
      {
        path: '/jobSlip/jobTicketList/explodeWork',
        name: '爆破作业票',
        component: () => import('@/views/business/jobSlip/jobTicketList/explodeWork/index'),
        meta: {
          i18n: '爆破作业票'
        }
      },
      {
        path: '/jobSlip/jobTicketList/otherWork',
        name: '其他作业票',
        component: () => import('@/views/business/jobSlip/jobTicketList/otherWork/index'),
        meta: {
          i18n: '其他作业票'
        }
      },
      {
        path: '/jobSlip/jobTicketList/offline',
        name: '线下作业票',
        component: () => import('@/views/business/jobSlip/jobTicketList/offline/index'),
        meta: {
          i18n: '线下作业票'
        }
      },
      {
        path: '/jobSlip/jobTicketList/limitSpace',
        name: '受限空间作业票',
        component: () => import('@/views/business/jobSlip/jobTicketList/limitSpace/index'),
        meta: {
          i18n: '受限空间作业票'
        }
      },
      {
        path: '/jobSlip/limitedSpaceLedger',
        name: '有限空间台账',
        component: () => import('@/views/business/jobSlip/limitedSpaceLedger/index'),
        meta: {
          i18n: '有限空间台账'
        }
      },
      {
        path: '/jobSlip/jobTicketList/hoistingWork',
        name: '吊装安全作业票',
        component: () => import('@/views/business/jobSlip/jobTicketList/hoistingWork/index'),
        meta: {
          i18n: '吊装安全作业票'
        }
      },

      {
        path: '/jobSlip/jobTicketList/jobTicketApproving',
        name: '作业票审核',
        component: () => import('@/views/business/jobSlip/jobTicketList/jobTicketApproving'),
        meta: {
          i18n: '吊装安全作业票'
        }
      },
    ]
  },

]
