
//分页数据（每页多少条） 系统默认的分页选择 DEFAULT_PAGE_SIZE ， 非默认的分页数据（页数固定的）选择具体值
export const PAGE_CONSTANT = {

  DEFAULT_PAGE_SIZE: 20, // 当前值会修改，10，20 ,其他均有可能
  TEN: 10,
  TWENTY: 20,
  //Cps 数采监控每页显示多少条
  CPS_MONITORING : 8,
  //此分页的数据用于查询出全部的数据，过滤弹出层的数据
  PAGE_LIST:99999999
}
