import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_BATCH_+'/batchRule/add',
    method: 'post',
    data: row
  })
}


export const getDetail = (cateCode) => {
  return request({
    url: `${ServerNameEnum.SERVER_BATCH_}/batchRule/detail`,
    method: 'get',
    params: {
      cateCode
    }
  })
}
