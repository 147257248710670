import httpService from "@/router/axios";

//获取app版本列表信息
export const getAppConfigList = (params) => {
    return httpService({
        url: "/api/ivystar-wisdom-security/appversion/page",
        method: "get",
        params,
    });
};

// 新增app版本
export const addAppVersion = (params) => {
    return httpService({
        url: "/api/ivystar-wisdom-security/appversion/save",
        method: "post",
        data: params,
    });
};
// 修改app版本
export const updateAppVersion = (params) => {
    return httpService({
        url: "/api/ivystar-wisdom-security/appversion/update",
        method: "post",
        data: params,
    });
};
