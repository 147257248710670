/*
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-09-24 14:03:03
 */
import request from '@/router/axios';

export const pageList = (row) => {
  return request({
    url: '/api/sinoma-maas-center/algo/pageList',
    method: 'post',
    data: row
  })
}
export const getById = (row) => {
  return request({
    url: '/api/sinoma-maas-center/algo/getById',
    method: 'post',
    data: row
  })
}
export const add = (row) => {
  return request({
    url: '/api/sinoma-maas-center/algo/add',
    method: 'post',
    data: row
  })
}

export const remove = (row) => {
  return request({
    url: '/api/sinoma-maas-center/algo/del',
    method: 'post',
    data: row
  })
}
export const edit = (row) => {
  return request({
    url: '/api/sinoma-maas-center/algo/edit',
    method: 'post',
    data: row
  })
}

//标签接口
export const tagsList = (row) => {
  return request({
    url: '/api/sinoma-maas-center/tags/list',
    method: 'post',
    data: row
  })
}
//算法版本
export const versionPageList = (row) => {
  return request({
    url: '/api/sinoma-maas-center/algo-version/pageList',
    method: 'post',
    data: row
  })
}
//更新版本
export const uploadVersion = (row) => {
  return request({
    url: '/api/sinoma-maas-center/algo/uploadVersion',
    method: 'post',
    data: row
  })
}

//设为默认版本
export const major = (row) => {
  return request({
    url: '/api/sinoma-maas-center/algo/major',
    method: 'post',
    data: row
  })
}
//删除版本
export const delVersion = (row) => {
  return request({
    url: '/api/sinoma-maas-center/algo/delVersion',
    method: 'post',
    data: row
  })
}


export const getServerById = (row) => {
  return request({
    url: '/api/sinoma-maas-center/server/getById',
    method: 'post',
    data: row
  })
}

export const serverEdit = (row) => {
  return request({
    url: '/api/sinoma-maas-center/server/edit',
    method: 'post',
    data: row
  })
}

//识别配置
export const recognizePageList = (row) => {
  return request({
    url: '/api/sinoma-maas-center/algo-recognize/pageList',
    method: 'post',
    data: row
  })
}

export const recognizeAdd = (row) => {
  return request({
    url: '/api/sinoma-maas-center/algo-recognize/add',
    method: 'post',
    data: row
  })
}

export const recognizeRemove = (row) => {
  return request({
    url: '/api/sinoma-maas-center/algo-recognize/del',
    method: 'post',
    data: row
  })
}
export const recognizeEdit = (row) => {
  return request({
    url: '/api/sinoma-maas-center/algo-recognize/edit',
    method: 'post',
    data: row
  })
}

//标签
export const tagsBatchByIdList = (row) => {
  return request({
    url: '/api/sinoma-maas-center/tags/batchByIdList',
    method: 'post',
    data: row
  })
}
