var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": _vm.$t(
                    `cip.plat.monitor.log.error.field.localErrorLog`
                  ),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "avue-crud",
        {
          attrs: { data: _vm.logsList, option: _vm.option },
          scopedSlots: _vm._u([
            {
              key: "type",
              fn: function (scope) {
                return [
                  _c("el-tag", { attrs: { type: "danger", size: "small" } }, [
                    _vm._v(_vm._s(scope.label)),
                  ]),
                ]
              },
            },
            {
              key: "expand",
              fn: function (props) {
                return [
                  _c("pre", { staticClass: "code" }, [
                    _vm._v("        " + _vm._s(props.row.stack) + "\n      "),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "menuLeft" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-upload",
                  },
                  on: { click: _vm.send },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("cip.plat.monitor.log.error.field.uploadServer")
                    )
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    icon: "el-icon-delete",
                  },
                  on: { click: _vm.clear },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("cip.plat.monitor.log.error.field.clearLocalLog")
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }