var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "avue-top-sidebar", attrs: { id: "avue-top-sidebar" } },
    [
      _c(
        "el-menu",
        {
          ref: "topSidderMenu",
          attrs: {
            collapse: _vm.isCollapse,
            "default-active": _vm.nowTagValue,
            "unique-opened": true,
            mode: "horizontal",
          },
          on: { select: _vm.topSidderSelect },
        },
        [
          _c("topsidebarItem", {
            attrs: {
              collapse: _vm.isCollapse,
              menu: _vm.list,
              props: _vm.website.menu.props,
              screen: _vm.screen,
              first: "",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }