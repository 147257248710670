var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "top-menu" },
    [
      _c(
        "el-menu",
        { attrs: { "default-active": _vm.activeIndex, mode: "horizontal" } },
        [
          _vm._l(_vm.list, function (item, index) {
            return [
              _c(
                "el-menu-item",
                {
                  key: index,
                  attrs: { index: item.id + "" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.openMenu(item, index)
                    },
                  },
                },
                [
                  _c("template", { slot: "title" }, [
                    _c(
                      "div",
                      {
                        class: _vm.language === "en" ? "menuItemBox" : "",
                        attrs: { title: _vm.generateTitle(item) },
                      },
                      [
                        _c("i", {
                          class: item.source,
                          staticStyle: { "padding-right": "5px" },
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.generateTitle(item)))]),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ]
          }),
          _c(
            "el-dropdown",
            [
              _c("span", { staticClass: "el-dropdown-link" }, [
                _vm._v("\n        " + _vm._s(_vm.$t("moreMenu")) + "\n      "),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                _vm._l(_vm.moreMenuList, function (item, index) {
                  return _c(
                    "el-dropdown-item",
                    { key: index },
                    [
                      _c(
                        "el-menu-item",
                        {
                          key: index + 7,
                          attrs: { index: item.id + "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.openMenu(item, index)
                            },
                          },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c("i", { class: item.source }),
                            _c("span", [
                              _vm._v(_vm._s(_vm.generateTitle(item))),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }