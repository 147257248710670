import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
const CONTROLLER_NAME = "msgSendRecord";
/**
 * 分页查询
 * @param current 页码
 * @param size  每页数量
 * @param params  查询条件
 * @returns AxiosPromise
 */
export const selectPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/${CONTROLLER_NAME}/selectPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getMsgTypeTree = (parentId) => {
  return request({
    url: ServerNameEnum.SERVER_MESSAGE_+'/msgTypeItem/selectTree',
    method: 'get',
    params: {
      parentId : parentId
    }
  });
};

export const getSelectById = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/${CONTROLLER_NAME}/selectById`,
    method: 'get',
    params: {
      id : id
    }
  });
};

export const getSelectByMsgId = (type) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/msgSendDetail/selectByChannel`,
    method: 'get',
    params: {
      id : type
    }
  });
};

export const readByMsgId = (msgId) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/msgSendDetail/readByMsgId`,
    method: 'get',
    params: {
      msgId : msgId
    }
  });
};

export const readList = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/msgSendDetail/readList`,
    method: 'get',
    params: {
      ids
    }
  });
};
export const readAll = () => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/msgSendDetail/readAll`,
    method: 'get',
  });
};

export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/msgSendDetail/remove`,
    method: 'get',
    params: {
      ids
    }
  });
};

export const selectMsgByAccount = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_MESSAGE_ + '/msgSendDetail/selectMsgByAccount',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  });
};

export const detailselectPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_MESSAGE_+'/msgSendDetail/selectMsg',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  });
};

export const getDetailInfo = (id) => {
  return request({
    url: ServerNameEnum.SERVER_MESSAGE_+'/msgSendDetail/selectById',
    method: 'get',
    params: {
      id : id
    }
  });
};

export const reSend = (id) => {
  return request({
    url: ServerNameEnum.SERVER_MESSAGE_+'/msgSend/reSend',
    method: 'get',
    params: {
      id : id
    }
  });
};


export const send = (params) => {
  return request({
    url: ServerNameEnum.SERVER_MESSAGE_+'/msgSend/send',
    method: 'post',
    data: {
      ...params
    }
  });
};

export const getMsgDetail = (msgId) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/attach/batchDetail',
    method: 'get',
    params: {
      msgId: msgId
    }
  });
};

export const getSelectParams = (msgId) => {
  return request({
    url: ServerNameEnum.SERVER_MESSAGE_+'/msgSendDetail/selectParams',
    method: 'get',
    params: {
      msgId : msgId
    }
  });
};
