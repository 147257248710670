import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 刷新参数缓存
export function refreshMetadata () {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/sources/refresh',
    method: 'get'
  })
}

export function listDataSource (data) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/sources/list',
    method: 'get',
    params: data
  })
}

export function pageDataSource (data) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/sources/page',
    method: 'get',
    params: data
  })
}

export function getDataSource (id) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/sources/' + id,
    method: 'get'
  })
}
export function getDataSource2 (id) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/sources/encrypt/' + id,
    method: 'get'
  })
}

export function delDataSource (id) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/sources/' + id,
    method: 'delete'
  })
}

export function delDataSources (ids) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/sources/batch/' + ids,
    method: 'delete'
  })
}

export function addDataSource (data) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/sources',
    method: 'post',
    data: data
  })
}

export function updateDataSource (data) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/sources/' + data.id,
    method: 'put',
    data: data
  })
}

export function checkConnection (data) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/sources/checkConnection',
    method: 'post',
    data: data
  })
}
export function checkConnection2 (data) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/sources/encrypt/checkConnection',
    method: 'post',
    data: data
  })
}

export function queryByPage (data) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/sources/queryByPage',
    method: 'post',
    data: data
  })
}

export function getDbTables (id) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/sources/' + id + '/tables',
    method: 'get'
  })
}

export function getDbTableColumns (id, tableName) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/sources/' + id + '/' + tableName + '/columns',
    method: 'get'
  })
}

export function sync (id) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/sources/sync/' + id,
    method: 'post'
  })
}

export function word (id) {
  return request({
    url:ServerNameEnum.SERVER_ZT_+ '/sources/word/' + id,
    method: 'post',
    responseType: 'blob'
  })
}
