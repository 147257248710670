var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showFileDialog
    ? _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: _vm.title,
            modal: true,
            "append-to-body": "",
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _vm.soureFileType == "video"
            ? _c("video", {
                staticClass: "chatList__msg--video",
                attrs: { src: _vm.link, controls: "" },
              })
            : _c("iframe", {
                ref: "fileIframe",
                staticStyle: { width: "100%", height: "100%" },
                attrs: { id: "iframe", src: _vm.link, frameborder: "0" },
              }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }