var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tagsView
    ? _c("div", { staticClass: "avue-tags" }, [
        _vm.contextmenuFlag
          ? _c(
              "div",
              {
                staticClass: "avue-tags__contentmenu",
                style: {
                  left: _vm.contentmenuX + "px",
                  top: _vm.contentmenuY + "px",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "item", on: { click: _vm.clearCacheTags } },
                  [_vm._v(_vm._s(_vm.$t("tagsView.clearCache")) + "\n    ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "item",
                    on: {
                      click: function ($event) {
                        return _vm.closeOthersTags("click")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("tagsView.closeOthers")) + "\n    ")]
                ),
                _c(
                  "div",
                  { staticClass: "item", on: { click: _vm.closeAllTags } },
                  [_vm._v(_vm._s(_vm.$t("tagsView.closeAll")) + "\n    ")]
                ),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "avue-tags__box",
            class: { "avue-tags__box--close": !_vm.website.isFirstPage },
          },
          [
            _c(
              "el-tabs",
              {
                attrs: { type: "card", closable: _vm.tagLen !== 1 },
                on: { "tab-click": _vm.openTag, edit: _vm.menuTag },
                nativeOn: {
                  contextmenu: function ($event) {
                    return _vm.handleContextmenu.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.active,
                  callback: function ($$v) {
                    _vm.active = $$v
                  },
                  expression: "active",
                },
              },
              _vm._l(_vm.tagList, function (item) {
                return _c("el-tab-pane", {
                  key: item.value,
                  attrs: { label: _vm.generateTitle(item), name: item.value },
                })
              }),
              1
            ),
            _c(
              "el-dropdown",
              { staticClass: "avue-tags__menu" },
              [
                _c("el-button", { attrs: { type: "primary", size: "mini" } }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("tagsView.menu")) +
                      "\n        "
                  ),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            _vm.$parent.isSearch = true
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("tagsView.search")))]
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.closeOthersTags("menu")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("tagsView.closeOthers")))]
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.closeAllTags.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("tagsView.closeAll")))]
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.clearCacheTags.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("tagsView.clearCache")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }