import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 风险发生可能（L） 新增||修改
export const bsRiskLecdLSubmit = (data) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/bsRiskLecdL/submit`,
      method: 'post',
      data
    })
  }
  // 风险发生可能（L） 删除
  export const bsRiskLecdLRemove = (data) => {
      return request({
        url: `${ServerNameEnum.SERVER_FX}/bsRiskLecdL/remove?ids=${data.ids}`,
        method: 'post',

      })
  }
  // 风险发生可能（L） 列表
  export const bsRiskLecdLPage = (params) => {
      return request({
        url: `${ServerNameEnum.SERVER_FX}/bsRiskLecdL/page`,
        method: 'get',
        params
      })
  }
  // 风险发生可能（L） 详情
  export const bsRiskLecdLDetail = (params) => {
      return request({
        url: `${ServerNameEnum.SERVER_FX}/bsRiskLecdL/detail`,
        method: 'get',
        params
      })
  }


// 人员暴露（E） 新增||修改
export const bsrisklecdeSubmit = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/bsrisklecde/submit`,
    method: 'post',
    data
  })
}
// 人员暴露（E） 删除
export const bsrisklecdeRemove = (data) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/bsrisklecde/remove?ids=${data.ids}`,
      method: 'post',
    })
}
// 人员暴露（E） 列表
export const bsrisklecdePage = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/bsrisklecde/page`,
      method: 'get',
      params
    })
}
// 人员暴露（E） 详情
export const bsrisklecdeDetail = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/bsrisklecde/detail`,
      method: 'get',
      params
    })
}


  // 风险后果（C） 新增||修改
export const bsRiskLecdCSubmit = (data) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/bsRiskLecdC/submit`,
      method: 'post',
      data
    })
  }
  // 风险后果（C） 删除
  export const bsRiskLecdCRemove = (data) => {
      return request({
        url: `${ServerNameEnum.SERVER_FX}/bsRiskLecdC/remove?ids=${data.ids}`,
        method: 'post',
      })
  }
  // 风险后果（C） 列表
  export const bsRiskLecdCPage = (params) => {
      return request({
        url: `${ServerNameEnum.SERVER_FX}/bsRiskLecdC/page`,
        method: 'get',
        params
      })
  }
  // 风险后果（C） 详情
  export const bsRiskLecdCDetail = (params) => {
      return request({
        url: `${ServerNameEnum.SERVER_FX}/bsRiskLecdC/detail`,
        method: 'get',
        params
      })
  }

  // 风险等级（D） 新增||修改
export const bsrisklecddlevelSubmit = (data) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/bsrisklecddlevel/submit`,
      method: 'post',
      data
    })
  }
  // 风险等级（D） 删除
  export const bsrisklecddlevelRemove = (data) => {
      return request({
        url: `${ServerNameEnum.SERVER_FX}/bsrisklecddlevel/remove?ids=${data.ids}`,
        method: 'post',
      })
  }
  // 风险等级（D） 列表
  export const bsrisklecddlevelPage = (params) => {
      return request({
        url: `${ServerNameEnum.SERVER_FX}/bsrisklecddlevel/page`,
        method: 'get',
        params
      })
  }
  // 风险等级（D） 详情
  export const bsrisklecddlevelDetail = (params) => {
      return request({
        url: `${ServerNameEnum.SERVER_FX}/bsrisklecddlevel/detail`,
        method: 'get',
        params
      })
  }


// 不分页(L)
export const getbsRiskLecdLMap = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/bsRiskLecdL/list`,
    method: 'get',
    params
  })
}

// 不分页(E)
export const getbsrisklecdeMap = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/bsrisklecde/list`,
    method: 'get',
    params
  })
}
// 不分页(C)
export const getbsRiskLecdCMap = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/bsRiskLecdC/list`,
    method: 'get',
    params
  })
}

// 不分页(D)
export const getbsrisklecddlevelMap = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/bsrisklecddlevel/list`,
    method: 'get',
    params
  })
}
