var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "uploader-file", attrs: { status: _vm.status } },
    [
      _c("div", {
        staticClass: "uploader-file-progress",
        class: _vm.progressingClass,
        style:
          _vm.file.customCompleted || _vm.status === "error"
            ? _vm.successProgressStyle
            : _vm.progressStyle,
      }),
      _c("div", { staticClass: "uploader-file-info" }, [
        _c("div", { staticClass: "uploader-file-name" }, [
          _c("i", { staticClass: "el-icon-paperclip" }),
          _vm._v(_vm._s(_vm.file.name)),
        ]),
        _c("div", { staticClass: "uploader-file-status" }, [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.file.customCompleted,
                  expression: "file.customCompleted",
                },
              ],
            },
            [_vm._v(_vm._s(_vm.statusText))]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.file.customCompleted,
                  expression: "!file.customCompleted",
                },
              ],
            },
            [
              _vm.status !== "uploading" && _vm.status !== "success"
                ? _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.file.customStatus === "check"
                            ? "文件校验中"
                            : _vm.statusText
                        )
                    ),
                  ])
                : _c("span", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.progressStyle.progress === "100%"
                            ? "99%"
                            : _vm.progressStyle.progress
                        )
                      ),
                    ]),
                  ]),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "uploader-file-actions" },
          [
            _vm.file.customStatus !== "check"
              ? [
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.status === "waiting" ||
                          _vm.status === "uploading",
                        expression: "status==='waiting'||status==='uploading'",
                      },
                    ],
                    staticClass: "actions-icon ym-custom ym-custom-pause",
                    on: { click: _vm.pause },
                  }),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.status === "paused",
                        expression: "status==='paused'",
                      },
                    ],
                    staticClass: "actions-icon el-icon-caret-right",
                    on: { click: _vm.resume },
                  }),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.status === "error",
                        expression: "status==='error'",
                      },
                    ],
                    staticClass: "actions-icon el-icon-refresh-right",
                    on: { click: _vm.retry },
                  }),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.status !== "success",
                        expression: "status!=='success'",
                      },
                    ],
                    staticClass: "actions-icon el-icon-close",
                    on: { click: _vm.remove },
                  }),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }