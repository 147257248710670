import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getCount = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdchecktask/count',
    method: 'get',
    params
  })
}

export const getList = (current,size,params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdchecktask/page',
    method: 'get',
    params:{
      current,
      size,
      ...params
    }
  })
}
export const detail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdchecktask/detail',
    method: 'get',
    params
  })
}
export const submit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdchecktask/submit',
    method: 'post',
    data
  })
}
export const hdchecktaskproSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdchecktaskpro/submit',
    method: 'post',
    data
  })
}
export const problemPage = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdchecktaskpro/problemPage',
    method: 'GET',
    params
  })
}
export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdchecktask/remove',
    method: 'post',
    params: {
      ids
    }
  })
}
export const getTreeTypes = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdchecktaskobjecttype/typeObjectTree',
    method: 'get',
    params
  })
}
export const hdchecktasklineList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdchecktaskline/list',
    method: 'get',
    params
  })
}
export const hdchecktasklineDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdchecktaskline/detail',
    method: 'get',
    params
  })
}
export const hdchecktasklineUpdate = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdchecktaskline/update',
    method: 'post',
    data
  })
}
export const getCode = () => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckact/getCode',
    method: 'get',
  })
}
