import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edutree/list`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edutree/detail`,
    method: 'get',
    params: {
      id
    }
  })
}

export const removeEdu = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edutree/remove`,
    method: 'post',
    params: {
      ids,
    }
  })
}

export const addEdu = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edutree/submit`,
    method: 'post',
    data: row
  })
}

export const updateEdu = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edutree/submit`,
    method: 'post',
    data: row
  })
}

export const getEduLazyTree = (parentId) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edutree/lazy-tree`,
    method: 'get',
    params: {
      parentId
    }
  })
}

export const getEduTree = (tenantId) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edutree/tree`,
    method: 'get',
    params: {
      tenantId,
    }
  })
}

export const getLazyList = (parentId, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edutree/lazy-list`,
    method: 'get',
    params: {
      ...params,
      parentId
    }
  })
}

