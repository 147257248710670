import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduuserexam/page`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const particularsPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduuserexam/particularsPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduuserexam/detail`,
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduuserexam/remove`,
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduuserexam/submit`,
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduuserexam/submit`,
    method: 'post',
    data: row
  })
}

export const getDetailById = (taskId) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduuserexam/getDetailById`,
    method: 'get',
    params: {
      taskId
    }
  })
}
export const trainingInquiry = (taskId) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduuserexam/trainingInquiry`,
    method: 'get',
    params: {
      taskId,
    }
  })
}
