import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/education/page`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/education/getDetail`,
    method: 'get',
    params: {
      id
    }
  })
}
export const updateCoursewareAccessTime = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/education/updateCoursewareAccessTime`,
    method: 'get',
    params: {
      id
    }
  })
}
export const getVideo = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/education/getVideo`,
    method: 'get',
    params: {
      id
    }
  })
}
export const myTrainingDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/education/myTrainingDetail`,
    method: 'get',
    params: {
      id
    }
  })
}

export const getStatisticsPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/education/getStatisticsPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getIsCompulsoryDicData = () => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dict-biz/dictionary?code=education_is_compulsory`,
    method: 'get',
  })
}

export const questionsSubmit = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/education/questionsSubmit`,
    method: 'post',
    data: row
  })
}

export const saveOrUpdate = (trainingEducationId) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/education/saveOrUpdate?trainingEducationId=` + trainingEducationId,
    method: 'post',
  })
}

export const saveVideo = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/education/saveVideo`,
    method: 'post',
    data: row
  })
}
export const updateEducationQuestions = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/education/updateEducationQuestions`,
    method: 'post',
    data: row
  })
}

export const verifyAnswer = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/education/verifyAnswer`,
    method: 'post',
    data: row
  })
}

export const scoreUploadList = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/scoreUpload/list`,
    method: 'get',
    params: {
      id
    }
  })
}
export const saveUpdateScoreUpload = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/scoreUpload/saveUpdateScoreUpload`,
    method: 'post',
    data: row
  })
}
export const scoreUploadDelete = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/scoreUpload/delete?id=`+id,
    method: 'get',
  })
}

