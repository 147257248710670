import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaperquestion/list`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaperquestion/detail`,
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaperquestion/remove`,
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaperquestion/submit`,
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaperquestion/submit`,
    method: 'post',
    data: row
  })
}

export const getExamPaper = (paperId,id,myTrainingTestId) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaperquestion/getExamPaper`,
    method: 'get',
    params: {
      paperId,
      id,
      myTrainingTestId:myTrainingTestId
    }
  })
}
export const saveEndTime = (paperId) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaperquestion/saveEndTime`,
    method: 'get',
    params: {
      paperId,
    }
  })
}

// 更新用户考试剩余时间
export const updateEndTime = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/examTime/updateEndTime`,
    method: 'post',
    data
  })
}

// 更新用户已作答题目
export const userAnswerSave = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/userAnswer/saveUpdate`,
    method: 'post',
    data
  })
}
