import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

/** 环保突发事件-分页 */
export const epeventPage = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/dbtbeforeclass/page',
      method: 'get',
      params
    })
  }
  
  /** 环保突发事件-详情 */
  export const epeventDetail = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/dbtbeforeclass/detail',
      method: 'get',
      params
    })
  }

