import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/serialNumber/page`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/serialNumber/remove`,
    method: 'post',
    params: {
      ids,
    }
  })
}
export const saveOrUpdate = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/serialNumber/submit`,
    method: 'post',
    data: row
  })
}

export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/serialNumber/detail`,
    method: 'get',
    params: {
      id,
    }
  })
}

// 获取编码
export const getPageCode = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/serialNumber/getSerial`,
    method: 'get',
    params
  })
}

