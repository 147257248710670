import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 获取分页列表
export const getPage = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/worksafety/page',
    method: 'get',
    params
  })
}
// 获取详情
export const getDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/worksafety/detail',
    method: 'get',
    params
  })
}
// 发起
export const submit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/worksafety/submit',
    method: 'post',
    data
  })
}
// 逻辑删除
export const remove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + `/worksafety/remove?ids=${data.ids}`,
    method: 'post',
    data
  })
}
// 保存
export const addUpdate = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/worksafety/saveOrUpdate',
    method: 'post',
    data
  })
}
// 获取编号
export const getCode = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/worksafety/getCode',
    method: 'get',
    params
  })
}

// 获取班前会及当天交底接口
export const getMeetList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/dbtbeforeclass/todayMeeting',
    method: 'get',
    params
  })
}