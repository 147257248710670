import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_NAME = "/index/indexcategory";
/**
 * 分类树
 * @param params
 * @returns {*}
 */
export const tree = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/tree`,
    method: 'get',
    params
  })
}
