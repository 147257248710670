import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const CONTROLLER_DEVICE = "/device";

export const getList = (current, size, params, locationCode, netCode) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_DEVICE+'/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
      locationCode,
      netCode
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_DEVICE+'/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_DEVICE+'/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
export const batchDeviceStatus = (ids,isEnable) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_DEVICE+'/batchDeviceStatus',
    method: 'get',
    params: {
      ids,
      isEnable,
    }
  })
}

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_DEVICE+'/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_DEVICE+'/update',
    method: 'post',
    data: row
  })
}

export const submit = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_DEVICE+'/submit',
    method: 'post',
    data: row
  })
}

export const modelUpdate = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_DEVICE+'/modelUpdate',
    method: 'post',
    data: row
  })
}
export const updateDeviceStatus = (id, isEnable) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_DEVICE+'/updateDeviceStatus',
    method: 'get',
    params: {
      id,
      isEnable
    }
  })
}
export const getListPage = ( current, size,params) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_DEVICE+'/getListPage',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}


