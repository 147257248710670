import request from '@/router/axios';
const SERVER_FX = '/api/sinoma-hse-prj'
// 项目进出场日志列表
export const getList = ( params) => {
    return request({
      url: SERVER_FX + '/prjwholerecord/page',
      method: 'get',
      params
    })
  }
