import {tableHe,bodyHe} from './tableHeight'
import {loadmore} from './loadmore'
const directives = {
  tableHe,
  bodyHe,
  loadmore

}
export default {
  install(app){
    Object.keys(directives).forEach((key)=>{
      app.directive(key,directives[key])
    })
  }
}

