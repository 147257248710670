import request from "@/router/axios";

export const visualization = params => {
  //可视化指标
  return request({
    url: `/api/sinoma-system/mockIndicatorValue1/listIndex?${params}`,
    // url: `/test_api/sinoma-system/mockIndicatorValue1/listIndex?${params}`,
    method: "get"
  });
};
export const getProjectCountByApplied = params => {
  //可视化指标
  return request({
    url: `/api/sinoma-hse-prj/appliedtatisticsnum/getProjectCountByApplied?${params}`,
    // url: `/test_api/sinoma-system/mockIndicatorValue1/listIndex?${params}`,
    method: "get",
  });
};
export const worldData = params => {
  //世界地图数据
  return request({
    url: `/api/sinoma-hse-prj/prjbasicinfo/prjUserCount`,
    method: "get",
    params: params
  });
};
// 监控token
export const LoginToService = params => {
  return request({
    url: `/dmonitor/LoginToService`,
    method: "get",
    params: params
  });
};
// 监控 方向
export const dmonitorPtz = params => {
  return request({
    url: `/dmonitor/ptz`,
    method: "get",
    params: params
  });
};
// 监控列表
export const dmonitorChannels = params => {
  return request({
    url: `/dmonitor/org/Channels`,
    method: "get",
    params: params
  });
};
export const dangerCountry = params => {
  //危险区域数据
  return request({
    url: `/api/sinoma-hse-prj/bsareadanger/listDangerCountry`,
    method: "get",
    params: params
  });
};
export const dbtbeforeclass = params => {
  //班前会
  return request({
    url: `/api/sinoma-hse-prj/dbtbeforeclass/page`,
    method: "get",
    params: params
  });
};
export const securityinspection = data => {
  //今日安全检查统计
  return request({
    url: `/api/sinoma-hse-prj/securityinspection/getTodayNum`,
    method: "post",
    data
  });
};
export const dbtmonitoraccountSave = data => {
  //监控管理 添加
  return request({
    url: `/api/sinoma-hse-prj/dbtmonitoraccount/save`,
    method: "post",
    data
  });
};
export const dbtmonitoraccountUpdate = data => {
  //监控管理 修改
  return request({
    url: `/api/sinoma-hse-prj/dbtmonitoraccount/update`,
    method: "post",
    data
  });
};
export const dbtmonitoraccountPage = params => {
  //监控管理 列表
  return request({
    url: `/api/sinoma-hse-prj/dbtmonitoraccount/page`,
    method: "get",
    params
  });
};
export const dbtmonitoraccountDetail = params => {
  //监控管理 详情
  return request({
    url: `/api/sinoma-hse-prj/dbtmonitoraccount/detail`,
    method: "get",
    params
  });
};
export const dbtmonitoraccountStatSummary = params => {
  //安全管理 AI智能分析统计
  return request({
    url: `/api/sinoma-hse-prj/almrecord/statSummary`,
    method: "get",
    params
  });
};
export const dbtmonitoraccountRemove = data => {
  //监控管理 添加
  return request({
    url: `/api/sinoma-hse-prj/dbtmonitoraccount/remove?ids=${data}`,
    method: "post",
  });
};
// 气象灾害
export const weatherInfo = data => {
  return request({
    url: `/api/sinoma-hse-prj/dbtmeteorologicaldisasterwarning/getInfo`,
    method: "post",
    data
  });
};

// 气象灾害
export const weatherInfoWarning = params => {
  return request({
    url: `/api/sinoma-hse-prj/dbtmeteorologicaldisasterwarning/page`,
    method: "get",
    params
  });
};

// dbtearlywarningdisposal/list
// 气象灾害
export const warningMeasure = params => {
  return request({
    url: `/api/sinoma-hse-prj/dbtearlywarningdisposal/list`,
    method: "get",
    params
  });
};