<template>
  <div class="top-menu">
    <el-menu :default-active="activeIndex" mode="horizontal">
      <template v-for="(item, index) in list">
        <el-menu-item
          :key="index"
          :index="item.id + ''"
          @click.native="openMenu(item,index)"
        >
          <template slot="title">
            <div :class="language==='en'?'menuItemBox':''" :title="generateTitle(item)">
            <i :class="item.source" style="padding-right: 5px"></i>
            <span>{{ generateTitle(item) }}</span>
            </div>
          </template>
        </el-menu-item>
      </template>
      <el-dropdown>
        <span class="el-dropdown-link">
          {{$t('moreMenu')}}
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item, index) of moreMenuList" :key="index">
            <el-menu-item
              :key="index + 7"
              :index="item.id + ''"
              @click.native="openMenu(item,index)"
            >
              <template slot="title">
                <i :class="item.source"></i>
                <span>{{ generateTitle(item) }}</span>
              </template>
            </el-menu-item>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-menu>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import { isURL } from "@/util/validate";
export default {
  name: "top-menu",
  data() {
    return {
      itemHome: {
        name: this.$t('navbar.home'),
        source: 'el-icon-menu',
      },
      activeIndex: "0",
      list:[],
      moreMenuList:[]
    };
  },
  inject: ["index", "topIndex"],
  created() {
    setTimeout(() => {
      this.getMenuList();
    }, 2000);
  },
  computed: {
    ...mapGetters(["menu","language",'website']),
    labelKey() {
      return this.website.menu.props.label || this.config.propsDefault.label;
    },
    pathKey() {
      return this.website.menu.props.path || this.config.propsDefault.path;
    },
  },
  methods: {
    openMenu(item,index) {
      let token = this.$store.getters.token;
      if (item.path == '/CipReport?user=test') {
        window.open(window.location.origin + item.path + '?isNewWindow=true');
        return;
      }
      if (item.isOpen == 1) {
        if (isURL(item.path)) {
          if (item.isSingleSignOn == 0) {
            if (item[this.pathKey].indexOf("doc.html") > -1) {
              window.open(item[this.pathKey]);
            }
            window.open(
              item[this.pathKey] + "&access_token=" + token + "&language=zh"
            );
          } else {
            window.open(item[this.pathKey]);
          }
        } else {
          window.open(
            window.location.origin + "#" + item.path + "?isNewWindow=true"
          );
        }
        return;
      }
      if (item.children.length == 0) {
        this.$router.$avueRouter.group = item.group;
        this.$router.$avueRouter.meta = item.meta;
        this.$router.push({
          path: this.$router.$avueRouter.getPath({
            name: item[this.labelKey],
            src: item[this.pathKey]
          }, item.meta),
          query: item.query
        });
      }
      this.$store.commit("SET_MENUONLY", item.children);
      this.$store.commit('SET_MENU_CLICK_INDEX',index)
    },
    generateTitle(item) {
      return this.$router.$avueRouter.generateTitle(
        item.name,
        (item.meta || {}).i18n
      );
    },
    getMenuList() {
      let _this = this;
      this.$nextTick(() => {
        // 监听页面宽度变化
        let width = document.body.offsetWidth - 707;
        let newList = []
        let number = 0
        if(this.language === 'en') {
          number = Math.floor(width / 160)
        } else {
          number = Math.floor(width / 116)
        }
        let length = _this.menu.length
        if (number >= length) return _this.list = _this.menu
        for (let i = 0; i < length; i++) {
          const item = _this.menu[i];
          if (i < number - 1) {
            newList.push(item)
          } else {
            this.moreMenuList.push(item)
          }
        }
        _this.list = newList
      })
      window.onresize = () => {
        this.getMenuList()
      }
    }
  }
};
</script>
<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 63px !important;
}

::v-deep .el-menu-item {
  font-size: 16px;
}
.menuItemBox{
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #FFFFFF;
}
</style>
