import request from '@/router/axios';
const SERVER_FX = '/api/sinoma-hse-prj'

// 应急演练列表
export const erexercisePage = (current, size, params) => {
  return request({
    url: SERVER_FX + '/erexercise/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
// 应急演练基本信息详情
export const erexerciseGetDetail = (id) => {
  return request({
    url: SERVER_FX + '/erexercise/detail',
    method: 'get',
    params: {
      id
    }
  })
}
// 应急演练列表删除
export const erexerciseRemove = (ids) => {
  return request({
    url: SERVER_FX + '/erexercise/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
// 应急演练基本信息保存
export const erexerciseAdd = (row) => {
  return request({
    url: SERVER_FX + '/erexercise/submit',
    method: 'post',
    data: row
  })
}

// 应急演练--人员清单列表
export const erexercisememberPage = (current, size, params) => {
  return request({
    url: SERVER_FX +'/erexercisemember/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
// 应急演练--人员清单列表详情
export const erexercisememberGetDetail = (id) => {
  return request({
    url: SERVER_FX +'/erexercisemember/detail',
    method: 'get',
    params: {
      id
    }
  })
}
// 应急演练--人员清单列表删除
export const erexercisememberRemove = (ids) => {
  return request({
    url: SERVER_FX +'/erexercisemember/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
// 应急演练--人员清单列表新增/ 签到
export const erexercisememberSubmit = (row) => {
  return request({
    url: SERVER_FX +'/erexercisemember/submit',
    method: 'post',
    data: row
  })
}

// 应急演练--人员清单列表批量签到
export const erexercisememberSubmitBatch = (row) => {
  return request({
    url: SERVER_FX +'/erexercisemember/submitBatch',
    method: 'post',
    data: row
  })
}

// 处置程序
// 应急演练--处置程序列表
export const erexerciseprocessPage = (current, size, params) => {
  return request({
    url: SERVER_FX +'/erexerciseprocess/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
// 应急演练--处置程序列表详情
export const erexerciseprocessGetDetail = (id) => {
  return request({
    url: SERVER_FX +'/erexerciseprocess/detail',
    method: 'get',
    params: {
      id
    }
  })
}
// 应急演练--处置程序列表删除
export const erexerciseprocessRemove = (ids) => {
  return request({
    url: SERVER_FX +'/erexerciseprocess/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
// 应急演练--处置程序列表新增
export const erexerciseprocessSubmit = (row) => {
  return request({
    url: SERVER_FX +'/erexerciseprocess/submit',
    method: 'post',
    data: row
  })
}
// 应急演练--处置程序新增获取最大序号
export const erexerciseprocessgetMaxSeqNum = (params) => {
  return request({
    url: SERVER_FX +'/erexerciseprocess/getMaxSeqNum',
    method: 'get',
    params
  })
}

// 处置措施
// 应急演练--处置措施列表
export const erexercisetreatmentPage = (current, size, params) => {
  return request({
    url: SERVER_FX +'/erexercisetreatment/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
// 应急演练--处置措施列表详情
export const erexercisetreatmentGetDetail = (id) => {
  return request({
    url: SERVER_FX +'/erexercisetreatment/detail',
    method: 'get',
    params: {
      id
    }
  })
}
// 应急演练--处置措施列表删除
export const erexercisetreatmentRemove = (ids) => {
  return request({
    url: SERVER_FX +'/erexercisetreatment/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
// 应急演练--处置措施列表新增
export const erexercisetreatmentSubmit = (row) => {
  return request({
    url: SERVER_FX +'/erexercisetreatment/submit',
    method: 'post',
    data: row
  })
}
// 应急演练--处置措施新增获取最大序号
export const erexercisetreatmentgetMaxSeqNum = (params) => {
  return request({
    url: SERVER_FX +'/erexercisetreatment/getMaxSeqNum',
    method: 'get',
    params
  })
}

// 应急物资
// 应急演练--应急物资列表
export const erexercisematerialPage = (current, size, params) => {
  return request({
    url: SERVER_FX +'/erexercisematerial/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
// 应急演练--应急物资列表详情
export const erexercisematerialGetDetail = (id) => {
  return request({
    url: SERVER_FX +'/erexercisematerial/detail',
    method: 'get',
    params: {
      id
    }
  })
}
// 应急演练--应急物资列表删除
export const erexercisematerialRemove = (ids) => {
  return request({
    url: SERVER_FX +'/erexercisematerial/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
// 应急演练--应急物资列表新增
export const erexercisematerialSubmit = (row) => {
  return request({
    url: SERVER_FX +'/erexercisematerial/submit',
    method: 'post',
    data: row
  })
}
// 应急演练--应急物资新增获取最大序号
export const erexercisematerialgetMaxSeqNum = (params) => {
  return request({
    url: SERVER_FX +'/erexercisematerial/getMaxSeqNum',
    method: 'get',
    params
  })
}

// 应急演练--执行结果提交
export const erexerciseresultSubmit = (row) => {
  return request({
    url: SERVER_FX +'/erexerciseresult/submit',
    method: 'post',
    data: row
  })
}

// 应急演练--执行结果查看详情
export const erexerciseresultGetDetail = (exeId) => {
  return request({
    url: SERVER_FX +'/erexerciseresult/detail',
    method: 'get',
    params: {
      exeId
    }
  })
}