import Layout from '@/page/index/'

export default [{
  path: '/login',
  name: '登录',
  component: () =>
    import( /* webpackChunkName: "page" */ '@/page/login/index'),
  meta: {
    keepAlive: true,
    isTab: false,
    isAuth: false
  }
},
{
  path: '/ssoLogin',
  name: 'sso登录',
  component: () =>
    import( /* webpackChunkName: "page" */ '@/page/ssoLogin/index'),
  meta: {
    keepAlive: true,
    isTab: false,
    isAuth: false
  }
},{
  path: '/transfer',
  name: '中转',
  component: () =>
    import( /* webpackChunkName: "page" */ '@/page/transfer/index'),
  meta: {
    keepAlive: true,
    isTab: false,
    isAuth: false
  }
},
  {
    path: '/lock',
    name: '锁屏',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/lock/index'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/appShow',
    name: 'app展示',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/appShow/index'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/changePassword',
    name: '修改密码',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/login/changePassword'),
    meta: {
      keepAlive: false,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/404',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/components/error-page/404'),
    name: '404',
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/notFLow',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/components/error-page/notFLow'),
    name: 'notFLow',
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/iframeIndex',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/iframeIndex'),
    meta: {
      keepAlive: false,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/403',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/components/error-page/403'),
    name: '403',
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/500',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/components/error-page/500'),
    name: '500',
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/',
    name: '主页',
    redirect: '/cip'
  },
  {
    path: '/myiframe',
    component: Layout,
    redirect: '/myiframe',
    children: [{
      path: ":routerPath",
      name: 'iframe',
      component: () =>
        import( /* webpackChunkName: "page" */ '@/components/iframe/main'),
      props: true
    }]

  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/knowledgeBrowse',
    name: '知识浏览',
    meta: {
      keepAlive: false,
      isTab: false,
      isAuth: false
    },
    component: () =>
      import('@/views/business/knowledge/knowledgeSearch/browse/browse'),
  },
  {
    path: '/large-screen/index',
    name: '智慧大屏',
    meta: {
      keepAlive: false,
      isTab: false,
      isAuth: false
    },
    component: () =>
      import('@/views/large-screen/index.vue'),
  },
  {
    path: '/visualization/IntelligentSecurity/index',
    name: '公司安全监管',
    meta: {
      keepAlive: false,
      isTab: false,
      isAuth: false
    },
    component: () =>
      import('@/views/visualization/IntelligentSecurity/index'),
  },
  {
    path: '/visualization/weatherDisasterWarning/index',
    name: '气象灾害预警',
    meta: {
      keepAlive: false,
      isTab: false,
      isAuth: false
    },
    component: () =>
      import('@/views/visualization/weatherDisasterWarning/index'),
  },
  {
    path: '/visualization/IntelligentSecurity/interligentSecurityL',
    name: '驾驶舱看板（智慧安环）',
    meta: {
      keepAlive: false,
      isTab: false,
      isAuth: false
    },
    component: () =>
      import('@/views/visualization/IntelligentSecurity/interligentSecurityL'),
  },
  {
    path: '/visualization/surveillanceVideo/index',
    name: '视频监控',
    meta: {
      keepAlive: false,
      isTab: false,
      isAuth: false
    },
    component: () =>
      import('@/views/visualization/surveillanceVideo/index'),
  },
  {
    path: '/visualization/wxViode/index',
    name: '视频监控（移动端）',
    meta: {
      keepAlive: false,
      isTab: false,
      isAuth: false
    },
    component: () =>
      import('@/views/visualization/wxViode/index'),
  },
  {
    path: '/visualization/wxSecurity/index',
    name: '安全管理（移动端）',
    meta: {
      keepAlive: false,
      isTab: false,
      isAuth: false
    },
    component: () =>
      import('@/views/visualization/wxSecurity/index'),
  },
  {
    path: '/visualization/wxViode/details/:url',
    name: '视频监控（详情）',
    meta: {
      keepAlive: false,
      isTab: false,
      isAuth: false
    },
    component: () =>
      import('@/views/visualization/wxViode/details.vue'),
  },
  {
    path: '/visualization/securityManagement/index',
    name: '项目安全监管',
    meta: {
      keepAlive: false,
      isTab: false,
      isAuth: false
    },
    component: () =>
      import('@/views/visualization/securityManagement/index'),
  },
  // {
  //   path: '/beforeclass/index',
  //   name: '班前会(测试)',
  //   meta: {
  //     keepAlive: false,
  //     isTab: false,
  //     isAuth: false
  //   },
  //   component: () =>
  //     import('@/views/beforeclass/index'),
  // },
  {
    path: '/trainingBrowse',
    name: '我的培训',
    meta: {},
    component: () =>
      import(  '@/views/business/training/trainingEducation/trainingBrowse.vue')
  },
  {
    path: '/wxOauth',
    name: '企业微信',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/views/home/wxOauth'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/wxRedirect',
    name: '企业微信重定向',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/views/home/wxRedirect'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/wxDefaultBrower',
    name: '企业微信打开默认浏览器',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/views/home/wxDefaultBrower'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },

  {
    path: '/unifiedPortalLogin',
    name: '统一门户',
    component: () =>
        import( /* webpackChunkName: "page" */ '@/views/home/unifiedPortalLogin'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },


  {
    path: '/gotoApplet',
    name: '企业微信到小程序',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/views/home/gotoApplet'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/wxGoApplet',
    name: '企业微信到小程序重定向',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/views/home/wxGoApplet'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/process/external/iframe/detail',
    name: '流程详情',
    meta: {
      isTab: false,
      isAuth: false,
    },
    children:[],
    component: () =>
      import('@/views/plugin/workflow/process/external/iframe/detail.vue'),
  },
  {
    path: '/visualization/humanResourceAnalysis/mobile',
    name: '人力资源分析移动端',
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    },
    component: () =>
      import('@/views/visualization/humanResourceAnalysis/mobile'),
  },
]
