import Layout from '@/page/index/'
//生产
export default [  {
  path: '/xxlJob',
  component: Layout,
  children: [
    {
      path: 'userManage/edit',
      name: '用户编辑',
      component: () =>
        import('@/views/xxlJob/userManage/edit'),
      meta: {
        title: '用户编辑',
        icon: '',
      }
    },
    {
      path: 'actuator/edit',
      name: '执行器管理',
      component: () =>
        import('@/views/xxlJob/actuator/edit'),
      meta: {
        title: '执行器管理',
        icon: '',
      }
    },
    {
      path: 'task/edit',
      name: '任务管理',
      component: () =>
        import('@/views/xxlJob/task/edit'),
      meta: {
        title: '任务管理',
        icon: '',
      }
    },
    {
      path: 'task/add',
      name: '任务新增',
      component: () =>
        import('@/views/xxlJob/task/add'),
      meta: {
        title: '任务新增',
        icon: '',
      }
    },
  ]
}
]
