import datacenter_en from "./datacenter/datacenter_en";
import indexManage_en from "./index/indexManage_en";
import mdModule_en from "./md/md_en";
import pmModule_en from "./pm/pm_en";
import qmModule_en from "./qm/qm_en";
import cpsModule_en from "./cps/cps_en";
import platModule_en from "./platform/platform_en";
// import formFillingPlan_en from "./formFillingPlan/formFillingPlan_en";
export default {
  cip: {
    cmn: {
      rule: {
        inputWarning: "Please enter ",
        selectWarning: "Please select ",
        noEmptyWarning: ' cannot be empty',
        inputErrorMessage: "Please enter the correct",
        removeSpacesWarning: "Please delete the space",
        deleteWarning: "Please delete the space before and after",
        nameLength2Warning: 'length cannot be greater than 20',
        nameLength3Warning: 'length cannot be greater than 30',
        nameLength4Warning: 'length cannot be greater than 40',
        nameLength5Warning: "length cannot be greater than 50",
        nameLength6Warning: 'length cannot be greater than 60',
        nameLength10Warning: 'length cannot be greater than 100',
        nameLength20Warning: "Between 0 and 200 characters in length",
        photograph: "Can only upload in jpg/png format and does not exceed 10M",
        ten: "No more than ten digits",
        number: "Please input number",
        orderNum: "Sort ",
        identityCardWarning: "Clear enter the correct ID number",
        autoValue: "Automatically",
      },
      btn: {
        send: "Send",
        modelEdit: 'modelEdit',
        reportConfig: "reportConfig",
        print: "Print",
        handel: "handel",
        execute: "execute",
        configuration: "configuration",
        choice: "choice",
        logout: "logout",
        batchInspection: "batchInspection",
        batchRule: "batchRule",
        batchNumRule: "batchNumRule",
        relieveBtn: "relieveBtn",
        shieldingBtn: "shieldingBtn",
        BloodRelationshipBtn: "BloodRelationshipBtn",
        saveLineBtn: "addLineBtn",
        viewProcessBtn: "viewProcess",
        processBtn: "processProgress",
        rolePermission: "Permission",
        publishBtn: "Publish",
        addBtn: "Add",
        editBtn: "Edit",
        configureFormula: "configureFormula",
        saveBtn: "Save",
        saveBackBtn: "SaveBack",
        delBtn: "Delete",
        viewBtn: "View",
        defBtn: "Save",
        celBtn: "Cancel ",
        clearBtn: "Clear",
        revBtn: "Modified ",
        addChildBtn: "AddChild",
        dicConfigBtn: "DictionaryConfiguration",
        validBtn: 'Valid',
        invalidBtn: 'Invalid',
        historyBtn: 'History',
        chartBtn: 'Chart',
        earlyWarningBtn: 'EarlyWarning',
        exportBtn: "Export",
        exportTemplateBtn: "Export Template",
        initiateBtn: "Initiate",
        importBtn: "Import",
        viewDataBtn: 'ViewData',
        permissionsBtn: "Permission settings",
        enable: "Enable",
        disable: "Disable",
        disableBtn: "Disable",
        detailBtn: "Detail",
        submitBtn: "Submit",
        unSubmitBtn: "Revocation",
        returnLoginBtn: "Return Login",
        copyBtn: "Copy",
        configBtn: "Configuration",
        selIndexBtn: "Select Metrics",
        dealBtn: "DealBtn",
        tipDetermineGenRecordBtn: "Confirm Generation",
        backBtn: "Retrieve",
        calBtn: "Count",
        goBackBtn: "GoBack",
        FillingBtn: "FillingBtn",
        chooseItemBtn: "ChooseItem",
        chooseData: "Data acquisition",
        autoCollctionBtn: "AutoCollction",
        initializationBtn: "Initialization",
        generateBtn: "Generate",
        audit: "audit",
        approve: "approve",
        startBtn: "start",
        stopBtn: "stop",
        resetCacheBtn: "resetCache",
        dispatchBtn: "dispatch",
        addPeopleBtn:'add People',
      },
      msg: {
        success: {
          addSuccess: "Add success",
          updataSuccess: "Modified successfully ",
          delSuccess: "Delete successfully ",
          operateSuccess: 'Operation successfully',
          setSuccess: "setSuccess successfully",
          backSuccess: 'tack back successfully',
          enableSuccess: "Enabled successfully",
          importSuccess: "Import successfully",
          sumbError: "Do not repeat submission",
          deptError:"You can select only one default department",
          selectARole:"Please select at least one role in the department",
          isDefaultDept:"Select at least one department as the primary organization"
        },
        error: {
          addError: "Add failure ",
          updataError: "Modify failed ",
          delError: "Delete failed ",
          nodeError: 'The node itself cannot be self-selected or subordinate',
          operateError: "Operation Error",
          saveError: 'Save Error',
          serviceError: 'serviceError!',
          generateError: "Data generation failed",
          dataError: 'If the system data is incorrect, please contact the administrator for handling',
          importError: "Import Error",
          // 后端国际化
          queryServiceError: "Service call failure during query",
          periodDateIsNullError: "The corresponding Accounting period date does not exist",
          periorYearError: "Statistical year conversion failed",
          submitError: "Submit Error",
          unitError: "Failed to query unit information!",
        },
        warning: {
          selectRightData: 'Please select data that can be deleted',
          addWarning: "Please save data first ",
          updataWarning: "Sure to change the data?",
          delWarning: "Sure to delete data?",
          retWarning: "Do you want to return to the list page?",
          selectWarning: " Please select data ",
          defaultCarry: "The default is automatic takeout",
          tipDetermineNoSelect: "Please select first",
          chooseAtLeastOne: "Please select at least one piece of data",
          tipDetrmineSelectDel: "Please select the data to delete",
          startusingWarning: "Make sure to select data enable?",
          tipDetermineNoSelectCate: "Please select a category first",
          loseefficacyWarning: "Confirm to select data invalidation?",
          determineDelWarning: 'Are you sure to delete the selected data?',
          determineUnsubmitWarning: 'Are you sure to withdraw the selected data?',
          tipDetermineNoSaveHead: "Please save the header table data first!",
          confirmWarning: "Whether to return to the list page",
          filterateWarning: "Enter keywords for filtering",
          editWarning: "Please select the data to edit",
          deleteWarning: "Please select the data to delete",
          selectEditNodeWarning: "Please select the node to edit",
          selectDeleteNodeWarning: "Please select the node to delete",
          hasChildrenWarning: 'There are subitems that have not been deleted!',
          hasIndicators: 'There are indicators under the node that have not been deleted',
          determineRemoveWarning: "Confirm to remove the selected data?",
          selectOneWarning: 'Only one piece of data can be selected',
          saveHeadWarning: "Please save the header table data first!",
          hasChildren: "There are subitems that have not been deleted!",
          onlyHaveOneYes: "The selection of indicators must and can only have one piece of data as the default",
          tipDetermineHaveFinishIn: "The current material has been received!",
          tipDetermineHaveFinishInStatus: "The current material has already been received and there is data that has already been received, unable to delete!",
          tipDetermineHaveFinishOutStatus: "There is data that has already been shipped out, unable to delete!",
          tipDetermineHaveFinishInStatusRow: "The current data has been stored and cannot be deleted!",
          tipDetermineHaveFinishOutStatusRow: "The current data has been shipped out and cannot be deleted!",
          tipDetermineHaveFinishOut: "The current materials are out of the warehouse!",
          startDateMustLessThanEndDate: "The start time cannot be longer than the end time.",
          validWarning: "Is this data enabled?",
          invalidWarning: "Do you want to disable this data?",
          generateWarning: "Whether to generate data",
          hadGenerateCannotDelete: "A quality inspection record has been generated and cannot be deleted!",
          selectDateAndTeamCode: "Select the inspection date and the inspection group name!",
          noCheckedRecordObj: "Select inspection objects!",
          noDataYet: "No data yet",
          modifiedWarning: "Data not modified",
          qualityInspection: "Whether to submit quality inspection records?",
          submittedWarning: "The quality inspection object cannot be repeatedly submitted",
          leastOneWarning: "Please select at least one piece of data",
          oneSubmitted: "Please select at least one submitted data",
          outInspection: "Do you want to retrieve the quality inspection records?",
          unsubmittedWarning: "Please select at least one unsubmitted data",
          classificationWarning: "Please select a sampling point classification",
          dontRepeatAbolish: "This document has been voided, please do not repeat the operation!",
          warehouseNoExist: "Warehouse does not exist or is not enabled",
          stockInItemUnMatch: "The received material does not match the default material in the warehouse, unable to be received!",
          parameterMissing: 'parameterMissing',
          codeNotExist: 'codeNotExist!',
          dataNotExist: "dataNotExist",
          repetition: 'There are multiple codes with the same code, please contact the administrator to deal with it!',
          conflict: 'Encoding already exist!',
          submitData: "Data cannot be changed after submission?",
          unSubmitData: "Confirm to withdraw the selected records?",
          headRecordWarning: "This type cannot be added",
          dataExist: "The data already exists and cannot be set repeatedly!",
          idNotNull: "ID cannot be empty!",
          enableNotNull: "Missing isEnable parameter!",
          parentIdsWarning: "Please select the parent menu!",
          unerasable: "This data cannot be deleted",
          nameWarning: "Name already exists",
          empowerWarning: "Tenant not authorized to configure",
          ruleTimeIsEmpty: "Not authorized to configure administrator role!",
          lineIsEmpty: "Do not have permission to configure super management roles!",
          saveStatus: "Not saved yet, please save first!",
          dataNotNull: "Data cannot be empty",
          unchangeable: "Data cannot be modified",
          paramFormatError: "parameter format not correct!",
          qmRecordHasExist: "The quality inspection record for the current time has been generated !",
          isSubmitNoRemove: 'Unable to delete submitted data!',
        },
      },
      title: {
        SerialNumber: "sort",
        typeTitle: "Type Classification",
        promptTitle: "Prompt",
        more: "See more",
      },
      components: {
        SNOWSIconBox: {
          title: {
            indexHeadTitle: "Icon Selection",
            ymIconHeadTitle: "ymIcon Icon",
            elementUIHeadTitle: "Element-UI Icon",
            ymCustomHeadTitle: "ymCustom Icon",
          },
          msg: {
            iconName: "Please enter the icon name",
          },
        },
        internationalizedData: {
          field: {
            en: "English:",
            zh: "Chinese:",
          },
          title: {
            internationalizedDataTitle: "Internationalized Data",
          },
          msg: {
            required: "required",
          },
        },
        login: {
          msg: {
            loggingIn: "Logging in, please wait...",
          },
        },
        chooseLangue: {
          field: {
            en: "English",
            zh: "Chinese",
          },
          title: {
            chooseLangueTitle: "Choose Langue",
          },
        },
        // 门户设计的组件
        basicControl: {
          field: {
            functionDesign: "Function Design",
            processDesign: "Process Design",
            rightManagement: "Right Management",
            reportDesign: "Report Design",
            portalDesign: "Portal Design",
            custom: "New customers(piece)",
            business: "New business opportunities(piece)",
            contract: "Contract amount (yuan)",
            collection: "Payment amount (yuan)",
            January: "Jan",
            February: "Feb",
            March: "Mar",
            April: "Apr",
            May: "May",
            June: "Jun",
            salesVolume: "Sales Volume",
            weatherStatistics: "Weather Statistics",
            fictitiousData: "Fictitious Data",
            houLiang: "Hou Liang",
            yiZhou: "Yi Zhou",
            yanZhou: "Yan Zhou",
            jingZhou: "Jing Zhou",
            youZhou: "You Zhou",
            email: "email",
            allianceAdvertising: "Alliance Advertising",
            videoAD: "Video AD",
            directAccess: "Direct Access",
            searchEngines: "Search Engines",
            Monday: "Monday",
            Tuesday: "Tuesday",
            Wednesday: "Wednesday",
            Thursday: "Thursday",
            Friday: "Friday",
            Saturday: "Saturday",
            Sunday: "Sunday",
            total: "Total",
            referrer: "Referrer",
            allocatedBudget: "Allocated Budget",
            actualSpending: "Actual Spending",
            sales: "Sales",
            administration: "Administration",
            informationTechology: "Information Techology",
            customerSupport: "Customer Support",
            development: "Development",
            marketing: "Marketing",
            vs: "Budget vs Spending",
          },
          title: {
            myToDoListTitle: "My To-Do",
            commonFunctionTitle: "Common Function",
            noticeTitle: "Notice",
            weatherForecastTitle: "Weather Forecast",
            todoListTitle: "To-Do List",
            dataPanelTitle: "Data Panel",
            histogramTitle: "Histogram",
            pieTitle: "Pie",
            lineChartTitle: "Line Chart",
            circularDiagramTitle: "Circular Diagram",
            areaTitle: "Area",
            radarChartTitle: "Radar Chart",
          },
        },
        index: {
          field: {},
          title: {
            menuSearchTitle: "Menu Search",
          },
          msg: {
            esc: "You can use the shortcut key ESC to close",
            clear: "Do you need to clear the cache?",
          },
        },
      },
      token: {
        user: 'The username or password is incorrectv',
        tenant: 'Tenant ID not found',
        authorization: 'The token authorization has expired',
        information: 'The users role information is not obtained',
        obtained: 'The users tenant information is not obtained',
        tenantAuthorization: 'The tenant authorization has expired, contact your administrator',
        login: 'There are too many login errors, please try again later',
        captcha: 'The captcha is incorrect',
        Authentication: 'If the token is missing, authentication fails',
        token: 'The token is invalid',
        disable: "The current account has been banned. Please contact the administrator",
      },
    },
    ...mdModule_en,
    ...pmModule_en,
    ...qmModule_en,
    ...platModule_en,
    ...cpsModule_en,

    // plat: {
    //   sys: {
    //     top: {
    //       field: {
    //         navigationMode: "navigationMode",
    //         colorBlock: "colorBlock",
    //         themeColors: "themeColors",
    //         otherSettings: "otherSettings",
    //         multiTabOpeningMode: "multiTabOpeningMode",
    //         language: "Display language selection",
    //         search: "Display page search box",
    //         stripe: "Display table zebra print",
    //         broadside: "Side navigation",
    //         top: "Top Navigation",
    //         mix: "Hybrid Navigation",
    //         dark: "Dark mode",
    //         brightening: "Brightening mode",
    //         blue: "Classic Blue",
    //         purple: "Rose Purple",
    //         azure: "azure blue",
    //         ocean: 'Ocean',
    //         green: "Vitality",
    //         yellow: 'Harvest',
    //         orange: "Yang Orange",
    //         red: "Enthusiasm",
    //         appqrcode: "app qr code"
    //       },
    //     },
    //     //租户管理
    //     tenant: {
    //       field: {
    //         min: "Data that cannot be less than 1",
    //         authTitle: "Tenant Authorization Configuration",
    //         dataSourceTitle: "Tenant Data Source Configuration",
    //         packageSetTitle: "Tenant Product Package Configuration",
    //         packageManageTitle: "Tenant Product Package Management",
    //         datasource: "datasource",
    //         packageName: "packageName",
    //         menuView: "menuView",
    //         tenantId: "tenantId",
    //         tenantName: "tenantName",
    //         linkman: "linkman",
    //         contactNumber: "contactNumber",
    //         address: "address",
    //         accountNumber: "accountNumber",
    //         expireTime: "expireTime",
    //         domainUrl: "domainUrl",
    //         tenantIdMessage: "Automatically generate tenant ID after saving",
    //         backgroundUrl: "backgroundUrl"
    //       },
    //       title:{
    //         indexHeadTitle: "Tenant Management",
    //         editHeadTitle: "Tenant ManagementEdit",
    //         addHeadTitle: "Tenant ManagementAdd",
    //       },
    //       btn:{
    //         authSetting: "authSetting",
    //         dataSourceSetting: "dataSourceSetting",
    //         packageSetting: "packageSetting",
    //         packageManage: "packageManage",
    //       }
    //     },
    //     user: {
    //       field: {
    //         subjectCode: "subjectCode",
    //         subjectName: "subjectName",
    //         postLevelCode: "postLevelCode",
    //         postLevelName: "postLevelName",
    //         dutyCode: "dutyCode",
    //         dutyName: "dutyName",
    //         shiftTypeCode: "shiftTypeCode",
    //         shiftTypeName: "shiftTypeName",
    //         areaCode: "areaCode",
    //         areaName: "areaName",
    //         workSectCode: "workSectCode",
    //         workSectName: "workSectName",
    //         classCode: "classCode",
    //         className: "className",
    //         extend: "extend",
    //         roleConfig: "roleConfig",
    //         userImport: "userImport",
    //         download: "download",
    //         platformConfig: "platformConfig",
    //         title: "title",
    //         customize: "customize",
    //         account: "account",
    //         realName: "realName",
    //         realCode: "realCode",
    //         userType: "userType",
    //         tenantName: "tenantName",
    //         roleName: "roleName",
    //         deptName: "deptName",
    //         detailInfo: "detailInfo",
    //         name: "name",
    //         phone: "phone",
    //         email: "email",
    //         sex: "sex",
    //         man: "man",
    //         women: "women",
    //         unknow: "unknow",
    //         enable: "enable",
    //         disable: "disable",
    //         birthday: "birthday",
    //         statusName: "statusName",
    //         dutyInfo: "dutyInfo",
    //         additionalInfo: "additionalInfo",
    //         code: "code",
    //         postId: "postId",
    //         password: "password",
    //         password2: "password2",
    //         inputPassword: "inputPassword",
    //         checkPassword: "Please input a password",
    //         uncheckPassword: "The passwords entered twice are inconsistent",
    //         config: "config",
    //         contactNumber: "Please enter the correct contact number",
    //         userExt: "userExt",
    //         excelFile: "Template Upload",
    //         waiting: "Template uploading, please wait",
    //         format: "Please upload. xls,. xlsx standard format files",
    //         isCovered: "Data coverage",
    //         yes: "yes",
    //         no: "no",
    //         excelTemplate: "Template download",
    //         restAccount: "Confirm to unseal the selected account?",
    //         exportData: "Whether to export user data?",
    //         notice: "notice",
    //         userData: "userData",
    //         userDataTemplate: "userDataTemplate",
    //         gestureUnlock: "gestureUnlock",
    //         passwordUnlock: "passwordUnlock",
    //         isEnabled: "isEnabled",
    //         voluntarily: "Auto brought in by App",
    //         contentFormat: "Parameter rules",
    //         operate: "operate",
    //         identityCard: "identityCard",
    //       },
    //       btn: {
    //         restPassword: "Reset password",
    //         accountConfig: "Account blocked ",
    //         enable: "enable",
    //         disable: "disable",
    //         export: "export",
    //         import: "import ",
    //         roleConfig: "Role Configuration",
    //       },
    //       title: {
    //         baseInfoTitle: "Basic Information",
    //         dutyInfoTitle: "Responsibility Information",
    //         detailInfoTitle: "Details",
    //         treeTitle: "organization list ",
    //         indexHeadTitle: "User Management ",
    //         editHeadTitle: "User edit ",
    //         addHeadTitle: "User added ",
    //         extendTitle: "User Extension ",
    //       },
    //       msg: {
    //         nameLength: "Name length: 2~5 characters ",
    //         nameLengths: "Name length: 2~20 characters ",
    //         restPassword: "Sure to reset the password of this account to 123456",
    //         nickName: "The length of the user nickname cannot be greater than 20",
    //         iPSWarning: " Initial password success ",
    //         dlocWarning: " Account unlocked successfully ",
    //         CfgWarning: " Account unlocked successfully ",
    //         onlyEnglish: "Only English case and numeric input is supported",
    //         content: "Please enter content",
    //         enable: "Confirm to enable the selected account?",
    //         disable: "Confirm to disable the selected account?",
    //         enableSuccess: "Account enabled successfully",
    //         disableSuccess: "Account disabled successfully",
    //         SupportNumbers: "Please enter the correct tenant (consisting of 6 digits)",
    //         enterTenant: "Please enter the tenant ID",
    //         userName: "Please enter a username",
    //         passWord: "Please enter the password",
    //         passwordLength: "The minimum password length is 6 digits",
    //         updatePassword: "Successfully modified password!",
    //       }
    //     },
    //     dept: {
    //       field: {
    //         deptName: 'deptName',
    //         tenantId: 'tenantId',
    //         fullName: 'fullName',
    //         parentId: 'parentId',
    //         deptCategory: 'deptCategory',
    //         isEnable: "isEnable",
    //         yes: "yes",
    //         no: "no",
    //         sort: 'sort',
    //         remark: 'remark',
    //       },
    //       title: {
    //         indexHeadTitle: "Dept Management ",
    //         editHeadTitle: "Dept edit ",
    //         addHeadTitle: "Dept added ",
    //       },
    //       msg: {
    //         isEnable: "A subordinate organization is currently in use. Please close the subordinate organization first",
    //       }
    //     },
    //     post: {
    //       field: {
    //         category: 'category',
    //         postCode: 'postCode',
    //         postName: "postName",
    //         tenantId: 'tenantId',
    //         sort: 'sort',
    //         remark: 'remark',
    //         isEnable: 'isEnable',
    //       },
    //       title: {
    //         indexHeadTitle: "Post Management ",
    //         editHeadTitle: "Post edit ",
    //         addHeadTitle: "Post added ",
    //       },
    //     },
    //     dict: {
    //       field: {
    //         code: 'Dictionary number',
    //         dictValue: 'Dictionary name',
    //         sort: 'Dictionary sorting',
    //         isSealed: 'isEnabled',
    //         remark: 'remark',
    //         parentId: "parentId",
    //         dictKey: 'dictKey',
    //         yes: 'yes',
    //         no: 'no'
    //       },
    //       title: {
    //         indexHeadTitle: "System dictionary",
    //         editHeadTitle: "System dictionary Edit",
    //         addHeadTitle: "System dictionary Add",
    //         bizIndexHeadTitle: "Business Dictionary",
    //         bizEditHeadTitle: "Business Dictionary Edit",
    //         bizAddHeadTitle: "Business Dictionary Add",
    //         indexConfigHeadTitle: "Dictionary Configuration",
    //         editConfigHeadTitle: "Dictionary Configuration Edit",
    //         addConfigHeadTitle: "Dictionary Configuration Add",
    //       },
    //     },
    //     menu: {
    //       field: {
    //         typeSetting: 'Typography',
    //         platform: 'platform',
    //         name: 'name',
    //         path: 'path',
    //         parentId: 'parentId',
    //         source: 'source',
    //         code: 'code',
    //         category: 'category',
    //         catelog: 'catelog',
    //         filePath: 'filePath',
    //         loadText: 'loadText',
    //         color: 'color',
    //         menus: "menus",
    //         button: "button",
    //         alias: 'alias',
    //         isOpen: 'isOpen',
    //         status: 'status',
    //         yes: "yes",
    //         no: 'no',
    //         sort: 'sort',
    //         remark: 'remark',
    //         isOnline: "isOnline"
    //       },
    //       title: {
    //         indexHeadTitle: "Menu Management",
    //         editHeadTitle: "Menu Edit",
    //         addHeadTitle: "Menu Add",
    //       },
    //     },
    //     topMenu: {
    //       field: {
    //         menuId: "menu bind",
    //         name: 'name',
    //         code: 'code',
    //         source: 'source',
    //         sort: 'sort',
    //         yes: 'yes',
    //         no: 'no',
    //       },
    //       btn: {
    //         menuSetting: "Menu Configuration"
    //       },
    //       title: {
    //         indexHeadTitle: "Top menu",
    //         subDialogTitle: 'subDialogTitle'
    //       },
    //     },
    //     param: {
    //       field: {
    //         paramName: 'paramName',
    //         paramKey: 'paramKey',
    //         paramValue: 'paramValue',
    //       },
    //       title: {
    //         indexHeadTitle: "Param Management",
    //         editHeadTitle: "Param Edit",
    //         addHeadTitle: "Param Add",
    //         viewHeadTitle: "Param View",
    //       },
    //     },
    //     knowledge: {
    //       field: {
    //         remark: 'remark',
    //         filePath: 'filePath',
    //         knowledgeCode: 'knowledgeCode',
    //         knowledgeName: 'knowledgeName',
    //         parentCode: 'parentCode',
    //         knowledgeTypeCode: 'knowledgeTypeCode',
    //         knowledgeTypeName: 'knowledgeTypeName',
    //       },
    //       title: {
    //         indexHeadTitle: "Knowledge Management",
    //         editHeadTitle: "Knowledge Edit",
    //         addHeadTitle: "Knowledge Add",
    //         viewHeadTitle: "Knowledge View",
    //         treeTitle: 'Knowledge Type',
    //         treeHeadCateTitle: 'Knowledge Type Management',
    //       },
    //       msg: {
    //         loadText: "Image upload in progress, please wait",
    //       }
    //     },
    //     client: {
    //       field: {
    //         clientId: 'clientId',
    //         clientSecret: 'clientSecret',
    //         authType: 'authorization type',
    //         authScope: 'authorization scope',
    //         accessTokenValidity: 'accessTokenValidity',
    //         refreshTokenValidity: 'refreshTokenValidity',
    //         webServerRedirectUri: 'webServerRedirectUri',
    //         resourceIds: 'resourceIds',
    //         authorities: 'authorities',
    //         autoapprove: 'autoapprove',
    //         additionalInformation: 'additionalInformation'
    //       },
    //       title: {
    //         indexHeadTitle: "Client Management",
    //         editHeadTitle: "Client Edit",
    //         addHeadTitle: "Client Add",
    //         viewHeadTitle: "Client View",
    //       },
    //     },
    //     params: {
    //       field: {
    //         content: 'content',
    //         paramCode: 'paramCode',
    //         paramName: 'paramName',
    //         filePath: 'filePath',
    //         loadText: "Image uploading, please wait",
    //         typeError: "The template is malformed",
    //         paramType: 'paramType',
    //         paramValue: 'paramValue',
    //         releaseTime: 'releaseTime',
    //         textareaRich: 'textareaRich',
    //         remark: 'remark',
    //         status: 'status',
    //         textarea: 'textarea',
    //         enable: 'enable',
    //         disable: 'disable',
    //         disenble: "disenble"
    //       },
    //       title: {
    //         indexHeadTitle: "Systeam Param Management ",
    //         editHeadTitle: "Systeam Param edit ",
    //         addHeadTitle: "Systeam Param added ",
    //       },
    //     },
    //     onlineUser: {
    //       field: {
    //         username: 'username',
    //         realname: 'realName',
    //         account: 'account',
    //         client_ip: 'clientIP',
    //         login_time: 'logintime',
    //       },
    //       btn: {
    //         unlineBtn: "inserting coil"
    //       },
    //       title: {
    //         indexHeadTitle: "Online User",
    //       },
    //       msg: {
    //         unline_tip: 'Confirm to offline this user?',
    //       }
    //     },
    //     international: {
    //       field: {
    //         name: "name",
    //         content: "content",
    //         url: "url",
    //       },
    //       title: {
    //         indexHeadTitle: "Internationalized File List",
    //         editHeadTitle: "Internationalized File Edit",
    //         viewHeadTitle: "Internationalized File View",
    //       },
    //     },
    //     serialNumber: {
    //       field: {
    //         ruleCode: 'ruleCode',
    //         ruleName: 'ruleName',
    //         ruleType: 'Serial number reset rules',
    //         serialLength: 'serialLength',
    //         currentValue: 'currentValue',
    //         initValue: 'initValue',
    //         step: 'step',
    //         prefix: 'prefix',
    //         curTime: 'curTime',
    //         systemTime: 'systemTime',
    //         split: 'split',
    //       },
    //       title: {
    //         indexHeadTitle: "Encoding generation rules",
    //         editHeadTitle: "Encoding generation rules Edit",
    //         addHeadTitle: "Encoding generation rules Add",
    //         viewHeadTitle: "Encoding generation rules View",
    //         baseTitle: "essential information",
    //       },
    //       msg: {
    //         select_warning: 'Please enter the rule number',
    //       },
    //     },
    //     feedback: {
    //       field: {
    //         feedback: 'feedback',
    //         disposeResult: 'disposeResult',
    //         handler: 'handler',
    //         opinion: 'opinion',
    //         screenshot: 'screenshot',
    //         disposeTime: 'disposeTime',
    //         createTime: "createTime",
    //         submitter: 'submitter',
    //       },
    //       title: {
    //         indexHeadTitle: "Feedback",
    //         editHeadTitle: "Feedback Edit",
    //       },
    //       msg: {
    //         suggest: 'Please tell us about any problems or suggestions',
    //       }
    //     },
    //     webDesign: {},
    //     dataLink: {
    //       field: {
    //         name: 'Name',
    //         host: 'Host',
    //         port: 'Port',
    //         driver: 'Driver',
    //         user: 'userName',
    //         password: 'password',
    //         dbName: 'DB Name',
    //         linkName: 'Link Name',
    //         sort: 'sort',
    //         remark: "remark"
    //       },
    //       btn: {
    //         testBtn: "Test Connection"
    //       },
    //       title: {
    //         indexHeadTitle: "Data Connection",
    //         editHeadTitle: "Data Connection Edit",
    //         addHeadTitle: "Data Connection Add",
    //       },
    //     },
    //     dataInterface: {
    //       field: {
    //         handling: "data handling",
    //         Query: "Query",
    //         Add: "Add",
    //         Modify: "Modify",
    //         Delete: "Delete",
    //         staticData: "static Data",
    //         SQLOperations: "SQL Operations ",
    //         APIOperations: "API Operations",
    //         type: "type",
    //         action: 'Action',
    //         sort: 'sort',
    //         status: 'status',
    //         description: 'remark',
    //         prev: 'prev',
    //         next: 'next',
    //         dataSource: 'Data Source',
    //         categoryId: 'Category',
    //         fullName: 'Interface Name',
    //         enCode: 'Interface Code',
    //         dataType: 'Interface Type',
    //         responseType: 'Response Type',
    //         requestMethod: 'Request Method',
    //         changeDB: 'Choose DB',
    //         SQL: 'SQL sentence',
    //         // content: '支持SQL语句&存储过程语句',
    //         systemVariables: 'System Variables',
    //         param: 'Interface Param',
    //         reviceMeth: 'Revice Method:Body/json',
    //         drag: 'drag',
    //         clickDrag: 'Click Drag',
    //         paramName: 'Param Name',
    //         paramType: 'Param Type',
    //         paramDescription: 'Param Description',
    //         char: 'char',
    //         int: 'int',
    //         date: 'date',
    //         decimal: 'decimal',
    //         bigint: 'bigint',
    //         text: 'text',
    //         make: 'operate',
    //         addParam: 'Add Param',
    //         interfaceType: 'Interface Type',
    //         interfacePath: 'Interface Path',
    //         addHeader: 'Add headers',
    //         interfaceParam: 'Interface Param',
    //         defaultValue: 'Default Value',
    //         isRequired: "Is it Required",
    //         user: "user",
    //         organization: "organization",
    //         currentOrganizationAndSuborganization: "currentOrganizationAndSuborganization",
    //       },
    //       btn: {
    //         previewBtn: "Preview",
    //         TestBtn: "Test Interface",
    //       },
    //       title: {
    //         indexHeadTitle: "Data Interface",
    //         editHeadTitle: "Data Interface Edit",
    //         addHeadTitle: "Data Interface Add",
    //         treeTitle: 'Data Interface Type',
    //         addDialogTitle: "Add Parameter",
    //         editDialogTitle: "Edit Parameter",
    //         previewTitle: "Interface Preview - ",
    //         basicInfo: 'Basic Info',
    //         dataConf: 'Data Configuration',
    //       },
    //       msg: {
    //         chooseCategory: 'Please select an interface classification',
    //         disabled: "The interface has been disabled. Please enable the interface first",
    //         repeat: "Duplicate parameter name",
    //         ipAddress: "Please enter domain names, multiple domain names separated by commas",
    //         msg: 'The current system variable only supports internal interface references',
    //         JSmsg: '1、 Scripts that support JavaScript',
    //         JSmsg1: '2、The mini program does not support online JS scripts',
    //         statement: "Please enter SQL query statement or static data",
    //         dataSource: "Please add a data source!",
    //         delParam: "This operation will delete the parameter. Do you want to continue?",
    //         data: "(data) => {\r\n    // Processing data logic\r\n\r\n    // Return the required data\r\n    return data\r\n}",
    //       }
    //     },
    //     visual3: {
    //       field: {
    //         customPath: "Custom Path",
    //         confCustomPath: "Configure static page addresses",
    //         page: "Page Design",
    //         drag: "Dragging to generate a portal",
    //         name: "Portal Name",
    //         portal: "Classification",
    //         enCode: "Portal encoding",
    //         sort: "sort",
    //         status: "status",
    //         remark: "remark",
    //         basicSet: "Basic Settings",
    //         addPortal: "New Portal",
    //         linkType: "linkType",
    //         linkPath: "linkPath",
    //         title: "title",
    //         dataType: "dataType",
    //         StaticData: "StaticData",
    //         RemoteData: "RemoteData",
    //         dataInterface: "dataInterface",
    //         numericalValue: "numericalValue",
    //         Icon: "Icon",
    //         ComponentProperties: "ComponentProperties",
    //         option: "option",
    //         AddOptions: "AddOptions",
    //         DataSettings: "DataSettings",
    //         select: "select",
    //         fullName: "Interface Name",
    //         orderNumber: "orderNumber",
    //         ProcessName: "ProcessName",
    //         ProcessCode: "ProcessCode",
    //         renovate: "renovate",
    //         nameInterface: "name",
    //         code: "code",
    //         type: "type",
    //         custom: "custom",
    //         system: "system",
    //         paramName: "paramName",
    //         paramType: "paramType",
    //         defaultValue: "defaultValue",
    //       },
    //       title: {
    //         indexHeadTitle: "Portal Design",
    //         PreviewTitle: "Portal Preview",
    //         dataSettingsTitle: "Data Settings",
    //         basicControlTitle: "Basic Control",
    //         chartControlTitle: "Chart Control",
    //       },
    //       msg: {
    //         titleWarning: "Please enter a title",
    //         menuWarning: "Please select the menu",
    //         numericalWarning: "Please enter a numerical value",
    //         iconWarning: "Please enter an icon name",
    //         continueWarning: "At least two options must be retained",
    //         InterfaceWarning: "Please select a data interface",
    //         processWarning: "Please select a sub process",
    //         enterWarning: "Please enter keywords for query",
    //         clear: "Are you sure you want to clear all?",
    //       },
    //       btn: {
    //         inquireBtn: "inquire",
    //         resettingBtn: "resetting",
    //         previewBtn: "Preview",
    //         emptyBtn: "empty",
    //       },
    //     },
    //     portal: {
    //       field: {
    //         customPath: "customPath",
    //         confCustomPath: "confCustomPath",
    //         page: "page",
    //         drag: "drag",
    //         name: "name",
    //         portal: "portal",
    //         enCode: "enCode",
    //         sort: "sort",
    //         status: "status",
    //         remark: "remark",
    //         basicSet: "basicSet",
    //         addPortal: "addPortal",
    //         linkType: "linkType",
    //         linkPath: "linkPath",
    //       },
    //       title: {
    //         indexHeadTitle: "Portal Design",
    //         previewTitle: "Portal Preview",
    //       },
    //       msg: {
    //         name: "Portal name cannot be empty",
    //         enCode: "Portal code cannot be empty",
    //         portal: "Portal classification cannot be empty",
    //       },
    //     }
    //   },
    //   authority: {
    //     role: {
    //       field: {
    //         rolePermissions: 'rolePermissions',
    //         webMenumissions: 'webMenumissions',
    //         appMenumissions: 'appMenumissions',
    //         dataPermissions: 'dataPermissions',
    //         protPermissions: 'protPermissions',
    //         roleName: "roleName",
    //         tenantId: "tenantId",
    //         roleAlias: 'roleAlias',
    //         roleType: 'roleType',
    //         parentId: "parentId",
    //         sort: 'sort',
    //         name: "name",
    //         path: "path",
    //         parentIds: "parentIds",
    //       },
    //       title: {
    //         indexHeadTitle: "Role Management",
    //         editHeadTitle: "Role Edit",
    //         addHeadTitle: "Role Add",
    //       },
    //       msg: {
    //         role: "Role and user binding cannot be deleted",
    //       }
    //     },
    //     dataScope: {
    //       field: {
    //         scopeName: 'scopeName',
    //         resourceCode: 'resourceCode',
    //         scopeColumn: 'scopeColumn',
    //         scopeType: 'scopeType',
    //         scopeField: 'scopeField',
    //         scopeClass: 'scopeClass',
    //         scopeValue: 'scopeValue',
    //         remark: 'remark',
    //         name: 'name',
    //         path: 'path',
    //         parentIds: "parentIds",
    //         source: 'source',
    //         code: 'code',
    //         category: 'category',
    //         alias: 'alias',
    //         action: 'action',
    //         sorts: 'sorts',
    //         isOpen: 'isOpen',
    //         remarks: 'remarks',
    //         menu: 'menu',
    //         button: 'button',
    //         toolBar: 'toolBar',
    //         actionBar: 'actionBar',
    //         toolActionBar: 'toolActionBar',
    //         yes: 'yes',
    //         no: 'no',
    //         allVisible: 'All Visible',
    //         personallyVisible: 'Personally Visible',
    //         deptVisible: 'Visible to the organization',
    //         deptAndChildVisible: 'Visible to the organization and its children',
    //         custom: 'Custom',
    //       },
    //       btn: {
    //         scopeConfBtn: 'Permission configuration',
    //       },
    //       title: {
    //         indexHeadTitle: "Data Permissions",
    //         confHeadTitle: "Data Permission Configuration",
    //       },
    //       msg: {
    //         scopeClass: "Please enter the full class name path of the corresponding method for MybatisMapper",
    //       }
    //     },
    //     apiScope: {
    //       field: {
    //         scopeName: 'scopeName',
    //         resourceCode: 'resourceCode',
    //         scopeType: 'scopeType',
    //         scopePath: 'scopePath',
    //         remark: 'remark',
    //         name: 'name',
    //         path: 'path',
    //         parentIds: "parentIds",
    //         source: 'source',
    //         code: 'code',
    //         category: 'category',
    //         alias: 'alias',
    //         action: 'action',
    //         sorts: 'sorts',
    //         isOpen: 'isOpen',
    //         remarks: 'remarks',
    //         menu: 'menu',
    //         button: 'button',
    //         toolBar: 'toolBar',
    //         actionBar: 'actionBar',
    //         toolActionBar: 'toolActionBar',
    //         yes: 'yes',
    //         no: 'no',
    //       },
    //       btn: {
    //         scopeConfBtn: 'Permission configuration',
    //       },
    //       title: {
    //         indexHeadTitle: "Interface Permissions",
    //         confHeadTitle: "Interface Permission Configuration",
    //       },
    //     },
    //   },
    //   // 系统运维
    //   sysOps: {
    //     stat: {
    //       field: {
    //         functionName: "functionName",
    //         functionCode: "functionCode",
    //         operationButton: "operationButton",
    //         operationUser: "operationUser",
    //         createTime: "createTime",
    //         usageNumber: "usageNumber",
    //       },
    //       title: {
    //         functionRecordTitle: "Function Record",
    //         buttonRecordTitle: "Button Record",
    //         functionFrequencyTitle: "Function Frequency",
    //         buttonFrequencyTitle: "Button Frequency",
    //       },
    //     },
    //   },
    //   wfDesign: {
    //     model: {
    //       field: {
    //         category: 'category',
    //         icon: 'icon',
    //         modelKey: 'modelKey',
    //         name: 'name',
    //         categoryId: 'categoryId',
    //         description: 'categoryId',
    //         version: 'version',
    //         fullScreen: 'Full Screen',
    //         selectForm: 'Select Form',
    //         formType: 'Form Type',
    //         builtInForm: 'BuiltIn Form',
    //         externalForm: 'External Form',
    //         nodeIndependentForm: 'Node Independent Form',
    //         formView: 'Form View',
    //         exFormKey: 'exFormKey',
    //         formKey: 'formKey',
    //         label: 'label',
    //         prop: 'prop',
    //         readable: 'readable',
    //         writable: 'writable',
    //       },
    //       btn: {
    //         changeTypeBtn: "Change Type",
    //         designBtn: "Design",
    //         deployBtn: "Deploy",
    //         permissionsBtn: "Permissions",
    //         moreBtn: "more",
    //         iconBtn: "icon",
    //         historysBtn: "historys",
    //         copyBtn: "copy",
    //         returnBtn: "Fallback version",
    //         allRemoveBtn: "All Remove",
    //         previewBtn: "Preview",
    //         mainBtn: "Set main version",
    //         prev: 'prev',
    //         next: 'next',
    //       },
    //       title: {
    //         indexHeadTitle: "Model Management",
    //         arrangeHeadTitle: "Select Process Classification",
    //         uploadIconHeadTitle: "Upload Icon",
    //         viewIconHeadTitle: "Model Preview",
    //         designFormsHeadTitle: "Design Form",
    //         designProcessHeadTitle: "Design Process",
    //         doneHeadTitle: "Done",
    //       },
    //       msg: {
    //         suggestedSize: "Suggested Size：50 * 50",
    //         requireIcon: "Icon must be filled in",
    //         changeType: "Please change the classification first",
    //         setIcon: "Successfully set icon",
    //         copy: "Successfully copied XML",
    //         deploy: "Successfully deployed",
    //         remove: "Delete all versions or roll back to the last version?",
    //         main: "The current main version will be automatically saved to history. Are you sure you want to make this version the main version?",
    //         form: "Don't have the form you want? Click to design",
    //         settings: "Can be used to control the explicit or implicit configuration of external form fields. Ignore this field if you want to control it yourself",
    //         script: "Understand the hazards of scripts before using them. If you are sure to use them, please refer to the documentation to release the configuration. <br>1. The JVM is fully accessible in the script. <br>2. Script execution blocks many system resources. <br>3, the script execution is dead loop/a lot of memory, and so on will cause the program to crash.",
    //         shell: "Please be aware of the hazards of Shell before using it. If you are sure to use it, please refer to the documentation to release this configuration. <br> Because you are not sure whether a dangerous command can be executed, for example, rm -rf *, understand the command well before using it.",
    //         node: "The node does not configure the form correctly",
    //         save: "Do you want to save this model as a new version? This means you can go back to a previous version",
    //       }
    //     },
    //     form: {
    //       field: {
    //         formKey: 'formKey',
    //         name: 'formName',
    //         remark: 'remark',
    //         status: 'status',
    //         categoryId: 'category',
    //         version: 'version',
    //         available: 'available',
    //         forbidden: 'forbidden',
    //       },
    //       btn: {
    //         changeTypeBtn: "Change Type",
    //         designBtn: "Design",
    //         historysBtn: "historys",
    //         copyBtn: "copy",
    //         returnBtn: "Fallback version",
    //         allRemoveBtn: "All Remove",
    //         previewBtn: "Preview",
    //         mainBtn: "Set main version",
    //       },
    //       title: {
    //         indexHeadTitle: "Form Management",
    //         editHeadTitle: "Form Management Edit",
    //         addHeadTitle: "Form Management Add",
    //         arrangeHeadTitle: "Select Classification",
    //         viewIconHeadTitle: "Form Preview",
    //         copyHeadTitle: "Copy Form",
    //       },
    //       msg: {
    //         remove: "Delete all versions or roll back to the last version?",
    //         main: "The current main version will be automatically saved to history. Are you sure you want to make this version the main version?",
    //         save: "Do you want to save this form as a new version? This means that you can return to the previous version",
    //       }
    //     },
    //     //部署管理
    //     deployment: {
    //       field: {
    //         name: 'formkey',
    //         key: 'formName',
    //         category: 'category',
    //         version: 'version',
    //         status: 'status',
    //         deployTime: 'deployTime',
    //         activate: 'activate',
    //         pending: 'pending',
    //       },
    //       btn: {
    //         changeTypeBtn: "changeType",
    //         activateBtn: 'activate',
    //         pendingBtn: 'pending',
    //       },
    //       title: {
    //         indexHeadTitle: "Deployment Management",
    //         arrangeHeadTitle: "Select Type",
    //       },
    //       msg: {
    //         remove: "This action cascades down the process instance that is currently in progress and cannot be recovered. Are you sure you want to remove it?",
    //         reconfirm: "Double hiddenTrouble! This action cascades down the process instance that is currently in progress and cannot be recovered. Are you sure you want to remove it?",
    //       }
    //     },
    //     //流程按钮
    //     button: {
    //       field: {
    //         buttonKey: "key",
    //         name: "name",
    //         display: "isDisplay",
    //         sort: "sort",
    //         status: "status",
    //         yes: "yes",
    //         no: "no",
    //         available: "available",
    //         forbidden: "forbidden",
    //       },
    //       title: {
    //         indexHeadTitle: "Flow Button",
    //         editHeadTitle: "Flow Button Edit",
    //         addHeadTitle: "Flow Button Add",
    //       },
    //     },
    //     //流程分类
    //     category: {
    //       field: {
    //         name: "categoryName",
    //         parentId: "parentCategory",
    //         sort: "sort",
    //         status: "status",
    //         isEnable: "isEnable",
    //         available: "available",
    //         forbidden: "forbidden",
    //       },
    //       title: {
    //         indexHeadTitle: "Process classification",
    //         editHeadTitle: "Process classification Edit",
    //         addHeadTitle: "Process classification Add",
    //       },
    //     },
    //     //表单默认值
    //     defaultValues: {
    //       field: {
    //         content: "content",
    //         name: "typeName",
    //         fieldType: "fieldType",
    //       },
    //       btn: {
    //         copy: "copy"
    //       },
    //       title: {
    //         indexHeadTitle: "Default Values",
    //         editHeadTitle: "Default Values Edit",
    //         addHeadTitle: "Default Values Add",
    //       },
    //       msg: {
    //         content: "Please enter the content. If the package is ${}, it means executing the code. Please ensure that the code is executable",
    //       }
    //     },
    //     //流程表达式
    //     condition: {
    //       field: {
    //         name: "name",
    //         expression: "expression",
    //         type: "type",
    //         status: "status",
    //         personnelConfiguration: "personnelConfiguration",
    //         flowCondition: "flowCondition",
    //         available: "available",
    //         forbidden: "forbidden",
    //       },
    //       title: {
    //         indexHeadTitle: "Process expression",
    //         editHeadTitle: "Process expression Edit",
    //         addHeadTitle: "Process expression Add",
    //       },
    //     },
    //   },
    //   wfOps: {
    //     //运维调度
    //     ops: {
    //       field: {
    //         nameOfTheProcess: "nameOfTheProcess",
    //         ProcessIdentification: "ProcessIdentification",
    //         SerialNumber: "SerialNumber",
    //         ApplicationTime: "ApplicationTime",
    //         category: "category",
    //         TheCurrentNode: "TheCurrentNode",
    //         Approver: "Approver",
    //         applicant: "applicant",
    //         StartTime: "StartTime",
    //         EndTime: "EndTime",
    //         Status: "Status",
    //         TimeRange: "TimeRange",
    //         activation: "activation",
    //         Suspend: "Suspend",
    //         node: "node",
    //       },
    //       btn: {
    //         flowBtn: "flowChart",
    //         crossBtn: "cross",
    //         rejectBtn: "reject",
    //         transferBtn: "transfer",
    //         entrustBtn: "entrust",
    //         stopBtn: "stop",
    //         copyBtn: "copy",
    //         pressBtn: "press",
    //         bgrBtn: "Reviewer",
    //       },
    //       title: {
    //         indexHeadTitle: "Maintenance Scheduling",
    //         flowChartHeadTitle: "Flow Chart",
    //         selectHeadTitle: "Select Node",
    //       },
    //       msg: {
    //         signOff: "Are you sure you want to reduce the signature of the selected task?",
    //         signOffSuccess: "Successfully reduced signature",
    //         pressSuccess: "Urged successfully",
    //         stopSuccess: "Terminated successfully",
    //         transferSuccess: "Successfully transferred",
    //         delegateSuccess: "Delegated successfully",
    //         copySuccess: "Copy successfully",
    //         addMultiInstanceSuccess: "Successfully signed",
    //         cross: "Are you sure you want to <span style='color: red;'>pass</span> all the selected tasks?",
    //         reject: "Are you sure you want to <span style='color: red;'>reject</span> all the selected tasks?",
    //         press: "Are you sure you want to urge the selected task? This operation is invalid if the task has no reviewer",
    //         stop: "Are you sure you want to terminate the selected task process?",
    //         bgr: "Make sure to change the selected task reviewer to ",
    //         transfer: "Make sure to transfer the selected task to ",
    //         delegate: "Make sure to delegate the selected task to ",
    //         copy: "Be sure to Copy the selected task to ",
    //         addMultiInstance: "Are you sure you want to sign the selected person?",
    //       }
    //     },
    //     //办结流程
    //     done: {
    //       field: {
    //         nameOfTheProcess: "nameOfTheProcess",
    //         ProcessIdentification: "ProcessIdentification",
    //         SerialNumber: "SerialNumber",
    //         category: "category",
    //         applicant: "applicant",
    //         StartTime: "StartTime",
    //         EndTime: "EndTime",
    //         Status: "Status",
    //         finished: "finished",
    //         terminate: "terminate",
    //         withdraw: "withdraw",
    //         deleted: "deleted",
    //         applicationInfo: "Application Information",
    //         flowInfo: "Flow Information",
    //         processTrace: "Process Trace",
    //       },
    //       btn: {
    //         flowBtn: "flow Chart",
    //         detailBtn: "detail",
    //       },
    //       title: {
    //         indexHeadTitle: "Closing Process",
    //         flowChartHeadTitle: "Flow Chart",
    //       },
    //       msg: {
    //         allName: "Please enter the exact process name",
    //       }
    //     },
    //     //流程列表
    //     process: {
    //       field: {
    //         nameOfTheProcess: "nameOfTheProcess",
    //         ProcessIdentification: "ProcessIdentification",
    //         SerialNumber: "SerialNumber",
    //         category: "category",
    //         applicant: "applicant",
    //         StartTime: "StartTime",
    //         EndTime: "EndTime",
    //         ProcessStatus: "ProcessStatus",
    //         detailedCirculation: "detailedCirculation",
    //         unfinished: "unfinished",
    //         finished: "finished",
    //         terminate: "terminate",
    //         withdraw: "withdraw",
    //         recall: "recall",
    //         reject: "reject",
    //         deleted: "deleted",
    //       },
    //       btn: {
    //         flowBtn: "flowChart",
    //         detailBtn: "detail",
    //         logicalDeletionBtn: "Logical Deletion",
    //         physicalDeletionBtn: "Physical Deletion",
    //       },
    //       title: {
    //         indexHeadTitle: "Process List",
    //         flowChartHeadTitle: "Flow Chart",
    //       },
    //       msg: {
    //         allName: "Please enter the exact process name",
    //         remove: "<p><span style='color: red;'>Logical Deletion</span>：The ongoing process ends directly, but historical data is retained and marked as deleted. After deletion, it cannot be restored as in progress.</p><p><span style='color: red;'>Physical Deletion</span>：Completely delete all data and cannot be restored.</p>",
    //         reason: "Please enter the reason for deletion",
    //         reasonSuccess: "Successfully deleted",
    //         reconfirm: "Second confirmation! This operation will permanently delete process data and cannot be restored!",
    //       }
    //     },
    //     //流程代理
    //     proxy: {
    //       field: {
    //         client: "client",
    //         agent: "agent",
    //         process: "process",
    //         specifyTheProcess: "specifyTheProcess",
    //         type: "type",
    //         startTime: 'startTime',
    //         endTime: 'endTime',
    //         effectiveTime: "effectiveTime",
    //         status: "status",
    //         total: "total",
    //         appoint: "appoint",
    //         permanent: "permanent",
    //         timing: "timing",
    //         available: "available",
    //         forbidden: "forbidden",
    //       },
    //       title: {
    //         indexHeadTitle: "Process Proxy",
    //         editHeadTitle: "Process Proxy Edit",
    //         addHeadTitle: "Process Proxy Add",
    //       },
    //     },
    //   },
    //   workflow: {
    //     components: {
    //       userOption: {
    //         field: {
    //           type: "type",
    //           value: "value",
    //           user: "user",
    //           role: "role",
    //           dept: "dept",
    //           post: "post",
    //           platform: "platform",
    //           h5: "Mobile terminal H5",
    //           mpWX: "WeChat mini program",
    //           android: "android",
    //         },
    //         title: {
    //           indexHeadTitle: "Permission configuration",
    //         },
    //       },
    //       userSelect: {
    //         field: {
    //           avatar: "avatar",
    //           name: "name",
    //           deptName: "dept",
    //           postName: "post",
    //         },
    //         title: {
    //           indexHeadTitle: "Personnel selection",
    //         },
    //       },
    //     },
    //   },
    //   reportcenter: {
    //     //报表管理
    //     report: {
    //       field: {
    //         reportName: "reportName",
    //         reportType: "reportType",
    //         sort: "sort",
    //         remark: "remark",
    //         reportCode: "reportCode",
    //         parentCode: "parentCode",
    //         reportTypeCode: "reportTypeCode",
    //         reportTypeName: "reportTypeName",
    //         reportTypeId: "reportType",
    //         reportReviewPath: "reportReviewPath"
    //       },
    //       btn: {
    //         settingsBtn: "Permission Settings",
    //         filePath: "filePath",
    //         otherFilePath: "otherFilePath",
    //         reportView: "reportView",
    //       },
    //       title: {
    //         indexHeadTitle: "Report Management",
    //         editHeadTitle: "Report Management Edit",
    //         addHeadTitle: "Report Management Add",
    //         viewHeadTitle: "Report Management View",
    //         headCateTitle: "Report Category Management",
    //         roleNameTitle: "Role",
    //       },
    //       msg: {
    //         only: "(Only the Runqian report template file can be uploaded)",
    //         loadText: "Report uploading, please wait",
    //         typeError: "Incorrect template format",
    //       },
    //     },
    //     //我的报表
    //     myReport: {
    //       field: {
    //         reportName: "reportName",
    //         reportTypeName: "reportTypeName",
    //       },
    //       title: {
    //         treeTitle: "Report Type",
    //         headTitle: "My Report",
    //       },
    //     },
    //     //中屏管理
    //     mediumScreen: {
    //       field: {
    //         name: "name",
    //         urlPath: "urlPath",
    //         code: "code",
    //         remark: "remark"
    //       },
    //       btn: {
    //         settingsBtn: "Permission Settings",
    //       },
    //       title: {
    //         indexHeadTitle: "Middle Screen Management",
    //         editHeadTitle: "Middle Screen Management Edit",
    //         addHeadTitle: "Middle Screen Management Add",
    //         viewHeadTitle: "Middle Screen Management View",
    //         roleNameTitle: "Role",
    //       },
    //     },
    //   },
    //   monitor: {
    //     log: {
    //       usual: {
    //         field: {
    //           serviceId: "serviceId",
    //           serverHost: "serverHost",
    //           serverIp: "serverIp",
    //           env: "Software environment",
    //           logLevel: "Log Level",
    //           logId: "LogID",
    //           requestUri: "Request interface",
    //           createTime: "Log time",
    //           userAgent: "User Agent",
    //           logData: "Log Data",
    //           params: "Request Data",
    //         },
    //         title: {
    //           indexHeadTitle: "General Log",
    //         },
    //       },
    //       api: {
    //         field: {
    //           serviceId: "serviceId",
    //           serverHost: "serverHost",
    //           serverIp: "serverIp",
    //           env: "Software environment",
    //           title: "Log name",
    //           method: "Request Method",
    //           requestUri: "requestUri",
    //           createTime: "createTime",
    //           userAgent: "userAgent",
    //           logData: "logData",
    //           params: "params",
    //         },
    //         title: {
    //           indexHeadTitle: "Interface Log",
    //         },
    //       },
    //       error: {
    //         field: {
    //           errorstack: "errorstack",
    //           content: "content",
    //           time: "time",
    //           type: "type",
    //           address: "address",
    //           uploadServer: "uploadServer",
    //           clearLocalLog: "clearLocalLog",
    //           localErrorLog: "localErrorLog",
    //           serviceId: "serviceId",
    //           serverHost: "serverHost",
    //           serverIp: "serverIp",
    //           env: "software environment",
    //           method: "Request Method",
    //           requestUri: "requestUri",
    //           createTime: "createTime",
    //           userAgent: "userAgent",
    //           params: "params",
    //           stackTrace: "stackTrace",
    //         },
    //         title: {
    //           indexHeadTitle: "Interface Log",
    //         },
    //         msg: {
    //           upload: "Confirm uploading local logs to the server?",
    //           clear: "Confirm clearing local log records?",
    //         },
    //       },
    //     }
    //   },
    //   intf: {
    //     interface: {
    //       field: {
    //         name: "name",
    //         code: "code",
    //         devName: "devName",
    //         devUrl: "devUrl",
    //         consumerName: "consumerName",
    //         isRetry: "isRetry",
    //         status: "status",
    //         maxRetryCount: "maxRetryCount",
    //         timeout: "timeout",
    //         inputMethodName: "inputMethodName",
    //         outputName: "outputName",
    //         outputMethodName: "outputMethodName",
    //         outputClassName: "outputClassName",
    //         outputFormat: "outputFormat",
    //         paramFormat: "paramFormat",
    //         serviceOutput: "serviceOutput",
    //         inputParamFormat: "inputParamFormat",
    //         ipAddress: "ipAddress",
    //         dialogTitle: "dialogTitle",
    //         inputStatus: "inputStatus",
    //         outputStatus: "outputStatus",
    //         responseTimeExt: "responseTimeExt",
    //         inputParams: "inputParams",
    //         params: "params",
    //         outputParams: "outputParams",
    //         failedReason: "failedReason",
    //         serviceType: "serviceType",
    //         title: "title",
    //         type: "type",
    //         ip: "ip",
    //         port: "port"
    //       },
    //       title: {
    //         indexHeadTitle: "Interface Management ",
    //         editHeadTitle: "Interface Management Edit ",
    //         addHeadTitle: "Interface Management added",
    //         treeTitle: "System information ",
    //         headCateTitle: "Service Consumers",
    //         logListHeadTitle: "Interface log list",
    //       },
    //     },
    //     serviceType: {
    //       field: {
    //         name: "name",
    //         code: "code",
    //         type: "type",
    //         ip: "ip",
    //         port: "port"
    //       },
    //       title: {
    //         indexHeadTitle: "System Information ",
    //         editHeadTitle: "Edit System information ",
    //         addHeadTitle: "System Information added ",
    //         headCateTitle: "systematic classification ",
    //         interfaceCate: "Interface Category",
    //         parentCate: 'Parent Category'
    //       },
    //     },
    //   },
    //   app: {
    //     appMenu: {
    //       field: {
    //         filePath: 'filePath',
    //         loadText: 'loadText',
    //         color: 'color',
    //         platform: 'platform',
    //         typeSetting: 'Typography',
    //         list: 'Menu management',
    //         name: 'name',
    //         path: 'path',
    //         parentId: 'parentId',
    //         source: 'source',
    //         code: 'code',
    //         category: 'category',
    //         catelog: 'catelog',
    //         menus: "menus",
    //         button: "button",
    //         alias: 'alias',
    //         isOpen: 'isOpen',
    //         status: 'status',
    //         yes: "yes",
    //         no: 'no',
    //         sort: 'sort',
    //         remark: 'remark',
    //       },
    //       title: {
    //         indexHeadTitle: "APP Menu Management",
    //         editHeadTitle: "APP Menu Edit",
    //         addHeadTitle: "APP Menu Add",
    //       },
    //     },
    //     appVersion: {
    //       field: {
    //         versionCode: "versionCode",
    //         versionName: "versionName",
    //         enableFlag: "enableFlag",
    //         versionInfo: "versionInfo",
    //         updateType: "updateType",
    //         downloadUrl: "downloadUrl",
    //         appUrl: "repositoryPath",
    //         remark: "remark",
    //       },
    //       title: {
    //         indexHeadTitle: "App version",
    //         editHeadTitle: "App versionEdit",
    //         addHeadTitle: "App versionAdd",
    //       },
    //     },
    //     bottomMenu: {
    //       field: {
    //         menuName: "menuName",
    //         menuType: "menuType",
    //         filePath: "filePath",
    //         loadText: "loadText",
    //         typeError: "typeError",
    //         sort: "sort",
    //         remark: "remark",
    //         roleIds: "roleIds",
    //         alias: "alias",
    //         brightPath: "brightPath",
    //       },
    //       title: {
    //         indexHeadTitle: "app Bottom Menu Management ",
    //         editHeadTitle: "app bottom menu management edit ",
    //         addHeadTitle: "app bottom menu management added ",
    //
    //       },
    //     },
    //     aboutUs: {
    //       field: {
    //         verCode: "verCode",
    //         urlPath: "urlPath",
    //         introduce: "introduce",
    //         textarea: "textarea",
    //         loadText: "loadText",
    //         image: "image",
    //       },
    //       title: {
    //         indexHeadTitle: "About Us",
    //         editHeadTitle: " About our edit ",
    //         addHeadTitle: " About our new ",
    //       },
    //
    //     },
    //     attendance: {
    //       field: {
    //         address: "address",
    //         code: "name",
    //         longitude: "longitude",
    //         dimension: "dimension",
    //         clockRange: "clockRange",
    //         workShiftTime: "workShiftTime",
    //         workUnshiftTime: "workUnshiftTime",
    //         remark: "remark"
    //       },
    //       title: {
    //         indexHeadTitle: "Set the clock ",
    //         editHeadTitle: " Set and edit ",
    //         addHeadTitle: " punch in set new ",
    //       },
    //       msg: {
    //         address: "Please enter detailed address",
    //       },
    //     },
    //     clock: {
    //       field: {
    //         address: "address",
    //         username: "username",
    //         clockTime: "clockInTime",
    //         clockOutTime: "clockOutTime"
    //       },
    //       title: {
    //         indexHeadTitle: "clocking record ",
    //         editHeadTitle: "punch record edit",
    //         addHeadTitle: "New punch record",
    //       },
    //     },
    //     adInfo: {
    //       field: {
    //         adName: "adName",
    //         adType: "adType",
    //         filePath: "filePath",
    //         loadText: "loadText",
    //         typeError: "typeError",
    //         sort: "sort",
    //         remark: "content",
    //         urlPath: "urlPath",
    //       },
    //       title: {
    //         indexHeadTitle: "Rotation Management ",
    //         editHeadTitle: " Managing editor for rotation ",
    //         addHeadTitle: " Rotation management added ",
    //       },
    //       msg: {
    //         avater: "Only jpg/png user avatars can be uploaded, and the size should not exceed 10M",
    //       },
    //     },
    //     broadcast: {
    //       field: {
    //         vcn: "sound ray",
    //         speed: "Speech speed",
    //         volume: "volume",
    //         url: "Speaker URL",
    //         address: "Speaker position",
    //         isOpen: "Enable or not",
    //       },
    //       title: {
    //         indexHeadTitle: "broadcast management",
    //         editHeadTitle: "Broadcast Management Editor",
    //         addHeadTitle: "New Broadcast Management",
    //       },
    //       menu: {
    //         selectBroadcast: 'Broadcasting equipment',
    //       }
    //     },
    //   },
    //   message: {
    //     //通道管理
    //     channel: {
    //       field: {
    //         channelCode: "channelNumber",
    //         channelName: "channelName",
    //         channelType: "channelType",
    //         ChannelUserIDSource: "ChannelUserIDSource",
    //         channelDescribe: "channelDescribe",
    //         channelParameter: "channelParameter",
    //         status: "isEnable",
    //         sort: "sort",
    //         remark: "remark",
    //         createTime: "createTime",
    //       },
    //       title: {
    //         indexHeadTitle: "Channel Management",
    //         editHeadTitle: "Channel Management Edit",
    //         addHeadTitle: "Channel Management Add",
    //         viewHeadTitle: "Channel Management View",
    //       },
    //     },
    //     //消息模板管理
    //     template: {
    //       field: {
    //         templateCode: "templateCode",
    //         templateName: "templateName",
    //         templateType: "templateType",
    //         templateMode: "templateMode",
    //         statusStr: "statusStr",
    //         status: "isEnable",
    //         sort: "sort",
    //         remark: "remark",
    //         taskStatuStr: "taskStatuStr",
    //         createTime: "createTime",
    //         listen: "listen",
    //         cronExpressions: "cronExpressions",
    //         TemplateDescription: "TemplateDescription",
    //         Enabled: "Enabled",
    //         notEnabled: "notEnabled",
    //         msgTypeEncoding: "messageTypeEncoding",
    //         msgTypeName: "messageTypeName",
    //         parentName: "parentName",
    //         appUrl: "appUrl",
    //         pcUrl: "pcUrl",
    //       },
    //       btn: {
    //         start: "start",
    //         stop: "stop",
    //       },
    //       title: {
    //         indexHeadTitle: "Message Template Management",
    //         editHeadTitle: "Message Template Management Edit",
    //         addHeadTitle: "Message Template Management Add",
    //         viewHeadTitle: "Message Template Management View",
    //         headItemTitle: "Message Type",
    //       },
    //       msg: {
    //         start: "Are you sure you want to start selecting a data template?",
    //         stop: "Are you sure you want to stop selecting a data template?",
    //         select: "Please select a message classification code!",
    //       },
    //       //输入参数
    //       parameter: {
    //         field: {
    //           list: "Parameter management",
    //           paramName: "paramName",
    //           paramKey: "paramKey",
    //           paramValue: "paramValue",
    //           paramRequired: "isRequired",
    //           paramRule: "paramRule",
    //         },
    //         title: {
    //           indexHeadTitle: "input parameter",
    //         },
    //         msg: {
    //           listNotEmpty: "The saved parameter list is empty!",
    //           ending: "ending",
    //           templateMode: "The sending mode is timed, and the parameter cannot be set as mandatory!",
    //           repeated: "Key cannot be repeated",
    //         },
    //       },
    //       //相关方
    //       parties: {
    //         field: {
    //           sendFromType: "senderSource",
    //           sendAccount: "sender",
    //           receiveFromType: "recipientSource",
    //           presetpPersonnel: "presetpPersonnel",
    //           inputParameter: "inputParameter",
    //           receiveSetType: "Personnel filtering method",
    //           sort: "sort",
    //           remark: "remark",
    //           role: "role",
    //           organ: "organ",
    //           parentId: "parentRole",
    //           parentDept: "parentDept",
    //           postId: "post",
    //           userCode: "userCode",
    //           userName: "userName",
    //           deptName: "deptName",
    //           contentFormat: "parameterRules",
    //         },
    //         title: {
    //           indexHeadTitle: "Related parties",
    //           appointHeadTitle: "Designated personnel",
    //         },
    //         msg: {
    //           save: "When the message template sending mode is timed, the sender and receiver sources must select preset personnel!",
    //         },
    //       },
    //       //消息规则
    //       rule: {
    //         field: {
    //           startTime: "startTime",
    //           endTime: "endTime",
    //           maxFrequency: "maxFrequency(day)",
    //           keywordFiltering: "keywordFiltering",
    //         },
    //         title: {
    //           indexHeadTitle: "Message Rules",
    //         },
    //       },
    //       //发送通道
    //       channel: {
    //         field: {
    //           channelCode: "channelCode",
    //           channelName: "channelName",
    //           channelType: "channelType",
    //           titleFormat: "titleFormat",
    //           contentFormat: "contentFormat",
    //           content: "content",
    //           createTime: "createTime",
    //         },
    //         btn: {
    //           editBtn: "Edit",
    //         },
    //         title: {
    //           indexHeadTitle: "Send Aisle",
    //           channelHeadTitle: "Channel management",
    //           headItemTitle: "Message Type",
    //         },
    //         msg: {
    //           listNotEmpty: "The saved parameter list is empty!",
    //           title: "The title in format editing cannot be empty",
    //           connect: "The content in format editing cannot be empty",
    //           all: "When the template is in timed mode, the title and content cannot have similarities",
    //         },
    //       },
    //     },
    //     //消息清单
    //     sendRecord: {
    //       field: {
    //         msgCode: "messageID",
    //         msgForm: "messageSource",
    //         msgType: "messageType",
    //         sendModel: "sendModel",
    //         sendNum: "sendNum",
    //         sendNumCensus: "sendingStatistics(totalNumber/Success/Failure)",
    //         sendTime: "sendTime",
    //         sendStatus: "sendStatus",
    //         startDate: "startDate",
    //         endDate: "endDate",
    //         templateName: "templateName",
    //         templateCode: "templateCode",
    //         templateCronExpr: "templateCronExpr",
    //         successMsgNum: "successMsgNum",
    //         failMsgNum: "failMsgNum",
    //         messageTemplateId: "templateID",
    //         titleParam: "messageTitleParameters",
    //         contentParam: "messageContentParameters",
    //         sendParam: "senderRelatedParameters",
    //         receiveParam: "receiverRelatedParameters",
    //       },
    //       title: {
    //         indexHeadTitle: "Message List",
    //         addHeadTitle: "Message Add",
    //         viewHeadTitle: "Message Basic Information",
    //       },
    //       //发送记录
    //       sendRecord: {
    //         field: {
    //           detailCode: "recordID",
    //           channelName: "channelName",
    //           sendAccount: "sendAccount",
    //           receiveAccount: "receiveAccount",
    //           msgTile: "Tile",
    //           msgContent: "Content",
    //           success: "success",
    //           fail: "fail",
    //         },
    //         btn: {
    //           resend: "resend",
    //         },
    //         title: {
    //           indexHeadTitle: "Send Record",
    //           viewHeadTitle: "Message Sending Record Details",
    //         },
    //       },
    //     },
    //     //我的消息
    //     list: {
    //       field: {
    //         msgTile: "Message Title",
    //         msgContent: "Message Content",
    //         isRead: "isRead",
    //         createTime: "Reception time",
    //         attachment: "attachment",
    //         picture: "picture",
    //         video: "video",
    //         buttonName: "Functional link"
    //       },
    //       title: {
    //         indexHeadTitle: "My Message",
    //         mailTitle: "mail",
    //         msgTypeTitle: "Message Type",
    //         msgSettingTitle: "Message Setting",
    //         notifySettingTitle: "Notification Setting",
    //         bcstSettingTitle: "Broadcast Setting",
    //       },
    //       btn: {
    //         readBtn: "Mark Read",
    //         messageSettingBtn: "Message Setting",
    //       },
    //       msg: {
    //         read: "Confirm to select message read?",
    //         mail: "Received a message, please hiddenTrouble it promptly",
    //         removeNotRead: "This message is unread, confirm to delete?",
    //         removeContainNotRead: "Selected data contains unread messages, confirm deletion?",
    //       }
    //     },
    //   },
    //   resource: {
    //     //对象存储
    //     oss: {
    //       field: {
    //         classify: "classify",
    //         ossCode: "resourceNumber",
    //         endpoint: "resourceAddress",
    //         bucketName: "spaceName",
    //         accessKey: "accessKey",
    //         secretKey: "secretKey",
    //         appId: "appId",
    //         region: "region",
    //         status: "isEnable",
    //         remark: "remark",
    //         backgroundUrl: "backgroundUrl",
    //         code: "Resource number",
    //       },
    //       btn: {
    //         debugBtn: "debug",
    //       },
    //       title: {
    //         indexHeadTitle: "Object Storage",
    //         editHeadTitle: "Object Storage Edit",
    //         addHeadTitle: "Object Storage Add",
    //         viewHeadTitle: "Object Storage View",
    //         debugHeadTitle: "Object Storage Upload Debugging",
    //       },
    //       msg: {
    //         enable: "Are you sure to enable this configuration?",
    //         img: "Obtain image address:",
    //       }
    //     },
    //     //附件管理
    //     attach: {
    //       field: {
    //         currentLocation: "currentLocation",
    //         filename: "filename",
    //         type: "type",
    //         size: "size(KB)",
    //         dateUpload: "dateUpload",
    //         fileSize: "fileSize(KB)",
    //         creationTime: "creationTime",
    //         attachmentDomain: "attachmentDomain",
    //         attachmentExtension: "attachmentExtension",
    //         attachmentAddress: "attachmentAddress",
    //         attachmentName: "attachmentName",
    //         attachmentSize: "attachmentSize",
    //         formerly: "formerly",
    //         management: "management",
    //         list: "list",
    //         network: "network",
    //         myPapers: "myPapers",
    //         all: "all",
    //         picture: "picture",
    //         document: "document",
    //         video: "video",
    //         music: "music",
    //         other: "other",
    //         upload: "upload",
    //         attachmentUpload: "attachmentUpload",
    //         clickToUpload: "clickToUpload",
    //       },
    //       btn: {
    //         upOneLevelBtn: "upOneLevel",
    //         howToPresentBtn: "howToPresent",
    //         UploadBtn: "Upload"
    //       },
    //       title: {
    //         viewHeadTitle: "Details",
    //         pictureHeadTitle: "Picture preview",
    //         videoHeadTitle: "Video preview",
    //         audioHeadTitle: "Music preview",
    //       },
    //       msg: {
    //         templateUploaded: "Template upload in progress, please wait",
    //       }
    //     },
    //     //短信配置
    //     sms: {
    //       field: {
    //         classify: "classify",
    //         smsCode: "resourceNumber",
    //         templateId: "templateID",
    //         accessKey: "accessKey",
    //         secretKey: "secretKey",
    //         regionId: "regionId",
    //         signName: "SMS Signature",
    //         status: "isEnable",
    //         remark: "remark",
    //         phone: "cell-phone",
    //         param: "push parameter",
    //         TemplateContent: "Template content",
    //       },
    //       btn: {
    //         debugBtn: "debug",
    //         submitBtn: "send",
    //       },
    //       title: {
    //         indexHeadTitle: "SMS Configuration",
    //         debugHeadTitle: "Debugging of SMS sending on mobile phones",
    //       },
    //       msg: {
    //         phone: "Please enter the phone number to send to",
    //         param: "Example: {'code': 2333, 'title': 'notification title'}",
    //         enable: "Are you sure to enable this configuration?",
    //         sent: "Successfully sent",
    //       }
    //     },
    //     //文件夹管理
    //     folder: {
    //       field: {
    //         name: "folderName",
    //         code: "folderCode",
    //         parentId: "parentFolder",
    //       },
    //       title: {
    //         indexHeadTitle: "Folder Management",
    //         addHeadTitle: "Folder Add",
    //       },
    //     },
    //   },
    //   xxljob: {
    //     //任务列表
    //     task: {
    //       field: {
    //         all: "all",
    //         stopIt: "stopIt",
    //         initiate: "initiate",
    //         id: "taskID",
    //         jobGroup: "actuator",
    //         jobCron: "CRON Expression",
    //         jobDesc: "taskDescription",
    //         addTime: "NewTime",
    //         updateTime: "updateTime",
    //         author: "author",
    //         alarmEmail: "alarmEmail",
    //         executorRouteStrategy: "executorRouteStrategy",
    //         executorHandler: "executorHandler",
    //         executorParam: "executorParam",
    //         executorBlockStrategy: "executorBlockStrategy",
    //         executorTimeout: "executorTimeout",
    //         executorFailRetryCount: "executorFailRetryCount",
    //         glueType: "runMode",
    //         glueSource: "GLUESource",
    //         glueRemark: "GLUERemark",
    //         glueUpdatetime: "GLUEUpdatetime",
    //         triggerStatus: "triggerStatus",
    //         triggerLastTime: "triggerLastTime",
    //         triggerNextTime: "triggerNextTime",
    //         childJobId: "childJobId",
    //       },
    //       btn: {
    //         moreBtn: "more",
    //         startBtn: "start",
    //         stopBtn: "stop",
    //         firstBtn: "first",
    //         logBtn: "queryLog",
    //         registerBtn: "register",
    //         nextBtn: "nextExecutionTime",
    //       },
    //       title: {
    //         indexHeadTitle: "Task Management",
    //         editHeadTitle: "Task Edit",
    //         addHeadTitle: "Task Add",
    //         executeOnceHeadTitle: "Execute Once",
    //         nodeHeadTitle: "Register Node",
    //         nextHeadTitle: "Next Execution Time",
    //         actator: 'job Category'
    //       },
    //       msg: {
    //         start: "Confirm to start the selected task?",
    //         stop: "Confirm to stop the selection task?",
    //         childJobMessage: "Please enter subtasks separated by commas for multiple tasks",
    //       }
    //     },
    //     headTitle: "USER",
    //     username: "username",
    //     password: "password",
    //     role: "role",
    //     permission: "permission",
    //     //调度日志
    //     dispatch: {
    //       field: {
    //         jobId: "taskId",
    //         triggerTime: "Schedule-Time",
    //         triggerCode: "Scheduling-Result",
    //         success: "success",
    //         fail: "fail",
    //         handleTime: "Execution-Time",
    //         handleCode: "Execution-Status",
    //       },
    //       btn: {
    //         triggerLogBtn: "dispatch",
    //         handleLogBtn: "execution",
    //       },
    //       title: {
    //         indexHeadTitle: "Dispatch Log",
    //         dispatchRemarkHeadTitle: "Dispatch Remark",
    //         executionLogHeadTitle: "Execution Log",
    //       },
    //     },
    //     //执行器管理
    //     actuator: {
    //       field: {
    //         appName: "AppName",
    //         name: "name",
    //         order: "sort",
    //         addressType: "registrationMethod",
    //         addressList: "machineAddress",
    //         manualEntry: "manualEntry",
    //         automaticLogon: "automaticLogon",
    //       },
    //       title: {
    //         indexHeadTitle: "Actuator Management",
    //         editHeadTitle: "Actuator Edit",
    //         addHeadTitle: "Actuator Add",
    //       },
    //       msg: {
    //         addressMessage: "Please enter a list of actuator addresses separated by commas for multiple addresses",
    //       }
    //     },
    //   },
    // },
    // desk: {
    //   portalSwitch: "portalSwitch",
    //   im: {
    //     field:{
    //       chat: "chat",
    //       msg: "msg",
    //       contacts: "contacts",
    //       search: "Search: Please enter keywords",
    //       noData: "noData",
    //       me: "me",
    //       sendEmo: "sendEmo",
    //       sendImg: "sendImg",
    //       chatRecord: "chatRecord",
    //       enterMsg: "Click here to directly enter a message for consultation",
    //       send: "send",
    //       noChatRecord: "noChatRecord",
    //       remove: "remove",
    //       removeCharRecord: "removeCharRecord"
    //     },
    //     title:{
    //       indexHeadTitle: "Internal chat",
    //     }
    //   },
    //   notice: {
    //     field: {
    //       title: 'Notification title ',
    //       category: 'Notification Type ',
    //       releaseTimeRange: 'Notification time ',
    //       publishTime: 'Publish time ',
    //       releaseTime: 'notice date ',
    //       content: 'Notice content ',
    //       publish_status: 'Publication status ',
    //       sound: "sound",
    //       maleVoice: "maleVoice",
    //       femaleVoice: "femaleVoice",
    //       speed: "speed",
    //       volume: "volume",
    //       responsiveMode: "responsiveMode",
    //       synchronousMode: "synchronousMode",
    //       immediateResponse: "immediateResponse",
    //       repeat: "Number of repetitions played",
    //       time: "Repeated playback interval",
    //     },
    //     btn: {
    //       broadcastBtn: "Broadcast",
    //       settingBtn: "Broadcast Settings",
    //     },
    //     title: {
    //       addHeadTitle: " Notice notice added ",
    //       editHeadTitle: " Notification notification editor ",
    //       viewHeadTitle: " Notice notice view ",
    //       indexHeadTitle: "Notice and Announcement ",
    //       settingHeadTitle: "Broadcast Settings",
    //     },
    //     msg: {
    //       synchronousMode: "Respond after the language is played",
    //       immediateResponse: "Respond without waiting for playback to complete",
    //       repeat: "Stop after reaching the specified number of times for this parameter (unit: times)",
    //       time: "The switching interval time for repeated playback (in seconds), if left blank, it defaults to 2 seconds",
    //       success: "Successfully Set",
    //     }
    //   },
    //   workflow: {
    //     field: {
    //       icon: 'icon',
    //       ProcessDesign: 'ProcessDesign ',
    //       ProcessIdentification: 'Process identification ',
    //       ProcessClassification: "Processclassification",
    //       version: 'Version ',
    //       state: 'state ',
    //       Suspend: "Suspend",
    //       nameOfTheProcess: 'process name ',
    //       SerialNumber: 'serial number ',
    //       TheCurrentNode: 'current node ',
    //       applicant: 'Applicant',
    //       ApplicationTime: 'Application time ',
    //       start: 'New process ',
    //       days: "days",
    //       creator: "creator",
    //       createDept: "createDept",
    //       datetime: "datetime",
    //       todo: 'To-do list',
    //       MyDone: 'I have done it',
    //       send: 'My request ',
    //       claim: 'Transaction to be signed ',
    //       copy: 'Cc matter',
    //       done: 'Settle matters',
    //       serialNumber: " Serial number",
    //       ProcessStatus: "ProcessStatus",
    //       OperationsNode: "OperationsNode",
    //       OperationTime: "OperationTime",
    //       category: "category",
    //       title: "title",
    //       Initiator: "Initiator",
    //       CCTime: "CCTime",
    //       reason: "Reason",
    //       originalName: "originalName",
    //     },
    //     msg: {
    //       beginTime: "The start time must be entered",
    //       unfinished: "underway",
    //       finished: "off the stocks",
    //       terminate: "Terminated",
    //       withdraw: "rescinded",
    //       recall: "Withdrawn",
    //       reject: "Rejected",
    //       deleted: "Deleted"
    //     },
    //     title: {
    //       indexHeadTitle: "New Process ",
    //       indexHead1Title: "My Request ",
    //       indexHeadTodo: 'To-do List ',
    //       indexHeadVisible: 'Flow chart ',
    //       indexHeadFirst: " Application Information ",
    //       indexHeadSecond: " Flow Information ",
    //       indexHeadThird: " Process Tracking ",
    //       indexHeadMyDone: "My done",
    //       indexHeadCopy: "CC Matters",
    //       indexHeadDone: "Completion matters",
    //       indexHeadClaim: "Pending matters",
    //       indexHeadDetails: "Process Details",
    //     },
    //     btn: {
    //       flowBtn: "flowChart",
    //       detailBtn: "Details",
    //       crossBtn: "cross",
    //       rejectBtn: "reject",
    //       transferBtn: "transfer",
    //       entrustBtn: "entrust",
    //       stopBtn: "stop",
    //       sealBtn: "seal",
    //       copyBtn: "copy",
    //       pressBtn: "press",
    //       bgrBtn: "Reviewer",
    //     },
    //
    //   },
    //
    // },
    // //生产管理
    // pm: {
    //   monthProducePlan: {
    //     title: {
    //       monthPurchasePlan: "monthPurchasePlan",
    //       monthPurchaseAddPlan: "monthPurchaseAddPlan",
    //       monthPurchaseEditTitle: "monthPurchaseEditTitle",
    //       monthPurchaseViewTitle: "monthPurchaseViewTitle",
    //       monthSalePlan: "monthSalePlan",
    //       monthSaleAddPlan: "monthSaleAddPlan",
    //       monthSaleEditTitle: "monthSaleEditTitle",
    //       monthSaleViewTitle: "monthSaleViewTitle",
    //       monthProducePlan: "monthProducePlan",
    //       monthProduceAddPlan: "monthProduceAddPlan",
    //       monthProduceEditTitle: "monthProduceEditTitle",
    //       monthProduceViewTitle: "monthProduceViewTitle",
    //       planDetails: "planDetails",
    //     },
    //     field: {
    //       Difference: "Difference",
    //       productName: "productName",
    //       planState: "planState",
    //       planNum: "planNum",
    //       planYear: "planYear",
    //       planMonth: "planMonth",
    //       itemName: "itemName",
    //       month: "month",
    //       planName: "planName",
    //       userName: "userName",
    //       yearTotal: "yearTotal",
    //       yearTotalAct: "yearTotalAct",
    //       month1Plan: "month1Plan",
    //       month2Plan: "month2Plan",
    //       month3Plan: "month3Plan",
    //       month4Plan: "month4Plan",
    //       month5Plan: "month5Plan",
    //       month6Plan: "month6Plan",
    //       month7Plan: "month7Plan",
    //       month8Plan: "month8Plan",
    //       month9Plan: "month9Plan",
    //       month10Plan: "month10Plan",
    //       month11Plan: "month11Plan",
    //       month12Plan: "month12Plan",
    //       month1Act: "month1Act",
    //       month2Act: "month2Act",
    //       month3Act: "month3Act",
    //       month4Act: "month4Act",
    //       month5Act: "month5Act",
    //       month6Act: "month6Act",
    //       month7Act: "month7Act",
    //       month8Act: "month8Act",
    //       month9Act: "month9Act",
    //       month10Act: "month10Act",
    //       month11Act: "month11Act",
    //       month12Act: "month12Act",
    //     }
    //   },
    //   yearProducePlan: {
    //     title: {
    //       yearPurchasePlan: "yearPurchasePlan",
    //       yearPurchaseAddPlan: "yearPurchaseAddPlan",
    //       yearPurchaseEditTitle: "yearPurchaseEditTitle",
    //       yearPurchaseViewTitle: "yearPurchaseViewTitle",
    //       yearSalePlan: "yearSalePlan",
    //       yearSaleAddPlan: "yearSaleAddPlan",
    //       yearSaleEditTitle: "yearSaleEditTitle",
    //       yearSaleViewTitle: "yearSaleViewTitle",
    //       yearProducePlan: "yearProducePlan",
    //       yearProduceAddPlan: "yearProduceAddPlan",
    //       yearProduceEditTitle: "yearProduceEditTitle",
    //       yearProduceViewTitle: "yearProduceViewTitle",
    //       planDetails: "planDetails",
    //       planDetail: "planDetail"
    //     },
    //     field: {
    //       productName: "productName",
    //       planForm: "yearXXPlanForm",
    //       monthForm: "monthXXPlanForm",
    //       planState: "planState",
    //       planNum: "planNum",
    //       planYear: "planYear",
    //       itemName: "itemName",
    //       year: "year",
    //       planName: "planName",
    //       userName: "userName",
    //       yearTotal: "yearTotal",
    //       month1: "month1",
    //       month2: "month2",
    //       month3: "month3",
    //       month4: "month4",
    //       month5: "month5",
    //       month6: "month6",
    //       month7: "month7",
    //       month8: "month8",
    //       month9: "month9",
    //       month10: "month10",
    //       month11: "month11",
    //       month12: "month12",
    //       dataError: "There is an error in the data format,Please confirm that the data can only hold up to two decimal places",
    //     }
    //   },
    //   //能管配置
    //   PmIndexCfg: {
    //     title: {
    //       title: "Management index Configuration"
    //     },
    //     btn: {
    //       exportMonitoring: "Export Configuration",
    //     },
    //     msg: {
    //       exportPmIndexCfg: "Export tube indicator configuration",
    //       template: "Export tube indicator configuration template"
    //     },
    //     field: {
    //       indexCode: "index code",
    //       indexName: "indexName",
    //       sectName: "sectName",
    //       sectCode: "sectCode",
    //       processName: "processName",
    //       processCode: "processCode",
    //       equipCode: "equipCode",
    //       equipName: "equipName",
    //       itemName: "itemName",
    //       itemCode: "itemCode",
    //       recordType: "recordType",
    //       type: "Time data type",
    //     }
    //   },
    //   // 一卡通出厂
    //   oneCardStockOut: {
    //     field: {
    //       outbound: "outbound",
    //       baseInfo: "baseInfo",
    //       soNo: "soNo",
    //       optType: "optType",
    //       customerName: "customerName",
    //       customerCode: "customerCode",
    //       carrierName: "carrierName ",
    //       carrierCode: "carrierCode",
    //       carNo: "carNo",
    //       extItemName: "extItemName",
    //       extItemCode: "extItemCode",
    //       unitCode: "extItemCode",
    //       unitName: "unitName",
    //       unitPrice: "unitPrice",
    //       transType: "transType",
    //       tareWeight: "tareWeight(dun)",
    //       grossWeight: "grossWeight(dun)",
    //       netWeight: "netWeight(dun)",
    //       inTime: "inTime",
    //       outTime: "outTime",
    //       batchCode: "batchCode",
    //       optFlag: "optFlag",
    //       optMsg: "optMsg",
    //       itemCodeAndName: "itemCodeAndName",
    //       status: "status",
    //     },
    //     title: {
    //       labelTitle: "One card factory",
    //       headTitle: "One card factory",
    //       textTitle: "One Card Information",
    //       indexHeadTitle: "One card factory",
    //       addHeadTitle: "One card factoryAdd",
    //       editHeadTitle: "One card factoryEdit",
    //       viewHeadTitle: "One card factoryView",
    //     },
    //   },
    //   // 一卡通入场
    //   oneCardStockIn: {
    //     field: {
    //       warehouse: "warehousing",
    //       poNo: "poNo",
    //       optType: "optType",
    //       supplierName: "supplierName",
    //       supplierCode: "supplierCode",
    //       carrierName: "carrierName ",
    //       carrierCode: "carrierCode",
    //       miningName: "miningName",
    //       extItemName: "extItemName",
    //       extItemCode: "extItemCode",
    //       unitCode: "unitCode",
    //       unitName: "unitName",
    //       unitPrice: "unitPrice",
    //       transType: "transType",
    //       tareWeight: "tareWeight(dun)",
    //       grossWeight: "grossWeight(dun)",
    //       netWeight: "netWeight(dun)",
    //       carNo: "carNo",
    //       inTime: "inTime",
    //       outTime: "outTime",
    //       batchCode: "batchCode",
    //       optFlag: "optFlag",
    //       optMsg: "optMsg",
    //       itemCodeAndName: "itemCodeAndName",
    //       status: "status",
    //     },
    //     title: {
    //       labelTitle: "One card entry into the factory",
    //       headTitle: "One card entry into the factory",
    //       textTitle: "One Card Information",
    //       indexHeadTitle: "One card entry into the factory",
    //       addHeadTitle: "One card entry into the factoryAdd",
    //       editHeadTitle: "One card entry into the factoryEdit",
    //       viewHeadTitle: "One card entry into the factoryView",
    //     },
    //   },
    //   //生产调度
    //   dispatch: {
    //     field: {
    //       icon: "icon",
    //       name: "processName",
    //       key: "processIdentification",
    //       category: "processCategory",
    //       version: "version",
    //       status: "status",
    //       activation: "activation",
    //       pending: "pending",
    //     },
    //     btn: {
    //       initiateBtn: "initiate",
    //     },
    //     title: {
    //       indexHeadTitle: "Dispatch List",
    //     },
    //   },
    //   //计划管理
    //   plan: {
    //     field: {
    //       year: 'year',
    //       month: "month",
    //       productCode: 'productCode',
    //       productName: 'productName',
    //       unitName: 'unitName',
    //       totalQty: 'totalQty',
    //       sum: 'sum',
    //       janQty: 'janQty',
    //       febQty: 'febQty',
    //       marQty: 'febQty',
    //       aprQty: 'aprQty',
    //       mayQty: 'mayQty',
    //       junQty: 'junQty',
    //       julQty: 'julQty',
    //       augQty: 'augQty',
    //       sepQty: 'sepQty',
    //       octQty: 'octQty',
    //       novQty: 'novQty',
    //       decQty: 'decQty',
    //       status: "status",
    //       documentNumber: "documentNumber",
    //       months: "month",
    //       documenter: "documenter",
    //       documentationDate: "documentationDate",
    //       remark: "remark",
    //       index: "index",
    //       yearMonth: "year month",
    //       deptName: "Reporting department",
    //       billDate: "Filling time",
    //       userName: "applicant"
    //     },
    //     title: {
    //       demandTitle: "Demand planning",
    //       saleTitle: "Sale planning",
    //       produceTitle: "Produce planning",
    //       operateTitle: "Operate planning",
    //       operateDetailTitle: "Operate planning Detail",
    //       itemTitle: "Production items",
    //       basicInformation: "Basic information",
    //       productionoperation: "Production operation plan"
    //     },
    //     msg: {
    //       itemTip: "Please Select Product",
    //       totalQtyTip: "Please enter the planned quantity in the correct format",
    //       productRepeat: "Each product can only save one piece of data per year",
    //     }
    //   },
    //   //物料入库
    //   stockIn: {
    //     field: {
    //       stockInCode: 'stockInCode',
    //       itemCodeAndName: 'itemCodeAndName',
    //       warehouseCodeAndName: 'warehouseCodeAndName',
    //       startDate: 'startDate',
    //       endDate: 'endDate',
    //       stockTime: 'stockTime',
    //       itemCode: 'itemCode',
    //       itemName: 'itemName',
    //       unitName: 'unitName',
    //       stockInQty: 'stockInQty',
    //       warehouseName: 'warehouseName',
    //       carNo: 'carNo',
    //       stockTransationType: 'stockTransationType',
    //       logisticsCode: 'logisticsCode',
    //       supplierCode: 'supplierCode',
    //       supplierName: "supplierName",
    //       extItemCode: 'extItemCode',
    //       extItemName: 'extItemName',
    //       status: 'status',
    //       remark: 'remark',
    //       dcsCode: "DCS Code",
    //       stockUser: "Inbound User",
    //     },
    //     btn: {
    //       confirmStockInBtn: "Stock In",
    //     },
    //     title: {
    //       logisticsTitle: "One Card Storage",
    //       logisticsAddTitle: "One Card Storage Add",
    //       logisticsEditTitle: "One Card Storage Edit",
    //       logisticsViewTitle: "One Card Storage View",
    //       dcsTitle: "DCS Warehousing",
    //       dcsAddTitle: "DCS Warehousing Add",
    //       dcsEditTitle: "DCS Warehousing Edit",
    //       dcsViewTitle: "DCS Warehousing View",
    //       manualTitle: "Manual Warehousing",
    //       manualAddTitle: "Manual Warehousing Add",
    //       manualEditTitle: "Manual Warehousing Edit",
    //       manualViewTitle: "Manual Warehousing View",
    //       baseInfoTitle: "Base Info",
    //       oneCardInfoTitle: "One Card Info",
    //       productionMaterialsTitle: "Production Materials",
    //       supplierTitle: "Supplier",
    //       ERPMaterialsTitle: "ERP Materials",
    //       wareHouseInfoTitle: "WareHouse Info",
    //     },
    //     msg: {
    //       tipDetermineHaveFinishInStatus: 'There is data that has been deposited and cannot be deleted!',
    //       tipDetermineHaveFinishInStatusRow: 'The current data is in the database and cannot be deleted!',
    //       tipDetermineHaveFinishIn: 'The current item is put into storage!',
    //       tip_determine_have_finish_out: 'The current item is put into storage!',
    //       startDateMustLessThanEndDate: "The start time cannot be greater than the end time",
    //       startDateMustLessThan: "Start time or end time If all must be selected",
    //       stockInFinished: "The warehouse receipt has already been stored!",
    //       stockInNotExist: "No warehouse receipt information found!",
    //     }
    //   },
    //   //物料出库
    //   stockOut: {
    //     field: {
    //       stockOutCode: 'stockOutCode',
    //       stockTransationType: 'stockTransationType',
    //       itemCodeAndName: 'itemCodeAndName',
    //       warehouseCodeAndName: 'warehouseCodeAndName',
    //       startDate: 'startDate',
    //       endDate: 'endDate',
    //       stockTime: 'stockTime',
    //       itemCode: 'itemCode',
    //       itemName: 'itemName',
    //       unitName: 'unitName',
    //       stockOutQty: 'stockOutQty',
    //       warehouseName: 'warehouseName',
    //       carNo: 'carNo',
    //       logisticsCode: 'logisticsCode',
    //       customerCode: 'customerCode',
    //       customerName: "customerName",
    //       extItemCode: 'extItemCode',
    //       extItemName: 'extItemName',
    //       remark: 'remark',
    //       status: 'status',
    //       dcsCode: "DCS Code",
    //       stockUser: "Outbound User",
    //     },
    //     btn: {
    //       confirmStockOutBtn: "Outbound",
    //     },
    //     title: {
    //       logisticsTitle: "One card outbound",
    //       logisticsAddTitle: "One Card outbound Add",
    //       logisticsEditTitle: "One Card outbound Edit",
    //       logisticsViewTitle: "One Card outbound View",
    //       dcsTitle: "DCS Outbound",
    //       dcsAddTitle: "DCS Outbound Add",
    //       dcsEditTitle: "DCS Outbound Edit",
    //       dcsViewTitle: "DCS Outbound View",
    //       manualTitle: "Manual Outbound",
    //       manualAddTitle: "Manual Outbound Add",
    //       manualEditTitle: "Manual Outbound Edit",
    //       manualViewTitle: "Manual Outbound View",
    //       baseInfoTitle: "Base Info",
    //       oneCardInfoTitle: "One Card Info",
    //       productionMaterialsTitle: "Production Materials",
    //       customerTitle: "Customer",
    //       ERPMaterialsTitle: "ERP Materials",
    //       wareHouseInfoTitle: "WareHouse Info",
    //     },
    //     msg: {
    //       tipDetermineHaveFinishOutStatus: "There is data that has already been shipped out and cannot be deleted!",
    //       tipDetermineHaveFinishOutStatusRow: "The current data has been shipped out and cannot be deleted!",
    //       tip_determine_have_finish_in: 'The current item is put into storage!',
    //       tipDetermineHaveFinishOut: 'The current item is put into storage!',
    //       startDateMustLessThanEndDate: "The start time cannot be greater than the end time",
    //       serial_number_code_error: "Encoding generation failed",
    //       stockOutFinished: "The delivery order has already been shipped out!",
    //       stockOutNotExist: "No outbound order information found!",
    //       startDateMustLessThansealDate: "The sealing time cannot be longer than the unsealing time",
    //     }
    //   },
    //   //物料盘点
    //   stockCheck: {
    //     field: {
    //       stockCheckCode: 'stockCheckCode',
    //       startTime: 'startTime',
    //       endTime: 'endTime',
    //       userName: "userName",
    //       realName: 'realName',
    //       participant: "participant",
    //       stockCheckTime: "inventoryTime",
    //       status: 'status',
    //       stockCheckType: 'stockCheckType',
    //       remark: "remark",
    //       warehouseCode: 'warehouseCode',
    //       warehouseName: 'warehouseName',
    //       itemCode: 'itemCode',
    //       itemName: 'itemName',
    //       itemDensity: "itemDensity",
    //       stockQty: 'stockQty',
    //       stockCheckQty: 'stockCheckQty',
    //       stockTransationQty: 'stockTransationQty',
    //       locationCode: 'locationCode',
    //       locationName: 'locationName',
    //       paramList: "paramList",
    //       warehouseInfo: "Warehouse Information",
    //       locationInfo: "Warehouse Location Information",
    //       formulaText: "Formula",
    //       inventoryCheck: "Inventory Check",
    //       cal: "Count",
    //       index: "Serial Number",
    //       code: "Parameter number",
    //       name: "Parameter",
    //       defVal: "Default value",
    //       value: "Parameter value",
    //       dailyInventory: "dailyInventory",
    //       monthlyInventory: "monthlyInventory",
    //       summary: "Summary Result",
    //       storageLocation: "Storage Location",
    //       record: "record",
    //     },
    //     btn: {
    //       adjustQtyBtn: "AdjustQty",
    //       selWarehouseBtn: "Select Warehouse",
    //       checkBtn: "Inventory",
    //       addCheckBtn: "Add Detail",
    //     },
    //     title: {
    //       stockCheckTitle: "Material Inventory",
    //       inventoryRecordsAddTitle: "Material Inventory Add",
    //       inventoryRecordsEditTitle: "Material Inventory Edit",
    //       inventoryRecordsViewTitle: "Material Inventory View",
    //       lineTitle: "Inventory List",
    //       baseTitle: "Basic Information",
    //       summaryTitle: "Summary Title",
    //       inventoryDetailTitle: "Inventory Detail",
    //       warehouseTitle: "Select Storage Location",
    //     },
    //     msg: {
    //       blank: "Please fill out the complete inventory list",
    //       delWarningBlank: "Please select at least one inventory list",
    //       noDelBlank: "Cannot delete inventory records with inventory status being approved or completed!",
    //       limitation: "The integer range of inventory quantity is within 1-8 digits",
    //       hasMonth: "There is a monthly inventory in this month's Accounting period, please re select",
    //       noFormula: "The warehouse has not yet configured formula parameters",
    //       cal: "The inventory result must be greater than or equal to 0",
    //       submit: "The inventory result for each line of inventory details must be filled in and must be greater than 0",
    //       warehouseCode: "Please select a storage location first",
    //     }
    //   },
    //   //库存查询
    //   stock: {
    //     field: {
    //       bzCode: "code",
    //       itemCodeAndName: "materialCode/name",
    //       warehouseCodeAndName: "warehouseCode/name",
    //       startDate: "startDate",
    //       endDate: "endDate",
    //       stockTime: "time",
    //       itemCode: "materialCode",
    //       itemName: "materialName",
    //       unitName: "unit",
    //       stockQty: "number",
    //       inventoryQty: "inventoryQuantity",
    //       warehouseName: "warehouseName",
    //       warehouseCode: "warehouseCode",
    //       month: "plan month",
    //     },
    //     title: {
    //       rawMaterialTitle: "Raw Material Warehousing",
    //       factoryTitle: "Factory Products",
    //       materialTitle: "Material Consumption",
    //       productOutputTitle: "Product Output",
    //       stockTakingTitle: "Inventory Count",
    //       stockListTitle: "Inventory List",
    //     },
    //   },
    //   //测点订阅
    //   centralControl: {
    //     field: {
    //       classify: "classify",
    //       importLabel: "importLabel",
    //       upload: "upload",
    //       categoryIndexCode: "categoryIndexCode",
    //       deviceCode: "deviceCode",
    //       devicePropertyCode: "devicePropertyCode",
    //       devicePropertyName: "devicePropertyName",
    //       categoryCode: "categoryCode",
    //       categoryName: "categoryName",
    //       categoryFormula: "categoryFormula",
    //       remark: "remark",
    //       standardPointClass: "standardPointClass",
    //       standardPointType: "standardPointType",
    //     },
    //     btn: {
    //       selectFileBtn: 'Select File',
    //     },
    //     title: {
    //       labelTitle: "Central control measurement point",
    //       headTitle: "List of central measurement points",
    //       menuTitle: "Classification properties",
    //       headCatTitle: "The classification of central control measurement points has been added",
    //       headTypeTitle: "Classification of measurement point subscription types",
    //       importTitle: 'Import',
    //       addMaterialTitle: 'Add Material',
    //       dialogTitle: "Device Attribute Selection",
    //       deviceTitle: "Device Information",
    //     },
    //     msg: {
    //       topicBusiTypeSelect: 'Please select a measurement point subscription category!',
    //       uploadInfo: 'Please select the uploaded measurement point file!',
    //       deletePmCategoryRecord: "Please delete the current classification central control record first!",
    //       deletePmCategoryIndex: "Please delete the current classification measurement point subscription list first!",
    //       importLoading: "Importing data, please wait",
    //       importFormat: "Support for importing. xlsx format files",
    //       tip_determine_no_select_cate: "Please select a category first",
    //       templateType: "The input file type does not match. Please use the export template type",
    //     }
    //   },
    //   //交接班记录
    //   classReport: {
    //     btn: {
    //       classAdd: "classAdd",
    //     },
    //     field: {
    //       classCode: "teamCode",
    //       className: "teamName",
    //       classCatCode: "teamTypeCode",
    //       classCatName: "teamType",
    //       deviceCode: "deviceCode",
    //       deviceName: "deviceName",
    //       calendarDate: "rosterDate",
    //       startCalendarDate: "roster start date",
    //       endCalendarDate: "roster end date",
    //       checkDate: "The start date cannot be longer than the end date",
    //       classShiftCode: "classShiftCode",
    //       classShiftName: "classShiftName",
    //       startTime: "startTime",
    //       endTime: "endTime",
    //       optUserName: "handoverPerson",
    //       optUserCode: "handoverPersonCode",
    //       createTime: "shiftHandoverTime",
    //       workTime: "workHours",
    //       classReportText: "shiftHandoverContent",
    //       to: " to ",
    //       statusType: "status"
    //     },
    //     title: {
    //       classReportTitle: "Team daily",
    //       classReportAddTitle: "Team daily Add",
    //       classReportEditTitle: "Team daily Edit",
    //       classReportViewTitle: "Team daily View",
    //     },
    //     msg: {
    //       data_empty: "There is no data for the current shift",
    //       delClassReportFail: "Failed to delete team daily report data!",
    //       warehouseCodeNotExist: "The warehouse code is missing and cannot be stocked in or out!",
    //     },
    //     //生产明细
    //     classReportLine: {
    //       field: {
    //         order: "order",
    //         equipName: "device",
    //         productName: "product",
    //         warehouseName: "Transfer out of warehouse",
    //         outputValInit: "Yield (initial value)",
    //         outputVal: "Yield (corrected value)",
    //         runTimesInit: "Running time (initial value)",
    //         runTimes: "Running time (corrected value)",
    //         usePower: "powerConsumption",
    //         hour: " hour ",
    //         itemCode: "materialCode",
    //         itemName: "materialName",
    //         itemInput: "Consumption (corrected value)",
    //         itemInputInit: "Consumption (initial value)",
    //       },
    //       btn: {
    //         consumeBtn: "Consumption",
    //       },
    //       title: {
    //         productionDetailsTitle: "Production Details",
    //         consumeTitle: "Material Consumption",
    //       },
    //     },
    //     //设备开停机情况
    //     deviceSwitchRecord: {
    //       field: {
    //         order: "order",
    //         deviceCode: "deviceCode",
    //         deviceName: "deviceName",
    //         deviceStatus: "eventType",
    //         optTime: "eventTime",
    //         eventClass: "eventClass",
    //         eventDesc: "eventDescription",
    //       },
    //       title: {
    //         EquipmentTitle: "Equipment Startup And Shutdown Status",
    //       },
    //     },
    //     //其他事件
    //     classReportEvent: {
    //       field: {
    //         order: "order",
    //         equipCode: "deviceCode",
    //         equipName: "deviceName",
    //         deviceStatus: "eventType",
    //         optTime: "eventTime",
    //         eventClass: "eventClass",
    //         eventDesc: "eventDescription",
    //       },
    //       title: {
    //         otherEventTitle: "Other Event",
    //       },
    //       msg: {
    //         equipNameTip: "Please select device name",
    //         deviceStatusTip: "Please select event type",
    //       },
    //     },
    //   },
    //   //开停机查询
    //   deviceQuery: {
    //     field: {
    //       deviceCode: "deviceCode",
    //       deviceName: "deviceName",
    //       startTime: "startTime",
    //       endTime: "endTime",
    //       runTime: "runTime",
    //       shutdownCode: "shutdownCode",
    //       shutdownInstruction: "shutdownInstruction",
    //     },
    //     title: {
    //       queryTitle: "Start/Stop Query",
    //     },
    //   },
    //   //设备状态
    //   deviceStatus: {
    //     field: {
    //       deviceCode: "deviceCode",
    //       deviceName: "deviceName",
    //       deviceStatus: "deviceStatus",
    //       optTime: "Operation Time",
    //     },
    //     title: {
    //       statusTitle: "Device Status",
    //       infoTitle: "Device Information",
    //       statusAddTitle: "Device Status Add",
    //       statusEditTitle: "Device Status Edit",
    //       statusViewTitle: "Device Status View",
    //     },
    //   },
    //   //生产采集修改
    //   classReportDtlHour: {
    //     field: {
    //       equipName: "equipName",
    //       dcsType: "dcsType",
    //       dcsName: "dcsName",
    //       date: "date",
    //       optTime: "optTime",
    //       dcsVal: "dcsVal",
    //       init: "init",
    //       update: "update",
    //       proItemName: "proItemName",
    //     },
    //     title: {
    //       indexTitle: "Production Collection Modification",
    //     },
    //     msg: {
    //       timeNotNull: "Time query criteria cannot be empty!",
    //       submit: "After clicking submit, all data for today cannot be modified again",
    //     }
    //   },
    //   //设备开停机记录
    //   deviceRecord: {
    //     field: {
    //       deviceCode: "deviceCode",
    //       deviceName: "deviceName",
    //       deviceStatus: "deviceStatus",
    //       optTime: "optTime",
    //       remark: "remark",
    //     },
    //     title: {
    //       deviceRecordTitle: "Equipment Startup/Shutdown Record",
    //       deviceRecordAddTitle: "Equipment Startup/Shutdown Record Add",
    //       deviceRecordEditTitle: "Equipment Startup/Shutdown Record Edit",
    //       deviceRecordViewTitle: "Equipment Startup/Shutdown Record View",
    //       infoTitle: "Device Information",
    //     },
    //   },
    //   //交接班记录修改
    //   classReportDtl: {
    //     field: {
    //       equipName: "equipName",
    //       classShiftName: "classShiftName",
    //       dcsType: "dcsType",
    //       dcsName: "dcsName",
    //       calendarDate: "calendarDate",
    //       classCatName: "classCatName",
    //       className: "className",
    //       dcsVal: "dcsVal",
    //       init: "init",
    //       update: "update",
    //     },
    //     title: {
    //       classReportDtlTitle: "Modification Of Team daily Report",
    //     },
    //     msg: {
    //       submit: "After clicking submit, all team daily report data for this day cannot be modified again",
    //     }
    //   },
    //   //上班签到
    //   workRecord: {
    //     field: {
    //       calendarDate: "calendarDate",
    //       classCatName: "classCatName",
    //       className: "className",
    //       classShiftName: "classShiftName",
    //       teamLeader: "teamLeader",
    //       userName: "personnel",
    //       isRequired: "isRequired",
    //       isTeamLeader: "isTeamLeader",
    //       remark: "remark",
    //       workDate: "clockInTime",
    //       bringOut: "bringOut",
    //       deptName: "deptName",
    //       isWork: "isWork",
    //       workType: "workType",
    //     },
    //     title: {
    //       workRecordTitle: "Work Record",
    //       classCalendarTitle: "Scheduling Calendar",
    //       classCalendarAddTitle: "Scheduling Calendar Add",
    //     },
    //     btn: {
    //       onDutyPersonnelBtn: "On Duty Personnel",
    //     },
    //     msg: {
    //       multiple_class_error: "There are multiple team information, please hiddenTrouble!",
    //       onlyOneLeader: "A team can only have one team leader",
    //       multiple_class_calendar_error: "There are multiple team classification information, please hiddenTrouble!",
    //       no_class_calendar_error: "The current logged in user does not have a team classification",
    //     }
    //   },
    // },
    // //基础设置
    // md: {
    //   cmn: {
    //     field: {
    //       name: "name",
    //       code: "code",
    //       remark: "remark",
    //       driver: "driver",
    //       manage: "manage",
    //     }
    //   },
    //   //水分系数
    //   waterRate: {
    //     msg: {
    //       month: "Please select a month",
    //     },
    //     field: {
    //       enable: "enable",
    //       disable: "disable",
    //       lastYear: 'lastYear',
    //       nextYear: 'nextYear',
    //       status: 'status',
    //       versionCode: 'versionCode',
    //       jan: "January",
    //       feb: "February",
    //       mar: "March",
    //       apr: "April",
    //       may: "May",
    //       jun: "June",
    //       jul: "July",
    //       aug: "August",
    //       sep: "September",
    //       oct: "October",
    //       nov: "November",
    //       dec: "December",
    //       waterRateValRef: 'Moisture Coefficient (%) Collection Reference',
    //       rateVal: 'Correction value of moisture coefficient (%)',
    //       remark: 'remark',
    //       moistureCoefficient: "moistureCoefficient",
    //       time: "time",
    //       qualityType: "qualityType",
    //       itemCode: "itemCode",
    //       itemName: "itemName",
    //       coefficient: "moistureCoefficient(%)",
    //       num: "qualityNumber",
    //       totalWeight: "totalWeight",
    //       weight: "batchWeight",
    //     },
    //     title: {
    //       moistureCoefficientTitle: 'Moisture Coefficient',
    //       chooseTitle: 'Please Select The Version',
    //       detailtitle: 'Moisture Coefficient Detail',
    //       productionMaterialTitle: "Production Material",
    //       automaticCollectionTitle: "Automatic Collection",
    //       qualityInspectionDetailTitle: "Quality Inspection Detail",
    //     },
    //     btn: {
    //       quoteBtn: "Confirm Reference",
    //     },
    //   },
    //   //工序管理
    //   process: {
    //     field: {
    //       processCode: "processCode",
    //       processName: "processName",
    //       shortName: "shortName",
    //       parentId: "parentId",
    //       remark: "remark",
    //       isEnable: "isEnable",
    //       sort: "processSort",
    //     },
    //     title: {
    //       processManagementTitle: "Process Management",
    //       processAddTitle: "Process Add",
    //       processEditTitle: "Process Edit",
    //     },
    //   },
    //   //区域维护
    //   monitor: {
    //     field: {
    //       areaCode: "areaCode",
    //       areaName: "areaName",
    //       parentArea: "parentArea",
    //       isMonitor: "isMonitorArea",
    //       isEnable: "isEnable",
    //       parentId: "parentId",
    //       parentCode: "parentCode",
    //       areaType: "areaType",
    //       processName: "processName",
    //     },
    //     title: {
    //       regionalMaintenanceTitle: "Regional Maintenance",
    //       regionalMaintenanceAddTitle: "Regional Maintenance Add",
    //       regionalMaintenanceEditTitle: "Regional Maintenance Edit",
    //     },
    //   },
    //   //点位配置
    //   monitoring: {
    //     //摄像头信息
    //     camera: {
    //       field: {
    //         camreaCode: "camreaCode",
    //         camreaName: "camreaName",
    //         funct: "deploymentFeature",
    //         url: "url",
    //         remark: "remark",
    //       },
    //       title: {
    //         cameraTitle: "Camera Information",
    //         cameraAddTitle: "Camera Information Add",
    //         cameraEditTitle: "Camera Information Edit",
    //       },
    //       msg: {
    //         // 以下三条为后端路径
    //         deleteArea: "Please delete the corresponding regional monitoring point data first",
    //         deleteDevice: "Please delete the device monitoring point data corresponding to the data first",
    //         deleteVideo: "Please delete the large screen video configuration data corresponding to the data first",
    //       },
    //     },
    //     //区域监控点位
    //     monitoring: {
    //       field: {
    //         areaName: "areaName",
    //         areaCode: "areaCode",
    //         processCode: "processCode",
    //         processName: "processName",
    //         camreaCode: "camreaCode",
    //         camreaName: "camreaName",
    //         funct: "deploymentFeature",
    //         url: "url",
    //       },
    //       title: {
    //         RMPTitle: "Regional Monitoring Point",
    //         RMPAddTitle: "Regional Monitoring Point Add",
    //         RMPEditTitle: "Regional Monitoring Point Edit",
    //         treeTitle: "Area Type",
    //       },
    //       msg: {
    //         camreaName: "Automatically brought out after selecting the camera name",
    //       },
    //     },
    //     //设备监控点位
    //     deviceMonitoring: {
    //       field: {
    //         mainCode: "mainCode",
    //         mainName: "mainName",
    //         camreaCode: "camreaCode",
    //         camreaName: "camreaName",
    //         funct: "deploymentFeature",
    //         url: "url",
    //         mainDeviceCode: "mainDeviceCode",
    //         mainDeviceName: "mainDeviceName",
    //       },
    //       title: {
    //         EMPTitle: "Equipment Monitoring Point",
    //         EMPAddTitle: "Equipment Monitoring Point Add",
    //         EMPEditTitle: "Equipment Monitoring Point Edit",
    //         treeTitle: "Process Type",
    //       },
    //       msg: {
    //         camreaName: "Automatically brought out after selecting the camera name",
    //       },
    //     },
    //     //大屏视频配置
    //     video: {
    //       field: {
    //         lsCode: "largeScreenDisplayCode",
    //         cameraCode: "cameraCode",
    //         cameraName: "cameraName",
    //         funct: "funct",
    //         areaName: "areaName",
    //         url: "url",
    //         remark: "remark",
    //       },
    //       title: {
    //         videoTitle: "Large Screen Video Configuration",
    //         videoAddTitle: "Large Screen Video Configuration Add",
    //         videoEditTitle: "Large Screen Video Configuration Edit",
    //         selectCameraTitle: "Select Camera",
    //       },
    //       msg: {
    //         code: "The large screen display code already exists!",
    //       },
    //     },
    //   },
    //   //设备管理
    //   device: {
    //     field: {
    //       mainDeviceCatCode: "mainDeviceCatCode",
    //       mainDeviceCatName: "mainDeviceCatName",
    //       importLevel: "importLevel",
    //       sort: "sort",
    //       optMode: "optMode",
    //       isSpecial: "isSpecial",
    //       isEnvPort: "isEnvPort",
    //       powerType: "powerType",
    //       portAtt: "portAtt",
    //       remark: "remark",
    //       subDeviceCatCode: "subDeviceCatCode",
    //       subDeviceCatName: "subDeviceCatName",
    //       adminDept: "adminDept",
    //       connectLocation: "connectLocation",
    //       mainDeviceCode: "mainDeviceCode",
    //       mainDeviceName: "mainDeviceName",
    //       processName: "processName",
    //       subDeviceCode: "subDeviceCode",
    //       subDeviceName: "subDeviceName",
    //       sparepartsCode: "sparepartsCode",
    //       sparepartsName: "sparepartsName",
    //       specs: "specs",
    //       value: "value",
    //       unit: "unit",
    //     },
    //     title: {
    //       mainDeviceCateTitle: "Main Device Cate",
    //       mainDeviceCateAddTitle: "Main Device Cate Add",
    //       mainDeviceCateEditTitle: "Main Device Cate Edit",
    //       subDeviceCateTitle: "Sub Device Cate",
    //       subDeviceCateAddTitle: "Sub Device Cate Add",
    //       subDeviceCateEditTitle: "Sub Device Cate Edit",
    //       mainAndSubDeviceRelationTitle: "Main And SubDevice Relation",
    //       mainAndSubRelationTitle: "Select Sub Device",
    //       mainDeviceTitle: "Main Device Management",
    //       processCateTitle: "Process Cate",
    //       mainDeviceAddTitle: "Main Device Add",
    //       mainDeviceEditTitle: "Main Device Edit",
    //       subDeviceTitle: "Sub Device Management",
    //       subDeviceAddTitle: "Sub Device Add",
    //       subDeviceEditTitle: "Sub Device Edit",
    //       sparePartTitle: "Spare Part List",
    //       sparePartAddTitle: "Spare Part List Add",
    //       sparePartEditTitle: "Spare Part List Edit",
    //     },
    //     msg: {
    //       selectMainDeviceCate: "Please select the main device cate",
    //       selectsubDeviceCate: "Please select the sub device cate",
    //       selectProcessCate: "Please select the process cate",
    //       selectMainDevice: "Please select the main device",
    //     }
    //   },
    //   //机构管理
    //   mdpostLevel: {
    //     field: {
    //       postLevelCode: "postLevelCode",
    //       postLevelName: "postLevelName",
    //       remark: "describe",
    //       sectCode: "sectCode",
    //       sectName: "sectName",
    //       processName: "processName",
    //       subjectCode: "subjectCode",
    //       subjectName: "subjectName",
    //       dutyCode: "dutyCode",
    //       dutyName: "dutyName",
    //       subjectId: "subjectId",
    //       shiftTypeCode: "shiftTypeCode",
    //       shiftTypeName: "shiftTypeName",
    //       shiftType: "shiftType",
    //       blockTrain: "blockTrain",
    //       dateList: "dateList",
    //       morning: "morning",
    //       midday: "midday",
    //       evening: "evening",
    //     },
    //     title: {
    //       rankTitle: "Rank Management",
    //       rankAddTitle: "Rank Add",
    //       rankEditTitle: "Rank Edit",
    //       sectionTitle: "Management Section",
    //       sectionAddTitle: "Management Section Add",
    //       sectionEditTitle: "Management Section Edit",
    //       majorTitle: "Management Major",
    //       majorAddTitle: "Management Major Add",
    //       majorEditTitle: "Management Major Edit",
    //       responsibilityTitle: "Responsibility Management",
    //       responsibilityAddTitle: "Responsibility Add",
    //       responsibilityEditTitle: "Responsibility Edit",
    //       shiftModeTitle: "Shift Mode",
    //       shiftModeAddTitle: "Shift Mode Add",
    //       shiftModeEditTitle: "Shift Mode Edit",
    //     },
    //     msg: {
    //       selectWarning: "Please select the management major first",
    //     }
    //   },
    //   //生产配方
    //   itemFormula: {
    //     field: {
    //       itemCode: "materialCode",
    //       itemName: "materialName",
    //       versionCode: "versionNumber",
    //       isEnable: "isEnable",
    //       bomItemName: "materialName",
    //       rateVal: "proportionalDosage(%)",
    //       consume: "productionConsumption(%)",
    //       remark: "remark",
    //     },
    //     title: {
    //       itemFormulaTitle: "Production Formula",
    //       itemFormulaEditTitle: "Production Formula Edit",
    //       itemFormulaViewTitle: "Production Formula View",
    //       selectTitle: "Select Formula Product",
    //       recipeVersionTitle: "Recipe Version",
    //       recipeVersionAddTitle: "Recipe Version Add",
    //       recipeVersionEditTitle: "Recipe Version Edit",
    //       recipeVersionCopyTitle: "Recipe Version Copy",
    //       formulaDetailTitle: "Formula Detail",
    //       selectMaterialTitle: "Select Recipe Material",
    //       queryTitle: "Production Ratio Reference Query",
    //     },
    //     btn: {
    //       productFormulaBtn: "Production Formula",
    //       productSearchBtn: "Production Ratio Reference Query",
    //       selectItemBtn: "Select Material",
    //     },
    //     msg: {
    //       selectWarning: "Please select the recipe version first!",
    //       rateVal: "The ratio of usage needs to be 100%",
    //       deleteRateVal: "Deleting this material requires allocating the proportion usage to other materials",
    //     }
    //   },
    //   //生产物料
    //   item: {
    //     field: {
    //       labelText: "title",
    //       parentCode: "parentCategory",
    //       parentName: "parentName",
    //       isEnable: "isEnable",
    //       itemCode: "materialCode",
    //       itemName: "materialName",
    //       itemCatCode: "itemCatCode",
    //       itemCatName: "itemCatName",
    //       upperLimit: "Safety stock (upper limit)",
    //       lowerLimit: "Safety stock (lower limit)",
    //       itemDensity: "itemDensity",
    //       remark: "remark",
    //       processName: "processName",
    //       code: "materialCode",
    //       name: "materialName",
    //       unitName: "unitName",
    //       isProduct: "isProduct",
    //       itemCodeOrName: "materialCode/Name",
    //       serialNumber: "serialNumber",
    //       bomItemName: "BOMMaterialName",
    //       bomItemCode: "BOMMaterialCode",
    //       extItemCodeOrName: "ERP materialCode/Name",
    //       extItemCode: "ERPMaterialCode",
    //       extItemName: "ERPMaterialName",
    //       extItemCatCode: "extItemCatCode",
    //       extItemCatName: "extItemCatName",
    //       warehouseCodeAndName: "warehouseCodeAndName",
    //       stockInOut: "inbound/outbound Type",
    //       warehouseCode: "warehouseCode",
    //       warehouseName: "warehouseName",
    //       stockDataSource: "dataSource",
    //       stockDcs: "DCS measuring point",
    //       select: "select",
    //       warehouseShapeCode: "warehouseShape",
    //     },
    //     title: {
    //       productionMaterialTitle: "Production Material",
    //       productionMaterialAddTitle: "Production Material Add",
    //       productionMaterialEditTitle: "Production Material Edit",
    //       productionMaterialViewTitle: "Production Material View",
    //       proMaterialClassTitle: "Production Material Classification",
    //       materialMappingTitle: "Material Mapping",
    //       materialBOMTitle: "Material BOM",
    //       erpMaterialTitle: "ERP Material",
    //       selectMaterialTitle: "Select Material",
    //       selectERPMaterialsTitle: "Select ERP Material",
    //       materialViewTitle: "View Material",
    //       materialEditTitle: "Edit Material",
    //       materialAddTitle: "Add Material",
    //       materialWarehouseTitle: "Material Warehouse",
    //       itemWarehouseHeadTitle: "Select Warehouse",
    //     },
    //     msg: {
    //       exportMaterialUnit: "Export material unit data?",
    //       exportMaterialSubstitute: "Export Material Substitute Data?",
    //       exportMaterialSupplier: "Export material supplier data?",
    //       exportMaterial: "Export Material Data?",
    //       notSupported: "Multiple selection not supported",
    //       notSelect: "This piece of data is not allowed to be selected!",
    //       erpBindOneItem: "An ERP material can only be bound to one production material",
    //     }
    //   },
    //   //仓库管理
    //   warehouse: {
    //     field: {
    //       code: "wareHouseCode",
    //       name: "wareHouseName",
    //       isEnable: "isEnable",
    //       itemCode: "materialCode",
    //       itemName: "materialName",
    //       isEnableEarlyWarning: "enableAlert",
    //       earlyWarningThreshold: "warningThreshold",
    //       locationCode: "warehouseLocationCode",
    //       locationName: "warehouseLocationName",
    //       formula: "formula",
    //       remark: "remark",
    //       defaultWarehouse: 'oneCardDefaultWarehouse',
    //       formulaCode: "parameterCode",
    //       formulaName: "parameterName",
    //       formulaValue: "defaultValue",
    //       describe: "describe",
    //       warnType: 'warningType',
    //       stationCode: 'measurementPointCode',
    //       warnRange: 'warningRange',
    //     },
    //     title: {
    //       warehouseManagementTitle: "Warehouse Management",
    //       warehouseAddTitle: "Warehouse Add",
    //       warehouseEditTitle: "Warehouse Edit",
    //       warehouseViewTitle: "Warehouse View",
    //       baseTitle: "Basic Information",
    //       locationTitle: "Warehouse Location Management",
    //       warehouseWarnTitle: 'Warehouse Warning',
    //       productionMaterialTitle: "Production Material",
    //       formulaTitle: "Formula",
    //       formulaParameterListTitle: "Formula Parameter List",
    //     },
    //     btn: {
    //       formulaBtn: "Formula",
    //     },
    //     msg: {
    //       selectWarning: "Please save the location information first",
    //       addWarning: "Please save the basic information first",
    //       saveWarning: "Please select the location information that needs to be saved",
    //       locationCodeBlank: "Please enter the warehouse location code",
    //       locationNameBlank: "Please enter the warehouse location name",
    //       codeSame: "Encoding already exists",
    //       formulaTextBlank: "Please fill in the calculation formula",
    //       formulaCodeBlank: "Please improve the formula parameter encoding information",
    //       formulaNameBlank: "Please improve the formula parameter name information",
    //       formulaValueBlank: "Please improve the default value information of formula parameters",
    //       formulaParam1Blank: "The parameter list contains parameters that are not used in the formula",
    //       formulaParam2Blank: "The formula uses parameters that were not added in the parameter list",
    //       onlyOneWarning: "Only one inventory warning can exist!",
    //       codeBlank: "Please enter the warehouse number",
    //       nameBlank: "Please enter the warehouse name",
    //       itemCodeBlank: "Please enter the material code",
    //       itemNameBlank: "Please enter the material name",
    //       defaultWarehouse: "Please select the default warehouse for one card",
    //       saveFailBecauseHave: "Save failed, The current warehouse has already configured materials",
    //     }
    //   },      //仓库管理
    //   physicalWarehouse: {
    //     field: {
    //       code: "wareHouseCode",
    //       name: "wareHouseName",
    //       type: "wareHouseType",
    //       isEnable: "isEnable",
    //       createTime: "createTime",
    //       location: "location",
    //       capacity: "capacity",
    //       formula: "formula",
    //       dcsPoint: "dcsPoint",
    //       remark: "remark",
    //       itemCode: "materialCode",
    //       itemName: "materialName",
    //       formulaCode: "parameterCode",
    //       formulaName: "parameterName",
    //       formulaValue: "defaultValue",
    //       describe: "describe",
    //     },
    //     title: {
    //       warehouseManagementTitle: "Physical Warehouse Management",
    //       warehouseAddTitle: "Physical Warehouse Add",
    //       warehouseEditTitle: "Physical Warehouse Edit",
    //       warehouseViewTitle: "Physical Warehouse View",
    //       baseTitle: "Basic Information",
    //       itemTitle: "item Title",
    //       itemDialogTitle: "Production Materials",
    //     },
    //     btn: {
    //       formulaBtn: "Formula",
    //     },
    //     msg: {
    //       selectWarning: "Please save the location information first",
    //       formula: "Please click on the configuration inventory calculation formula",
    //       code: "Automatically generate if not filled in",
    //     }
    //   },
    //   //班组
    //   class: {
    //     field: {
    //       classCode: "classCode",
    //       className: "className",
    //       isEnable: "isEnable",
    //       classCatCode: "classCatCode",
    //       classCatName: "classCatName",
    //       parentName: "parentName",
    //       remark: "remark",
    //       userCode: "userCode",
    //       userName: "userName",
    //       isLeader: "isLeader",
    //     },
    //     title: {
    //       classTitle: "Team",
    //       classAddTitle: "Team Add",
    //       classEditTitle: "Team Edit",
    //       classViewTitle: "Team View",
    //       teamClassificationTitle: "Team Classification",
    //       baseTitle: "Basic Information",
    //       classMemberTitle: "Team Member",
    //       selectMemberTitle: "Select Member",
    //     },
    //     msg: {
    //       firstSaveData: "Please save the data in the team members first",
    //       onlyOneLeader: "A team can only have one team leader",
    //       memberIsEmpty: "Team members cannot be empty",
    //       memberDuplicated: "Team members cannot be duplicate",
    //     }
    //   },
    //   //班次
    //   classShift: {
    //     field: {
    //       classShiftCatCode: "classShiftCatCode",
    //       classShiftCatName: "classShiftCatName",
    //       classShiftCode: "classShiftCode",
    //       classShiftName: "classShiftName",
    //       startTime: "startTime",
    //       endTime: "endTime",
    //       classShiftDateType: "classShiftDateType",
    //       remark: "remark",
    //     },
    //     title: {
    //       classShiftTitle: "ClassShift",
    //       classShiftCatTitle: "classShiftCat",
    //       classShiftEditTitle: "ClassShift Edit",
    //       classShiftViewTitle: "ClassShift View",
    //     },
    //     msg: {
    //       tipDetermineInit: "Confirm initialization data?",
    //       classShiftCat: "Please select a shift classification",
    //       classShiftCode: "Automatic generation of classShiftCode",
    //     }
    //   },
    //   //排班规则
    //   classShiftRule: {
    //     field: {
    //       classShiftRuleCode: "classShiftRuleCode",
    //       classShiftRuleName: "classShiftRuleName",
    //       classCatCode: "classCatCode",
    //       classCatName: "classCatName",
    //       startDate: "startDate",
    //       endDate: "endDate",
    //       remark: "remark",
    //       classShiftRuleSort: "classShiftRuleSort",
    //       className: "className",
    //       classShiftName: "classShiftName",
    //     },
    //     title: {
    //       classShiftRuleTitle: "ClassShiftRule",
    //       classShiftRuleAddTitle: "ClassShiftRule Add",
    //       classShiftRuleEditTitle: "ClassShiftRule EDit",
    //       classShiftRuleViewTitle: "ClassShiftRule View",
    //       baseTitle: "Basic Information",
    //       classShiftRuleLineTitle: "ClassShiftRuleLine",
    //     },
    //     msg: {
    //       lineIsEmpty: "This layout rule has no row information",
    //       ruleTimeIsEmpty: "The scheduling rule time is empty",
    //       ruleTimeError: "The start time of this scheduling rule is greater than the end time",
    //       firstSaveData: "Please save the data in the scheduling rule row first",
    //       only_one: "The team name in the scheduling rule line is duplicate!",
    //       tip_determine_gen: "Confirm generation of data?",
    //       ruleDay: "The number of shift days is less than the number of shift rule days",
    //       dateRepetition: "There are duplicate dates between this scheduling period and historical scheduling",
    //     }
    //   },
    //   //排班日历
    //   classCalendar: {
    //     field: {
    //       calendarDate: "calendarDate",
    //       classCatName: "classCatName",
    //       className: "className",
    //       calendarEndDate: "calendarEndDate",
    //       classShiftName: "classShiftName",
    //       remark: "remark",
    //       userName: "memberName",
    //       isLeader: "isLeader",
    //     },
    //     title: {
    //       classCalendarTitle: "Scheduling Calendar",
    //       classCalendarViewTitle: "Scheduling Calendar View",
    //       baseTitle: "Basic Information",
    //       classMemberTitle: "Team Member",
    //       classMemberAddTitle: "Add Personnel On Duty",
    //     },
    //   },
    //   //工艺配置
    //   processSetting: {
    //     field: {
    //       processCode: "processCode",
    //       processName: "processName",
    //       equipCode: "equipCode",
    //       equipName: "equipName",
    //       classCatName: "classCatName",
    //       isEnable: "isEnable",
    //       processOutitemFormulaType: "processOutitemFormulaType",
    //       remark: "remark",
    //       itemCode: "itemCode",
    //       dcsName: "dcsName",
    //       itemName: "itemName",
    //       warehouseName: "warehouseName",
    //       dcsType: "dcsType",
    //       dcsCode: "dcsCode",
    //       itemGroupCode: "itemGroupCode",
    //       weightRatio: "weightRatio(%)",
    //       //selectDcsTable页面的测点订阅
    //       categoryIndexCode: "categoryIndexCode",
    //       deviceCode: "deviceCode",
    //       devicePropertyCode: "devicePropertyCode",
    //       devicePropertyName: "devicePropertyName",
    //       categoryName: "categoryName",
    //       categoryCode: "categoryCode",
    //     },
    //     title: {
    //       processSettingTitle: "Process Setting",
    //       processSettingAddTitle: "Process Setting Add",
    //       processSettingEditTitle: "Process Setting Edit",
    //       productionMaterialTitle: "Production Material",
    //       labelTitle: "Measurement Point Subscription",
    //       lineTitle: "Material Consumption",
    //       equipmentSettingTitle: "Equipment Operation Setting",
    //       headTitle: "ERP Material Management",
    //     },
    //     msg: {
    //       decCode:"The sum of the proportions of the same point codes must be equal to 100",
    //       decName:"The name of the same measuring point must be the same",
    //       decNameNull:"Please enter the station name",
    //       number:"You can only enter two digits after the decimal point",
    //       warehouse_name_blank: "Please select a warehouse name",
    //       dcsType_name_blank: "Please select the type of measuring point",
    //       dcs_code_blank: "Please select the point code",
    //       dataHaveExist: "This device already has a configuration",
    //     }
    //   },
    //   //项目管理
    //   project: {
    //     field: {
    //       projectCode: "projectCode",
    //       projectName: "projectName",
    //       projectAddr: "projectAddr",
    //       address: "address",
    //       longitude: "longitude",
    //       dimension: "dimension",
    //       companyName: "companyName",
    //       remark: "projectDesc",
    //     },
    //     title: {
    //       indexTitle: "Project Management",
    //       addTitle: "Project Add",
    //       editTitle: "Project Edit",
    //       viewTitle: "Project View",
    //     },
    //   },
    //   itemOrg: {
    //     field: {
    //       itemOrgs: "itemOrgs",
    //       itemDetail: "itemDetail",
    //       orgErr: "orgErr！",
    //       itemErr: "Material cannot be duplicate, please reselect!",
    //     },
    //     title:{
    //       indexTitle: "Material Organization Mapping",
    //     }
    //   },
    //   virtualOrg: {
    //     field: {
    //       inSide: "inSide",
    //       title: "title",
    //       outSide: "outSide",
    //       orgCode: "orgCode",
    //       orgName: "orgName",
    //       companyName: "companyName",
    //       orgType: "orgType",
    //       remark: "remark"
    //     },
    //     title:{
    //       indexTitle: "Organizational management",
    //     }
    //   },
    //   saleArea: {
    //     field: {
    //       tagName: "tagName",
    //       areaName: "areaName",
    //       areaCode: "areaCode",
    //       createPeople: "createPeople",
    //       createTime: "createTime",
    //       belongArea: "belongArea",
    //       remarks: "remarks",
    //       cityName: "cityName",
    //       cityCode: "cityCode",
    //     },
    //   },
    //   projectFiles: {
    //     field: {
    //       tagName: "tagName",
    //       projectName: "projectName",
    //       projectCode: "projectCode",
    //       createPeople: "createPeople",
    //       createTime: "createTime",
    //       projectType: "projectType",
    //       belongCompany: "belongCompany",
    //       remarks: "remarks"
    //     },
    //     title:{
    //
    //     }
    //   },
    // },
    // business: {
    //   components: {
    //     common: {
    //       treeTableDialog: {
    //         msg: {
    //           selected: "selected",
    //         }
    //       }
    //     },
    //     //ERP物料
    //     mdExtItemDialog: {
    //       field: {
    //         extItemCode: "ERP materialCode",
    //         extItemName: "ERP materialName",
    //         logisticsCode: "logisticsCode",
    //         batchCode: "batchCode",
    //         carNo: "carNo",
    //       },
    //       title: {
    //         erpMaterialTitle: "ERP Material",
    //       },
    //     },
    //     //ERP物料管理
    //     mdItemDialog: {
    //       field: {
    //         itemCode: "Material Code",
    //         itemName: "Material Name",
    //         unitName: "Unit Name",
    //       },
    //       title: {
    //         headTitle: "ERP Material Management",
    //       },
    //     },
    //     //仓库信息
    //     mdWarehouseDialog: {
    //       field: {
    //         code: "wareHouseCode",
    //         name: "wareHouseName",
    //         isEnable: "isEnable",
    //       },
    //       title: {
    //         wareHouseTitle: "Warehouse Information",
    //       },
    //     },
    //     //仓库信息
    //     mdWarehouseQytDialog: {
    //       field: {
    //         warehouseName: "warehouseName",
    //         warehouseCode: "warehouseCode",
    //         itemName: "Material Name",
    //         itemCode: "Material Code",
    //         stockQty: "Inventory Quantity",
    //         unitName: "Unit Name",
    //       },
    //       title: {
    //         wareHouseTitle: "Warehouse Information",
    //       },
    //     },
    //     //设备信息
    //     pmDeviceDialog: {
    //       field: {
    //         deviceCode: "deviceCode",
    //         deviceName: "deviceName",
    //       },
    //       title: {
    //         deviceTitle: "Device Information",
    //       },
    //     },
    //   },
    // },
    // cps: {
    //   msg: {
    //     removeSpaces: 'Please delete the spaces before and after',
    //     tipDetermineRemove: 'Confirm to remove the selected data?',
    //     importFormat: 'Excel format file import',
    //     importLoading: 'Importing data, please wait...',
    //     saveHead: 'saveHead',
    //   },
    //   field: {
    //     createTime: 'createTime',
    //     remark: 'remark',
    //     serial: "serialNumber",
    //   },
    //   btn: {
    //     importData: 'importData',
    //     exportModel: 'exportModel',
    //     exportMonitoring: 'exportMonitoring',
    //     exportDevice: 'exportDevice',
    //     exportPoint: 'exportPoint',
    //     improtDevice: 'improtDeviceData',
    //     improtPoint: 'improtPointData',
    //     exportDownload: 'exportDownload',
    //     download: 'download',
    //     exportDeviceTemplate: 'exportDeviceTemplate',
    //   },
    //   device: {
    //     deviceProperty: {
    //       devicePropertyCode: 'devicePropertyCode',
    //       devicePropertyName: 'devicePropertyName',
    //       dataType: 'dataType',
    //       variableType: 'variableType',
    //       standardPointCode: 'standardPointCode',
    //       rangeMax: 'rangeMax',
    //       rangeMin: 'rangeMin',
    //       dcsCode: 'dcsCode',
    //       isEnable: 'isEnable',
    //       isPush: "isPush",
    //     },
    //     deviceEvent: {
    //       deviceEventCode: 'deviceEventCode',
    //       deviceEventName: 'deviceEventName',
    //       dataType: 'dataType',
    //       variableType: 'variableType',
    //       standardPointCode: 'standardPointCode',
    //       deviceEventType: 'deviceEventType',
    //       dcsCode: 'dcsCode',
    //       isEnable: 'isEnable',
    //       gaugingType: 'floating-pointType',
    //       isPush: "isPush",
    //     },
    //     deviceService: {
    //       deviceServiceCode: 'deviceServiceCode',
    //       deviceServiceName: 'deviceServiceName',
    //       dataType: 'dataType',
    //       variableType: 'variableType',
    //       standardPointCode: 'standardPointCode',
    //       rangeMax: 'rangeMax',
    //       rangeMin: 'rangeMin',
    //       dcsCode: 'dcsCode',
    //       isEnable: 'isEnable',
    //       isPush: "isPush",
    //     },
    //     deviceCalc: {
    //       selectInputParam: 'Please select an input parameter',
    //       deviceCalcCode: 'deviceCalcCode',
    //       deviceCalcName: 'deviceCalcName',
    //       resultDataType: 'resultDataType',
    //       calcMethod: 'calcMethod',
    //       inputParam: 'inputParam',
    //       isSave: 'isSave',
    //       isPush: 'isPush',
    //       isEnable: 'isEnable',
    //     },
    //     title: {
    //       netType: 'Logical location classification',
    //       locationType: 'Physical location classification',
    //     },
    //     msg: {
    //       chinese: 'You cannot enter Chinese characters',
    //       exportData: 'Decide to export device data？',
    //       modelUpdate: 'Are you sure to regenerate device details based on the associated model? The old details will be overwritten',
    //       selectprotocolParams: 'Please selectprotocol configuration',
    //       checkModel: 'Please select the model first',
    //     },
    //     field: {
    //       netType: {
    //         netCode: 'netCode',
    //         netName: 'netName',
    //         orderNum: 'orderNum',
    //         isEnable: 'isEnable',
    //         heartbeatTopic: 'heartbeatTopic'
    //       },
    //       locationType: {
    //         parentName: 'ParentClassification',
    //         locationCode: 'locationCode',
    //         locationName: 'locationName',
    //         orderNum: 'orderNum',
    //         isEnable: 'isEnable',
    //         ten: 'A number up to 10 digits'
    //       },
    //       netTitle: 'logical location classification',
    //       locTitle: 'physical location classification',
    //       deviceData: '【Data acquisition platform】Equipment data acquisition data',
    //       deviceTemplate: '【Data acquisition platform】Equipment data acquisition import template',
    //       autoValue: 'Automatically generated by default',
    //       list: 'Device management',
    //       CSV: 'Link to CSV chart',
    //       deviceCode: 'deviceCode',
    //       deviceName: 'deviceName',
    //       productCode: 'productCode',
    //       productName: 'productName',
    //       modelCode: 'modelCode',
    //       modelName: 'modelName',
    //       subModelCode: 'subModelCode',
    //       subModelName: 'subModelName',
    //       selectModel: 'select model',
    //       deviceType: 'deviceType',
    //       deviceKey: 'deviceKey',
    //       protocolCode: 'protocolCode',
    //       protocolName: 'protocolName',
    //       locationName: 'locationName',
    //       netName: 'netName',
    //       deviceTags: 'deviceTags',
    //       topicUrl: 'topicUrl',
    //       protocolParams: 'protocolParams',
    //       isEnable: 'isEnable',
    //       property: 'property',
    //       event: 'event',
    //       service: 'service',
    //       calc: 'calc',
    //       select: 'device',
    //       normal: 'normalQuantity',
    //       abnormal: 'abnormalQuantity',
    //       enableDeviceCount: 'normalQuantity',
    //       disableDeviceCount: 'abnormalQuantity',
    //       dragin: 'auto in by default',
    //       updateModel: 'update model',
    //       mainDeviceCat: 'Main Device Cat',
    //       mainDevice: 'Main Device',
    //       mainDeviceAdd: 'Main Device Add',
    //       deviceCatConf: 'Device Cat Conf',
    //       subDeviceCat: 'Sub Device Cat',
    //       subDevice: 'Sub Device',
    //       mainDeviceCatAdd: 'Main DeviceCat Add',
    //       subDeviceCatAdd: 'Sub DeviceCat Add',
    //       subDeviceAdd: 'Sub DeviceAdd',
    //       mainDeviceCatCode: 'Main DeviceCat Code',
    //       mainDeviceCatName: 'Main DeviceCat Name',
    //       optMode: 'optMode',
    //       isSpecial: 'isSpecial',
    //       isEnvPort: 'isEnv Port',
    //       powerType: 'powerType',
    //       portAtt: 'PortAtt',
    //       importLevel: 'important Level',
    //       subDeviceCatCode: 'Sub DeviceCat Code',
    //       subDeviceCatName: 'Sub DeviceCat Name',
    //       adminDept: 'adminDept',
    //       connectLocation: 'connect Location',
    //       mainAndSubRelation: 'Please select a sub-device',
    //       mainDeviceName: 'Main Device Name',
    //       mainDeviceCode: 'Main Device Code',
    //       processName: 'Process Name',
    //       subDeviceCode: 'Sub Device Code',
    //       subDeviceName: 'Sub Device Name',
    //       sparepartsCode: 'Spareparts Code',
    //       part: 'part',
    //       addPart: 'addPart',
    //       sparepartsName: 'Spareparts Name',
    //       remark: 'remark',
    //       specs: 'specs',
    //       value: 'value',
    //       unit: 'unit',
    //       specsMan: 'specsMan'
    //     }
    //   },
    //   model: {
    //     modelType: {
    //       title: 'ClassificationModel',
    //       parentCode: 'parentCode',
    //       parentName: 'parentName',
    //       modelTypeCode: 'modelTypeCode',
    //       modelTypeName: 'modelTypeName',
    //       orderNum: 'orderNum',
    //       isEnable: 'isEnable',
    //       ten: 'A number up to 10 digits',
    //     },
    //     modelProperty: {
    //       modelPropertyCode: 'modelPropertyCode',
    //       modelPropertyName: 'modelPropertyName',
    //       dataType: 'dataType',
    //       variableType: 'variableType',
    //       standardPointCode: 'standardPointCode',
    //       rangeMax: 'rangeMax',
    //       rangeMin: 'rangeMin',
    //       isEnable: 'isEnable',
    //       isPush: 'isPush',
    //     },
    //     modelEvent: {
    //       modelEventCode: 'modelEventCode',
    //       modelEventName: 'modelEventName',
    //       dataType: 'dataType',
    //       variableType: 'variableType',
    //       standardPointCode: 'standardPointCode',
    //       modelEventType: 'modelEventType',
    //       isEnable: 'isEnable',
    //       isPush: 'isPush',
    //     },
    //     modelService: {
    //       modelServiceCode: 'modelServiceCode',
    //       modelServiceName: 'modelServiceName',
    //       dataType: 'dataType',
    //       variableType: 'variableType',
    //       standardPointCode: 'standardPointCode',
    //       rangeMax: 'rangeMax',
    //       rangeMin: 'rangeMin',
    //       isEnable: 'isEnable',
    //       isPush: 'isPush',
    //     },
    //     modelCalc: {
    //       selectInputParam: 'selectInputParam',
    //       modelCalcCode: 'modelCalcCode',
    //       modelCalcName: 'modelCalcName',
    //       resultDataType: 'resultDataType',
    //       calcMethod: 'calcMethod',
    //       inputParam: 'inputParam',
    //       isSave: 'isSave',
    //       isPush: 'isPush',
    //       isEnable: 'isEnable',
    //     },
    //     msg: {
    //       exportData: 'determine to export model data？',
    //       select: 'Please select model',
    //       picture: 'jpg/png model picture can only be uploaded, and no more than 500kb',
    //     },
    //     title: {
    //       title: 'model',
    //       model: "ClassificationModel",
    //     },
    //     field: {
    //       modelData: '【Data acquisition platform】Equipment model data',
    //       modelTemplate: '【Data Acquisition Platform】Equipment model import template',
    //       modelCode: 'modelCode',
    //       modelName: 'modelName',
    //       subModelCode: 'subModelCode',
    //       subModelName: 'subModelName',
    //       isEnable: 'isEnable',
    //       version: 'version',
    //       list: 'modelManagement',
    //       title: 'title',
    //       property: 'property',
    //       event: 'event',
    //       service: 'service',
    //       calc: 'calc',
    //       devicePicture: 'Model picture',
    //       modelClassify: 'ClassificationModel'
    //     },
    //   },
    //   protocol: {
    //     title: {
    //       title: 'Protocol management',
    //     },
    //     msg: {
    //       select: 'Please select an agreement',
    //     },
    //     field: {
    //       protocolCode: 'protocolCode',
    //       protocolName: 'protocolName',
    //       protocolType: 'protocolType',
    //       protocolParams: 'protocolParams',
    //       isEnable: 'isEnable',
    //       heartbeatTopic: 'heartbeatTopic'
    //     }
    //   },
    //   monitoring: {
    //     title: {
    //       title: 'DataAcquisitionMonitoring',
    //     },
    //     msg: {
    //       currenttime: 'The end time cannot be later than the current time',
    //       exceed: "The time range cannot exceed one day",
    //       exceedMonth: "The time range cannot exceed one month",
    //       template: '[Data mining platform] device data mining export template',
    //       exportMonitoring: '[Data collection platform] equipment data collection',
    //       leastOne: 'Please select at least one piece of data',
    //     },
    //     field: {
    //       second: "second",
    //       terminalCoding: 'terminalCoding',
    //       terminalName: 'terminalName',
    //       agreementName: 'agreementName',
    //       isEnable: 'isEnable',
    //       createTime: 'createTime',
    //       isStatus: 'isStatus',
    //       heartbeatTopic: 'heartbeatTopic',
    //       select: 'select',
    //       connect: 'connect',
    //       break: 'break',
    //       protocolParams: 'protocolParams',
    //       equipment: 'equipment',
    //       cardPattern: 'cardPattern',
    //       listPattern: 'listPattern',
    //       getAI: 'AI',
    //       getDI: 'DI',
    //       getAO: "AO",
    //       getDO: "DO",
    //       getST: "ST",
    //       standardPointCode: 'standardPointCode：',
    //       eventualCreateTime: 'eventualCreateTime：',
    //       ipLocation: 'ip',
    //       clsid: 'CLSID',
    //       clientId: 'clientId',
    //       deviceCode: 'deviceCode',
    //       gaugingType: 'pointCode',
    //       remark: 'remark',
    //       today: 'today',
    //       week: 'week',
    //       month: 'month',
    //       until: 'until',
    //       beginTime: 'beginTime',
    //       endTime: 'endTime',
    //       noData: 'noData',
    //       exportPoint: 'exportPoint',
    //       deviceCard: 'device',
    //       DeviceSummation: 'point',
    //       deviceName: 'deviceName',
    //       deviceEventCode: 'pointCode',
    //       stationName: 'pointNmae',
    //       stationCode: 'pointCode',
    //       variableType: 'typeVariables',
    //       modelName: 'modelName',
    //       netName: 'logical place',
    //       locationName: 'physical location',
    //       enable: 'Have been enabled',
    //       notEnabled: 'not enabled',
    //       inputParam: 'inputParam:',
    //       calcMethod: 'calcMethod:',
    //       protocolParamsSelect: 'protocolParamsSelect'
    //     },
    //     btn: {
    //       history: 'history',
    //       chart: 'chart',
    //       earlyWarning: 'earlyWarning',
    //       remove: 'removePoint',
    //       station: 'selectPoint',
    //       device: 'selectDevice',
    //       protocolParams: 'protocolParams',
    //     },
    //   },
    //   cache: {
    //     btn: {
    //       importData: "importData",
    //       exportModel: "ExportModel ",
    //       exportMonitoring: "ExportTerminal ",
    //       export: "The cache data was exported",
    //       exportPoint: "derive test point",
    //       improtDevice: "Import device data",
    //       improtPoint: "Import point data",
    //       exportDownload: "Export Device Template",
    //       download: "Template download",
    //       exportDcsTemplate: "Export template",
    //       cancel: "cancel",
    //       screening: "screeningPoint",
    //       select: "select",
    //     },
    //     cachePoint: {
    //       tree: {
    //         title: "OPC Server",
    //         dialogOPC: "OPC",
    //         serverCode: "server code",
    //         ip: "ip",
    //         clsId: "clsId",
    //         progId: "progId",
    //         username: "username",
    //         password: "password",
    //         domain: "domain",
    //         frequency: "frequency",
    //         dbPath: "cache file storage path",
    //         remark: "remark",
    //         hasChildrenWarning: "Children not deleted!",
    //       },
    //       btn: {
    //         issueBtn: "Build configuration"
    //       },
    //       title: {
    //         title: "DCS cache test point Configuration",
    //         dialogTree: "Model Classification",
    //       },
    //       msg: {
    //         select: "Please select a protocol",
    //         delivery: "Please select the delivery mode",
    //         frequency: "Please select the frequency",
    //         generateFile: "Make sure to generate the cache configuration file",
    //         addPoints: "Are you sure to add the selected point to the current OPC service?",
    //         treeCode: "Please select an OPC service on the left"
    //       },
    //       field: {
    //         deviceCode: "DeviceCode",
    //         deviceName: "DeviceName",
    //         pointCode: "Pointcode",
    //         pointName: "pointName",
    //         dataType: "dataType",
    //         gaugingType: "Gaugingtype",
    //         variableType: 'variableType',
    //         dcsCode: 'dcsCode',
    //         issueConfiguration: "deliver ",
    //         exportPointConfig: "[DCS cache point] point data configuration",
    //         template: "[DCS cache point] point configuration export template",
    //         configFile: "cache point configuration",
    //         delivery: "Delivery mode",
    //         all: "all",
    //         portion: "portion",
    //         frequency: "frequency",
    //         selectFrequency: "selectFrequency",
    //         minute: "minute"
    //       }
    //     },
    //     cacheData: {
    //       title: {
    //         title: "DCS cache data",
    //         selectWarning: "Please filter the point to query"
    //       },
    //       field: {
    //         time: "cache time",
    //         pointCode: "DCS Code",
    //         pointValue: "DCS Value",
    //         exportCacheData: "[DCS cache data] export",
    //         until: 'until',
    //         beginTime: 'beginTime',
    //         endTime: 'endTime',
    //       }
    //     }
    //   }
    // },
    // dig: {
    //   guration: {
    //     field: {
    //       notEnabled: "notEnabled",
    //       enable: "enable",
    //       name: "name",
    //       content: "content",
    //     },
    //     title: {
    //       indexHeadTitle: "ConfigurationList",
    //     },
    //   },
    // },
    // plugin: {
    //   workflow: {
    //     process: {
    //       field: {
    //         icon: "icon",
    //         name: "name",
    //         key: "key",
    //         category: "category",
    //         version: "version",
    //         status: "status",
    //         activation: "activation",
    //         pending: "pending",
    //       },
    //       title: {
    //         indexHeadTitle: "Dispatch List",
    //       }
    //     }
    //   }
    // },
    // qm: {
    //   business: {
    //     md: {
    //       codeRule: {
    //         field: {
    //           ruleCode: "ruleCode",
    //           ruleName: "ruleName",
    //           resettingCycle: "resettingCycle",
    //           num: "num",
    //           curVal: "curVal",
    //           initVal: "initVal",
    //           isEnable: "isEnable",
    //           codeRuleBusiType: "codeRuleBusiType",
    //           formtable: {
    //             paramType: "paramType",
    //             paramValType: "paramValType",
    //             paramVal: "paramVal",
    //             seq: "seq"
    //           }
    //         },
    //         title: {
    //           indexHeadTitle: "Coding Rules",
    //           addHeadTitle: "Coding RulesAdd",
    //           editHeadTitle: "Coding RulesEdit",
    //           viewHeadTitle: "Coding RulesView",
    //           formtableTitle: "Encoding composition",
    //         }
    //       },
    //       cusCar: {
    //         field: {
    //           cusCar: "cusCar",
    //           carNo: "carNo",
    //           remark: "remark",
    //           gpsSupplier: "gpsSupplier",
    //           falseness: "falseness",
    //           black_name: "black_name",
    //           companyName: "companyName",
    //           userName: "userName/userName",
    //           blackRemark: "blackRemark",
    //           createUser: "createUser",
    //           createTime: "createTime",
    //           blackUpdateUser: "blackUpdateUser",
    //           updateTime: "updateTime",
    //           lastModifiedTime: "lastModifiedTime",
    //         },
    //         title:{
    //           headTitle: "cusCar",
    //         }
    //
    //       },
    //       class: {
    //         field: {
    //           classCode: "classCode",
    //           className: "className",
    //           classCatCode: "classCatCode",
    //           classCatName: "classCatName",
    //           isEnable: "isEnable",
    //           remark: "remark",
    //           afterTip: {
    //             firstSaveData: "Please save the data in the team members first",
    //           }
    //         },
    //         title: {
    //           indexHeadTitle: "teams and groups",
    //           addHeadTitle: "teams and groupsAdd",
    //           editHeadTitle: "teams and groupsEdit",
    //           viewHeadTitle: "teams and groupsView",
    //         }
    //       },
    //       supplier: {
    //         field: {
    //           headTitle: "Supplier Basic Information",
    //           supplierCode: "supplierCode",
    //           supplierName: "supplierName",
    //           contactPerson: "contactPerson",
    //           address: "address",
    //           tel: "tel",
    //           isEnable: "isEnable",
    //           remark: "remark",
    //         },
    //         title: {
    //           indexHeadTitle: "supplier",
    //           addHeadTitle: "supplierAdd",
    //           viewHeadTitle: "supplierView",
    //           editHeadTitle: "supplierEdit",
    //         }
    //       },
    //       warehouseCfg: {
    //         field: {
    //           warehouseCode: "warehouseCode",
    //           warehouseName: "warehouseName",
    //           itemCode: "itemCode",
    //           itemName: "itemName",
    //           isEnable: "isEnable",
    //           itemCodeAndName: "itemCodeAndName",
    //           warehouseCodeAndName: "warehouseCodeAndName",
    //         },
    //         title:{
    //           indexHeadTitle: "One Card Warehouse Configuration",
    //         }
    //       },
    //       itemWarehouse: {
    //         field: {
    //           warehouseCodeAndName: "warehouseCodeAndName",
    //           warehouseName: "warehouseName",
    //           warehouseCode: "warehouseCode",
    //           stockInOut: "stockInOut",
    //           stockDataSource: "stockDataSource",
    //           stockDcs: "stockDcs",
    //           remark: "remark",
    //           isEnable: "isEnable",
    //           itemWarehouseHeadTitle: "itemWarehouseHeadTitle",
    //         },
    //         title:{
    //           indexHeadTitle: "material warehouse",
    //         }
    //       },
    //       extItemCate: {
    //         field: {
    //           headTitle: "ERP Material Management",
    //           headCatTitle: "ERP material classification",
    //           pleaseInput: "pleaseInput",
    //           parentCode: "parentCode",
    //           parentName: "parentName",
    //           extItemName: "extItemName",
    //           extItemCode: "extItemCode",
    //           extItemCatCode: "extItemCatCode",
    //           extItemCatName: "extItemCatName",
    //           isEnable: "isEnable",
    //           remark: "remark",
    //           itemCateId: "itemCateId",
    //           unitCode: "unitCode",
    //           unitName: "unitName",
    //           hasChildren: "hasChildren!",
    //           afterTip: {
    //             unitError: "Error in querying unit information！",
    //           }
    //         },
    //         title: {
    //           indexHeadTitle: "ERP Material Management",
    //           addHeadTitle: "ERP Material ManagementAdd",
    //           viewHeadTitle: "ERP Material ManagementView",
    //           editHeadTitle: "ERP Material ManagementEdit",
    //         },
    //       },
    //       deviceFaultCat: {
    //         field: {
    //           headTitle: "Shutdown Reason Management",
    //           pleaseInput: "Please Input",
    //           parentCode: "parentCode",
    //           deviceFaultDetailName: "deviceFaultDetailName",
    //           deviceFaultDetailCode: "deviceFaultDetailCode",
    //           deviceFaultCatCode: "deviceFaultCatCode",
    //           deviceFaultCatName: "deviceFaultCatName",
    //           isEnable: "isEnable",
    //           remark: "remark",
    //           hasChildren: "hasChildren",
    //           afterTip: {
    //             unitError: "unitError",
    //           }
    //         },
    //         title: {
    //           indexHeadTitle: "Shutdown Reason",
    //           addHeadTitle: "Shutdown Reason Add",
    //           viewHeadTitle: "Shutdown Reason View",
    //           editHeadTitle: "Shutdown Reason Edit",
    //           headCatTitle: "Shutdown Reason Type",
    //         },
    //       },
    //       customer: {
    //         field: {
    //           customerName: "customerName",
    //           customerCode: "customerCode",
    //           address: "address",
    //           contactPerson: "contactPerson",
    //           tel: "tel",
    //           isEnable: "isEnable",
    //           remark: "remark",
    //           tip_determine_del: "Netty confirms to delete the selected data?",
    //         },
    //         title: {
    //           indexHeadTitle: "custom",
    //           headTitle: "custom",
    //           addHeadTitle: "customAdd",
    //           viewHeadTitle: "customView",
    //           editHeadTitle: "customEdit",
    //         },
    //       },
    //       item: {
    //         field: {
    //           cateHeadTitle: "Production Material Classification",
    //           parentCode: "parentCode",
    //           parentName: "parentName",
    //           labelText: "labelText",
    //           superiorName: "superiorName",
    //           list: "list",
    //           name: "name",
    //           code: "code",
    //           norms: "norms",
    //           model: "model",
    //           charNo: "charNo",
    //           unit: "unit",
    //           uname: "uname",
    //           idCard: "idCard",
    //           black_name: "black_name",
    //           black_name_remark: "black_name_remark",
    //           phone: "phone",
    //           wechat: "wechat",
    //           itemName: "itemName",
    //           itemCode: "itemCode",
    //           itemCatId: "itemCatId",
    //           itemCatCode: "itemCatCode",
    //           itemCatName: "itemCatName",
    //           processName: "processName",
    //           processCode: "processCode",
    //           isEnable: "isEnable",
    //           isProduct: "isProduct",
    //           remark: "remark",
    //           itemCateId: "itemCateId",
    //           unitCode: "unitCode",
    //           unitName: "unitName",
    //         },
    //         title: {
    //           indexHeadTitle: "Production materials",
    //           addHeadTitle: "Production materialsAdd",
    //           viewHeadTitle: "Production materialsView",
    //           editHeadTitle: "Production materialsEdit"
    //         },
    //         msg: {
    //           itemErr: "Material cannot be duplicate, please reselect!",
    //         }
    //       },
    //     },
    //     qm: {
    //       dispatch:{
    //         title:{
    //           indexHeadTitle: 'Quality dispatch Form',
    //           headTitleEdit: 'Quality dispatch Form Edit',
    //           headTitleDetail: 'Quality dispatch Form Detail',
    //           receive:'receive',
    //           hedaTitleAdd:'Quality dispatch Form Add',
    //           headTitleSubTitle: 'Dispatch Form Detail',
    //           selectItem: 'Select Item'
    //         },
    //         tip: 'Please select the data in preparation',
    //         tipRequired: 'Please fill in the required information',
    //         columns:{
    //           dispatchCode:'dispatchCode',
    //           startSendTimeQuery:'sendTimeQueryStart',
    //           endSendTimeQuery:'sendTimeQueryEnd',
    //           startAssertTimeQuery:'assertTimeQueryStart',
    //           endAssertTimeQuery:'assertTimeQueryEnd',
    //           dispatchStatus:'dispatchStatus',
    //           sendTime: 'send Time',
    //           assertTime: 'assert Time',
    //           sendName: 'send Name',
    //           reviceDept: 'revice Dept',
    //           reviceName: 'revice Name',
    //           confirmTime: 'confirm Time',
    //           Automatically :'Automatically generate after saving',
    //           SchedulingContent:'Scheduling Content',
    //         },
    //         discharge: {
    //           extItemName:' Material name ',
    //           dischargePosition: 'Discharge position ',
    //           controlExplain:' Control Description'
    //         },
    //         factoryMixRatio: {
    //           headTitle: 'Product indicator Control ',
    //           itemName:' Product ',
    //           outboundProportion:' Outbound proportion ',
    //           controlInstruct:' Control Description ',
    //         },
    //         incomingMaterials: {
    //           supplierName:' Supplier ',
    //           timeStr:' Entry time ',
    //           batchCode:' Batch number ',
    //           totalWeight:' incoming volume '
    //         },
    //         MaterialRatio: {
    //           itemName:'raw material',
    //           feedingproportion :'proportion',
    //           feedingInstruct:'Feeding instructions'
    //         },
    //         qualityControl:{
    //           productIndex: 'Product Indicator Control ',
    //           indexName:' Indicator name ',
    //           indexCode:' Indicator code ',
    //           teamName:' Quality Control team ',
    //           indexClass:' index classification ',
    //           conditionValue:' condition ',
    //           targetValue:' target value ',
    //           floatingRange:' float range ',
    //           controlInstruct:' Control Description ',
    //           deviceCode:' Device number ',
    //           itemCode:' Production material ',
    //           storageLocation:' storage location '
    //         }
    //       },
    //       dispatchModel:{
    //         title:{
    //           indexHeadTitle: 'Dispatch Form Template',
    //           hedaTitleAdd: 'Adding a dispatch order template',
    //           headTitleEdit: 'Schedule template editing',
    //           headTitleDetail: 'Schedule template details',
    //         },
    //         columns:{
    //           modelName: 'Template name',
    //           modelCode: 'Template Code',
    //           dispatchType: 'Dispatch order type',
    //           updateBy: "Last modified Time",
    //           basicInfo: 'Basic information',
    //           detailInfo: 'Details',
    //           materialRatio: 'Material ratio'
    //         }
    //       },
    //       param: {
    //         field: {
    //           indexParamCode: "indexParamCode",
    //           indexParamName: "indexParamName",
    //           indexDataType: "indexDataType",
    //           indexDecimalDigit: "indexDecimalDigit",
    //           unitCode: "unitCode",
    //           unitName: "unitName",
    //           isEnable: "isEnable",
    //           remark: "remark",
    //         },
    //         title: {
    //           indexHeadTitle: "Indicator parameters",
    //           title: "index formulas",
    //           addHeadTitle: "Indicator parametersAdd",
    //           editHeadTitle: "Indicator parametersEdit",
    //           viewHeadTitle: "Indicator parametersView",
    //         }
    //       },
    //       indexFormula: {
    //         field: {
    //           formulaCode: "formulaCode",
    //           formulaName: "formulaName",
    //           formulaDesc: "formulaDesc",
    //           remark: "remark",
    //           paramCode: "paramCode",
    //           paramName: "paramName",
    //           paramDefaultVal: "paramDefaultVal",
    //           formulaParam: "formulaParam",
    //           add: "add",
    //           indexCode: "indexCode",
    //           indexName: "indexName",
    //           indexFormulaCode: "indexFormulaCode",
    //           indexFormulaName: "indexFormulaName",
    //           indexFormulaDesc: "indexFormulaDesc",
    //           indexFormulaButton: "indexFormulaButton",
    //         },
    //         title: {
    //           indexHeadTitle: "index formulas",
    //           addHeadTitle: "index formulasAdd",
    //           editHeadTitle: "index formulasEdit",
    //           viewHeadTitle: "index formulasView",
    //         }
    //       },
    //       templateIndex: {
    //         field: {
    //           templateCode: "templateCode",
    //           templateName: "templateName",
    //           teamCode: "teamCode",
    //           teamName: "teamName",
    //           indexCode: "indexCode",
    //           indexName: "indexName",
    //           unitCode: "unitCode",
    //           unitName: "unitName",
    //           limitRange: "limitRange",
    //           sortNum: "sortNum",
    //           indexFormulaCode: "indexFormulaCode",
    //           indexFormulaName: "indexFormulaName",
    //           indexFormulaDesc: "indexFormulaDesc",
    //           remark: "remark",
    //           select_warning: "select_warning",
    //           afterTip: {
    //             defaultFormulaExist: "defaultFormulaExist",
    //           }
    //         },
    //         title: {
    //           indexHeadTitle: "Quality inspection template indicators",
    //           addHeadTitle: "Quality inspection template indicatorsAdd",
    //           editHeadTitle: "Quality inspection template indicatorsEdit",
    //           viewHeadTitle: "Quality inspection template indicatorsView",
    //         }
    //       },
    //       index: {
    //         field: {
    //           indexCode: "indexCode",
    //           indexName: "indexName",
    //           unitCode: "unitCode",
    //           unitName: "unitName",
    //           teamCode: "teamCode",
    //           indexColumnCode: 'indexColumnCode',
    //           teamName: "teamName",
    //           enableFormula: "enableFormula",
    //           isEnable: "isEnable",
    //           remark: "remark",
    //         },
    //         title: {
    //           headTitle: "project information",
    //           indexHeadTitle: "Quality inspection indicators",
    //           addHeadTitle: "Quality inspection indicatorsAdd",
    //           editHeadTitle: "Quality inspection indicatorsEdit",
    //           viewHeadTitle: "Quality inspection indicatorsView",
    //         }
    //       },
    //       origIndex: {
    //         field: {
    //           indexCode: "indexCode",
    //           indexName: "indexName",
    //           indexFormulaCode: "indexFormulaCode",
    //           indexFormulaName: "indexFormulaName",
    //           paramCode: "paramCode",
    //           paramName: "paramName",
    //           origDataType: "origDataType",
    //           remark: "remark",
    //         },
    //         title: {
    //           indexHeadTitle: "Template parameters",
    //           addHeadTitle: "Template parametersAdd",
    //           editHeadTitle: "Template parametersEdit",
    //           viewHeadTitle: "Template parametersView",
    //         }
    //       },
    //       qmTeamMember: {
    //         field: {
    //           teamCode: "teamCode",
    //           teamName: "teamName",
    //           userCode: "userCode",
    //           userName: "userName",
    //           isGroupLeader: "isGroupLeader",
    //           message: "Cannot select repeatedly",
    //         },
    //         title: {
    //           indexHeadTitle: "Quality control team",
    //           headTitle: "Quality Inspection Personnel List",
    //           teamIndexTitle: "Quality inspection indicators",
    //           teamMemberTitle: "Quality inspection personnel",
    //         }
    //       },
    //       qmTeam: {
    //         field: {
    //           teamCode: "teamCode",
    //           teamName: "teamName",
    //           parentName: "teamName",
    //           isEnable: "isEnable",
    //           remark: "remark",
    //           team: "Please select a group first",
    //           tip_determine_del: "This quality inspection group has associated information. Are you sure to delete this quality inspection group and associated information?",
    //         },
    //         title: {
    //           indexHeadTitle: "Quality inspection team",
    //           addHeadTitle: "Quality inspection teamAdd",
    //           editHeadTitle: "Quality inspection teamEdit",
    //           viewHeadTitle: "Quality inspection teamView",
    //           teamMemberTitle: "Quality inspection personnel",
    //         }
    //       },
    //       qmTeamIndex: {
    //         field: {
    //           teamCode: "teamCode",
    //           teamName: "teamName",
    //           indexCode: "indexCode",
    //           indexName: "indexName",
    //           unitCode: "unitCode",
    //           unitName: "unitName",
    //         },
    //         title: {
    //           indexHeadTitle: "List of quality inspection indicators",
    //           teamIndexTitle: "Quality inspection indicators",
    //         }
    //       },
    //       point: {
    //         field: {
    //           labelText: "Add sampling point classification",
    //           parentCode: "parentCode",
    //           parentName: "parentName",
    //           pointCode: "pointCode",
    //           pointName: "pointName",
    //           pointCatCode: "pointCatCode",
    //           pointCatName: "pointCatName",
    //           isEnable: "isEnable",
    //           remark: "remark",
    //           equipCode: "equipCode",
    //           equipName: "equipName",
    //           select_warning: "Please save the basic information first",
    //         },
    //         title: {
    //           indexHeadTitle: "Sampling point",
    //           pointClass: "pointClass",
    //           addHeadTitle: "Sampling pointAdd",
    //           editHeadTitle: "Sampling pointEdit",
    //           viewHeadTitle: "Sampling pointView",
    //         },
    //       },
    //       recordObj: {
    //         field: {
    //           recordObjCode: "recordObjCode",
    //           recordObjName: "recordObjName",
    //           qualityType: "qualityType",
    //           itemName: "itemName",
    //           templateName: "templateName",
    //           pointName: "pointName",
    //           pointCatName: "pointCatName",
    //           add: "add",
    //           sampleCodeRule: "sampleCodeRule",
    //           recordCodeRule: "recordCodeRule",
    //           batchRule: "batchRule",
    //           targetType: "targetType",
    //           packageFlag: "packageFlag",
    //           batchSplitName: "batchSplitName",
    //           batchCodeRule: "batchCodeRule",
    //           objIndex: "objIndex",
    //           standardType: "standardType",
    //           formulaName: "formulaName",
    //           isMajorIndex: "isMajorIndex",
    //           objIndexConfig: "objIndexConfig",
    //           splitSidesType: "splitSidesType",
    //           color: "color",
    //           copyRecordObj: 'copyRecordObj',
    //         },
    //         title: {
    //           headTitle: "Production materials",
    //           indexHeadTitle: "Quality inspection object",
    //           addHeadTitle: "Quality inspection objectAdd",
    //           editHeadTitle: "Quality inspection objectEdit",
    //           viewHeadTitle: "Quality inspection objectView",
    //         },
    //         msg: {
    //           conflict: "Batch cutting method cannot be repeated!",
    //         }
    //       },
    //       itemIndex: {
    //         field: {
    //           recordCode: "recordCode",
    //           item: "item",
    //           itemCode: "itemCode",
    //           itemName: "itemName",
    //           indexCode: "indexCode",
    //           indexName: "indexName",
    //           teamCode: "teamCode",
    //           teamName: "teamName",
    //           unitName: "unitName",
    //           itemIndexFormula: "itemIndexFormula",
    //           itemIndexStandard: "itemIndexStandard",
    //           isDefault: "isDefault",
    //           standardType: "standardType",
    //           standardContent: "standardContent",
    //           indexClass: "indexClass",
    //           standardTypeOnly: "standardTypeOnly",
    //           sortNum: 'sortNum',
    //           warnCfg: "warnCfg",
    //         },
    //         title: {
    //           indexHeadTitle: "Material indicator information",
    //         },
    //       },
    //       warnCfg: {
    //         field: {
    //           consecutiveNum: 'consecutiveNum',
    //           upperLimit: "upperLimit",
    //           lowerLimit: "lowerLimit",
    //           isLimit: "isLimit",
    //           qualifiedRange: 'qualifiedRange',
    //           warningScope: 'warningScope',
    //           isConsecutive: "isConsecutive",
    //           isDefault: "isDefault",
    //         },
    //       },
    //
    //       batchSplit: {
    //         field: {
    //           baseInfo: "baseInfo",
    //           batchSplitCode: "batchSplitCode",
    //           batchSplitName: "batchSplitName",
    //           isQualityCfg: "isQualityCfg",
    //         },
    //         title: {
    //           indexHeadTitle: "Batch cutting rule settings",
    //           addHeadTitle: "Batch cutting rule settingsAdd",
    //           editHeadTitle: "Batch cutting rule settingsEdit",
    //           viewHeadTitle: "Batch cutting rule settingsView",
    //         }
    //       },
    //       batchSplitParam: {
    //         field: {
    //           modeType: "modeType",
    //           modeType2: "modeType2",
    //           param: "param",
    //           errorModeType: "Cycle (team hours), cut a batch every XX hours and cannot exceed 24",
    //           tip: {
    //             notNull: "notNull!",
    //             nonRepeatable: "nonRepeatable!"
    //           }
    //         },
    //         title: {
    //           title: "Batch cutting rule",
    //           title2: "Sampling rules",
    //         }
    //       },
    //       batch: {
    //         field: {
    //           baseInfo: "baseInfo",
    //           batchCode: "batchCode",
    //           qualityType: "qualityType",
    //           customerCode: "customerCode",
    //           customerName: "customerName",
    //           miningName: "miningName",
    //           itemCode: "itemCode",
    //           itemName: "itemName",
    //           extItemCode: "extItemCode",
    //           extItemName: "extItemName",
    //           sumWeight: "sumWeight",
    //           sumNum: "sumNum",
    //           sumSampleNum: "sumSampleNum",
    //           firstAcceptDate: "firstAcceptDate",
    //           lastAcceptDate: "lastAcceptDate",
    //           remark: "remark",
    //           status: "status",
    //         },
    //         title: {
    //           indexHeadTitle: "Batch Information",
    //           addHeadTitle: "Batch InformationAdd",
    //           editHeadTitle: "Batch InformationEdit",
    //           viewHeadTitle: "Batch InformationView",
    //           oldBatchCode: "Current batch code",
    //           newBatchCode: "New batch code",
    //           newBatchCodeBlank: "The new batch code cannot be empty",
    //           editBatchCode: "Edit batch code",
    //         }
    //       },
    //       sampleLine: {
    //         field: {
    //           bzCode: "一卡通编码",
    //           batchCode: "批次编号",
    //           sampleCode: "sampleCode",
    //           sampleBatchCode: "sampleBatchCode",
    //           carNo: "carNo",
    //           extItemName: "extItemName",
    //           extItemCode: "extItemCode",
    //         },
    //         title: {
    //           indexHeadTitle: "One Card Information",
    //         }
    //       },
    //       sample: {
    //         field: {
    //           sealStatus: 'sealStatus',
    //           sealDate: 'sealDate',
    //           itemCodeAndName: "itemCodeAndName",
    //           pointCodeAndName: "pointCodeAndName",
    //           sampleCode: "sampleCode",
    //           qualityType: "qualityType",
    //           batchDtlCode: "batchDtlCode",
    //           inspectionType: "inspectionType",
    //           recordObjCode: "recordObjCode",
    //           status: "status",
    //           sampleDate: "sampleDate",
    //           userName: "userName",
    //           sampleType: "sampleType",
    //           pointCode: "pointCode",
    //           pointName: "pointName",
    //           equipCode: "equipCode",
    //           equipName: "equipName",
    //           itemCode: "itemCode",
    //           isRecord: "isRecord",
    //           itemName: "itemName",
    //           remark: "remark",
    //           bzCode: "oneCardCode",
    //           batchCode: "batchCode",
    //           carNo: "carNo",
    //           extItemCode: "extItemCode",
    //           extItemName: "extItemName",
    //           customerCode: "customerCode",
    //           customerName: "customerName",
    //           recordObj: "recordObj",
    //           projectName: "projectName",
    //           periodicSpline: "periodicSpline",
    //           picture: "picture",
    //           proportionScheme: "proportion Scheme",
    //           materialCode: "material Code",
    //           rawMaterial: "material Name",
    //           ratio: "ratio",
    //           pictureType: "picture Type",
    //           literalness: "literalness",
    //           pictureUpload: "picture Upload",
    //           sumWeight: 'sumWeight',
    //           afterTip: {
    //             qualityTypeExist: "Select the inspection type first",
    //             qmRecordObjUnExist: "The quality inspection object does not exist",
    //             qmRecordObjIndexUnExist: "Quality inspection indicator does not exist",
    //             qmRecordObjFormulaUnExist: "The quality inspection indicator formula does not exist",
    //             qmRecordObjStandardUnExist: "Indicator standard does not exist",
    //             isNotPeriodType: "The current quality inspection object is not a cycle sample",
    //             dataHasExist: "The quality inspection record has been generated",
    //             mdItemUnExist: "Production material does not exist",
    //           }
    //         },
    //         title: {
    //           save: "Save the information first",
    //           selectBzCode: "Please select the card information",
    //           selectRecordObj: "Select the inspection type",
    //           indexHeadTitle: "Sample Management",
    //           recordHeadTitle: "Quality inspection report sheet",
    //           sampleRecondTitle: "Sample Record",
    //           labelText: "Add sampling point classification",
    //           viewHeadTitle: "Sample Management View",
    //           addHeadTitle: "Sample Management Add",
    //           editHeadTitle: "Sample Management Edit",
    //           sampleRecondViewTitle: "Sample Record View",
    //           sampleRecondAddTitle: "Sample Record Add",
    //           sampleRecondEditTitle: "Sample Record Edit",
    //           oneCardInfoTitle: "One Card Information",
    //           ProportioningScheme: "ProportioningScheme",
    //           issue: " This status has been issued and cannot be changed",
    //           oneCardTitle: "One Card Materials",
    //         },
    //         btn: {
    //           nonQualityInspectionBtn: "nonQualityInspection",
    //           issuingQualityInspectionBtn: "issuingQualityInspection",
    //         },
    //         msg: {
    //           nonDraft: "Sample records containing non draft status cannot be deleted",
    //         },
    //       },
    //       record: {
    //         field: {
    //           time: "time",
    //           recordDate: "recordDate",
    //           recordStartDate: "recordStartDate",
    //           recordEndDate: "recordEndDate",
    //           equipCode: "equipCode",
    //           equipName: "equipName",
    //           batchCode: "batchCode",
    //           status: "status",
    //           operate: "operate",
    //           recordCode: "recordCode",
    //           sampleCode: "sampleCode",
    //           templateCode: "templateCode",
    //           sampleBatchCode: "sampleBatchCode",
    //           sampleType: "sampleType",
    //           samplePointCode: "samplePointCode",
    //           samplePointCatName: "samplePointCatName",
    //           itemCode: "itemCode",
    //           itemName: "itemName",
    //           pointName: "pointName",
    //           qualityType: "qualityType",
    //           recordObjCode: "recordObjCode",
    //           teamName: "teamName",
    //           remark: "remark",
    //           teamCode: "teamCode",
    //           recordIndex: 'recordIndex',
    //           picture: 'Quality inspection picture',
    //           recordStartYear: "Summary start time",
    //           recordEndYear: "Summary end time",
    //           recordEndHour: "Summary closing hour",
    //           recordEndAndrecordStart: "The start time or end time of the summary cannot be empty",
    //           recordEndAndrecordDate: "The start time or end time of the summary cannot be later than the time of the current inspection object",
    //           CollectRecordCode: "Quality inspection code",
    //           tip: {
    //             noUpdate: "Quality inspection indicator information not modified!",
    //           }
    //         },
    //         title: {
    //           seal: "seal",
    //           handle: "处理",
    //           indexHeadTitle: "Quality inspection records",
    //           addHeadTitle: "Quality inspection recordsAdd",
    //           editHeadTitle: "Quality inspection recordsEdit",
    //           viewHeadTitle: "Quality inspection recordsView",
    //         },
    //         msg: {
    //           prompt: "Please click on the tree on the left to select a specific category",
    //         }
    //       },
    //       stockCheckLine: {
    //         field: {
    //           locationName: "locationName",
    //           formulaText: "formulaText",
    //           stockCheckQty: "stockCheckQty",
    //           cal: "cal",
    //           index: "index",
    //           code: "code",
    //           name: "name",
    //           defVal: "defVal",
    //           value: "value",
    //           paramCode: "paramCode",
    //           paramName: "paramName",
    //           paramValue: "paramValue",
    //         }
    //       },
    //       // 周期质检
    //       periodQualityTest: {
    //         field: {
    //           teamCode: "teamCode",
    //           teamName: "teamName",
    //         },
    //       },
    //       recordIndex: {
    //         field: {
    //           sampleCode: "sampleCode",
    //           indexCode: "indexCode",
    //           indexName: "indexName",
    //           indexValue: "indexValue",
    //           standardType: "standardType",
    //           standardContent: "standardContent",
    //           temIndexStandard: "temIndexStandard",
    //           indexAdjValue: "indexAdjValue",
    //           limitRange: "limitRange",
    //           isQualified: "isQualified",
    //           select_warning: "select_warning",
    //           indexFormulaDesc: "indexFormulaDesc",
    //           indexFormulaName: "indexFormulaName",
    //           indexValueCal: "indexValueCal",
    //           userName: "userName",
    //           updateTime: "updateTime",
    //         },
    //         title: {
    //           infoTitle: "Quality inspection indicator information",
    //           listTitle: "List of quality inspection indicators",
    //         }
    //       },
    //       device: {
    //         file: {
    //           deviceName: "Device name",
    //           deviceCode: "Device code",
    //           deviceInfo: "Remark"
    //         },
    //         title: {
    //           deviceTitle: "Quality inspection equipment",
    //           add: "Quality inspection equipment add",
    //           edit: "Quality inspection equipment edit"
    //         }
    //       },
    //       recordV2: {
    //         tip: {
    //           del_success: "Successfully deleted",
    //           back_success: "Successfully returned",
    //           submit_success: "Submitted successfully",
    //           save_success: "Successfully saved",
    //           no_update: "The quality inspection record has not been modified!",
    //         },
    //         confirm: {
    //           del: "Delete quality inspection records?",
    //           back: "Whether to return the quality inspection record?",
    //           submit: "Whether to submit quality inspection records?",
    //         }
    //       },
    //       stockQyt: {
    //         file: {
    //           itemCode: "itemCode",
    //           itemName: "itemName",
    //           unitCode: "unitCode",
    //           unitName: "unitName",
    //           warehouseCode: "warehouseCode",
    //           warehouseName: "warehouseName",
    //           stockQty: "stockQty",
    //         },
    //         title: {
    //           title: "Warehouse Information",
    //           headTitle: "Quality inspection equipment",
    //           headListTitle: "Newly added quality inspection equipment",
    //         }
    //       },
    //       template: {
    //         field: {
    //           templateCode: "templateCode",
    //           templateName: "templateName",
    //           itemCode: "itemCode",
    //           itemName: "itemName",
    //           isEnable: "isEnable",
    //           remark: "remark",
    //           isShi: "isShi",
    //           isFou: "isFou"
    //         },
    //         title: {
    //           indexHeadTitle: "Quality inspection template",
    //         }
    //       },
    //       arrivalLine: {
    //         field: {
    //           itemCode: "itemCode",
    //           itemName: "itemName",
    //           norms: "norms",
    //           model: "model",
    //           chartNo: "chartNo",
    //           unit: "unit",
    //           deliveryQuantity: "deliveryQuantity",
    //           arrivalQuantity: "arrivalQuantity",
    //           batchNo: "batchNo",
    //           arrivalDate: "arrivalDate",
    //           stockOrgName: "stockOrgName",
    //           warehouseName: "warehouseName",
    //           locationName: "locationName",
    //           poundNo: "poundNo",
    //           tareWeight: "tareWeight",
    //           grossWeight: "grossWeight",
    //           originPlace: "originPlace",
    //           carNo: "carNo",
    //         },
    //       },
    //     }
    //   }
    // },
    // base: {
    //   md: {
    //     unit: {
    //       field: {
    //         unitCode: "unitCode",
    //         unitName: "unitName",
    //         isEnable: "isEnable",
    //         remark: "remark",
    //       },
    //       title: {
    //         indexHeadTitle: "Units Manager",
    //         addHeadTitle: "Units ManagerAdd",
    //         editHeadTitle: "Units ManagerEdit",
    //         viewHeadTitle: "Units ManagerView",
    //       }
    //     },
    //     period: {
    //       field: {
    //         periodCode: "periodCode",
    //         year: "year",
    //         month: "month",
    //         startDate: "startDate",
    //         endDate: "endDate",
    //         isEnable: "isEnable",
    //         periodDate: "periodDate",
    //         generateDate: "generateDate",
    //         viewDate: "viewDate",
    //         dateTitle: "dateTitle",
    //         detail: "detail",
    //         select_warning: "Please select the period information that needs to be saved",
    //         year_blank: "year_blank",
    //         month_blank: "month_blank",
    //         start_blank: "start_blank",
    //         start_end: "The end date cannot be less than the start date",
    //         end_blank: "end_blank",
    //         // isEnable_blank: "Enable cannot be empty",
    //         date_blank: "Period date cannot be empty",
    //       },
    //       title: {
    //         indexHeadTitle: "Period management",
    //       },
    //       msg: {
    //         checkPeriod: "Prohibit saving duplicate interval time!",
    //         checkPeriodDate: "Date has been generated, cannot repeat operation!",
    //       }
    //     },
    //     fgp: {
    //       field: {
    //         startDate: "startDate",
    //         endDate: "endDate",
    //         remark: "remark",
    //         add_warning: "Please save the basic information first",
    //         start_blank: "start_blank",
    //         end_blank: "end_blank",
    //       },
    //       title: {
    //         baseTitle: "Basic information",
    //         indexHeadTitle: "Peak valley flat list",
    //         addHeadTitle: "Peak valley flat listAdd",
    //         editHeadTitle: "Peak valley flat listEdit",
    //         viewHeadTitle: "Peak valley flat listView",
    //       },
    //     },
    //     fgpLine: {
    //       field: {
    //         fgpType: "fgpType",
    //         startTime: "startTime",
    //         endTime: "endTime",
    //         price: "price",
    //         remark: "remark",
    //         select_warning: "Please select the peak valley level settings that need to be saved",
    //         type_blank: "Peak valley level type cannot be empty",
    //         start_blank: "Start time cannot be empty",
    //         end_blank: "Start time cannot be empty",
    //         price_blank: "price_blank",
    //         afterTip: {
    //           checkPeriod: "Peak valley average time is not a continuous 24-hour period on the same day. Please modify it before saving!",
    //         }
    //       },
    //       title: {
    //         indexHeadTitle: "Peak valley average time",
    //         addHeadTitle: "Peak valley average timeAdd",
    //         editHeadTitle: "Peak valley average timeEdit",
    //         viewHeadTitle: "Peak valley average timeView",
    //       }
    //     },
    //     param: {
    //       field: {
    //         paramCode: "paramCode",
    //         paramName: "paramName",
    //         paramValue: "paramValue",
    //         remark: "remark",
    //       },
    //       title: {
    //         indexHeadTitle: "parameter management",
    //         addHeadTitle: "parameter managementAdd",
    //         editHeadTitle: "parameter managementEdit",
    //         viewHeadTitle: "parameter managementView",
    //       },
    //       msg: {
    //         hiddenTrouble: "The parameter value is a two decimal place range from 0.00 to 1.00, with padding allowed",
    //       },
    //     },
    //   },
    // },
    //数据中台
    dc: {
      //接口系统配置
      InterfaceSystem: {
        title: {
          InterfaceSystemConfiguration: "InterfaceSystemConfiguration",
          NewInterfaceSystemConfiguration: "NewInterfaceSystemConfiguration",
        },
        field: {
          isToken: "WhetherToMaintainTokenInformation",
          isToken2: "TokenMaintained",
          TokenInformation: "TokenInformation",
          AddRequestBody: "AddRequestBody",
          yes: "yes",
          no: "no"


        }
      },
      //全量接口采集
      FullInter: {
        title: {
          name: "FullInterfaceAcquisition",
          name2: "AddFullInterfaceAcquisition",
        },

        field: {
          InterfaceName: "InterfaceName",
          InterfaceAddress: "InterfaceAddress",
          RequestMethod: "RequestMethod",
          ReturnStatusCode: "ReturnStatusCode",
          ReturnStatusValue: "ReturnStatusValue",
          PrimaryKeyName: "PrimaryKeyName",
          ResultConfiguration: "ResultConfiguration",
          InterfaceCode: "InterfaceCode",
          TopicConfiguration: "TopicConfiguration",
          SystemSourceID: "SystemSourceID",
          ForeignKeyCode: "ForeignKeyCode",
          CreationTime: "CreationTime",
          ModificationTime: "ModificationTime",
          InterfaceDescription: "InterfaceDescription",
        },
        msg: {
          msg1: "Request parameters",
          msg2: "Please select type",
        }
      },
      //增量接口采集
      zljkcj: {
        title: {
          name: "IncrementalInterfaceAcquisition",
          name2: "AddIncrementalInterfaceAcquisition",
        },

        field: {
          InterfaceCode: "InterfaceCode",
          InterfaceName: "InterfaceName",
          InterfaceAddress: "InterfaceAddress",
          RequestMethod: "RequestMethod",
          AccessMode: "AccessMode",
          dataType: "dataType",
          StartTimeField: "StartTimeField",
          EndTimeField: "EndTimeField",
          IncrementalTimestamp: "IncrementalTimestamp",
          IncreaseStepLengthTime: "IncreaseStepLengthTime",
          IncrementalTimeType: "IncrementalTimeType",
          IncrementalTimeTypeFormat: "IncrementalTimeTypeFormat",
          StepLengthdays: "StepLengthdays",
          ReturnDataItem: "ReturnDataItem",
          ReturnStatusCode: "ReturnStatusCode",
          ReturnDescription: "ReturnDescription",
          PrimarykeyField: "PrimarykeyField",
          SourceSystem: "SourceSystem",
          TokenField: "TokenField",
          SourceDatabaseInformation: "SourceDatabaseInformation",

          RequestHeader: "RequestHeader",
          AddRequestHeader: "AddRequestHeader",
          TimeTypeFormat: "TimeTypeFormat",
          increment: "increment",
          Addsteptimestamp: 'Addsteptimestamp',
          Interfaceconfigurationinformation: "Interfaceconfigurationinformation",
          Endtimefield: "Endtimefield",
          Updatetimefield: "Updatetimefield",
          Timetype: "Timetype",
          Timestamptype: "Timestamptype",
          Maximumtimestamp: "Maximumtimestamp",
          Sourcedatabase: "Sourcedatabase",
          constant: "constant",
          parameter: "parameter",
          Requestor: "Requestor",
          type: "type",
          startTime: 'startTime',
          endTime: "endTime",
          Updatetime: "Updatetime",




        },
        msg: {
          msg1: "Please enter interface code/interface name"
        }
      },
      //Token刷新配置
      Token: {
        msg: "Save sucess",
        title: {
          name: "Tokenrefreshconfiguration",
          Addname: "AddTokenrefreshconfiguration",
        },

        field: {
          Interfacecode: "Interfacecode",
          Url: "Url",
          Requestinformation: "Requestinformation",
          RequestMethod: "RequestMethod",
          Splicinginformation: "Splicinginformation",
          Fieldname: "Fieldname",
          Datasourceid: "Datasourceid",
          Tokensourceid: "Tokensourceid",
          operation: "operation",
          RequestHeader: "Requestheader",
          Addrequestheader: "Addrequestheader",
          edit: "edit",
          Interfaceaddress: "Interfaceaddress",
          baseInfo: 'baseInfo'
        },



      },

      //离线数据采集
      OffCollection: {
        title: {
          name: "OfflinedataCollection",
        },

        field: {
          step: "step",
          structure: "structure",
          FieldMapping: "FieldMapping",
          Datasynchronization: "Datasynchronization",
          Databasename: "Databasename",
          Datatablename: "Datatablename",
          SQLstatement: "SQLstatement",
          Resolvefield: "Resolvefield",
          Shardfield: "Shardfield",
          TableAllFields: "TableAllFields",
          SelectAll: "SelectAll",
          Wherecondition: "Wherecondition",
          PrecedingSQLstatement: "PrecedingSQLstatement",
          PostSQLstatement: "PostSQLstatement",
          Sourcefield: "Sourcefield",
          Targetfield: "Targetfield",
          Startsynchronization: "Startsynchronization",
          start: "start",
          fill: "fill",
          Previousstep: "Previousstep",
          nextstep: "nextstep",
        },
        msg: {
          msg1: "Please select a database name!"
        },
      },

      // EXCEL数据导入
      ExcelDataImport: {
        btn: {
          SelectFile: "Select File",
          SetStartLine: "Set Start Line",
          ConfigureImportTable: "Configure Import Table",
          SetImportFields: "Set Import Fields",
          SetImportMode: "Set Import Mode",
          StartImporting: "Start Importing"
        },
        field: {
          PleaseSelectDatabase: "PleaseSelectDatabase",
          ImportFrom: "ImportFrom",
          ClickToUpload: "ClickToUpload",
          Surface: "Surface",

          ColumnNameLine: "ColumnNameLine",
          FirstDataRow: "FirstDataRow",
          LastDataRow: "LastDataRow",
          SourceTable: "SourceTable",
          TargetTable: "TargetTable",
          NewTable: "NewTable",
          TargetField: "TargetField",
          Type: "Type",
          length: "length",
          Proportion: "Proportion",
          PrimaryKey: "PrimaryKey",
          ImportMode: "ImportMode",
          StartSynchronization: "StartSynchronization",
        },
        msg: {
          msg1: "You can define some additional options for the source",
          msg2: "You can define field correspondence and set the correspondence between the specified source and destination fields",
          msg3: "Add: Add records to the target table",
          msg4: "Copy: Delete all records from the target and re import from the source",
        },
      },

      //数据源
      datasource: {
        title: {
          name1: "Data source management",
        },
        field: {
          index: 'number',
          name: "data source name",
          refreshCache: "refresh cache",
          syncStatus: "sync status",
          status: "status",
          createTime: 'creation time',
          refreshSuccess: "Refresh the cache successfully",
          refreshFailed: "Refresh cache failed",
          notSync: "not synchronized",
          synchronizing: "synchronizing",
          synced: "synced",
          sjyxx: "Data source information",
          ljxx: "connection information",
          datasourceType: "data source type",
          remark: "remark",
          host: "host",
          port: "port",
          sid: "Service Name",
          dbName: "database",
          username: "username",
          password: "password",
          ltxcs: "Connectivity hiddenTrouble",
          sjyxz: "New data source",
          sjybj: "Data source editor",
          ysjtb: "metadata synchronization",
          sjyxq: "Data source details",
          fileName: "database design document.doc"
        },

        msg: {
          ysjtbTip: "The metadata is being synchronized in the background, please go to the metadata management to view the results",
        },

      },

      //元数据
      datacolumn: {
        title: "MetadataManagement",
        field: {
          fieldName: "Field Name",
          load: "Loading...",
          fieldComment: "field comment",
          isPrimaryKey: "Whether primary key",
          isEmpty: "Is it allowed to be empty",
          dataType: "type of data",
          dataLength: "Data length",
          dataPrecision: "Data Accuracy",
          dataScale: "Data decimal places",
          dataDefault: "data default",
          sourceName: "data source",
          tableName: "Database Table",
          updateField: "change field",
          version: "version",
          fieldOldValue: "original value",
          fieldNewValue: "latest value",
          status: "status",
          remark: "remark",
          ysjbg: "metadata changes"
        },

      },

      //数据主题
      dataSubject: {
        title: "dataSubject",
        field: {
          name: "name",
          Englishname: "Englishname",
          Parent: "Parent",
          codeName: "codeName",
        },
        msg: {
          Tips: "Tips",
          msg1: "Are you sure you want to delete?",
          msg2: "Delete succeeded",
        },
        rules: {
          name: "Please enter a name",
          ename: "Please enter English name"
        },
        btn: {
          AddChild: "AddChild",
        }
      },

      //数据目录
      DataDirectory: {
        btn: {
          Description: "Description",
          BrowseData: "BrowseData",
        },
        field: {
          Dataname: "Dataname",
          datadescription: "datadescription",
          Datadirectory: "Datadirectory",
          datatype: "datatype",
          metadata: "metadata",
          Columnname: "Columnname",
          explain: "explain",

          Datasource: "Datasource",
          Tablename: "Tablename",
          name: "name",
          catalogue: "catalogue",
        },

        rules: {
          rules1: "Please select a data table name",
          rules2: "Please select a data source",
        },
        msg: {
          msg1: "Operation succeeded!",
          msg2: "Please select data on the left",
          msg3: "Saved successfully",
        },
      },
      //数据对象
      dataobject: {

        msg: {
          delmsg1: "Please select the data object to delete",
          delmsg2: "Please operate individually",
          delmsg3: "Are you sure you want to delete the current data object?",
          p1: "Please select type",
          p2: "Please enter the code",
          p3: "Please select identification",
          p4: "Please enter comments",
        },

        title: {
          tip: "tip",
          tip2: "see",
          name: "data object"
        },
        field: {
          code: "code",
          identification: "identification",
          type: "type",
          edit: "edit",
          remark: "remark",
          databank: "databank",
          Apiinterface: "Apiinterface",
          tree: "tree",
          tableName: "tableName",
          saveField: "saveField",
          ConditionField: "ConditionField",
          comparisonField: "comparisonField",
          sortField: "sortField",
          fieldName: "fieldName",
          fieldComments: "fieldComments",
          enterValue: "enterValue",
          default: "default",
          sort: "sort",
          AscendingOrder: "AscendingOrder",
          DescendingOrder: "DescendingOrder",
          BeEqualTo: "BeEqualTo",
          NotEqualTo: "NotEqualTo",
          section: "section",
          greaterThan: "greaterThan",
          lessThan: "lessThan",
          GreaterThanOrEqualTo: "GreaterThanOrEqualTo",
          LessThanOrEqualTo: "LessThanOrEqualTo",
          contain: "contain",
          Excluding: "Excluding",
          FixedTimeRange: "FixedTimeRange",
          DynamicTimeRange: "DynamicTimeRange",
        }
      },

      //数据血缘
      Primordial: {
        title: 'DataConsanguinity',
        field: {
          number: 'number',
          tableName: "tableName",
          nodeName: 'nodeName',
          remarks: "remarks",
          operate: 'operate',
          addRelationship: 'addRelationship'

        },
        msg: {
          tip: 'tip',
          deleteOrNot: 'deleteOrNot',
          NewNode: "NewNode",
          editNode: "editNode",
          submission: 'Data without blood relationship will be deleted after submission. Do you want to continue?',
          successfully: 'Submitted successfully',
          Canceled: 'Canceled',
          ConfirmClose: 'ConfirmClose',
          bloodAdd: 'Can only be added on the blood node',
          Parent: 'Parent',
          TreeDirectory: 'TreeDirectory',
          relationship: "Cannot create a new directory and blood relationship under data blood relationship!",
          deleteTheDetails: 'Please edit and delete the data lineage on the right!',
          directoryToModify: "Please select the directory to modify"


        }


      },


      //数据字典
      dataDictionary: {
        title: {
          name: "dataDictionary",
          name2: "AdddataDictionary",
        },

        field: {
          InterfaceName: "InterfaceName",
          InterfaceAddress: "InterfaceAddress",
          code: "code",
          name: "name",
          operate: "operate",
          FieldCode: "FieldCode",
          DictionaryName: "DictionaryName",
          DictionaryType: "DictionaryType",
          Datasourcecode: "Datasourcecode",
          Datasourcename: "Datasourcename",
          Databasename: "Databasename",
          list: "list",
          tree: "tree"
        },
        rules: {
          rules1: "Please enter data source code",
          rules2: "Please enter data source type ",
          rules3: "Please enter the database name",
          rules4: "Please enter the data source connection configuration",
          rules5: "Please select a type",
        },
        msg: {
          Tips: "Tips",
          msg1: "Please select to delete data",
          msg2: "Delete succeeded",
          msg3: "There are empty values in the list!",
        }
      },

      //数据分流
      shunt: {
        title: {
          name: "Data diversion",
        },
        btn: {
          tip: 'point out',
          confirmButtonText: 'define',
          cancelButtonText: 'cancel',
          startUsing: 'start using',
          forbidden: 'forbidden',
          address: 'operate',
          edit: "edit",
          yes: "yes",
          no: "no",
        },


        field: {
          ShuntCoding: 'Shunt coding',
          InterfaceCode: 'Interface code',
          Transcoding: 'Transcoding',
          sfqy: 'Enable or not',
          zxtj: 'conditions for execution',
          describe: 'describe',
          conditionsForExecution: 'conditionsForExecution',
          isEnable: 'Enable or not',

        },
        msg: {
          LoadingDesperately: 'Loading desperately',
          diversionCode: 'Please enter diversion code/interface code/conversion code/description',
          interfaceCode: 'Please enter the interface code',
          conversionCode: 'Please enter conversion code',
          description: 'Please enter a description',
          executionConditions: 'Please enter execution conditions',
          diversionEditing: 'Data diversion editing',
          diversionAdd: 'Add data diversion',

          isDelect: 'Are you sure you want to delete?',
          DeleteSucceeded: 'Delete succeeded',
          ModifiedSuccessfully: 'Modified successfully',
          SuccessfullyAdded: 'SuccessfullyAdded',

        }
      },
      //数据转化
      transformation: {
        title: {
          name: 'data conversion',
        },
        msg: {
          placeholder: "Please enter data interface/field name",
        },



        field: {
          infCode: 'data interface',
          fieldName: "Field name",
          targetTable: 'Target table name',
          srcField: 'Source field',
          targetField: "Target field",
          unitCode: "Unit conversion code",
          mapCode: 'Encoding mapping',
          groupNo: 'divide into groups',
          defaultValue: "Default",
          dimension: 'dimension',
          dimensionSed: 'Dimension ID',
          edit: "edit",
          aggregationCode: 'Please select the aggregation code',
          targetTagName: 'Please enter the target tag name',
          sourceField: "Please enter the source field",
          PleasetargetField: 'Please enter the target field',
          conversionEditing: 'Data conversion editing',
          DataConversionAdd: 'Data conversion add',


        }

      },


      //数据注入

      injection: {
        title: {
          name: "Data injection configuration",
        },



        field: {
          loading: "Loading desperately",
          tablename: 'Target table name',
          keycolumn: 'Primary key splicing',


          aggregationCode: 'Please enter the aggregation code',

          groupingField: 'Please enter grouping field'
        },
        msg: {
          placeholder: "Please enter the target table name",
          PleaseSelect: 'Please select to delete data',
          ModifiedSuccessfully: 'Modified successfully',
          SuccessfullyAdded: 'Successfully added',
          isDelect: 'Are you sure you want to delete?',
          DeleteSucceeded: 'Delete succeeded',
          configurationEditing: 'Data injection configuration editing',
          configurationAddition: 'Data injection configuration addition'
        }


      },


      //数据汇聚
      DataAggregation: {
        title: {
          name: "DataAggregation",
          DataAggregationGrouping: "DataAggregationGrouping",
          DataAggregationconfiguration: "DataAggregationconfiguration",
          DataAggregationoutput: "DataAggregationoutput",
          AddDataAggregationGrouping: "AddDataAggregationGrouping",
          AddDataAggregationconfiguration: "AddDataAggregationconfiguration",
          AddDataAggregationoutput: "AddDataAggregationoutput",
        },




        field: {
          AggregationCoding: "AggregationCoding",
          Groupfields: "Groupfields",
          remarks: "remarks",
          operation: "operation",

          Convergencetime: "Convergencetime",
          Convergencedimension: "Convergencedimension",
          Aggregationsourcetable: "Aggregationsourcetable",
          Taskgrouping: "Taskgrouping",
          Executionorder: "Executionorder",
          Aggregateflag: "Aggregateflag",
          Sourcefield: "Sourcefield",
          Targetfield: "Targetfield",
          Targettable: "Targettable",


        }





      },

      //项目列表
      projectlist: {
        title: {
          name: "ProjectList",
        },
        listedit: {
          dispatch: "dispatch",
          see: "see",
          release: "release",
          TaskManagement: "TaskManagement",
          edit: "edit",
          delete: "delete"
        },
        seetabs: {
          Joblog: "Joblog",
          flowChart: "flowChart",
          taskList: "taskList",
          ProcessLog: "ProcessLog",
          InformationService: "InformationService",
          taskListlabel: {
            name: "name",
            type: "type",
            startTime: "startTime",
            Endtime: "Endtime",
            timeConsuming: "timeConsuming",
            state: "state",
            details: "details",
          },
          editlabel: {
            Englishname: "Englishname",
            Chinesename: "Chinesename",
            Contentdescription: "Contentdescription",
            EditItem: "EditItem",
            NewProject: "NewProject"
          },
          addrule: {
            ename: "Please enter English name",
            cname: "Please enter a Chinese name",
            text: "Please enter content description"
          },
        },
        msg: {
          msg1: "Do you want to delete this task?",
          msg2: "Run each component of this experiment in turn",
          msg3: "Stop running test",
        },
        btn: {
          deploy: "deploy",
          run: "run",
          stop: "stop",
          Taskbuilding: "Taskbuilding",
          name: "name",
          save: "save",
          DocumentContent: "DocumentContent",
          Navigator: "Navigator",
          ParameterSetting: "ParameterSetting",
          back: "back",
          sure: "sure",
          close: "close",
          canvas: "canvas",
          Gridalignment: "Gridalignment",
          revoke: "revoke",
          redo: "redo",
          delete: "delete",
          enlarge: "enlarge",
          narrow: "narrow",
          Fittocanvas: "Fittocanvas",
          Actualsize: "Actualsize",
          Postlevel: "Postlevel",
          Levelleading: "Levelleading",
          Multiplechoice: "Multiplechoice",
          Group: "Group",
          Ungroup: "Ungroup",
        }
      },


      //任务调度
      taskScheduling: {
        name: "taskScheduling",
        tabs: {
          Projectlist: "Projectlist",
          Historylist: "Historylist",
          Scheduledtasklist: "Scheduledtasklist",
          Runninglist: "Runninglist",
        },
        btn: {
          Scheduledscheduling: "Scheduledscheduling",
          dispatch: "dispatch",
          see: "see",
          kill: "kill",
        },
        msg: {
          msg1: "Dispatched successfully",
          msg2: "Delete or not",
          msg3: "Whether to kill this task"
        },
        title: {
          Tips: "Tips",
          InformationService: "InformationService",
          flowchart: "flowchart",
          tasklist: "tasklist",
          Processlog: "Processlog",
          Joblog: "Joblog",
        },
        status: {
          success: "success",
          fail: "fail",
          Running: "Running",
        },

        label1: {
          name: "name",
          Chinesename: "Chinesename",
          describe: "describe",
          Lastmodificationtime: "Lastmodificationtime",
          Modifiedby: "Modifiedby",
          operation: "operation",
          type: "type",
        },
        label2: {
          Executionid: "Executionid",
          flow: "flow",
          engineering: "engineering",
          user: "user",
          starttime: "starttime",
          Endtime: "Endtime",
          timeconsuming: "timeconsuming",
          state: "state",
        },
        label3: {
          ID: "ID",
          Taskname: "Taskname",
          project: "project",
          Firstscheduledrun: "Firstscheduledrun",
          Nextexecutiontime: "Nextexecutiontime",
          expression: "expression",
          operation: "operation",
        },
        label4: {
          ID: "ID",
          ExecutorID: "ExecutorID",
          flow: "flow",
          engineering: "engineering",
          user: "user",
          jurisdiction: "jurisdiction",
          starttime: "starttime",
          Endtime: "Endtime",
          Losstime: "Losstime",
          state: "state",
          operation: "operation",
        },

      },
      // 班组指标
      TeamIndicators: {
        title: "TeamIndicators"
      },



      //数据服务接口
      dataapi: {
        title: "Data service interface",
        field: {
          apiName: "API name",
          apiVersion: "API version",
          apiUrl: "API path",
          reqMethod: "request type",
          resType: "return format",
          status: "status",
          createTime: 'createTime',
          attrConfig: "attribute configuration",
          executeConfig: "execute configuration",
          paramsConfig: "parameter configuration",
          deny: "IP blacklist",
          rateLimit: "Whether to limit current",
          limiting: "Current limiting configuration",
          every: "Every",
          second: "Limit requests in seconds",
          times: "Second-rate",
          remark: "remark",
          configType: "configuration method",
          sourceName: "data source",
          fieldList: "field list",
          columnName: "column name",
          columnDataDefault: "column default",
          columnComment: "column comment",
          reqable: "Whether as a request parameter",
          resable: "Whether as a return parameter",
          sqlParsing: "SQL parsing",
          reqParams: "request parameters",
          paramName: "parameter name",
          nullable: "Is it allowed to be empty",
          paramComment: "describe",
          paramType: "Parameter Type",
          whereType: "operator",
          exampleValue: "example value",
          defaultValue: "Defaults",
          returnField: "return field",
          fieldComment: "field description",
          apiAdd: "New data API",
          apiDetail: "Data API Details",
          apiEdit: "Data APIEdit",
          denyPlaceholder: "Please enter multiple IP blacklists in English, separated",
          apiDocument: "interface documentation",
          apiExample: "interface example",
          fileName: "interface documentation.doc",
          interfaceCall: "interface call",
          reqHead: "request header",
          reqData: "request data",
          KEY: "key",
          VALUE: "value",
          DESCRIPTION: "describe",
          noData: "No data",
          paramValue: "parameter value"
        },

      },


      // 质量检测模版
      QTemplate: {
        title: "QualityInspectionTemplate",
        field: {
          TemplateType: "TemplateType",
          whole: "whole",
          testing: "testing",
          TaskName: "TaskName",
          inputData: "inputData",
          DataTheme: "DataTheme",
          DataObject: "DataObject",
          LimitTheNumberOfEntries: "LimitTheNumberOfEntries",
          CompareFields: "CompareFields",
          sortField: "sortField",
          field: "field",
          dataType: "dataType",
          sort: "sort",
        }
      },

      //检测任务管理
      DTManagement: {
        title: {
          DTManagement: "DetectionTaskManagement",
          NewDetectionTask: "NewDetectionTask",
        },
        field: {
          TaskName: "TaskName",
          TemplateName: "TemplateName",
          TemplateClassification: "TemplateClassification",
          Creator: "Creator",
          CreationTime: "CreationTime",
          Integrity: "Integrity",
          accuracy: "accuracy",
        },

      },

      // 检测任务记录
      DTRecord: {
        title: "DetectionTaskRecord",
        field: {
          ThemeDomain: "ThemeDomain",
          theme: "theme",
          resultsOfEnforcement: "resultsOfEnforcement",
          TriggerType: "TriggerType",
          DeterminationMethodName: "DeterminationMethodName",
          executionTime: "executionTime",
          Manual: "Manual",
          JudgmentMethod: "JudgmentMethod",
        },
      },
      //配置判定任务
      CJudgmentTask: {
        title: {
          CJudgmentTask: "ConfigurationJudgmentTask",
          AddConfigurationJudgmentTask: "AddConfigurationJudgmentTask",
          JudgmentTask: "JudgmentTask",
        },
        field: {
          state: "state",
          remarks: "remarks",
          QualityMonitoringTasks: "QualityMonitoringTasks",
          CycleSetting: "CycleSetting",
          AndOrRelationship: "AndOrRelationship",
          TaskID: "TaskID",
          JudgmentRelationshipEncoding: "JudgmentRelationshipEncoding",
          DeterminationType: "DeterminationType",
          DetermineInputValues: "DetermineInputValues",
          condition: "condition",
          DoYouWantToExecuteImmediately: "DoYouWantToExecuteImmediately",
          ExecuteImmediately: "ExecuteImmediately",
          ManualExecution: "ManualExecution",
        },
        btn: {
          AddParent: "AddParent",
          AddChild: "AddChild",
          choice: "choice",
          FixedValue: "FixedValue",
          Task: "Task",
          And: "And",
          Or: "Or",
        },
        msg: {
          msg1: "Do you want to perform this task?"
        }

      },

      // 判定结果管理
      MResults: {
        title: {
          MResults: "ManagementOfJudgmentResults",
        },
        field: {
          resultsOfEnforcement: "resultsOfEnforcement",
          executionTime: "executionTime",
          JudgmentResults: "JudgmentResults",
        },
      },
      //AI
      //Ai算法
      AiSuanfa: {
        title: {
          suafa: 'Algorithm management',
        },
        btn: {
          all: 'whole',
          search: 'search',
          gf: 'authority',
          itemBtn: 'Training model',
          yuce: 'calculate',
        },

        field: {
          label: 'Algorithm name',
          sfFl: 'Algorithm classification',
          name: 'Algorithm name',
          dbtype: 'Algorithm attribution',
          source: 'Algorithm classification',
          describe: 'Algorithm description',
          file: 'Select File',
          xl: 'train',
          placeholder: 'input data',
          modeName: 'Model name',
          modePath: 'Model path',
          AlgorithName: 'Algorithm name',
          x: 'X-axis',
          y: 'y-axis',
          x1: 'Please select the X-axis property',
          y1: 'Please select the Y-axis property',
          zh: 'Chinese language',
          field: 'field',
          dataType: 'data type',
          mxpg: 'Model evaluation',
          ycjg: 'Forecast results',
          NumberOfClassClusters: "NumberOfClassClusters",
          TrainingResults: "TrainingResults",
        },

      },
      AiModel: {
        msg: 'Please enter a model name',
        name: 'Model list',
        field: {
          id: 'Model ID',
          model_name: "Model name",
          algorithm_name: 'Algorithm name',
          model_input: 'Model input',
          model_path: 'Model path',
          model_evaluation: 'Model evaluation',
        }
      },
      AiResult: {
        msg: 'Please enter a model name',
        name: 'Model list',
        field: {
          predictId: 'predict Id',
          modelId: "Model ID",
          predict_name: 'predict name',
          algorithm_name: 'algorithm name',
          model_name: 'Model name',
        },
        title: 'model Detail',
      },
      //补充
      DComission: {
        InterfaceCollection: "Interface Collection",
        InterfaceCollectionAdd: "Interface Collection Add",
        run: "Running",
        stop: "Paused",
        Execute: "Start",
        id: "Primary Key ID",
        tip: "Prompt",
        false: "Cancel",
        sure: "Confirm",
        to: "To",
        startdate: "Start Date",
        enddate: "End Date",
        startTime: "Start Time",
        endTime: "End Time",
        tsField: "Time Identifier",
        tenRun: "Last 10 Runs",
        State: "Status",
        reqbody: "Request Body",
        Addreqbody: "Add Request Body",
        ConversionInfo: "Conversion Information",
        AddConversionInfo: "Add Conversion Information",
        fieldName: "Field Name",
        targetTable: "Target Table Name",
        srcField: "Source Field",
        targetField: "Target Field",
        toInfo: "Injection Information",
        keycolumn: "Key Column Concatenation",
        seeText1: "View",
        seeText2: "Collapse",
        field: "Field",
        msg1: "Start this task?",
        msg2: "Pause this task?",
        msg3: "Execution successful!",
        msg4: "Start successful!",
        msg5: "Pause successful!",
        runtime: "Execution Time",
        selectPlaceholder: "Please select"
      },

      KFDComission: {
        name: "Name",
        kftitle: "Kafka Data List",
        brokerList: "Broker List"
      },

      offlineDComission: {
        atuoMatching:"Matching Correspondence",
        modelState: "Model State",
        is: "Yes",
        no: "No",
        ysj: "Metadata Synchronization",
        readerDatasourceId: "Reader Data Source ID",
        writerDatasourceId: "Writer Data Source ID",
        readerTable: "Reader Table",
        writerTable: "Writer Table",
        runState: "Status",
        delmsg: "Delete Successful",
        deltip: "Please Select Data to Delete",
        yuaninfo: "Source Information",
        sjkid: "Database Interface",
        bmid: "Table Name Interface",
        incrTimeFeild: "Incremental Time Field",
        chooseTime: "Choose Date Time",
        incrTimeFormat: "Time Format",
        incrTimeFormat2: "Other Time Format",
        mbinfo: "Target Information",
        postSql: "Post-SQL Statement",
        yuan: "Source",
        filedzs: "Field Description",
        type: "Type",
        gdtime: "Fixed Time Range",
        dttime: "Dynamic Time Range",
        star: "Start",
        end: "End",
        dygx: "Correspondence",
        see: "View",
        other: "Other",
        rule1: "Enter Name",
        rule2: "Select Period",
        dengyu: "Equal to",
        budengyu: "Not Equal to",
        qujian: "Range",
        dayu: "Greater Than",
        xiaoyu: "Less Than",
        dadengyu: "Greater Than or Equal to",
        xiaodengyu: "Less Than or Equal to",
        baohan: "Contains",
        bubaohan: "Does Not Contain",
        zhedie: "Fold",
        getinfo: "Click to Start Getting Information",
        savetip: "For all fields in the table, please select the correspondence",
        editmsg: "Edit Successful",
        savemsg: "Save Successful",
        czmsg: "Operation Successful",
        jieximsg: "Parse Successful",
      },

      EXCELDComission: {
        title: "Excel Data Import List",
        title2: "Excel Data Import",
        yuanname: "Source Name",
        sourceName: "Data Source",
        tableName: "Table Name",
        createUser: "Created By",
        createTime: "Created Time"
      },

      datamapDComission: {
        all: "Total",
        tableCount: "Data Tables",
        dataObjectCount: "Data Objects",
        indexCount: "Data Indexes",
        indexzt: "Index Theme",
        yes: "Yes",
        no: "No"
      },

      dataobjDComission: {
        searchtj: "Search Criteria",
        filedname: "Field Name",
        type: "Type",
        caozuo: "Operation",
        searchres: "Search Results",
        del: "Delete",
        basicinfo: "Basic Information",
        indexCode: "Index Interface",
        placeholder1: "Enter Keywords",
        sortField: "Sort Field",
        sort: "Sort",
        upsort: "Ascending",
        downsort: "Descending",
        msg1: "Please Fill in Complete Time",
        shujuku: "Database",
        api: "API Interface",
        indexzb: "Index",
        search: "search",
      },


      PrimordialDComission: {
        title: "Add Lineage",
        msg1: "Please Select the Directory to Delete!",
        back: "Back"
      },
      apiDComission: {
        title: "Data API Edit",
        save: "Save",
        caozuo: "Operation",
        del: "Delete",
      },
      qualityDComission: {
        rule1: "Please enter the task name",
        rule2: "Please select a topic",
        rule3: "Please select a data object",
        rule4: "Please enter the limit number",
        rule5: "Please select a comparison field",
        title1: "Detection Task Management",
        title2: "Judgment Result Management",
        msg1: "Delete this task?",
        placeholder1: "Please select a relationship",
        inputTaskValue: "Output value",
        judgeCompareResultName: "Judgment result",
        caozuo: "Operation",
        see: "View",
        zhedie: "Collapse",
        judgeName: "Method name",
        judgeResult: "Execution result",
        zhengchang: "Normal",
        ychang: "Abnormal",
        createTime: "Execution time",
      },

      AIDComission: {
        title1: "Algorithm Configuration",
        title2: "Algorithm Editing Configuration",
        placeholder1: "Please enter name",
        name: "Name",
        code: "Code",
        url: "URL",
        sort: "Sort",
        createby: "Updater",
        createDate: "Update time",
        danxuan: "Single selection",
        duoxuan: "Multiple selection",
        buxuan: "No selection",
        tiaocan: "Parameter adjustment",
        add: "Add",
        argName: "Parameter name",
        argCode: "Parameter code",
        argSort: "Parameter sort",
        chongzhi: "Reset",
        choosezhou: "Select axis feature",
        isX: "Is it on the X axis?",
        isY: "Is it on the Y axis?",
        xmsg: "This model restricts X axis to single selection!",
        ymsg: "This model restricts Y axis to single selection!",
        noy: "This model restricts Y axis to no selection!",
      },



    },
    ...indexManage_en
  },


  /*---------------------------------------------------------------------------------------------------------------------------------*/

  // sinoma: {
  //   im: {
  //     chat: 'Chat',
  //     title: 'Internal chat',
  //     msg: 'message',
  //     contacts: 'contacts',
  //     search: 'Search: Please enter keywords',
  //     noData: 'No Data',
  //     me: 'Me',
  //     sendEmo: 'Send emoticons',
  //     sendImg: 'Send Image',
  //     chatRecord: 'Chat Record',
  //     enterMsg: 'Click here to enter the message directly',
  //     send: 'Send',
  //     noChatRecord: 'No chat information',
  //     remove: 'detach',
  //     removeCharRecord: 'Delete chat record'
  //   },
  //   device: {
  //     treeTitle: "Main equipment classification",
  //     treeTitleOne: "Process classification",
  //     treeTitleTwo: "Sub equipment classification",
  //     mainDeviceCat: 'Main Device Cat',
  //     mainDevice: 'Main Device',
  //     mainDeviceAdd: 'Main Device Add',
  //     deviceCatConf: 'Device Cat Conf',
  //     subDeviceCat: 'Sub Device Cat',
  //     subDevice: 'Sub Device',
  //     mainDeviceCatAdd: 'Main DeviceCat Add',
  //     subDeviceCatAdd: 'Sub DeviceCat Add',
  //     subDeviceAdd: 'Sub DeviceAdd',
  //     mainDeviceCatCode: 'Main DeviceCat Code',
  //     mainDeviceCatName: 'Main DeviceCat Name',
  //     optMode: 'optMode',
  //     isSpecial: 'isSpecial',
  //     isEnvPort: 'isEnv Port',
  //     powerType: 'powerType',
  //     portAtt: 'PortAtt',
  //     importLevel: 'important Level',
  //     subDeviceCatCode: 'Sub DeviceCat Code',
  //     subDeviceCatName: 'Sub DeviceCat Name',
  //     adminDept: 'adminDept',
  //     connectLocation: 'connect Location',
  //     mainAndSubRelation: 'Please select a sub-device',
  //     mainDeviceName: 'Main Device Name',
  //     mainDeviceCode: 'Main Device Code',
  //     processName: 'Process Name',
  //     subDeviceCode: 'Sub Device Code',
  //     subDeviceName: 'Sub Device Name',
  //     sparepartsCode: 'Spareparts Code',
  //     part: 'part',
  //     addPart: 'addPart',
  //     sparepartsName: 'Spareparts Name',
  //     remark: 'remark',
  //     specs: 'specs',
  //     value: 'value',
  //     unit: 'unit',
  //     specsMan: 'specsMan',
  //     sort: "sort"
  //   },
  //   token: {
  //     user: 'The username or password is incorrectv',
  //     tenant: 'Tenant ID not found',
  //     authorization: 'The token authorization has expired',
  //     information: 'The users role information is not obtained',
  //     obtained: 'The users tenant information is not obtained',
  //     tenantAuthorization: 'The tenant authorization has expired, contact your administrator',
  //     login: 'There are too many login errors, please try again later',
  //     captcha: 'The captcha is incorrect',
  //     Authentication: 'If the token is missing, authentication fails',
  //     token: 'The token is invalid',
  //     disable: "The current account has been banned. Please contact the administrator",
  //   },
  //   AboutUs: {
  //     title: 'About us',
  //     verCode: 'verCode',
  //     urlPath: 'Image',
  //     introduce: 'introduce',
  //     loadText: 'loadText',
  //     image: "Only jpg/png format can be uploaded, and no more than 10 MB",
  //   },
  //   onlineuser: {
  //     title: 'Online User',
  //     username: 'username',
  //     realname: 'realName',
  //     account: 'account',
  //     client_ip: 'clientIP',
  //     login_time: 'logintime',
  //   },
  //   attendance: {
  //     title: 'Attendance',
  //     recordtitle: 'Clock Record',
  //     address: 'address',
  //     code: 'code',
  //     longitude: 'longitude',
  //     dimension: 'dimension',
  //     clockRange: 'clockRange',
  //     workShiftTime: 'workShiftTime',
  //     workUnshiftTime: 'workUnshiftTime',
  //     remark: 'remark'
  //   },
  //   clock: {
  //     address: 'address',
  //     username: 'username',
  //     clockTime: 'CLock Time',
  //     clockOutTime: 'Clock OutTime'
  //   },
  //   condition: {
  //     name: 'name',
  //     expression: 'expression',
  //     type: 'type',
  //     status: 'status',
  //   },
  //   defaultValues: {
  //     name: 'name',
  //     content: 'content',
  //     fieldType: 'fieldType',
  //   },
  //   category: {
  //     name: 'name',
  //     pid: 'pid',
  //     sort: 'sort',
  //     status: 'status',
  //   },
  //   button: {
  //     name: 'name',
  //     display: 'display',
  //     sort: 'sort',
  //     status: 'status',
  //   },
  //   deployment: {
  //     name: 'key',
  //     key: 'name',
  //     category: 'category',
  //     version: 'version',
  //     status: 'status',
  //     deployTime: 'deployTime',
  //   },
  //   designFrom: {
  //     formKey: 'formKey',
  //     modelKey: 'modelKey',
  //     name: 'name',
  //     remark: 'remark',
  //     status: 'status',
  //     categoryId: 'categoryId',
  //     version: 'version',
  //     edit: 'edit',
  //     historys: 'historys',
  //     copy: 'copy',
  //     design: 'design',
  //     delete: 'delete',
  //     add: 'add',
  //     remove: 're',
  //   },
  //   folder: {
  //     title: 'folder Manage',
  //     name: ' folder Name',
  //     code: ' folder Code',
  //     parentId: 'parent folder'
  //   },
  //   design: {
  //     placeholderModelKey: 'form Key',
  //     model: 'Model design',
  //     form: 'Form design',
  //     formEdit: 'Form designEdit',
  //     deployment: 'Deployment management',
  //     button: 'Process button',
  //     buttonEdit: 'Process buttonEdit',
  //     category: 'Process classification',
  //     categoryEdit: 'Process classificationEdit',
  //     defaultValues: 'Form defaults',
  //     defaultValuesEdit: 'Form defaultsEdit',
  //     condition: 'Process expressions',
  //     conditionEdit: 'Process expressionsEdit',
  //   },
  //   model: {
  //     category: 'category',
  //     icon: 'icon',
  //     modelKey: 'modelKey',
  //     name: 'name',
  //     categoryId: 'categoryId',
  //     description: 'categoryId',
  //     version: 'version',
  //     list: ' Model Management',
  //     title: 'Model',
  //     property: 'property',
  //     event: 'Event',
  //     service: 'service',
  //     calc: 'Stream computing',
  //     select: 'Please select the model',
  //     devicePicture: 'Model Picture',
  //     picture: 'jpg/png model picture can only be uploaded, and no more than 500kb',
  //     modelClassify: 'Model classification'
  //   },
  //   process: {
  //     start: 'Create a new process',
  //     todo: 'To do',
  //     send: 'My request',
  //     MyDone: 'Mine done',
  //     done: 'Closing matters',
  //     claim: 'Pending transactions',
  //     copy: 'Cc matters',
  //
  //   },
  //   done: {
  //     nameOfTheProcess: 'nameOfTheProcess',
  //     ProcessIdentification: 'ProcessIdentification',
  //     SerialNumber: 'SerialNumber',
  //     category: 'category',
  //     TheCurrentNode: 'TheCurrentNode',
  //     applicant: 'applicant',
  //     ApplicationTime: 'ApplicationTime',
  //     ProcessStatus: 'ProcessStatus',
  //
  //   },
  //   copy: {
  //     title: 'title',
  //     Initiator: 'Initiator',
  //     CCTime: 'CCTime',
  //
  //   },
  //   indexs: {
  //     nameOfTheProcess: 'nameOfTheProcess',
  //     ProcessIdentification: 'ProcessIdentification',
  //     SerialNumber: 'SerialNumber',
  //     ApplicationTime: 'ApplicationTime',
  //     category: 'category',
  //     TheCurrentNode: 'TheCurrentNode',
  //     Approver: 'Approver',
  //     applicant: 'applicant',
  //     StartTime: 'StartTime',
  //     EndTime: 'EndTime',
  //     Status: 'Status',
  //     TimeRange: 'TimeRange',
  //     activation: 'activation',
  //     Suspend: 'Suspend',
  //   },
  //   opsdone: {
  //     nameOfTheProcess: 'nameOfTheProcess',
  //     ProcessIdentification: 'ProcessIdentification',
  //     SerialNumber: 'SerialNumber',
  //     category: 'category',
  //     Approver: 'Approver',
  //     StartTime: 'StartTime',
  //     EndTime: 'EndTime',
  //     Status: 'Status',
  //   },
  //   opsProxy: {
  //     client: 'client',
  //     agent: 'agent',
  //     Process: 'Process',
  //     SpecifyTheProcess: 'SpecifyTheProcess',
  //     type: 'type',
  //     EffectiveTime: 'EffectiveTime',
  //     Status: 'Status',
  //
  //   },
  //   opslist: {
  //     nameOfTheProcess: 'nameOfTheProcess',
  //     ProcessIdentification: 'ProcessIdentification',
  //     SerialNumber: 'ProcessIdentification',
  //     category: 'category',
  //     applicant: 'applicant',
  //     StartTime: 'StartTime',
  //     EndTime: 'EndTime',
  //     ProcessStatus: 'ProcessStatus',
  //     DetailedCirculation: 'DetailedCirculation',
  //   },
  //   claim: {
  //     nameOfTheProcess: 'nameOfTheProcess',
  //     ProcessIdentification: 'ProcessIdentification',
  //     SerialNumber: 'SerialNumber',
  //     category: 'category',
  //     CurrentSteps: 'CurrentSteps',
  //     applicant: 'applicant',
  //     ApplicationTime: 'ApplicationTime',
  //   },
  //   ops: {
  //     index: 'O&M scheduling',
  //     done: 'Closing process',
  //     list: 'A list of processes',
  //     proxy: 'Process agent',
  //     proxyEdit: 'Process agentEdit',
  //     attach: {
  //       title: 'ObjectStorage',
  //       classify: 'classify',
  //       ossCode: 'ossCode',
  //       endpoint: 'endpoint',
  //       bucketName: 'bucketName',
  //       accessKey: 'accessKey',
  //       secretKey: 'secretKey',
  //       appId: 'appId',
  //       region: 'region',
  //       status: 'status',
  //       remark: 'remark',
  //       backgroundUrl: 'backgroundUrl',
  //       code: 'code',
  //       ObjectDebugging: 'ObjectDebugging',
  //     },
  //     oss: {
  //       FileSize: 'FileSize（KB）',
  //       CreationTime: 'CreationTime',
  //       AttachmentDomain: 'AttachmentDomain',
  //       AttachmentExtension: 'AttachmentExtension',
  //       AttachmentAddress: 'AttachmentAddress',
  //       AttachmentName: 'AttachmentName',
  //       Formerly: 'Formerly',
  //       filename: 'filename',
  //       type: 'type',
  //       Size: 'Size（KB）',
  //       DateUpload: 'DateUpload',
  //       Management: 'Management',
  //       CurrentLocation: 'CurrentLocation',
  //       HowToPresent: 'HowToPresent',
  //       All: 'All',
  //       MyPapers: 'MyPapers',
  //       Picture: 'Picture',
  //       Document: 'Document',
  //       Video: 'Video',
  //       Music: 'Music',
  //       Other: 'Other',
  //       Upload: 'Upload',
  //       Delete: 'Delete',
  //       AttachmentUpload: 'AttachmentUpload',
  //       TemplateUploaded: 'TemplateUploaded',
  //       List: 'List',
  //       Network: 'Network',
  //       OneLevelUp: 'OneLevelUp',
  //       DetailedInformation: 'DetailedInformation  '
  //
  //     },
  //     sms: {
  //       SMSConfiguration: 'SMSConfiguration',
  //       SMSSendingDebugging: 'SMSSendingDebugging',
  //       classify: 'classify',
  //       endpoint: 'endpoint',
  //       bucketName: 'bucketName',
  //       smsCode: 'smsCode',
  //       accessKey: 'accessKey',
  //       secretKey: 'secretKey',
  //       templateId: 'templateId',
  //       regionId: 'regionId',
  //       signName: 'signName',
  //       status: 'status',
  //       remark: 'remark',
  //       TemplateContent: 'TemplateContent',
  //     }
  //   },
  //
  //   mediumscreen: {
  //     title: 'Medium screen management',
  //     name: 'name',
  //     urlPath: 'urlPath',
  //     code: 'code',
  //     remark: 'remark'
  //   },
  //   form: {
  //     input_error: 'please enter',
  //     input_error_message: 'please enter the correct',
  //     select_error: 'please choose',
  //     noEmpty: 'Name cannot be empty',
  //     removeSpaces: 'please remove the space',
  //     removeSpaces2: 'Please delete the spaces before and after',
  //     nameLength2: 'length cannot be greater than 20',
  //     nameLength3: 'length cannot be greater than 30',
  //     nameLength4: 'length cannot be greater than 40',
  //     nameLength6: 'length cannot be greater than 60',
  //     nameLength10: 'length cannot be greater than 100',
  //   },
  //   confirm: {
  //     title: 'OK will select Data deletion?',
  //     startusing: "Confirm Enable Selection Data?",
  //     loseefficacy: "Confirm to invalidate the selected data?",
  //     read: "Confirm that the selected message has been read?",
  //     removeNotRead: "This message is unread, confirm to delete?",
  //     removeContainNotRead: "The selected data contains unread messages, confirm to delete?",
  //     Confirm: "Whether to return to the list page?",
  //     sure: 'sure',
  //     cancel: 'cancel',
  //   },
  //   afterTip: {
  //     role: "Role and user binding cannot be deleted",
  //     empower: 'Tenant is not authorized to configure',
  //     conflict: 'Encoding already exist!',
  //     repetition: 'There are multiple codes with the same code, please contact the administrator to deal with it!',
  //     saveFail: 'saveFail',
  //     deleteFail: 'deleteFail',
  //     deleteChildFirst: 'deleteChildFirst',
  //     dataError: 'If the system data is incorrect, please contact the administrator for handling',
  //     dataExist: 'The data already exists and cannot be set repeatedly!',
  //     submitFail: 'submitFailed',
  //     idNotNull: 'idNotNull!',
  //     dataNotExist: 'dataNotExist',
  //     generateFail: 'generateFail',
  //     codeNotExist: 'codeNotExist!',
  //     serviceError: 'serviceError!',
  //     dataNotNull: 'dataNotNull',
  //     parameterMissing: 'parameterMissing',
  //     unchangeable: 'unchangeable',
  //     deletedSuccess: 'deletedSuccess',
  //     node: 'The node itself cannot be self-selected',
  //     name: 'Name already exists',
  //   },
  //   btn: {
  //     execute: "execute",
  //     preview: 'preview',
  //     test: 'Test Connect',
  //     icon: 'icon',
  //     more: 'more',
  //     Permissions: 'Permissions',
  //     input_sort: 'Please enter a number of up to 10 digits',
  //     deploy: 'deploy',
  //     sj: 'Suspend',
  //     bs: 'activation',
  //     qx: 'ChangeCatecory',
  //     zz: 'PullThePlug',
  //     cb: 'Reminders',
  //     cs: 'carbonCopy',
  //     wt: 'entrust',
  //     zb: 'Transfer',
  //     bgshr: 'reviewer',
  //     bh: 'overrule',
  //     Debug: 'Debug',
  //     tk: 'Pass',
  //     flow: 'flow',
  //     detail: 'detail',
  //     launch: 'launch',
  //     historys: 'history',
  //     copy: 'copy',
  //     design: 'design',
  //     delete: 'delete',
  //     close: 'close',
  //     add: 'add',
  //     publish: 'publish',
  //     edit: 'edit',
  //     remove: 'remove',
  //     view: 'view',
  //     query: 'query',
  //     reset: 'reset',
  //     save: 'save',
  //     saveBack: 'saveBack',
  //     goBack: 'goBack',
  //     saveAndAdd: 'saveAndAdd',
  //     cancel: 'cancel',
  //     handel: 'handel',
  //     summary: 'summary',
  //     determine: 'determine',
  //     addChild: 'addChild',
  //     addLine: 'addLine',
  //     dicConfig: "dicConfig",
  //     shielding: 'shielding',
  //     relieve: 'relieve',
  //     stockIn: 'stockIn',
  //     confirmStockIn: 'confirmStockIn',
  //     confirmStockOut: 'confirmStockOut',
  //     import: 'import',
  //     export: 'export',
  //     platform: 'platform',
  //     restPassword: 'restPassword',
  //     roleConfig: 'roleConfig',
  //     accountConfig: 'accountConfig',
  //     revise: "revise",
  //     permissions: 'permissions',
  //     submit: 'submit',
  //     back: 'back',
  //     upload: 'upload',
  //     enable: 'enable',
  //     disable: 'disable',
  //     valid: 'valid',
  //     invalid: 'invalid',
  //     generate: 'generate',
  //     init: 'init',
  //     configureFormula: 'configureFormula',
  //     menuSetting: 'menuSetting',
  //     authSetting: 'authSetting',
  //     dataSourceSetting: 'dataSourceSetting',
  //     packageSetting: 'packageSetting',
  //     packageManage: 'packageManage',
  //     generateInspectionRecord: 'generateInspectionRecord',
  //     batchNumRule: 'batchNumRule',
  //     batchRule: 'batchRule',
  //     batchInspection: 'batchInspection',
  //     cutLine: 'cutLine',
  //     choice: 'choice',
  //     resend: 'resend',
  //     consume: 'consume',
  //     protocolParams: 'protocolConfiguration',
  //     configuration: 'ConfigurationDelivery',
  //     message_setting: 'message setting',
  //     viewBloodRelationship: 'viewBloodRelationship',
  //     typeTitle: 'dataType',
  //     read: 'Mark as read',
  //   },
  //   tip: {
  //     addSuccess: 'addSuccess',
  //     updateSuccess: 'updateSuccess',
  //     delSuccess: 'delSuccess',
  //     success: 'success',
  //     error: 'error',
  //     selectEmpty: 'selectEmpty',
  //     selectOne: 'selectOne',
  //     selectQiYong: 'Enabled successfully',
  //
  //     unline_tip: 'Confirm to offline this user?',
  //     tip_determine_del: 'tip_determine_del?',
  //     choose_at_least_one: 'choose_at_least_one',
  //     hasChildren: 'hasChildren!',
  //
  //     valid: 'Whether the data is enabled？',
  //     invalid: 'Whether to disable the data？',
  //     generate: 'Whether data is generated',
  //     had_generate_cannot_delete: 'quality hiddenTrouble record has been generated and cannot be deleted！',
  //     noEnable: 'Whether to disable the data？',
  //     tip_determine_gen: 'tip_determine_gen?',
  //     tip_determine_gen_record: 'tip_determine_gen_record',
  //     tip_determine_init: 'tip_determine_init?',
  //     tip_determine_gen_loading: 'The data generation may take too long, please refresh it yourself later!?',
  //     tip_detrmine_select_del: 'tip_detrmine_select_del',
  //     tip_determine_no_save_head: 'tip_determine_no_save_head!',
  //     tip_determine_no_select: 'Please select it first',
  //     tip_determine_no_select_cate: 'Please hiddenTrouble the category first',
  //     selected: 'selected',
  //     setSuccess: 'setSuccess',
  //     right_data: 'right_data',
  //     correct_two_decimal_places: 'correct_two_decimal_places',
  //     input_search: 'Enter keywords to filter',
  //     select_edit_node: 'Select the node you want to edit',
  //     select_delete_node: 'Select the node you want to delete'
  //   },
  //   md: {
  //     waterRate: {
  //
  //       waterRateMain: {
  //         title: 'Moisture coefficient',
  //         choose: 'Please select a version',
  //         lastYear: 'lastYear',
  //         nextYear: 'nextYear',
  //         status: 'status',
  //         versionCode: 'versionCode',
  //         copy: 'copy',
  //         edit: 'edit',
  //         remove: 'remove',
  //       },
  //       waterRateDetail: {
  //         title: 'Moisture coefficient details',
  //         itemName: 'material',
  //         waterRateValRef: 'Moisture coefficient (%) collection reference',
  //         rateVal: 'Correction value of moisture coefficient (%)',
  //         remark: 'remark',
  //         chooseItem: 'chooseItem',
  //         autoCollction: 'autoCollction'
  //       }
  //     },
  //     wareHouseWarn: {
  //       warnType: 'warnType',
  //       stationCode: 'stationCode',
  //       warnRange: 'warnRange'
  //     },
  //     classCalendarMember: {
  //       title1: 'Sing Work'
  //     },
  //     name: 'name',
  //     code: 'code',
  //     remark: 'remark',
  //     driver: 'driver',
  //     manage: 'manage',
  //     form: {
  //       input_error: 'Please enter',
  //       select_error: 'Please select',
  //     },
  //     param: {
  //       LabelTitle: "Parameter Management",
  //       ParamCode: "ParamCode",
  //       ParamName: "ParamName",
  //       ParamValue: "ParamValue",
  //       Remark: "Remark",
  //     },
  //     class: {
  //       title: 'Team',
  //       classCode: 'Shift coding',
  //       className: 'CrewName',
  //       classCatCode: 'Team classification code',
  //       classCatName: 'The name of the crew classification',
  //       isEnable: 'isEnable',
  //       remark: 'remark',
  //     },
  //     classCat: {
  //       title: 'Team classification',
  //       classCatCode: 'Team classification code',
  //       classCatName: 'The name of the crew classification',
  //       parentCode: 'parentCode',
  //       parentName: 'parentName',
  //       remark: 'remark',
  //     },
  //     classMember: {
  //       title: 'Team members',
  //       classCode: 'Shift coding',
  //       className: 'CrewName',
  //       userCode: 'userCode',
  //       userName: 'userName',
  //       isLeader: 'isLeader',
  //       remark: 'remark',
  //       afterTip: {
  //         memberIsEmpty: 'Crew members cannot be empty',
  //         onlyOneLeader: 'A shift can only have one team leader',
  //         memberDuplicated: 'Team members cannot be duplicated',
  //         chooseMenu: 'The parent node can only select the menu type!',
  //         saveFail: 'Save failed',
  //       }
  //     },
  //     monitoring: {
  //       treeTitle: "Area classification",
  //       title: "Process Management",
  //       titleEdit: "Add Operation Management",
  //       labelTitle: 'Area monitoring points',
  //       areaName: 'areaName',
  //       areaCode: 'areaCode',
  //       camreaCode: 'camreaCode',
  //       camreaName: 'camreaName',
  //       funct: 'funct',
  //       url: 'url',
  //     },
  //     deviceMonitoring: {
  //       treeTitle: "Process classification",
  //       labelTitle: "Device monitoring points",
  //       mainDeviceCode: 'mainDeviceCode',
  //       mainDeviceName: 'mainDeviceName',
  //       mainCode: 'mainCode',
  //       mainName: 'mainName',
  //       camreaCode: 'camreaCode',
  //       camreaName: 'camreaName',
  //       funct: 'funct',
  //       url: 'Access address',
  //     },
  //     classShift: {
  //       title: 'shift',
  //       classShiftCode: 'Shift code',
  //       classShiftName: 'Shift name',
  //       startTime: 'startTime',
  //       endTime: 'endTime',
  //       classShiftDateType: 'Date category',
  //       remark: 'remark',
  //     },
  //     classShiftRule: {
  //       title: 'Scheduling rules',
  //       classShiftRuleCode: 'Scheduling rule coding',
  //       classShiftRuleName: 'The name of the scheduling rule',
  //       classCatCode: 'Team classification code',
  //       classCatName: 'The name of the crew classification',
  //       startDate: 'startDate',
  //       endDate: 'endDate',
  //       remark: 'remark',
  //       afterTip: {
  //         ruleDay: "The number of shift days is less than the number of shift rule days",
  //         dateRepetition: "Date repetition",
  //         lineIsEmpty: 'The typesetting rule has no line information',
  //         ruleTimeIsEmpty: 'The scheduling rule time is empty',
  //         ruleTimeError: 'The shift rule start time is greater than the end time'
  //       }
  //     },
  //     classShiftRuleLine: {
  //       title: 'Scheduling rule lines',
  //       classShiftRuleCode: 'Scheduling rule coding',
  //       classShiftRuleSort: 'Scheduling rule ordering',
  //       classCatCode: 'Team classification code',
  //       classCatName: 'The name of the crew classification',
  //       classCode: 'Shift coding',
  //       className: 'Crew name',
  //       classShiftCode: 'Shift code',
  //       classShiftName: 'Shift name',
  //       remark: 'remark',
  //     },
  //     classCalendar: {
  //       title: 'Scheduling calendar',
  //       classShiftRuleCode: 'Scheduling rule coding',
  //       calendarDate: 'Calendar date',
  //       classCatCode: 'Team classification code',
  //       classCatName: 'The name of the crew classification',
  //       classCode: 'Shift coding',
  //       className: 'Crew name',
  //       classShiftCode: 'Shift code',
  //       classShiftName: 'Shift name',
  //       remark: 'remark',
  //     },
  //     item: {
  //       headTitle: 'Production items',
  //       cateHeadTitle: 'Production item classification',
  //
  //       parentCode: 'parentCode',
  //       parentName: 'parentName',
  //       labelText: 'labelText',
  //       superiorName: 'superiorName',
  //       list: 'Material information',
  //       name: 'Item name',
  //       code: 'Item code',
  //       norms: 'specification',
  //       model: 'Model',
  //       charNo: 'Figure number',
  //       unit: 'unit',
  //       uname: 'name',
  //       idCard: "idCard",
  //       black_name: 'black_name',
  //       black_name_remark: 'black_name_remark',
  //       phone: "phone",
  //       wechat: 'wechat',
  //
  //       itemName: 'itemName',
  //       itemCode: 'itemCode',
  //       itemCatId: 'itemCatId',
  //       itemCatCode: 'itemCatCode',
  //       itemCatName: 'itemCatName',
  //       processName: 'processName',
  //       processCode: 'processCode',
  //       isEnable: 'isEnable',
  //       isProduct: 'isProduct',
  //       remark: 'remark',
  //       itemCateId: 'itemCateId',
  //       unitCode: 'unitCode',
  //       unitName: 'unitName',
  //     },
  //     unit: {
  //       headTitle: 'Unit management',
  //
  //       unitCode: 'unitCode',
  //       unitName: 'unitName',
  //       isEnable: 'unitName',
  //       remark: 'remark',
  //     },
  //     itemGrant: {
  //       headTitle: 'Organize mapping',
  //       orgName: 'orgName',
  //       remark: 'remark',
  //
  //       orgSelectErr: 'You cannot add organization mappings repeatedly',
  //     },
  //     itemMapping: {
  //       headTitle: 'Item mapping',
  //       extItemCodeOrName: 'extItemCodeOrName',
  //       extItemName: 'extItemName',
  //       extItemCode: 'extItemCode',
  //       isEnable: 'isEnable',
  //       remark: 'remark',
  //       orgSelectErr: 'You cannot add organization mappings repeatedly',
  //       ExtItemHeadTitle: 'ExtItemHeadTitle',
  //     },
  //     itemBom: {
  //       headTitle: 'Item BOM',
  //       selectHeadTitle: 'selectHeadTitle',
  //       itemCodeOrName: 'itemCodeOrName/name',
  //       isEnable: 'isEnable',
  //       itemName: 'itemName',
  //       itemCode: 'itemCode',
  //       bomItemName: 'bomItemName',
  //       bomItemCode: 'bomItemCode',
  //       remark: 'remark',
  //       orgSelectErr: 'You cannot add organization mappings repeatedly',
  //       afterTip: {
  //         enableNotNull: 'The isEnable parameter is missing！',
  //       }
  //     },
  //     extItemCate: {
  //       title: 'ERP materials',
  //       headTitle: 'ERP material management',
  //       headCatTitle: 'ERP material classification',
  //       pleaseInput: 'pleaseInput',
  //
  //       parentCode: 'parentCode',
  //       parentName: 'parentName',
  //       extItemName: 'extItemName',
  //       extItemCode: 'extItemCode',
  //       extItemCatCode: 'extItemCatCode',
  //       extItemCatName: 'extItemCatName',
  //       isEnable: 'isEnable',
  //       remark: 'remark',
  //       itemCateId: 'ERP_itemCateId',
  //       unitCode: 'unitCode',
  //       unitName: 'unitName',
  //       hasChildren: 'There are children that cannot be deleted!',
  //       afterTip: {
  //         unitError: 'The query unit information is incorrect！',
  //       }
  //     },
  //     org: {
  //       name: 'Organization name',
  //       code: 'Organization code',
  //       mapping: 'Organize mapping',
  //     },
  //     itemOrg: {
  //       list: 'Item organization mapping',
  //       itemOrgs: 'Organization name or organization code',
  //       itemDetail: 'Item details',
  //       orgErr: 'The organization already has a mapping！',
  //       itemErr: 'The item cannot be duplicated, please select it again!',
  //     },
  //     saleArea: {
  //       tagName: 'Sales flow to regions',
  //       areaName: 'areaName',
  //       areaCode: 'areaCode',
  //       createPeople: 'createPeople',
  //       createTime: 'createTime',
  //       belongArea: 'belongArea',
  //       remarks: 'remarks',
  //       cityName: 'cityName',
  //       cityCode: 'cityCode',
  //     },
  //     projectFiles: {
  //       tagName: 'Project archives',
  //       projectName: 'projectName',
  //       projectCode: 'projectCode',
  //       createPeople: 'createPeople',
  //       createTime: 'createTime',
  //       projectType: 'projectType',
  //       belongCompany: 'belongCompany',
  //       remarks: 'remarks'
  //     },
  //     virtualOrg: {
  //       list: 'Organization management',
  //       inSide: 'inSide',
  //       title: 'title',
  //       outSide: 'outSide',
  //       orgCode: 'orgCode',
  //       orgName: 'orgName',
  //       companyName: 'companyName',
  //       orgType: "orgType",
  //       remark: 'remark'
  //     },
  //     mdProject: {
  //       headTitle: 'Project information',
  //
  //       projectCode: 'projectCode',
  //       projectName: 'projectName',
  //       projectType: 'projectType',
  //       companyName: 'companyName',
  //     },
  //     customer: {
  //       title: 'client',
  //       form: {
  //         input_error: 'input_error',
  //         input_error_message: 'input_error_message',
  //         select_error: 'select_error',
  //       },
  //       headTitle: 'Customer management',
  //       customerName: 'customerName',
  //       customerCode: 'customerCode',
  //       address: 'address',
  //       contactPerson: 'contactPerson',
  //       tel: 'tel',
  //       isEnable: 'isEnable',
  //       remark: 'remark',
  //       tip_determine_del: 'tip_determine_del?',
  //     },
  //     monitor: {
  //       Title: 'Area maintenance',
  //       TitleEdit: 'Area maintenanceEdit',
  //       remark: "remark",
  //       headCateTitle: 'Operation information',
  //       headTitle: 'Region information',
  //       headTitleAdd: 'Region informationEdit',
  //       processCode: 'processCode',
  //       processName: 'processName',
  //       shortName: 'shortName',
  //       acestors: 'acestors',
  //       parentId: 'parentId',
  //       parentCode: 'parentCode',
  //       isEnable: 'isEnable',
  //       areaCode: 'areaCode',
  //       areaName: 'areaName',
  //       areaType: 'areaType',
  //       isMonitor: 'isMonitor',
  //       parentArea: 'ParentArea',
  //     },
  //     cusAddress: {
  //       headTitle: 'address',
  //
  //       address: 'address',
  //       remark: 'remark',
  //
  //       tip_determine_del: 'tip_determine_del?',
  //     },
  //     cusCar: {
  //       cusCar: 'cusCar',
  //       headTitle: 'cusCar',
  //
  //       carNo: 'carNo',
  //       remark: 'remark',
  //       gpsSupplier: 'gpsSupplier',
  //       falseness: 'falseness',
  //       black_name: 'black_name',
  //       companyName: 'companyName',
  //       userName: 'Block/Dismiss people',
  //       blackRemark: 'blackRemark',
  //       createUser: 'createUser',
  //       createTime: 'createTime',
  //       blackUpdateUser: 'blackUpdateUser',
  //       updateTime: 'updateTime',
  //       lastModifiedTime: 'lastModifiedTime',
  //
  //
  //     },
  //     cusContrast: {
  //       headTitle: 'Customer comparison information',
  //
  //       outCusName: 'outCusName',
  //       settCusName: 'settCusName',
  //       settType: 'settType',
  //       moneyType: 'moneyType',
  //       transportType: 'transportType',
  //       productBrandId: 'productBrandId',
  //       arrivalAdd: 'arrivalAdd',
  //       cusType: 'cusType',
  //       deptName: 'deptName',
  //       areaFlow: 'areaFlow',
  //       disburdenAdd: 'disburdenAdd',
  //       seaCusName: 'seaCusName',
  //       staffName: 'staffName',
  //       remark: 'remark',
  //
  //     },
  //     cusDelivery: {
  //       headTitle: 'shipments',
  //
  //       outCusName: 'outCusName',
  //       orgName: 'orgName',
  //       productName: 'productName',
  //       remark: 'remark',
  //     },
  //     cusDriver: {
  //       headTitle: 'driver',
  //
  //       driverName: 'driverName',
  //       remark: 'remark',
  //     },
  //     cusProductBrand: {
  //       headTitle: 'Product brand',
  //
  //       productBrandId: 'productBrandId',
  //       remark: 'remark',
  //     },
  //     cusUser: {
  //       headTitle: 'user',
  //
  //       userName: 'userName',
  //       remark: 'remark',
  //     },
  //     supplier: {
  //       title: 'vendor',
  //       form: {
  //         input_error: 'input_error',
  //         input_error_message: 'input_error_message',
  //         select_error: 'select_error',
  //       },
  //       headTitle: 'Basic supplier information',
  //       supplierCode: 'supplierCode',
  //       supplierName: 'supplierName',
  //       contactPerson: 'contactPerson',
  //       address: 'address',
  //       tel: 'tel',
  //       isEnable: 'isEnable',
  //       remark: 'remark',
  //     },
  //     warehouseCfg: {
  //       field: {
  //         warehouseCode: "warehouseCode",
  //         warehouseName: "warehouseName",
  //         itemCode: "itemCode",
  //         itemName: "itemName",
  //         isEnable: "isEnable",
  //         itemCodeAndName: "itemCodeAndName",
  //         warehouseCodeAndName: "warehouseCodeAndName",
  //       },
  //       title:{
  //         indexHeadTitle: "One Card Warehouse Configuration",
  //       }
  //     },
  //     itemWarehouse: {
  //       field: {
  //           warehouseCodeAndName: 'Repository code/repository name',
  //           warehouseName: 'Repository name',
  //           warehouseCode: 'Warehouse code',
  //           stockInOut: 'stockInOut',
  //           stockDataSource: 'stockDataSource',
  //           stockDcs: 'stockDcs',
  //           remark: 'remark',
  //           isEnable: 'isEnable',
  //           itemWarehouseHeadTitle: 'itemWarehouseHeadTitle',
  //       },
  //       title:{
  //         indexHeadTitle: "Item warehouse",
  //       }
  //     },
  //     supplierCorp: {
  //       headTitle: 'Supplier cooperatives',
  //
  //       purCorpName: 'purCorpName',
  //       reptCorpName: 'reptCorpName',
  //       stockOrgName: 'stockOrgName',
  //       remark: 'remark',
  //
  //     },
  //     supplierUser: {
  //       headTitle: 'Vendor users',
  //
  //       userName: 'userName',
  //       userWc: 'userWc',
  //       userTel: 'userTel',
  //       userEmail: 'userEmail',
  //       isSys: 'isSys',
  //       remark: 'remark',
  //
  //     },
  //     supplierProduct: {
  //       headTitle: 'Supplier cooperation products',
  //
  //       productName: 'productName',
  //       remark: 'remark',
  //
  //     },
  //     wareHouse: {
  //       warehouseWarn: 'warehouse Warn',
  //       title: 'Warehouse information',
  //       formulaButton: 'formulaButton',
  //       formulaText: 'formulaText',
  //       baseTitle: 'baseTitle',
  //       listTitle: 'listTitle',
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       code: 'warehouseCode',
  //       name: 'warehouseName',
  //       shapeCode: 'shapeCode',
  //       isEnable: 'isEnable',
  //       remark: 'remark',
  //       isEnableEarlyWarning: 'isEnableEarlyWarning',
  //       earlyWarningThreshold: 'earlyWarningThreshold',
  //       locationCode: 'locationCode',
  //       locationName: 'locationName',
  //       locationTitle: 'locationTitle',
  //       select_warning: 'select_warning',
  //       add_warning: 'add_warning',
  //       save_warning: 'save_warning',
  //       isEnable_blank: 'isEnable_blank',
  //       code_blank: 'code_blank',
  //       name_blank: 'name_blank',
  //       location_code_blank: 'location_code_blank',
  //       location_name_blank: 'location_name_blank',
  //       isEnableEarlyWarning_blank: 'isEnableEarlyWarning_blank',
  //       earlyWarningThreshold_blank: 'earlyWarningThreshold_blank',
  //       formulaText_blank: 'formulaText_blank',
  //     },
  //     wareHouseFormula: {
  //       title: 'List of formula arguments',
  //       formulaCode: 'formulaCode',
  //       formulaName: 'formulaName',
  //       formulaValue: 'formulaValue',
  //       remark: 'remark',
  //       select_warning: 'select_warning',
  //       formulaCode_blank: 'formulaCode_blank',
  //       formulaName_blank: 'formulaName_blank',
  //       formulaValue_blank: 'formulaValue_blank',
  //       formulaParam1_blank: 'formulaParam1_blank',
  //       formulaParam2_blank: 'formulaParam2_blank',
  //     },
  //     period: {
  //       title: 'Period management',
  //       periodCode: 'periodCode',
  //       year: 'year',
  //       month: 'month',
  //       startDate: 'startDate',
  //       endDate: 'endDate',
  //       isEnable: 'isEnable',
  //       periodDate: 'periodDate',
  //       generateDate: 'generateDate',
  //       viewDate: 'viewDate',
  //       dateTitle: 'dateTitle',
  //       detail: 'detail',
  //       select_warning: 'select_warning',
  //       year_blank: 'year_blank',
  //       month_blank: 'month_blank',
  //       start_blank: 'start_blank',
  //       end_blank: 'end_blank',
  //       isEnable_blank: 'isEnable_blank',
  //       date_blank: 'date_blank',
  //       afterTip: {
  //         checkPeriod: 'If the date range and the existing period cross, please contact the administrator to handle it！',
  //         checkPeriodDate: 'The period date already exists, please contact your administrator for processing!',
  //       }
  //     },
  //     fgp: {
  //       baseTitle: 'baseTitle',
  //       title: 'List of peaks and valleys',
  //       startDate: 'startDate',
  //       endDate: 'endDate',
  //       remark: 'remark',
  //       add_warning: 'add_warning',
  //       start_blank: 'start_blank',
  //       end_blank: 'end_blank',
  //     },
  //     fgpLine: {
  //       title: 'fgpTime',
  //       fgpType: 'fgpType',
  //       startTime: 'startTime',
  //       endTime: 'endTime',
  //       price: 'price',
  //       remark: 'remark',
  //       select_warning: 'select_warning',
  //       type_blank: 'type_blank',
  //       start_blank: 'start_blank',
  //       end_blank: 'end_blank',
  //       price_blank: 'price_blank',
  //       afterTip: {
  //         checkPeriod: 'The peak-valley flat time is not a continuous time, please modify it before saving!',
  //       }
  //     },
  //     serialNumber: {
  //       title: 'Encode build rules',
  //       ruleCode: 'ruleCode',
  //       ruleName: 'ruleName',
  //       ruleType: 'Serial number reset rules',
  //       serialLength: 'serialLength',
  //       currentValue: 'currentValue',
  //       initValue: 'initValue',
  //       step: 'step',
  //       prefix: 'prefix',
  //       curTime: 'curTime',
  //       systemTime: 'systemTime',
  //       split: 'split',
  //       select_warning: 'select_warning',
  //
  //
  //     },
  //     xxljob: {
  //       searchForm: {
  //         jobDesc: 'Please enter a description of the task',
  //         executorHandler: 'input_errorJobHandler',
  //         author: 'input_errorAuthor',
  //         PleaseSelectCategory: 'PleaseSelectCategory',
  //         TaskExecutor: 'TaskExecutor',
  //         DataPlatform: 'DataPlatform',
  //         all: 'all',
  //         StopIt: 'StopIt',
  //         initiate: 'initiate',
  //       },
  //       taskList: {
  //         headTitle: 'Task management',
  //         id: 'Task ID',
  //         jobDesc: 'jobDesc',
  //         runMode: 'runMode',
  //         cron: 'cronExpression',
  //         author: 'author',
  //         triggerStatus: 'triggerStatus',
  //       },
  //
  //     },
  //
  //   },
  //   qm: {
  //     warnCfg: {
  //       upperLimit: 'Upper Limit',
  //       lowerLimit: 'Lower Limit',
  //       isLimit: 'Is Limit',
  //       isConsecutive: 'Is Consecutive',
  //       isDefault: 'Is Default'
  //     },
  //     form: {
  //       input_error: 'input_error',
  //       select_error: 'select_error',
  //     },
  //     qmTeam: {
  //       headTitle: 'Quality inspection group',
  //       teamCode: 'teamCode',
  //       teamName: 'teamName',
  //       parentName: 'parentName',
  //       isEnable: 'isEnable',
  //       remark: 'remark',
  //       teamMemberTitle: 'teamMemberTitle',
  //       team: 'Please select Grouping first',
  //       tip_determine_del: 'If there is associated information in the quality inspection group, determine to delete the quality inspection group and the associated information？',
  //     },
  //     qmTeamMember: {
  //       headTitle: 'List of quality inspectors',
  //       teamMemberTitle: 'Quality inspectors',
  //       teamCode: 'teamCode',
  //       teamName: 'teamName',
  //       userCode: 'userCode',
  //       userName: 'userName',
  //       isGroupLeader: 'isGroupLeader',
  //       message: 'Selection cannot be repeated',
  //     },
  //     qmTeamIndex: {
  //       headTitle: 'List of quality inspection indicators',
  //       teamIndexTitle: 'Quality inspection indicators',
  //       teamCode: 'teamCode',
  //       teamName: 'teamName',
  //       indexCode: 'indexCode',
  //       indexName: 'indexName',
  //       unitCode: 'unitCode',
  //       unitName: 'unitName',
  //     },
  //     point: {
  //       headTitle: 'Sampling point',
  //       labelText: 'Added sampling point classification',
  //
  //       parentCode: 'parentCode',
  //       parentName: 'parentName',
  //       pointCode: 'pointCode',
  //       pointName: 'pointName',
  //       pointCatCode: 'pointCatCode',
  //       pointCatName: 'pointCatName',
  //       isEnable: 'isEnable',
  //       remark: 'remark',
  //       select_warning: 'select_warning',
  //     },
  //     index: {
  //       title: 'Quality inspection indicators',
  //       indexCode: 'indexCode',
  //       indexName: 'indexName',
  //       unitCode: 'unitCode',
  //       unitName: 'unitName',
  //       teamCode: 'teamCode',
  //       teamName: 'teamName',
  //       enableFormula: 'enableFormula',
  //       isEnable: 'isEnable',
  //       remark: 'remark',
  //     },
  //     indexFormula: {
  //       title: 'Indicator formula',
  //       indexCode: 'indexCode',
  //       indexName: 'indexName',
  //       indexFormulaCode: 'indexFormulaCode',
  //       indexFormulaName: 'indexFormulaName',
  //       indexFormulaDesc: 'indexFormulaDesc',
  //       indexFormulaButton: 'indexFormulaButton',
  //       remark: 'remark',
  //     },
  //     origIndex: {
  //       title: 'Template parameters',
  //       indexCode: 'indexCode',
  //       indexName: 'indexName',
  //       indexFormulaCode: 'indexFormulaCode',
  //       indexFormulaName: 'indexFormulaName',
  //       paramCode: 'paramCode',
  //       paramName: 'paramName',
  //       origDataType: 'origDataType',
  //       remark: 'remark',
  //     },
  //     template: {
  //       title: 'Quality inspection template',
  //       templateCode: 'templateCode',
  //       templateName: 'templateName',
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       isEnable: 'isEnable',
  //       remark: 'remark',
  //       isShi: 'isShi',
  //       isFou: 'isFou'
  //     },
  //     templatePoint: {
  //       propTitle: 'Sampling point',
  //       title: 'Quality inspection template sampling point mapping',
  //       templateCode: 'templateCode',
  //       templateName: 'templateName',
  //       pointCode: 'pointCode',
  //       pointName: 'pointName',
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       codeRuleCode: 'codeRuleCode',
  //       codeRuleName: 'codeRuleName',
  //       batchRuleCode: 'batchRuleCode',
  //       batchRuleName: 'batchRuleName',
  //       remark: 'remark',
  //     },
  //     templateIndex: {
  //       title: 'Quality inspection template indicators',
  //       templateCode: 'templateCode',
  //       templateName: 'templateName',
  //       teamCode: 'teamCode',
  //       teamName: 'teamName',
  //       indexCode: 'indexCode',
  //       indexName: 'indexName',
  //       unitCode: 'unitCode',
  //       unitName: 'unitName',
  //       limitRange: 'limitRange',
  //       sortNum: 'sortNum',
  //       indexFormulaCode: 'indexFormulaCode',
  //       indexFormulaName: 'indexFormulaName',
  //       indexFormulaDesc: 'indexFormulaDesc',
  //       remark: 'remark',
  //       select_warning: 'select_warning',
  //       afterTip: {
  //         defaultFormulaExist: 'defaultFormulaExist',
  //       }
  //     },
  //     templateIndexFormula: {
  //       title: 'Template formulas',
  //       templateCode: 'templateCode',
  //       indexCode: 'indexCode',
  //       indexName: 'indexName',
  //       indexFormulaCode: 'indexFormulaCode',
  //       indexFormulaName: 'indexFormulaName',
  //       indexFormulaDesc: 'indexFormulaDesc',
  //       isDefaultFormula: 'isDefaultFormula',
  //       quote: 'quote',
  //       remark: 'remark',
  //       select_warning: 'select_warning'
  //     },
  //     templateOrigIndex: {
  //       title: 'Template parameters',
  //       templateCode: 'templateCode',
  //       templateName: 'templateName',
  //       indexCode: 'indexCode',
  //       indexName: 'indexName',
  //       indexFormulaCode: 'indexFormulaCode',
  //       indexFormulaName: 'indexFormulaName',
  //       paramCode: 'paramCode',
  //       paramName: 'paramName',
  //       remark: 'remark',
  //     },
  //     plan: {
  //       demand: {
  //         title: 'Demand planning',
  //         year: 'year',
  //         itemCode: 'itemCode',
  //         itemName: 'itemName',
  //         unitName: 'unitName',
  //         totalQty: 'totalQty',
  //         sum: 'sum',
  //         janQty: 'janQty',
  //         febQty: 'febQty',
  //         marQty: 'febQty',
  //         aprQty: 'aprQty',
  //         mayQty: 'mayQty',
  //         junQty: 'junQty',
  //         julQty: 'julQty',
  //         augQty: 'augQty',
  //         sepQty: 'sepQty',
  //         octQty: 'octQty',
  //         novQty: 'novQty',
  //         decQty: 'decQty',
  //         itemTip: 'pleaseSelectProduct'
  //       },
  //       sale: {
  //         title: 'Sales plan',
  //         year: 'year',
  //         productCode: 'productCode',
  //         productName: 'productName',
  //         unitName: 'unitName',
  //         totalQty: 'totalQty',
  //         sum: 'sum',
  //         janQty: 'janQty',
  //         febQty: 'febQty',
  //         marQty: 'marQty',
  //         aprQty: 'aprQty',
  //         mayQty: 'mayQty',
  //         junQty: 'junQty',
  //         julQty: 'julQty',
  //         augQty: 'augQty',
  //         sepQty: 'sepQty',
  //         octQty: 'octQty',
  //         novQty: 'novQty',
  //         decQty: 'decQty',
  //       },
  //       produce: {
  //         title: 'Production planning',
  //         year: 'year',
  //         productCode: 'productCode',
  //         productName: 'productName',
  //         unitName: 'unitName',
  //         totalQty: 'totalQty',
  //         sum: 'sum',
  //         janQty: 'janQty',
  //         febQty: 'febQty',
  //         marQty: 'marQty',
  //         aprQty: 'aprQty',
  //         mayQty: 'mayQty',
  //         junQty: 'junQty',
  //         julQty: 'julQty',
  //         augQty: 'augQty',
  //         sepQty: 'sepQty',
  //         octQty: 'octQty',
  //         novQty: 'novQty',
  //         decQty: 'decQty',
  //       },
  //     },
  //     sample: {
  //       headTitle: 'sample',
  //       labelText: 'Added sampling point classification',
  //
  //       qualityType: 'qualityType',
  //       batchCode: 'batchCode',
  //       batchDtlCode: 'batchDtlCode',
  //       inspectionType: 'inspectionType',
  //       recordObjCode: 'recordObjCode',
  //       extItemName: 'extItemName',
  //       extItemCode: 'extItemCode',
  //       status: 'status',
  //       sampleDate: 'sampleDate',
  //       userName: 'userName',
  //       itemCodeAndName: 'itemCodeAndName/name',
  //       pointCodeAndName: 'pointCodeAndName/name',
  //       sampleCode: 'sampleCode',
  //       sampleBatchCode: 'sampleBatchCode',
  //       sampleType: 'sampleType',
  //       pointCode: 'pointCode',
  //       pointName: 'pointName',
  //       itemCode: 'itemCode',
  //       isRecord: 'isRecord',
  //       itemName: 'itemName',
  //       remark: 'remark',
  //       afterTip: {
  //         qmRecordObjUnExist: ' record object does not exist',
  //         qmRecordObjIndexUnExist: 'recordObject index isnot exist',
  //         qmRecordObjFormulaUnExist: 'recordObject formula isnot exist',
  //         qmRecordObjStandardUnExist: 'recordObject standard isnot exist',
  //         isNotPeriodType: 'current record object isnot period type  ',
  //       }
  //     },
  //     sampleLine: {
  //       headTitle: 'Sample details',
  //       // labelText:'新增取样点分类',
  //
  //       sampleCode: 'sampleCode',
  //       sampleBatchCode: 'sampleBatchCode',
  //       bzCode: 'bzCode',
  //       carNo: 'carNo/Ship number',
  //       extItemName: 'extItemName',
  //       extItemCode: 'extItemCode',
  //     },
  //     param: {
  //       title: 'Indicator parameters',
  //       indexParamCode: 'indexParamCode',
  //       indexParamName: 'indexParamName',
  //       indexDataType: 'indexDataType',
  //       indexDecimalDigit: 'indexDecimalDigit',
  //       unitCode: 'unitCode',
  //       unitName: 'unitName',
  //       isEnable: 'isEnable',
  //       remark: 'remark',
  //     },
  //     record: {
  //       title: 'Quality inspection records',
  //       recordCode: 'recordCode',
  //       sampleCode: 'sampleCode',
  //       templateCode: 'templateCode',
  //       sampleBatchCode: 'sampleBatchCode',
  //       sampleType: 'sampleType',
  //       samplePointCode: 'samplePointCode',
  //       samplePointCatName: 'samplePointCatName',
  //       itemCode: 'itemCode',
  //       itemName: 'itemName'
  //     },
  //     record_index: {
  //       sampleCode: 'sampleCode',
  //       indexCode: 'indexCode',
  //       indexValue: 'indexValue',
  //       indexAdjValue: 'indexAdjValue',
  //       limitRange: 'limitRange',
  //       isQualified: 'isQualified',
  //       select_warning: 'select_warning',
  //     },
  //     record_param: {
  //       title: 'Indicator parameters',
  //       sampleCode: 'sampleCode',
  //       indexCode: 'indexCode',
  //       indexName: 'indexName',
  //       paramCode: 'paramCode',
  //       paramName: 'paramName',
  //       paramValue: 'paramValue',
  //     },
  //     periodQualityTest: {
  //       teamCode: "teamCode",
  //       teamName: "teamName",
  //
  //       warning: {
  //         selectDateAndTeamCode: "select recordDate and TeamCode ！",
  //       }
  //     },
  //
  //   },
  //   pm: {
  //     dispatch: {
  //       dispatchName: 'dispatchName',
  //     },
  //     form: {
  //       input_error: 'input_error',
  //       select_error: 'select_error',
  //     },
  //     tip: {
  //       data_empty: 'current class no data',
  //     },
  //     stockQyt: {
  //       headTitle: 'Inventory on-hand',
  //       headListTitle: 'A list of inventory on-hand',
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       unitCode: 'unitCode',
  //       unitName: 'unitName',
  //       warehouseCode: 'warehouseCode',
  //       warehouseName: 'warehouseName',
  //       stockQty: 'stockQty',
  //     },
  //     deviceRunRecord: {
  //       headTitle: 'Equipment start-up and shutdown records',
  //       deviceCode: 'deviceCode',
  //       deviceStatus: 'deviceStatus',
  //       optTime: 'optTime',
  //       startTime: 'startTime',
  //       endTime: 'endTime',
  //       runTime: 'runTime',
  //       remark: 'remark'
  //     },
  //     deviceStatus: {
  //       headTitle: 'deviceStatus',
  //       selectTitle: 'DeviceInformation',
  //       deviceCode: 'deviceCode',
  //       deviceName: 'deviceName',
  //       deviceStatus: 'deviceStatus',
  //     },
  //     stockTansation: {
  //       headTitle: 'Raw materials are put into storage',
  //       factoryTitle: 'factoryTitle',
  //       materialTitle: 'materialTitle',
  //       productOutputTitle: 'productOutputTitle',
  //       headListTitle: 'A list of inventory on-hand',
  //       bzCode: 'bzCode',
  //       stockTime: 'stockTime',
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       itemCodeAndName: 'itemCodeAndName',
  //       warehouseCodeAndName: 'warehouseCodeAndName',
  //       unitName: 'unitName',
  //       stockQty: 'stockQty',
  //       warehouseName: 'warehouseName',
  //       warehouseCode: 'warehouseCode',
  //       startDate: 'startDate',
  //       endDate: 'endDate',
  //       afterTip: {
  //         stockQtyIsEmpty: 'There are no current items under the warehouse and cannot be shipped out！',
  //         underStock: 'The warehouse is not in sufficient stock to be shipped out！'
  //       }
  //     },
  //     stockIn: {
  //       headTitle: 'Items are put into storage',
  //       confirmStockIn: 'Confirm warehousing',
  //       btn: {
  //         tip_determine_have_finish_in_status: 'There is data that has been deposited and cannot be deleted',
  //         tip_determine_have_finish_in_status_row: 'The current data is in the database and cannot be deleted！',
  //         tip_determine_have_finish_in: 'The current item is put into storage！',
  //         tip_determine_have_finish_out: 'The current item is put into storage！',
  //       }
  //     },
  //     stockInLogistics: {
  //       baseInfo: 'baseInfo',
  //       labelTitle: 'labelTitle',
  //       headTitle: 'List of items',
  //       textTitle: 'Card information',
  //
  //       stockInCode: 'stockInCode',
  //       stockTransationType: 'stockTransationType',
  //       itemCodeAndName: 'itemCodeAndName',
  //       warehouseCodeAndName: 'warehouseCodeAndName',
  //       startDate: 'startDate',
  //       endDate: 'endDate',
  //       stockTime: 'stockTime',
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       unitName: 'unitName',
  //       stockInQty: 'stockInQty',
  //       warehouseName: 'warehouseName',
  //       carNo: 'carNo',
  //       logisticsCode: 'logisticsCode',
  //       supplierName: "supplierName",
  //       supplierCode: 'supplierCode',
  //       extItemName: 'extItemName',
  //       extItemCode: 'extItemCode',
  //       status: 'status',
  //       remark: 'remark',
  //     },
  //     stockInDcs: {
  //       labelTitle: 'DCSStorage',
  //       headTitle: 'List of items',
  //
  //       dcsCode: 'dcsCode',
  //       remark: 'remark',
  //       status: 'status',
  //     },
  //     stockInManual: {
  //       labelTitle: 'Manual warehousing',
  //       headTitle: 'List of items',
  //
  //       stockUser: 'stockUser',
  //       remark: 'remark',
  //       status: 'status',
  //
  //     },
  //     stockCheck: {
  //       title: 'Inventory records',
  //       stockCheckCode: 'stockCheckCode',
  //       startTime: 'startTime',
  //       endTime: 'endTime',
  //       realName: 'realName',
  //       participant: "participant",
  //       stockCheckTime: 'stockCheckTime',
  //       status: 'status',
  //       stockCheckType: 'stockCheckType',
  //       lineTitle: 'lineTitle',
  //       warehouseCode: 'warehouseCode',
  //       warehouseName: 'warehouseName',
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       stockQty: 'stockQty',
  //       stockCheckQty: 'stockCheckQty',
  //       stockTransationQty: 'stockTransationQty',
  //       locationCode: 'locationCode',
  //       locationName: 'locationName',
  //       paramList: "paramList",
  //       adjustQty: 'adjustQty',
  //       stockCheckCode_warning: 'stockCheckCode_warning',
  //       stockCheckType_warning: 'stockCheckType_warning',
  //       status_warning: 'status_warning',
  //       stockCheckTime_warning: 'stockCheckTime_warning',
  //       blank: 'Please complete the complete inventory checklist'
  //     },
  //     stockOut: {
  //       headTitle: 'Items are out of the warehouse',
  //     },
  //     stockOutLogistics: {
  //       labelTitle: 'One card out of the warehouse',
  //       headTitle: '物料列表',
  //       textTitle: 'Card information',
  //
  //       stockOutCode: 'stockOutCode',
  //       stockTransationType: 'stockTransationType',
  //       itemCodeAndName: 'itemCodeAndName',
  //       warehouseCodeAndName: 'warehouseCodeAndName',
  //       startDate: 'startDate',
  //       endDate: 'endDate',
  //       stockTime: 'stockTime',
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       unitName: 'unitName',
  //       stockOutQty: 'stockOutQty',
  //       warehouseName: 'warehouseName',
  //       carNo: 'carNo',
  //       logisticsCode: 'logisticsCode',
  //       customerName: "customerName",
  //       customerCode: 'customerCode',
  //       extItemName: 'extItemName',
  //       extItemCode: 'extItemCode',
  //       remark: 'remark',
  //       status: 'status',
  //     },
  //     stockOutDcs: {
  //       labelTitle: 'DCSOutbound',
  //       headTitle: 'List of items',
  //       stockOutCode: 'stockOutCode',
  //       stockTransationType: 'stockOutCode',
  //       itemCodeAndName: 'itemCodeAndName',
  //       warehouseCodeAndName: 'warehouseCodeAndName',
  //       startDate: 'startDate',
  //       endDate: 'endDate',
  //       stockTime: 'stockTime',
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       unitName: 'unitName',
  //       stockOutQty: 'stockOutQty',
  //       warehouseName: 'warehouseName',
  //       carNo: 'carNo',
  //       logisticsCode: 'logisticsCode',
  //       supplierName: "supplierName",
  //       supplierCode: 'supplierCode',
  //       extItemName: 'extItemName',
  //       extItemCode: 'extItemCode',
  //       dcsCode: 'dcsCode',
  //       remark: 'remark',
  //       status: 'status',
  //     },
  //     stockOutManual: {
  //       labelTitle: 'Manually out of the warehouse',
  //       headTitle: 'List of items',
  //
  //       stockOutCode: 'stockOutCode',
  //       stockTransationType: 'stockTransationType',
  //       itemCodeAndName: 'itemCodeAndName',
  //       warehouseCodeAndName: 'warehouseCodeAndName',
  //       startDate: 'startDate',
  //       endDate: 'endDate',
  //       stockTime: 'stockTime',
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       unitName: 'unitName',
  //       stockOutQty: 'stockOutQty',
  //       warehouseName: 'warehouseName',
  //       carNo: 'carNo',
  //       logisticsCode: 'logisticsCode',
  //       supplierName: "supplierName",
  //       supplierCode: 'supplierCode',
  //       extItemName: 'extItemName',
  //       extItemCode: 'extItemCode',
  //       stockUser: 'stockUser',
  //       remark: 'remark',
  //       status: 'status',
  //     },
  //     centralControlMeasurePoint: {
  //       labelTitle: 'Central control measurement point',
  //       headTitle: 'List of central measurement points',
  //       menuTitle: 'Classification properties',
  //       headCatTitle: 'The classification of central control measurement points has been added',
  //
  //       classify: 'classify',
  //       categoryName: 'categoryName',
  //       categoryCode: 'categoryCode',
  //       categoryFormula: 'categoryFormula',
  //       remark: 'remark',
  //       afterTip: {
  //         deletePmCategoryRecord: 'deletePmCategoryRecord！'
  //       }
  //     },
  //     centralControlIndex: {
  //       labelTitle: 'Central control measurement point',
  //       dialogTitle: 'Device property selection',
  //       categoryIndexCode: 'categoryIndexCode',
  //       deviceCode: 'deviceCode',
  //       devicePropertyCode: 'devicePropertyCode',
  //       devicePropertyName: 'devicePropertyName',
  //       categoryName: 'categoryName',
  //       categoryCode: 'categoryCode',
  //       categoryFormula: 'categoryFormula',
  //       remark: 'remark',
  //       standardPointClass: 'standardPointClass',
  //       standardPointType: 'standardPointType',
  //     },
  //   },
  //   authority: {
  //     list: 'Role management',
  //     rolePermissions: 'rolePermissions',
  //     webMenumissions: 'webMenumissions',
  //     appMenumissions: 'appMenumissions',
  //     dataPermissions: 'dataPermissions',
  //     protPermissions: 'protPermissions',
  //     roleName: "roleName",
  //     tenantId: "tenantId",
  //     roleAlias: 'roleAlias',
  //     roleType: 'roleType',
  //     parentId: "parentId",
  //     sort: 'sort',
  //     scopeName: 'scopeName',
  //     resourceCode: 'resourceCode',
  //     scopeColumn: 'scopeColumn',
  //     scopeType: 'scopeType',
  //     scopeField: 'scopeField',
  //     scopeClass: 'scopeClass',
  //     scopeValue: 'scopeValue',
  //     remark: 'remark',
  //     name: 'name',
  //     path: 'path',
  //     parentIds: "parentIds",
  //     source: 'source',
  //     code: 'code',
  //     category: 'category',
  //     alias: 'alias',
  //     action: 'action',
  //     sorts: 'sorts',
  //     isOpen: 'isOpen',
  //     remarks: 'remarks',
  //   },
  //   lnv: {
  //     arrival: {
  //       createUser: 'createUser',
  //       createTime: 'createTime',
  //       list: 'Arrival list',
  //       arrivalCode: 'arrivalCode',
  //       orderCode: 'orderCode',
  //       ncOrderCode: 'ncOrderCode',
  //       supplierName: 'supplierName',
  //       userName: 'userName',
  //       companyName: 'companyName',
  //       orderDeptName: 'orderDeptName',
  //       arrivalDate: 'arrivalDate',
  //       receiveType: 'receiveType',
  //       soureSystem: 'soureSystem',
  //       transportType: 'transportType',
  //       mineAddr: 'mineAddr',
  //       remark: 'remark',
  //       sonSupplierName: 'sonSupplierName',
  //       status: 'status',
  //       details: 'details',
  //       noEmpty: 'noEmpty',
  //       lineData: 'lineData',
  //     },
  //     arrivalLine: {
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       norms: 'norms',
  //       model: 'model',
  //       chartNo: 'chartNo',
  //       unit: 'unit',
  //       deliveryQuantity: 'deliveryQuantity',
  //       arrivalQuantity: 'arrivalQuantity',
  //       batchNo: 'batchNo',
  //       arrivalDate: 'arrivalDate',
  //       stockOrgName: 'stockOrgName',
  //       warehouseName: 'warehouseName',
  //       locationName: 'locationName',
  //       poundNo: 'poundNo',
  //       tareWeight: 'tareWeight',
  //       grossWeight: 'grossWeight',
  //       originPlace: 'originPlace',
  //       carNo: 'carNo',
  //     },
  //     rcpt: {
  //       list: 'Receipt',
  //       rcptCode: 'rcptCode',
  //       arrivalCode: 'arrivalCode',
  //       orderCode: 'orderCode',
  //       arrivalDate: 'arrivalDate',
  //       rcptDate: 'rcptDate',
  //       status: 'status',
  //       headTitle: 'Inbound details'
  //     },
  //     rcptline: {
  //       rcptDate: 'rcptDate',
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       quantity: 'quantity',
  //       unit: 'unit',
  //       batchNo: 'batchNo',
  //       stockOrgName: 'stockOrgName',
  //       warehouseName: 'warehouseName',
  //       locationName: 'locationName',
  //     },
  //     transaction: {
  //       itemCode: 'itemCode',
  //       list: 'Inventory transactions',
  //       batchId: 'batchId',
  //       itemName: 'itemName',
  //       billType: 'billType',
  //       billCode: 'billCode',
  //       quantity: 'quantity',
  //       unit: 'unit',
  //       orderCode: 'orderCode',
  //       transactionDate: 'transactionDate',
  //     },
  //     stock: {
  //       itemCode: 'itemCode',
  //       list: 'Inventory on-hand',
  //       stockOrgName: 'stockOrgName',
  //       warehouseName: 'warehouseName',
  //       locationName: 'locationName',
  //       itemName: 'itemName',
  //       stockQuantity: 'stockQuantity',
  //       occupyQuantity: 'occupyQuantity',
  //     },
  //     apply: {
  //       list: 'Application for use',
  //       issueApplyCode: 'issueApplyCode',
  //       ncOrderCode: 'ncOrderCode',
  //       applyUser: 'applyUser',
  //       applyCompanyName: 'applyCompanyName',
  //       applyDeptName: 'applyDeptName',
  //       sysUser: 'sysUser',
  //       stockOrgName: 'stockOrgName',
  //       warehouseName: 'warehouseName',
  //       projectName: 'projectName',
  //       receiveType: 'receiveType',
  //       applyDate: 'applyDate',
  //       status: 'status',
  //       remark: 'remark',
  //       createTime: 'createTime',
  //       createUser: 'createUser',
  //       examineEdit: 'examineEdit！',
  //       examineRemove: 'examineRemove！',
  //       inventoryDetails: 'inventoryDetails',
  //       submitForReview: 'submitForReview',
  //       choiceStockOrg: 'choiceStockOrg',
  //       choiceWarehouse: 'choiceWarehouse',
  //       stockErr: 'stockErr!',
  //     },
  //     applyLine: {
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       norms: 'norms',
  //       model: 'model',
  //       chartNo: 'chartNo',
  //       unit: 'unit',
  //       amount: 'amount',
  //       stockOrgName: 'stockOrgName',
  //       warehouseName: 'warehouseName',
  //       locationName: 'locationName',
  //       applyDate: 'applyDate',
  //       remark: 'remark',
  //       batchNo: 'batchNo',
  //       inventoryDetailsNoNull: 'inventoryDetailsNoNull',
  //       itemDetailsNoNull: 'itemDetailsNoNull',
  //       PC: 'PC',
  //     },
  //     issue: {
  //       list: 'Outbound order',
  //       issueCode: 'issueCode',
  //       issueApplyCode: 'issueApplyCode',
  //       ncOrderCode: 'ncOrderCode',
  //       applyUser: 'applyUser',
  //       applyCompanyName: 'applyCompanyName',
  //       applyDeptName: 'applyDeptName',
  //       sysUser: 'sysUser',
  //       deptName: 'deptName',
  //       companyName: 'companyName',
  //       stockOrgName: 'stockOrgName',
  //       warehouseName: 'warehouseName',
  //       receiveType: 'receiveType',
  //       projectName: 'projectName',
  //       remark: 'remark',
  //       status: 'status',
  //       stockOut: 'stockOut',
  //       stockOutDetail: 'stockOutDetail',
  //     },
  //     issueline: {
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       batchNo: 'batchNo',
  //       unit: 'unit',
  //       amount: 'amount',
  //       stockOrgName: 'stockOrgName',
  //       warehouseName: 'warehouseName',
  //       locationName: 'locationName',
  //       issueDate: 'issueDate',
  //       remark: 'remark',
  //     },
  //     materialStockDetail: {
  //       itemName: 'itemName',
  //       quantityReserved: 'quantityReserved',
  //       billCode: 'billCode',
  //       remark: 'remark',
  //     },
  //   },
  //   job: {
  //     task: {
  //       headTitle: 'Task management',
  //       jobGroup: 'jobGroup',
  //       jobCron: 'jobCron',
  //       jobDesc: 'jobDesc',
  //       addTime: 'addTime',
  //       updateTime: 'updateTime',
  //       author: 'author',
  //       alarmEmail: 'author',
  //       executorRouteStrategy: 'executorRouteStrategy',
  //       executorHandler: 'executorHandler',
  //       executorParam: 'executorParam',
  //       executorBlockStrategy: 'executorBlockStrategy',
  //       executorTimeout: 'executorTimeout',
  //       executorFailRetryCount: 'executorFailRetryCount',
  //       glueType: 'glueType',
  //       glueSource: 'glueSource',
  //       glueRemark: 'glueRemark',
  //       glueUpdatetime: 'glueUpdatetime',
  //       childJobId: 'childJobId',
  //       triggerStatus: 'triggerStatus',
  //       triggerLastTime: 'triggerLastTime',
  //       triggerNextTime: 'triggerNextTime',
  //       childJobMessage: 'Please enter subtasks, separated by commas',
  //
  //     },
  //     actuator: {
  //       headTitle: 'Actuator management',
  //       appName: 'AppName',
  //       title: 'name',
  //       order: 'order',
  //       addressType: 'addressType',
  //       addressList: 'addressList',
  //       addressMessage: 'Please enter a list of actuator addresses, separated by commas',
  //     },
  //     dispatch: {
  //       headTitle: 'Schedule logs',
  //       jobGroup: 'jobGroup',
  //       jobId: 'jobId',
  //       executorAddress: 'executorAddress',
  //       executorHandler: 'executorHandler',
  //       executorParam: 'executorParam',
  //       executorShardingParam: 'executorShardingParam',
  //       executorFailRetryCount: 'executorFailRetryCount',
  //       triggerTime: 'triggerTime',
  //       triggerCode: 'triggerCode',
  //       triggerMsg: 'triggerMsg',
  //       handleTime: 'handleTime',
  //       handleCode: 'handleCode',
  //       handleMsg: 'handleMsg',
  //       alarmStatus: 'alarmStatus',
  //       start: 'start',
  //       end: 'end',
  //       triggerLog: 'triggerLog',
  //       handleLog: 'handleLog',
  //     },
  //     headTitle: 'User management',
  //     username: 'username',
  //     password: 'password',
  //     role: 'role',
  //     permission: 'permission',
  //   },
  //   system: {
  //     serviceType: {
  //       title: 'System Info',
  //       name: 'Name',
  //       code: 'Code',
  //       type: 'Type',
  //       ip: 'IP Address',
  //       port: 'Port'
  //     },
  //     portal: {
  //       title: 'Visual Portal',
  //       customPath: 'Custom Path',
  //       confCustomPath: 'Conf Custom Path',
  //       page: 'Page Devise',
  //       drag: 'Drag generate portal',
  //       name: 'Portal Name',
  //       portal: 'Portal Category',
  //       enCode: 'Portal Code',
  //       sort: 'sort',
  //       status: 'status',
  //       remark: 'remark',
  //       basicSet: 'Basic Setting',
  //       addPortal: 'Add Portal',
  //       linkType: 'Link Type',
  //       linkPath: 'Link Path',
  //     },
  //     dblink: {
  //       title: 'DB Link',
  //       name: 'Name',
  //       host: 'Host',
  //       port: 'Port',
  //       driver: 'Driver',
  //       user: 'userName',
  //       password: 'password',
  //       dbName: 'DB Name',
  //       linkName: 'Link Name',
  //       sort: 'sort'
  //     },
  //     dataInterface: {
  //       handling: "data handling",
  //       name: "Name cannot be empty",
  //       statement: "Please enter SQL query statement or static data",
  //       interface: "Please fill in the interface path",
  //       Please: "Please select a category",
  //       code: "Code cannot be empty",
  //       Query: "Query",
  //       Add: "Add",
  //       Modify: "Modify",
  //       Delete: "Delete",
  //       staticData: "static Data",
  //       SQLOperations: "SQL Operations ",
  //       APIOperations: "API Operations",
  //       type: "type",
  //       chooseCategory: 'Please Choose Interface Category',
  //       basicInfo: 'Basic Info',
  //       dataConf: 'Data Conf',
  //       action: 'Action',
  //       sort: 'sort',
  //       status: 'status',
  //       description: 'remark',
  //       prev: 'prev',
  //       next: 'next',
  //       dataSource: 'Data Source',
  //       categoryId: 'Category',
  //       confirmButton: 'confirm',
  //       cancelButton: 'cancel',
  //       titleCate: 'Data Interface Category',
  //       title: 'Data Interface',
  //       fullName: 'Interface Name',
  //       enCode: 'Interface Code',
  //       dataType: 'Interface Type',
  //       responseType: 'Response Type',
  //       requestMethod: 'Request Method',
  //       changeDB: 'Choose DB',
  //       SQL: 'SQL sentence',
  //       content: 'Support for SQL statements&stored procedure statements',
  //       systemVariables: 'System Variables',
  //       msg: 'The current system variable only supports internal interface references',
  //       param: 'Interface Param',
  //       reviceMeth: 'Revice Method:Body/json',
  //       drag: 'drag',
  //       clickDrag: 'Click Drag',
  //       paramName: 'Param Name',
  //       paramType: 'Param Type',
  //       char: 'char',
  //       int: 'int',
  //       date: 'date',
  //       decimal: 'decimal',
  //       bigint: 'bigint',
  //       text: 'text',
  //       make: 'operate',
  //       addParam: 'Add Param',
  //       JSmsg: '1.Scripts that support JavaScript',
  //       JSmsg1: '2.Applets do not support online JS scripts',
  //       interfaceType: 'Interface Type',
  //       interfacePath: 'Interface Path',
  //       addHeader: 'Add headers',
  //       interfaceParam: 'InterfaceParam',
  //       defaultValue: 'Default Value'
  //     },
  //     guration: {
  //       name: 'name',
  //       content: 'content',
  //       parameterTitle: 'Configuration list',
  //     },
  //     feedback: {
  //       feedback: 'feedback',
  //       disposeResult: 'disposeResult',
  //       handler: 'handler',
  //       opinion: 'opinion',
  //       screenshot: 'screenshot',
  //       disposeTime: 'disposeTime',
  //       createTime: "createTime",
  //       submitter: 'submitter',
  //       message: 'userName',
  //       suggest: 'Please tell us about any problems or suggestions',
  //     },
  //     relationships: {
  //       regionName: 'regionName',
  //       regionCode: 'regionCode',
  //       parentId: 'parentId',
  //       type: 'type',
  //       unit: 'unit',
  //       area: 'area',
  //       yes: 'yes',
  //       no: 'no',
  //       sort: 'sort',
  //       remark: 'remark',
  //     },
  //     error: {
  //       errorstack: "Error stack",
  //       content: 'content',
  //       time: 'time',
  //       type: 'type',
  //       address: 'address',
  //       uploadServer: 'upload Server',
  //       clearLocalLog: 'clear Local Log',
  //       localErrorLog: 'Local Error Log',
  //       headTitle: 'Error log',
  //       serviceId: 'serviceId',
  //       serverHost: 'serverHost',
  //       serverIp: 'serverIp',
  //       env: "env",
  //       method: 'method',
  //       requestUri: 'requestUri',
  //       createTime: 'createTime',
  //       userAgent: 'userAgent',
  //       params: 'params',
  //       stackTrace: 'stackTrace',
  //     },
  //     api: {
  //       headTitle: 'Interface logs',
  //       serviceId: 'serviceId',
  //       serverHost: 'serverHost',
  //       serverIp: 'serverIp',
  //       env: "env",
  //       title: 'Log name',
  //       method: 'method',
  //       requestUri: 'requestUri',
  //       createTime: 'createTime',
  //       userAgent: 'userAgent',
  //       logData: 'logData',
  //       params: 'params',
  //     },
  //     usual: {
  //       headTitle: 'Generic logs',
  //       serviceId: 'serviceId',
  //       serverHost: 'serverHost',
  //       serverIp: 'serverIp',
  //       env: "env",
  //       logLevel: "logLevel",
  //       logId: 'logId',
  //       requestUri: 'requestUri',
  //       createTime: 'createTime',
  //       userAgent: 'userAgent',
  //       logData: 'logData',
  //       params: 'params',
  //     },
  //     params: {
  //       content: 'content',
  //       parameterTitle: 'System parameters',
  //       paramCode: 'paramCode',
  //       paramName: 'paramName',
  //       filePath: 'filePath',
  //       loadText: "Image uploading, please wait",
  //       typeError: "The template is malformed",
  //       paramType: 'paramType',
  //       paramValue: 'paramValue',
  //       releaseTime: 'releaseTime',
  //       publishTime: 'Publish Time',
  //       publish_status: 'Publish Status',
  //       textareaRich: 'textareaRich',
  //       remark: 'remark',
  //       tip_determine_del: 'OK will select Data deletion?',
  //       status: 'status',
  //       textarea: 'textarea',
  //       enable: 'enable',
  //       disable: 'disable',
  //       disenble: "disenble"
  //     },
  //     bottomMenu: {
  //       headTitle: 'Menu management at the bottom of the app',
  //       menuName: 'menuName',
  //       menuType: 'menuType',
  //       filePath: 'filePath',
  //       loadText: "Image uploading, please wait",
  //       typeError: "The template is malformed",
  //       sort: 'sort',
  //       remark: 'remark',
  //       roleIds: 'roleIds',
  //       alias: 'alias',
  //       brightPath: 'brightPath',
  //     },
  //     interface: {
  //       serviceType: 'Interface Category',
  //       paramFormat: 'Param Format',
  //       serviceOutput: 'Service Consumers',
  //       params: 'params',
  //       headTitle: 'Interface management',
  //       logHeadTitle: 'logHeadTitle',
  //       name: 'name',
  //       code: 'code',
  //       devName: 'devName',
  //       devUrl: 'devUri',
  //       requestMethod: 'Request Method',
  //       consumerName: 'consumerName',
  //       isRetry: 'isRetry',
  //       status: 'status',
  //       maxRetryCount: 'maxRetryCount',
  //       timeout: 'timeout',
  //       inputMethodName: 'inputMethodName',
  //       outputName: 'outputName',
  //       outputMethodName: 'outputMethodName',
  //       outputClassName: 'outputClassName',
  //       outputFormat: 'outputFormat',
  //       inputParamFormat: 'inputParamFormat',
  //       ipAddress: 'IP access settings',
  //       dialogTitle: 'dialogTitle',
  //       inputStatus: 'inputStatus',
  //       outputStatus: 'outputStatus',
  //       responseTimeExt: 'responseTimeExt',
  //       inputParams: 'inputParams',
  //       outputParams: 'outputParams',
  //       failedReason: 'failedReason',
  //       logListHeadTitle: 'logListHeadTitle',
  //     },
  //     knowledge: {
  //       treeTitle: 'KnowledgeType',
  //       remark: 'remark',
  //       filePath: 'filePath',
  //       headTitle: 'Knowledge base management',
  //       headCateTitle: 'Knowledge base type management',
  //       knowledgeCode: 'knowledgeCode',
  //       knowledgeName: 'knowledgeName',
  //       parentCode: 'parentCode',
  //       knowledgeTypeCode: 'knowledgeTypeCode',
  //       knowledgeTypeName: 'knowledgeTypeName',
  //     },
  //     adInfo: {
  //       headTitle: 'Carousel management',
  //       adName: 'adName',
  //       adType: 'adType',
  //       filePath: 'filePath',
  //       loadText: "Image uploading, please wait",
  //       typeError: "The template is malformed",
  //       sort: 'sort',
  //       remark: 'content',
  //       urlPath: 'urlPath',
  //     },
  //     report: {
  //       headTitle: 'Report management',
  //       reportName: 'reportName',
  //       reportType: 'reportType',
  //       filePath: 'filePath',
  //       loadText: "Image uploading, please wait",
  //       typeError: "The template is malformed",
  //       sort: 'sort',
  //       remark: 'remark',
  //       headCateTitle: 'Report category management',
  //       reportCode: 'reportCode',
  //       parentCode: 'parentCode',
  //       reportTypeCode: 'reportTypeCode',
  //       reportTypeName: 'reportTypeName',
  //       reportTypeId: 'reportTypeId',
  //       reportReviewPath: 'reportReviewPath'
  //     },
  //     dept: {
  //       list: 'Dept management',
  //       deptName: 'deptName',
  //       tenantId: 'tenantId',
  //       fullName: 'fullName',
  //       parentId: 'parentId',
  //       deptCategory: 'deptCategory',
  //       sort: 'sort',
  //       remark: 'remark',
  //     },
  //     dict: {
  //       list: "System dictionary",
  //       code: 'Dictionary number',
  //       dictValue: 'Dictionary name',
  //       sort: 'Dictionary sorting',
  //       isSealed: 'isSealed',
  //       remark: 'remark',
  //       parentId: "parentId",
  //       dictKey: 'dictKey',
  //       yes: 'yes',
  //       no: 'no'
  //     },
  //     dictbiz: {
  //       list: "Business dictionary",
  //     },
  //     menu: {
  //       filePath: 'filePath',
  //       loadText: 'loadText',
  //       color: 'color',
  //       platform: 'platform',
  //       typeSetting: 'Typography',
  //       list: 'Menu management',
  //       name: 'name',
  //       path: 'path',
  //       parentId: 'parentId',
  //       source: 'source',
  //       code: 'code',
  //       category: 'category',
  //       catelog: 'catelog',
  //       menus: "menus",
  //       button: "button",
  //       alias: 'alias',
  //       isOpen: 'isOpen',
  //       status: 'status',
  //       yes: "yes",
  //       no: 'no',
  //       sort: 'sort',
  //       remark: 'remark',
  //     },
  //     topMenu: {
  //       menuId: "menu bind",
  //       list: "Top menu",
  //       name: 'name',
  //       code: 'code',
  //       source: 'source',
  //       sort: 'sort',
  //       yes: 'yes',
  //       no: 'no',
  //       subDialogTitle: 'subDialogTitle'
  //     },
  //     post: {
  //       list: "Job management",
  //       category: 'category',
  //       postCode: 'postCode',
  //       postName: "postName",
  //       tenantId: 'tenantId',
  //       sort: 'sort',
  //       remark: 'remark',
  //       isEnable: 'isEnable',
  //     },
  //     user: {
  //       list: 'User management',
  //       roleConfig: 'roleConfig',
  //       userImport: 'userImport',
  //       download: 'download',
  //       platformConfig: 'platformConfig',
  //       title: 'title',
  //       customize: 'customize',
  //       account: 'account',
  //       realName: 'realName',
  //       realCode: 'realCode',
  //       userType: 'userType',
  //       tenantName: 'tenantName',
  //       roleName: 'roleName',
  //       deptName: 'deptName',
  //       baseInfo: 'baseInfo',
  //       detailInfo: 'detailInfo',
  //       name: 'name',
  //       nameLength: 'The name should be between 2 and 5 characters long',
  //       phone: 'phone',
  //       email: 'email',
  //       sex: 'sex',
  //       man: 'man',
  //       women: 'women',
  //       unknow: 'unknow',
  //       birthday: 'birthday',
  //       statusName: 'statusName',
  //       dutyInfo: 'dutyInfo',
  //       code: 'code',
  //       postId: "postId",
  //       password: 'password',
  //       password2: "Confirm the password",
  //       inputPassword: 'inputPassword',
  //       checkPassword: 'checkPassword',
  //       uncheckPassword: 'uncheckPassword',
  //       config: 'config',
  //       userExt: 'userExt',
  //       excelFile: 'excelFile',
  //       waiting: "Please wait while the template is uploading",
  //       format: 'Please upload .xls,.xlsx standard format file',
  //       isCovered: 'isCovered',
  //       yes: 'v',
  //       no: 'no',
  //       excelTemplate: "excelTemplate",
  //       restPassword: 'Make sure to reset the selected account password to 123456?',
  //       restAccount: 'Confirm that account unblock will be selected?',
  //       exportData: 'Whether to export user data?',
  //       notice: 'notice',
  //       userData: 'userData',
  //       userDataTemplate: 'userDataTemplate',
  //     },
  //     //租户管理
  //     tenant: {
  //       min: 'Data cannot be less than 1',
  //       list: 'Tenant management',
  //       authTitle: 'authTitle',
  //       dataSourceTitle: 'dataSourceTitle',
  //       packageSetTitle: 'packageSetTitle',
  //       packageManageTitle: 'packageManageTitle',
  //       datasource: 'datasource',
  //       packageName: 'packageName',
  //       menuView: 'menuView',
  //       tenantId: 'tenantId',
  //       tenantName: 'tenantName',
  //       linkman: 'linkman',
  //       contactNumber: 'contactNumber',
  //       address: 'address',
  //       accountNumber: 'accountNumber',
  //       expireTime: 'expireTime',
  //       domainUrl: 'domainUrl',
  //       tenantIdMessage: 'Automatically generated after tenant ID is saved',
  //       backgroundUrl: 'backgroundUrl'
  //     },
  //     //应用管理
  //     client: {
  //       list: 'Application management',
  //       clientId: 'clientId',
  //       clientSecret: 'clientSecret',
  //       authType: 'authType',
  //       authScope: 'Searching...',
  //       accessTokenValidity: 'accessTokenValidity',
  //       refreshTokenValidity: 'refreshTokenValidity',
  //       webServerRedirectUri: 'webServerRedirectUri',
  //       resourceIds: 'resourceIds',
  //       authorities: 'authorities',
  //       autoapprove: 'autoapprove',
  //       additionalInformation: 'additionalInformation'
  //     },
  //     //参数管理
  //     param: {
  //       list: 'Parameter management',
  //       paramName: 'paramName',
  //       paramKey: 'paramKey',
  //       paramValue: 'paramValue',
  //       paramRequired: 'whether it is required',
  //       paramRule: 'paramRule',
  //     },
  //     appVersion: {
  //       title: 'App version',
  //       verCode: 'verCode',
  //       appName: 'appName',
  //       enableFlag: 'enableFlag',
  //       appUrl: 'appUrl',
  //       downLoadPath: 'DownLoadPath',
  //       remark: 'remark',
  //       loadText: "Please wait while the template is uploading",
  //     }
  //   },
  //   pur: {
  //     form: {
  //       input_error: 'input_error',
  //       input_error_message: 'input_error_message',
  //       select_error: 'select_error',
  //     },
  //     contract: {
  //       tagName: 'tagName',
  //       headTitle: 'Item information',
  //       contractCode: "contractCode",
  //       contractName: "contractCode",
  //       companyName: "companyName",
  //       companyCode: "companyCode",
  //       deptName: "deptName",
  //       supplierName: "supplierName",
  //       supplierCode: 'supplierCode',
  //
  //       signDate: "signDate",
  //       contractDate: "contractDate",
  //       createTime: "createTime",
  //       startDate: "startDate",
  //       endDate: "endDate",
  //       transportType: "transportType",
  //       isFocus: "isFocus",
  //       paymentType: "paymentType",
  //       taxAmount: "taxAmount",
  //       createUserName: "createUserName",
  //       status: "status",
  //       amount: "amount",
  //       taxPrice: "taxPrice",
  //       taxRate: "taxRate",
  //       qualityIndex: "qualityIndex",
  //       deliveryAddr: "deliveryAddr",
  //       offlineCode: "offlineCode",
  //       voucherNo: "voucherNo",
  //       contractType: "contractType",
  //       remarks: "remarks",
  //       sourceSystem: "sourceSystem",
  //     },
  //     contractItem: {
  //       tagName: 'Purchase contracts',
  //       orderNo: "orderNo",
  //       companyId: "companyId",
  //       contractId: "contractId",
  //       itemId: "itemId",
  //       itemCode: "itemCode",
  //       itemName: "itemName",
  //       norms: "norms",
  //       model: "model",
  //       chartNo: "chartNo",
  //       unit: "unit",
  //       originPlace: "originPlace",
  //       qulitySpec: "qulitySpec",
  //       amount: "amount",
  //       taxPrice: "taxPrice",
  //       taxRate: "taxRate",
  //       price: "price",
  //       taxAmount: "taxAmount",
  //       voucherNo: "voucherNo",
  //       priceAmount: 'priceAmount',
  //       remarks: "remarks"
  //     },
  //     reqApply: {
  //       tagName: "Demand application",
  //       reqApplyCode: "reqApplyCode",
  //       reqApplyType: "reqApplyType",
  //       ncApplyCode: "ncApplyCode",
  //       reqApplyDate: "reqApplyDate",
  //       userName: "userName",
  //       createUserName: "createUserName",
  //       reqDeptCode: "reqDeptCode",
  //       reqDeptName: "reqDeptName",
  //       companyName: "companyName",
  //       stockOrgCode: "stockOrgCode",
  //       stockOrgName: "stockOrgName",
  //       remark: "remark",
  //       sourceSystem: "sourceSystem"
  //     },
  //     reqApplyL: {
  //       headTitle: 'Item information',
  //       orderNo: "orderNo",
  //       itemCode: "itemCode",
  //       itemName: "itemName",
  //       norms: "norms",
  //       model: "model",
  //       chartNo: "chartNo",
  //       unit: "unit",
  //       amount: "amount",
  //       price: "price",
  //       total: "total",
  //       purpose: "purpose",
  //       stockOrgCode: 'stockOrgCode',
  //       stockOrgName: "stockOrgName",
  //       warehouseName: 'warehouseName',
  //       locationName: 'locationName',
  //       applyDate: "applyDate",
  //     },
  //     reqPurchase: {
  //       tagName: "Requisition",
  //       headTitle: 'Item information',
  //       proApplyCode: "proApplyCode",
  //       companyName: "companyName",
  //       proApplyType: "proApplyType",
  //       reqPurchaseType: "reqPurchaseType",
  //       ncApplyCode: "ncApplyCode",
  //       reqPurchaseDate: "reqPurchaseDate",
  //       userName: "userName",
  //       reqName: "reqName",
  //       reqSource: "reqSource",
  //       reqDeptCode: "reqDeptCode",
  //       reqDeptName: "reqDeptName",
  //       receiveType: "receiveType",
  //       stockOrgCode: "stockOrgCode",
  //       stockOrgName: "stockOrgName",
  //       sourceSystem: "sourceSystem",
  //       status: "status",
  //     },
  //     reqPurchaseL: {
  //       itemCode: "itemCode",
  //       itemName: "itemName",
  //       norms: "norms",
  //       model: "model",
  //       chartNo: "chartNo",
  //       unit: "unit",
  //       amount: "amount",
  //       state: "state",
  //       price: "price",
  //       total: "amount",
  //       purpose: "purpose",
  //       stockOrgCode: 'stockOrgCode',
  //       stockOrgName: "stockOrgName",
  //       warehouseCode: "warehouseCode",
  //       warehouseName: "warehouseName",
  //       locationCode: "locationCode",
  //       locationName: "locationName",
  //       applyDate: "applyDate",
  //     },
  //     summary: {
  //       tagName: "Requirements summary",
  //       error_message: 'Select at least two pieces of data',
  //       error_1: 'Rollups cannot be repeated',
  //       error_2: 'Requirements are different and cannot be aggregated！',
  //     },
  //     invoice: {
  //       headTitle: 'Invoice entry',
  //
  //       invoiceCode: 'invoiceCode',
  //       ncInvoiceCode: 'ncInvoiceCode',
  //       invoiceType: 'invoiceType',
  //       orderCode: 'orderCode',
  //       contractCode: 'contractCode',
  //       contractName: 'contractName',
  //       invoiceDate: 'invoiceDate',
  //       userName: 'userName',
  //       deptName: 'deptName',
  //       companyName: 'companyName',
  //       orderDeptName: 'orderDeptName',
  //       supplierName: 'supplierName',
  //       userDeptName: 'userDeptName',
  //       seedSupplierName: 'seedSupplierName',
  //       taxAmount: 'taxAmount',
  //       taxRate: 'taxRate',
  //       amount: 'amount',
  //       invoiceNo: 'invoiceNo',
  //       invoiceCount: 'invoiceCount',
  //       imageNo: 'imageNo',
  //       imageType: 'imageType',
  //       eleInvoiceNo: 'eleInvoiceNo',
  //       arrivalCode: 'arrivalCode',
  //       balanceNo: 'balanceNo',
  //       remark: 'remark',
  //       createUser: 'createUser',
  //       createDept: 'createDept',
  //       status: 'status',
  //       createTime: 'createTime',
  //       start_error: "start_error",
  //       process_error: "process_error",
  //       save_success: 'save_success',
  //     },
  //     invoiceL: {
  //       headTitle: 'Invoice lines',
  //
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       batchNo: 'batchNo',
  //       norms: 'norms',
  //       model: 'model',
  //       chartNo: 'chartNo',
  //       unit: 'unit',
  //       quantity: 'quantity',
  //       taxClass: 'taxClass',
  //       taxRate: 'taxRate',
  //       taxPrice: 'taxPrice',
  //       taxAmount: 'taxAmount',
  //       price: 'price',
  //       amount: 'amount',
  //       remark: 'remark',
  //
  //       err_empty: 'Invoice lines can\'t be empty',
  //       err_no_link_invoice: 'The arrival order does not have data for unassociated invoices',
  //       err_no_has_order_data: 'The arrival order contains data that is not in the order',
  //       err_least_selection_one: 'Select at least one piece of dataAt least one piece of data',
  //       err_line_no_del_all: 'Row data cannot be deleted entirely',
  //       tip_determine_del: 'OK will select Data deletion?',
  //     },
  //     order: {
  //       title: 'Order line information',
  //       headTitleBatch: 'Bulk purchases',
  //       headTitle: 'General purchasing',
  //       batchNo: 'batchNo',
  //       quantity: 'quantity',
  //       taxClass: 'taxClass',
  //       taxPrice: 'taxPrice',
  //       price: 'price',
  //       orderCode: 'orderCode',
  //       orderName: 'orderName',
  //       ncOrderCode: 'ncOrderCode',
  //       contractCode: 'contractCode',
  //       contractName: 'contractName',
  //       orderType: 'orderType',
  //       orderDate: 'orderDate',
  //       deptCode: 'deptCode',
  //       deptId: 'deptId',
  //       deptName: 'deptName',
  //       orderDeptCode: 'orderDeptCode',
  //       orderDeptId: 'orderDeptId',
  //       orderDeptName: 'orderDeptName',
  //       companyCode: 'companyCode',
  //       companyName: 'companyName',
  //       orderOrgCode: 'orderOrgCode',
  //       orderOrgName: 'orderOrgName',
  //       supplierCode: 'supplierCode',
  //       supplierName: 'supplierName',
  //       transportType: 'transportType',
  //       isRetreat: 'isRetreat',
  //       // isFocus: '是否总部集采',
  //       isFocus: 'isFocus',
  //       coalClass: 'coalClass',
  //       shipCode: 'shipCode',
  //       userName: 'userName',
  //       mineAddr: 'mineAddr',
  //       purType: 'purType',
  //       userDeptCode: 'userDeptCode',
  //       userDeptName: 'userDeptName',
  //       seedSupplierCode: 'seedSupplierCode',
  //       seedSupplierName: 'seedSupplierName',
  //       taxAmount: 'taxAmount',
  //       taxRate: 'taxRate',
  //       amount: 'amount',
  //       isInvoice: 'isInvoice',
  //       invoiceNo: 'invoiceNo',
  //       invoiceCount: 'invoiceCount',
  //       sourceSystem: 'sourceSystem',
  //       createUserName: 'createUserName',
  //       createStatus: 'createStatus',
  //       createTime: 'createTime',
  //       reqPurchaseCode: 'reqPurchaseCode',
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       norms: 'norms',
  //       model: 'model',
  //       chartNo: 'chartNo',
  //       unit: 'unit',
  //       warehouseCode: "warehouseCode",
  //       arrivalDate: 'arrivalDate',
  //       stockOrgCode: 'stockOrgCode',
  //       stockOrgId: 'stockOrgId',
  //       stockOrgName: 'stockOrgName',
  //       warehouseName: 'warehouseName',
  //       isClose: 'isClose',
  //       remark: 'remark',
  //     },
  //   },
  //   inv: {
  //     form: {
  //       input: 'input',
  //       select: 'select',
  //     },
  //     stockCheck: {
  //       headTitle: 'Inventory',
  //
  //       stockCheckCode: 'stockCheckCode',
  //       ncOrderCode: 'ncOrderCode',
  //       invOrgName: 'invOrgName',
  //       warehouseName: 'warehouseName',
  //       companyName: 'companyName',
  //       userName: 'Warehouse administrator',
  //       status: 'status',
  //       updateTime: 'updateTime',
  //       createTime: 'createTime',
  //       remark: 'remark',
  //     },
  //     stockCheckL: {
  //       headTitle: 'Count rows',
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       accountAmount: 'accountAmount',
  //       checkAmount: 'checkAmount',
  //       differAmount: 'differAmount',
  //       unit: 'unit',
  //       remark: 'remark',
  //
  //
  //       checkAmountError: 'Please fill in the correct count quantity',
  //       updateError: 'The inventory data is saved and cannot be modified!',
  //     },
  //     billCheck: {
  //       headTitle: 'Reconciliation Information',
  //       billCheckCode: 'billCheckCode',
  //       companyCode: 'companyCode',
  //       companyName: 'companyName',
  //       supplierCode: 'supplierCode',
  //       supplierName: 'supplierName',
  //       arrivalAmount: 'arrivalAmount',
  //       taxTotal: 'taxTotal',
  //       buckleAmount: 'buckleAmount',
  //     },
  //     billCheckL: {
  //       headTitle: 'Reconcile line information',
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       norms: 'norms',
  //       model: 'model',
  //       chartNo: 'chartNo',
  //       unit: 'unit',
  //       buckleAmount: 'buckleAmount',
  //       arrivalAmount: 'arrivalAmount',
  //       taxPrice: 'taxPrice',
  //       price: 'price',
  //       quantity: 'quantity',
  //       tax: 'tax',
  //       taxAmount: 'taxAmount',
  //       priceAmount: 'taxAmount',
  //       taxConut: 'taxConut',
  //       ncRcptMemoCode: 'ncRcptMemoCode',
  //       ncArrivalCode: 'ncArrivalCode',
  //       ncOrderCode: 'ncOrderCode',
  //       rcptMemoCode: 'rcptMemoCode',
  //       arrivalCode: 'arrivalCode',
  //       orderCode: 'orderCode',
  //     },
  //   },
  //   sale: {
  //     form: {
  //       input_error: 'input_error',
  //       select_error: 'select_error',
  //       input_error_message: 'input_error_message',
  //     },
  //     customerOrde: {
  //       tagName: "tagName",
  //       cusOrderCode: 'cusOrderCode',
  //       customerName: 'customerName',
  //       businessName: 'businessName',
  //       deptName: 'deptName',
  //       orgCode: 'orgCode',
  //       orgName: 'orgName',
  //       productBrandName: 'productBrandName',
  //       userName: 'userName',
  //       arrivalAddr: 'arrivalAddr',
  //       itemCode: 'itemCode',
  //       itemName: 'itemName',
  //       areaFlow: 'areaFlow',
  //       orderAmount: 'orderAmount',
  //       orderPrice: 'orderPrice',
  //       freight: 'freight',
  //       sourceSystem: 'sourceSystem',
  //     },
  //     customerOrdeAppend: {
  //       tagName: "tagName",
  //       orderAmount: 'orderAmount',
  //       orderPrice: 'orderPrice',
  //       userName: 'Salesman',
  //       freight: 'freight',
  //       updateTime: 'updateTime',
  //     },
  //     quota: {
  //       tagName: "Credit management",
  //       quotaCode: 'The document number',
  //       creditType: 'creditType',
  //       userName: 'userName',
  //       begDate: 'begDate',
  //       endDate: 'endDate',
  //       corpName: 'corpName',
  //       deptName: 'deptName',
  //       quotaAmount: 'deptName',
  //       cusCode: 'cusCode',
  //       cusName: 'cusName',
  //       ncQuotaCode: 'ncQuotaCode',
  //       quotaType: 'quotaType',
  //       createTime: 'createTime'
  //     },
  //     orderSupply: {
  //       tagName: "bill of lading",
  //       orderSupplyCode: "orderSupplyCode",
  //       planDate: "planDate",
  //       customerName: "customerName",
  //       businessName: "businessName",
  //       productBrand: "productBrand",
  //       address: "address",
  //       driverName: "driverName",
  //       driverTel: "driverTel",
  //       driverCode: "driverCode",
  //       carNo: "carNo",
  //       settlement: "settlement",
  //       transportType: "transportType",
  //       userName: "Salesman",
  //       settlementBusinessName: "settlementBusinessName",
  //       salOrgCode: "salOrgCode",
  //       salOrgName: "salOrgName",
  //       stockOrgName: "stockOrgName",
  //       saleAreaName: "saleAreaName",
  //       ncOrderCode: "ncOrderCode",
  //       customerOrderCode: "customerOrderCode",
  //       itemName: "itemName",
  //       norms: "norms",
  //       model: "model",
  //       chartNo: "chartNo",
  //       unit: "unit",
  //       planAmount: "planAmount",
  //       actualAmount: "actualAmount",
  //       deptName: "deptName",
  //       settType: 'settType',
  //     },
  //     orderReturn: {
  //       tagName: "Payment receipt",
  //       returnCode: "returnCode",
  //       businessType: "businessType",
  //       corpName: "corpName",
  //       sendCorpName: "sendCorpName",
  //       cashType: "cashType",
  //       moneyType: "moneyType",
  //       bankNum: "bankNum",
  //       currency: "currency",
  //       bankType: "bankType",
  //       rate: "rate",
  //       tradeObject: "tradeObject",
  //       cusName: "cusName",
  //       fundState: "fundState",
  //       userName: "Salesman",
  //       deptName: "deptName",
  //       ncReturnCode: "ncReturnCode",
  //       collectionAmount: "collectionAmount",
  //       amountProject: "amountProject",
  //       planProject: "planProject",
  //       createTime: "createTime",
  //     },
  //     companyPlan: {
  //       tagName: "Company sales plan",
  //       planType: "planType",
  //       businessName: "businessName",
  //       totalAmount: "totalAmount",
  //       createTime: "createTime",
  //       createUserName: "createUserName",
  //       status: "status",
  //       remark: "remark",
  //     },
  //     planL: {
  //       tagName: 'Department information',
  //       deptName: "Department name",
  //       planAmount: "planAmount",
  //       updateTime: "updateTime",
  //       remark: "remark",
  //     },
  //     deptPlan: {
  //       tagName: "Departmental sales plans",
  //       businessName: "businessName",
  //       deptName: "deptName",
  //       totalAmount: "totalAmount",
  //     },
  //     deptPlanL: {
  //       message: "The planned volume is out of range",
  //       tagName: "tagName",
  //       customerName: "customerName",
  //       planAmount: "planAmount"
  //     },
  //     contract: {
  //       tagName: 'Sales Contract',
  //       headTitle: 'Item information',
  //       contractCode: "contractCode",
  //       contractName: "contractName",
  //       companyName: "companyName",
  //       companyCode: "companyCode",
  //       deptName: "deptName",
  //       supplierName: "supplierName",
  //       customerName: 'customerName',
  //       supplierCode: 'supplierCode',
  //       signDate: "signDate",
  //       contractDate: "contractDate",
  //       createTime: "createTime",
  //       startDate: "startDate",
  //       endDate: "endDate",
  //       transportType: "transportType",
  //       isFocus: "isFocus",
  //       paymentType: "paymentType",
  //       taxAmount: "taxAmount",
  //       createUserName: "createUserName",
  //       status: "status",
  //       amount: "The contract does not include tax amounts",
  //       taxPrice: "taxPrice",
  //       taxRate: "taxRate",
  //       qualityIndex: "qualityIndex",
  //       deliveryAddr: "deliveryAddr",
  //       offlineCode: "offlineCode",
  //       voucherNo: "voucherNo",
  //       contractType: "contractType",
  //       remarks: "remarks",
  //       sourceSystem: "sourceSystem",
  //     },
  //     contractItem: {
  //       tagName: 'Sales Contract',
  //       orderNo: "orderNo",
  //       companyId: "companyId",
  //       contractId: "contractId",
  //       itemId: "itemId",
  //       itemCode: "itemCode",
  //       itemName: "itemName",
  //       norms: "norms",
  //       model: "model",
  //       chartNo: "chartNo",
  //       unit: "unit",
  //       originPlace: "originPlace",
  //       qulitySpec: "qulitySpec",
  //       amount: "amount",
  //       taxPrice: "taxPrice",
  //       taxRate: "taxRate",
  //       price: "price",
  //       taxAmount: "taxAmount",
  //       voucherNo: "voucherNo",
  //       priceAmount: 'priceAmount',
  //       remarks: "remarks"
  //     },
  //   },
  //   cps: {
  //     removeSpaces: 'Please delete the spaces before and after',
  //     createTime: 'createTime',
  //     import: 'import',
  //     importData: 'importData',
  //     download: 'download',
  //     export: 'export',
  //     importFormat: 'Excel format file import',
  //     importLoading: 'Importing data, please wait...',
  //     remark: 'remark',
  //     saveHead: 'saveHead',
  //     model: {
  //       exportData: 'exportModelData？',
  //       modelData: '【Data acquisition platform】Equipment model data',
  //       modelTemplate: '【Data Acquisition Platform】Equipment model import template',
  //       modelCode: 'modelCode',
  //       modelName: 'modelName',
  //       subModelCode: 'subModelCode',
  //       subModelName: 'subModelName',
  //       isEnable: 'isEnable',
  //       version: 'version',
  //       list: 'modelManagement',
  //       title: 'model',
  //       property: 'property',
  //       event: 'event',
  //       service: 'service',
  //       calc: 'calc',
  //       select: 'select',
  //       devicePicture: 'Model picture',
  //       picture: 'Only jpg/png model images with a maximum size of 500kb can be uploaded',
  //       modelClassify: 'ClassificationModel'
  //     },
  //     modelType: {
  //       title: 'ClassificationModel',
  //       parentCode: 'parentCode',
  //       parentName: 'parentName',
  //       modelTypeCode: 'modelTypeCode',
  //       modelTypeName: 'modelTypeName',
  //       orderNum: 'orderNum',
  //       isEnable: 'isEnable',
  //       ten: "A number up to 10 digits",
  //     },
  //     modelProperty: {
  //       modelPropertyCode: 'modelPropertyCode',
  //       modelPropertyName: 'modelPropertyName',
  //       dataType: 'dataType',
  //       variableType: 'variableType',
  //       standardPointCode: 'standardPointCode',
  //       rangeMax: 'rangeMax',
  //       rangeMin: 'rangeMin',
  //       isEnable: 'isEnable',
  //     },
  //     modelEvent: {
  //       modelEventCode: 'modelEventCode',
  //       modelEventName: 'modelEventName',
  //       dataType: 'dataType',
  //       variableType: 'variableType',
  //       standardPointCode: 'standardPointCode',
  //       modelEventType: 'modelEventType',
  //       isEnable: 'isEnable',
  //     },
  //     modelService: {
  //       modelServiceCode: 'modelServiceCode',
  //       modelServiceName: 'modelServiceName',
  //       dataType: 'dataType',
  //       variableType: 'variableType',
  //       standardPointCode: 'standardPointCode',
  //       rangeMax: 'rangeMax',
  //       rangeMin: 'rangeMin',
  //       isEnable: 'isEnable',
  //     },
  //     modelCalc: {
  //       selectInputParam: 'selectInputParam',
  //       modelCalcCode: 'modelCalcCode',
  //       modelCalcName: 'modelCalcName',
  //       resultDataType: 'resultDataType',
  //       calcMethod: 'calcMethod',
  //       inputParam: 'inputParam',
  //       isSave: 'isSave',
  //       isPush: 'isPush',
  //       isEnable: 'isEnable',
  //     },
  //     protocol: {
  //       title: 'Protocol management',
  //       protocolCode: 'protocolCode',
  //       protocolName: 'protocolName',
  //       protocolType: 'protocolType',
  //       protocolParams: 'protocolParams',
  //       isEnable: 'isEnable',
  //       select: 'Please select an agreement',
  //       heartbeatTopic: 'heartbeatTopic'
  //     },
  //     monitoring: {
  //       title: 'DataAcquisitionMonitoring',
  //       terminalCoding: 'terminalCoding',
  //
  //       terminalName: 'terminalName',
  //       agreementName: 'agreementName',
  //       isEnable: 'isEnable',
  //       createTime: 'createTime',
  //       isStatus: 'isStatus',
  //       heartbeatTopic: 'heartbeatTopic',
  //       select: 'select',
  //       connect: 'connect',
  //       break: 'break',
  //       protocolParams: 'protocolParams',
  //       equipment: 'equipment',
  //       cardPattern: 'cardPattern',
  //       listPattern: 'listPattern',
  //       getAI: 'AI',
  //       getDI: 'DI',
  //       standardPointCode: 'standardPointCode：',
  //       eventualCreateTime: 'eventualCreateTime：',
  //       history: 'history',
  //       chart: 'chart',
  //       earlyWarning: 'earlyWarning',
  //       ipLocation: 'ipLocation',
  //       clsid: 'clsid',
  //       deviceCode: 'deviceCode',
  //       gaugingType: 'gaugingType',
  //       remark: 'remark',
  //       today: 'today',
  //       week: 'week',
  //       month: 'month',
  //       until: 'until',
  //       beginTime: 'beginTime',
  //       endTime: 'endTime',
  //       export: 'export',
  //       currenttime: 'The end time cannot be later than the current time',
  //       exceed: "The time range cannot exceed one day",
  //       noData: 'noData',
  //       template: '[Data mining platform] device data mining export template',
  //       exportMonitoring: '[Data collection platform] equipment data collection',
  //       exportPoint: 'historical data',
  //       deviceCard: 'device',
  //       DeviceSummation: 'Point',
  //       station: 'selectPoint',
  //       device: 'selectDevice',
  //       deviceName: 'deviceName',
  //       deviceEventCode: 'PointCoding',
  //       stationName: 'PointName',
  //       stationCode: 'PointCoding',
  //       variableType: 'types of variables',
  //       modelName: 'ModelName',
  //       netName: 'logical place',
  //       locationName: 'physical location',
  //       enable: 'Have been enabled',
  //       notEnabled: 'not enabled',
  //       leastOne: 'Please select at least one piece of data'
  //
  //     },
  //     device: {
  //
  //       chinese: 'You cannot enter Chinese characters',
  //       exportData: 'Decide to export device data？',
  //       deviceData: '【Data acquisition platform】Equipment data acquisition data',
  //       deviceTemplate: '【Data acquisition platform】Equipment data acquisition import template',
  //       autoValue: 'Automatically generated by default',
  //       list: 'Device management',
  //       CSV: 'Link to CSV chart',
  //       deviceCode: 'deviceCode',
  //       deviceName: 'deviceName',
  //       productCode: 'productCode',
  //       productName: 'productName',
  //       modelCode: 'modelCode',
  //       modelName: 'modelName',
  //       subModelCode: 'subModelCode',
  //       subModelName: 'subModelName',
  //       selectModel: 'select model',
  //       deviceType: 'deviceType',
  //       deviceKey: 'deviceKey',
  //       protocolCode: 'protocolCode',
  //       protocolName: 'protocolName',
  //       locationName: 'locationName',
  //       netName: 'netName',
  //       deviceTags: 'deviceTags',
  //       topicUrl: 'topicUrl',
  //       protocolParams: 'protocolParams',
  //       isEnable: 'isEnable',
  //       property: 'property',
  //       event: 'event',
  //       service: 'service',
  //       calc: 'calc',
  //       checkModel: 'Please select the model first',
  //       updateModel: 'update model',
  //       modelUpdate: 'Are you sure to regenerate device details based on the associated model? The old details will be overwritten',
  //       netTitle: 'logical location classification',
  //       locTitle: 'physical location classification',
  //       select: 'device',
  //       normal: 'normalQuantity',
  //       abnormal: 'abnormalQuantity',
  //       enableDeviceCount: 'normalQuantity',
  //       disableDeviceCount: 'abnormalQuantity',
  //       dragin: 'auto in by default',
  //       selectprotocolParams: 'Please selectprotocol configuration',
  //     },
  //     locationType: {
  //       title: 'Physical location classification',
  //       parentName: 'The parent classification name',
  //       locationCode: 'locationCode',
  //       locationName: 'locationName',
  //       orderNum: 'orderNum',
  //       isEnable: 'isEnable'
  //     },
  //     netType: {
  //       title: 'Logical location classification',
  //       netCode: 'netCode',
  //       netName: 'netName',
  //       orderNum: 'orderNum',
  //       isEnable: 'isEnable',
  //       heartbeatTopic: 'heartbeatTopic'
  //     },
  //     deviceProperty: {
  //       devicePropertyCode: 'devicePropertyCode',
  //       devicePropertyName: 'devicePropertyName',
  //       dataType: 'dataType',
  //       variableType: 'variableType',
  //       standardPointCode: 'standardPointCode',
  //       rangeMax: 'rangeMax',
  //       rangeMin: 'rangeMin',
  //       dcsCode: 'dcsCode',
  //       isEnable: 'isEnable',
  //     },
  //     deviceEvent: {
  //       deviceEventCode: 'deviceEventCode',
  //       deviceEventName: 'deviceEventName',
  //       dataType: 'dataType',
  //       variableType: 'variableType',
  //       standardPointCode: 'standardPointCode',
  //       deviceEventType: 'deviceEventType',
  //       dcsCode: 'dcsCode',
  //       isEnable: 'isEnable',
  //       gaugingType: 'floating-pointType',
  //     },
  //     deviceService: {
  //       deviceServiceCode: 'deviceServiceCode',
  //       deviceServiceName: 'deviceServiceName',
  //       dataType: 'dataType',
  //       variableType: 'variableType',
  //       standardPointCode: 'standardPointCode',
  //       rangeMax: 'rangeMax',
  //       rangeMin: 'rangeMin',
  //       dcsCode: 'dcsCode',
  //       isEnable: 'isEnable',
  //     },
  //     deviceCalc: {
  //       selectInputParam: 'Please select an input parameter',
  //       deviceCalcCode: 'deviceCalcCode',
  //       deviceCalcName: 'deviceCalcName',
  //       resultDataType: 'resultDataType',
  //       calcMethod: 'calcMethod',
  //       inputParam: 'inputParam',
  //       isSave: 'isSave',
  //       isPush: 'isPush',
  //       isEnable: 'isEnable',
  //     },
  //
  //   },
  //   start: {
  //     icon: 'icon',
  //     ProcessDesign: 'ProcessDesign',
  //     ProcessIdentification: 'ProcessIdentification',
  //     ProcessClassification: 'ProcessClassification',
  //     version: 'version',
  //     state: 'state',
  //     activation: 'activation',
  //     Suspend: 'Suspend',
  //   },
  //   MyDone: {
  //     nameOfTheProcess: 'nameOfTheProcess',
  //     ProcessIdentification: 'ProcessIdentification',
  //     SerialNumber: 'SerialNumber',
  //     ProcessClassification: 'ProcessClassification',
  //     applicant: 'applicant',
  //     OperationsNode: 'OperationsNode',
  //     OperationTime: 'OperationTime',
  //   },
  //   send: {
  //     nameOfTheProcess: 'nameOfTheProcess',
  //     ProcessIdentification: 'ProcessIdentification',
  //     SerialNumber: 'SerialNumber',
  //     ProcessClassification: 'ProcessClassification',
  //     TheCurrentNode: 'TheCurrentNode',
  //     ApplicationTime: 'ApplicationTime',
  //     ProcessStatus: 'ProcessStatus',
  //   },
  //   todo: {
  //     nameOfTheProcess: 'nameOfTheProcess',
  //     ProcessIdentification: 'ProcessIdentification',
  //     SerialNumber: 'SerialNumber',
  //     ProcessClassification: 'ProcessClassification',
  //     TheCurrentNode: 'TheCurrentNode',
  //     applicant: 'applicant',
  //     ApplicationTime: 'ApplicationTime'
  //   },
  //   desk: {
  //     portalSwitch: "Portal switching",
  //     notice: 'notice',
  //     title: 'Notification title',
  //     category: 'category',
  //     releaseTimeRange: 'releaseTimeRange',
  //     releaseTime: 'releaseTime',
  //     content: 'content',
  //   },
  //   message: {
  //     channel: {
  //       headTitle: 'Channel management',
  //       sort: 'sort',
  //       remark: 'sort',
  //       channelCode: 'channelCode',
  //       channelName: 'channelName',
  //       channelType: 'channelType',
  //       status: 'status',
  //       createTime: 'createTime',
  //       ChannelUserIDSource: "ChannelUserIDSource",
  //       channelDescribe: 'channelDescribe',
  //       msgType: "message type"
  //     },
  //     template: {
  //       headTitle: 'Message template management',
  //       headItemTitle: 'Message type',
  //       sort: 'sort',
  //       remark: 'remark',
  //       templateCode: 'templateCode',
  //       templateName: 'templateName',
  //       templateType: 'templateType',
  //       templateMode: 'templateMode',
  //       statusStr: 'statusStr',
  //       status: 'status',
  //       taskStatuStr: 'taskStatuStr',
  //       createTime: 'createTime',
  //       ListenForAndCronExpressions: "ListenForAndCronExpressions",
  //       TemplateDescription: 'TemplateDescription',
  //       msgTypeEncoding: 'Message type encoding',
  //       msgTypeName: 'Message type name',
  //     },
  //     templateParam: {
  //       headTitle: 'Input parameters',
  //       sort: 'sort',
  //       remark: 'remark',
  //     },
  //     templateParties: {
  //       headTitle: 'headTitle',
  //       sort: 'sort',
  //       remark: 'remark',
  //       receiveFromType: 'receiveFromType',
  //       presetPersonnel: 'presetPersonnel',
  //       inputParameter: 'inputParameter',
  //     },
  //     templateRule: {
  //       headTitle: 'Message rules',
  //       sort: 'sort',
  //       remark: 'remark',
  //       startTime: 'startTime',
  //       endTime: 'endTime',
  //       maxFrequency: 'maxFrequency',
  //       keywordFiltering: 'keywordFiltering',
  //     },
  //     templateChannel: {
  //       headTitle: 'Send channel',
  //       sort: 'sort',
  //       remark: 'remark',
  //       titleFormat: 'titleFormat',
  //       title: 'title',
  //       contentFormat: 'contentFormat',
  //       content: 'content',
  //     },
  //     sendInfo: {
  //       headTitle: 'Message manifest',
  //       msgCode: 'Message ID',
  //       msgForm: 'msgForm',
  //       msgType: 'msgType',
  //       sendModel: 'sendModel',
  //       sendNum: 'sendNum',
  //       sendNumCensus: 'sendNumCensus(total/success/failure)',
  //       sendTime: 'sendTime',
  //       detailCode: 'detailCode',
  //       channelName: 'channelName',
  //       sendAccount: 'sendAccount',
  //       receiveAccount: 'receiveAccount',
  //       msgTile: 'msgTile',
  //       msgSetting: 'message setting',
  //       msgContent: 'msgContent',
  //       sendStatus: 'sendStatus',
  //       startDate: 'startDate',
  //       endDate: 'endDate',
  //       detailsHeadTitle: 'detailsHeadTitle',
  //       templateName: 'templateName',
  //       templateCronExpr: 'templateCronExpr',
  //       successMsgNum: 'successMsgNum',
  //       failMsgNum: 'failMsgNum',
  //       msgDetailList: 'msgDetailList',
  //       detailInfoTitle: 'detailInfoTitle',
  //       messageTemplateId: 'messageTemplateId',
  //       titleParam: 'titleParam',
  //       contentParam: 'contentParam',
  //       sendParam: 'sendParam',
  //       receiveParam: 'receiveParam',
  //     },
  //     user: {
  //       list: 'userManage',
  //       roleConfig: 'userRoleConfig',
  //       userImport: 'userImport',
  //       download: 'download',
  //       platformConfig: 'platformConfig',
  //       title: 'title',
  //       customize: 'customize',
  //       account: 'account',
  //       realName: 'realName',
  //       realCode: 'realCode',
  //       userType: 'userType',
  //       tenantName: 'tenantName',
  //       roleName: 'roleName',
  //       deptName: 'deptName',
  //       baseInfo: 'baseInfo',
  //       detailInfo: 'detailInfo',
  //       name: 'name',
  //       nameLength: 'Name is 2-5 characters long',
  //       phone: 'phone',
  //       email: 'email',
  //       sex: 'sex',
  //       man: 'man',
  //       women: 'women',
  //       unknow: 'unknow',
  //       birthday: 'birthday',
  //       statusName: 'statusName',
  //       dutyInfo: 'dutyInfo',
  //       code: 'code',
  //       postId: "postId",
  //       password: 'password',
  //       password2: "Confirm Password",
  //       inputPassword: 'Please input a password',
  //       checkPassword: 'Please enter the password again',
  //       uncheckPassword: 'The passwords entered twice are inconsistent',
  //       config: 'config',
  //       userExt: 'userExt',
  //       excelFile: 'excelFile',
  //       waiting: "Template uploading, please wait",
  //       format: 'Please upload. xls,. xlsx standard format files',
  //       isCovered: 'Data coverage',
  //       yes: 'yes',
  //       no: 'no',
  //       excelTemplate: "Template Download",
  //       restPassword: 'Are you sure to reset the selected account password to 123456?',
  //       restAccount: 'Confirm to unseal the selected account?',
  //       exportData: 'Whether to export user data?',
  //       notice: 'notice',
  //       userData: 'userData',
  //       userDataTemplate: 'userDataTemplate',
  //     },
  //     error: {
  //       headTitle: 'errorLog',
  //       serviceId: 'serviceId',
  //       serverHost: 'serverHost',
  //       serverIp: 'serverIp',
  //       env: "env",
  //       method: 'method',
  //       requestUri: 'requestUri',
  //       createTime: 'createTime',
  //       userAgent: 'userAgent',
  //       params: 'params',
  //       stackTrace: 'stackTrace',
  //     },
  //     api: {
  //       headTitle: 'apiLog',
  //       serviceId: 'serviceId',
  //       serverHost: 'serverHost',
  //       serverIp: 'serverIp',
  //       env: "env",
  //       title: 'title',
  //       method: 'method',
  //       requestUri: 'requestUri',
  //       createTime: 'createTime',
  //       userAgent: 'userAgent',
  //       logData: 'logData',
  //       params: 'params',
  //     },
  //     usual: {
  //       headTitle: 'usualLog',
  //       serviceId: 'serviceId',
  //       serverHost: 'serverHost',
  //       serverIp: 'serverIp',
  //       env: "env",
  //       logLevel: "logLevel",
  //       logId: 'logId',
  //       requestUri: 'requestUri',
  //       createTime: 'createTime',
  //       userAgent: 'userAgent',
  //       logData: 'logData',
  //       params: 'params',
  //     },
  //     dept: {
  //       list: 'deptManage',
  //       deptName: 'deptName',
  //       tenantId: 'tenantId',
  //       fullName: 'fullName',
  //       parentId: 'parentId',
  //       deptCategory: 'deptCategory',
  //       sort: 'sort',
  //       remark: 'remark',
  //     },
  //   },
  //   dataobject: {
  //     //数据对象
  //     message: {
  //       delmsg1: "Please select the data object to delete",
  //       delmsg2: "Please operate individually",
  //       delmsg3: "Are you sure you want to delete the current data object?",
  //     },
  //     placeholder: {
  //       p1: "Please select type",
  //       p2: "Please enter the code",
  //       p3: "Please select identification",
  //       p4: "Please enter comments",
  //     },
  //     table: {
  //       code: "code",
  //       identification: "identification",
  //       type: "type",
  //       edit: "edit",
  //       remark: "remark",
  //     },
  //     title: {
  //       tip: "tip",
  //       tip2: "see",
  //       name: "data object"
  //     },
  //     formlabel: {
  //       databank: "databank",
  //       Apiinterface: "Apiinterface",
  //       tree: "tree",
  //       tableName: "tableName",
  //       saveField: "saveField",
  //       ConditionField: "ConditionField",
  //       comparisonField: "comparisonField",
  //       fieldName: "fieldName",
  //       fieldComments: "fieldComments",
  //       enterValue: "enterValue",
  //       default: "default",
  //     }
  //   }
  // },

  title: 'Sinoma is a framework',
  tip: 'tip',
  logoutTip: 'Exit the system, do you want to continue?',
  submitText: 'submit',
  cancelText: 'cancel',
  search: 'Please input search content',
  menuTip: 'none menu list',
  moreMenu: "More",
  wel: {
    more: 'more',
    myreport: 'My Report',
    info: 'Good morning, Sinoma is a framework',
    dept: 'a certain technology department',
    team: 'Team ranking',
    project: 'Project access',
    count: 'Item number',
    todo: 'To Do',
    knowledge: 'knowledge',
    // data: {
    //   subtitle: 'real time',
    //   column1: 'Classified statistics',
    //   column2: 'Annex statistics',
    //   column3: 'Article statistics',
    //   key1: 'C',
    //   key2: 'A',
    //   key3: 'A',
    //   text1: 'Total Record Number of Classifications',
    //   text2: 'Number of attachments Uploaded',
    //   text3: 'Comment frequency'
    // },
    // data2: {
    //   column1: 'Registration today',
    //   column2: 'Login today',
    //   column3: 'Subscription today',
    //   column4: 'Todays review'
    // },
    // data3: {
    //   column1: 'Conversion rate（Day 28%）',
    //   column2: 'Attendance rate（Day 11%）',
    //   column3: 'Attendance rate（Day 33%）'
    // },
    // data4: {
    //   column1: 'Error log',
    //   column2: 'Data display',
    //   column3: 'Privilege management',
    //   column4: 'user management'
    // },
    // table: {
    //   rw: 'Work Tasks',
    //   nr: 'Work content',
    //   sj: 'Working hours',
    // }
  },
  userinfo: {
    openWeakPassword: "openWeakPassword",
    closeWeakPassword: "closeWeakPassword",
    weakPassword: 'Enable Weak Password',
    personalDetails: 'Personal Details',
    avatar: 'avatar',
    userinfotip: 'only upload format jpg or png, and size less than 500KB',
    name: 'Name',
    username: 'userName',
    phone: 'phone',
    isTenant: "Tenant Status",
    themeColor: "Theme Color",
    openTenant: "openTenant",
    closeTenant: "closeTenant",
    email: 'email',
    editPasswd: 'Edit PassWd',
    oldpasswd: 'Old PassWord',
    newPasswd: 'New PassWord',
    chooseLangue: 'choose Langue',
    confirmPasswd: 'confirm PassWord',
    passwdMsg: 'The password should be at least 8 digits and contain numbers, uppercase and lowercase letters and special characters',
    differentPassword: 'The two passwords are inconsistent',
    oldPasswordNotNull: 'The original password cannot be empty',
    theme: "Please hiddenTrouble the theme color dictionary configuration",
    newPasswordNotNull: 'The new password cannot be empty',
    title1: '* Attention',
    title2: '：Please reset your password for the first login',
    sysConfig: 'System Config',
    sysIcon: 'System Icon',
    LoginBJ: "Login Background Image",
    loginIcon: 'Login Icon',
    NavigationIcon: 'Navigation Icon',
    LOGOIcon: 'LOGO Icon',
    AppIcon: 'APP Icon',
    SysName: 'System Name',
    title: 'title',
    Sysedition: 'Platform Name',
    companyName: 'Company Name',
    copyright: 'copyright',
    companyCode: 'Company Abbreviated Name',
    companyAddress: 'Company Address',
    companyContacts: 'Company Contacts',
    companyTelePhone: 'Company Phone',
    companyEmail: 'Company Email',
    sysDescription: 'System Description',
    save: 'Save',
    clear: 'Clear',
  },
  route: {
    classificationRole:"classificationRole",
    yearProducePlan: "Year Produce Plan",
    yearProducePlanAdd: "Year Produce PlanAdd",
    yearProducePlanEdit: "Year Produce PlanEdit",
    yearProducePlanView: "Year Produce PlanView",
    yearPurchasePlan: "Year Purchase Plan",
    yearPurchasePlanAdd: "Year Purchase PlanAdd",
    yearPurchasePlanEdit: "Year Purchase PlanEdit",
    yearPurchasePlanView: "Year Purchase PlanView",
    yearSalePlan: "Year Sale Plan",
    yearSalePlanAdd: "Year Sale PlanAdd",
    yearSalePlanEdit: "Year Sale PlanEdit",
    yearSalePlanView: "Year Sale PlanView",
    monthProducePlan: "Month Produce Plan",
    monthProducePlanAdd: "Month Produce PlanAdd",
    monthProducePlanEdit: "Month Produce PlanEdit",
    monthProducePlanView: "Month Produce PlanView",
    monthPurchasePlan: "Month Purchase Plan",
    monthPurchasePlanAdd: "Month Purchase PlanAdd",
    monthPurchasePlanEdit: "Month Purchase PlanEdit",
    monthPurchasePlanView: "Month Purchase PlanView",
    monthSalePlan: "Month Sale Plan",
    monthSalePlanAdd: "Month Sale PlanAdd",
    monthSalePlanEdit: "Month Sale PlanEdit",
    monthSalePlanView: "Month Sale PlanView",
    indexCfg: "Indicator configuration",
    recordV3: "Quality inspection records(List)",
    deviceFaultAdd: "Reason for shutdownAdd or Edit",
    deviceFault: "Reason for shutdown",
    inspectionReport: "Quality Inspection Sample Report",
    periodDate: "Period date management",
    sampleView: "sampleView",
    params: "params manage",
    paramsAdd: "paramsAdd",
    paramsEdit: "paramsEdit",
    paramsView: "paramsView",
    period: "accounting period",
    powerFgp: "configuration management",
    powerFgpAdd: "configuration managementAdd",
    powerFgpEdit: "configuration managementEdit",
    powerFgpView: "configuration managementView",
    qualityScheduling: "Quality scheduling",
    unit: "Unit management",
    unitAdd: "Unit managementAdd",
    unitEdit: "Unit managementEdit",
    unitView: "Unit managementView",
    extItem: "ERP materials",
    extItemAdd: "ERP materialsAdd",
    extItemEdit: "ERP materialsEdit",
    extItemView: "ERP materialsView",
    supplier: "Basic supplier information",
    supplierAdd: "Basic supplier informationAdd",
    supplierEdit: "Basic supplier informationEdit",
    supplierView: "Basic supplier informationView",
    customer: "Basic customer information",
    customerAdd: "Basic customer informationAdd",
    customerEdit: "Basic customer informationEdit",
    customerView: "Basic customer informationView",
    batch: "Batch Information",
    record: "Quality inspection records",
    periodQualityTest: "periodQualityTest",
    sample: "sample",
    sampleRecord: "Sample Record",
    sampleRecordEdit: "Sample Record Editing",
    sampleRecordAdd: "Sample Record Add",
    sampleRecordView: 'Sample Record View',
    batchDetails: "Batch Details",
    itemPurchaseAdd: "One card entry into the factoryAdd",
    itemPurchaseEdit: "One card entry into the factoryEdit",
    itemPurchaseView: "One card entry into the factoryView",
    productSaleAdd: "One card factoryAdd",
    productSaleEdit: "One card factoryEdit",
    productSaleView: "One card factoryView",
    itemBalance: "Item Balance",
    itemBalanceAdd: "Item Balance Add",
    itemBalanceEdit: "Item Balance Edit",
    itemBalanceView: "Item Balance View",
    batchSplit: "Batch cutting rules",
    batchSplitView: "Batch cutting rulesView",
    batchSplitEdit: "Batch cutting rulesEdit",
    batchSplitAdd: "Batch cutting rulesAdd",
    recordObj: "Quality inspection object",
    recordObjAdd: "Quality inspection objectAdd",
    recordObjEdit: "Quality inspection objectEdit",
    recordObjView: "Quality inspection objectView",
    point: "Sampling point",
    pointAdd: "Sampling pointAdd",
    pointEdit: "Sampling pointEdit",
    pointView: "Sampling pointView",
    noticeModel: "Notice Model",
    noticeModelEdit: "Notice Model Edit",
    dischargingNotice: "Discharging Notice",
    incomingMaterialUsage: "Incoming Material Usage",
    qualityControlAdjustmentNotice: "Quality Control Adjustment Notice",
    factoryControlNotice: "Factory Control Notice",
    otherNotices: "Other Notices",
    qualityNotice: "Quality Notice",
    qmTeam: "Quality inspection team",
    qmIndex: "Quality inspection indicators",
    qmIndexAdd: "Quality inspection indicatorsAdd",
    qmIndexEdit: "Quality inspection indicatorsEdit",
    qmIndexView: "Quality inspection indicatorsView",
    deviceIndex: "Quality inspection equipment",
    deviceIndexDetail: "Quality inspection equipment Detail",
    deviceData: "Quality control device data collection",
    indexFormula: "indexFormula",
    indexFormulaAdd: "indexFormulaAdd",
    indexFormulaEdit: "indexFormulaEdit",
    indexFormulaView: "indexFormulaView",
    qmParam: "Indicator parameters",
    qmParamAdd: "Indicator parametersAdd",
    qmParamView: "Indicator parametersView",
    qmParamEdit: "Indicator parametersEdit",
    codeRule: "codeRule",
    codeRuleEdit: "EncodingRuleEdit",
    codeRuleAdd: "EncodingRuleAdd",
    codeRuleView: "EncodingRuleView",
    feedback: "Feedback",
    feedbackView: "FSeedbackEdit",
    functionRecord: "Function Record",
    functionFrequency: "Function Frequency",
    buttonRecord: "Button Record",
    buttonFrequency: "Button Frequency",
    dictbizConfig: "Business Dictionary",
    dictConfig: "System Dictionary",
    config: "Dictionary Configuration",
    international: "Internationalization modification",
    gurationEdit: "Configuration library",
    serviceType: 'Interface Info',
    dataLink: 'Data Link',
    dataLinkEdit: 'Data LinkEdit',
    dataInterface: 'Data Interface',
    visualPortal: 'Visual Portal',
    guration: 'Configuration list',
    monitorAdd: 'Region informationEdit',
    monitor: 'Region information',
    aboutUs: 'About us',
    aboutUsEdit: 'About usEdit',
    attendance: 'Attendance',
    clockingRecord: "Clocking record",
    attendanceAdd: 'Attendance Add',
    JobTask: 'Task list',
    JobTaskEdit: 'Task list editing',
    JobTaskAdd: 'Task list adding',
    dispatch: 'Schedule logs',
    dispatchEdit: 'Schedule log added',
    actuator: 'Actuator management',
    actuatorEdit: 'Executor management edits',
    monitoring: 'Monitoring points',
    monitoringEdit: 'Monitoring pointsEdit',
    oss: 'Object Storage',
    attach: 'Attach Management',
    folderManage: 'folder Manage',
    relationships: 'Regional relationships',
    relationshipsAdd: 'Regional relationshipsEdit',
    info: 'info',
    website: 'website',
    sms: 'SMSConfiguration',
    avuexwebsite: 'avuex',
    dashboard: 'dashboard',
    more: 'more',
    tags: 'tags',
    store: 'store',
    permission: 'permission',
    logs: 'logs',
    table: 'table',
    form: 'form',
    top: 'backtop',
    data: 'data',
    test: 'test',
    apiLog: 'apiLog',
    errorLog: 'Error Log',
    localErrorLog: "Local Error Log",
    usualLog: 'usualLog',
    user: 'userManage',
    userAdd: 'userAdd',
    userEdit: 'userEdit',
    dept: 'deptManage',
    deptEdit: 'deptEdit',
    report: 'reportManagement',
    myReport: 'My Report',
    knowledge: 'knowledge',
    post: 'JobManagement',
    postEdit: 'JobManagementEdit',
    dict: 'SystemDictionary',
    dictEdit: 'SystemDictionaryEdit',
    dictbiz: 'BusinessDictionary',
    dictbizEdit: 'BusinessDictionaryEdit',
    menu: 'MenuManagement',
    menuEdit: 'MenuManagementEdit',
    topmenu: 'TopMenu',
    param: 'Parameter management',
    paramEdit: 'Parameter managementEdit',
    tenant: 'Tenant management',
    tenantEdit: 'Tenant managementEdit',
    client: 'Application management',
    clientEdit: 'Application managementEdit',
    serialNumber: 'Encode build rules',
    serialNumberAdd: 'Encode build rulesAdd',
    serialNumberView: 'Encode build rulesView',
    serialNumberEdit: 'Encode build rulesEdit',
    index: 'maintenance scheduling',
    role: 'Role management',
    roleEdit: 'Role managementEdit',
    datascope: 'Data permissions',
    apiscope: 'apiscope',
    model: 'model design',
    process: 'model designEdit',
    modelHistory: 'Model History',
    forms: 'Form design',
    formsEdit: 'Form designEdit',
    formHistory: 'Form History',
    deployment: 'Deployment Management',
    buttonEdit: 'Process buttonEdit',
    button: 'Process button',
    categoryEdit: 'Process classificationEdit',
    category: 'Process classification',
    formDefaultValues: 'Form Defaults',
    formDefaultValuesEdit: 'Form DefaultsEdit',
    conditionEdit: 'Process expression',
    condition: 'Process expressionEdit',
    done: 'Settlement process',
    list: 'Process list',
    proxy: 'Process Agent',
    proxyEdit: 'Process AgentEdit',
    adInfo: 'broadcast management',
    broadcast: 'Rotation management',
    usual: 'General log',
    apis: 'Interface log',
    errors: 'errors log',
    appMenu: 'APP Menu Management',
    appMenuAdd: 'APP Menu Add',
    appMenuEdit: 'APP Menu Edit',
    appVersion: 'App version',
    appVersionadd: 'App versionEdit',
    bottomMenu: 'App bottom menu management',
    bottomMenuEdit: 'App bottom menu managementEdit',
    interfaceList: 'Interface management',
    interfaceListEdit: 'Interface managementEdit',
    interfaceLogEdit: 'Interface logEdit',
    interfaceLog: 'Interface log',
    channel: 'Channel Management',
    channelAdd: "Channel Management Add",
    channelEdit: "Channel Management Edit",
    channelView: "Channel Management View",
    templates: 'Message template management',
    templatesAdd: 'Message Template Management Add',
    templatesEdit: 'Message Template Management Edit',
    sendInfoEdit: 'Message listEdit',
    sendInfo: 'Message list',
    messageBasicInformation: "Message Basic Information",
    mediumscreen: 'Medium screen management',
    onineUser: 'Online User',
    mediumscreenmanageAdd: 'Medium screen Add',
    mediumscreenmanageEdit: "Medium screen Edit",
    mediumscreenmanageView: "Medium screen View",
    parameter: 'system parameter',
    meterEdit: 'system parameterEdit',
    noticeAdd: 'Notice announcementAdd',
    noticeEdit: 'Notice announcementEdit',
    notices: 'Notice announcement',
    processStart: 'New Process',
    processTodo: 'To Do',
    processSend: 'My request',
    processMyDone: 'My done',
    processDone: 'Closing matters',
    processClaim: 'Pending transactions',
    processCopy: 'Cc matters',
    paramAdd: 'Parameter managementEdit',
    adInfoEdit: 'New rotation management',
    broadcastEdit: 'broadcast managementEdit',
    knowledgeAdd: "Knowledge Add",
    knowledgeEdit: 'Knowledge Edit',
    knowledgeView: "Knowledge View",
    reportAdd: "Report Add",
    reportEdit: 'Report Edit',
    reportView: "Report View",
    zInterfaceAc: 'IncrementalInterfaceAcquisition',
    excelList:"Excel Data Import List",
    zInterfaceAcEdit: 'IncrementalInterfaceAcquisitionEdit',
    qInterfaceAc: 'FullInterfaceAcquisition',
    qInterfaceAcEdit: 'FullInterfaceAcquisitionEdit',
    tokenRe: 'tokenRe',
    tokenReEdit: 'tokenReEdit',
    offline: 'OfflinedataCollection',
    excelForNavicat: 'excelForNavicat',
    InterfaceSystemConfiguration: 'InterfaceSystemConfiguration',
    datasource: 'DataSourceManagement',
    datasourceEdit: 'DataSourceManagementEdit',
    datacolumn: 'metadataManagement',
    dataSubject: 'dataSubject',
    NewDataModel:"NewDataModel",
    qualityTemplate:"qualityTemplate",
    DetectionTaskManagement:"DetectionTaskManagement",
    dataSubjectEdit: 'dataSubjectEdit',
    dataDictionary: 'dataDictionary',
    dataDictionaryEdit: 'dataDictionaryEdit',
    DataDirectory: 'DataDirectory',
    dataapi:"Data service interface",
    Primordial: 'Primordial',
    testingTaskAdd:"testingTaskAdd",
    testingTask:"testingTask",
    judgeTask:"Configuration judgment task",
    judgeTaskadd:"Configuration judgment tas Add",
    PrimordialEdit: 'PrimordialEdit',
    PrimordialMold: 'PrimordialMold',
    shunt: 'DataDiversion',
    JudgeResults:"JudgeResults",
    taskRecordDetail:"TaskRecordDetail",
    AiModel:"AiModel",
    AiSet:"AiSet",
    AiSetEdit:"AiSetEdit",
    suanfa:"Algorithm management",
    suanfadetail:"Algorithm management Detail",
    AiModelDetail:"AiModelDetail",
    shuntEdit: 'DataDiversionEdit',
    transformation: 'DataConversion',
    transformationEdit: 'DataConversionEdit',
    injection: 'DataInjectionConfiguration',
    injectionEdit: 'DataInjectionConfigurationEdit',
    dataConvergence: 'DataConvergence',
    configEdit: 'configEdit',
    groupingEdit: 'groupingEdit',
    DataAggregationoutputEdit: 'DataAggregationoutputEdit',
    DataConfig: 'DataConfig',
    DataConfigEdit: 'DataConfigEdit',
    sjcj: 'DataCollection',
    sjcjEdit: 'DataCollectionEdit',
    DataAggregation: 'DataAggregation',
    ProjectList: "ProjectList",
    taskScheduling: "taskScheduling",
    DataObject: 'DataObject',
    messageList: "My Message",
    //生产管理
    dispatchStart: "Production Scheduling",
    demandPlan: "Demand Planning",
    salePlan: "Sale Planning",
    producePlan: "Produce Planning",
    operatePlan: "Operate Planning",
    operatePlanDetail: "Operate Planning Detail",
    stockIn: "Material Receipt",
    oneCardStorageAdd: "One Card Storage Add",
    oneCardStorageEdit: "One Card Storage Edit",
    oneCardStorageView: "One Card Storage View",
    dcsAdd: "DCS Warehousing Add",
    dcsEdit: "DCS Warehousing Edit",
    dcsView: "DCS Warehousing View",
    manualAdd: "Manual Warehousing Add",
    manualEdit: "Manual Warehousing Edit",
    manualView: "Manual Warehousing View",
    stockOut: "Material Outbound",
    logisticsAddTitle: "One Card outbound Add",
    logisticsEditTitle: "One Card outbound Edit",
    logisticsViewTitle: "One Card outbound View",
    dcsTitle: "DCS Outbound",
    dcsAddTitle: "DCS Outbound Add",
    dcsEditTitle: "DCS Outbound Edit",
    dcsViewTitle: "DCS Outbound View",
    manualTitle: "Manual Outbound",
    manualAddTitle: "Manual Outbound Add",
    manualEditTitle: "Manual Outbound Edit",
    manualViewTitle: "Manual Outbound View",
    stockCheck: "Material Inventory",
    materialInventoryAdd: "Material Inventory Add",
    materialInventoryEdit: "Material Inventory Edit",
    materialInventoryView: "Material Inventory View",
    stock: "Inventory Query",
    centralControl: "Measurement Point Subscription",
    classReport: "Team daily",
    classReportAdd: "Team daily Add",
    classReportEdit: "Team daily Edit",
    classReportView: "Team daily View",
    deviceQuery: "Start/Stop Query",
    deviceStatus: "Device Status",
    deviceStatusAdd: "Device Status Add",
    deviceStatusEdit: "Device Status Edit",
    deviceStatusView: "Device Status View",
    classReportDtlHour: "Production Collection Modification",
    deviceRecord: "Equipment Startup/Shutdown Record",
    deviceRecordAdd: "Equipment Startup/Shutdown Record Add",
    deviceRecordEdit: "Equipment Startup/Shutdown Record Edit",
    deviceRecordView: "Equipment Startup/Shutdown Record View",
    classReportDtl: "Modification Of Team daily Report",
    device: 'device',
    deviceAdd: 'deviceAdd',
    deviceView: 'deviceView',
    deviceEdit: 'deviceEdit',
    modelManagement: 'model',
    modelAdd: 'modelAdd',
    modelView: 'modelView',
    modelEdit: 'modelEdit',
    protocol: 'protocol',
    protocolAdd: 'protocolAdd',
    protocolView: 'protocolView',
    protocolEdit: 'protocolEdit',
    pointConfiguration: "pointConfiguration",
    cacheData: 'cacheData',
    workRecord: 'Work Record',
    projectTc: "Project Management",
    projectTcAdd: "Project Add",
    projectTcEdit: "Project Edit",
    projectTcView: "Project View",
    //基础设置
    processSetting: "Process Setting",
    processSettingAdd: "Process Setting Add",
    processSettingEdit: "Process Setting Edit",
    class: "Team",
    classAdd: "Team Add",
    classEdit: "Team Edit",
    classView: "Team View",
    classShift: "ClassShift",
    classShiftEdit: "ClassShift Edit",
    classShiftView: "ClassShift View",
    classShiftRule: "ClassShiftRule",
    classShiftRuleAdd: "ClassShiftRule Add",
    classShiftRuleEdit: "ClassShiftRule Edit",
    classShiftRuleView: "ClassShiftRule View",
    classCalendar: "Scheduling Calendar",
    classCalendarView: "Scheduling Calendar View",
    productionMaterial: "Production Material",
    productionMaterialAdd: "Production Material Add",
    productionMaterialEdit: "Production Material Edit",
    productionMaterialView: "Production Material View",
    warehouseManagement: "Warehouse Management",
    warehouseAdd: "Warehouse Add",
    warehouseEdit: "Warehouse Edit",
    warehouseView: "Warehouse View",
    physicalWarehouseManagement: "Physical Warehouse Management",
    physicalWarehouseAdd: "Physical Warehouse Add",
    physicalWarehouseEdit: "Physical Warehouse Edit",
    physicalWarehouseView: "Physical Warehouse View",
    itemFormula: "Production Formula",
    itemFormulaEdit: "Production Formula Edit",
    itemFormulaView: "Production Formula View",
    processManagement: "Process Management",
    processAdd: "Process Add",
    processEdit: "Process Edit",
    regionalMaintenance: "Regional Maintenance",
    regionalMaintenanceAdd: "Regional Maintenance Add",
    regionalMaintenanceEdit: "Regional Maintenance Edit",
    pointsConfiguration: "Point Configuration",
    cameraAdd: "Camera Information Add",
    cameraEdit: "Camera Information Edit",
    RMPAdd: "Regional Monitoring Point Add",
    RMPEdit: "Regional Monitoring Point Edit",
    EMPAdd: "Equipment Monitoring Point Add",
    EMPEdit: "Equipment Monitoring Point Edit",
    videoAdd: "Large Screen Video Configuration Add",
    videoEdit: "Large Screen Video Configuration Edit",
    deviceManagement: "Device Management",
    mainDeviceCateAdd: "Main Device Cate Add",
    mainDeviceCateEdit: "Main Device Cate Edit",
    subDeviceCateAdd: "Sub Device Cate Add",
    subDeviceCateEdit: "Sub Device Cate Edit",
    mainDeviceAdd: "Main Device Add",
    mainDeviceEdit: "Main Device Edit",
    subDeviceAdd: "Sub Device Add",
    subDeviceEdit: "Sub Device Edit",
    sparePartAdd: "Spare Part List Add",
    sparePartEdit: "Spare Part List Edit",
    postManagement: "Post Management",
    rankAdd: "Rank Add",
    rankEdit: "Rank Edit",
    sectionAdd: "Management Section Add",
    sectionEdit: "Management Section Edit",
    majorAdd: "Management Major Add",
    majorEdit: "Management Major Edit",
    responsibilityAdd: "Responsibility Add",
    responsibilityEdit: "Responsibility Edit",
    shiftModeAdd: "Shift Mode Add",
    shiftModeEdit: "Shift Mode Edit",
    waterRate: "Moisture Coefficient",
    modelManager: "Model Manager",
    indexCfgEdit: "Can manage index configuration editing",
    indexCfgAdd: "Management index configuration added",
    indexCfgView: "View indicator configuration",
    //index
    IndicatorDefinition:"IndicatorDefinition",
    IndicatorInterface:"IndicatorInterface",
    appCategoryView: "Indicator View",
    appCategoryIndex: "Indicator System",
    appIndexView: "Indicator View",
    appIntfEdit: "Indicator Interface Edit",
    appIntfView: "Indicator Interface View",
    indexView: "Indicator View",
    indexThemeAddOrEditProgressBar: "multidimensional modeling Add",
  },
  login: {
    title: 'Login ',
    info: 'Sinoma Development Platform',
    tenantId: 'Please input tenantId',
    username: 'Please input username',
    password: 'Please input a password',
    wechat: 'Wechat',
    qq: 'QQ',
    github: 'github',
    gitee: 'gitee',
    phone: 'Please input a phone',
    code: 'Please input a code',
    submit: 'Login',
    userLogin: 'userLogin',
    phoneLogin: 'phoneLogin',
    thirdLogin: 'thirdLogin',
    msgText: 'send code',
    msgSuccess: 'reissued code',
    titleText: 'Login System'
  },
  navbar: {
    home: 'Home',
    mail: 'mail',
    bugSet: 'error Log',
    info: 'info',
    logOut: 'logout',
    userinfo: 'userinfo',
    switchDept: 'switch dept',
    dashboard: 'dashboard',
    lock: 'lock',
    bug: 'none bug',
    bugs: 'bug',
    language: 'language',
    notice: 'notice',
    theme: 'theme',
    color: 'color',
    feedback: 'feedback'
  },
  tagsView: {
    add: 'Add',
    search: 'Search',
    menu: 'menu',
    clearCache: 'Clear Cache',
    closeOthers: 'Close Others',
    closeAll: 'Close All',
    shortcutMenu: 'shortcut Menu',
    language: "language",
    document: 'help document',
    addShortcutTip: 'Please select a lower menu',
    setup: "setting",
    chat: 'chat',
    appqrcode: "app qr code",
    feedback: "feedback",
    screenfullF: 'exit screenfull',
    screenfull: 'screenfull',
    mobiledownload: "Mobile version download",
    messageSend:"messageSend",
  },

  // clearenergy: {
  //   appropriate: {
  //     payYear: 'appropriate year',
  //     payType: 'payType',
  //     payUnitId: 'payUnitId',
  //     acceptType: 'acceptType',
  //     acceptUnitId: 'acceptUnitId',
  //     payProject: 'payProject',
  //     auditState: 'auditState',
  //     year: 'year',
  //     centerAmount: 'centerAmount（wanYuan）',
  //     localAmount: 'localAmount（wanYuan）',
  //     provinceAmount: 'provinceAmount',
  //     cityAmount: 'cityAmount',
  //     countyAmount: 'countyAmount',
  //     payAllAmount: 'payAllAmount（wanYuan）',
  //     file: 'file',
  //     createPeople: 'createPeople',
  //     createTime: 'createTime',
  //     operation: 'operation',
  //     remark: 'remark',
  //     allAmount: 'allAmount（wanYuan）',
  //     fileName: 'fileName',
  //     fileType: 'fileType'
  //   }
  // },
  gateway: {
    todo: "My to-do list"
  },
  ...datacenter_en,
  // ...formFillingPlan_en,
}
