import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";

//测试连接
export const test = (row) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + "/dblink/test",
    method: "post",
    data: row
  });
};


export const getPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + "/dblink/page",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + "/dblink/detail",
    method: "get",
    params: {
      id,
    },
  });
};

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + "/dblink/remove",
    method: "get",
    params: {
      ids,
    },
  });
};

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/adInfo/submit",
    method: "post",
    data: row,
  });
};

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + "/dblink/saveOrUpdate",
    method: "post",
    data: row,
  });
};
