<template>
  <div class="common_tree_handle common_tree_box">
    <div class="common_tree_handle" v-show="show">
      <el-header
        class="flex-container flex-align-c header"
        height="46px"
        ref="elHeader"
        v-if="titleShow"
      >
        <el-row class="flex-container flex-align-c header-row">
          <el-col span="18" class="flex-container flex-align-c">
            <el-tooltip
              effect="dark"
              :content="treeTitle || this.$t('cip.cmn.title.typeTitle')"
              placement="top-start"
              v-if="showTopTip"
            >
              <label class="typeTitle">{{
                treeTitle || $t("cip.cmn.title.typeTitle")
              }}</label>
            </el-tooltip>
            <label v-else class="typeTitle">{{
              treeTitle || $t("cip.cmn.title.typeTitle")
            }}</label>
          </el-col>
          <el-col span="6" v-if="isShowdig">
            <template>
              <el-popover
                width="40"
                v-model="visible"
                popper-class="treePopperClass"
              >
                <p class="popv_p" @click="add()">
                  {{ $t("cip.cmn.btn.addBtn") }}
                </p>
                <p class="popv_p" @click="edit()">
                  {{ $t("cip.cmn.btn.editBtn") }}
                </p>
                <p class="popv_p" @click="getdelete()">
                  {{ $t("cip.cmn.btn.delBtn") }}
                </p>
                <el-button slot="reference" type="text">
                  <i
                    class="el-icon-setting"
                    style="font-size: 18px; color: black"
                  ></i>
                </el-button>
              </el-popover>
            </template>
          </el-col>
        </el-row>
      </el-header>
      <el-row
        style="padding: 14px 12px; background: #ffffff; position: relative"
        v-if="isShowSearchInput"
      >
        <el-input
          size="small"
          :placeholder="$t('cip.cmn.msg.warning.filterateWarning')"
          v-model="filterText"
          class="searchInput"
          :class="popoverShow ? 'inputWidth' : ''"
        >
        </el-input>
        <el-popover
          placement="right"
          width="80"
          v-if="popoverShow"
          trigger="hover"
        >
          <el-button
            type="text"
            size="mini"
            style="font-size: 14px"
            @click="openAll"
            v-if="treeExpand == false && expandShow"
            >展开全部
          </el-button>
          <el-button
            type="text"
            size="mini"
            style="font-size: 14px"
            @click="openAll"
            v-if="treeExpand == true && expandShow"
            >收缩全部
          </el-button>
          <el-button
            type="text"
            size="mini"
            style="margin: 0; font-size: 14px"
            v-if="isInclude == false"
            @click="includeDown"
            >包含下级
          </el-button>
          <el-button
            type="text"
            size="mini"
            style="margin: 0; font-size: 14px"
            v-if="isInclude == true"
            @click="includeDown"
            >不包含下级
          </el-button>
          <el-button
            slot="reference"
            type="primary"
            icon="el-icon-more"
            class="moreBtn"
          ></el-button>
        </el-popover>
      </el-row>
      <slot name="header"></slot>
      <el-tree
        ref="commonTree"
        v-loading="loading"
        :data="treeChangeData"
        :props="defaultProps"
        :lazy="lazy"
        :load="loadNode"
        :default-expand-all="treeExpand"
        :highlight-current="true"
        :show-checkbox="showCheckbox"
        :check-strictly="checkStrictly"
        :default-expanded-keys="defaultExpandedKeys"
        :expand-on-click-node="false"
        :default-checked-keys="defaultCheckedKeys"
        :current-node-key="currentNodeKey"
        :node-key="nodeKey"
        @node-click="nodeClick"
        @check-change="handleCheckChange"
        :filter-node-method="filterNode"
      >
        <span slot-scope="{ node, data }" class="flex-container flex-align-c">
          <el-image
            v-if="data.deptCategory == 1 || data.deptCategory == 2"
            style="width: 12px; height: 12px"
            :src="require('@/assets/images/organization.svg')"
          ></el-image>
          <i v-else :class="icon(data)"></i>
          <el-tooltip
            :disabled="node.label.length > 16 - node.level ? false : true"
            :content="node.label"
            placement="bottom"
            effect="light"
          >
            <div>
              <span
                v-if="
                  data.isEnable && data.deptCategory && data.deptCategory == 5
                "
                :style="{
                  color: data.isEnable == 1 ? ' #C5211B' : '#67c23a',
                }"
                >{{ data.isEnable == 1 ? "【竣工】" : "【在建】" }}</span
              >
              <span
                :class="issuanceStatus[data.issuanceStatus]"
                class="treeLabel"
                :style="{ width: 200 - (node.level - 1) * 18 + 'px' }"
                >{{ node.label }}</span
              >
            </div>
          </el-tooltip>
          <!--          </el-tooltip>-->
        </span>
      </el-tree>
    </div>
    <el-button
      class="rightBtn"
      :class="!show ? 'rightBtn1' : ''"
      v-if="isZoom"
      circle
      type="primary"
      :icon="show ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"
      @click="click_arrow()"
    ></el-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getCommonUrl } from "@/api/common";
export default {
  data() {
    return {
      issuanceStatus: {
        1: "color-909399",
        2: "color-67C23A",
      },
      visible: false,
      filterText: "",
      isInclude: false,
      defaultExpandAll: false,
      treeChangeData: [],
      nodeData: {},
      treeDatas: [],
      // show: true,
      style_button: {
        float: "right",
        background: "#ffffff",
        position: "float",
        height: "18px",
        width: "18px",
      },
      defaultExpandedKeys: [],
    };
  },

  // treeData            树展示的数据
  // defaultProps        树展示的数据格式
  // searchTitle         过滤的字段名称
  // isShowdig           是否展示搜索旁边的下拉
  // showCheckbox        是否开启多选
  // isShowSearchInput   是否显示搜索框
  // @getTreeAdd         下拉新增方法
  // @getTreeEdit        下拉编辑方法
  // @getTreeDelete      下拉删除方法
  // @getNodeClick       节点点击方法
  // @getHandleCheckChange  多选节点选中状态发生变化时的回调
  computed: {},
  props: {
    treeData: {
      type: Array,
    },
    loadNode: Function,
    nodeKey: {
      type: String,
      default: () => "id",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    treeTitle: {
      type: String,
      default: "",
    },
    treeForm: {
      type: Object,
      default: () => ({}),
    },
    treeFormOption: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      default: true,
    },
    showTopTip: {
      type: Boolean,
      default: false,
    },
    isZoom: {
      type: Boolean,
      default: false,
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: "children",
          label: "label",
        };
      },
    },
    treeExpand: {
      type: Boolean,
      default: false,
    },
    expandShow: {
      type: Boolean,
      default: false,
    },
    popoverShow: {
      type: Boolean,
      default: false,
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
    defaultCheckedKeys: {
      type: Array,
    },
    // defaultExpandedKeys: {
    //   type: Array,
    // },
    defaultExpandedLevel: {
      type: Number,
      default: 1,
    },
    currentNodeKey: {
      type: String,
    },
    searchTitle: {
      type: String,
      default: "",
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    isShowdig: {
      type: Boolean,
      default: false,
    },
    isShowSearchInput: {
      type: Boolean,
      default: true,
    },
    titleShow: {
      type: Boolean,
      default: true,
    },
    risk: {
      type: Boolean, // 区分子节点和父节点不同类型的图标（风险管理）
      default: false,
    },
    isLeafIcons: {
      type: Boolean, // 区分子节点和父节点不同类型的图标（通用版本）
      default: false,
    },
    iconsFlied: {
      type: String, // 区分子节点和父节点不同类型的图标（通用版本）
      default: "",
    },
    url: {
      type: String,
      default: "/api/sinoma-system/dept/tree",
    },
    urlParmas: {
      type: Object,
      default: {
        tenantId: "",
        deptCategory: "",
        parentId: mapGetters(["userInfo"]).userInfo.deptId,
      },
    },
    iconsMap: {
      type: Array, // icon映射 key Value 区分图标
      default: {},
    },
    handleData: {
      type: Function,
    },
    isRequst:{
      type:Boolean,
      default:false
    }
  },
  created() {
    this.getTreeData();
  },
  methods: {
    // 获取treeData
    async getTreeData() {
      if (this.treeData || this.isRequst) {
        console.log('组件',this.treeData)
        this.treeChangeData = this.treeData;
        this.defaultExpandedKeys = this.treeData.map((item) => {
          return item.id;
        });
        this.$forceUpdate()
      } else {
        await getCommonUrl(this.url, this.urlParmas).then((res) => {
          // treeData
          // console.log(res, " 获取treeData");
          this.treeChangeData = this.handleData
            ? this.handleData(res.data.data)
            : res.data.data || [];
          this.$emit("getTreeData", this.treeChangeData);
          this.defaultExpandedKeys = this.treeChangeData.map((item) => {
            return item.id;
          });
          this.$nextTick(() => {
            if (this.treeChangeData.length) {
              this.$refs.commonTree.setCurrentKey(
                this.treeChangeData[0].id ||
                  this.treeChangeData[0][this.nodeKey]
              );
              this.nodeClick(this.treeChangeData[0], {});
            }
          });
        });
      }
    },
    icon(data) {
      if (this.risk)
        return data.isSource ? "el-icon-message-solid" : "el-icon-folder";
      else if (this.isLeafIcons)
        return data[this.iconsFlied]
          ? "el-icon-message-solid"
          : "el-icon-folder";
      else if (data.thirdFlg == 1) return "el-icon-star-off";
      else if (data.deptCategory == 3) return "el-icon-folder";
      else if (data.deptCategory == 5) return "el-icon-office-building";
      else if (data.children) return "el-icon-folder";
      else if (data.deptCategory == "1") return "el-icon-folder";
      else if (data.iconsFlied) {
        return this.iconsMap[data.iconsFlied];
      } else {
        return "el-icon-document";
      }
    },
    setCurrentKey(id) {
      this.$nextTick(() => {
        this.$refs.commonTree.setCurrentKey(id);
      });
    },
    click_arrow(a) {
      this.show = !this.show;
      this.$emit("showChange", this.show);
    },
    openAll() {
      this.treeExpand = !this.treeExpand;
    },
    includeDown() {
      this.isInclude = !this.isInclude;
      this.$emit("include-down", this.isInclude);
    },
    // 新增
    add() {
      this.$emit("getTreeAdd", this.nodeData);
      this.visible = false;
    },
    // 编辑
    edit() {
      if (this.nodeData.id) {
        this.$emit("getTreeEdit", this.nodeData);
      } else {
        this.$message.warning(this.$t("cip.cmn.msg.warning.editWarning"));
      }

      this.visible = false;
    },
    // 删除
    getdelete() {
      if (this.nodeData.id) {
        this.$emit("getTreeDelete", this.nodeData);
      } else {
        this.$message.warning(this.$t("cip.cmn.msg.warning.deleteWarning"));
      }
      this.visible = false;
    },
    nodeClick(data, node) {
      // this.isInclude = false;
      if (data.issuanceStatus != "0") {
        this.nodeData = data;
        this.$emit("getNodeClick", this.nodeData, node);
      } else {
        this.$refs.commonTree.setCurrentKey(null);
        this.$message.warning("未下发任务，不可点击");
        return;
      }
    },
    // 多选
    handleCheckChange(data, choosed) {
      this.$emit("getHandleCheckChange", data, choosed);
    },

    filterNode(value, data) {
      if (!value) return true;

      return data[this.defaultProps.label].indexOf(value) !== -1;
    },
    /**
     * 递归过滤嵌套数组（树形结构）
     * @param arr {Array}要过滤的原始数据
     * @param teamName {Boolean} 你要过滤的关键词
     *
     */
    recursiveFilter(arr, teamName) {
      let result = [];
      arr.forEach((item1) => {
        if (item1[this.defaultProps.label].indexOf(teamName) != -1) {
          result.push(item1);
          if (item1.children) {
            //递归循环
            item1.children = this.recursiveFilter(item1.children, teamName);
          }
        } else {
          if (item1.children) {
            //递归循环
            item1.children = this.recursiveFilter(item1.children, teamName);
          }
        }
      });
      this.treeChangeData = result;
      return result;
    },
    checkNode(val) {
      this.$nextTick(() => {
        this.$refs.commonTree.setCurrentNode(this.nodeData);
      });
    },
  },
  watch: {
    filterText(val) {
      this.$refs.commonTree.filter(val);
    },
    treeData: {
      handler(val){
        this.treeChangeData = val;
        this.defaultExpandedKeys = val.map((item) => {
          return item.id;
        });
        this.$forceUpdate()
      }
    },
    // treeData(n) {
    //   if (n) {
    //     this.treeChangeData = n;
    //     this.$nextTick(() => {
    //       if (this.treeChangeData.length) {
    //         this.$refs.commonTree.setCurrentKey(
    //           this.treeChangeData[0].id || this.treeChangeData[0][this.nodeKey]
    //         );
    //         this.nodeClick(this.treeChangeData[0], {});
    //       }
    //     });
    //   }
    // },
    currentNodeKey(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.commonTree.setCurrentKey(val);
        });
      }
    },
  },
  mounted() {
    // this.treeChangeData = this.treeData;
  },
};
</script>
<style lang='scss' scoped>
.common_tree_box {
  .common_tree_handle {
    width: 280px;
    // padding-right: 12px;
    box-sizing: border-box;
    border-right: 1px solid #cccccc;
    background: #ffffff;
    flex-shrink: 0;

    .el-tree {
      font-size: 12px;
      border-radius: 4px;
      padding: 0 5px 0 12px;
      overflow-y: scroll;
    }
    .color-909399 {
      color: #909399;
    }
    .color-67C23A {
      color: #67c23a;
    }
    .inputWidth {
      width: calc(100% - 34px);
    }

    .treeLabel {
      margin-left: 5px;
    }
    .searchInput {
      font-size: 12px;

      ::v-deep input {
        height: 28px;
      }
    }

    .moreBtn {
      position: absolute;
      top: 14px;
      right: 9px;
      transform: rotate(90deg);
      padding: 7px;
    }
  }

  .header {
    text-align: right;
    font-size: 12px;
    height: 46px;
    background-color: #ffffff;
    padding: 0 12px;
  }

  .typeTitle {
    font-size: 14px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .header-row {
    width: 100%;
    line-height: 45px;
    border-bottom: 1px solid #cccccc;
  }

  ::v-deep .el-tree-node__content {
    line-height: 26px;
  }

  .el-popover {
    min-width: 80px !important;
  }

  .rightBtn {
    width: 48px;
    height: 96px;
    transform: rotate(180deg);
    position: absolute;
    left: 264px;
    top: calc(50vh - 148px);
    z-index: 99;
    margin-right: 5px;
    clip-path: polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%);
    background: #d2d3d6;
  }

  .rightBtn1 {
    margin-right: 5px;
    clip-path: polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%);
    background: #d2d3d6;
    left: -30px;
  }
}

.treePopperClass {
  .popv_p {
    line-height: 32px;
    text-align: center;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
}
</style>
