import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

/**
 * 数据主题列表
 * @param params
 * @returns {*}
 */
export const getaDtaDirectoryAllByPid = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/data-directory/get-data-directory-all-by-pid',
    method: 'post',
    data:params
  })
}
/**
 * 数据主题新增
 * @param {id:'',pid:'',name:'',ename:'',url}
 * @returns {*}
 */
export const updateDataDirectory = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/data-directory/create-update-data-directory',
    method: 'post',
    data:params
  })
}
/**
 * 数据主题详情
 * @param {id:''}
 */
export const getDataDirectoryById = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/data-directory/get-data-directory-by-id',
    method: 'post',
    data:params
  })
}
/**
 *
 * @param {id:''}
 * @returns {*}
 */
// export const deleteDataDirectoryById = (params) => {
//   return request({
//     url: ServerNameEnum.SERVER_ZT_+'/storage/data-directory/delete-data-directory-by-id',
//     method: 'post',
//     data:params
//   })
// }

export const authListAll = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/db-manager/auth-list-all',
    method: 'post',
    data:params
  })
}
//todo 数据字典
export const getDictList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/mdm-dict/get-all-page',
    method: 'post',
    data:params
  })
}
export const delDict = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/mdm-dict/delete-dict-by-id',
    method: 'post',
    data:params
  })
}
export const saveDict = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/mdm-dict/save-dict',
    method: 'post',
    data:params
  })
}
export const getDiceDetaill = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/mdm-dict/get-dict-by-id',
    method: 'post',
    data:params
  })
}

export const getBloodTree=()=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/datablood/data-blood-type/tree',
    method:'Get'
  })
}

// export const databloodDetail=()=>{
//   return request({
//     url:ServerNameEnum.SERVER_ZT_+'/datablood/data-blood/detail/1621741660526157826',
//     method:'Get'
//   })
// }

// 血缘 详情
export const databloodDetail=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+`/datablood/data-blood/detail/${params}` ,
    method:'get',
  })
}



//新增血缘类型
export const SaveDataBloodType=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/datablood/data-blood-type/save',
    method:'post',
    data:params
  })
}


//删除血缘类型
export const DelBloodType=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/datablood/data-blood-type/delete',
    method:'post',
    data:params
  })
}

// 

//新增血缘
export const AddDataBlood=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/datablood/data-blood/save',
    method:'post',
    data:params
  })
}

//删除血缘
export const DelDataBlood=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/datablood/data-blood/delete',
    method:'post',
    data:params
  })
}

//todo 新主题
//todo 新增
export const createUdateDataDirectory=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/storage/data-directory/create-update-data-directory',
    method:'post',
    data:params
  })
}
//todo 查询
export const getDataDirectoryAllByPid=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/storage/data-directory/get-data-directory-all-by-pid',
    method:'post',
    data:params
  })
}
//todo 删除
export const deleteDataDirectoryById=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/storage/data-directory/delete-data-directory-by-id',
    method:'post',
    data:params
  })
}


