import httpService from "@/router/axios";

// 安全工作-事故管理接口，分页
export const $_getPageList = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/workaccident/list",
    method: "get",
    params,
  });
};

// 安全工作-事故管理接口，修改
export const $_removeData = (row) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/workaccident/update",
    method: "post",
    data: row,
  });
};

// 安全工作-事故管理接口，详情
export const $_getDetail = (id) => {
  return httpService({
    url: `/api/ivystar-wisdom-security/workaccident/detail/${id}`,
    method: "get",
  });
};
