import Layout from '@/page/index'

export default [
    // 职业健康档案
    {
        path: '/healthManage',
        component: Layout,
        name: '职业健康管理',
        children: [
            {
                path: '/business/healthManage/appliance/form',
                name: '领用记录信息',
                component: () => import('@/views/business/healthManage/appliance/form.vue'),
                meta: {
                    i18n: '领用记录信息'
                }
            },
            {
                path: '/business/healthManage/testRecords/form',
                name: '体检记录信息',
                component: () => import('@/views/business/healthManage/testRecords/form.vue'),
                meta: {
                    i18n: '体检记录信息'
                }
            },
        ]
    }
]