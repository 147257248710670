/*
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-08-29 15:30:02
 */
import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjeqvisitappointment/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/sinoma-hse-prj/prjeqvisitappointment/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/sinoma-hse-prj/prjeqvisitappointment/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/sinoma-hse-prj/prjeqvisitappointment/submit',
    method: 'post',
    data: row
  })
}

//保存访客闸机预约
export const saveSelect = (row) => {
  return request({
    url: '/api/sinoma-hse-prj/prjeqvisitappointment/saveSelect',
    method: 'post',
    data: row
  })
}

export const visitList = (current, size, params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjeqthroughrecord/visit',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
