import httpService from "@/router/axios";

// 获取证书管理类型表格数据
export const $_getList = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/certificate/list",
    method: "get",
    params,
  });
};

// 证书管理类型新增表格
export const $_cersave = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/certificate/save",
    method: "post",
    data: params,
  });
};
//证书管理类型修改
export const $_submit = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/certificate/update",
    method: "post",
    data: params,
  });
};

// 获取  职业工种管理
export const $_workList = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/professionalwork/list",
    method: "get",
    params,
  });
};

// 新增  职业工种管理
export const $_workadd = (row) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/professionalwork/save",
    method: "post",
    data: row,
  });
};

// 修改  职业工种管理
export const $_workupdate = (row) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/professionalwork/update",
    method: "post",
    data: row,
  });
};

// 获取  黑名单行为清单
export const $_getBlacklistList = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/blacklistbehavior/list",
    method: "get",
    params,
  });
};

// 新增  黑名单行为清单
export const $_addBlacklistList = (row) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/blacklistbehavior/save",
    method: "post",
    data: row,
  });
};

// 修改  黑名单行为清单
export const $_updateBlacklistList = (row) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/blacklistbehavior/update",
    method: "post",
    data: row,
  });
};

// 获取  公司管理list不分页
export const $_getCompanyList = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/company/list",
    method: "get",
    params,
  });
};

// 获取  公司管理page
export const $_getCompanyPage = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/company/page",
    method: "get",
    params,
  });
};

// 获取  公司管理 树
export const $_getCompanyTree = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/company/tree",
    method: "get",
    params,
  });
};

// 新增  公司管理
export const $_addcompany = (row) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/company/save",
    method: "post",
    data: row,
  });
};

// 修改  公司管理
export const $_updatecompany = (row) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/company/update",
    method: "post",
    data: row,
  });
};

// 删除  公司管理
export const $_removecompany = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/company/remove",
    method: "post",
    params,
  });
};

// 详情 公司管理
export const $_getDetail = (id) => {
  return httpService({
    url: `/api/ivystar-wisdom-security/company/detail/${id}`,
    method: "get",
  });
};

// 删除  公司管理
export const $_remove = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/company/remove",
    method: "post",
    params,
  });
};

//  公司管理  树
export const $_companyUserTreeAll = (params) => {
  return httpService({
    url: `/api/ivystar-wisdom-security/wisdomsecurity/companyUserTreeAll`,
    method: "get",
    params
  });
};

// 下载 项目清单 导入模板
export const companyExportTemplate = () => {
  return "/api/ivystar-wisdom-security/company/exportTemplate";
};

// 导入 项目清单 模板
export const importCompany = () => {
  return "/api/ivystar-wisdom-security/company/import";
};

export const getTopCompany = (params) => {
  return httpService({
    url: '/api/ivystar-wisdom-security/company/topCompany',
    method: 'post',
    data: params,
  })
}
