var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-card" }, [
    _c("div", { staticClass: "card-header clearfix" }, [
      _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm.showMore
        ? _c(
            "span",
            { staticClass: "more-btn", on: { click: _vm.clickMore } },
            [
              _vm._v("\n      " + _vm._s(this.$t("cip.cmn.title.more"))),
              _c("i", { staticClass: "el-icon-arrow-right" }),
            ]
          )
        : _vm._e(),
      _vm.showSet
        ? _c("span", { staticClass: "set-btn", on: { click: _vm.clickSet } }, [
            _c("i", { staticClass: "el-icon-plus" }),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "card-container" }, [_vm._t("content")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }