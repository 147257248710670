var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      key: _vm.key,
      ref: "elSelect",
      staticClass: "SNOWS-selectTree",
      attrs: {
        clearable: _vm.clearable,
        disabled: _vm.disabled,
        placeholder: _vm.placeholder,
        "popper-class": `SNOWS-select-tree`,
        filterable: _vm.filterable,
        "filter-method": _vm.selectFilter,
        multiple: _vm.multiple,
        "collapse-tags": _vm.collapseTags,
      },
      on: {
        clear: _vm.clearHandle,
        focus: _vm.selectFocus,
        "visible-change": _vm.visibleChange,
        "remove-tag": _vm.removeTag,
      },
      model: {
        value: _vm.valueTitle,
        callback: function ($$v) {
          _vm.valueTitle = $$v
        },
        expression: "valueTitle",
      },
    },
    [
      _vm._l(_vm.selectOptions, function (item) {
        return _c("el-option", {
          key: item.id,
          staticStyle: { display: "none" },
          attrs: { label: item[_vm.props.label], value: item[_vm.props.value] },
        })
      }),
      _c(
        "el-option",
        {
          staticClass: "options",
          attrs: { value: _vm.optionTitle, label: _vm.optionTitle },
        },
        [
          _c("el-tree", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.options.length,
                expression: "options.length",
              },
            ],
            ref: "selectTree",
            class: { single: !_vm.multiple },
            attrs: {
              id: "tree-option",
              accordion: _vm.accordion,
              data: _vm.options,
              "default-expand-all": _vm.defaultExpandAll,
              props: _vm.props,
              "node-key": _vm.props.value,
              "default-expanded-keys": _vm.defaultExpandedKey,
              "filter-node-method": _vm.filterNode,
              "show-checkbox": _vm.multiple,
              "check-on-click-node": _vm.multiple,
              "expand-on-click-node": !_vm.multiple,
            },
            on: { "node-click": _vm.handleNodeClick, check: _vm.check },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ node, data }) {
                    return _c(
                      "span",
                      {
                        staticClass: "custom-tree-node",
                        class: { "is-disabled": data.disabled },
                      },
                      [
                        _vm._t(
                          "default",
                          function () {
                            return [
                              data.icon
                                ? _c("i", { class: data.icon })
                                : _vm._e(),
                              _c("span", { staticClass: "text" }, [
                                _vm._v(_vm._s(node.label)),
                              ]),
                            ]
                          },
                          { data: { node, data } }
                        ),
                      ],
                      2
                    )
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.options.length,
                  expression: "!options.length",
                },
              ],
              staticClass: "empty-text",
            },
            [_vm._v("无数据")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }