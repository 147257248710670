import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_PROTOCOL = "/monitoring";
const STATION = "/station";
const clients = "/client"
export const devicetime = (params) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+clients+'/devicetime/deviceCalcTime',
    method: 'get',
    params: {
      ...params,
    }
  })
}
export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const dialogdevice = (current, size, params,locationCode, netCode) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/dialogdevice',
    method: 'get',
    params: {
      ...params,
      current,
      size,
      locationCode,
      netCode
    }
  })
}

export const deviceDialogPage = (current, size, params,locationCode, netCode) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/deviceDialogPage',
    method: 'get',
    params: {
      ...params,
      current,
      size,
      locationCode,
      netCode
    }
  })
}
export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
export const removeDevice = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/removeDevice?ids=' + ids,
    method: 'post',
    // params: {
    //   ids,
    // }
  })
}
export const removeDevicehome = (ids,identification) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/removeDevicehome?ids=' + ids+'&identification='+identification,
    method: 'post',
    // params: {
    //   ids,
    // }
  })
}
export const saveDevice = (terminalCoding,ids) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/saveDevice?terminalCoding=' + terminalCoding+'&ids='+ids,
    method: 'post',
    // params: {
    //   ids,
    // }
  })
}
export const submitStation = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+STATION+'/submit?ids='+ids,
    method: 'post',
    // params: {
    //   ids,
    // }
  })
}
export const removeStation = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+STATION+'/remove?ids='+ids,
    method: 'post',
    // params: {
    //   ids,
    // }
  })
}
export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/update',
    method: 'post',
    data: row
  })
}

export const submit = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/submit',
    method: 'post',
    data: row
  })
}
export const updateMonitoringStatus = (id, isEnable) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/updateMonitoringStatus',
    method: 'get',
    params: {
      id,
      isEnable
    }
  })
}
export const updateEmqxStatus = (iplocation, endTime, isEnable) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/updateEmqxStatus',
    method: 'get',
    params: {
      iplocation,
      endTime,
      isEnable
    }
  })
}

export const getmonitoringCardList = ( current, size,row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/monitoringCard',
    method: 'get',
    params: {
      ...row,
      current,
      size,
    }
  })
}
export const monitoringDialog = ( current, size,row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/monitoringDialog',
    method: 'get',
    params: {
      ...row,
      current,
      size,
    }
  })
}
export const cardStationDialog = ( current, size,row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/cardStationDialog',
    method: 'get',
    params: {
      ...row,
      current,
      size,
    }
  })
}

export const configuration = (terminalCoding) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/configuration?terminalCoding='+terminalCoding,
    method: 'get',
    // params: {
    //   terminalCoding,
    // }
  })
}
export const selectData = (row,distinction) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/selectData',
    method: 'get',
    params: {
      ...row,
      distinction,
    }
  })
}
export const calcHistoryData = (row,distinction) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/calcHistoryData',
    method: 'get',
    params: {
      ...row,
      distinction,
    }
  })
}

export const ipUtil = () => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_PROTOCOL+'/ipUtil',
    method: 'post',
  })
}
