// 指标管理
export default {
  index: {
    appIndex: {
      theme: {
        treeTitle: "title",
        listTitle: "indicators",
      }
    },
    // 应用
    app: {
      // 体系分类
      category: {
        treeHeader: "System Classification",
        listHeader: "Index List",
        indexCode: "Index Code",
        indexName: "Index Name",
        alias: "Alias",
        unitCode: "Index Unit",
        timeDimension: "Time Dimension",
        indexLevel: "Index Level",
        parentCode: "Parent Classification",
        categoryCode: "Classification Code",
        categoryName: "Classification Name",
        sort: 'Sort',
        errorMsg1: "Classification code cannot be duplicate, please input again!",
        indexView: "indicator view",
        time: "time",
      }
    },
    theme: {
      title: "Multidimensional Model",
      headTitle: "Multidimensional Model Management",
      headCatTitle: "Multidimensional Model Classification",
      pleaseInput: "Please Input",
      parentCode: "Parent Category",
      parentName: "Parent Category Name",
      cateGoryCode: "Classification Code",
      cateGoryName: "Classification Name",
      isEnable: "Is Enable",
      remark: "Remark",
      hasChildren: "Has Children and Cannot Be Deleted!",
      themeName: "Multidimensional Model Name",
      themeCode: "Multidimensional Model Code",
      category: "Category",
      factName: "Fact Table",
      themeDimensionTable: "Dimension Table",
      themeDimension: "Dimension",
      themeCuboid: "Cuboid",
      themeTime: "Time Dimension",
      tableName: "Table Name",
      tableRemark: "Table Remark",
      modeOfConn: "Connection Mode",
      conditionOfConn: "Connection Condition",
      modelName: "Model Name",
      subModelName: "Sub-model Name",
      fieldName: "Field Name",
      fieldType: "Field Type",
      fieldRemark: "Field Remark",
      expression: "Expression",
      alias: "Alias",
      description: "Description",
      dataType: "Data Type",
      overview: "Overview",
      wideTableSql: "Wide Table SQL",
      cuboid: "Cuboid",
      dimenCount: "Dimension Count",
      cuboidCount: "Cuboid Count",
      physicalTableConstruction: "Physical Table Construction",
      anotherName: "Another Name",
      basicInfo: "Basic Information",
      modelConfig: "Model Configuration",
      CuboidConfig: "Cuboid Configuration",
      done: "Done",
      nextStep: "Next Step",
      previousStep: "Previous Step",
      addThem: "+ Add Theme",
      indexCount: "Index Count",
      edit: "Edit",
      remove: "Remove",
      repeatData: "There are duplicate data, please select again!",
      list: "List",
      dynQuery: "Custom Query",
      error1: "The multidimensional model is being used by an index and cannot be edited!",
      select: "Select"
    },
    atomIndex: {
      title: "Index",
      headTitle: "Index Configuration",
      indexCode: "Index Code",
      indexName: "Index Name",
      theme: "Theme",
      confType: "conf type",
      unitCode: "unit",
      indexType: "Index Type",
      dimensionNum: "Dimension Count",
      dimension: "Dimension",
      api: "API",
      webSocket: "WebSocket",
      cuboidId: "Cuboid ID",
      isEffective: "Is Effective",
      apiMsg: "Save to generate automatically",
      checkIndex: "Indices with different dimensions cannot be added",
      queryData: "View Data",
      dynSqlBtn: "Custom Index",
      dynSql: "Custom SQL",
      dataSource: "Data Source",
      hour: "hour",
      day: "day",
      month: "month",
      year: "year",
      accuracy: "accuracy",
      alias: "alias",
      jobCron: "period settings",
      indexCaliber: "business caliber",
      simulationType: "simulation type",
      simulationValue: "simulation value",
      compositeFormula: "composite formula",
      composite: "composite",
      drillDown: "drill down",
      influxdbPoint: "measuring point",
      functionType: "function type",
      fullDimensionalData: "full dimensional data",

    },
    dimension: {
      headTitle: "Dimension",
      fieldName: "Dimension Code",
      fieldRemark: "Dimension Name",
      fieldType: "Type"
    },
    measure: {
      headTitle: "Measure",
      expression: "Expression",
      alias: "Alias",
      description: "Description",
      dataType: "Data Type",
      auxiliaryCalc: "Auxiliary Calculation"
    },
    combinatorialMeasure: {
      headTitle: "Combinatorial Measure",
      scalarCode: "Scalar Code",
      scalarName: "Scalar Name",
      type: "Type",
      calculatingFunction: "Calculation Function"
    },
    condition: {
      headTitle: "Condition",
      Dimension: "Dimension",
      isRequired: "Required"
    },
    scope: {
      headTitle: "Scope",
      connectedRelation: "Connected Relation",
      Dimension: "Dimension",
      computeSign: "Compute Sign",
      value: "Value",
      message: "Please save the header information first",
      testConnect: "Test Script"
    },
    intf: {
      requestBtn: "Request",
      title: "Metric Interface",
      intfUrl: "Interface URL",
      timeDimension: "Time Dimension",
      timeSelect: "Time Selection",
      timeSelectStart: "Please select a start time",
      timeSelectEnd: "Please select an end time",
      currentPage: "Current Page",
      pageSize: "Page Size",
      intfParams: "Request Parameters",
      intfResultRows: "Returned Rows",
      indexClass: "Metric Index",
      intfCode: "Interface Code",
      intfName: "Interface Name",
      simulateCall: "Simulate Call",
      message1: "Metric code is empty, please select a metric code before simulating the call!",
      message2: "Interface code already exists, please modify!"
    },
    //数据模型
    datamodel: {
      title: "datamodel",
      field: {
        ChineseNameOfData: "ChineseNameOfData",
        Dataname: "Dataname",
        datatype: "datatype",
        Creationtime: "Creationtime",
        form: 'form',
        relationship: 'relationship',
        Fieldname: 'Fieldname',
        FieldName: "FieldName",
        Chinesename: 'Chinesename',
        type: 'type',
        length: 'length',
        accuracy: 'accuracy',
        Primarykeyornot: 'Primarykeyornot',
        Isitempty: 'Isitempty',
        Currenttablename: "Currenttablename",
        Associatedtable: "Associatedtable",
        Associatedfield: "Associatedfield",
        is: "is",
        no: "no",
        newtable: "newtable",
        Modelname: "Modelname",
        datasource: "datasource",
        Pleaseselect: "Pleaseselect",
        Tablename: "Tablename",
        notes: "notes",
      },
      rules: {
        rules1: "Please enter a table name",
        rules2: "Please select a data source",
        rules3: "Please enter comments",
      },
      msg: {
        msg1: "Edit succeeded",
        msg2: "Save succeeded",

      }
    },

    omission: {
      headTitle1: "Index Data Query",
      headTitle2: "Drill-Down Index Query",
      convert: "Conversion",
      seedata: "View Drill-Down Data",
      indexLabel: "Index",
      message1: "This index has no drill-down indices",
      original: "Original Value",
      analog: "Analog Value",
      isCache: "Whether to cache",
      indexLevel: "Index level",
      timeDimension: "Time dimension",
      appIndexScopes: "Dimension mapping",
      appIndexMeasures: "Metrics",
      simulatedData: "Simulated data" ,
      originalData: "Original data" ,
      lineChart: "Line chart" ,
      viewResult: "view result" ,
    },
  }
}
