/*
 * @Author: absorbedyy@163.com
 * @Date: 2024-05-09 09:23:15
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-05-16 11:16:52
 * @Description: 
 */
import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

/** 排放记录-分页 */
export const pollutionRecordPage = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epemission/page',
    method: 'get',
    params
  })
}
/** 环保舆情-详情 */
export const pollutionRecordDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epemission/detail',
    method: 'get',
    params
  })
}
export const pollutionRecordSubmit= (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epemission/submit',
    method: 'post',
    data
  })
}
export const pollutionRecordRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epemission/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

/** 排放记录记录- end */


/** 排污许可证记录- start */
/** 排污许可证-分页 */
export const pollutionPermitPage = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epemissionlicense/page',
    method: 'get',
    params
  })
}
export const pollutionPermitList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epemissionlicense/list',
    method: 'get',
    params
  })
}
export const pollutionPermitDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epemissionlicense/detail',
    method: 'get',
    params
  })
}
export const pollutionPermitSubmit= (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epemissionlicense/submit',
    method: 'post',
    data
  })
}

export const pollutionPermitRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epemissionlicense/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

/** 排污许可证- end */
