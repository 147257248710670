var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.refet,
        title: this.$t(
          "cip.cmn.components.chooseLangue.title.chooseLangueTitle"
        ),
        visible: _vm.dialogVisible,
        center: "",
        width: "30%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-radio-group",
        {
          staticStyle: { width: "100%", "text-aline": "center" },
          model: {
            value: _vm.radio,
            callback: function ($$v) {
              _vm.radio = $$v
            },
            expression: "radio",
          },
        },
        [
          _c(
            "el-radio",
            { attrs: { disabled: _vm.language === "zh", label: "zh" } },
            [
              _vm._v(
                _vm._s(this.$t("cip.cmn.components.chooseLangue.field.zh"))
              ),
            ]
          ),
          _c(
            "el-radio",
            { attrs: { disabled: _vm.language === "en", label: "en" } },
            [
              _vm._v(
                _vm._s(this.$t("cip.cmn.components.chooseLangue.field.en"))
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.refet } }, [
            _vm._v(_vm._s(this.$t("cip.cmn.btn.celBtn"))),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.handleSetLanguage },
            },
            [_vm._v(_vm._s(this.$t("cip.cmn.btn.defBtn")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }