import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getTree = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/sthdtype/tree',
    method: 'get',
    params
  })
}
export const getList = (current,size,params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/sthdlist/page',
    method: 'get',
    params:{
      current,
      size,
      ...params
    }
  })
}
export const submit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/sthdtype/submit',
    method: 'POST',
    data
  })
}
export const remove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/sthdtype/remove',
    method: 'post',
    data
  })
}
export const sthdlistRemove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/sthdlist/remove',
    method: 'post',
    data
  })
}
export const sthdlistSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/sthdlist/submit',
    method: 'post',
    data
  })
}
