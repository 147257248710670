var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "avue-logo" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.keyCollapse
          ? _c("div", { key: "0" }, [
              _c("img", {
                staticClass: "avue-logo_subtitle",
                staticStyle: { height: "auto", width: "50px" },
                attrs: { src: "/img/logo-1.png" },
              }),
            ])
          : _vm._e(),
      ]),
      _c(
        "transition-group",
        { attrs: { name: "fade" } },
        [
          !_vm.keyCollapse
            ? [
                _c("span", { key: "1", staticClass: "avue-logo_title" }, [
                  _vm._v(_vm._s(_vm.website.indexTitle) + " "),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }