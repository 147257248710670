import httpService from "@/router/axios";

export const getRiskConfigList = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/riskConfig/list",
    method: "get",
    params,
  });
};

export const removeRiskConfig = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/riskConfig/remove",
    method: "post",
    params,
  });
};

export const saveRiskConfig = (row) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/riskConfig/save",
    method: "post",
    data: row,
  });
};

// 下载导入模板
export const exportTemplateRiskConfig = () => {
  return "/api/ivystar-wisdom-security/riskConfig/exportTemplate";
};

export const importRiskConfig = () => {
  return "/api/ivystar-wisdom-security/riskConfig/import";
};
