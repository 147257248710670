export const options = (safe)=> {
  return {
    index: true,
    column: [
      {
        label: safe.$t("cip.plat.sys.param.field.paramName"),
        prop: "paramName",
        search: true,
        span: 24,
        rules: [{
          required: true,
          message:  safe.$t("cip.cmn.rule.inputWarning")+safe.$t("cip.plat.sys.param.field.paramName"),
          trigger: "blur"
        }]
      },
      {
        label: safe.$t("cip.plat.sys.param.field.paramKey"),
        prop: "paramKey",
        search: true,
        span: 24,
        rules: [{
          required: true,
          message: safe.$t("cip.cmn.rule.inputWarning")+safe.$t("cip.plat.sys.param.field.paramKey"),
          trigger: "blur"
        }]
      },
      {
        label:  safe.$t("cip.plat.sys.param.field.paramValue"),
        prop: "paramValue",
        type: "textarea",
        span: 24,
        minRows: 6,
        rules: [{
          required: true,
          message: safe.$t("cip.cmn.rule.inputWarning")+safe.$t("cip.plat.sys.param.field.paramValue"),
          trigger: "blur"
        }]
      }
    ]
  }
};
export const searchColumns = (safe)=> {
  return [
    {
      prop: "paramName",
      search: true,
      placeholder: safe.$t("cip.cmn.rule.inputWarning")+safe.$t("cip.plat.sys.param.field.paramName"),
    },
    {
      placeholder: safe.$t("cip.cmn.rule.inputWarning")+safe.$t("cip.plat.sys.param.field.paramKey"),
      prop: "paramKey",
      search: true,
    }
  ]
};
