import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getList = (current,size,params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjentryexit/page',
    method: 'get',
    params:{
      current,
      size,
      ...params
    }
  })
}
export const detail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjentryexit/detail',
    method: 'get',
    params
  })
}
export const update = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjentryexit/actFinish',
    method: 'post',
    data
  })
}
export const submit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjentryexit/submit',
    method: 'post',
    data
  })
}
export const hdcheckindextype = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckindextype/submit',
    method: 'post',
    data
  })
}
export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjentryexit/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
export const getCode = () => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjentryexit/getCode',
    method: 'get',
  })
}
export const getTypeTree = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckindextype/typeTree',
    method: 'get',params
  })
}
export const TypeTreeRemove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckindextype/remove',
    method: 'post',
    data
  })
}
