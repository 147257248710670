<template>
  <div>
    <el-input :value="value||innerValue" :placeholder="placeholder" readonly v-if="!detailed" />
    <p v-else>{{value||innerValue}}</p>
  </div>
</template>

<script>
import {getUserInfo} from '@/api/system/user.js'
import { mapGetters } from "vuex";
export default {
  name: 'snows-open-data',
  props: {
    value: {
      type: String,
      default: ''
    },
    /**
     * currUser - 当前用户
     * currTime - 当前时间
     * currOrganize - 所属组织
     * currPosition - 所属岗位
     */
    type: {
      type: String,
      default: 'currUser'
    },
    showLevel: {
      type: String,
      default: 'last'
    },
    detailed: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '系统自动生成'
    },
  },
  data() {
    return {
      innerValue: '',
      userInfo:{}
    }
  },
  watch: {
    showLevel() {
      this.setDefault()
    }
  },
  computed:{
    ...mapGetters(["language"])
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      getUserInfo().then((res) => {
        this.userInfo = res.data.data;
        this.setDefault()
      })
    },
    setDefault() {
      if (this.type === 'currUser') {
        if (this.language == 'zh') {
          this.innerValue = JSON.parse(this.userInfo.realName).zh + '/' + this.userInfo.account
        } else {
          this.innerValue = JSON.parse(this.userInfo.realName).en + '/' + this.userInfo.account
        }
      }
      if (this.type === 'currTime') {
        this.innerValue = this.sinoma.toDate(new Date().getTime(), 'yyyy-MM-dd HH:mm:ss')
      }
      if (this.type === 'currOrganize') {
        this.innerValue = this.userInfo.deptName
      }
      if (this.type === 'currPosition') {
        this.innerValue = this.userInfo.postName
      }
    }
  }
}
</script>
