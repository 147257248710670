import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const OPC_SERVER = "/opcServer";

export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_ + OPC_SERVER + '/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getCacheDataList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_ + OPC_SERVER + '/getCacheDataList',
    method: 'post',
    data: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_ + OPC_SERVER + '/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const removeTree = (id) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_ + OPC_SERVER + '/remove',
    method: 'post',
    params: {
      id,
    }
  })
}

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_ + OPC_SERVER + '/update',
    method: 'post',
    data: row
  })
}

export const submit = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_ + OPC_SERVER + '/submit',
    method: 'post',
    data: row
  })
}

export const tree = () => {
  return request({
    url: ServerNameEnum.SERVER_CPS_ + OPC_SERVER + '/tree',
    method: 'get',
  })
}

export const dialogTree = () => {
  return request({
    url: ServerNameEnum.SERVER_CPS_ + OPC_SERVER + '/dialogTree',
    method: 'get',
  })
}

export const submitPoints = (data) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_ + OPC_SERVER + '/submitPoints',
    method: 'post',
    data: data
  })
}

export const removePoints = (temp) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_ + OPC_SERVER + '/removePoints',
    method: 'post',
    params: {
      temp,
    }
  })
}
