import {httpSecurity} from "@/router/axios";

/**
 * @des 安全检查标准
 */

/* 检查项 */
// 不分页
export const inspectitemList = (params) => { return httpSecurity({ url: "/inspectitem/list", method: "get", params, }); };
// 新增
export const inspectitemAdd = (params) => { return httpSecurity({ url: "/inspectitem/save", method: "post", data: params, }); };
// 删除
export const inspectitemDel = (params) => { return httpSecurity({ url: "/inspectitem/remove", method: "post", params, }); };

/* 检查内容 */
// 分页
export const inspectcontentPage = (params) => { return httpSecurity({ url: "/inspectcontent/page", method: "get", params, }); };
// 新增
export const inspectcontentAdd = (params) => { return httpSecurity({ url: "/inspectcontent/save", method: "post", data: params, }); };
// 编辑
export const inspectcontentEdit = (params) => { return httpSecurity({ url: "/inspectcontent/update", method: "post", data: params, }); };
// 详情
export const inspectcontentDetail = (params) => { return httpSecurity({ url: "/inspectcontent/detail", method: "get", params, }); };
// 删除
export const inspectcontentDel = (params) => { return httpSecurity({ url: "/inspectcontent/remove", method: "post", params, }); };
// 其他业务引用--检查项
export const inspectcontent = (params) => { return httpSecurity({ url: "/inspectitem/quote-list", method: "get", params, }); };

// 模板
export const inspectitemTtemplate = () => {return '/api/ivystar-wisdom-security/inspectitem/import-template' }
// 导入
export const inspectitemImport = () => {return '/api/ivystar-wisdom-security/inspectitem/import' }

