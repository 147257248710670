import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_NAME = "/knowledge";


export const page = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/page`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const list = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/list`,
    method: 'post',
    params: {
      data
    }
  })
}

export const saveOrUpdate= (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}${CONTROLLER_NAME}/saveOrUpdate`,
    method: 'post',
    data
  })
}

export const selectById = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/selectById`,
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/remove`,
    method: 'get',
    params: {
      ids
    }
  })
}
