var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "specificMainBox" }, [
    _c(
      "div",
      { staticClass: "specificTItle" },
      [
        _c("el-divider", [
          _vm._v(_vm._s(_vm.$t("cip.plat.sys.top.field.navigationMode"))),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "navigationBigBox" },
      _vm._l(_vm.imgUrl1, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "navigationBox",
            on: {
              click: function ($event) {
                return _vm.checkList(item)
              },
            },
          },
          [
            _c("div", { staticClass: "navigationImgBox" }, [
              _c("img", { attrs: { src: item.imgrurl, alt: "" } }),
              item.layoutType === _vm.layoutType
                ? _c("div", { staticClass: "icon-checked" }, [
                    _c("i", { staticClass: "el-icon-check" }),
                  ])
                : _vm._e(),
            ]),
            _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: item.name,
                  placement: "top-start",
                },
              },
              [_c("span", [_vm._v(_vm._s(item.name))])]
            ),
          ],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "specificTItle" },
      [
        _c("el-divider", [
          _vm._v(_vm._s(_vm.$t("cip.plat.sys.top.field.colorBlock"))),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "navigationBigBox" },
      [
        _vm._l(_vm.imgUrl2, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "navigationBox",
              on: {
                click: function ($event) {
                  return _vm.checkList2(item)
                },
              },
            },
            [
              _c("div", { staticClass: "navigationImgBox" }, [
                _c("img", { attrs: { src: item.imgrurl, alt: "" } }),
                item.className === _vm.slideClass
                  ? _c("div", { staticClass: "icon-checked" }, [
                      _c("i", { staticClass: "el-icon-check" }),
                    ])
                  : _vm._e(),
              ]),
              _c("span", [_vm._v(_vm._s(item.name))]),
            ]
          )
        }),
        _c("div", { staticStyle: { width: "65px" } }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "specificTItle" },
      [
        _c("el-divider", [
          _vm._v(_vm._s(_vm.$t("cip.plat.sys.top.field.themeColors"))),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "colorList" },
      _vm._l(_vm.imgUrl3, function (item, index) {
        return _c(
          "el-tooltip",
          {
            key: index,
            staticClass: "item",
            attrs: { content: item.name, effect: "dark", placement: "top" },
          },
          [
            _c(
              "el-tag",
              {
                attrs: { color: item.color },
                on: {
                  click: function ($event) {
                    return _vm.checkList3(item)
                  },
                },
              },
              [
                item.className === _vm.themeClass
                  ? _c("i", { staticClass: "el-icon-check" })
                  : _vm._e(),
              ]
            ),
          ],
          1
        )
      }),
      1
    ),
    _c(
      "div",
      { staticClass: "specificTItle" },
      [
        _c("el-divider", [
          _vm._v(_vm._s(_vm.$t("cip.plat.sys.top.field.otherSettings"))),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "drawer-item" },
      [
        _c("span", [
          _vm._v(_vm._s(_vm.$t("cip.plat.sys.top.field.multiTabOpeningMode"))),
        ]),
        _c("el-switch", {
          staticClass: "drawer-switch",
          model: {
            value: _vm.tagsView,
            callback: function ($$v) {
              _vm.tagsView = $$v
            },
            expression: "tagsView",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "drawer-item" },
      [
        _c("span", [_vm._v(_vm._s(_vm.$t("cip.plat.sys.top.field.search")))]),
        _c("el-switch", {
          staticClass: "drawer-switch",
          model: {
            value: _vm.showSearch,
            callback: function ($$v) {
              _vm.showSearch = $$v
            },
            expression: "showSearch",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "drawer-item" },
      [
        _c("span", [_vm._v(_vm._s(_vm.$t("cip.plat.sys.top.field.stripe")))]),
        _c("el-switch", {
          staticClass: "drawer-switch",
          model: {
            value: _vm.showStripe,
            callback: function ($$v) {
              _vm.showStripe = $$v
            },
            expression: "showStripe",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "specificTItle" },
      [_c("el-divider", [_vm._v(_vm._s(_vm.$t("cip.desk.portalSwitch")))])],
      1
    ),
    _c(
      "div",
      { staticClass: "drawer-item" },
      [
        _vm.portalData.length > 0
          ? _c(
              "el-select",
              {
                on: { change: _vm.setPortalId },
                model: {
                  value: _vm.userPortalId,
                  callback: function ($$v) {
                    _vm.userPortalId = $$v
                  },
                  expression: "userPortalId",
                },
              },
              _vm._l(_vm.portalData, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.fullName, value: item.id },
                })
              }),
              1
            )
          : _c("el-input", {
              attrs: { disabled: "", placeholder: "默认门户" },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }