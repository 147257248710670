import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

/** 环保突发事件-分页 */
export const mobilizationTotalNumber = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/dbtHumanResourceAnalysis/mobilizationTotalNumber',
    method: 'post',
    params
  })
}

/** 环保突发事件-分页 */
export const postInputDistribution = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/dbtHumanResourceAnalysis/postInputDistribution',
    method: 'post',
    params
  })
}
/** 环保突发事件-分页 */
export const laborForceCurve = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/dbtHumanResourceAnalysis/laborForceCurve',
    method: 'post',
    params
  })
}
