import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_DEVELOP_+'/code/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const build = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_DEVELOP_+'/code/gen-code',
    method: 'post',
    params: {
      ids,
      system: 'sinoma'
    }
  })
}
export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_DEVELOP_+'/code/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_DEVELOP_+'/code/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_DEVELOP_+'/code/submit',
    method: 'post',
    data: row
  })
}

export const copy = (id) => {
  return request({
    url: ServerNameEnum.SERVER_DEVELOP_+'/code/copy',
    method: 'post',
    params: {
      id,
    }
  })
}

export const getCode = (id) => {
  return request({
    url: ServerNameEnum.SERVER_DEVELOP_+'/code/detail',
    method: 'get',
    params: {
      id,
    }
  })
}
