import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
/** 安全会议 */
/** 头表接口 */
export const mettingHeadPage = (current, size, params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/grsafetymeetinghead/page`,
      method: 'get',
      params: {
        current,
        size,
        ...params
      }
    })
}

export const mettingHeadSubmit = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grsafetymeetinghead/submit`,
        method: 'post',
        data: params
    })
}

export const mettingHeadRemove = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grsafetymeetinghead/remove`,
        method: 'post',
        params: {ids}
    })
}

export const mettingHeadDetail = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grsafetymeetinghead/detail`,
        method: 'get',
        params
    })
}

/** 行表 */
export const mettingLinePage  = (current, size, params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/grsafetymeetingline/page`,
      method: 'get',
      params: {
        current,
        size,
        ...params
      }
    })
}

export const mettingLineSubmitBatch = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grsafetymeetingline/submitBatch`,
        method: 'post',
        data: params
    })
}

export const mettingLineRemove = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grsafetymeetingline/remove`,
        method: 'post',
        params: {ids}
    })
}

export const listUser = (params) => {
    return request({
      url: ServerNameEnum.SERVER_USER_+'/user-list',
      method: 'get',
      params
    })
  }