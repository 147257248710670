import httpService from '@/router/axios'

//获取项目管理
export const getCodeUrl = () => {
  return httpService({
    url: '/api/sinoma-sync/external/codeUrl',
    method: 'get',
  })
}

//获取项目管理
export const $_getlist = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemslist/page',
    method: 'get',
    params,
  })
}

//获取 摄像头播放地址 数据
export const $_getCameraUrl = (params) => {
  return httpService({
    url: '/api/sinoma-sync/camera/getLiveUrl',
    method: 'get',
    params,
  })
}

//获取系统组数据部门
export const $_getDeptList = (params) => {
  return httpService({
    url: '/api/sinoma-sync/dept/tree',
    method: 'get',
    params,
  })
}


//新增 项目管理
export const $_save = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemslist/save',
    method: 'post',
    data: params,
  })
}

//新增 项目管理
export const $_getDeptIds = (id) => {
  return httpService({
    url: `/api/sinoma-sync/itemsdept/getDeptIds/${id}`,
    method: 'get',
  })
}

// 详情 项目管理
export const $_detail = (id) => {
  return httpService({
    url: '/api/sinoma-sync/itemslist/detail/' + id,
    method: 'get',
    // params,
  })
}

//获取项目管理--人员审核--分页
export const $_getWisdomsecurityapplyPage = (params) => {
  return httpService({
    url: '/api/sinoma-sync/wisdomsecurityapply/page',
    method: 'get',
    params,
  })
}

//新增 项目管理---人员审核--
export const $_wisdomsecurityapplyAudit = (params) => {
  return httpService({
    url: '/api/sinoma-sync/wisdomsecurityapply/audit',
    method: 'post',
    data: params,
  })
}

//安全管理人员列表
export const $_getserslist = (params) => {
  return httpService({
    url: '/api/sinoma-sync/wisdomsecurity/list',
    method: 'get',
    params,
  })
}


// 新增  安全管理人员接口
export const $_addItemslistusers = (params) => {
  return httpService({
    url: '/api/sinoma-sync/project/saveBatch',
    method: 'post',
    data: params,
  })
}

//获取 施工现场
export const $_construction = (params) => {
  return httpService({
    // url: "/api/sinoma-sync/itemsconstruction/list",
    url: '/api/sinoma-sync/itemsconstruction/page',
    method: 'get',
    params,
  })
}

// 详情 施工现场
export const getDetail = (id) => {
  return httpService({
    url: `/api/sinoma-sync/itemsconstruction/detail/${id}`,
    method: 'get',
  })
}

// // 项目管理  树
// export const getDetail = (projectId) => {
//     return httpService({
//         url: `/api/sinoma-sync/itemsdept/tree/${projectId}`,
//         method: 'get',
//     })
// }

// 项目管理  不分页接口
export const $_itemslist = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemslist/list2',
    method: 'get',
    params,
  })
}

// 项目进度管理 不分页接口
export const $_mangList = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemsprogress/list2',
    method: 'get',
    params,
  })
}

// 新增  项目进展阶段接口
export const $_saveItemsprogress = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemsprogress/save',
    method: 'post',
    data: params,
  })
}

// 修改  项目管理
export const $_updateitemslist = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemslist/update',
    method: 'post',
    data: params,
  })
}

// 树  项目组织架构
export const getTenantTree = () => {
  return httpService({
    url: `/api/sinoma-sync/tenant/tree`,
    method: 'get',
  })
}

//系统人员（用户管理）
export const getUserPage = (params) => {
  return httpService({
    url: '/api/ivystar-user/page',
    method: 'get',
    params,
  })
}

// 新增  项目组织架构
export const $_addSaveItemsdept = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemsdept/save',
    method: 'post',
    data: params,
  })
}

// 编辑  项目组织架构
export const $_editUpdate = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemsdept/update',
    method: 'post',
    data: params,
  })
}

// 删除  项目组织架构
export const $_removeItemsdept = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemsdept/remove',
    method: 'post',
    params,
  })
}

// 详情  项目组织架构
export const $_getDetail = (id) => {
  return httpService({
    url: `/api/sinoma-sync/itemsdept/detail/${id}`,
    method: 'get',
  })
}

// 详情  职工工种管理接口
export const $_getDetailId = (id) => {
  return httpService({
    url: `/api/sinoma-sync/professionalwork/detail/${id}`,
    method: 'get',
  })
}

// 所属公司 树
export const $_getComTree = (params) => {
  return httpService({
    url: '/api/sinoma-sync/company/tree',
    method: 'get',
    params,
  })
}

// 公司项目树
export const $_getCompanyProjectTreeAll = (params) => {
  return httpService({
    url: '/api/sinoma-sync/company/companyProjectTreeAll',
    method: 'get',
    params,
  })
}

// 所属公司 分页page
export const $_getPageData = (params) => {
  return httpService({
    url: '/api/sinoma-sync/company/list',
    method: 'get',
    params,
  })
}

// 获取  公司管理
export const $_getCompanyList = (params) => {
  return httpService({
    url: '/api/sinoma-sync/company/list',
    method: 'get',
    params,
  })
}

//删除 项目管理
export const $_remove = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemslist/remove',
    method: 'post',
    params,
  })
}

//新增 项目管理
export const $_addSaveBatch = (params) => {
  return httpService({
    url: '/api/sinoma-sync/project/saveBatch',
    method: 'post',
    data: params,
  })
}

// 项目管理  批量编辑
export const $_updateBatch = (params) => {
  return httpService({
    url: '/api/sinoma-sync/project/updateBatch',
    method: 'post',
    data: params,
  })
}

//智慧安全用户扩展表接口
export const $_getPopPage = (params) => {
  return httpService({
    url: '/api/sinoma-sync/wisdomsecurity/list2',
    method: 'get',
    params,
  })
}

//智慧安全用户扩展表接口 page接口
export const $_getPersonnelDataPage = (params) => {
  return httpService({
    url: '/api/sinoma-sync/wisdomsecurity/page',
    method: 'get',
    params,
  })
}

/*项目管理-详情-项目危险源库 API start:*/
// 根据项目ID获取项目危险源库列表
export const getProjectHazardList = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemshazards/list',
    method: 'get',
    params,
  })
}

/* NEW:项目基础管理中已经绑定的风险源 */
export const getItemshazardsriskPage = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemshazardsrisk/page',
    method: 'get',
    params,
  })
}

// 根据项目ID获取项目危险源库列表-不分页
export const getProjectHazardAllList = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemshazards/list2',
    // url: "/api/sinoma-sync/hazards/list2",
    method: 'get',
    params,
  })
}

// 删除项目危险源库中的危险源
export const delProjectHazardItem = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemshazards/remove',
    method: 'post',
    params,
  })
}

// 获取危险源列表
export const getHazardList = (params) => {
  return httpService({
    url: '/api/sinoma-sync/hazards/list2',// 不分页
    method: 'get',
    params,
  });
};

// 获取危险源列表 (不分页)
export const $_getHazardList2 = (params) => {
  return httpService({
    url: "/api/sinoma-sync/hazards/list2",
    method: "get",
    params,
  });
};

// 给项目危险源库新增危险源
export const addProjectHazardItem = (data) => {
  return httpService({
    url: '/api/sinoma-sync/itemshazards/saveBatch',
    method: 'post',
    data,
  })
}

// 查看项目危险源库的危险源详情
export const getProjectHazardItemDetail = (id) => {
  return httpService({
    url: `/api/sinoma-sync/itemshazards/detail/${id}`,
    method: 'get',
  })
}

/*项目管理-详情-项目危险源库 API end*/

// 获取  区域管理
export const $_getpage_list = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemsarea/list',
    method: 'get',
    params,
  })
}

// 获取  区域管理 不分页
export const $_getpage_list2 = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemsarea/list2',
    method: 'get',
    params,
  })
}

// 新增  区域管理
export const $_addsave = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemsarea/save',
    method: 'post',
    data: params,
  })
}

// 修改  区域管理
export const $_updateRegional = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemsarea/update',
    method: 'post',
    data: params,
  })
}

// 临时删除÷
export const $_delRemoveData = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemsarea/remove',
    method: 'post',
    params,
  })
}

// 临时删除÷
export const $_RemovePapoData = (params) => {
  return httpService({
    url: '/api/sinoma-sync/project/remove',
    method: 'post',
    params,
  })
}

// // 详情 公司管理
// export const $_getCompanyDetail = (id) => {
//   return httpService({
//     url: `/api/sinoma-sync/company/detail/${id}`,
//     method: "get",
//   });
// };

//  凭userId查询当前人员的详情数据
export const $_detailByUserId = (userId) => {
  return httpService({
    url: `/api/sinoma-sync/wisdomsecurity/detailByUserId/${userId}`,
    method: 'get',
  })
}
export const $_checkByUserld = (userId) => {
  return httpService({
    url: `/api/sinoma-sync/wisdomsecurity/checkByUserId/${userId}`,
    method: 'get',
  })
}

export const $_userProjectLogList = (params) => {
  return httpService({
    url: '/api/sinoma-sync/project/userProjectLogList',
    method: 'get',
    params,
  })
}

//相关方接口
export const getItemscompanylist = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemscompany/page',
    method: 'get',
    params,
  })
}
//相关方新增接口
export const saveItemscompany = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemscompany/save',
    method: 'post',
    data: params,
  })
}

//相关方删除接口
export const removeItemscompany = (params) => {
  return httpService({
    url: '/api/sinoma-sync/itemscompany/remove',
    method: 'post',
    params,
  })
}
/*项目基础资料 > 项目硬件配置 start*/
// 获取基础设备列表数据
export const getConfigDeviceList = (params) => {
  return httpService({
    url: '/api/sinoma-sync/camerabase/page',
    method: 'get',
    params,
  })
}

// 获取项目硬件配置列表数据
export const getProjectConfigDeviceList = (params) => {
  return httpService({
    url: '/api/sinoma-sync/camera/page',
    method: 'get',
    params,
  })
}
// 新增一个基础设备
export const addNewDevice = (params) => {
  return httpService({
    url: '/api/sinoma-sync/camerabase/save',
    method: 'post',
    data: params,
  })
}
// 编辑设备别名
export const editDeviceAlias = (params) => {
  return httpService({
    url: '/api/sinoma-sync/camera/update',
    method: 'post',
    data: params,
  })
}

// 给项目绑定设备
export const bindDeviceByProject = (params) => {
  return httpService({
    url: '/api/sinoma-sync/camera/save',
    method: 'post',
    data: params,
  })
}


// 给项目解绑设备
export const unBindDeviceByProject = (params) => {
  return httpService({
    url: `/api/sinoma-sync/camera/remove?ids=${params}`,
    method: 'post',
    data: params,
  })
}
/*项目基础资料 > 项目硬件配置 end*/

/*获取大屏监控的相关数据*/

export const getSuperScreenData = (params) => {
  return httpService({
    url: '/api/sinoma-sync/videoScreen/info',
    method: 'get',
    data: params,
  })
}

// 获取项目级培训记录
export const getProjectTrainingRecords = (params) => {
  return httpService({
    url: '/api/sinoma-sync/traininginfo/page',
    method: 'get',
    params,
  })
}

// 培训统计
export const getProjectTrainingStatistics = (params) => {
  return httpService({
    url: '/api/sinoma-sync/traininginfo/statistics',
    method: 'get',
    params,
  })
}
// 获取培训记录详情
export const getTrainingRecordDetail = (id) => {
  return httpService({
    url: `/api/sinoma-sync/traininginfo/detail?id=${id}`,
    method: 'get'
  })
}

// 获取人员级培训记录
export const getPersonTrainingRecords = (params) => {
  return httpService({
    url: '/api/sinoma-sync/trainingworkerresult/worker/training',
    method: 'get',
    params,
  })
}

/* 数据字典---项目基础管理 'area_country' */
export const getDictionaryTree = (params) => {
  return httpService({
    url: '/api/sinoma-sync/dict/dictionary-tree',
    method: 'get',
    params,
  })
}

// 获取摄像头系统详情
export const getVideoSystemDetail = (params) => {
  return httpService({
    url: '/api/sinoma-sync/videosystem/detail',
    method: 'get',
    params,
  })
}
export const getView = (params) => {
  return httpService({
    url: '/api/sinoma-sync/projectOverView/getView',
    method: 'get',
    params,
  })
}

// 通过tenantId获取上级公司信息
export const getTenantParent = (params) => {
  return httpService({
    url: '/api/sinoma-sync/tenant/parent',
    method: 'get',
    params,
  })
}
