<template>
  <div class="popupSelect-container">
    <div class="el-select" @click="openDialog">
      <el-input :placeholder="placeholder" :class="{'link-style':this.disabled&&innerValue}"
        v-model="innerValue" readonly :validate-event="false"
        @mouseenter.native="inputHovering = true" @mouseleave.native="inputHovering = false">
        <template slot="suffix">
          <i v-show="!showClose"
            :class="['el-select__caret', 'el-input__icon', 'el-icon-arrow-up']"></i>
          <i v-if="showClose" class="el-select__caret el-input__icon el-icon-circle-close"
            @click.stop="clear"></i>
        </template>
      </el-input>
    </div>
    <template v-if="popupType==='dialog'">
      <el-dialog :title="popupTitle" :close-on-click-modal="false" :visible.sync="visible"
        v-if="visible" class="SNOWS-dialog SNOWS-dialog_center" lock-scroll append-to-body
        :width='popupWidth'>
        <el-row class="SNOWS-common-search-box" :gutter="16">
          <el-form @submit.native.prevent>
            <el-col :span="10">
              <el-form-item label="关键词">
                <el-input v-model="listQuery.keyword" placeholder="请输入关键词查询" clearable
                  @keyup.enter.native="search()" class="search-input" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="search()">
                  查询
                </el-button>
                <el-button icon="el-icon-refresh-right" @click="reset()">重置
                </el-button>
              </el-form-item>
            </el-col>
          </el-form>
          <div class="SNOWS-common-search-box-right">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link icon="icon-ym icon-ym-Refresh SNOWS-common-head-icon" :underline="false"
                @click="initData()" />
            </el-tooltip>
          </div>
        </el-row>
        <SNOWS-table v-loading="listLoading" :data="list" :border="false" highlight-current-row
          @row-click="rowClick" :hasNO="false">
          <el-table-column width="35">
            <template slot-scope="scope">
              <el-radio :label="scope.row.id" v-model="checked">&nbsp;</el-radio>
            </template>
          </el-table-column>
          <el-table-column type="index" width="50" label="序号" align="center" />
          <el-table-column :prop="item.value" :label="item.label" v-for="(item,i) in columnOptions"
            :key="i" />
        </SNOWS-table>
        <pagination :total="total" :page.sync="listQuery.currentPage"
          :limit.sync="listQuery.pageSize" @pagination="initData" v-if="hasPage" />
        <span slot="footer" class="dialog-footer">
          <el-button @click="visible = false" size="small">取消</el-button>
          <el-button type="primary" @click="select()" size="small">确定
          </el-button>
        </span>
      </el-dialog>
    </template>
    <template v-if="popupType ==='drawer'">
      <el-drawer :title="popupTitle" :visible.sync="visible" :wrapperClosable="false" ref="drawer"
        :size='popupWidth' append-to-body class="SNOWS-common-drawer">
        <div class="SNOWS-flex-main">
          <el-row class="SNOWS-common-search-box" :gutter="16">
            <el-form @submit.native.prevent>
              <el-col :span="10">
                <el-form-item label="关键词">
                  <el-input v-model="listQuery.keyword" placeholder="请输入关键词查询" clearable
                    @keyup.enter.native="search()" class="search-input" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <el-button type="primary" icon="el-icon-search" @click="search()">
                    查询
                  </el-button>
                  <el-button icon="el-icon-refresh-right" @click="reset()">重置
                  </el-button>
                </el-form-item>
              </el-col>
            </el-form>
            <div class="SNOWS-common-search-box-right">
              <el-tooltip effect="dark" content="刷新" placement="top">
                <el-link icon="icon-ym icon-ym-Refresh SNOWS-common-head-icon" :underline="false"
                  @click="initData()" />
              </el-tooltip>
            </div>
          </el-row>
          <SNOWS-table v-loading="listLoading" :data="list" :border="false" highlight-current-row
            @row-click="rowClick" :hasNO="false">
            <el-table-column width="35">
              <template slot-scope="scope">
                <el-radio :label="scope.row.id" v-model="checked">&nbsp;</el-radio>
              </template>
            </el-table-column>
            <el-table-column type="index" width="50" label="序号" align="center" />
            <el-table-column :prop="item.value" :label="item.label"
              v-for="(item,i) in columnOptions" :key="i" />
          </SNOWS-table>
          <pagination :total="total" :page.sync="listQuery.currentPage"
            :limit.sync="listQuery.pageSize" @pagination="initData" v-if="hasPage" />
          <div class="drawer-footer">
            <el-button @click="visible = false" size="small">取消
            </el-button>
            <el-button type="primary" @click="select()" size="small">确定
            </el-button>
          </div>
        </div>
      </el-drawer>
    </template>
    <el-form v-if="detailVisible">
      <Detail v-if="detailVisible" ref="Detail" @close="detailVisible = false" />
    </el-form>
  </div>
</template>

<script>
import { getFieldDataSelect, getDataChange, getConfigData } from '@/api/onlineDev/visualDev'
// import Detail from '@/views/basic/dynamicModel/list/detail'
export default {
  name: 'PopupSelect',
  // components: { Detail },
  props: {
    value: {
      default: ''
    },
    modelId: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    relationField: {
      type: String,
      default: ''
    },
    field: {
      type: String,
      default: ''
    },
    columnOptions: {
      type: Array,
      default: () => []
    },
    hasPage: {
      type: Boolean,
      default: false
    },
    pageSize: {
      type: Number,
      default: 20
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    popupType: {
      type: String,
      default: 'dialog'
    },
    popupTitle: {
      type: String,
      default: '选择数据'
    },
    popupWidth: {
      type: String,
      default: '800px'
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      list: [],
      innerValue: '',
      listQuery: {
        keyword: '',
        currentPage: 1,
        pageSize: 20
      },
      total: 0,
      checked: '',
      checkedTxt: '',
      checkedRow: {},
      listLoading: false,
      inputHovering: false,
      visible: false,
      detailVisible: false
    }
  },
  watch: {
    value(val) {
      this.setDefault()
    }
  },
  computed: {
    showClose() {
      let hasValue = this.multiple
        ? Array.isArray(this.value) && this.value.length > 0
        : this.value !== undefined && this.value !== null && this.value !== '';
      let criteria = this.clearable &&
        !this.disabled &&
        this.inputHovering &&
        hasValue;
      return criteria;
    }
  },
  created() {
    this.listQuery.pageSize = this.hasPage ? this.pageSize : 10000
    this.setDefault()
  },
  methods: {
    initData() {
      if (!this.modelId || !this.relationField) return
      this.listLoading = true
      let columnOptionsList = this.columnOptions.map(o => o.value)
      let query = {
        ...this.listQuery,
        relationField: this.relationField,
        columnOptions: columnOptionsList.join(',')
      }
      getFieldDataSelect(this.modelId, query).then(res => {
        this.list = res.data.list
        this.total = res.data.pagination.total
        this.listLoading = false
        this.setDefault()
      }).catch(() => { this.listLoading = false })
    },
    search() {
      this.listQuery.currentPage = 1
      this.listQuery.pageSize = this.hasPage ? this.pageSize : 10000
      this.initData()
    },
    reset() {
      this.listQuery.keyword = ''
      this.listQuery.currentPage = 1
      this.listQuery.pageSize = this.hasPage ? this.pageSize : 10000
      this.initData()
    },
    openDialog() {
      if (this.disabled) {
        if (!this.value) return
        getConfigData(this.modelId).then(res => {
          if (!res.data) return
          if (!res.data.formData) return
          let formData = JSON.parse(res.data.formData)
          formData.popupType = 'general'
          this.detailVisible = true
          this.$nextTick(() => {
            this.$refs.Detail.init(formData, this.modelId, this.value)
          })

        })
        return
      }
      this.checked = this.value
      this.visible = true
      this.reset()
    },
    clear() {
      this.checked = ''
      this.innerValue = ''
      this.checkedRow = {}
      this.$emit('input', this.checked)
      this.$emit('change', this.checked, this.checkedRow)
    },
    select() {
      if (!this.checked) return
      this.$emit('input', this.checked)
      this.$emit('change', this.checked, this.checkedRow)
      this.visible = false
    },
    rowClick(row) {
      this.checked = row.id
      this.checkedTxt = row[this.relationField]
      this.checkedRow = row
    },
    setDefault() {
      this.getItemValue(this.value)
    },
    getItemValue(val) {
      if (val) {
        if (!this.modelId) return
        getDataChange(this.modelId, val).then(res => {
          if (!res.data || !res.data.data) return
          let data = JSON.parse(res.data.data)
          this.innerValue = data[this.relationField]
          this.checkedTxt = data[this.relationField]
          if (!this.field) return
          let relationData = this.$store.state.generator.relationData
          this.$set(relationData, this.field, data)
          this.$eventBus.$emit('eventBus', relationData, this.field)
          this.$store.commit('generator/UPDATE_RELATION_DATA', relationData)
        })
      } else {
        this.innerValue = ''
        this.checkedTxt = ''
        if (!this.field) return
        let relationData = this.$store.state.generator.relationData
        this.$set(relationData, this.field, {})
        this.$eventBus.$emit('eventBus', relationData, this.field)
        this.$store.commit('generator/UPDATE_RELATION_DATA', relationData)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  height: 70vh;
  padding: 0 0 10px !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .SNOWS-common-search-box {
    margin-bottom: 0;
    .SNOWS-common-search-box-right {
      padding: 10px 10px 0 0;
    }
  }
}
.link-style {
  ::v-deep .el-input__inner {
    color: #1890ff !important;
    text-decoration: underline;
    cursor: pointer !important;
  }
}
</style>
