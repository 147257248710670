import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

/** 劳保用品领用记录 */
export const ohissuePage = (current, size, params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/ohissue/page`,
      method: 'get',
      params: {
        current, 
        size, 
        ...params
      }
    })
}

export const ohissueSubmit = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/ohissue/submit`,
        method: 'post',
        data: params
      })
}

// 删除
export const ohissueRemove = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/ohissue/remove`,
        method: 'post',
        params: {ids}
    })
}

export const ohissueDetail = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/ohissue/detail`,
    method: 'get',
    params
  })
}

/** 领用明细 */
export const ohissueDetailPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/ohissuedetail/page`,
    method: 'get',
    params: {
      current, 
      size, 
      ...params
    }
  })
}

export const ohissuedetailSubmit = (params) => {
  return request({
      url: `${ServerNameEnum.SERVER_FX}/ohissuedetail/submit`,
      method: 'post',
      data: params
    })
}

// 删除
export const ohissuedetailRemove = (ids) => {
  return request({
      url: `${ServerNameEnum.SERVER_FX}/ohissuedetail/remove`,
      method: 'post',
      params: {ids}
  })
}

export const ohissuedetailDetail = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/ohissuedetail/detail`,
    method: 'get',
    params
  })
}
