import Cookies from 'js-cookie'
import store from '@/store/'
const TokenKey = 'sinoma-access-token'
const RefreshTokenKey = 'sinoma-refresh-token'
export function getToken() {
    return Cookies.get(TokenKey) || store.getters.token
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function getRefreshToken() {
  return Cookies.get(RefreshTokenKey)
}

export function setRefreshToken(token) {
  return Cookies.set(RefreshTokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function removeRefreshToken() {
  return Cookies.remove(RefreshTokenKey)
}
