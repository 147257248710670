import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 获取业务字典
export const dictionaryBiz = (code) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dict-biz/dictionary?code=` + code,
    method: 'get'
  })
}
// 培训计划
export const gettrainingPlanpage = (current, size, params)=>{
  return request({
    url:ServerNameEnum.SERVER_TRAINING_+'/trainingPlan/page',
    method:'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
//课程类型
export const getDicData = () => {
  return request({
    url: `/api/sinoma-system/dict-biz/dictionary?code=course_type`,
    method: 'get'
  })
}
// 培训计划
export const getAll = ()=>{
  return request({
    url:ServerNameEnum.SERVER_TRAINING_+'/trainingPlan/getAll',
    method:'get',
  })
}

//新增培训计划
export const SaveTrainingPlan = (params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ +'/trainingPlan/save',
    method: 'post',
    data:params
  })
}

//修改培训计划
export const SaveTrainingUpdate = (params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ +'/trainingPlan/update',
    method: 'post',
    data:params
  })
}
// 培训计划详情
export const GettrainingDetail = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_TRAINING_+'/trainingPlan/detail',
    method:'get',
    params
  })
}

// 培训计划删除
export const DeltrainingPlan = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_TRAINING_+'/trainingPlan/delete',
    method:'get',
    params
  })
}




// 单位分组列表分页
export const GettrainingDeptGroup = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_TRAINING_+'/trainingDeptGroup/page',
    method:'get',
    params
  })
}

// 单位分组列表
export const gettrainingDeptGroupList = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_TRAINING_+'/trainingDeptGroup/groupDeptList',
    method:'get',
    params
  })
}


// 单位分组详情
export const DeptGroupDetail = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_TRAINING_+'/trainingDeptGroup/detail',
    method:'get',
    params
  })
}


// 删除单位
export const DelDeptGroup = (id)=>{
  return request({
    url:ServerNameEnum.SERVER_TRAINING_+'/trainingDeptGroup/delete',
    method:'get',
    params: {
      id: id
    }
  })
}

//单位分组保存或修改
export const SaveOrUpdateDeptGroup = (params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ +'/trainingDeptGroup/submit',
    method: 'post',
    data:params
  })
}

//单位分组保存
export const SavetrainingDeptGroup = (params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ +'/trainingDeptGroup/save',
    method: 'post',
    data:params
  })
}


//单位分组修改
export const UpdatetrainingDeptGroup = (params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ +'/trainingDeptGroup/update',
    method: 'post',
    data:params
  })
}


//单位分组绑定单位
export const trainingDeptGroupBindingDept = (params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ +'/trainingDeptGroup/bindingDept',
    method: 'post',
    data:params
  })
}

// 培训信息列表
export const trainingTaskPage = (current, size, params)=>{
  return request({
    url:ServerNameEnum.SERVER_TRAINING_+'/trainingTask/page',
    method:'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

// 培训信息详情
export const trainingTaskDetail = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_TRAINING_+'/trainingTask/detail',
    method:'get',
    params
  })
}

// 培训信息删除
export const trainingTaskDelete = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_TRAINING_+'/trainingTask/delete',
    method:'get',
    params
  })
}

//培训信息填写
export const trainingTaskSave = (params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ +'/trainingTask/save',
    method: 'post',
    data:params
  })
}

//培训信息修改
export const trainingTaskUpdate = (params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ +'/trainingTask/update',
    method: 'post',
    data:params
  })
}

//培训任务状态修改
export const trainingTaskStatusUpdate = (params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ +'/trainingTask/updateStatus',
    method: 'post',
    data:params
  })
}

//培训任务状态修改
export const trainingTaskIdUpdate = (params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ +'/trainingTask/updateTaskId',
    method: 'post',
    data:params
  })
}

//培训计划状态修改
export const trainingPlanIdUpdate = (params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ +'/trainingPlan/updateTaskId',
    method: 'post',
    data:params
  })
}

//我的任务状态修改
export const trainingTaskUseStatusUpdate = (params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ +'/trainingPlanUse/updateStatus',
    method: 'post',
    data:params
  })
}

//培训计划状态修改
export const trainingPlanStatusUpdate = (params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ +'/trainingPlan/updateStatus',
    method: 'post',
    data:params
  })
}
/**
 * 查询是否有重复数据
 */
export const getOrganization = (params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_+`/trainingPlan/getOrganization`,
    method: 'post',
    data:params
  })
}
//我的任务状态评价
export const evaluate = (params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ +'/trainingPlanUse/evaluate',
    method: 'post',
    data:params
  })
}

//任务统计
export const taskStatisticsPage = (current, size ,id, params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ +'/trainingTask/taskStatisticsPage',
    method: 'get',
    params: {
      ...params,
      id,
      current,
      size,
    }
  })
}

  //培训任务年度 修改
  export const trainingSonUpdate = (params) => {
    return request({
      url: ServerNameEnum.SERVER_TRAINING_ +'/trainingSon/update',
      method: 'post',
      data:params
    })
  }
  //培训任务年度 新增
  export const trainingSonSave = (params) => {
    return request({
      url: ServerNameEnum.SERVER_TRAINING_ +'/trainingSon/save',
      method: 'post',
      data:params
    })
  }
    //培训任务年度 查询
    export const trainingSonDetail = (params) => {
        return request({
          url:ServerNameEnum.SERVER_TRAINING_+'/trainingSon/detail',
          method:'get',
          params
        })
    }
  //培训任务年度 子表
    export const trainingSonPage = (params) => {
      return request({
        url: ServerNameEnum.SERVER_TRAINING_ + '/trainingSon/page',
        method: 'get',
        params
      })
    }

// 培训信息删除
export const trainingSonDelete = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_TRAINING_+'/trainingSon/remove',
    method:'get',
    params
  })
}

//我的培训
export const useTaskPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ +'/trainingPlanUse/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}


//我的培训
export const useTrainingId = (params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ +'/trainingSon/trainingId',
    method: 'get',
    params
  })
}

export const getDetailById = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingPlanUse/getDetailById`,
    method: 'get',
    params

  })
}

export const deptAllUser = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_USER_}/getTrainersBody`,
    method: 'post',
    data: params
  })
}

//培训课程视频
export const trainCourseList = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingPlanUse/startTraining`,
    method: 'post',
    data
  })
}

//培训课程视频
export const updateTrainCourse = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingPlanUse/updateTraining`,
    method: 'post',
    data
  })
}

// 培训任务新增
export const trainingTaskSubmit = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingTask/submit`,
    method: 'post',
    data
  })
}
// 调用更新用户培训状态接口
export const changeUserExam = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/client/changeUserExam`,
    method: 'post',
    data
  })
}
// 培训任务新增
export const removeUserByUserId = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingTask/removeUserByUserId`,
    method: 'post',
    data
  })
}
// 删除中间表数据
export const trainingTaskRemove = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingTask/remove?id=`+id,
    method:'get',
  })
}

export const trainingUpdateSign = (id,deptId,userId) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingTask/updateSign`,
    method: 'post',
      params: {
          id,
          deptId,
          userId
      }
  })
}
