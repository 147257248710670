<template>
<!--  <el-drawer :before-close="handleClose" :visible.sync="drawShow" :with-header="false" :wrapperClosable="true"-->
<!--             direction="rtl" size="280px">-->
    <div class="specificMainBox">
      <div class="specificTItle">
        <el-divider>{{ $t("cip.plat.sys.top.field.navigationMode") }}</el-divider>
      </div>
      <div class="navigationBigBox">
        <div v-for="(item,index) in imgUrl1" :key="index" class="navigationBox" @click="checkList(item)">
          <div class="navigationImgBox">
            <img :src="item.imgrurl" alt="">
            <div v-if="item.layoutType === layoutType" class="icon-checked">
              <i class=" el-icon-check"></i>
            </div>
          </div>
          <el-tooltip effect="dark" :content="item.name" placement="top-start">
            <span>{{ item.name }}</span>
          </el-tooltip>
        </div>
      </div>
      <div class="specificTItle">
        <el-divider>{{ $t("cip.plat.sys.top.field.colorBlock") }}</el-divider>
      </div>
      <div class="navigationBigBox">
        <div v-for="(item,index) in imgUrl2" :key="index" class="navigationBox" @click="checkList2(item)">
          <div class="navigationImgBox">
            <img :src="item.imgrurl" alt="">
            <div v-if="item.className === slideClass" class="icon-checked">
              <i class=" el-icon-check"></i>
            </div>
          </div>
          <span>{{ item.name }}</span>
        </div>
        <div style="width: 65px"></div>
      </div>
      <div class="specificTItle">
        <el-divider>{{ $t("cip.plat.sys.top.field.themeColors") }}</el-divider>
      </div>
      <div class="colorList">
        <el-tooltip v-for="(item,index) in imgUrl3" :key="index" :content="item.name" class="item" effect="dark"
                    placement="top">
          <el-tag :color="item.color" @click="checkList3(item)">
            <i v-if="item.className===themeClass" class="el-icon-check"></i>
          </el-tag>
        </el-tooltip>
      </div>
      <div class="specificTItle">
        <el-divider>{{ $t("cip.plat.sys.top.field.otherSettings") }}</el-divider>
      </div>
      <div class="drawer-item">
        <span>{{ $t("cip.plat.sys.top.field.multiTabOpeningMode") }}</span>
        <el-switch v-model="tagsView" class="drawer-switch"/>
      </div>
      <!--      <div class="drawer-item">-->
      <!--        <span>{{$t("cip.plat.sys.top.field.language")}}</span>-->
      <!--        <el-switch v-model="showLanguage" class="drawer-switch"/>-->
      <!--      </div>-->
      <div class="drawer-item">
        <span>{{ $t("cip.plat.sys.top.field.search") }}</span>
        <el-switch v-model="showSearch" class="drawer-switch"/>
      </div>
      <div class="drawer-item">
        <span>{{ $t("cip.plat.sys.top.field.stripe") }}</span>
        <el-switch v-model="showStripe" class="drawer-switch"/>
      </div>
      <div class="specificTItle">
        <el-divider>{{ $t("cip.desk.portalSwitch") }}</el-divider>
      </div>
      <div class="drawer-item">
        <el-select v-if="portalData.length>0" v-model="userPortalId" @change="setPortalId">
          <el-option
            v-for="item in portalData"
            :key="item.id"
            :label="item.fullName"
            :value="item.id">
          </el-option>
        </el-select>
        <el-input disabled v-else placeholder="默认门户"></el-input>
      </div>
    </div>
<!--  </el-drawer>-->
</template>
<script>
import themeColor from '@/assets/scss/theme.scss';
import {setTheme} from "@/util/util";
import themeMinix from '@/mixins/minix';
import {getEnablePortal, getUserPortal, userSubmit} from "@/api/onlineDev/portal";
import {mapGetters} from "vuex";
import {userStyleDetail, userStyleSave} from '@/api/user.js'

export default {
  props: ['specificShow'],
  mixins: [themeMinix],
  data() {
    return {
      form:{},
      userPortalId: '',
      portalData: [],
      themeClass: '',
      drawShow: false,
      imgUrl1: [
        {
          imgrurl: require('@/assets/settings/classic.png'),
          name: this.$t("cip.plat.sys.top.field.broadside"),
          layoutType: 'classic'
        },
        {
          imgrurl: require('@/assets/settings/functional.png'),
          name: this.$t("cip.plat.sys.top.field.top"),
          layoutType: 'functional'
        },
        {
          imgrurl: require('@/assets/settings/blend.png'),
          name: this.$t("cip.plat.sys.top.field.mix"),
          layoutType: 'blend'
        },
        // {
        //   imgrurl: require('@/assets/settings/plain.png'),
        //   name: '简约导航',
        //   layoutType: 'plain'
        // }
      ],
      imgUrl2: [
        {
          imgrurl: require('@/assets/settings/dark.png'),
          name: this.$t("cip.plat.sys.top.field.dark"),
          className: 'dark'
        },
        {
          imgrurl: require('@/assets/settings/lightWhite.png'),
          name: this.$t("cip.plat.sys.top.field.brightening"),
          className: 'lightWhite'
        }
      ],
      imgUrl3: [
        {
          name: '红',
          className: 'red',
          color: themeColor.redTheme,
        },
        {
          name: '蓝',
          className: 'blue',
          color: themeColor.blueTheme,
        },
        {
          name: '灰',
          className: 'grey',
          color: themeColor.greyTheme,
        },
        {
          name: '黑',
          className: 'black',
          color: themeColor.blackTheme,
        },
        {
          name: '天蓝',
          className: 'skyBlue',
          color: themeColor.skyBlueTheme,
        },
        {
          name: '橘红',
          className: 'tangerine',
          color: themeColor.tangerineTheme,
        },
        {
          name: '深蓝',
          className: 'darkBlue',
          color: themeColor.darkBlueTheme,
        },
        {
          name: '绿',
          className: 'green',
          color: themeColor.greenTheme,
        },
      ]
    }
  },
  computed: {
    defaultThemeClass() {
      return this.$store.state.common.themeClass
    },
    tagsView: {
      get() {
        return this.$store.state.common.tagsView;
      },
      set(val) {
        this.$store.commit('SET_TAGSVIEW', val)
        let multiTab;
        if (val == true) {
          multiTab = 'true'
        } else {
          multiTab = 'false'
        }
        this.form.multiTab = multiTab;
        this.saveUserStyle();
      }
    },
    tagsIcon: {
      get() {
        return this.$store.state.common.tagsIcon;
      },
      set(val) {
        this.$store.commit('SET_TAGSICON', val)
      }
    },
    showLanguage: {
      get() {
        return this.$store.state.common.showLanguage;
      },
      set(val) {
        this.$store.commit('SET_SHOWLANGUAGE', val)
      }
    },
    showSearch: {
      get() {
        return this.$store.state.common.showSearch;
      },
      set(val) {
        this.$store.commit('SET_SHOWSEARCH', val)
        let searchBox;
        if (val == true) {
          searchBox = 'true'
        } else {
          searchBox = 'false'
        }
        this.form.searchBox = searchBox;
        this.saveUserStyle();
      }
    },
    showStripe: {
      get() {
        return this.$store.state.common.showStripe;
      },
      set(val) {
        this.$store.commit('SET_SHOWSTRIPE', val)
        let tableStyle;
        if (val == true) {
          tableStyle = 'true'
        } else {
          tableStyle = 'false'
        }
        this.form.tableStyle = tableStyle;
        this.saveUserStyle();
      }
    },
    useCache: {
      get() {
        return this.$store.state.common.useCache;
      },
      set(val) {
        this.$store.commit('SET_USECACHE', val)
      }
    },
    layoutType: {
      get() {
        return this.$store.state.common.layoutType;
      },
      set(val) {
        // 更改当前用户主题，需增加新街口
        this.$store.commit('SET_LAYOUTTYPE', val)
      }
    },
    slideClass: {
      get() {
        return this.$store.state.common.slideClass;
      },
      set(val) {
        this.$store.commit('SET_SLIDECLASS', val)
      }
    },
    isCollapse: {
      get() {
        return this.$store.state.common.isCollapse;
      },
    },
    ...mapGetters(['userInfo', 'portalId'])
  },
  inject: ["topIndex"],
  watch: {
    defaultThemeClass: {
      handler: function (val, oldVal) {
        if (!val) return
        this.themeClass = val
        let activeItem = this.imgUrl3.filter(o => o.className === val)[0]
        this.theme = activeItem && activeItem.color ? activeItem.color : "#1890ff"
      },
      immediate: true
    },
  },
  mounted() {
    this.getPortalData();
    this.getUserStyleDetail();
  },
  methods: {
    getUserStyleDetail() {
      userStyleDetail(this.userInfo.user_id).then((res) =>{
        if (res.data.code == 200) {
          this.form = res.data.data;
        }
      })
    },
    saveUserStyle() {
      userStyleSave(this.form).then((res) =>{
      })
    },
    getUserPortal() {
      this.userPortalId = '';
      getUserPortal(this.userInfo.user_id).then(res => {
        if (res.data.data == null) {
          if (this.portalData.length != 0) {
            this.userPortalId = this.portalData[0].id
          }
        } else {
          if (res.data.data.portalId == '' && this.portalData.length != 0) {
            this.userPortalId = this.portalData[0].id
          } else {
            this.userPortalId = res.data.data.portalId;
          }
        }
      })
    },
    setPortalId(val) {
      userSubmit(this.userInfo.user_id,this.userInfo.role_id, val).then(res => {
        this.$store.commit('SET_PORTALID', val)
      })
    },
    getPortalData() {
      getEnablePortal(this.userInfo.role_id).then(res => {
        this.portalData = res.data.data;
        this.getUserPortal();
      })
    },
    // 选择导航模式
    checkList(item) {
      if (item.layoutType === this.layoutType) return
      this.layoutType = item.layoutType;
      this.form.navigateMode = this.layoutType;
      this.saveUserStyle();
      if (this.layoutType === 'blend') {
        this.$store.dispatch("GetMenuOnly")
      }
      window.localStorage.setItem("layoutType", this.layoutType);//保存数据
      this.topIndex.getMenuList(item);
      if (this.layoutType == 'plain') {
        this.$store.commit("SET_COLLAPSE", true);
      } else {
        this.$store.commit("SET_COLLAPSE", false);
      }
    },
    // 选择主题颜色
    checkList2(item) {
      if (item.className === this.slideClass) return
      this.slideClass = item.className
      this.form.colorBlock = this.slideClass;
      this.saveUserStyle();
      this.$store.commit("SET_THEME_NAME", this.slideClass);
      setTheme(this.slideClass);
    },
    // 选择全局颜色
    checkList3(item) {
      if (item.className === this.themeClass) return;
      this.$store.commit('SET_THEMECLASS', item.className);
      this.$store.commit('SET_THEMECOLOR', item.color);
      this.form.themeColors = item.color;
      this.form.themeClass = item.className;
      this.saveUserStyle();
    },
    handleClose() {
      this.drawShow = false;
    },
    handleOpen() {
      this.drawShow = true;
    }
  }
}
</script>
<style lang="scss" scoped>
.specificMainBox {
  //padding: 30px 20px;
  overflow: auto;
  overflow-x: hidden;
  height: calc(100% - 180px);

  .specificTItle {
    margin: 24px 0;

    .el-divider__text {
      font-size: 14px !important;
    }
  }

  .navigationBigBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .navigationBox {
      cursor: pointer;
      text-align: center;
      color: #606266;
      font-size: 12px;
      margin-bottom: 10px;
      line-height: initial;

      .navigationImgBox {
        width: 65px;
        height: 55px;
        display: block;
        border-radius: 10px;
        overflow: hidden;
        margin: 0 auto 10px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .icon-checked {
        display: block;
        width: 0px;
        height: 0px;
        border: 10px solid #409eff;
        border-left: 10px solid transparent;
        border-top: 10px solid transparent;
        border-bottom-right-radius: 10px;
        position: absolute;
        transform: scale(0.8);
        right: -1px;
        bottom: -1px;

        i {
          position: absolute;
          top: -2px;
          left: -3px;
          font-size: 12px;
          color: #fff;
          transform: scale(0.8);
        }
      }

      span {
        height: 18px;
        line-height: 18px;
        width: 70px;
        display: block;
        overflow: hidden;
        margin: auto;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .colorList {
    padding: 10px 0;
    line-height: initial;

    .el-tag {
      width: 24px;
      height: 24px;
      border: none !important;
      margin-right: 4px;
      border-radius: 2px;
      cursor: pointer;
      padding: 0;
      text-align: center;
      vertical-align: top;
    }

    .el-icon-check {
      color: #fff;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .drawer-item {
    color: #606266;
    font-size: 14px;
    padding: 12px 0;
    line-height: initial;
  }

  .drawer-switch {
    float: right;
  }
}
</style>
