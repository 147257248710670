import request from '@/router/axios';
const SERVER_FX = '/api/sinoma-hse-prj'

// 应急物资列表
export const ermaterialPage = (current, size, params) => {
  return request({
    url: SERVER_FX + '/ermaterial/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const ermaterialList = (params) => {
  return request({
    url: SERVER_FX + '/ermaterial/list',
    method: 'get',
    params
  })
}

export const ermaterialGetDetail = (id) => {
  return request({
    url: SERVER_FX + '/ermaterial/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const ermaterialRemove = (ids) => {
  return request({
    url: SERVER_FX + '/ermaterial/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const ermaterialAdd = (row) => {
  return request({
    url: SERVER_FX + '/ermaterial/submit',
    method: 'post',
    data: row
  })
}

