<template>
  <div class="avue-top">
    <i
      v-if="layoutType == 'classic' || layoutType == 'blend'"
      :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
      style="font-size: 20px; cursor: pointer"
      @click="changeCollapse"
    ></i>
    <div class="topSidderLogo" v-if="layoutType == 'functional'">
      <img v-if="language == 'zh'" src="@/assets/images/sidder-logo.png"/>
      <img v-if="language == 'en'" src="@/assets/images/sidder-logo-en.png"/>
    </div>
    <!--    顶部导航栏-->
    <topsidder
      ref="topSidder"
      v-if="layoutType == 'functional' && topMeanShow == true"
    />
    <top-menu
      v-if="layoutType == 'blend' && topMeanShow == true"
      ref="topMenu"
    ></top-menu>
    <!-- <div class="top-bar__left">
        <div v-if="showCollapse" class="avue-breadcrumb">
            <a href="#" @click="getAllMenu">
                <img :src='file' class="logo" />
            </a>
        </div>
    </div> -->
    <div class="top-bar__title">
      <!--            <el-collapse-transition>-->
      <!--                <div v-show="topMeanShow == true" class="transition-box">-->
      <!--                    <div v-if="showMenu" class="top-bar__item top-bar__item&#45;&#45;show">-->
      <!--                        <top-menu ref="topMenu"></top-menu>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--            </el-collapse-transition>-->
      <el-collapse-transition>
        <span v-show="topMeanShow == false">
          <span v-if="showSearch" class="top-bar__item topSearchInput">
            <top-search @setMean="setMean"></top-search>
          </span>
        </span>
      </el-collapse-transition>
    </div>
    <div class="top-bar__right">
      <el-tooltip
        :content="$t('tagsView.search')"
        effect="dark"
        placement="bottom"
        v-if="showSearch"
      >
        <div class="top-bar__item">
          <i
            class="el-icon-search"
            style="cursor: pointer"
            @click="changeMeanStatus"
          ></i>
        </div>
      </el-tooltip>
      <!--      <el-tooltip content="智能助理" effect="dark" placement="bottom">-->
      <!--        <div :class="{ twinkle: isTwinkle }" class="top-bar__item">-->
      <!--          <i-->
      <!--            class="robot"-->
      <!--            style="cursor: pointer"-->
      <!--            @click="changeChatStatus()"-->
      <!--          ></i>-->
      <!--        </div>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip :content="$t('tagsView.chat')" effect="dark" placement="bottom">-->
      <!--        <div :class="{'twinkle':isTwinkle}" class="top-bar__item">-->
      <!--          <i class="el-icon-chat-dot-round" style="cursor: pointer" @click="openUserList()"></i>-->
      <!--        </div>-->
      <!--      </el-tooltip>-->
      <el-tooltip content="切换旧版" effect="dark" placement="bottom">
        <div class="top-bar__item" @click="switchSystem">
          <i
            slot="reference"
            class="iconfont icon-qiehuanxitong"
            style="cursor: pointer"
          ></i>
        </div>
      </el-tooltip>
      <el-tooltip content="微信小程序" effect="dark" placement="bottom">
        <div class="top-bar__item">
          <i
            slot="reference"
            class="el-icon-mobile-phone"
            style="cursor: pointer"
            @click="phoneShow = true"
          ></i>
        </div>
      </el-tooltip>
      <!--      <el-tooltip v-if="showColor" :content="$t('navbar.color')" effect="dark" placement="bottom">-->
      <!--        <div class="top-bar__item">-->
      <!--          <top-color></top-color>-->
      <!--        </div>-->
      <!--      </el-tooltip>-->
      <el-tooltip
        :content="$t('tagsView.feedback')"
        effect="dark"
        placement="bottom"
      >
        <div class="top-bar__item">
          <i
            class="el-icon-s-order"
            style="cursor: pointer"
            @click="feedback"
          ></i>
        </div>
      </el-tooltip>
      <!--      <el-badge :hidden=isRead :value="value">-->
      <!--        <div class="suitem" @click="messageSend(item)">-->
      <!--          <img height="36" src="../../../../public/img/top/notice.png" width="36" />-->
      <!--        </div>-->
      <!--      </el-badge>-->
      <el-tooltip
        :content="$t('tagsView.messageSend')"
        effect="dark"
        placement="bottom"
      >
        <div class="top-bar__item">
          <el-badge :is-dot="isRead" class="item">
            <i
              class="el-icon-message"
              style="cursor: pointer"
              @click="messageSend"
            ></i>
          </el-badge>
        </div>
      </el-tooltip>
      <el-tooltip
        :content="$t('tagsView.shortcutMenu')"
        effect="dark"
        placement="bottom"
      >
        <div class="top-bar__item">
          <i
            class="el-icon-menu"
            style="cursor: pointer"
            @click="changeShortcutStatus"
          ></i>
        </div>
      </el-tooltip>
      <!--      <el-tooltip :content="$t('tagsView.language')" effect="dark" placement="bottom" v-if="showLanguage">-->
      <!--        <div class="top-bar__item">-->
      <!--          <el-dropdown trigger="click" @command="handleSetLanguage" placement="bottom">-->
      <!--            <i class="icon-guojihua1" style="cursor: pointer;color:#ffffff"></i>-->
      <!--            <el-dropdown-menu slot="dropdown">-->
      <!--              <el-dropdown-item v-for="item of systemLangList" :key="item.id" :disabled="language === item.dictKey"-->
      <!--                                :command="item.dictKey">-->
      <!--                {{ item.dictValue }}-->
      <!--              </el-dropdown-item>-->
      <!--            </el-dropdown-menu>-->
      <!--          </el-dropdown>-->
      <!--        </div>-->
      <!--      </el-tooltip>-->
      <!-- 在线帮助文档 -->
      <!--      <el-tooltip :content="$t('tagsView.document')" effect="dark" placement="bottom">-->
      <!--        <div class="top-bar__item">-->
      <!--          <i class="online-help icon-help" style="cursor: pointer" @click="openOnlineHelp"></i>-->
      <!--        </div>-->
      <!--      </el-tooltip>-->
      <!--           <el-tooltip-->
      <!--v-if="showLock"-->
      <!--effect="dark"-->
      <!--:content="$t('navbar.lock')"-->
      <!--placement="bottom"-->
      <!--&gt;-->
      <!--<div class="top-bar__item">-->
      <!--<top-lock></top-lock>-->
      <!--</div>-->
      <!--</el-tooltip>-->
      <el-tooltip
        v-if="showFullScren"
        :content="
          isFullScren ? $t('tagsView.screenfullF') : $t('tagsView.screenfull')
        "
        effect="dark"
        placement="bottom"
      >
        <div class="top-bar__item">
          <i
            :class="isFullScren ? 'icon-tuichuquanping' : 'icon-quanping'"
            style="cursor: pointer"
            @click="handleScreen"
          ></i>
        </div>
      </el-tooltip>
      <el-link @click="portrait" :underline="false">
        <span class="el-dropdown-link">
          <!--                    {{ loginUser.realName.zh}}-->
          <img
            v-if="userInfo.avatar"
            :src="userInfo.avatar"
            class="top-bar__img"
            style="cursor: pointer"
          />
          <img
            v-else
            src="@/assets/images/default_avatar.png"
            class="top-bar__img"
            style="cursor: pointer"
          />
        </span>
      </el-link>
      <!--      <el-tooltip :content="$t('tagsView.setup')" effect="dark" placement="bottom">-->
      <!--        <div class="top-bar__item">-->
      <!--          <i class="icon-ym icon-ym-header-pannel" style="cursor: pointer" @click="openSpecific"></i>-->
      <!--        </div>-->
      <!--      </el-tooltip>-->
      <el-drawer
        :direction="leftDirection"
        :modal="false"
        :visible.sync="leftDrawer"
        :with-header="false"
        custom-class="ht-drawer"
        size="23%"
        title="菜单栏"
      >
        <div v-if="isAllMenu == true" class="menu_title">
          <div v-for="(item, index) of menuData" :key="index">
            <div class="menu_fir_title">
              <div v-if="item.name != '协同办公'">
                <p>·{{ item.name }}</p>
                <div
                  v-for="(i, idx) of item.children"
                  :key="idx"
                  class="menu_sed_title"
                >
                  <span @click="getMenuGo(i)">{{ i.name }}</span>
                </div>
              </div>
              <div v-if="item.name == '协同办公'">
                <div v-for="(i, idx) of item.children" :key="idx">
                  <p>·{{ i.name }}</p>
                  <div
                    v-for="(it, idt) of i.children"
                    :key="idt"
                    class="menu_sed_title"
                  >
                    <span @click="getMenuGo(it)">{{ it.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isAllMenu == false" class="menu_title">
          <p v-if="isThreeShow == true" class="menu_First_p">·{{ menuName }}</p>
          <div v-for="(item, index) of menuData" :key="index">
            <div v-if="isThreeShow == true">
              <div class="menu_First_title">
                <span @click="getMenuGo(item)">{{ item.name }}</span>
              </div>
            </div>
            <div v-if="isThreeShow == false" class="menu_fir_title">
              <p>·{{ item.name }}</p>
              <div
                v-for="(i, idx) of item.children"
                :key="idx"
                class="menu_sed_title"
              >
                <span @click="getMenuGo(i)">{{ i.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
      <el-dialog
        title="微信小程序"
        class="phoneBox"
        :visible.sync="phoneShow"
        width="700px"
      >
        <div class="con">
          <el-row>
            <el-col :span="12" class="left">
              <img
                class="conLeftImg"
                src="@/assets/images/phone_em.png"
                alt=""
              />
            </el-col>
            <el-col :span="12" class="right">
              <p class="name">微信小程序</p>
              <div class="ewmIcon">
                <div :span="8" class="left">
                  <div class="iconItem">
                    <!-- <a-icon class="icons" type="android" /> -->
                    <img src="@/assets/images/weChart.png" alt="" srcset=""/>
                  </div>
                  <img class="ewm" src="@/assets/images/wx_applet.jpg" alt=""/>
                  <p class="title">扫描二维码进入微信小程序</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-dialog>
      <el-drawer
        :direction="direction"
        :modal="false"
        :visible.sync="drawer"
        :with-header="false"
        size="350px"
        title="个人设置"
      >
        <div class="card_posit">
          <div class="person_top">
            <div class="person_img">
              <img
                v-if="userInfo.avatar"
                :src="userInfo.avatar"
                class="image"
              />
              <img
                v-else
                src="@/assets/images/default_avatar.png"
                class="image"
              />
            </div>
            <div
              class="person_title">
              <span class="person_account">
                {{ userInfo.realName }}
              </span>
              <!--              <span class="person_account" v-if="language == 'en'">-->
              <!--                {{ loginUser.realName.en || loginUser.realName.zh }}-->
              <!--              </span>-->
              <el-tooltip
                class="item"
                effect="dark"
                :content="deptNoChange ? deptStart : dept.title"
                placement="top-start"
              >
                <!-- <span class="person_uid">{{ loginUser.deptName }}</span> -->
                <!--  -->
                <span v-if="deptNoChange" class="person_uid">{{
                    deptStart
                  }}</span>
                <span v-else class="person_uid">{{ dept.title }}</span>
                <!--  -->
              </el-tooltip>
            </div>
            <i
              class="el-icon-s-tools"
              :style="{ color: themeColor }"
              style="font-size: 24px; cursor: pointer"
              @click="clickUser"
            ></i>
          </div>
          <div class="deptBox">
            <avue-input-tree
              default-expand-all
              v-model="deptId"
              placeholder="请选择所属部门"
              @change="chooseDept"
              :props="inputTreeProps"
              :dic="nowUserDeptList"
            >
              <template slot-scope="{ item }">
                <div class="tree-node">
                  <i
                    v-if="item.isDefaultDept == 1"
                    class="el-icon-s-flag"
                    style="color: #c5211b"
                  ></i>
                  <div
                    :style="{
                      'margin-left': item.isDefaultDept == 1 ? '8px' : '22px',
                    }"
                  >
                    {{ item.title }}
                  </div>
                </div>
              </template>
            </avue-input-tree>
          </div>
          <top-specific ref="specific"></top-specific>
          <!--          <div class="sudoku_row">-->
          <!--            <div class="sudoku_item" @click="clickUser()">-->
          <!--              <img height="36" src="../../../../public/img/top/userinfo.png" width="36"/>-->
          <!--              <span>{{ $t("navbar.userinfo") }}</span>-->
          <!--            </div>-->
          <!--            <div class="sudoku_item" @click="clickLogs()">-->
          <!--              <img height="36" src="../../../../public/img/top/bugSet.png" width="36"/>-->
          <!--              <span>{{ $t("navbar.bugSet") }}</span>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="logout">
            <el-button
              size="medium"
              style="width: 100%"
              type="primary"
              @click.native="logout"
            >
              {{ $t("navbar.logOut") }}
            </el-button>
          </div>

        </div>
      </el-drawer>
    </div>
    <top-lang :dialogVisible="dialogVisible" @hideLang="hideLang"></top-lang>
    <top-theme ref="wTheme" style="display: none"></top-theme>
    <top-logs ref="wLogs" style="display: none"></top-logs>
    <!--    <top-specific ref="specific"></top-specific>-->
    <!--意见反馈-->
    <el-dialog
      :title="$t('cip.plat.sys.feedback.field.feedback')"
      :visible.sync="feedbackBox"
      append-to-body
      modal-append-to-body
      top="50px"
      width="60%"
    >
      <el-input
        v-model="feedbackForm.feedback"
        :placeholder="$t('cip.plat.sys.feedback.msg.suggest')"
        type="textarea"
      ></el-input>
      <br/>
      <br/>
      <img :src="feedbackForm.screenshot" width="100%"/>
      <span slot="footer">
        <el-button icon="el-icon-check" type="primary" @click="handleSubmit">{{
            $t("cip.cmn.btn.submitBtn")
          }}</el-button>
      </span>
    </el-dialog>
    <UserList ref="UserList" @changeTwinkle="changeTwinkle"/>


    <div v-if="isVisible">
      <div
          class="floating-box"
          :class="{ expanded: isExpanded }"
          :style="{ left: position.x + 'px', top: position.y + 'px' }"
          @mousedown="startDrag"
      >
        <div class="toggle-button" @click.stop="toggleExpand">
          <span v-if="isExpanded"><i class="el-icon-zoom-out"></i></span>
          <span v-else><i class="el-icon-zoom-in"></i></span>
        </div>
        <div class="box-content">
          <div class="noticeTitle" :style="{backgroundColor:themeColor}">{{ "部署通知："+title }}</div>
          <div class="noticeContent">{{ content }}</div>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
import {getRoutes} from "@/api/system/menu";
import {resetRouter} from "@/router/router";
import {mapGetters, mapState} from "vuex";
import {getNotice} from "@/api/desk/deployNotice";
import {fullscreenToggel, listenfullscreen} from "@/util/util";
import topLock from "./top-lock";
import topMenu from "./top-menu";
import topSearch from "./top-search";
import topTheme from "./top-theme";
import UserList from "@/page/index/UserList";
import topLogs from "./top-logs";
import topColor from "./top-color";
import topNotice from "./top-notice";
import topLang from "./top-radioLang";
import topSpecific from "./top-specific";
import {getLogo} from "@/api/system/params";
import {getStats, getQRcode} from "@/api/system/appVersion";
import {add, putFile} from "../../../api/system/feedback";
import feedbackOn from "@/views/system/feedback.vue";
import topsidder from "@/page/index/sidebar/topsidder";
import {getDic} from "@/api/dict";
import website from "@/config/website";
import themeMinix from "@/mixins/minix";
import {
  getMsgSetting,
  getBcstSettings,
  nowUserDept,
  refreshToken,
} from "@/api/user";
import themeColor from "@/assets/scss/theme.scss";
import html2canvas from "html2canvas";
import {selectMsgByAccount} from "@/api/message/sendInfo";
import axios from "axios";

var deskWS;
var ws;
export default {
  inject: ["index"],
  mixins: [themeMinix],
  provide() {
    return {
      topIndex: this,
    };
  },
  components: {
    topLock,
    topMenu,
    topSearch,
    topTheme,
    topLogs,
    topColor,
    topNotice,
    topLang,
    feedbackOn,
    UserList,
    topSpecific,
    topsidder,
  },
  name: "top",
  data() {
    return {
      isVisible:false,
      position: { x: 50, y: 50 }, // 初始位置
      dragging: false,
      offset: { x: 0, y: 0 },
      title: "",
      content: "",
      isExpanded: false,

      scrollIndex: 0,
      deskWS: null, //建立的连接
      timeout: 28 * 1000, //30秒一次心跳

      isRead: false,
      value: "",
      isTwinkle: false,
      feedbackForm: {
        screenshot: "",
        feedback: "",
        submitter: "",
      },
      inputTreeProps: {
        label: "title",
        value: "id",
      },
      socket: "",
      visible: false,
      feedbackBox: false,
      file: "",
      downPath: "../th.png",
      RobotModuleShow: false,
      isUserShow: false,
      topMeanShow: true,
      dialogVisible: false,
      curSelect: null,
      noticeShow: false,
      drawer: false,
      direction: "rtl",
      leftDrawer: false,
      leftDirection: "ltr",
      menuData: [],
      menuList: [],
      menuItem: {},
      menuName: "",
      isThreeShow: true,
      isAllMenu: false,
      specificShow: false,
      phoneShow: false,
      systemLangList: [],
      // loginUser: {
      //   tokensMd: "",
      // },
      //
      deptNoChange: true, //所属部门未改变
      dept: {}, //一开始所属部门
      //
      nowUserDeptList: [],
      userInfoShow: false,
      imgUrl3: [
        {
          name: this.$t("cip.plat.sys.top.field.blue"),
          className: "blue",
          color: themeColor.blueTheme,
        },
        {
          name: this.$t("cip.plat.sys.top.field.purple"),
          className: "purple",
          color: themeColor.purpleTheme,
        },
        {
          name: this.$t("cip.plat.sys.top.field.azure"),
          className: "azure",
          color: themeColor.azureTheme,
        },
        {
          name: this.$t("cip.plat.sys.top.field.ocean"),
          className: "ocean",
          color: themeColor.oceanTheme,
        },
        {
          name: this.$t("cip.plat.sys.top.field.green"),
          className: "green",
          color: themeColor.greenTheme,
        },
        {
          name: this.$t("cip.plat.sys.top.field.yellow"),
          className: "yellow",
          color: themeColor.yellowTheme,
        },
        {
          name: this.$t("cip.plat.sys.top.field.orange"),
          className: "orange",
          color: themeColor.orangeTheme,
        },
        {
          name: this.$t("cip.plat.sys.top.field.red"),
          className: "red",
          color: themeColor.redTheme,
        },
      ],
      broadcast: {
        code: "CIP-VOICE-PLAY",
        data: {
          text: "",
          vcn: "xiaofeng",
          speed: 60,
          volume: 20,
          rdn: "0",
          rcn: "1",
          reg: 2,
          sync: false,
          queue: false,
          loop: {
            duration: 999,
            times: 1,
            gap: 1,
          },
          prompt: false,
        },
      },
    };
  },
  filters: {},
  mounted() {
    this.initStats();
    this.getNowUserDept();
    this.getNotice();
    listenfullscreen(this.setScreen);
    this.getTagShow();
    this.getinfomation();
    this.getSystemLang();
    this.getChannel();
    this.deskWebSocket()
  },

  computed: {
    deptId() {
      return this.userInfo.deptId
    },
    deptStart() {
      let deptObj = this.nowUserDeptList.filter((item) => item.id == this.userInfo.deptId)[0] || {};
      return deptObj.title
    },
    flag() {
      return this.feedbackForm.screenshot;
    },
    defaultThemeClass() {
      return this.$store.state.common.themeClass;
    },
    ...mapState({
      showDebug: (state) => state.common.showDebug,
      showTheme: (state) => state.common.showTheme,
      showLock: (state) => state.common.showLock,
      showFullScren: (state) => state.common.showFullScren,
      showCollapse: (state) => state.common.showCollapse,
      showSearch: (state) => state.common.showSearch,
      showMenu: (state) => state.common.showMenu,
      showColor: (state) => state.common.showColor,
      isCollapse: (state) => state.common.isCollapse,
      showShortcutMenu: (state) => state.common.showShortcutMenu,
      showLanguage: (state) => state.common.showLanguage,
      layoutType: (state) => state.common.layoutType,
    }),
    ...mapGetters([
      "currentUser",
      "isFullScren",
      "tagWel",
      "tagList",
      "isCollapse",
      "tag",
      "logsLen",
      "logsFlag",
      "colorName",
      "menuAll",
      "menuId",
      "layoutType",
      "showLanguage",
      "language",
      "topMenuClickIndex",
      "userInfo",
      "openChatState",
      "themeColor",
    ]),
  },
  watch: {
    defaultThemeClass: {
      handler: function (val, oldVal) {
        if (!val) return;
        this.themeClass = val;
        let activeItem = this.imgUrl3.filter((o) => o.className === val)[0];
        this.theme =
          activeItem && activeItem.color ? activeItem.color : "#1890ff";
      },
      immediate: true,
    },
  },
  methods: {
    getNotice(){
      getNotice().then((res)=>{
        let result = res.data.data
        if(result.status===0){
          this.title = result.title
          console.log("result",result)
          console.log("result.content",result.remark)
          this.content = result.remark
          this.isVisible = true
          return;
        }
        this.isVisible = false
      })
    },
    //跳转数字施工系统
    switchSystem() {
      let url = `/SignonService/SignonService2/data/GetAuthCode.ashx`;
      axios.get(url).then((res) => {
        if (res.status == 200 && res.data) {
          let authData = res.data;
          let identityid = this.userInfo.phone; //手机号码
          let urlPath = `${process.env.VUE_APP_BASE_SIGNONSERVICE}/Auth/SignOn.aspx?identityid=${identityid}&authcode=${authData.authcode}&timestamp=${authData.timestamp}&token=${authData.token}&autohome=true`;
          window.open(urlPath, "_blank");
        }
      });
    },
    chooseDept(e) {
      if (e == this.userInfo.deptId) return;
      // console.log(this.userInfo.dept_id, "this.userInfo.dept_id");
      this.deptNoChange = false;
      this.dept = this.nowUserDeptList.filter((item) => item.id == e)[0] || {};
      // console.log(this.dept, "dept");
      // let dept = this.nowUserDeptList.filter(item => item.id == e)[0] || {}
      let grant_type=this.userInfo.grant_type
      refreshToken(
        this.userInfo.refresh_token,
        this.userInfo.tenant_id,
        e,
        this.userInfo.role_id
      ).then(async (res) => {
        if (res.status == 200) {
          console.log(res)
          const data = res.data;
          this.$store.commit("DEL_ALL_TAG");
          this.$store.commit("SET_TOKEN", data.access_token);
          this.$store.commit("SET_REFRESH_TOKEN", data.refresh_token);
          this.$store.commit("SET_USER_INFO", {
            ...data,grant_type
          });
         await this.$store.dispatch("GetMenu").then((data) => {
            if (data.length !== 0) {
              console.log(data)
              this.$router.$avueRouter.formatRoutes(data, true);
            }
          });
          if(location.pathname == "/cip/index"){
            location.reload();
          }else {
            this.$router.push({path: "/"},()=>{
              console.log('跳转成功')
              location.reload();
            });
          }
        }
      }).catch(err=>{debugger
        console.log(err)
      });
    },
    getNowUserDept() {
      nowUserDept().then((res) => {
        if (res.data.code === 200) {
          this.nowUserDeptList = res.data.data;
        }
      });
    },
    portrait() {
      this.drawer = true;
    },
    // 查询系统字典语言
    getSystemLang() {
      getDic("language").then((res) => {
        let response = res.data;
        if (response.success) {
          this.systemLangList = response.data;
        }
      });
    },
    // 选择系统语言
    // handleSetLanguage(lang) {
    //   this.$i18n.locale = lang;
    //   this.$store.commit("SET_LANGUAGE", lang);
    //   let tag = this.tag;
    //   let title = this.$router.$avueRouter.generateTitle(
    //     tag.label,
    //     (tag.meta || {}).i18n
    //   );
    //   //根据当前的标签也获取label的值动态设置浏览器标题
    //   this.$router.$avueRouter.setTitle(title);
    //   //混合导航左侧菜单国际化处理
    //   getRoutes().then((res) => {
    //     let menus = res.data.data;
    //     this.$store.commit(
    //       "SET_MENUONLY",
    //       menus[this.topMenuClickIndex].children
    //     );
    //   });
    // },
    changeCollapse() {
      this.$store.commit("SET_COLLAPSE", !this.isCollapse);
    },
    changeTwinkle(boo) {
      this.isTwinkle = boo;
    },
    // openUserList() {
    //   this.$refs.UserList.init();
    // },
    reconnectDeskWs() {
      //重新连接
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function () {
        //新连接
        that.deskWebSocket();
        that.lockReconnect = false;
      }, 5000);
    },
    deskWebSocket() {
      let that1 = this;
      that1.deskWS = new WebSocket(website.deskWebSocket + this.userInfo.user_id)
      that1.deskWS.onopen = () => {
        console.log('deskws 连接正常')
      }
      that1.deskWS.onmessage = (msg) => {
        let msgData = JSON.parse(msg.data)
        if(msgData.type==="notice"){
          if(msgData.status===0){
            this.title = msgData.title
            this.content = msgData.content
            this.isVisible = true
            return;
          }
          this.isVisible = false
        }else{
          that1.$notify({
            title: '公告',
            message: msgData.title,
            //十秒之后自动关闭
            duration: 60000,
            type: 'success',
            onClick() {
              that1.$router.push({
                path: `/notice/edit`,
                query: {
                  id: msgData.id,
                }
              })
            }
          });
        }


      }
    },
    messageSend() {
      this.$router.push({
        path: `/message/list`,
      });
    },
    getChannel() {
      selectMsgByAccount(1, 10, {isRead: 1}).then((res) => {
        this.isRead = res.data.data.total != 0;
      });
    },

    feedback() {
      this.feedbackForm = {
        screenshot: "",
        feedback: "",
      };
      html2canvas(document.querySelector("#app")).then((canvas) => {
        this.feedbackForm.screenshot = canvas.toDataURL("image/jpeg", 0.1);
        let feed = {data: this.feedbackForm.screenshot};
        putFile(feed).then((res) => {
          this.feedbackForm.screenshot = res.data.data.link;
        });
      });
      this.feedbackBox = true;
    },

    handleSubmit() {
      if (this.language == "zh") {
        this.feedbackForm.submitter = this.currentUser.realName.zh;
      } else if (this.language == "en") {
        this.feedbackForm.submitter = this.currentUser.realName.en;
      }
      add(this.feedbackForm).then(() => {
        this.$message(
          {
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          },
          (error) => {
            window.console.log(error);
            loading();
          }
        );
      });
      this.feedbackBox = false;
      feedbackOn.mounted;
    },
    initStats() {
      getQRcode().then((res) => {
        let data = res.data.data;
        if (data != "" && data != null) {
          this.downPath = data;
        }
      });
    },
    closeDrawer(e) {
      this.drawer = e;
    },
    getinfomation() {
      getLogo().then((res) => {
        res.data.data.forEach((item) => {
          if (item.paramType == "file" && item.paramValue != null) {
            this.file = item.paramValue;
          }
        });
      });
    },
    changeChatStatus() {
      console.log("changeChatStatus", window.$chat);
      if (!this.openChatState) {
        window.$chat.emit("openChatWin");
      } else {
        window.postMessage({type: "closeWindow"}, "*");
      }
    },
    changeShortcutStatus() {
      this.$store.commit("SET_SHORTCUTMENU");
    },
    openOnlineHelp() {
      let src = process.env.VUE_APP_BASE_ONLINEHELPURL + "/sinoma";
      this.$router.push({
        path:
          "/myiframe/urlPath?name=" +
          this.$t("tagsView.document") +
          "&src=" +
          src,
      });
    },
    getAllMenu() {
      this.isThreeShow = true;
      this.$store.dispatch("GetMenu").then((res) => {
        this.menuData = res;
        // this.leftDrawer = true;
        this.isAllMenu = true;
      });
    },
    getRouter() {
      getRoutes().then((res) => {
        this.meunData = res.data.data;
      });
    },
    getMenuList(item) {
      this.isAllMenu = false;
      this.isThreeShow = true;
      this.menuName = item.name;
      this.$store.dispatch("GetMenu", item.id).then((res) => {
        let menuData = res;
        let meunList = [];
        for (let i = 0; i < menuData.length; i++) {
          let params = menuData[i].children;
          for (let c = 0; c < params.length; c++) {
            if (params[c].children.length > 0) {
              this.isThreeShow = false;
            }
          }
          meunList.push(params);
        }
        this.menuData = meunList[0];
      });
    },
    getTagShow() {
    },
    getMenuGo(item) {
      this.$router.push({
        path: this.$router.$avueRouter.getPath({src: item.path}),
      });
      this.leftDrawer = false;
    },
    touchstart: function (e) {
      var that = this;
      var list = that.sudokus;
      for (var i = 0, len = list.length; i < len; ++i) {
        if (list[i].id == e) {
          that.curSelect = i;
        }
      }
    },
    touchend: function () {
      var that = this;
      that.curSelect = null;
    },
    clickUser() {
      this.drawer = false;
      this.$router.push({
        path: "/info/index",
      });
    },
    clickLanguage() {
      this.dialogVisible = !this.dialogVisible;
    },
    clickNotice() {
      this.noticeShow = true;
    },
    hideLang() {
      // this.drawer = false;
      this.dialogVisible = false;
    },
    clickTheme() {
      this.$refs.wTheme.open();
    },
    clickLogs() {
      // this.$refs.wLogs.handleOpen();
      this.$router.push({
        path: "/top/top-logs",
      });
    },
    logout() {
      let grant_type=this.userInfo.grant_type
      this.$confirm(this.$t("logoutTip"), this.$t("tip"), {
        confirmButtonText: this.$t("submitText"),
        cancelButtonText: this.$t("cancelText"),
        type: "warning",
      }).then(() => {
        //关闭智能助理窗口
        window.postMessage({type: "closeWindow"}, "*");
        this.$store.dispatch("LogOut").then(() => {
          if (grant_type && grant_type != 'password' ) {
            location.href = "https://home.sinoma.com.cn/portal/logout?service=https://hse.sinoma.com.cn/login?sinoma=logout";
          } else {
            resetRouter();
            this.$router.push({path: "/login"});
          }
        });
      });
    },
    setMean() {
      this.topMeanShow = !this.topMeanShow;
    },
    changeMeanStatus() {
      this.topMeanShow = !this.topMeanShow;
    },
    handleScreen() {
      fullscreenToggel();
      this.$EventBus.$emit('handleScreen');
    },
    // setCollapse() {
    //   this.index.openMenu();
    //   this.leftDrawer = true;
    // },
    setScreen() {
      this.$store.commit("SET_FULLSCREN");
    },


    startDrag(event) {
      this.dragging = true;
      this.offset = {
        x: event.clientX - this.position.x,
        y: event.clientY - this.position.y,
      };
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);
    },
    onDrag(event) {
      if (this.dragging) {
        this.position = {
          x: event.clientX - this.offset.x,
          y: event.clientY - this.offset.y,
        };
      }
    },
    stopDrag() {
      this.dragging = false;
      document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.stopDrag);
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },

  },
};
</script>

<style lang="scss" scoped>
.tree-node {
  display: flex;
  align-items: center;
}

::v-deep .phoneBox .el-dialog__header .el-dialog__close {
  line-height: 40px;
}

::v-deep .el-badge__content.is-fixed.is-dot {
  top: 23px;
}

.con {
  .left {
    padding: 6px;

    .conLeftImg {
      width: 88%;
      height: 550px;
    }
  }

  .right {
    padding: 0 12px;

    .name {
      font-size: 22px;
      color: #000;
      text-align: center;
      margin: 1.5rem 0;
    }

    .ewmIcon {
      display: flex;
      align-items: center;
      justify-content: space-around;
      //margin-top: 100px;

      .iconItem {
        justify-content: center;
        text-align: center;
        margin-bottom: 22px;

        img {
          height: 70px;
          width: 70px;
        }

        p {
          text-align: center;
          font-size: 30px;
        }
      }

      .left {
        display: flex;
        flex-direction: column;
        align-items: center;

        .ewm {
          width: 200px;
          height: 200px;
          margin: 50px 0;
        }
      }
    }
  }
}

.topSidderLogo {
  width: 210px;
  height: 60px;

  img {
    width: 100%;
    height: 100%;
  }
}

.logo {
  width: 50px;
  height: 50px;
}

.topSearchInput {
  position: fixed;
  right: 560px;
  top: 0;
}

.v-enter,
.v-leave-to {
  opacity: 0;
  transform: translateX(100px);
}

.v-enter-active,
.v-leave-active {
  transition: all 0.6s ease;
}

.menu_title {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: #f4f4f4;

  .menu_First_p {
    width: 100%;
    font-weight: 600;
    color: #000;
    font-size: 18px;
    float: left;
    line-height: 28px;
    cursor: pointer;
    margin: 10px;
    border-bottom: 1px dashed #babfc7;
  }

  .menu_First_title {
    width: 50%;
    line-height: 48px;
    float: left;

    span {
      display: inline-block;
      width: 100%;
      float: left;
      font-size: 14px;
      line-height: 48px;
      cursor: pointer;
      text-indent: 1em;
    }
  }

  .menu_fir_title {
    width: 100%;
    line-height: 28px;
    padding: 0px 10px;
    box-sizing: border-box;
    float: left;
    border-top: 1px dashed #babfc7;

    p {
      width: 100%;
      font-weight: 600;
      color: #000;
      font-size: 18px;
      float: left;
      line-height: 28px;
      cursor: pointer;
      margin: 10px;
    }

    .menu_sed_title {
      width: 50%;
      line-height: 32px;
      float: left;

      span {
        display: inline-block;
        width: 100%;
        float: left;
        font-size: 14px;
        line-height: 32px;
        cursor: pointer;
        text-indent: 1em;
      }
    }
  }
}

.menu_thrid_title {
  width: 50%;
  line-height: 32px;
  float: left;
  /* background: red; */
  display: inline-block;

  span {
    display: inline-block;
    width: 100%;
    float: left;
    font-size: 14px;
    line-height: 32px;
    cursor: pointer;
    text-indent: 1em;
  }
}

.card_posit {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  position: relative;

  .person_top {
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 76px;
    display: flex;
    flex-wrap: wrap;

    .person_img {
      width: 52px;
      height: 52px;
      text-align: left;

      .image {
        width: 52px;
        height: 52px;
        border-radius: 50%;
      }
    }

    .person_title {
      padding-left: 10px;
      box-sizing: border-box;
      width: calc(100% - 76px);
      line-height: 28px;
      font-size: 14px;
      font-weight: bold;
      float: left;
      color: #333333;

      span {
        width: 100%;
        display: inline-block;
        float: left;
      }

      .person_uid {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .deptBox {
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 10px;
  }

  .logout {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: calc(100% - 20px);
  }
}

.sudoku_row {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .sudoku_item {
    cursor: pointer;
    outline: 1px solid #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25%;
    padding-top: 10px;
    font-size: 12px;
    padding-bottom: 10px;
    box-sizing: border-box;
    color: #333333;

    img {
      margin-bottom: 3px;
      display: block;
    }
  }

  .opacity {
    opacity: 0.4;
    background: #e5e5e5;
  }
}

.tui-tab-item-title-active {
  color: #5677fc;
  font-size: 28rpx;
  font-weight: bold;
  border-bottom: 6rpx solid #5677fc;
}
</style>


<style lang="scss">
.ht-drawer {
  margin-top: 60px;
  height: 95vh !important;
  overflow-y: scroll;
  padding-bottom: 20px;
  box-sizing: border-box;
  background: #f4f4f4;

  /* height: calc(100vh -184px) !important; */
  .el-drawer__body {
    overflow: auto;
    height: 95vh !important;
    /* height: calc(100vh -184px) !important; */
  }
}

/* 定义keyframe动画，命名为blink */
@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  50.01% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.twinkle {
  animation: blink 0.5s linear infinite;
}



.floating-box {
  position: absolute;
  width: 200px; /* 悬浮块宽度 */
  height: 150px; /* 悬浮块高度 */
  background-color: rgba(255, 255, 255, 0.7); /* 半透明背景色 */
  border: 1px solid #E4E7ED;
  cursor: move;
  //padding: 3px;
  user-select: none; /* 防止选中文本影响拖动 */
  transition: width 0.3s, height 0.3s; /* 平滑过渡效果 */
  z-index: 99999;
  border-radius: 4px;

}

.floating-box.expanded {
  width: 400px; /* 展开后的宽度 */
  height: 200px; /* 展开后的高度 */
}

.toggle-button {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  z-index: 99999;
}

.toggle-button span {
  font-size: 14px;
}

.box-content {
  //display: flex;
  //flex-direction: column;
  height: 100%;
}

.noticeTitle {
  width: calc(100% - 12px);
  font-weight: bold;
  font-size: 16px;
  color: #FFF;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #EBEEF5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 6px;
}

.noticeContent {
  width: calc(100% - 12px);
  height: calc(100% - 53px);
  color: #444444;
  font-size: 14px;
  overflow-wrap: break-word;
  white-space: break-spaces;
  overflow: auto;
  background-color: #f4f6f8;
  padding: 6px;
}
</style>
