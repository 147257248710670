import request from '@/router/axios';
import ServerNameEnum from "@/util/ServerNameEnum";

// 列表数据
export function listData(current, size, params) {
  return request({
    url: ServerNameEnum.SERVER_DESK_+ `/visualDev/page`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

// 发布选择菜单数据
export function menuTree() {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+ `/menu/tree`,
    method: 'get',
  })
}

// 发布
export function menuPublish(data) {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+ `/menu/submit`,
    method: 'post',
    data
  })
}

// 删除
export function deleteData(params) {
  return request({
    url: ServerNameEnum.SERVER_DESK_+ `/visualDev/delete`,
    method: 'get',
    params: {
      ...params,
    }
  })
}
