var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "UploadFile-container" },
    [
      _vm.fileList.length
        ? [
            _c(
              "transition-group",
              {
                staticClass: "el-upload-list el-upload-list--picture-card",
                attrs: { tag: "ul", name: "el-list" },
              },
              _vm._l(_vm.fileList, function (file, index) {
                return _c(
                  "li",
                  {
                    key: file.fileId,
                    staticClass: "el-upload-list__item is-success",
                  },
                  [
                    _c("el-image", {
                      ref: "image" + index,
                      refInFor: true,
                      staticClass: "el-upload-list__item-thumbnail",
                      attrs: {
                        src: file.url,
                        "preview-src-list": _vm.getImgList(_vm.fileList),
                        "z-index": 10000,
                      },
                    }),
                    _c(
                      "span",
                      { staticClass: "el-upload-list__item-actions" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "el-upload-list__item-preview",
                            on: {
                              click: function ($event) {
                                return _vm.handlePictureCardPreview(index)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-zoom-in" })]
                        ),
                        !_vm.disabled
                          ? _c(
                              "span",
                              {
                                staticClass: "el-upload-list__item-delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRemove(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-delete" })]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        : _vm._e(),
      !_vm.detailed
        ? [
            _c(
              "el-upload",
              {
                ref: "elUpload",
                staticClass: "upload-btn",
                attrs: {
                  action:
                    "/api/sinoma-resource/oss/endpoint/put-file-attach-name" +
                    "/" +
                    _vm.type,
                  headers: _vm.uploadHeaders,
                  "on-success": _vm.handleSuccess,
                  multiple: _vm.limit !== 1,
                  "show-file-list": false,
                  accept: "image/*",
                  "before-upload": _vm.beforeUpload,
                  disabled: _vm.disabled,
                  "list-type": "picture-card",
                },
              },
              [
                _c("i", { staticClass: "el-icon-plus" }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showTip,
                        expression: "showTip",
                      },
                    ],
                    staticClass: "el-upload__tip",
                    attrs: { slot: "tip" },
                    slot: "tip",
                  },
                  [
                    _vm._v(
                      "\n        只能上传不超过" +
                        _vm._s(_vm.fileSize) +
                        _vm._s(_vm.sizeUnit) +
                        "的" +
                        _vm._s(_vm.accept) +
                        "图片\n      "
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }