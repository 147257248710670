var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          ref: "drawer",
          staticClass: "contacts-drawer SNOWS-common-drawer",
          attrs: {
            title: _vm.$t("cip.desk.im.title.indexHeadTitle"),
            visible: _vm.drawer,
            direction: "rtl",
            size: "350px",
            modal: true,
            wrapperClosable: false,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("cip.desk.im.field.msg"),
                    name: "reply",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.replyLoading && _vm.page.currentPage == 1,
                          expression: "replyLoading && page.currentPage==1",
                        },
                      ],
                      staticClass: "userList replyList",
                    },
                    [
                      _vm.replyList.length
                        ? _c(
                            "div",
                            _vm._l(_vm.replyList, function (item, i) {
                              return _c(
                                "div",
                                {
                                  key: i,
                                  staticClass: "userList-item",
                                  on: {
                                    contextmenu: function ($event) {
                                      $event.preventDefault()
                                      return _vm.openMenu(item, $event)
                                    },
                                    click: function ($event) {
                                      return _vm.readInfo(item, true)
                                    },
                                  },
                                },
                                [
                                  _c("el-avatar", {
                                    attrs: { size: 36, src: item.headIcon },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "userList-txt",
                                      staticStyle: { height: "44px" },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "title",
                                          staticStyle: {
                                            height: "18px",
                                            margin: "0",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(item.realName) +
                                                "/" +
                                                _vm._s(item.account)
                                            ),
                                          ]),
                                          _c("el-badge", {
                                            attrs: {
                                              value: item.unreadMessage,
                                              hidden: !item.unreadMessage,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "name",
                                          staticStyle: {
                                            height: "18px",
                                            margin: "0",
                                            display: "flex",
                                            "line-height": "18px",
                                            "margin-top": "5px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "content" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getMsgText(
                                                    item.latestMessage,
                                                    item.messageType
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("span", { staticClass: "time" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("toDateText")(
                                                  item.latestDate
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _c("p", { staticClass: "noData-txt" }, [
                            _vm._v(
                              _vm._s(_vm.$t("cip.desk.im.field.noChatRecord"))
                            ),
                          ]),
                      _c(
                        "ul",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.visibleMenu,
                              expression: "visibleMenu",
                            },
                          ],
                          staticClass: "contextmenu",
                          style: { top: _vm.top + "px" },
                        },
                        [
                          _c(
                            "li",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.relocation()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("cip.desk.im.field.remove")))]
                          ),
                          _c(
                            "li",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.deleteChatRecord()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("cip.desk.im.field.removeCharRecord")
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("cip.desk.im.field.contacts"),
                    name: "contacts",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "search-input",
                      attrs: {
                        placeholder: _vm.$t("cip.desk.im.field.search"),
                        clearable: "",
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.search()
                        },
                      },
                      model: {
                        value: _vm.username,
                        callback: function ($$v) {
                          _vm.username = $$v
                        },
                        expression: "username",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-input__icon el-icon-search",
                        attrs: {
                          slot: "suffix",
                          title: _vm.$t("tagsView.search"),
                        },
                        on: { click: _vm.search },
                        slot: "suffix",
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading && _vm.page.currentPage == 1,
                          expression: "loading && page.currentPage==1",
                        },
                      ],
                      ref: "userList",
                      staticClass: "userList",
                    },
                    [
                      _vm.userList.length
                        ? _c(
                            "div",
                            _vm._l(_vm.userList, function (item, i) {
                              return _c(
                                "div",
                                {
                                  key: i,
                                  staticClass: "userList-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.readInfo(item)
                                    },
                                  },
                                },
                                [
                                  _c("el-avatar", {
                                    attrs: { size: 36, src: item.avatar },
                                  }),
                                  _c("div", { staticClass: "userList-txt" }, [
                                    _c("p", { staticClass: "title" }, [
                                      _vm._v(_vm._s(item.realName)),
                                    ]),
                                    _c("p", { staticClass: "name" }, [
                                      _c("span", [
                                        _vm._v(_vm._s(item.department)),
                                      ]),
                                    ]),
                                  ]),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _c("p", { staticClass: "noData-txt" }, [
                            _vm._v(_vm._s(_vm.$t("cip.desk.im.field.noData"))),
                          ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Im", { ref: "SNOWSIm", attrs: { "append-to-body": "" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }