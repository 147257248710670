var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comSelect-container" },
    [
      _c(
        "el-select",
        {
          attrs: {
            filterable: "",
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
            clearable: "",
          },
          on: { change: _vm.dictionaryTypeChange },
          model: {
            value: _vm.innerValue,
            callback: function ($$v) {
              _vm.innerValue = $$v
            },
            expression: "innerValue",
          },
        },
        _vm._l(_vm.treeData, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.dictValue, value: item.id },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }