<template>
  <div class="menu-wrapper">
    <template v-for="item in menu">
      <el-submenu
        v-if="!validatenull(item[childrenKey]) && vaildRoles(item)"
        :key="item[labelKey]"
        :index="item[pathKey]"
        :title="generateTitle(item)"
        class="nest-menu"
        :popper-class="`${themeClass}`"
      >
        <template slot="title" v-if="!collapse">
          <div :class="language === 'en' ? 'headMenuItem' : ''">
            <i :class="item[iconKey]"></i>
            <span
              slot="title"
              :class="{ 'el-menu--display': collapse && first }"
            >{{ generateTitle(item) }}</span
            >
          </div>
        </template>
        <template slot="title" v-else>
          <i :class="item[iconKey]"></i>
          <span
            slot="title"
            :class="{ 'el-menu--display': collapse && first }"
          >{{ generateTitle(item) }}</span
          >
        </template>
        <template v-for="(child, cindex) in item[childrenKey]">
          <el-menu-item
            v-if="validatenull(child[childrenKey])"
            :key="child[labelKey]"
            :class="{ 'is-active': vaildActive(child) }"
            :index="child[pathKey]"
            @click="open(child)"
          >
            <template slot="title">
              <div
                :class="language === 'en' ? 'headMenuItem' : ''"
                :title="generateTitle(child)"
              >
                <i :class="child[iconKey]"></i>
                <span>{{ generateTitle(child) }}</span>
              </div>
            </template>
          </el-menu-item>
          <sidebar-item
            v-else
            :key="cindex"
            :collapse="collapse"
            :menu="[child]"
            :props="props"
            :screen="screen"
          ></sidebar-item>
        </template>
      </el-submenu>
      <el-menu-item
        v-else-if="validatenull(item[childrenKey]) && vaildRoles(item)"
        :key="item[labelKey]"
        :class="{ 'is-active': vaildActive(item) }"
        :index="item[pathKey]"
        class="elMenuItem"
        @click="open(item)"
      >
        <template>
          <div class="menuItemBox" :title="generateTitle(item)">
            <i :class="item[iconKey]" style="margin: 0"></i>
            <span
              :alt="item[pathKey]"
              :class="{ 'el-menu--display': collapse && first }"
            >{{ generateTitle(item) }}</span
            >
          </div>
        </template>
      </el-menu-item>
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { isURL, validatenull } from "@/util/validate";
import config from "./config.js";
import { functionSave } from "@/api/system/statistic";
import { isDoc } from "../../../util/validate";
import website from "@/config/website";

export default {
  name: "sidebarItem",
  components: {},

  data() {
    return {
      config: config,
      // 功能记录
      function: {},
    };
  },
  props: {
    menu: {
      type: Array,
    },
    screen: {
      type: Number,
    },
    first: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
      default: () => {
        return {};
      },
    },
    collapse: {
      type: Boolean,
    },
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([
      "roles",
      "themeClass",
      "language",
      "userInfo",
      "openmenu",
      "website",
    ]),
    labelKey() {
      return this.props.label || this.config.propsDefault.label;
    },
    pathKey() {
      return this.props.path || this.config.propsDefault.path;
    },
    iconKey() {
      return this.props.icon || this.config.propsDefault.icon;
    },
    childrenKey() {
      return this.props.children || this.config.propsDefault.children;
    },
    isOpenKey() {
      return this.props.isOpen || this.config.propsDefault.isOpen;
    },
    nowTagValue() {
      return this.$router.$avueRouter.getValue(this.$route);
    },
  },
  watch: {
    openmenu: {
      handler(newValue) {
        // console.log('sidebar openmenu:',newValue)
        if (newValue === "" || JSON.stringify(newValue) === "{}") return;
        this.open(newValue);
        // console.log('sidebar shouyeMenuLists:',this.shouyeMenuLists)
        // let lastLevels = this.getLastLevels(this.shouyeMenuLists)
        // console.log('sidebar lastLevels:',lastLevels)

        //lastTwoLevels是一个列表结构，需要递归后，通过id字段进行匹配，找到对应的菜单，然后执行打开菜单操作
        // lastLevels.forEach((item) => {
        //   if(item.id== newValue.id) {
        //     console.log('sidebar open:',item)
        //     this.open(item)
        //     this.$store.commit('SET_OPENMENU', {})
        //     return
        //   }
        // });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    generateTitle(item) {
      return this.$router.$avueRouter.generateTitle(
        item[this.labelKey],
        (item.meta || {}).i18n
      );
    },
    vaildActive(item) {
      if (this.validIsOpen(item)) {
        return false;
      }
      const groupFlag = (item["group"] || []).some((ele) =>
        this.$route.path.includes(ele)
      );
      return this.nowTagValue === item[this.pathKey] || groupFlag;
    },
    vaildRoles(item) {
      item.meta = item.meta || {};
      return item.meta.roles ? item.meta.roles.includes(this.roles) : true;
    },
    validatenull(val) {
      return validatenull(val);
    },
    open(item) {
      let token = this.$store.getters.token;
      if (item.path == "/CipReport?user=test") {
        window.open(window.location.origin + item.path + "?isNewWindow=true");
        return;
      }
      if (this.screen <= 1) this.$store.commit("SET_COLLAPSE");
      if (item.isOpen == 1) {
        if (isURL(item.path)) {
          if (item.isSingleSignOn == 0) {
            if (item[this.pathKey].indexOf("doc.html") > -1) {
              window.open(item[this.pathKey]);
            }
            window.open(
              item[this.pathKey] + "&access_token=" + token + "&language=zh"
            );
          } else {
            window.open(item[this.pathKey]);
          }
        } else {
          window.open(
            window.location.origin + "#" + item.path + "?isNewWindow=true"
          );
        }
      } else {
        this.$router.$avueRouter.group = item.group;
        this.$router.$avueRouter.meta = item.meta;
        this.$router.push({
          path: this.$router.$avueRouter.getPath(
            {
              name: item[this.labelKey],
              src: item[this.pathKey],
            }, item.meta),
          query: item.params ?JSON.parse(item.params) :'',
        });
      }
      if (item.name != this.function.functionName) {
        this.function = {
          functionName: item.name,
          functionCode: item.code,
          operationUser: this.userInfo.real_name,
        };
        functionSave(this.function);
      }
    },
    validIsOpen(item) {
      if (item[this.isOpenKey] === 2 && isURL(item[this.pathKey])) {
        return true;
      }
    },
  },
};
</script>
<style scoped>
.headMenuItem {
  max-width: 136px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #ffffff;
}
</style>

