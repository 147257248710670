import SNOWSTreeSelect from '@/components/SNOWS-treeSelect'
import topOperation from '@/components/SNOWS-topOperation/index'
import tableOperation from '@/components/SNOWS-tableOperation'
import Pagination from '@/components/Pagination'
import SNOWSTable from '@/components/SNOWS-table'
import SNOWSFormTipItem from '@/components/SNOWS-form-tip-item'
import SNOWSOpenData from '@/components/SNOWS-open-data'
import UserBox from '@/components/SNOWS-userBox'
import Screenfull from '@/components/Screenfull'
import SNOWSQuill from '@/components/SNOWSEditor/quill'
import UserSelect from '@/components/SNOWS-userSelect'
import UsersSelect from '@/components/SNOWS-usersSelect'
import uploadBtn from '@/components/SNOWS-uploadBtn'
import ImportBox from '@/components/SNOWSImport'
// 代码生成器专供
import SNOWSText from '@/components/Generator/components/SNOWSText'
import SNOWSLink from '@/components/Generator/components/SNOWSLink'
import jnpfBarcode from '@/components/Generator/components/SNOWSBarcode'
import jnpfQrcode from '@/components/Generator/components/SNOWSQrcode'
import SNOWSButton from '@/components/Generator/components/SNOWSButton'
import SNOWSUploadFz from '@/components/Generator/components/Upload/UploadFz'
import SNOWSUploadImg from '@/components/Generator/components/Upload/UploadImg'
import PopupSelect from '@/components/Generator/components/PopupSelect'
import PopupTableSelect from '@/components/Generator/components/PopupTableSelect'
import PopupAttr from '@/components/Generator/components/PopupAttr'
import NumRange from '@/components/Generator/components/NumRange'
import ComSelect from '@/components/Generator/components/ComSelect'
import GroupSelect from '@/components/Generator/components/GroupSelect'
import DepSelect from '@/components/Generator/components/DepSelect'
import PosSelect from '@/components/Generator/components/PosSelect'
import DicSelect from '@/components/Generator/components/DicSelect'
import BillRule from '@/components/Generator/components/BillRule'
import JNPFInputTable from '@/components/Generator/components/InputTable'
import RoleSelect from '@/components/Generator/components/RoleSelect'
import SNOWSAddress from '@/components/Generator/components/Address'
import GroupTitle from '@/components/Generator/components/GroupTitle'
import RelationForm from '@/components/Generator/components/RelationForm'
import RelationFormAttr from '@/components/Generator/components/RelationFormAttr'
import Calculate from '@/components/Generator/components/Calculate'
import inputTree from '@/components/hse-avue-components/input-tree/'

export default {
  install(Vue, options) {
    Vue.component('avue-input-tree', inputTree)
    Vue.component('SNOWSTreeSelect', SNOWSTreeSelect)
    Vue.component('topOpts', topOperation)
    Vue.component('tableOpts', tableOperation)
    Vue.component('Pagination', Pagination)
    Vue.component('SNOWSTable', SNOWSTable)
    Vue.component('SNOWSFormTipItem', SNOWSFormTipItem)
    Vue.component('SNOWSOpenData', SNOWSOpenData)
    Vue.component('uploadBtn', uploadBtn)
    Vue.component('UserBox', UserBox)
    Vue.component('SNOWSText', SNOWSText)
    Vue.component('SNOWSLink', SNOWSLink)
    Vue.component('jnpfBarcode', jnpfBarcode)
    Vue.component('jnpfQrcode', jnpfQrcode)
    Vue.component('SNOWSButton', SNOWSButton)
    Vue.component('SNOWSUploadFz', SNOWSUploadFz)
    Vue.component('SNOWSUploadImg', SNOWSUploadImg)
    Vue.component('PopupSelect', PopupSelect)
    Vue.component('PopupTableSelect', PopupTableSelect)
    Vue.component('PopupAttr', PopupAttr)
    Vue.component('NumRange', NumRange)
    Vue.component('ComSelect', ComSelect)
    Vue.component('GroupSelect', GroupSelect)
    Vue.component('DepSelect', DepSelect)
    Vue.component('PosSelect', PosSelect)
    Vue.component('UserSelect', UserSelect)
    Vue.component('UsersSelect', UsersSelect)
    Vue.component('RoleSelect', RoleSelect)
    Vue.component('DicSelect', DicSelect)
    Vue.component('BillRule', BillRule)
    Vue.component('JNPFInputTable', JNPFInputTable)
    Vue.component('SNOWSAddress', SNOWSAddress)
    Vue.component('GroupTitle', GroupTitle)
    Vue.component('RelationForm', RelationForm)
    Vue.component('RelationFormAttr', RelationFormAttr)
    Vue.component('Calculate', Calculate)
    Vue.component('SNOWSQuill', SNOWSQuill)
    Vue.component('Screenfull', Screenfull)
    Vue.component('ImportBox', ImportBox)
  }
}
