/*
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-10-11 10:24:04
 */
import request from '@/router/axios';

export const getTree = (params) => {
  return request({
    url: '/api/sinoma-maas-center/node/tree',
    method: 'get',
    params: {
      ...params,
    }
  })
}
export const getById = (row) => {
  return request({
    url: '/api/sinoma-maas-center/camera/getById',
    method: 'post',
    data: row
  })
}

export const add = (row) => {
  return request({
    url: '/api/sinoma-maas-center/camera/add',
    method: 'post',
    data: row
  })
}

export const correct = (row) => {
  return request({
    url: '/api/sinoma-maas-center/camera/correct',
    method: 'post',
    data: row
  })
}
export const remove = (row) => {
  return request({
    url: '/api/sinoma-maas-center/camera/del',
    method: 'post',
    data: row
  })
}
export const edit = (row) => {
  return request({
    url: '/api/sinoma-maas-center/camera/edit',
    method: 'post',
    data: row
  })
}
