import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";
export const getList = (offset, limit, params) => {
  return request({
    url: ServerNameEnum.SERVER_SINOMA_INTERFACE_ + "/intf/list",
    method: "get",
    params: {
      ...params,
      offset,
      limit,
    },
  });
};

export const selectById = (id) => {
  return request({
    url: ServerNameEnum.SERVER_SINOMA_INTERFACE_ + "/intf/get",
    method: 'get',
    params: {
      id
    }
  })
}

export const saveOrUpdate= (data) => {
  return request({
    url: ServerNameEnum.SERVER_SINOMA_INTERFACE_ + "/intf/saveOrUpdate",
    method: 'post',
    data:data
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_SINOMA_INTERFACE_ + "/intf/delete",
    method: 'post',
    params: {
      ids
    }
  })
}
export const rowRemove = (id) => {
  return request({
    url: ServerNameEnum.SERVER_SINOMA_INTERFACE_ + "/intf/deleteRow",
    method: 'post',
    params: {
      id
    }
  })
}
