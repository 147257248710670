import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 获取数据列
export const getDataList = (current, size, params) => {
    return request({
        url: ServerNameEnum.SERVER_SYSTEM_ + '/mockIndicatorValue1/listPage',
        method: 'get',
        params: {
            ...params,
            current,
            size,
        }
    })
}
// 获取数据详情
export const getDataDetail = (id) => {
    return request({
        url: ServerNameEnum.SERVER_SYSTEM_ + '/mockIndicatorValue1/detail',
        method: 'get',
        params: {
            id
        }
    })
}
// 删除数据
export const remove = (ids) => {
    return request({
        url: ServerNameEnum.SERVER_SYSTEM_ + '/mockIndicatorValue1/remove',
        method: 'post',
        params: {
            ids,
        }
    })
}

// 新增编辑数据
export const addData = (row) => {
    return request({
        url: ServerNameEnum.SERVER_SYSTEM_ + '/mockIndicatorValue1/submit',
        method: 'post',
        data: row
    })
}
