import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_NAME = "/index/themeDimension";

/**
 * 查询字段
 * @param data
 * @returns {*}
 */
export const selectField = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/selectField`,
    method: 'post',
    data: data,
  })
}

/**
 * 删除
 * @param ids  删除的id集合
 * @returns AxiosPromise
 */
export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/lineRemove`,
    method: 'get',
    params: {
      ids
    }
  })
}

/**
 * 保存
 * @param data  需要保存的数据
 * @returns AxiosPromise
 */
export const saveOrUpdate = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/saveTheme`,
    method: 'post',
    data
  })
}

/**
 * 查询维度列表
 * @param params
 * @returns {*}
 */
export const listByThemeId = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/listByThemeId`,
    method: 'get',
    params: {
      ...params,
    }
  })
}


