<template>
  <div>
    <!-- <el-row v-if="type == ''"> -->
      <!-- <el-col> -->
        <head-layout
          :head-btn-options="headBtnOptions"
          :head-title="$t('cip.plat.sys.feedback.title.indexHeadTitle')"
        ></head-layout>
      <!-- </el-col> -->
    <!-- </el-row> -->
    <grid-head-layout
      ref="gridHeadLayout"
      :grid-head-btn="gridHeadBtn"
      :search-columns="searchColumns"
      :deviceProtocol="deviceProtocol"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @row-view="rowView"
    >
    </grid-layout>
  </div>
</template>

<script>

let baseUrl = '/api/sinoma-system/dict/dictionary?code='
import {mapGetters} from "vuex";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {
  remove,
  getList
} from '@/api/system/feedback'

export default {
  name: "protocol",
  components: {
    GridLayout,
    HeadLayout,
  },
  props: {

  },
  data() {
    return {
      tableLoading: true,
      tableData: [],
      deviceProtocol:this.verdict,
      page: {
        pageSize: PAGE_CONSTANT.TWENTY,
        currentPage: 1,
        total: 0
      }
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    searchColumns() {
      return [
        {
          prop: "feedback",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.feedback.field.feedback"),
        },
        {
          label: "",
          prop: "disposeResult",
          type: 'select',
          dataType: 'string',
          span: 4,
          dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          placeholder: this.$t(`cip.cmn.rule.selectWarning`) + this.$t(`cip.plat.sys.feedback.field.disposeResult`)
        },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        linklabel: "protocolCode",
        customAddBtn: true,
        selection: this.type == '',

        column: [
          {
            label: this.$t('cip.plat.sys.feedback.field.feedback'),
            prop: 'feedback',
            overHidden: true,
            align: "left",
          },
          // {
          //   label: $t("sinoma.system.tenant.backgroundUrl"),
          //   prop: "screenshot",
          //   type: 'upload',
          //   listType: 'picture-img',
          //   dataType: 'string',
          //   propsHttp: {
          //     res: 'data',
          //     url: 'screenshot',
          //   },
          //   hide: true,
          //   span: 24,
          // },
          {
            label: this.$t('cip.plat.sys.feedback.field.screenshot'),
            prop: 'screenshot',
            type: 'upload',
            overHidden: true,
            width: 240,
            align: "left",
          },
          {
            label: this.$t('cip.plat.sys.feedback.field.submitter'),
            prop: 'submitter',
            overHidden: true,
            width: 240,
            align: "center",
          },
          {
            label: this.$t('cip.plat.sys.feedback.field.disposeResult'),
            prop: 'disposeResult',
            type: 'switch',
            align: "center",
            dicUrl: `/api/sinoma-system/dict/dictionary?code=yes_no`,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            overHidden: true,
            width: 100,
          },
          {
            label: this.$t('cip.plat.sys.feedback.field.handler'),
            prop: 'handler',
            overHidden: true,
            width: 200,
            align: "center",
          },
          {
            label: this.$t('cip.plat.sys.feedback.field.createTime'),
            prop: 'createTime',
            type: 'datetime',
            align: "center",
            format: 'yyyy-MM-dd HH:mm:ss',
            width: 185,
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.sys.feedback.field.disposeTime'),
            prop: 'disposeTime',
            type: 'datetime',
            align: "center",
            format: 'yyyy-MM-dd HH:mm:ss',
            overHidden: true,
            width: 185,
          }
        ],
      }
    },
    gridRowBtn() {
      let   buttonBtn = [];
      if (this.permission.feedback_edit) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.editBtn'),
            emit: "row-view",
            type: "button",
            icon: ""
          });
      }
      return  buttonBtn;
    },
    gridHeadBtn() {
      return []
    },
    headBtnOptions() {
      return [
    //     {
    //       label: this.$t('cip.cmn.btn.addBtn'),
    //       emit: "head-add-tabs",
    //       type: "button",
    //       icon: "",
    //       btnOptType: 'add',
    //     },
    //     {
    //       label: this.$t('cip.cmn.btn.delBtn'),
    //       emit: "head-remove",
    //       type: "button",
    //       icon: "",
    //       btnOptType: 'remove',
    //     }
      ]
    },
  },
  mounted() {
    this.onLoad(this.page, {protocolType: this.protocolType});
  },
  methods: {
    rowSelect(row) {
      this.$emit('select-data', row);
    },
    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query);
    },
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query);
    },
    rowView(row) {
      this.$router.push({
        path: `/system/FeedbackView`,
        query: {
          id: row.id,
          type: 'edit',
          pageType: 'edit',
        }
      })
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList(
        this.page.currentPage,
        this.page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    }
  }
};
</script>
<style>
</style>
