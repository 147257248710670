export const options = (safe)=> {
  return {
    index: true,
    column: [{
        label: safe.$t("cip.plat.sys.tenant.field.tenantId"),
        prop: "tenantId",
        width: 100,
        search: true,
        addDisplay: false,
        editDisplay: false,
        span: 24,
        rules: [{
          required: true,
          message: safe.$t("cip.cmn.rule.inputWarning")+safe.$t("cip.plat.sys.tenant.field.tenantId"),
          trigger: "blur"
        }]
      },
      {
        label: safe.$t("cip.plat.sys.tenant.field.tenantName"),
        prop: "tenantName",
        search: true,
        width: 180,
        span: 24,
        isGlobal:true, //是否支持国际化
        rules: [{
          required: true,
          message:  safe.$t("cip.cmn.rule.inputWarning")+safe.$t("cip.plat.sys.tenant.field.tenantName"),
          trigger: "submit"
        }]
      },
      {
        label:safe.$t("cip.plat.sys.tenant.field.linkman"),
        prop: "linkman",
        width: 100,
        search: true,
        isGlobal:true, //是否支持国际化
        rules: [{
          required: true,
          message: safe.$t("cip.cmn.rule.inputWarning")+safe.$t("cip.plat.sys.tenant.field.linkman"),
          trigger: "submit"
        }]
      },
      {
        label: safe.$t("cip.plat.sys.tenant.field.contactNumber"),
        prop: "contactNumber",
        width: 150,
      },
      {
        label: safe.$t("cip.plat.sys.tenant.field.address"),
        prop: "address",
        span: 24,
        minRows: 2,
        type: "textarea",
        hide: true,
      },
      {
        label: safe.$t("cip.plat.sys.tenant.field.accountNumber"),
        prop: "accountNumber",
        align:'right',
        width: 90,
        slot: true,
        addDisplay: false,
        editDisplay: false,
      },
      {
        label: safe.$t("cip.plat.sys.tenant.field.expireTime"),
        prop: "expireTime",
        align:'center',
        width: 180,
        slot: true,
        addDisplay: false,
        editDisplay: false,
      },
      {
        label: safe.$t("cip.plat.sys.tenant.field.domainUrl"),
        prop: "domainUrl",
        span: 24,
      },
      {
        label: safe.$t("cip.plat.sys.tenant.field.backgroundUrl"),
        prop: "backgroundUrl",
        type: 'upload',
        listType: 'picture-img',
        dataType: 'string',
        action: '/api/sinoma-resource/oss/endpoint/put-file',
        propsHttp: {
          res: 'data',
          url: 'link',
        },
        hide: true,
        span: 24,
      }
    ]
  }
};
export const searchColumns = (safe)=> {
  return [
    {
      prop: "tenantId",
      search: true,
      placeholder: safe.$t("cip.cmn.rule.inputWarning")+safe.$t("cip.plat.sys.tenant.field.tenantId"),
    },
    {
      placeholder: safe.$t("cip.cmn.rule.inputWarning")+safe.$t("cip.plat.sys.tenant.field.tenantName"),
      prop: "tenantName",
      search: true,
    },
    {
      placeholder:  safe.$t("cip.cmn.rule.inputWarning")+safe.$t("cip.plat.sys.tenant.field.linkman"),
      prop: "linkman",
      search: true,
    }
  ]
};
