import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const downloadFile = (fileUrl, fileName) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_ + '/download/downloadFile',
    responseType: 'blob',
    method: 'post',
    params: {
      fileUrl,
      fileName
    }
  })
}
