import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';


export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + '/rptEnterpriseSafeEnvStat/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
/**
 * 新增或修改
 * @param
 * @returns AxiosPromise
 */
export const saveOrUpdate = (data,type) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptEnterpriseSafeEnvStat/submit`,
    method: 'post',
    data,
    params: {
      type
    }
  })
}
/**
 * 通过审核后汇总页面修改数据
 * @param
 * @returns AxiosPromise
 */
export const update = (data) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptEnterpriseSafeEnvStat/update`,
    method: 'post',
    data,
  })
}
/**
 * 数据批量提交保存
 * @param
 * @returns AxiosPromise
 */
export const submissionsSave = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rptEnterpriseSafeEnvStat/submissionsSave`,
    method: 'post',
    data: data
  })
}
/**
 * 分页查询
 * @param current 页码
 * @param size  每页数量
 * @param params  查询条件
 * @returns AxiosPromise
 */
export const selectPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptEnterpriseSafeEnvStat/page`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
/**
 * 分页查询
 * @param current 页码
 * @param size  每页数量
 * @param params  查询条件
 * @returns AxiosPromise
 */
export const auditPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptEnterpriseSafeEnvStat/auditPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
/**
 * 分页查询
 * @param current 页码
 * @param size  每页数量
 * @param params  查询条件
 * @returns AxiosPromise
 */
export const summaryGetPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptEnterpriseSafeEnvStat/summaryGetPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const saveSubmitInterface = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rptEnterpriseSafeEnvStat/saveSubmitInterface`,
    method: 'post',
    data: row
  })
}
export const detail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptEnterpriseSafeEnvStat/detail`,
    method: 'get',
    params: {
      'id': id
    }
  })
}
export const queryTheMonthlyProductionReportDetail = (data) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptEnterpriseSafeEnvStat/detail`,
    method: 'get',
    params: data
  })
}
export const processDetails = (id) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptEnterpriseSafeEnvStat/processDetails`,
    method: 'get',
    params: {
      'id': id
    }
  })
}
export const collect = (data) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptEnterpriseSafeEnvStat/collect`,
    method: 'get',
    params: data
  })
}

export const summaryClickDataState = (data) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptEnterpriseSafeEnvStat/summaryClickDataState`,
    method: 'get',
    params: data
  })
}

export const save = (data) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptEnterpriseSafeEnvStat/save`,
    method: 'post',
    data
  })
}

export const rejectSendingMessage = (row,value,templateCode) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptEnterpriseSafeEnvStat/rejectSendingMessage`,
    method: 'post',
    data:row,
    params:{
      value,
      templateCode
    }
  })
}

export const getListData = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rptSafeActionStat/listDataIds`,
    method: 'post',
    params: {
      ids,
    }
  })
}

