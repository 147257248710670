import Layout from '@/page/index/'
//流程
export default [  {
  path: '/workflow',
  component: Layout,
  children: [
    {
      path: '/plugin/workflow/process/quality_scheduling',
      name: '办结流程',
      component: () =>
        import('@/views/plugin/workflow/process/quality_scheduling'),
      meta: {
        i18n: 'qualityScheduling'
      },
    },
    {
      path: 'design/formEdit',
      name: '表单设计编辑',
      component: () =>
        import('@/views/plugin/workflow/design/formEdit'),
      meta: {
        i18n: 'formsEdit'
      },
    },
    {
      path: '/plugin/workflow/ops/done',
      name: '办结流程',
      component: () =>
        import('@/views/plugin/workflow/ops/done'),
      meta: {
        i18n: 'done'
      },
    },
    {
      path: '/plugin/workflow/ops/list',
      name: '流程列表',
      component: () =>
        import('@/views/plugin/workflow/ops/list'),
      meta: {
        i18n: 'list'
      },
    },
    {
      path: '/plugin/workflow/ops/proxy',
      name: '流程代理',
      component: () =>
        import('@/views/plugin/workflow/ops/proxy'),
      meta: {
        i18n: 'proxy'
      },
    },
    {
      path: '/plugin/workflow/ops/proxyEdit',
      name: '流程代理新增',
      component: () =>
        import('@/views/plugin/workflow/ops/proxyEdit.vue'),
      meta: {
        i18n: 'proxyEdit'
      },
    },
    {
      path: '/plugin/workflow/ops/index',
      name: '运维调度',
      component: () =>
        import('@/views/plugin/workflow/ops/index'),
      meta: {
        i18n: 'index'
      },
    },
    {
      path: 'design/conditionEdit',
      name: '流程表达式编辑',
      component: () =>
        import('@/views/plugin/workflow/design/conditionEdit'),
      meta: {
        i18n: 'conditionEdit'
      },
    },{
      path: 'design/categoryEdit',
      name: '流程分类编辑',
      component: () =>
        import('@/views/plugin/workflow/design/categoryEdit'),
      meta: {
        i18n: 'categoryEdit'
      },
    },
    {
      path: 'design/form-default-values-edit',
      name: '表单默认值编辑',
      component: () =>
        import('@/views/plugin/workflow/design/form-default-values-edit'),
      meta: {
        i18n: 'formDefaultValuesEdit'
      },
    },
    {
      path: 'design/buttonEdit',
      name: '流程按钮编辑',
      component: () =>
        import('@/views/plugin/workflow/design/buttonEdit'),
      meta: {
        i18n: 'buttonEdit'
      },
    },
    {
      path: 'design/process/:id',
      name: '流程设计',
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/plugin/workflow/design'),
      meta: {
        i18n: 'process'
      },
    },

    {
      path: '/plugin/workflow/design/model',
      name: '模型设计',
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/plugin/workflow/design/model'),
      meta: {
        i18n: 'model'
      },
    },
    {
      path: '/plugin/workflow/design/form',
      name: '表单设计',
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/plugin/workflow/design/form'),
      meta: {
        i18n: 'forms'
      },
    },

    {
      path: '/plugin/workflow/design/deployment',
      name: '部署管理',
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/plugin/workflow/design/deployment'),
      meta: {
        i18n: 'deployment'
      },
    },
    {
      path: '/plugin/workflow/design/condition',
      name: '流程表达式',
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/plugin/workflow/design/condition'),
      meta: {
        i18n: 'condition'
      },
    },

    {
      path: '/plugin/workflow/design/button',
      name: '流程按钮',
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/plugin/workflow/design/button'),
      meta: {
        i18n: 'button'
      },
    },

    {
      path: '/plugin/workflow/design/category',
      name: '流程分类',
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/plugin/workflow/design/category'),
      meta: {
        i18n: 'category'
      },
    },

    {
      path: '/plugin/workflow/design/form-default-values',
      name: '表单默认值',
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/plugin/workflow/design/form-default-values'),
      meta: {
        i18n: 'formDefaultValues'
      },
    },

    {
      path: 'design/model/history/:id',
      name: '模型历史',
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/plugin/workflow/design/model-history'),
      meta: {
        i18n: 'modelHistory'
      },
    },
    {
      path: 'design/form/history/:id',
      name: '表单历史',
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/plugin/workflow/design/form-history'),
      meta: {
        i18n: 'formHistory'
      },
    },
    {
      path: 'process/start/:params',
      name: '新建流程',
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/plugin/workflow/process/components/form'),
    },
    {
      path: 'process/detail/:params',
      name: '流程详情',
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/plugin/workflow/process/components/detail'),
    },
    {
      path: '/plugin/workflow/process/start',
      name: '新建流程',
      meta: {
        i18n: 'processStart'
      },
      component: () =>
          import( /* webpackChunkName: "views" */ "@/views/plugin/workflow/process/start"),
    },
    {
      path: '/plugin/workflow/process/index',
      name: '流程',
      meta: {
        i18n: 'processTodo'
      },
      component: () =>
        import(/* webpackChunkName: "views" */ "@/views/plugin/workflow/process/index.vue"),
    },
    {
      path: '/plugin/workflow/process/todo',
      name: '待办事宜',
      meta: {
        i18n: 'processTodo'
      },
      component: () =>
          import(/* webpackChunkName: "views" */ "@/views/plugin/workflow/process/todo.vue"),
    },
    {
      path: '/plugin/workflow/process/send',
      name: '我的请求',
      meta: {
        i18n: 'processSend'
      },
      component: () =>
          import(/* webpackChunkName: "views" */ "@/views/plugin/workflow/process/send.vue"),
    },
    {
      path: '/plugin/workflow/process/my-done',
      name: '我的已办',
      meta: {
        i18n: 'processMyDone'
      },
      component: () =>
          import(/* webpackChunkName: "views" */ "@/views/plugin/workflow/process/my-done.vue"),
    },

    {
      path: '/plugin/workflow/process/done',
      name: '办结事宜',
      meta: {
        i18n: 'processDone'
      },
      component: () =>
          import(/* webpackChunkName: "views" */ "@/views/plugin/workflow/process/done.vue"),
    },
    {
      path: '/plugin/workflow/process/claim',
      name: '待签事务',
      meta: {
        i18n: 'processClaim'
      },
      component: () =>
          import( /* webpackChunkName: "views" */ "@/views/plugin/workflow/process/claim.vue"),
    },
    {
      path: '/plugin/workflow/process/copy',
      name: '抄送事宜',
      meta: {
        i18n: 'processCopy'
      },
      component: () =>
          import(/* webpackChunkName: "views" */ "@/views/plugin/workflow/process/copy.vue"),
    },
    {
      path: '/workflow/flowTodo',
      name: '流程代办',
      component: () =>
          import(/* webpackChunkName: "views" */ "@/views/workFlow/flowTodo/index.vue"),
    },
    {
      path: '/workflow/flowDone',
      name: '流程已办',
      component: () =>
          import(/* webpackChunkName: "views" */ "@/views/workFlow/flowDone/index.vue"),
    },
    {
      path: '/workflow/flowLaunch',
      name: '我发起的',
      component: () =>
        import(/* webpackChunkName: "views" */ "@/views/workFlow/flowLaunch/index.vue"),
    },
    {
      path: '/workflow/formDesign',
      name: '流程表单',
      meta: {
        i18n: 'processCopy'
      },
      component: () =>
          import(/* webpackChunkName: "views" */ "@/views/workFlow/formDesign/index.vue"),
    },
    {
      path: '/workflow/flowLaunch',
      name: '流程表单',
      meta: {
        i18n: 'processCopy'
      },
      component: () =>
          import(/* webpackChunkName: "views" */ "@/views/workFlow/flowLaunch/index.vue"),
    },
    {
      path: '/process/external/iframe/detail',
      name: '流程详情',
      component: () =>
        import('@/views/plugin/workflow/process/external/iframe/detail.vue'),
    },
  ]
}
]
