import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const CONTROLLER_NAME = "/xxljob/";

/**
 *  登录xxl-job
 */

export const login = () => {
  return request({
    url: `${ServerNameEnum.SERVER_JOB_}${CONTROLLER_NAME}login`,
    method: 'post',
  })
}
