import httpService from "@/router/axios";

// 知识库-法律法规-   分页
export const $_getPageList = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/laws/page",
    method: "get",
    params,
  });
};

// 法律法规分类
export const $_getLawType = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/lawtype/page",
    method: "get",
    params,
  });
};
//法律法规分类新增
export const $_addLawTypeSave = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/lawtype/save",
    method: "post",
    data: params,
  });
};
//法律法规分类修改
export const $_addLawTypeUpdate = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/lawtype/update",
    method: "post",
    data: params,
  });
};
//法律法规分类删除
export const $_addLawTypeRemove = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/lawtype/remove",
    method: "post",
    params,
  });
};
//法律法规分类树
export const $_addLawTypeTree = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/lawtype/tree",
    method: "get",
    params,
  });
};
//知识库-法律法规-   新增
export const $_addSave = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/laws/save",
    method: "post",
    data: params,
  });
};

// 知识库-法律法规-   修改
export const $_updateData = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/laws/update",
    method: "post",
    data: params,
  });
};

// 知识库-法律法规-   详情
export const $_getDetail = (params) => {
  return httpService({
    url: `/api/ivystar-wisdom-security/laws/detail`,
    method: "get",
    params
  });
};

// 知识库-法律法规- 删除
export const $_delRemoveData = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/laws/remove",
    method: "post",
    params,
  });
};

// 知识库-法律法规-   导出
export const $_getLawExport = () => {
  return "/api/ivystar-wisdom-security/laws/export";
};


