

/**
 * 全局配置文件
 */
console.log(process.env.VUE_APP_BASE_SOCKET)
let VUE_APP_BASE_VIEW=process.env.VUE_APP_BASE_SOCKET
export default {
  title: "sinoma",
  logo: "S",
  key: 'sinoma',//配置主键,目前用于存储
  indexTitle: 'Sinoma Admin',
  clientId: 'sinoma', // 客户端id
  clientSecret: 'sinoma_secret', // 客户端密钥
  tenantMode: true, // 是否开启租户模式
  tenantId: "", // 管理组租户编号
  captchaMode: false, // 是否开启验证码模式
  switchMode: false, // 是否开启部门切换模式
  lockPage: '/lock',
  tokenTime: 3000,
  tokenHeader: 'Sinoma-Auth',
  //http的status默认放行列表
  statusWhiteList: [],
  //配置首页不可关闭
  isFirstPage: false,
  fistPage: {
    label: "首页",
    value: "/cip/index",
    params: {},
    query: {},
    meta: {
      i18n: 'dashboard'
    },
    group: [],
    close: false
  },
  //配置菜单的属性
  menu: {
    iconDefault: 'iconfont icon-caidan',
    props: {
      label: 'name',
      path: 'path',
      icon: 'source',
      children: 'children'
    }
  },
  // 第三方系统授权地址
  authUrl: 'http://192.168.1.186/sinoma-auth/oauth/render',
  // 报表设计器地址(cloud端口为8108,boot端口为80)
  reportUrl: 'http://192.168.1.186:8108/ureport',
  headOpenPageType: 'tab',
  gridOpenPageType: 'drawer',
  // videobasUrl: 'http://127.0.0.1:185',
  /*********************测试环境配置****************************/


  //下面配置信息废弃，如此配置请移步env文件
  // 润乾地址
  webUrl: process.env.VUE_APP_BASE_WEBURL,
  rqReportUrl: process.env.VUE_APP_BASE_RQREPORTURL,
  onlineHelpUrl: process.env.VUE_APP_BASE_ONLINEHELPURL,
  indexIntfUrl: process.env.VUE_APP_BASE_INDEXINTFURL,
  // 存放在首页的消息webSocket的IP地址
  messageWebSocket: `ws://${VUE_APP_BASE_VIEW}/message-websocket/`,
  // 存放在首页的公告webSocket的IP地址
  deskWebSocket: `ws://${VUE_APP_BASE_VIEW}/desk-websocket/`,
  // IM
  userWebSocket: `ws://${VUE_APP_BASE_VIEW}/message/websocket/`,
  sysWebSocket: `ws://${VUE_APP_BASE_VIEW}/system-websocket/`,
  // 现场培训
  trainWebSocket: `wss://${VUE_APP_BASE_VIEW}/training-websocket/`,
  // trainWebSocket: 'ws://172.16.90.24:9030/training-websocket/',
  //机器人单点登录地址和默认跳转应用
  botUrl: `http://${VUE_APP_BASE_VIEW}/assistant/ssoLogin`,
  botRedirectUrl: '/chat2?appId=65a87c42199f92b4ba881168',
  kkfileUrl: `http://${VUE_APP_BASE_VIEW}/preview/onlinePreview`,
  // kkfile预览转码队列
  kkfileViewUrl: `http://${VUE_APP_BASE_VIEW}/preview/addTask?url=`,
  //企业微信跳转应用地址
  enterpriseWechatUrl: 'https://hse.snowsoft.cn',
  //中材OA地址
  enterpriseOaUrl: 'https://home.sinoma.com.cn',
  // 画图工具链接
  hmiUrl:`http://${VUE_APP_BASE_VIEW}/hmiwebdev/#/HmiEdit`,//开发环境
  // 开发测试
  corp_id:'ww198da5843dde67f8',
  agentid: 1000008,
  appid:'wx9878207941881e02',
}

