export default {

  cps: {
    msg: {
      removeSpaces: 'Please delete the spaces before and after',
      tipDetermineRemove: 'Confirm to remove the selected data?',
      importFormat: 'Excel format file import',
      importLoading: 'Importing data, please wait...',
      saveHead: 'saveHead',
    },
    field: {
      createTime: 'createTime',
      remark: 'remark',
      serial: "serialNumber",
    },
    btn: {
      importData: 'importData',
      exportModel: 'exportModel',
      exportMonitoring: 'exportMonitoring',
      exportDevice: 'exportDevice',
      exportPoint: 'exportPoint',
      improtDevice: 'improtDeviceData',
      improtPoint: 'improtPointData',
      exportDownload: 'exportDownload',
      download: 'download',
      exportDeviceTemplate: 'exportDeviceTemplate',
    },
    device: {
      deviceProperty: {
        devicePropertyCode: 'devicePropertyCode',
        devicePropertyName: 'devicePropertyName',
        dataType: 'dataType',
        variableType: 'variableType',
        standardPointCode: 'standardPointCode',
        rangeMax: 'rangeMax',
        rangeMin: 'rangeMin',
        dcsCode: 'dcsCode',
        isEnable: 'isEnable',
        isPush: "isPush",
      },
      deviceEvent: {
        deviceEventCode: 'deviceEventCode',
        deviceEventName: 'deviceEventName',
        dataType: 'dataType',
        variableType: 'variableType',
        standardPointCode: 'standardPointCode',
        deviceEventType: 'deviceEventType',
        dcsCode: 'dcsCode',
        isEnable: 'isEnable',
        gaugingType: 'floating-pointType',
        isPush: "isPush",
      },
      deviceService: {
        deviceServiceCode: 'deviceServiceCode',
        deviceServiceName: 'deviceServiceName',
        dataType: 'dataType',
        variableType: 'variableType',
        standardPointCode: 'standardPointCode',
        rangeMax: 'rangeMax',
        rangeMin: 'rangeMin',
        dcsCode: 'dcsCode',
        isEnable: 'isEnable',
        isPush: "isPush",
      },
      deviceCalc: {
        selectInputParam: 'Please select an input parameter',
        deviceCalcCode: 'deviceCalcCode',
        deviceCalcName: 'deviceCalcName',
        resultDataType: 'resultDataType',
        calcMethod: 'calcMethod',
        inputParam: 'inputParam',
        isSave: 'isSave',
        isPush: 'isPush',
        isEnable: 'isEnable',
      },
      title: {
        netType: 'Logical location',
        locationType: 'Physical location',
      },
      msg: {
        chinese: 'You cannot enter Chinese characters',
        exportData: 'Decide to export device data？',
        modelUpdate: 'Are you sure to regenerate device details based on the associated model? The old details will be overwritten',
        selectprotocolParams: 'Please selectprotocol configuration',
        checkModel: 'Please select the model first',
      },
      field: {
        netType: {
          netCode: 'netCode',
          netName: 'netName',
          orderNum: 'orderNum',
          isEnable: 'isEnable',
          heartbeatTopic: 'heartbeatTopic'
        },
        locationType: {
          parentName: 'ParentClassification',
          locationCode: 'locationCode',
          locationName: 'locationName',
          orderNum: 'orderNum',
          isEnable: 'isEnable',
          ten: 'A number up to 10 digits'
        },
        netTitle: 'logical location classification',
        locTitle: 'physical location classification',
        deviceData: '【Data acquisition platform】Equipment data acquisition data',
        deviceTemplate: '【Data acquisition platform】Equipment data acquisition import template',
        autoValue: 'Automatically generated by default',
        list: 'Device management',
        CSV: 'Link to CSV chart',
        deviceCode: 'deviceCode',
        deviceName: 'deviceName',
        productCode: 'productCode',
        productName: 'productName',
        modelCode: 'modelCode',
        modelName: 'modelName',
        subModelCode: 'subModelCode',
        subModelName: 'subModelName',
        selectModel: 'select model',
        deviceType: 'deviceType',
        deviceKey: 'deviceKey',
        protocolCode: 'protocolCode',
        protocolName: 'protocolName',
        locationName: 'locationName',
        netName: 'netName',
        deviceTags: 'deviceTags',
        topicUrl: 'topicUrl',
        protocolParams: 'protocolParams',
        isEnable: 'isEnable',
        property: 'property',
        event: 'event',
        service: 'service',
        calc: 'calc',
        select: 'device',
        normal: 'normalQuantity',
        abnormal: 'abnormalQuantity',
        enableDeviceCount: 'normalQuantity',
        disableDeviceCount: 'abnormalQuantity',
        dragin: 'auto in by default',
        updateModel: 'update model',
        mainDeviceCat: 'Main Device Cat',
        mainDevice: 'Main Device',
        mainDeviceAdd: 'Main Device Add',
        deviceCatConf: 'Device Cat Conf',
        subDeviceCat: 'Sub Device Cat',
        subDevice: 'Sub Device',
        mainDeviceCatAdd: 'Main DeviceCat Add',
        subDeviceCatAdd: 'Sub DeviceCat Add',
        subDeviceAdd: 'Sub DeviceAdd',
        mainDeviceCatCode: 'Main DeviceCat Code',
        mainDeviceCatName: 'Main DeviceCat Name',
        optMode: 'optMode',
        isSpecial: 'isSpecial',
        isEnvPort: 'isEnv Port',
        powerType: 'powerType',
        portAtt: 'PortAtt',
        importLevel: 'important Level',
        subDeviceCatCode: 'Sub DeviceCat Code',
        subDeviceCatName: 'Sub DeviceCat Name',
        adminDept: 'adminDept',
        connectLocation: 'connect Location',
        mainAndSubRelation: 'Please select a sub-device',
        mainDeviceName: 'Main Device Name',
        mainDeviceCode: 'Main Device Code',
        processName: 'Process Name',
        subDeviceCode: 'Sub Device Code',
        subDeviceName: 'Sub Device Name',
        sparepartsCode: 'Spareparts Code',
        part: 'part',
        addPart: 'addPart',
        sparepartsName: 'Spareparts Name',
        remark: 'remark',
        specs: 'specs',
        value: 'value',
        unit: 'unit',
        specsMan: 'specsMan'
      }
    },
    model: {
      modelType: {
        title: 'ClassificationModel',
        parentCode: 'parentCode',
        parentName: 'parentName',
        modelTypeCode: 'modelTypeCode',
        modelTypeName: 'modelTypeName',
        orderNum: 'orderNum',
        isEnable: 'isEnable',
        ten: 'A number up to 10 digits',
      },
      modelProperty: {
        modelPropertyCode: 'modelPropertyCode',
        modelPropertyName: 'modelPropertyName',
        dataType: 'dataType',
        variableType: 'variableType',
        standardPointCode: 'standardPointCode',
        rangeMax: 'rangeMax',
        rangeMin: 'rangeMin',
        isEnable: 'isEnable',
        isPush: 'isPush',
      },
      modelEvent: {
        modelEventCode: 'modelEventCode',
        modelEventName: 'modelEventName',
        dataType: 'dataType',
        variableType: 'variableType',
        standardPointCode: 'standardPointCode',
        modelEventType: 'modelEventType',
        isEnable: 'isEnable',
        isPush: 'isPush',
      },
      modelService: {
        modelServiceCode: 'modelServiceCode',
        modelServiceName: 'modelServiceName',
        dataType: 'dataType',
        variableType: 'variableType',
        standardPointCode: 'standardPointCode',
        rangeMax: 'rangeMax',
        rangeMin: 'rangeMin',
        isEnable: 'isEnable',
        isPush: 'isPush',
      },
      modelCalc: {
        selectInputParam: 'selectInputParam',
        modelCalcCode: 'modelCalcCode',
        modelCalcName: 'modelCalcName',
        resultDataType: 'resultDataType',
        calcMethod: 'calcMethod',
        inputParam: 'inputParam',
        isSave: 'isSave',
        isPush: 'isPush',
        isEnable: 'isEnable',
      },
      msg: {
        exportData: 'determine to export model data？',
        select: 'Please select model',
        picture: 'jpg/png model picture can only be uploaded, and no more than 500kb',
      },
      title: {
        title: 'model',
        model: "ClassificationModel",
      },
      field: {
        modelData: '【Data acquisition platform】Equipment model data',
        modelTemplate: '【Data Acquisition Platform】Equipment model import template',
        modelCode: 'modelCode',
        modelName: 'modelName',
        subModelCode: 'subModelCode',
        subModelName: 'subModelName',
        isEnable: 'isEnable',
        version: 'version',
        list: 'modelManagement',
        title: 'title',
        property: 'property',
        event: 'event',
        service: 'service',
        calc: 'calc',
        devicePicture: 'Model picture',
        modelClassify: 'ClassificationModel'
      },
    },
    protocol: {
      title: {
        title: 'Protocol management',
      },
      msg: {
        select: 'Please select an agreement',
      },
      field: {
        protocolCode: 'protocolCode',
        protocolName: 'protocolName',
        protocolType: 'protocolType',
        protocolParams: 'protocolParams',
        isEnable: 'isEnable',
        heartbeatTopic: 'heartbeatTopic'
      }
    },
    monitoring: {
      title: {
        title: 'DataAcquisitionMonitoring',
      },
      msg: {
        currenttime: 'The end time cannot be later than the current time',
        exceed: "The time range cannot exceed one day",
        exceedMonth: "The time range cannot exceed one month",
        template: '[Data mining platform] device data mining export template',
        exportMonitoring: '[Data collection platform] equipment data collection',
        leastOne: 'Please select at least one piece of data',
      },
      field: {
        second: "second",
        terminalCoding: 'terminalCoding',
        terminalName: 'terminalName',
        agreementName: 'agreementName',
        isEnable: 'isEnable',
        createTime: 'createTime',
        isStatus: 'isStatus',
        heartbeatTopic: 'heartbeatTopic',
        select: 'select',
        connect: 'connect',
        break: 'break',
        protocolParams: 'protocolParams',
        equipment: 'equipment',
        cardPattern: 'cardPattern',
        listPattern: 'listPattern',
        getAI: 'AI',
        getDI: 'DI',
        getAO: "AO",
        getDO: "DO",
        getST: "ST",
        standardPointCode: 'standardPointCode：',
        eventualCreateTime: 'eventualCreateTime：',
        ipLocation: 'ip',
        clsid: 'CLSID',
        clientId: 'clientId',
        deviceCode: 'deviceCode',
        gaugingType: 'pointCode',
        remark: 'remark',
        today: 'today',
        week: 'week',
        month: 'month',
        until: 'until',
        beginTime: 'beginTime',
        endTime: 'endTime',
        noData: 'noData',
        exportPoint: 'exportPoint',
        deviceCard: 'device',
        DeviceSummation: 'point',
        deviceName: 'deviceName',
        deviceEventCode: 'pointCode',
        stationName: 'pointNmae',
        stationCode: 'pointCode',
        variableType: 'typeVariables',
        modelName: 'modelName',
        netName: 'logical place',
        locationName: 'physical location',
        enable: 'Have been enabled',
        notEnabled: 'not enabled',
        inputParam: 'inputParam:',
        calcMethod: 'calcMethod:',
        protocolParamsSelect: 'protocolParamsSelect'
      },
      btn: {
        history: 'history',
        chart: 'chart',
        earlyWarning: 'earlyWarning',
        remove: 'removePoint',
        station: 'selectPoint',
        device: 'selectDevice',
        protocolParams: 'protocolParams',
      },
    },
    cache: {
      btn: {
        importData: "importData",
        exportModel: "ExportModel ",
        exportMonitoring: "ExportTerminal ",
        export: "The cache data was exported",
        exportPoint: "derive test point",
        improtDevice: "Import device data",
        improtPoint: "Import point data",
        exportDownload: "Export Device Template",
        download: "Template download",
        exportDcsTemplate: "Export template",
        cancel: "cancel",
        screening: "screeningPoint",
        select: "select",
      },
      cachePoint: {
        tree: {
          title: "OPC Server",
          dialogOPC: "OPC",
          serverCode: "server code",
          ip: "ip",
          clsId: "clsId",
          progId: "progId",
          username: "username",
          password: "password",
          domain: "domain",
          frequency: "frequency",
          dbPath: "cache file storage path",
          remark: "remark",
          hasChildrenWarning: "Children not deleted!",
        },
        btn: {
          issueBtn: "Build configuration"
        },
        title: {
          title: "DCS cache test point Configuration",
          dialogTree: "Model Classification",
        },
        msg: {
          select: "Please select a protocol",
          delivery: "Please select the delivery mode",
          frequency: "Please select the frequency",
          generateFile: "Make sure to generate the cache configuration file",
          addPoints: "Are you sure to add the selected point to the current OPC service?",
          treeCode: "Please select an OPC service on the left"
        },
        field: {
          deviceCode: "DeviceCode",
          deviceName: "DeviceName",
          pointCode: "Pointcode",
          pointName: "pointName",
          dataType: "dataType",
          gaugingType: "Gaugingtype",
          variableType: 'variableType',
          dcsCode: 'dcsCode',
          issueConfiguration: "deliver ",
          exportPointConfig: "[DCS cache point] point data configuration",
          template: "[DCS cache point] point configuration export template",
          configFile: "cache point configuration",
          delivery: "Delivery mode",
          all: "all",
          portion: "portion",
          frequency: "frequency",
          selectFrequency: "selectFrequency",
          minute: "minute"
        }
      },
      cacheData: {
        title: {
          title: "DCS cache data",
          selectWarning: "Please filter the point to query"
        },
        field: {
          time: "cache time",
          pointCode: "DCS Code",
          pointValue: "DCS Value",
          exportCacheData: "[DCS cache data] export",
          until: 'until',
          beginTime: 'beginTime',
          endTime: 'endTime',
        }
      }
    }
  },

}
