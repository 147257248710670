import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';


// 获取重大风险源列表
export const getList = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/dmdangerslist/list`,
      method: 'get',
      params
    })
  }
  // 新增或编辑
  export const addList = (data) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/dmdangerslist/submit`,
      method: 'post',
      data
    })
  }
  // 获取详情
  export const getDetail = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/dmdangerslist/detail`,
      method: 'get',
      params
    })
  }
   // 删除列表
   export const removeList = (data) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/dmdangerslist/remove?ids=${data.ids}`,
      method: 'post',
      data
    })
}
// 导出列表
export const exportList = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/dmdangerslist/export`,
    method: 'get',
    params
  })
}
