/*
 * @Author: absorbedyy@163.com
 * @Date: 2024-05-09 09:23:15
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-08-20 15:27:19
 * @Description:
 */
import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';


/** 设备设施清单-分页 */
export const alarmHandlingPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/almrecord/page',
    method: 'get',
    params: {
      current,
      size,
      ...params
    }
  })
}

export const alarmHandlingDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/almrecord/detail',
    method: 'get',
    params
  })
}
export const alarmHandlingSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/almrecord/batchProcess',
    method: 'post',
    data
  })
}

export const alarmHandlingExport = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/almrecord/export',
    method: 'get',
    responseType: 'blob',
    params
  })
}


/** 设备设施证书资料-分页 */
export const eqLedgerCertificatePage = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqequipmentmaterial/page',
    method: 'get',
    params
  })
}
export const eqLedgerCertificateDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqequipmentmaterial/detail',
    method: 'get',
    params
  })
}
export const eqLedgerCertificateSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqequipmentmaterial/submit',
    method: 'post',
    data
  })
}
export const eqLedgerCertificateRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqequipmentmaterial/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}


/** 设备设施保养记录-分页 */
export const eqLedgerMaintainPage = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqmaintrecord/page',
    method: 'get',
    params
  })
}
export const eqLedgerMaintainDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqmaintrecord/detail',
    method: 'get',
    params
  })
}
export const eqLedgerMaintainSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqmaintrecord/submit',
    method: 'post',
    data
  })
}
export const eqLedgerMaintainRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqmaintrecord/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}


/** 设备设施维修记录-分页 */
export const eqLedgerServicePage = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqrepairrecord/page',
    method: 'get',
    params
  })
}
export const eqLedgerServiceDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqrepairrecord/detail',
    method: 'get',
    params
  })
}
export const eqLedgerServiceSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqrepairrecord/submit',
    method: 'post',
    data
  })
}
export const eqLedgerServiceRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqrepairrecord/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
