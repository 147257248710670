import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_NAME = "/appSystemCategory";


/**
 * 分页查询
 * @param current 页码
 * @param size  每页数量
 * @param params  查询条件
 */
export const sysCategoryWithIndexPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}/${CONTROLLER_NAME}/sysCategoryWithIndexPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const saveOrUpdateTreeNew= (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}/indexDefine/save`,
    method: 'post',
    data
  })
}

export const tree = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/tree`,
    method: 'get',
    params
  })
}

/**
 * 分类编辑新增
 * @param
 * @returns AxiosPromise
 */
export const sysCategoryWithIndexSave= (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/sysCategoryWithIndexSave`,
    method: 'post',
    data

  })
}
/**
 * 分类编辑新增
 * @param
 * @returns AxiosPromise
 */
export const saveOrUpdateTree= (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/save`,
    method: 'post',
    data
  })
}

/**
 * 删除
 * @param ids  删除的id集合
 * @returns AxiosPromise
 */
export const removeTree = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/removeTree`,
    method: 'get',
    params: {
      ids
    }
  })
}


/**
 * 删除
 * @param
 * @returns AxiosPromise
 */
export const removeCategoryWithIndex= (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/removeCategoryWithIndex`,
    method: 'post',
    data
  })
}
/**
 * 检查分类编码是否存在
 * @param params  编码
 * @returns AxiosPromise
 */
export const checkCategoryCodeExits = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/checkCategoryCodeExits`,
    method: 'post',
    data
  })
}
