import request from '@/router/axios';
const SERVER_FX = '/api/sinoma-hse-prj'

// 应急预案列表
export const erplanPage = (current, size, params) => {
  return request({
    url: SERVER_FX + '/erplan/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
// 应急预案基本信息详情
export const erplanGetDetail = (id) => {
  return request({
    url: SERVER_FX + '/erplan/detail',
    method: 'get',
    params: {
      id
    }
  })
}
// 应急预案列表删除
export const erplanRemove = (ids) => {
  return request({
    url: SERVER_FX + '/erplan/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
// 应急预案基本信息保存
export const erplanAdd = (row) => {
  return request({
    url: SERVER_FX + '/erplan/submit',
    method: 'post',
    data: row
  })
}

// 应急预案--人员清单列表
export const erplanmemberPage = (current, size, params) => {
  return request({
    url: SERVER_FX +'/erplanmember/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
// 应急预案--人员清单列表详情
export const erplanmemberGetDetail = (id) => {
  return request({
    url: SERVER_FX +'/erplanmember/detail',
    method: 'get',
    params: {
      id
    }
  })
}
// 应急预案--人员清单列表删除
export const erplanmemberRemove = (ids) => {
  return request({
    url: SERVER_FX +'/erplanmember/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
// 应急预案--人员清单列表新增
export const erplanmemberSubmit = (row) => {
  return request({
    url: SERVER_FX +'/erplanmember/submit',
    method: 'post',
    data: row
  })
}
// 应急预案--人员清单列表批量新增 且替换原来的数据
export const erplanmemberSubmitBatch = (row) => {
  return request({
    url: SERVER_FX +'/erplanmember/submitBatch',
    method: 'post',
    data: row
  })
}

// 应急预案--处置程序列表
export const erplanprocessPage = (current, size, params) => {
  return request({
    url: SERVER_FX +'/erplanprocess/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
// 应急预案--处置程序列表详情
export const erplanprocessGetDetail = (id) => {
  return request({
    url: SERVER_FX +'/erplanprocess/detail',
    method: 'get',
    params: {
      id
    }
  })
}
// 应急预案--处置程序列表删除
export const erplanprocessRemove = (ids) => {
  return request({
    url: SERVER_FX +'/erplanprocess/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
// 应急预案--处置程序列表新增
export const erplanprocessSubmit = (row) => {
  return request({
    url: SERVER_FX +'/erplanprocess/submit',
    method: 'post',
    data: row
  })
}
// 应急预案--处置程序新增获取最大序号
export const erplanprocessgetMaxSeqNum = (params) => {
  return request({
    url: SERVER_FX +'/erplanprocess/getMaxSeqNum',
    method: 'get',
    params
  })
}

// 应急预案--处置措施列表
export const erplantreatmentPage = (current, size, params) => {
  return request({
    url: SERVER_FX +'/erplantreatment/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
// 应急预案--处置措施列表详情
export const erplantreatmentGetDetail = (id) => {
  return request({
    url: SERVER_FX +'/erplantreatment/detail',
    method: 'get',
    params: {
      id
    }
  })
}
// 应急预案--处置措施列表删除
export const erplantreatmentRemove = (ids) => {
  return request({
    url: SERVER_FX +'/erplantreatment/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
// 应急预案--处置措施列表新增
export const erplantreatmentSubmit = (row) => {
  return request({
    url: SERVER_FX +'/erplantreatment/submit',
    method: 'post',
    data: row
  })
}
// 应急预案--处置措施新增获取最大序号
export const erplantreatmentgetMaxSeqNum = (params) => {
  return request({
    url: SERVER_FX +'/erplantreatment/getMaxSeqNum',
    method: 'get',
    params
  })
}

// 应急预案--应急物资列表
export const erplanmaterialPage = (current, size, params) => {
  return request({
    url: SERVER_FX +'/erplanmaterial/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
// 应急预案--应急物资列表详情
export const erplanmaterialGetDetail = (id) => {
  return request({
    url: SERVER_FX +'/erplanmaterial/detail',
    method: 'get',
    params: {
      id
    }
  })
}
// 应急预案--应急物资列表删除
export const erplanmaterialRemove = (ids) => {
  return request({
    url: SERVER_FX +'/erplanmaterial/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
// 应急预案--应急物资列表新增
export const erplanmaterialSubmit = (row) => {
  return request({
    url: SERVER_FX +'/erplanmaterial/submit',
    method: 'post',
    data: row
  })
}
// 应急预案--应急物资新增获取最大序号
export const erplanmaterialgetMaxSeqNum = (params) => {
  return request({
    url: SERVER_FX +'/erplanmaterial/getMaxSeqNum',
    method: 'get',
    params
  })
}