import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';


/** 设备维保  */

/* 设备保养记录 */
export const maintainRecordPage  = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqmaintrecord/page',
    method: 'get',
    params: {
        current, 
        size,
        ...params,
    }
  })
}
export const maintainRecordDetail  = (id) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/eqmaintrecord/detail',
      method: 'get',
      params: {id}
    })
}

export const maintainRecordSubmit  = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/eqmaintrecord/submit',
      method: 'post',
      data: params
    })
}

export const maintainRecordRemove  = (ids) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/eqmaintrecord/remove',
      method: 'post',
      params: {ids}
    })
}


/** 设备维修记录 */
export const serviceRecordPage  = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqrepairrecord/page',
    method: 'get',
    params: {
        current, 
        size,
        ...params,
    }
  })
}
export const serviceRecordDetail  = (id) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/eqrepairrecord/detail',
      method: 'get',
      params: {id}
    })
}

export const serviceRecordSubmit  = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/eqrepairrecord/submit',
      method: 'post',
      data: params
    })
}

export const serviceRecordRemove  = (ids) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/eqrepairrecord/remove',
      method: 'post',
      params: {ids}
    })
}