import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_+'/videoPlay/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getVideoPlay = (id) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + '/videoPlay/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_+'/videoPlay/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const submit = (row) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_+'/videoPlay/submit',
    method: 'post',
    data: row
  })
}

export const start = (row) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_+'/videoPlay/start',
    method: 'post',
    data: row
  })
}
export const stop = (row) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_+'/videoPlay/stop',
    method: 'post',
    data: row
  })
}
export const get = (row) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_+'/videoPlay/get',
    method: 'post',
    data: row
  })
}
