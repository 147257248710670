import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 获取数据库表列表
export function dataModelList(id) {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + `/dblink/getAllTable/${id}`,
    method: 'get'
  })
}

// 选择数据连接数据
export const dataLinkList = () => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + "/dblink/list",
    method: "get"
  });
};

// 新增表
export function addTable(id,data) {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + `/dbtable/createTable/${id}`,
    method: 'post',
    data
  })
}

// 编辑表
export const editTable = (id,data) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + `/dbtable/getInfo/${id}`,
    method: "get",
    params: {
      ...data,
    },
  });
};

// 删除
export const deleteTable = (id,data) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + `/dbtable/delTable/${id}`,
    method: "get",
    params: {
      ...data,
    },
  });
};

// 打开数据
export const openTable = (id,data) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + `/dbtable/getData/${id}`,
    method: "get",
    params: {
      ...data,
    },
  });
};

// 修改表
export function updateTable(id,data) {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + `/dbtable/updateTable/${id}`,
    method: 'post',
    data
  })
}

