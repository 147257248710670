<template>
  <div class="number-range flex-container flex-jus-sb">
    <avue-input-number @change="handleChange($event,attrs.startProp)" v-model="startProp" v-bind="attrs" :placeholder="startPlaceholder"
                        :clearable="true" :controls="false"></avue-input-number>-
    <avue-input-number @change="handleChange($event,attrs.endProp)" v-bind="attrs" v-model="endProp" :placeholder="endPlaceholder"
                     :min="startProp"
                       :clearable="true" :controls="false"></avue-input-number>
  </div>
</template>
<script >
  export default {
    name:'numberRange',
    inject:['app'],
    props:{
      startPlaceholder: String,
      endPlaceholder: String,
    },
    computed:{
      attrs(){
        return this.$attrs
      },
    },
    mounted() {
    },
    data(){
      return {
        startProp:undefined,
        endProp:undefined,
      }
    },
    methods:{
      reset(){
        this.startProp=undefined
        this.endProp=undefined
      },
      handleChange(val,model){
        this.$set(this.app.searchForm,model,val)
      },
    },
  }
</script>



<style scoped lang="scss">
.number-range{
  border: 1px solid #DCDFE6;
  ::v-deep  .el-input__inner{
    border: 0 !important;
  }
}

</style>
