<template>
      <el-row
        class="flex-container flex-align-c head-layout">
        <el-col class="flex-align-c flex-container headLabel"
          :span="headBtnOptions.length > 0 ? 10 : 18">
          {{headTitle}}
          <slot name="titleSuffix"></slot>
        </el-col>
        <el-col :span="headBtnOptions.length > 0 ? 14 : 6" class="flex-container flex-jus-e headBtn">
          <div style="float: right" class="flex-container">
              <slot name="left"></slot>
            <el-button-group class="white-space">

              <el-badge class="badgeValue"  v-if="showBtn(rowBtn)" v-for="(rowBtn, index) in headBtnOptions" :key="index" :value="rowBtn.badgeValue">
                <el-button
                  size="mini"
                  :disabled="rowBtn.disabled"
                  :loading="rowBtn.loading"
                  :type=" rowBtn.btnOptType == 'save' ? 'primary' : rowBtn.btnOptType "
                  :style="rowBtn.style ? rowBtn.style : []"
                  :icon="rowBtn.icon"
                  @click="btnClick(rowBtn)"
                  >{{btnText(rowBtn)}}
                </el-button>
              </el-badge>
              <slot name="right"></slot>
            </el-button-group>
          </div>
        </el-col>
      </el-row>
</template>
<script>
import { mapGetters } from "vuex";
import log from "@/views/system/dataInterface/Log";

export default {
  name: "head",
  props: {
    icon: {
      type: String,
      default: "",
    },
    headTitle: {
      type: String,
      default: "",
    },
    headBtnOptions: {
      type: Array,
      default: [],
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    isShowTip: {
      type: Boolean,
      default: true,
    },
    row: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      component: null,
      isShowDialog: false,
      isShowDrawer: false,
      pageType: "",
    };
  },
  computed: {
    ...mapGetters(["themeColor"]),
  },
  watch: {
    themeColor(val) {
      this.themeColor = val;
    },
  },
  mounted() {
    // this.headTitle = this.headTitle ? this.headTitle : this.$route.meta.title; 影响其他页面
    if (this.$route.query.hasOwnProperty("pageType")) {
      this.pageType = this.$route.query.pageType;
    }
  },
  methods: {
    btnText(rowBtn){
      let btn={
        'save':this.$t("cip.cmn.btn.saveBtn"),
        'upload':'上传',
        'forward':'转发',
        'saveBack':this.$t("cip.cmn.btn.saveBackBtn"),
        'add':this.$t("cip.cmn.btn.addBtn"),
        'edit':this.$t("cip.cmn.btn.editBtn"),
        'cancel':this.$t("cip.cmn.btn.celBtn"),
        'remove':this.$t("cip.cmn.btn.delBtn"),
        'view':this.$t("cip.cmn.btn.viewBtn"),
        'import':this.$t("cip.cmn.btn.importBtn"),
        'export':this.$t("cip.cmn.btn.exportBtn"),
        'determine':this.$t("cip.cmn.btn.defBtn"),
        'refer':'提交',
        'complete':'完成',
        'batchConfirm': '批量确认',
        'headSelect': '选择',
      }
      return btn[rowBtn.btnOptType] || rowBtn.label
    },
    showBtn(row){
      if (!row.show) return true
      return typeof row.show === 'function' &&  row.show(this.row)
    },
    btnClick(btn) {
      if(btn.handle){
        btn.handle(this.row)
      }else {
        this.$emit(btn.emit);
      }
    },
    initHeadBtn() {},
  },
};
</script>

<style scoped lang="scss">
.head-layout{
  background-color: #ffffff;
  border-bottom: 1px solid #cccccc;
  height: 46px;
  //padding: 9px 12px 8px 12px;
}

.headLabel {
  font-size: 14px;
  margin-left: 6px;
  white-space: nowrap; /* 确保文本不会换行 */
  //overflow: hidden; /* 隐藏超出容器的内容 */
  text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
}
.headLabelTooltip {
  width: 500px;
  white-space: normal;
  word-break: break-all;
}
</style>
