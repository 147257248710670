var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-wrapper" },
    [
      _vm._l(_vm.menu, function (item) {
        return [
          !_vm.validatenull(item[_vm.childrenKey]) && _vm.vaildRoles(item)
            ? _c(
                "el-submenu",
                {
                  key: item[_vm.labelKey],
                  staticClass: "nest-menu",
                  attrs: {
                    index: item[_vm.pathKey],
                    title: _vm.generateTitle(item),
                    "popper-class": `${_vm.themeClass}`,
                  },
                },
                [
                  !_vm.collapse
                    ? _c("template", { slot: "title" }, [
                        _c(
                          "div",
                          {
                            class: _vm.language === "en" ? "headMenuItem" : "",
                          },
                          [
                            _c("i", { class: item[_vm.iconKey] }),
                            _c(
                              "span",
                              {
                                class: {
                                  "el-menu--display": _vm.collapse && _vm.first,
                                },
                                attrs: { slot: "title" },
                                slot: "title",
                              },
                              [_vm._v(_vm._s(_vm.generateTitle(item)))]
                            ),
                          ]
                        ),
                      ])
                    : _c("template", { slot: "title" }, [
                        _c("i", { class: item[_vm.iconKey] }),
                        _c(
                          "span",
                          {
                            class: {
                              "el-menu--display": _vm.collapse && _vm.first,
                            },
                            attrs: { slot: "title" },
                            slot: "title",
                          },
                          [_vm._v(_vm._s(_vm.generateTitle(item)))]
                        ),
                      ]),
                  _vm._l(item[_vm.childrenKey], function (child, cindex) {
                    return [
                      _vm.validatenull(child[_vm.childrenKey])
                        ? _c(
                            "el-menu-item",
                            {
                              key: child[_vm.labelKey],
                              class: { "is-active": _vm.vaildActive(child) },
                              attrs: { index: child[_vm.pathKey] },
                              on: {
                                click: function ($event) {
                                  return _vm.open(child)
                                },
                              },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    class:
                                      _vm.language === "en"
                                        ? "headMenuItem"
                                        : "",
                                    attrs: { title: _vm.generateTitle(child) },
                                  },
                                  [
                                    _c("i", { class: child[_vm.iconKey] }),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.generateTitle(child))),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _c("sidebar-item", {
                            key: cindex,
                            attrs: {
                              collapse: _vm.collapse,
                              menu: [child],
                              props: _vm.props,
                              screen: _vm.screen,
                            },
                          }),
                    ]
                  }),
                ],
                2
              )
            : _vm.validatenull(item[_vm.childrenKey]) && _vm.vaildRoles(item)
            ? _c(
                "el-menu-item",
                {
                  key: item[_vm.labelKey],
                  staticClass: "elMenuItem",
                  class: { "is-active": _vm.vaildActive(item) },
                  attrs: { index: item[_vm.pathKey] },
                  on: {
                    click: function ($event) {
                      return _vm.open(item)
                    },
                  },
                },
                [
                  [
                    _c(
                      "div",
                      {
                        staticClass: "menuItemBox",
                        attrs: { title: _vm.generateTitle(item) },
                      },
                      [
                        _c("i", {
                          class: item[_vm.iconKey],
                          staticStyle: { margin: "0" },
                        }),
                        _c(
                          "span",
                          {
                            class: {
                              "el-menu--display": _vm.collapse && _vm.first,
                            },
                            attrs: { alt: item[_vm.pathKey] },
                          },
                          [_vm._v(_vm._s(_vm.generateTitle(item)))]
                        ),
                      ]
                    ),
                  ],
                ],
                2
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }