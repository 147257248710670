/**
 * 全站权限配置
 *
 */
 import router from './router/router'
 import store from './store'
 import {validatenull} from '@/util/validate'
 import {getToken} from '@/util/auth'
 import {queryStringToObj,getQueryString} from "@/util/util";
 import NProgress from 'nprogress' // progress bar
 import 'nprogress/nprogress.css' // progress bar style
 NProgress.configure({showSpinner: false});
 const lockPage = store.getters.website.lockPage; //锁屏页
 let first=true
 router.beforeEach((to, from, next) => {
   const location = window.location
   // https://hse.sinoma.com.cn/#/ssoLogin?token=xxxxx
   if(location.href.indexOf('https://hse.sinoma.com.cn/#/ssoLogin')!=-1&&first){
     let thirdToken =queryStringToObj(location.href);
     first=false
     store.dispatch('zjcAuthLogin',thirdToken.token).then((res)=>{
       if(res.data.success){
        store.dispatch('GetMenu')
        next({path: '/'})
        return
       }else{
        next({path: '/login'})
        return
       }
      })
   }
   if(to.redirectedFrom && to.redirectedFrom.indexOf('/#/') != -1){
     next(to.redirectedFrom.replaceAll('/#/','/'))
   }
   const userinfo = store.getters.userInfo;
   const meta = to.meta || {};
   const isMenu = meta.menu === undefined ? to.query.menu : meta.menu;
   store.commit('SET_IS_MENU', isMenu === undefined);
   if (getToken()) {
     if (store.getters.isLock && to.path !== lockPage) { //如果系统激活锁屏，全部跳转到锁屏页
       next({path: lockPage})
     } else if (to.path === '/login') { //如果登录成功访问登录页跳转到主页
       next({path: '/'})
     } else if (to.path == '/cip/index') {
       if (userinfo.weak_password == "false" && userinfo.first_login == "N") {
         next({path: '/changePassword'})
       } else {
         next()
       }
     } else if(to.path == '/changePassword') {
       if (!userinfo.user_id) {
         next({path: '/login'})
       } else {
         next()
       }
     } else {
       //如果用户信息为空则获取用户信息，获取用户信息失败，跳转到登录页
       if (store.getters.token.length === 0) {
         store.dispatch('FedLogOut').then(() => {
           next({path: '/login'})
         })
       } else {
         const value = to.query.src || to.fullPath;
         const label = to.query.name || to.name;
         const meta = to.meta || router.$avueRouter.meta || {};
         const i18n = to.query.i18n;
         if (to.query.target) {
           window.open(value)
         } else if (meta.isTab !== false && !validatenull(value) && !validatenull(label)) {
           if(!to.query.isNewWindow){
             let queryType=['add','edit','view']
             let newLabel=''
             if(to.query.type == 'add'){
               newLabel=label.indexOf('新增') == -1 ? label+'新增' :label
             }else if(to.query.type == 'edit'){
               newLabel=label.indexOf('编辑') == -1 ? label+'编辑' :label
             }else if(to.query.type == 'view'){
               newLabel=label.indexOf('查看') == -1 ? label+'查看' :label
             }else {
               newLabel=label
             }
             if(!store.state.common.tagsView && !queryType.includes(to.query.type)){
               store.commit('DEL_ALL_TAG')
             }
             store.commit('ADD_TAG', {
               label: newLabel,
               value: value,
               query: to.query,
               meta: (() => {
                 if (!i18n) {
                   return meta
                 }
                 return {
                   i18n: i18n
                 }
               })(),
               group: router.$avueRouter.group || []
             });
           }
         }
         next()
       }
     }
   } else {
     //企业微信到登录页面
     if(to.path=='/login'){
       let wxSinoma =getQueryString('sinoma')
       if(wxSinoma=='logout'){
         location.href='https://home.sinoma.com.cn/cas/login?service=https://hse.sinoma.com.cn/login?'
       }
     }
     //判断是否需要认证，没有登录访问去登录页
     if(to.path == '/changePassword') {
       next('/login')
     } else if (meta.isAuth === false) {
       if(!to.redirectedFrom || to.redirectedFrom == '/'){
         next()
       }else {
         next({query:{redirect:to.redirectedFrom}})
       }
     } else {
       next('/login'+location.search)
     }
   }
 })

 router.afterEach((to, from) => {
   NProgress.done();
   let i18n = store.getters.tag.meta.i18n;
   let  title = router.$avueRouter.generateTitle(to.name, i18n);
   if(!to.query.isNewWindow){
     router.$avueRouter.setTitle(title);
   }else {
     router.$avueRouter.setTitle(to.name);

   }
 });
