var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-upload",
    {
      staticClass: "upload-btn",
      attrs: {
        action: _vm.define.comUrl + _vm.url,
        headers: { Authorization: _vm.$store.getters.token },
        data: _vm.data,
        "on-success": _vm.handleSuccess,
        "before-upload": _vm.beforeUpload,
        "show-file-list": false,
      },
    },
    [
      _c(
        "el-button",
        {
          ref: "uploadBtn",
          attrs: {
            type: _vm.buttonType,
            icon: _vm.showIcon ? "el-icon-upload2" : "",
            loading: _vm.loading,
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.buttonText) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }