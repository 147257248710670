import Layout from '@/page/index/'

export default [


  //安全环境
  {
    path: '/reEducationSetting',
    component: Layout,
    name:'考试设置',
    children: [
      {
        path: '/business/training/reEducationSetting/add',
        name: '再教育设置新增',
        meta: {},
        component: () =>
          import('@/views/business/training/reEducationSetting/add.vue')
      },      {
        path: '/business/training/reEducationSetting/edit',
        name: '再教育设置编辑',
        meta: {},
        component: () =>
          import('@/views/business/training/reEducationSetting/add.vue')
      },      {
        path: '/business/training/reEducationSetting/view',
        name: '再教育设置查看',
        meta: {},
        component: () =>
          import('@/views/business/training/reEducationSetting/add.vue')
      },
    ]
  },
]
