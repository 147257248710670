import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';


export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + '/rptHDTroubleShotTingStat/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
/**
 * 新增或修改
 * @param
 * @returns AxiosPromise
 */
export const saveOrUpdate = (data,type) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptHDTroubleShotTingStat/submit`,
    method: 'post',
    data,
    params: {
      type
    }
  })
}

/**
 * 审核通过新增或修改
 * @param
 * @returns AxiosPromise
 */
export const preserve = (data) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptHDTroubleShotTingStat/preserve`,
    method: 'post',
    data
  })
}
/**
 * 通过审核后汇总页面修改数据
 * @param
 * @returns AxiosPromise
 */
export const update = (data) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptHDTroubleShotTingStat/update`,
    method: 'post',
    data,
  })
}
/**
 * 数据批量提交保存
 * @param
 * @returns AxiosPromise
 */
export const submissionsSave = (data) => {
  return request({

    url: ServerNameEnum.SERVER_REPORT_ + `/rptHDTroubleShotTingStat/submissionsSave`,
    method: 'post',
    data: data
  })
}
/**
 * 分页查询
 * @param current 页码
 * @param size  每页数量
 * @param params  查询条件
 * @returns AxiosPromise
 */
export const selectPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptHDTroubleShotTingStat/page`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
/**
 * 分页查询
 * @param current 页码
 * @param size  每页数量
 * @param params  查询条件
 * @returns AxiosPromise
 */
export const summaryGetPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptHDTroubleShotTingStat/summaryGetPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const saveSubmitInterface = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rptHDTroubleShotTingStat/saveSubmitInterface`,
    method: 'post',
    data: row
  })
}
export const detail = (data) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptHDTroubleShotTingStat/detail`,
    method: 'get',
    params: data
  })
}

export const summaryClick = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rptHDTroubleShotTingStat/summaryClick`,
    method: 'post',
    data: row
  })
}

export const submitSummary = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rptHDTroubleShotTingStat/submitSummary`,
    method: 'post',
    data
  })
}

/**
 * 分页查询
 * @param current 页码
 * @param size  每页数量
 * @param params  查询条件
 * @returns AxiosPromise
 */
export const auditPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptHDTroubleShotTingStat/auditPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const summaryClickDataState = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rptHDTroubleShotTingStat/summaryClickDataState`,
    method: 'post',
    data
  })
}


export const rejectSendingMessage = (row,value,templateCode) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rptHDTroubleShotTingStat/rejectSendingMessage`,
    method: 'post',
    data:row,
    params:{
      value,
      templateCode
    }
  })
}

export const getListData = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rptHDTroubleShotTingStat/listDataIds`,
    method: 'post',
    params: {
      ids,
    }
  })
}
