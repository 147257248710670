import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/fillReportAllocation/getPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
