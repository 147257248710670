var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isDetail
        ? [
            _vm.isStorage == 1
              ? _c("el-input", {
                  attrs: {
                    placeholder: "用于展示关联表单的属性，且数据不会保存",
                    readonly: "",
                  },
                  model: {
                    value: _vm.innerValue,
                    callback: function ($$v) {
                      _vm.innerValue = $$v
                    },
                    expression: "innerValue",
                  },
                })
              : _vm.isStorage == 2
              ? _c("el-input", {
                  attrs: {
                    placeholder: "用于展示关联表单的属性，且数据同时会保存入库",
                    readonly: "",
                  },
                  model: {
                    value: _vm.innerValue,
                    callback: function ($$v) {
                      _vm.innerValue = $$v
                    },
                    expression: "innerValue",
                  },
                })
              : _vm._e(),
          ]
        : _c("p", [_vm._v(_vm._s(_vm.innerValue))]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }