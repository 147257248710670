import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getProvinceSelector = (params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/region/lazy-tree',
    method: 'get',
    params: {
      ...params,
    }
  })
}
