import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
/** 证照管理- 企业 */
export const enterprisePage = (current, size, params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/grcertificatecontrol/page`,
      method: 'get',
      params: {
        current,
        size,
        ...params
      }
    })
}

export const enterpriseSubmit = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grcertificatecontrol/submit`,
        method: 'post',
        data: params
    })
}

export const enterpriseRemove = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grcertificatecontrol/remove`,
        method: 'post',
        params: {ids}
    })
}

export const enterpriseDetail = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grcertificatecontrol/detail`,
        method: 'post',
        params: {ids}
    })
}

export const enterpriseOCR = (params) => {
    return request({
        url: `/api/sinoma-agent/textSummary/ocrRecognition`,
        method: 'post',
        data: params
    })
}