/*
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-06-04 16:25:04
 */
import request from '@/router/axios';

// 引用项目弹窗的项目列表
export const quoteProjectPage = (current, size, params)=>{
  return request({
    url:'/api/sinoma-sync/sinomaproject/page',
    method:'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

// 引用项目弹窗的引用到项目列表接口
export const quoteProjectSubmit= (data,contractId) => {
  return request({
    url: '/api/sinoma-hse-prj/prjbasicinfo/submitBatch?contractId='+contractId,
    method: 'post',
    data:data
  })
}

// 人员全息-查询用户项目信息
export const prjbasicinfoPageByUserId = (params)=>{
  return request({
    url:'/api/sinoma-hse-prj/prjbasicinfo/userPrj',
    method:'get',
    params
  })
}

// 相关方组织全息-查询第三方公司项目信息
export const prjbasicinfoPageByCompanyId = (params)=>{
  return request({
    url:'/api/sinoma-hse-prj/prjbasicinfo/companyPrj',
    method:'get',
    params
  })
}
// 相关方组织全息-项目相关方下企业证照接口
export const fileSubmit = (data)=>{
  return request({
    url:'/api/sinoma-hse-prj/prjthirdcertificate/submit',
    method:'POST',
    data
  })
}
//项目相关方下人员证照接口
export const fileUserSubmit = (data)=>{
  return request({
    url:'/api/sinoma-hse-prj/prjthirdusercertificate/submit',
    method:'POST',
    data
  })
}
export const getFileList = (params)=>{
  return request({
    url:'/api/sinoma-hse-prj/prjthirdcertificate/list',
    method:'get',
    params
  })
}
export const getFileUserList = (params)=>{
  return request({
    url:'/api/sinoma-hse-prj/prjthirdusercertificate/list',
    method:'get',
    params
  })
}



// 基础信息列表
export const prjbasicinfoPage = (current, size, params)=>{
  return request({
    url:'/api/sinoma-hse-prj/prjbasicinfo/page',
    method:'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
//基础信息详情
export const prjbasicinfoDetail = (params)=>{
  return request({
    url:'/api/sinoma-hse-prj/prjbasicinfo/detail',
    method:'get',
    params: {
      ...params,
    }
  })
}


//新增项目列表
export const prjbasicinfoSubmit= (params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjbasicinfo/submit',
    method: 'post',
    data:params
  })
}

//项目角色人员列表——不分页
export const prjroleuserList = (params)=>{
  return request({
    url:'/api/sinoma-hse-prj/prjroleuser/list',
    method:'get',
    params: {
      ...params,
    }
  })
}
//系统角色
export const classificationRoleList = (params)=>{
  return request({
    url:'/api/sinoma-system/role/list',
    method:'get',
    params: {
      ...params,
    }
  })
}

//项目角色新增
export const prjroleuserSubmit= (params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjroleuser/batchSubmit',
    method: 'post',
    data:params
  })
}
//删除角色
export const prjroleuserRemove= (params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjroleuser/remove',
    method: 'post',
    params
  })
}


//项目架构左侧数据——不分页
export const prjstructureList = (params)=>{
  return request({
    url:'/api/sinoma-hse-prj/prjstructure/list',
    method:'get',
    params: {
      ...params,
    }
  })
}
//新增项目架构
export const prjstructureSubmit= (params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjstructure/submit',
    method: 'post',
    data:params
  })
}
//删除组织架构
export const prjstructureRemove= (params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjstructure/remove',
    method: 'post',
    params
  })
}


//项目架构人员列表——不分页
export const prjstructureuserList = (params)=>{
  return request({
    url:'/api/sinoma-hse-prj/prjstructureuser/list',
    method:'get',
    params: {
      ...params,
    }
  })
}
//删除项目架构人员
export const prjstructureuserRemove= (params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjstructureuser/remove',
    method: 'post',
    params
  })
}

//项目组织人员新增
export const prjstructureuserSubmit= (params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjstructureuser/batchSubmit',
    method: 'post',
    data:params
  })
}

//相关方组织--不分页
export const prjthirdcompanyPage = (params)=>{
  return request({
    url:'/api/sinoma-hse-prj/prjthirdcompany/list',
    method:'get',
    params: {
      ...params,
    }
  })
}

//相关方组织新增
export const prjthirdcompanySubmit= (params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjthirdcompany/submit',
    method: 'post',
    data:params
  })
}
//相关方组织新增
export const prjthirdcompanyDetail= (params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjthirdcompany/detail',
    method: 'GET',
    params
  })
}
//相关方组织批量新增
export const prjthirdcompanyBatchSubmit= (params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjthirdcompany/batchSubmit',
    method: 'post',
    data:params
  })
}

//删除相关方组织
export const prjthirdcompanyRemove= (params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjthirdcompany/remove',
    method: 'post',
    params
  })
}

//项目相关方人员--不分页
export const prjthirduserList = (params)=>{
  return request({
    url:'/api/sinoma-hse-prj/prjthirduser/list',
    method:'get',
    params
  })
}
//项目相关方人员--分页
export const prjthirduserPage = (params)=>{
  return request({
    url:'/api/sinoma-hse-prj/prjthirduser/page',
    method:'get',
    params
  })
}

//项目相关方人员新增
export const prjthirduserSubmit= (params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjthirduser/submit',
    method: 'post',
    data:params
  })
}
//工种
export const stpostList1= (dept_id) => {
  return request({
    url: `/api/sinoma-hse-prj/sinoma-hse-prj/stpost/typeTree?organizationId=${dept_id}`,
    method: 'get',
  })
}

//项目相关方人员批量新增
export const prjthirduserBatchSubmit = (row) => {
  return request({
    url: '/api/sinoma-hse-prj/prjthirduser/batchSubmit',
    method: 'post',
    data: row
  })
}
//项目相关方人员组织
export const prjthirduserRemove= (params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjthirduser/remove',
    method: 'post',
    params
  })
}

// 项目二维码获取
export const prjQrcode= (params) => {
  return request({
    url: '/api/sinoma-auth/wx/acode',
    method: 'get',
    params
  })
}

// 组织人员详情
export const prjstructureuserDetail= (params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjthirduser/detail',
    method: 'get',
    params
  })
}

// 项目相关方人员批量新增
export const prjthirduserDetail = (params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjthirduser/detail',
    method: 'get',
    params
  })
}

// 项目相关方人员批量新增
export const queryProjectAreaCategoryByDeptId = (params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjbasicinfo/queryProjectAreaCategoryByDeptId',
    method: 'get',
    params
  })
}
// 视频监控（新） 树
export const projectAreaTree = () => {
  return request({
    url: '/api/sinoma-hse-prj/prjbasicinfo/projectAreaTree',
    method: 'get',
  })
}

//项目组织人员新增
export const prjstructureuserSubmitFile= (params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjstructureuser/submit',
    method: 'post',
    data:params
  })
}
//统计总数
export const userCountByType= (params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjthirduser/userTypeCount',
    method: 'get',
    params
  })
}

//项目架构左侧数据——树结构
export const prjstructureTree = (params)=>{
  return request({
    url:'/api/sinoma-hse-prj/prjstructure/tree',
    method:'get',
    params: {
      ...params,
    }
  })
}
