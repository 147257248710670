/*
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-02-01 16:46:45
 */
import request from '@/router/axios';
import ServerNameEnum from '../../util/ServerNameEnum'
/** 区域分类树-接口 */
export const getAreaClassTree = ( params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisareatype/tree',
    method: 'get',
    params: {
      ...params,
    }
  })
}

export const getClassSubmit = ( params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisareatype/submit',
    method: 'post',
    data: params,
  })
}

export const getClassRemove = ( ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisareatype/remove',
    method: 'post',
    params: {ids},
  })
}

/** 项目区域-列表接口 */
export const getAreaClassPage = (current,size,params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisarea/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}/** 项目区域-列表接口 */
export const getAreaPage = (current,size,params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisarea/areaPage',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
/** 项目区域-列表接口 */
export const getAreaClassList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisarea/list',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const getAreaClassLinSubmit = ( params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisarea/submit',
    method: 'post',
    data: params,
  })
}

export const  getAreaClassLinRemove = ( ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisarea/remove',
    method: 'post',
    params: {ids},
  })
}

export const  getAreaClassLinDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisarea/detail',
    method: 'get',
    params: {id},
  })
}

// 项目区域负责人
export const  prjAreaUserPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisareauser/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  })
}

export const  prjAreaUserSubmit = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisareauser/submit',
    method: 'post',
    data: {
      ...params
    },
  })
}

export const  prjAreaUserRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisareauser/remove',
    method: 'post',
    params: {ids}
  })
}
//人员清单删除 风险管控使用
export const  pmRiskUserRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/pmRiskUser/remove',
    method: 'post',
    params: {ids}
  })
}
//人员清单提交 风险管控使用
export const  pmRiskUserSubmit = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/pmRiskUser/submit',
    method: 'post',
    data: {
      ...params
    },
  })
}
//人员清单列表 风险管控使用
export const  pmRiskUserPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/pmRiskUser/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  })
}

export const  prjAreaUserList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisareauser/list',
    method: 'get',
    params
  })
}
// 区域负责人end

/* 区域检查清单 */
// 分类
export const  prjAreaCheckTypeTree = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisareachecklisttype/tree',
    method: 'get',
    params
  })
}

export const  prjAreaCheckTypeSubmit = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisareachecklisttype/submit',
    method: 'post',
    data: params
  })
}

export const  prjAreaCheckTypeRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisareachecklisttype/remove',
    method: 'post',
    params: {ids}
  })
}

// 检查清单
export const  prjAreaCheckPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisareachecklist/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  })
}

export const  prjAreaCheckSubmit = (params,prjAreaId,parentTypeId) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisareachecklist/batchSave',
    method: 'post',
    data: params,
    params: {prjAreaId,parentTypeId}
  })
}
export const  prjAreaCheckRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjbasisareachecklist/remove',
    method: 'post',
    params: {ids}
  })
}
/* 区域检查清单end */

/**
 * 区域检查记录
 */
export const  prjAreaCheckRecordAdd = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdchecktask/submitByArea',
    method: 'post',
    data: params
  })
}
