import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/menu/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getLazyList = (parentId, current, size,params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/menu/lazy-list',
    method: 'get',
    params: {
      ...params,
      parentId,
      current,
      size,
    }
  })
}


// 获取菜单列表（下拉框）
export const getSelectorAll = data => {
  return request({
    url: '/api/system/Menu/Selector/All',
    method: 'GET',
    data
  })
}

export const getLazyAppList = (parentId, current, size,params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/menu/lazy-app-list',
    method: 'get',
    params: {
      ...params,
      parentId,
      current,
      size,
    }
  })
}

export const getLazyMenuList = (parentId, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/menu/lazy-menu-list',
    method: 'get',
    params: {
      ...params,
      parentId
    }
  })
}

export const getMenuList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/menu/menu-list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getMenuTree = (tenantId) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/menu/tree',
    method: 'get',
    params: {
      tenantId,
    }
  })
}

export const getAppMenuTree = (tenantId) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/menu/appTree',
    method: 'get',
    params: {
      tenantId,
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/menu/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/menu/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/menu/submit',
    method: 'post',
    data: row
  })
}

export const getMenu = (id) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/menu/detail',
    method: 'get',
    params: {
      id,
    }
  })
}

export const getTopMenu = () => request({
  url: ServerNameEnum.SERVER_SYSTEM_+'/menu/top-menu',
  method: 'get'
});

export const getRoutes = (topMenuId) => request({
  url: ServerNameEnum.SERVER_SYSTEM_+'/menu/routes',
  method: 'get',
  params: {
    topMenuId,
  }
});

export const getMenuByParentId = (parentId) => request({
  url: ServerNameEnum.SERVER_SYSTEM_+'/menu/getMenuByParentId',
  method: 'get',
  params: {
    parentId,
  }
});
