import request from '@/router/axios';

export const getList = (params) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/hdhiddendanger/page',
    method: 'get',
    params
  })
}
export const getPage = (params) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/hdhiddendanger/assessment/page',
    method: 'get',
    params
  })
}
export const remove = (data) => { //删除
  return request({
    url: '/api/sinoma-hse-prj/hdassessment/remove',
    method: 'POST',
    data
  })
}
export const detail = (params) => { //详情
  return request({
    url: '/api/sinoma-hse-prj/hdassessment/detail',
    method: 'get',
    params
  })
}
export const save = (data) => { //保存
  return request({
    url: '/api/sinoma-hse-prj/hdassessment/save',
    method: 'post',
    data
  })
}
export const update = (data) => { //编辑
  return request({
    url: '/api/sinoma-hse-prj/hdassessment/update',
    method: 'post',
    data
  })
}

export const HD_SOURCE_TYPE = (key) => {
  return request({
    url: `/api/sinoma-system/dict-biz/dictionary?code=${key}`,
    method: 'get',
  })
}
export const hd_level = () => {
  return request({
    url: `/api/sinoma-system/dict-biz/dictionary?code=hd_level`,
    method: 'get',
  })
}
