import {
  setStore,
  getStore,
  removeStore
} from '@/util/store'
import website from '@/config/website'

const common = {

  state: {
    language: getStore({name: 'language'}) || 'zh',
    isCollapse: getStore({name: 'isCollapse'}) || false,
    isFullScren: false,
    isMenu: true,
    isShade: false,
    screen: -1,
    isLock: getStore({name: 'isLock'}) || false,
    showTag: true,
    showDebug: true,
    showCollapse: true,
    showLock: true,
    showFullScren: true,
    showTheme: true,
    showMenu: true,
    showColor: true,
    layoutType: getStore({name: 'layoutType'}) || 'functional',
    slideClass: getStore({name: 'slideClass'}) || 'dark',
    themeClass: getStore({name: 'themeClass'}) || 'blue',
    themeColor: getStore({name: 'themeColor'}) || '#1890ff',
    tagsView: getStore({ name: 'tagsView' }) || false,
    tagsIcon: getStore({name: 'tagsIcon'}) || true,
    showLanguage: getStore({name: 'showLanguage'}) || true,
    showSearch: getStore({ name: 'showSearch' }) || true,
    showStripe: getStore({ name: 'showStripe' }) || true,
    useCache: getStore({name: 'useCache'}) || true,
    colorName: getStore({name: 'colorName'}) || '#409EFF',
    themeName: getStore({name: 'themeName'}) || 'dark',
    lockPasswd: getStore({name: 'lockPasswd'}) || '',
    allTable: [],
    formItemList: [],
    subTable: [],
    dataBase: '',
    hasTable: true,
    website: website,
    showShortcutMenu: false,
    portalId: '',
    timeDimension: '',
    visualDevData: getStore({name: 'visualDevData'}) || [],
    posData: getStore({name: 'posData'}) || [],
    roleData: getStore({name: 'roleData'}) || [],
    userData: getStore({name: 'userData'}) || [],
    userInfoObj: getStore({ name: 'userInfoObj' }) || {},
    report: getStore({ name: 'report' }) || false,
    openmenu: {},
    openChatState: false,
    refresh: getStore({name: 'refresh'}) || false
    // oldForm:{},
    // newForm:{}
  },
  mutations: {
    SET_REPORT:(state,report) => {
      state.report = report
      setStore({
        name: 'report',
        content: state.report,
      })
    },
    SET_REFRESH: (state, refresh) => {
      state.refresh = refresh;
      setStore({
        name: 'refresh',
        content: state.refresh
      })
    },
    SET_USERDATA: (state, userData) => {
      state.userData = userData;
      setStore({
        name: 'userData',
        content: state.userData
      })
    },
    SET_VISUALDEVDATA: (state, visualDevData) => {
      state.visualDevData = visualDevData;
      setStore({
        name: 'visualDevData',
        content: state.visualDevData
      })
    },
    SET_POSDATA: (state, posData) => {
      state.posData = posData
      setStore({
        name: 'posData',
        content: state.posData
      })
    },
    SET_ROLEDATA: (state, roleData) => {
      state.roleData = roleData;
      setStore({
        name: 'roleData',
        content: state.roleData
      })
    },
    // SET_OLDFORM: (state, oldForm) => {
    //   state.oldForm = oldForm
    // },
    // SET_NEWFORM: (state, newForm) => {
    //   state.newForm = newForm
    // },
    SET_DIMENSION: (state, timeDimension) => {
      state.timeDimension = timeDimension
    },
    SET_PORTALID: (state, portalId) => {
      state.portalId = portalId
    },
    SET_ALLTABLE: (state, allTable) => {
      state.allTable = allTable
    },
    SET_TABLE: (state, hasTable) => {
      state.hasTable = hasTable
    },
    UPDATE_FORMITEM_LIST: (state, formItemList) => {
      state.formItemList = formItemList
    },
    UPDATE_SUB_TABLE: (state, subTable) => {
      state.subTable = subTable
    },
    SET_DATABASE: (state, dataBase) => {
      state.dataBase = dataBase
    },
    SET_TAGSICON: (state, tagsIcon) => {
      state.tagsIcon = tagsIcon
      setStore({
        name: 'tagsIcon',
        content: state.tagsIcon
      })
    },
    SET_THEMECOLOR: (state, themeColor) => {
      state.themeColor = themeColor
      setStore({
        name: 'themeColor',
        content: state.themeColor
      })
    },
    SET_SHOWLANGUAGE: (state, showLanguage) => {
      state.showLanguage = showLanguage
      setStore({
        name: 'showLanguage',
        content: state.showLanguage
      })
    },
    SET_USECACHE: (state, useCache) => {
      state.useCache = useCache
      setStore({
        name: 'useCache',
        content: state.useCache
      })
    },
    SET_SHOWSEARCH: (state, showSearch) => {
      state.showSearch = showSearch
      setStore({
        name: 'showSearch',
        content: state.showSearch
      })
    },
    SET_SHOWSTRIPE: (state, showStripe) => {
      state.showStripe = showStripe
      setStore({
        name: 'showStripe',
        content: state.showStripe
      })
    },
    SET_TAGSVIEW: (state, tagsView) => {
      state.tagsView = tagsView
      setStore({
        name: 'tagsView',
        content: state.tagsView
      })
    },
    SET_THEMECLASS: (state, themeClass) => {
      state.themeClass = themeClass
      setStore({
        name: 'themeClass',
        content: state.themeClass
      })
    },
    SET_SLIDECLASS: (state, slideClass) => {
      state.slideClass = slideClass
      setStore({
        name: 'slideClass',
        content: state.slideClass
      })
    },
    SET_LAYOUTTYPE: (state, layoutType) => {
      state.layoutType = layoutType
      setStore({
        name: 'layoutType',
        content: state.layoutType
      })
    },
    SET_SHORTCUTMENU: (state) => {
      state.showShortcutMenu = !state.showShortcutMenu
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language
      setStore({
        name: 'language',
        content: state.language
      })
    },
    SET_SHADE: (state, active) => {
      state.isShade = active;
    },
    SET_COLLAPSE: (state, isCollapse) => {
      state.isCollapse = isCollapse;
      setStore({
        name: 'isCollapse',
        content: state.isCollapse
      })
    },
    SET_FULLSCREN: (state) => {
      state.isFullScren = !state.isFullScren;
    },
    SET_IS_MENU: (state, menu) => {
      state.isMenu = menu;
    },
    SET_LOCK: (state) => {
      state.isLock = true;
      setStore({
        name: 'isLock',
        content: state.isLock,
        type: 'session'
      })
    },
    SET_SCREEN: (state, screen) => {
      state.screen = screen;
    },
    SET_COLOR_NAME: (state, colorName) => {
      state.colorName = colorName;
      setStore({
        name: 'colorName',
        content: state.colorName,
      })
    },
    SET_THEME_NAME: (state, themeName) => {
      state.themeName = themeName;
      setStore({
        name: 'themeName',
        content: state.themeName,
      })
    },
    SET_LOCK_PASSWD: (state, lockPasswd) => {
      state.lockPasswd = lockPasswd;
      setStore({
        name: 'lockPasswd',
        content: state.lockPasswd,
        type: 'session'
      })
    },
    CLEAR_LOCK: (state) => {
      state.isLock = false;
      state.lockPasswd = '';
      removeStore({
        name: 'lockPasswd',
        type: 'session'
      });
      removeStore({
        name: 'isLock',
        type: 'session'
      });
    },
    SET_OPENMENU:(state,openmenu) => {
      state.openmenu = openmenu
      setStore({
        name: 'openmenu',
        content: state.openmenu,
      })
    },
    SET_OPENCHATSTATE:(state,openChatState) => {
      state.openChatState = openChatState
      console.log('openChatState:',state.openChatState)
      setStore({
        name: 'openChatState',
        content: state.openChatState,
      })
    },
    SET_USERINFOOBJ:(state,userInfoObj) => {
      state.userInfoObj = userInfoObj
      setStore({
        name: 'userInfoObj',
        content: state.userInfoObj,
      })
    },

  }
}
export default common
