var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "avue-top" },
    [
      _vm.layoutType == "classic" || _vm.layoutType == "blend"
        ? _c("i", {
            class: _vm.isCollapse ? "el-icon-s-unfold" : "el-icon-s-fold",
            staticStyle: { "font-size": "20px", cursor: "pointer" },
            on: { click: _vm.changeCollapse },
          })
        : _vm._e(),
      _vm.layoutType == "functional"
        ? _c("div", { staticClass: "topSidderLogo" }, [
            _vm.language == "zh"
              ? _c("img", {
                  attrs: { src: require("@/assets/images/sidder-logo.png") },
                })
              : _vm._e(),
            _vm.language == "en"
              ? _c("img", {
                  attrs: { src: require("@/assets/images/sidder-logo-en.png") },
                })
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.layoutType == "functional" && _vm.topMeanShow == true
        ? _c("topsidder", { ref: "topSidder" })
        : _vm._e(),
      _vm.layoutType == "blend" && _vm.topMeanShow == true
        ? _c("top-menu", { ref: "topMenu" })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "top-bar__title" },
        [
          _c("el-collapse-transition", [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.topMeanShow == false,
                    expression: "topMeanShow == false",
                  },
                ],
              },
              [
                _vm.showSearch
                  ? _c(
                      "span",
                      { staticClass: "top-bar__item topSearchInput" },
                      [_c("top-search", { on: { setMean: _vm.setMean } })],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "top-bar__right" },
        [
          _vm.showSearch
            ? _c(
                "el-tooltip",
                {
                  attrs: {
                    content: _vm.$t("tagsView.search"),
                    effect: "dark",
                    placement: "bottom",
                  },
                },
                [
                  _c("div", { staticClass: "top-bar__item" }, [
                    _c("i", {
                      staticClass: "el-icon-search",
                      staticStyle: { cursor: "pointer" },
                      on: { click: _vm.changeMeanStatus },
                    }),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "el-tooltip",
            {
              attrs: {
                content: "切换旧版",
                effect: "dark",
                placement: "bottom",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "top-bar__item",
                  on: { click: _vm.switchSystem },
                },
                [
                  _c("i", {
                    staticClass: "iconfont icon-qiehuanxitong",
                    staticStyle: { cursor: "pointer" },
                    attrs: { slot: "reference" },
                    slot: "reference",
                  }),
                ]
              ),
            ]
          ),
          _c(
            "el-tooltip",
            {
              attrs: {
                content: "微信小程序",
                effect: "dark",
                placement: "bottom",
              },
            },
            [
              _c("div", { staticClass: "top-bar__item" }, [
                _c("i", {
                  staticClass: "el-icon-mobile-phone",
                  staticStyle: { cursor: "pointer" },
                  attrs: { slot: "reference" },
                  on: {
                    click: function ($event) {
                      _vm.phoneShow = true
                    },
                  },
                  slot: "reference",
                }),
              ]),
            ]
          ),
          _c(
            "el-tooltip",
            {
              attrs: {
                content: _vm.$t("tagsView.feedback"),
                effect: "dark",
                placement: "bottom",
              },
            },
            [
              _c("div", { staticClass: "top-bar__item" }, [
                _c("i", {
                  staticClass: "el-icon-s-order",
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.feedback },
                }),
              ]),
            ]
          ),
          _c(
            "el-tooltip",
            {
              attrs: {
                content: _vm.$t("tagsView.messageSend"),
                effect: "dark",
                placement: "bottom",
              },
            },
            [
              _c(
                "div",
                { staticClass: "top-bar__item" },
                [
                  _c(
                    "el-badge",
                    { staticClass: "item", attrs: { "is-dot": _vm.isRead } },
                    [
                      _c("i", {
                        staticClass: "el-icon-message",
                        staticStyle: { cursor: "pointer" },
                        on: { click: _vm.messageSend },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-tooltip",
            {
              attrs: {
                content: _vm.$t("tagsView.shortcutMenu"),
                effect: "dark",
                placement: "bottom",
              },
            },
            [
              _c("div", { staticClass: "top-bar__item" }, [
                _c("i", {
                  staticClass: "el-icon-menu",
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.changeShortcutStatus },
                }),
              ]),
            ]
          ),
          _vm.showFullScren
            ? _c(
                "el-tooltip",
                {
                  attrs: {
                    content: _vm.isFullScren
                      ? _vm.$t("tagsView.screenfullF")
                      : _vm.$t("tagsView.screenfull"),
                    effect: "dark",
                    placement: "bottom",
                  },
                },
                [
                  _c("div", { staticClass: "top-bar__item" }, [
                    _c("i", {
                      class: _vm.isFullScren
                        ? "icon-tuichuquanping"
                        : "icon-quanping",
                      staticStyle: { cursor: "pointer" },
                      on: { click: _vm.handleScreen },
                    }),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "el-link",
            { attrs: { underline: false }, on: { click: _vm.portrait } },
            [
              _c("span", { staticClass: "el-dropdown-link" }, [
                _vm.userInfo.avatar
                  ? _c("img", {
                      staticClass: "top-bar__img",
                      staticStyle: { cursor: "pointer" },
                      attrs: { src: _vm.userInfo.avatar },
                    })
                  : _c("img", {
                      staticClass: "top-bar__img",
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        src: require("@/assets/images/default_avatar.png"),
                      },
                    }),
              ]),
            ]
          ),
          _c(
            "el-drawer",
            {
              attrs: {
                direction: _vm.leftDirection,
                modal: false,
                visible: _vm.leftDrawer,
                "with-header": false,
                "custom-class": "ht-drawer",
                size: "23%",
                title: "菜单栏",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.leftDrawer = $event
                },
              },
            },
            [
              _vm.isAllMenu == true
                ? _c(
                    "div",
                    { staticClass: "menu_title" },
                    _vm._l(_vm.menuData, function (item, index) {
                      return _c("div", { key: index }, [
                        _c("div", { staticClass: "menu_fir_title" }, [
                          item.name != "协同办公"
                            ? _c(
                                "div",
                                [
                                  _c("p", [_vm._v("·" + _vm._s(item.name))]),
                                  _vm._l(item.children, function (i, idx) {
                                    return _c(
                                      "div",
                                      {
                                        key: idx,
                                        staticClass: "menu_sed_title",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.getMenuGo(i)
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(i.name))]
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                          item.name == "协同办公"
                            ? _c(
                                "div",
                                _vm._l(item.children, function (i, idx) {
                                  return _c(
                                    "div",
                                    { key: idx },
                                    [
                                      _c("p", [_vm._v("·" + _vm._s(i.name))]),
                                      _vm._l(i.children, function (it, idt) {
                                        return _c(
                                          "div",
                                          {
                                            key: idt,
                                            staticClass: "menu_sed_title",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.getMenuGo(it)
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(it.name))]
                                            ),
                                          ]
                                        )
                                      }),
                                    ],
                                    2
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                        ]),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _vm.isAllMenu == false
                ? _c(
                    "div",
                    { staticClass: "menu_title" },
                    [
                      _vm.isThreeShow == true
                        ? _c("p", { staticClass: "menu_First_p" }, [
                            _vm._v("·" + _vm._s(_vm.menuName)),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.menuData, function (item, index) {
                        return _c("div", { key: index }, [
                          _vm.isThreeShow == true
                            ? _c("div", [
                                _c("div", { staticClass: "menu_First_title" }, [
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.getMenuGo(item)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.isThreeShow == false
                            ? _c(
                                "div",
                                { staticClass: "menu_fir_title" },
                                [
                                  _c("p", [_vm._v("·" + _vm._s(item.name))]),
                                  _vm._l(item.children, function (i, idx) {
                                    return _c(
                                      "div",
                                      {
                                        key: idx,
                                        staticClass: "menu_sed_title",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.getMenuGo(i)
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(i.name))]
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                        ])
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "el-dialog",
            {
              staticClass: "phoneBox",
              attrs: {
                title: "微信小程序",
                visible: _vm.phoneShow,
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.phoneShow = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "con" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { staticClass: "left", attrs: { span: 12 } },
                        [
                          _c("img", {
                            staticClass: "conLeftImg",
                            attrs: {
                              src: require("@/assets/images/phone_em.png"),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "right", attrs: { span: 12 } },
                        [
                          _c("p", { staticClass: "name" }, [
                            _vm._v("微信小程序"),
                          ]),
                          _c("div", { staticClass: "ewmIcon" }, [
                            _c(
                              "div",
                              { staticClass: "left", attrs: { span: 8 } },
                              [
                                _c("div", { staticClass: "iconItem" }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/weChart.png"),
                                      alt: "",
                                      srcset: "",
                                    },
                                  }),
                                ]),
                                _c("img", {
                                  staticClass: "ewm",
                                  attrs: {
                                    src: require("@/assets/images/wx_applet.jpg"),
                                    alt: "",
                                  },
                                }),
                                _c("p", { staticClass: "title" }, [
                                  _vm._v("扫描二维码进入微信小程序"),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-drawer",
            {
              attrs: {
                direction: _vm.direction,
                modal: false,
                visible: _vm.drawer,
                "with-header": false,
                size: "350px",
                title: "个人设置",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "card_posit" },
                [
                  _c("div", { staticClass: "person_top" }, [
                    _c("div", { staticClass: "person_img" }, [
                      _vm.userInfo.avatar
                        ? _c("img", {
                            staticClass: "image",
                            attrs: { src: _vm.userInfo.avatar },
                          })
                        : _c("img", {
                            staticClass: "image",
                            attrs: {
                              src: require("@/assets/images/default_avatar.png"),
                            },
                          }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "person_title" },
                      [
                        _c("span", { staticClass: "person_account" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.userInfo.realName) +
                              "\n            "
                          ),
                        ]),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.deptNoChange
                                ? _vm.deptStart
                                : _vm.dept.title,
                              placement: "top-start",
                            },
                          },
                          [
                            _vm.deptNoChange
                              ? _c("span", { staticClass: "person_uid" }, [
                                  _vm._v(_vm._s(_vm.deptStart)),
                                ])
                              : _c("span", { staticClass: "person_uid" }, [
                                  _vm._v(_vm._s(_vm.dept.title)),
                                ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("i", {
                      staticClass: "el-icon-s-tools",
                      staticStyle: { "font-size": "24px", cursor: "pointer" },
                      style: { color: _vm.themeColor },
                      on: { click: _vm.clickUser },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "deptBox" },
                    [
                      _c("avue-input-tree", {
                        attrs: {
                          "default-expand-all": "",
                          placeholder: "请选择所属部门",
                          props: _vm.inputTreeProps,
                          dic: _vm.nowUserDeptList,
                        },
                        on: { change: _vm.chooseDept },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ item }) {
                              return [
                                _c("div", { staticClass: "tree-node" }, [
                                  item.isDefaultDept == 1
                                    ? _c("i", {
                                        staticClass: "el-icon-s-flag",
                                        staticStyle: { color: "#c5211b" },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      style: {
                                        "margin-left":
                                          item.isDefaultDept == 1
                                            ? "8px"
                                            : "22px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.title) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.deptId,
                          callback: function ($$v) {
                            _vm.deptId = $$v
                          },
                          expression: "deptId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("top-specific", { ref: "specific" }),
                  _c(
                    "div",
                    { staticClass: "logout" },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { size: "medium", type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.logout.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("navbar.logOut")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("top-lang", {
        attrs: { dialogVisible: _vm.dialogVisible },
        on: { hideLang: _vm.hideLang },
      }),
      _c("top-theme", { ref: "wTheme", staticStyle: { display: "none" } }),
      _c("top-logs", { ref: "wLogs", staticStyle: { display: "none" } }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("cip.plat.sys.feedback.field.feedback"),
            visible: _vm.feedbackBox,
            "append-to-body": "",
            "modal-append-to-body": "",
            top: "50px",
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.feedbackBox = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: {
              placeholder: _vm.$t("cip.plat.sys.feedback.msg.suggest"),
              type: "textarea",
            },
            model: {
              value: _vm.feedbackForm.feedback,
              callback: function ($$v) {
                _vm.$set(_vm.feedbackForm, "feedback", $$v)
              },
              expression: "feedbackForm.feedback",
            },
          }),
          _c("br"),
          _c("br"),
          _c("img", {
            attrs: { src: _vm.feedbackForm.screenshot, width: "100%" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-check", type: "primary" },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.submitBtn")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("UserList", {
        ref: "UserList",
        on: { changeTwinkle: _vm.changeTwinkle },
      }),
      _vm.isVisible
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "floating-box",
                class: { expanded: _vm.isExpanded },
                style: {
                  left: _vm.position.x + "px",
                  top: _vm.position.y + "px",
                },
                on: { mousedown: _vm.startDrag },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "toggle-button",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.toggleExpand.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm.isExpanded
                      ? _c("span", [
                          _c("i", { staticClass: "el-icon-zoom-out" }),
                        ])
                      : _c("span", [
                          _c("i", { staticClass: "el-icon-zoom-in" }),
                        ]),
                  ]
                ),
                _c("div", { staticClass: "box-content" }, [
                  _c(
                    "div",
                    {
                      staticClass: "noticeTitle",
                      style: { backgroundColor: _vm.themeColor },
                    },
                    [_vm._v(_vm._s("部署通知：" + _vm.title))]
                  ),
                  _c("div", { staticClass: "noticeContent" }, [
                    _vm._v(_vm._s(_vm.content)),
                  ]),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }