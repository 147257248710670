import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';


// 获取风险类型树
export const getList = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/riskcard/list`,
      method: 'get',
      params
    })
  }
  // 新增或编辑
  export const addList = (data) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/riskcard/submit`,
      method: 'post',
      data
    })
  }
  // 删除列表
  export const deleteList = (data) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/riskcard/remove?ids=${data.ids}`,
      method: 'post',
    })
  }
  // 导出清单
  export const exportList = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/riskcard/exportWord`,
      method: 'get',
      params
    })
  }
  // 获取详情
  export const getDetail = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/riskcard/detail`,
      method: 'get',
      params
    })
  }
  