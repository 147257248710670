<template>
  <div :class="classObj" class="avue-contail">

    <div :class="layoutType" class="avue-layout" ref="avueLayout" :style="hideMenu?'padding-left:0px':''">
      <!-- 左侧导航栏  -->
      <div v-if="layoutType != 'functional' && !hideMenu" :class="isCollapse == true ?'leftWide':'leftNarrow'"
           ref="avueLeft" class="avue-left">
        <div class="logo-content" v-if="!isIframeIn">
          <img v-if="keyCollapse" :src="path">
          <img v-if="!keyCollapse&&language=='zh'" src="@/assets/images/sidder-logo.png">
          <img v-if="!keyCollapse&&language=='en'" src="@/assets/images/sidder-logo-en.png">
        </div>
        <sidebar ref="sidebar"/>
      </div>
      <div class="avue-main">
        <!-- 顶部导航栏 -->
        <top ref="top" v-if="!isIframeIn&&!hideMenu"/>
        <!-- 顶部标签卡 -->
        <tags v-if="!hideMenu"/>
        <shortcutMenu v-if="!isIframeIn&&!hideMenu"></shortcutMenu>
        <transition name="fade-scale" v-if="!isIframeIn&&!hideMenu">
          <search v-show="isSearch" class="avue-view"></search>
        </transition>

        <div v-show="!isSearch" id="avue-view" ref="avueView" :style="dragStyle">
          <keep-alive :include="cachedViews"  >
            <router-view style="height: 100%;"  />
          </keep-alive>
        </div>
      </div>
    </div>
    <div class="avue-shade" @click="showCollapse"></div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import tags from "./tags";
import search from "./search";
import top from "./top/";
import sidebar from "./sidebar/";
import admin from "@/util/admin";
import {validatenull} from "@/util/validate";
import {calcDate} from "@/util/date.js";
import {getStore} from "@/util/store.js";
import Hamburger from './hamburger'
import shortcutMenu from "@/page/index/shortcutMenu";
import {getSysConfig} from "@/api/system/user";
import {getUserInfo} from "@/api/system/user";
import {listData} from "@/api/systemData/abilityDesign";
import {getPostList, getRoleList, getUserSelect} from "@/api/onlineDev/visualDev";

export default {
  components: {
    top,
    tags,
    search,
    sidebar,
    Hamburger,
    shortcutMenu,
  },
  name: "index",
  provide() {
    return {
      index: this
    };
  },
  data() {
    return {
      VUE_APP_BASE_BOTURL:process.env.VUE_APP_BASE_BOTURL,
      VUE_APP_BASE_BOTREDIRECTURL:process.env.VUE_APP_BASE_BOTREDIRECTURL,
      path: '',
      ids: '',
      names: '',
      namesM: "",
      //搜索控制
      isSearch: false,
      //刷新token锁
      refreshLock: false,
      //刷新token的时间
      refreshTime: "",
      clientHeight: "",
      hideMenu: false,
      loginUser: {
        tokensMd: "",
      },
      ws: null, //建立的连接
      timeoutObj: null, //心跳心跳倒计时
      timeoutnum: null, //断开 重连倒计时
      lockReconnect: false, //是否真正建立连接
      avueViewStyleBool:false,
    };
  },
   created() {
    //实时检测刷新token
    this.refreshToken();
    getSysConfig().then(res => {
      this.path = res.data.data.navigation_icon
    })
   this.getInfo()
  },
  mounted() {
    console.log(222)
    this.init();
    this.clientHeight = document.documentElement.clientHeight;
    if (window.location.href.indexOf('hideMenu=true') > -1) {
      this.hideMenu = true
    }

    // 流程详情页内嵌表单删除边框是
    let that = this;
    window.addEventListener('message', function (event) {
      if (event.data.type == 'workFlow') {
        that.avueViewStyleBool= true;
      }
    }, false)
  },
  computed: {
    ...mapGetters(['tagList','userData','deptTree',"isMenu", "isLock", "isCollapse", "website", "menu", "keyCollapse", "slideClass", 'layoutType', 'themeClass', 'isIframeIn', "language", "userInfo", "userInfoObj"]),
    tagsView: {
      get() {
        return this.$store.state.common.tagsView;
      },
    },
    classObj() {
      return {
        ['avue--collapse']: this.keyCollapse,
        [this.layoutType]: true,
        [this.themeClass]: true,
      }
    },
    cachedViews(){
      return [...this.tagList.filter(item=>item.meta.code).map(item=>item.meta.code),'layout']
    },
    dragStyle(){
      return  {
      padding: this.avueViewStyleBool ?'0px' :'',
      overflowY: this.avueViewStyleBool?'auto':'',
      background:this.avueViewStyleBool ?'#f4f5f6' :'',
      boxSizing:this.avueViewStyleBool ?'border-box' :'',
      height:this.avueViewStyleBool ? '100vh' :this.tagsView ? 'calc(100% - 100px)':'calc(100% - 60px)'
      }
    },
  },
  props: [],
  methods: {
   async getInfo(){
      const { userInfo } = this.$store.getters;
     this.loginUser = await  getUserInfo().then((res) => res.data.data)
     this.$store.commit("SET_USERINFOOBJ", this.loginUser);
     this.$store.commit("SET_CURRENT_USER", this.loginUser);
     this.$store.commit("SET_USER_INFO", {
       ...userInfo,
       ...this.loginUser,
     });
     this.initWebpack()
    },
    initWebpack() {
      this.ws = new WebSocket(process.env.VUE_APP_BASE_SYSWEBSOCKET + this.userInfo.tokensMd);
      this.ws.onopen = this.onopen;
      this.ws.onmessage = this.onmessage;
      this.ws.onclose = this.onclose;
      this.ws.onerror = this.onerror;
    },

    start() {
      //开启心跳
      var self = this;
      self.timeoutObj && clearTimeout(self.timeoutObj);
      self.timeoutObj = setInterval(function () {
        //这里发送一个心跳，后端收到后，返回一个心跳消息，
        if (self.ws.readyState == 1) {
          //如果连接正常
          self.ws.send("heartCheck");
        } else {
          //否则重连
          self.ws.close();
          self.ws = null;
          self.reconnect();
          console.log("reconnect ----");
        }
      }, 5000);
    },
    reset() {
      //清除时间
      clearTimeout(this.timeoutObj);
      //重启心跳
      this.start();
    },
    reconnect() {
      //重新连接
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function () {
        //新连接
        that.initWebpack();
        that.lockReconnect = false;
      }, 5000);
    },
    onopen() {
      var msg = JSON.stringify({
        cmd: "enter_chatting",
        token: this.$store.getters.token,
      });
      this.ws.send(msg);
      //开启心跳
      this.start();
    },
    onmessage(e) {
      let that = this;
      var message = JSON.parse(e.data);
      // console.log("onmessage", message)
      if (message.type == "openmenu") {
        // console.log("openmenu", message.menu)
        that.open(message.menu);
      }
      //收到服务器信息，心跳重置
      this.reset();
    },
    open(menu) {
      let menuObj = JSON.parse(menu);
      this.$store.commit("SET_OPENMENU", menuObj);
    },
    onclose(e) {
      var msg = JSON.stringify({
        cmd: "out_chatting",
        token: this.$store.getters.token,
      });
      this.ws.send(msg);
      //重连
      this.reconnect();
    },
    onerror(e) {
      console.log("出现错误");
      //重连
      this.reconnect();
    },
    showCollapse() {
      this.$store.commit("SET_COLLAPSE");
    },
    // 初始化
    init() {
      this.$store.commit("SET_SCREEN", admin.getScreen());
      window.onresize = () => {
        setTimeout(() => {
          this.$store.commit("SET_SCREEN", admin.getScreen());
        }, 0);
      };
      this.$store.dispatch("FlowRoutes").then(() => {});
      if(this.deptTree.length == 0){
        this.$store.dispatch('GetDeptTree').then((res) => {
          let data = res;
          this.recursion(data)
          this.$store.commit('SET_DEPT_TREE', data)
        })
      }
      // 获取在线开发配置项
      listData(1, 500).then((res) => {
        let data = res.data.data.records;
        this.$store.commit('SET_VISUALDEVDATA', data)
      });
      // 岗位数据
      getPostList(1, 500).then((res) => {
        let data = res.data.data.records;
        this.$store.commit('SET_POSDATA', data)
      })
      // 角色数据
      getRoleList(1, 500).then((res) => {
        let data = res.data.data.records;
        this.$store.commit('SET_ROLEDATA', data)
      });
      // 用户数据
      if(this.userData.length == 0){//取缓存数据判断
        getUserSelect(1, 500).then((res) => {
          let data = res.data.data.records;
          this.$store.commit('SET_USERDATA', data)
        });
      }
    },
    /**
     * 递归树
     * @param arr
     * @returns {*|*[]}
     */

    recursion(arr) {
      arr.forEach((item) => {
        // if(item.parentId == 0) this.ids = ''
        let tmp = this.ids + "," + item.id;
        // let tmpName = this.names + "/" + item.title;
        let tmpNames = this.namesM + "," + item.title;
        item.organizeIds = tmp.slice(1).split(",");
        // item.organize = tmpName.slice(1);
        item.unitId = item.id;
        item.organizeName = tmpNames.slice(1).split(",");
        item.organize = item.organizeName.join('/');
        item.fullName = item.title;
        item.label = item.title;
        if (item.children) {
          this.ids += "," + item.id;
          this.names += "/" + item.title;
          this.namesM += "," + item.title;
          this.recursion(item.children);
          this.ids = this.ids.slice(0, this.ids.lastIndexOf(","));
          this.names = this.names.slice(0, this.names.lastIndexOf("/"));
          this.namesM = this.namesM.slice(0, this.namesM.lastIndexOf(","));
        }
      });
    },

    funTree(arr) {
      let data = JSON.parse(JSON.stringify(arr))
      let newData = []
      data.forEach((item) => {
        item.children.forEach((items) => {
          items.fullName = items.title;
          if (item.id == items.parentId) {
            let list = new Array();
            items.organize = item.title + '/' + items.title;
            list.push(item.id, items.id);
            items.organizeIds = list;
          }
        })
      })
      newData = data;
      return newData
    },
    //打开菜单
    openMenu(item = {}, clickMenu = false) {
      this.$store.dispatch("GetMenu", item.id).then(data => {
        if (data.length !== 0) {
          this.$router.$avueRouter.formatRoutes(data, true);
        }
        if (clickMenu) {
          //当点击顶部菜单后默认打开第一个菜单
          if (!this.validatenull(item)) {
            let itemActive = {},
              childItemActive = 0;
            if (item.path) {
              itemActive = item;
            } else {
              let activeMen = this.menu[childItemActive];
              if (activeMen.children) {
                while (true) {
                  if (activeMen.children.length === 0) {
                    itemActive = activeMen;
                    break
                  }
                  activeMen = activeMen.children[childItemActive];
                }
              }
            }
            this.$store.commit('SET_MENU_ID', item);
            this.$router.push({
              path: this.$router.$avueRouter.getPath({
                name: (itemActive.label || itemActive.name),
                src: itemActive.path
              }, itemActive.meta)
            });
          }
        }

      });
    },
    // 定时检测token
    refreshToken() {
      this.refreshTime = setInterval(() => {
        const token = getStore({
          name: "token",
          debug: true
        }) || {};
        // console.log("297",token)
        const date = calcDate(token.datetime, new Date().getTime());
        // console.log("299",date.seconds)
        if (validatenull(date)) return;
        if (date.seconds >= this.website.tokenTime && !this.refreshLock) {
          this.refreshLock = true;
          this.$store
            .dispatch("refreshToken")
            .then(() => {
              this.refreshLock = false;
            })
            .catch(() => {
              this.refreshLock = false;
            });
        }
        // console.log("310",this.refreshTime)
      }, 10000);
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    }
  }
};
</script>
<style lang="scss" scoped>
#avue-view {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 16px;
  background: #f4f5f6;
  box-sizing: border-box;
}

.avue-left {
  box-sizing: border-box;
}

.logo-content {
  width: 100%;
  height: 60px;

  img {
    width: 100%;
    height: 100%;
  }
}

.hamburger-container {
  position: absolute;
  width: 100%;
  bottom: 5px;
  box-sizing: border-box;
  text-align: right;
}

.avue--collapse {
  .hamburger-container {
    text-align: center;
  }
}

.functional {
  padding-left: 0px !important;
}
</style>
