
import Layout from "@/page/index/";

export default [
  //相关方组织全息
  {
    path: "/relatedOrgHolography",
    component: Layout,
    name: "相关方组织全息",
    children: [
      {
        path: "/business/safe/relatedOrgHolography/detail",
        name: "相关方组织全息详情",
        component: () =>
          import("@/views/business/safe/relatedOrgHolography/form.vue"),

        meta: {
          title: "相关方组织全息详情",
          icon: "",
        },
      },
      {
        path: "/business/safe/relatedOrgHolography/edit",
        name: "相关方组织全息编辑",
        component: () =>
          import("@/views/business/safe/relatedOrgHolography/form.vue"),

        meta: {
          title: "相关方组织全息编辑",
          icon: "",
        },
      },
    ],
  },
];
