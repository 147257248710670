import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/appVersion/selectPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getValid = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/appVersion/stats`,
    method: "post",
    data: row
  });
};

export const getStats = () => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/appVersion/enable`,
    method: "get",
  });
};


export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/appVersion/remove`,
    method: 'post',
    params: {
      ids,
    }
  })
}
export const saveOrUpdate = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/appVersion/save`,
    method: 'post',
    data: row
  })
}

export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/appVersion/selectById`,
    method: 'get',
    params: {
      id,
    }
  })
}

export const getQRcode = () => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/appVersion/getQRcode`,
    method: 'get',
    params: {}
  })
}


