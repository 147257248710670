import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_DEVICEPROPERTY = "/deviceProperty";

export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_DEVICEPROPERTY+'/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getUnionList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_DEVICEPROPERTY+'/unionPage',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_DEVICEPROPERTY+'/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_DEVICEPROPERTY+'/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_DEVICEPROPERTY+'/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_DEVICEPROPERTY+'/update',
    method: 'post',
    data: row
  })
}

export const submit = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_DEVICEPROPERTY+'/submit',
    method: 'post',
    data: row
  })
}
export const batchPropertyStatus = (ids,isEnable) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_DEVICEPROPERTY+'/batchPropertyStatus',
    method: 'get',
    params: {
      ids,
      isEnable
    }
  })
}
