import Layout from '@/page/index/'

export default [

  //安全环境

  {
    path: '/reportFilling',
    component: Layout,
    name:'报表填报',
    children: [

      {
        path: '/business/reportManager/fillReport/safetyProductionMonthly/add',
        name: '安全生产月报报表',
        component: () => import('@/views/business/reportManager/fillReport/safetyProductionMonthly/add.vue'),
        meta: {
          title: '安全生产月报报表',
          icon: ''
        }
      },   {
        path: '/business/reportManager/fillReport/safetyProductionMonthly/view',
        name: '安全生产月报查看',
        component: () => import('@/views/business/reportManager/fillReport/safetyProductionMonthly/add.vue'),
        meta: {
          title: '安安全生产月报查看',
          icon: ''
        }
      },   {
        path: '/business/reportManager/fillReport/safetyProductionMonthly/edit',
        name: '安全生产月报编辑',
        component: () => import('@/views/business/reportManager/fillReport/safetyProductionMonthly/add.vue'),
        meta: {
          title: '安全生产月报编辑',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/fillReport/safetyProductionMonthly/collect/index',
        name: '本级项目人员汇总信息',
        component: () => import('@/views/business/reportManager/fillReport/productionSafetySummary'),
        meta: {
          title: '本级项目人员汇总信息',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/fillReport/index',
        name: '报表填报',
        component: () => import('@/views/business/reportManager/fillReport/index'),
        meta: {
          title: '报表填报',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/fillReport/index',
        name: '报表填报',
        component: () => import('@/views/business/reportManager/fillReport/index'),
        meta: {
          title: '报表填报',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/summaryReport/index',
        name: '报表汇总',
        component: () => import('@/views/business/reportManager/summaryReport/index'),
        meta: {
          title: '报表汇总',
          icon: ''
        }
      },
    ]
  }
]
