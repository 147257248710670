import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

  // 获取列表
  export const getList = (params) => {
      return request({
        url: `${ServerNameEnum.SERVER_FX}/safetyproduction/list`,
        method: 'get',
        params
      })
  }
    // 新增或者修改
    export const addList = (data) => {
        return request({
          url: `${ServerNameEnum.SERVER_FX}/safetyproduction/submit`,
          method: 'post',
          data
        })
    }
  // 删除
  export const removeList = (data) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/safetyproduction/remove?ids=${data.ids}`,
      method: 'post',
      data
    })
}

     // 获取详情
  export const getDetail = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/safetyproduction/detail`,
      method: 'get',
      params
    })
}