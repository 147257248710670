import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

import AiUrl from "./seeurl"
//todo 获取算法分类头
export const getDictValueByDictCode = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/report-server/mdm-dict/get-dict-value-by-dict-code',
    method: 'post',
    data:params
  })
}
//todo 算法上传
export const aiAlgorithmUpload = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/algorithm/ai-algorithm/upload',
    method: 'post',
    data:params
  })
}
//todo 获取算法列表
export const getAlgorithmList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/algorithm/ai-algorithm/get-algorithm-list',
    method: 'post',
    data:params
  })
}
//todo 获取算法详情
export const getAlgorithmById = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/algorithm/ai-algorithm/get-algorithm-by-id',
    method: 'post',
    data:params
  })
}
//todo 算法下拉
export const getAlgorithmAllList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/algorithm/ai-algorithm/get-all-list',
    method: 'post',
    data:params
  })
}
//todo 数据来源下拉
//todo 数据表下拉
export const getTableListMap = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/db-table-column/get-table-list-map',
    method: 'post',
    data:params
  })
}
//todo 详情页面 x y 特性
export const getTableColumnList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/db-table-column/get-table-column-list ',
    method: 'post',
    data:params
  })
}


/**
 * 模型管理
 */
//todo 模型管理列表
export const getModelList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/algorithm/ai-algorithm/get-model-list ',
    method: 'post',
    data:params
  })
}
//todo 模型详情
export const getModelDetailsByModelId = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/algorithm/ai-algorithm/get-model-details-by-model-id',
    method: 'post',
    data:params
  })
}
//todo 训练模型
export const getPowerconsumption = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/algorithm/ai-algorithm/get-powerconsumption',
    method: 'post',
    data:params
  })
}
//todo 训练模型
export const getPowerconsumption2 = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/algorithm/ai-algorithm/save-model-parameters',
    method: 'post',
    data:params
  })
}
//todo 训练模型3
export const getPowerconsumption3 = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+AiUrl.AiUrl,
    method: 'post',
    data:params
  })
}
//todo 预测结果
export const getModelPredictResults = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/algorithm/ai-algorithm/get-model-predict-results',
    method: 'post',
    data:params
  })
}
//todo 预测下面表格
export const getPmmlPredictData = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/algorithm/ai-algorithm/get-pmml-predict-data',
    method: 'post',
    data:params
  })
}
//todo 算法预测

export const saveModelPredict = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/algorithm/ai-algorithm/save-model-predict-paramters',
    method: 'post',
    responseType: 'blob',
    data:params
  })
}
//训练结果
// export const aixlimg=(params)=>{
//   return request({
//     url:ServerNameEnum.SERVER_ZT_+`/algorithm/ai-algorithm/get-train-image-result/${params}` ,
//     method:'get',
//   })
// }


export const aixlimg = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/algorithm/ai-algorithm/get-train-image-result',
    method:'get',
    params
  })
}

// ai查询配置详情
export const suanfaSetDetail = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/algorithm/ai-algorithm/get_algorithm_by_id',
    method:'get',
    params
  })
}

//todo ai配置更新
export const suanfaSetUpdate = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/algorithm/ai-algorithm/save_or_update',
    method: 'post',
    data:params
  })
}
