<template>
  <div id="app" class="body">
    <!--    <router-view :key="$route.fullPath" v-if="isActive"/>-->
    <router-view v-if="isActive" />
  </div>
</template>

<script>
import { setTheme } from "@/util/util";
import { mapGetters } from "vuex";

export default {
  name: "app",

  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isActive: true,
      clientHeight: "",
    };
  },
  watch: {
    "$i18n.locale"(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.reload();
      }
    },
  },
  methods: {
    initHeadBottom() {},
    reload() {
      this.isActive = false;
      this.$nextTick(() => {
        this.isActive = true;
      });
    },
  },
  created() {},
  computed: {
    ...mapGetters(["slideClass"]),
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
// @import "./styles/assets/font.css";
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  //font-family: PingFangSC-Regular, sans-serif;
}
.avue--detail .el-col {
  margin-bottom: 0;
}
.body {
  font-family: PingFangSt;
  font-weight: normal;
}

// .el-tabs__content {
//   background-color: #f3f3f3;
//   padding: 1px !important;
// }
.el-drawer__header {
  padding: 1px 1px 1px 1px !important;
}
.el-drawer__body {
  padding: 0px 0px 0px 0px !important;
}
.el-card__body {
  padding: 0 12px 10px 12px !important;
  box-sizing: border-box;
}
.remove-avue-form-menu {
  .avue-form__menu {
    display: none !important;
  }
}
</style>
