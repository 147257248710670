import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";

const CONTROLLER_NAME = "sinomaprojectcontractinfo";

/**
 * 分页查询
 */
export const getList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYNC}/${CONTROLLER_NAME}/list`,
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

/**
 * 新增或修改
 */
export const submit= (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYNC}/${CONTROLLER_NAME}/submit`,
    method: 'post',
    data: row
  })
}

/**
 * 回显数据
 */
export const getDetail = (code) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYNC}/${CONTROLLER_NAME}/detail`,
    method: "get",
    params: {
      code,
    },
  });
};

/**
 * 删除
 */
export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYNC}/${CONTROLLER_NAME}/remove`,
    method: 'post',
    params: {
      ids
    }
  })
};
