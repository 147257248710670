export const optionParent = (safe,language)=> {
  let validatePass = (rule, value, callback) => {
    console.log("value",value)
    if (value != '') {
      if(!value.includes('{')){
        callback(new Error("请正确填写国际化数据"))
      }
      let val = JSON.parse(value)
      let val2 = val[language]
      if (val2.substring(0,1)==" " || val2.substring(val2.length-1)==" ") {
        callback(new Error(safe.$t('cip.cmn.rule.deleteWarning')))
      }
      if (val2.length > 100) {
        callback(new Error(safe.$t('cip.cmn.rule.nameLength10Warning')));
      } else {
        callback();
      }
    } else {
      callback(new Error(safe.$t('cip.plat.sys.dict.field.dictValue')+safe.$t('cip.cmn.rule.noEmptyWarning')));
    }
  };

  return {
    tip: false,
    searchShow: true,
    searchMenuSpan: 10,
    border: true,
    index: true,
    selection: true,
    viewBtn: true,
    menuWidth: 200,
    dialogWidth: 880,
    dialogClickModal: false,
    column: [
      {
        label: safe.$t('cip.plat.sys.dict.field.code'),
        prop: "code",
        search: true,
        maxlength: 50,
        showWordLimit: true,
        align: "left",
        overHidden: true,
        rules: [
          {
            required: true,
            message: safe.$t('cip.cmn.rule.inputWarning') + safe.$t('cip.plat.sys.dict.field.code'),
            trigger: "blur"
          },
        ]
      },
      {
        label: safe.$t('cip.plat.sys.dict.field.dictValue'),
        prop: "dictValue",
        search: true,
        align: "left",
        overHidden: true,
        isGlobal:true, //是否国际化
        rules: [{ required: true, validator: validatePass, trigger: ["blur","change"] }]
      },
      {
        label: safe.$t('cip.plat.sys.dict.field.isSealed'),
        prop: "isSealed",
        type: "select",
        row:true,
        align: "center",
        width: 100,
        overHidden: true,
        dicData: [
          {
            label: safe.$t('cip.plat.sys.dict.field.yes'),
            value: 0
          },
          {
            label: safe.$t('cip.plat.sys.dict.field.no'),
            value: 1
          }
        ],
        value: 0,
        rules: [
          {
            required: true,
            message: safe.$t('cip.cmn.rule.selectWarning') + safe.$t('cip.plat.sys.dict.field.isSealed'),
            trigger: "blur"
          }
        ]
      },
      {
        label: safe.$t('cip.plat.sys.dict.field.remark'),
        prop: "remark",
        row:true,
        hide: true,
        type: "textarea",
        maxlength: 200,
        showWordLimit: true,
        overHidden: true,
        width: 260,
      }
    ]
  }
};

export const optionChild = (safe,language)=> {
  let validatePass = (rule, value, callback) => {
    if (value != '') {
      if(!value.includes('{')){
        callback(new Error("请正确填写国际化数据"))
      }
      let val = JSON.parse(value)
      let val2 = val[language]
      console.log(language)
      if (val2.substring(0,1)==" " || val2.substring(val2.length-1)==" ") {
        callback(new Error(safe.$t('cip.cmn.rule.deleteWarning')))
      }
      if (val2.length > 100) {
        callback(new Error(safe.$t('cip.cmn.rule.nameLength10Warning')));
      } else {
        callback();
      }
    } else {
      callback(new Error(safe.$t('cip.plat.sys.dict.field.dictValue')+safe.$t('cip.cmn.rule.noEmptyWarning')));
    }
  };

  return {
    height: 'auto',
    calcHeight: 95,
    tip: false,
    searchShow: true,
    searchMenuSpan: 10,
    tree: true,
    border: true,
    index: true,
    selection: true,
    viewBtn: true,
    menuWidth: 200,
    dialogWidth: 880,
    dialogClickModal: false,
    column: [
      {
        label: safe.$t('cip.plat.sys.dict.field.code'),
        prop: "code",
        addDisabled: true,
        editDisabled: true,
        search: true,
        rules: [
          {
            required: true,
            message: safe.$t('cip.cmn.rule.inputWarning') + safe.$t('cip.plat.sys.dict.field.code'),
            trigger: "blur"
          }
        ]
      },
      {
        label: safe.$t('cip.plat.sys.dict.field.dictValue'),
        prop: "dictValue",
        search: true,
        align: "center",
        isGlobal:true, //是否国际化
        rules: [{ required: true, validator: validatePass, trigger: ["blur","change"] }]
      },
      {
        label: safe.$t('cip.plat.sys.dict.field.parentId'),
        prop: "parentId",
        type: "tree",
        dicData: [],
        hide: true,
        props: {
          label: "title"
        },
        addDisabled: true,
        editDisabled: true,
        rules: [
          {
            required: false,
            message: safe.$t('cip.cmn.rule.inputWarning') + safe.$t('cip.plat.sys.dict.field.parentId'),
            trigger: "click"
          }
        ]
      },
      {
        label: safe.$t('cip.plat.sys.dict.field.dictKey'),
        prop: "dictKey",
        width: 80,
        maxlength: 30,
        align: "center",
        showWordLimit: true,
        rules: [
          {
            required: true,
            message: safe.$t('cip.cmn.rule.inputWarning') + safe.$t('cip.plat.sys.dict.field.dictKey'),
            trigger: "blur"
          },
        ]
      },
      {
        label: safe.$t('cip.plat.sys.dict.field.sort'),
        prop: "sort",
        width: 80,
        type: "number",
        minRows:0,
        maxRows:1000000000,
        align: "center",
        //显示排序
        // hide: true,
        rules: [
          {
            required: true,
            message: safe.$t('cip.cmn.rule.inputWarning') + safe.$t('cip.plat.sys.dict.field.sort'),
            trigger: "blur"
          }
        ]
      },
      {
        label: safe.$t('cip.plat.sys.dict.field.isSealed'),
        prop: "isSealed",
        type: "select",
        align: "center",
        width: 80,
        dicData: [{
          label: safe.$t('cip.plat.sys.dict.field.yes'),
          value: 0
        },
        {
          label: safe.$t('cip.plat.sys.dict.field.no'),
          value: 1
        }
        ],
        value: 0,
        row: true,
        rules: [
          {
            required: true,
            message: safe.$t('cip.cmn.rule.inputWarning') + safe.$t('cip.plat.sys.dict.field.isSealed'),
            trigger: "blur"
          }
        ]
      },
      {
        label: safe.$t('cip.plat.sys.dict.field.remark'),
        prop: "remark",
        hide: true,
        type: "textarea",
        maxlength: 200,
        showWordLimit: true,
      }
    ]
  }
};
