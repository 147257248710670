var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: "SNOWS-button SNOWS-button-" + _vm.align },
    [
      _c(
        "el-button",
        _vm._g(_vm._b({}, "el-button", _vm.$attrs, false), _vm.$listeners),
        [_vm._v(_vm._s(_vm.buttonText))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }