import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';


// 安全考核列表
export const page = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/safetyassess/page`,
      method: 'get',
      params
    })
}

// 安全考核新增
export const save = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/safetyassess/save`,
      method: 'post',
      data:params
    })
}

// 安全考核编辑
export const update = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/safetyassess/update`,
      method: 'post',
      data:params
    })
}

// 安全考核删除
export const remove = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/safetyassess/remove`,
      method: 'post',
      params
    })
}

// 安全考核详情
export const detail = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/safetyassess/detail`,
      method: 'get',
      params
    })
}

// 安全考核导出
export const exportUrl = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/safetyassess/export`,
      method: 'get',
      params
    })
}

// 获取部门
export const getDept = (params) => {
    return request({
      url: `/api/sinoma-system/dept/deptChildTree`,
      method: 'get',
      params
    })
}

// 获取人员
export const getUser = (params) => {
    return request({
      url: `/api/sinoma-hse-prj/prjbasisareauser/list`,
      method: 'get',
      params
    })
}