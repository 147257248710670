import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
/** 安全费用 */
export const costPage = (current, size, params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/grsafetycost/page`,
      method: 'get',
      params: {
        current,
        size,
        ...params
      }
    })
}

export const costSubmit = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grsafetycost/submit`,
        method: 'post',
        data: params
    })
}

export const costRemove = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grsafetycost/remove`,
        method: 'post',
        params: {ids}
    })
}

export const costDetail = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grsafetycost/detail`,
        method: 'get',
        params
    })
}

export const costLinePage = (current, size, params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/grsafetycostline/page`,
      method: 'get',
      params: {
        current,
        size,
        ...params
      }
    })
}

export const costLineSubmit = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grsafetycostline/submit`,
        method: 'post',
        data: params
    })
}

export const costLineRemove = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grsafetycostline/remove`,
        method: 'post',
        params: {ids}
    })
}