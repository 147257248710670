import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";

const prefix = ServerNameEnum.SERVER_REPORT_

export const listMyFilling = (current, size, params) => { //我的填报列表
    return request({
      url: `${prefix}/patask/listMyFilling`,
      method: 'get',
      params: {
        ...params,
        current,
        size,
      }
    })
  }
  export const listMyFillingAll = (current, size, params) => { //我的填报列表
    return request({
      url: `${prefix}/patask/listMyFillingAll`,
      method: 'get',
      params: {
        ...params,
        current,
        size,
      }
    })
  }


  export const getInfoList = (current, size, params) => { //填报任务列表
    return request({
      url: `${prefix}/patask/getInfoList`,
      method: 'get',
      params: {
        ...params,
        current,
        size,
      }
    })
  }

  export const issue = (data) => { //填报任务保存+下发
    return request({
      url: `${prefix}/patask/issue`,
      method: 'post',
      data
    })
  }

  export const reissue = (data) => { //填报任务重新下发
    return request({
      url: `${prefix}/patask/reissue`,
      method: 'post',
      data
    })
  }

  export const pamodel = (current, size, params) => { //模板列表
    return request({
      url: `${prefix}/pamodel/list`,
      method: 'get',
      params: {
        ...params,
        current,
        size,
      }
    })
  }

  
  export const getInfo = (id) => { //任务详情
    return request({
      url: `${prefix}/patask/getInfo`,
      method: 'get',
      params: {
        id
      }
    })
  }

  export const modelTypeList = () => { //模板类型下拉数据
    return request({
      url: `${prefix}/pamodel/modelTypeList`,
      method: 'get'
    })
  }

  export const saveTemplateData = (row) => {
    return request({
      url: `${prefix}` +'/template/saveTemplateData',
      method: 'post',
      data: row
    })
  }
  
  export const listTemplateData= (row) => {
    return request({
      url: `${prefix}` + '/template/listTemplateData',
      method: 'post',
      data: row
    })
  }
  export const updateTemplatePageLabel= (row) => {
    return request({
      url: `${prefix}` + '/template/updateTemplatePageLabel',
      method: 'post',
      data: row
    })
  }
  
  export const saveTemplateTableData = (row) => {
    return request({
      url: `${prefix}` + '/template/saveTemplateTableData',
      method: 'post',
      data: row
    })
  }
  
  export const listTemplateTableData= (row) => {
    return request({
      url: `${prefix}` + '/template/listTemplateTableData',
      method: 'post',
      data: row
    })
  }
  
  export const deptListTwoLevelTree = () => {
    return request({
      url: `${prefix}` + '/template/deptListTwoLevelTree',
      method: 'get',
    })
  }
  export const detailInit = (busiObj,data) => {
    return request({
      url: `${prefix}` + `/bill/init?busiObj=${busiObj}`,
      method: 'post',
      data
    })
  }