<template>
  <div>
    <el-row>
      <el-col>
        <head-layout :head-btn-options="headBtnOptions"
          :head-title="$t(`cip.plat.monitor.log.error.field.localErrorLog`)"></head-layout>
      </el-col>
    </el-row>
    <avue-crud :data="logsList" :option="option">
      <template slot="menuLeft">
        <el-button type="primary" size="small" icon="el-icon-upload" @click="send">{{
          $t('cip.plat.monitor.log.error.field.uploadServer') }}</el-button>
        <el-button type="danger" size="small" icon="el-icon-delete" @click="clear">{{
          $t('cip.plat.monitor.log.error.field.clearLocalLog') }}</el-button>
      </template>
      <template slot-scope="scope" slot="type">
        <el-tag type="danger" size="small">{{ scope.label }}</el-tag>
      </template>
      <template slot-scope="props" slot="expand">
        <pre class="code">
          {{ props.row.stack }}
        </pre>
      </template>
    </avue-crud>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import logs from "@/page/logs/index";
import HeadLayout from "@/views/components/layout/head-layout";
export default {
  name: "top-logs",
  components: {
    logs,
    HeadLayout,
  },
  data() {
    return {
      option: {
        menu: false,
        addBtn: false,
        page: true,
        border: true,
        expand: true,
        refreshBtn: false,
        headerAlign: "center",
        column: [
          {
            label: this.$t('cip.plat.monitor.log.error.field.type'),
            prop: "type",
            width: 80,
            align: "center",
            slot: true,
            dicData: [
              {
                label: "bug",
                value: "error"
              }
            ]
          },
          {
            label: this.$t('cip.plat.monitor.log.error.field.address'),
            width: 200,
            prop: "url",
            overHidden: true
          },
          {
            label: this.$t('cip.plat.monitor.log.error.field.content'),
            prop: "message",
            overHidden: true
          },
          {
            label: this.$t('cip.plat.monitor.log.error.field.errorstack'),
            prop: "stack",
            hide: true
          },
          {
            label: this.$t('cip.plat.monitor.log.error.field.time'),
            align: "center",
            prop: "time",
            width: 200
          }
        ]
      }
    };
  },
  created() { },
  mounted() { },
  computed: {
    ...mapGetters(["logsFlag", "logsLen", "logsList"]),
  },
  props: [],
  methods: {
    send() {
      this.$confirm(this.$t('cip.plat.monitor.log.error.msg.upload'), this.$t('cip.cmn.title.promptTitle'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          this.$store.dispatch("SendLogs").then(() => {
            this.$parent.$parent.box = false;
            this.$message({
              type: "success",
              message: this.$t('cip.cmn.msg.success.operateSuccess')
            });
          });
        })
        .catch(() => {});
    },
    clear() {
      this.$confirm(this.$t('cip.plat.monitor.log.error.msg.clear'), this.$t('cip.cmn.title.promptTitle'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          this.$store.commit("CLEAR_LOGS");
          this.$parent.$parent.box = false;
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        })
        .catch(() => {});
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .avue-crud__menu{
  margin-bottom: 0 !important;
  align-items: center;
  padding: 8px 0;
}
</style>
