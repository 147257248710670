var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popupSelect-container" },
    [
      _c(
        "el-popover",
        {
          ref: "popover",
          staticClass: "popover-container",
          attrs: {
            placement: "bottom-start",
            width: "700",
            trigger: "click",
            disabled: _vm.selectDisabled,
          },
          on: { "after-enter": _vm.openDialog },
        },
        [
          _c(
            "div",
            {
              staticClass: "el-select",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _vm.multiple
                ? _c(
                    "div",
                    {
                      ref: "tags",
                      staticClass: "el-select__tags",
                      style: {
                        "max-width": _vm.inputWidth - 32 + "px",
                        width: "100%",
                        cursor: "pointer",
                      },
                    },
                    [
                      _vm.collapseTags && _vm.tagsList.length
                        ? _c(
                            "span",
                            [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    closable: !_vm.selectDisabled,
                                    size: _vm.collapseTagSize,
                                    type: "info",
                                    "disable-transitions": "",
                                  },
                                  on: {
                                    close: function ($event) {
                                      return _vm.deleteTag($event, 0)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-select__tags-text" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.tagsList[0][_vm.relationField]
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.tagsList.length > 1
                                ? _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        closable: false,
                                        type: "info",
                                        "disable-transitions": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "el-select__tags-text" },
                                        [
                                          _vm._v(
                                            "+ " +
                                              _vm._s(_vm.tagsList.length - 1)
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.collapseTags
                        ? _c(
                            "transition-group",
                            { on: { "after-leave": _vm.resetInputHeight } },
                            _vm._l(_vm.tagsList, function (item, i) {
                              return _c(
                                "el-tag",
                                {
                                  key: item[_vm.propsValue],
                                  attrs: {
                                    size: _vm.collapseTagSize,
                                    closable: !_vm.selectDisabled,
                                    type: "info",
                                    "disable-transitions": "",
                                  },
                                  on: {
                                    close: function ($event) {
                                      return _vm.deleteTag($event, i)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-select__tags-text" },
                                    [_vm._v(_vm._s(item[_vm.relationField]))]
                                  ),
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-input",
                {
                  ref: "reference",
                  attrs: {
                    type: "text",
                    placeholder: _vm.currentPlaceholder,
                    disabled: _vm.selectDisabled,
                    readonly: "",
                    "validate-event": false,
                    tabindex: _vm.multiple ? "-1" : null,
                  },
                  nativeOn: {
                    mouseenter: function ($event) {
                      _vm.inputHovering = true
                    },
                    mouseleave: function ($event) {
                      _vm.inputHovering = false
                    },
                  },
                  model: {
                    value: _vm.innerValue,
                    callback: function ($$v) {
                      _vm.innerValue = $$v
                    },
                    expression: "innerValue",
                  },
                },
                [
                  _c("template", { slot: "suffix" }, [
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.showClose,
                          expression: "!showClose",
                        },
                      ],
                      class: [
                        "el-select__caret",
                        "el-input__icon",
                        "el-icon-arrow-up",
                      ],
                    }),
                    _vm.showClose
                      ? _c("i", {
                          staticClass:
                            "el-select__caret el-input__icon el-icon-circle-close",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleClearClick.apply(null, arguments)
                            },
                          },
                        })
                      : _vm._e(),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          [
            _vm.filterable
              ? _c(
                  "el-form",
                  {
                    attrs: { inline: true },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "关键词" } },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder: "请输入关键词查询",
                            clearable: "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.search()
                            },
                          },
                          model: {
                            value: _vm.listQuery.keyword,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "keyword", $$v)
                            },
                            expression: "listQuery.keyword",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 5 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      size: "small",
                                      icon: "el-icon-search",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.search()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                查询\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "small",
                                      icon: "el-icon-refresh-right",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.reset()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                重置\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "SNOWS-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                attrs: {
                  data: _vm.list,
                  border: false,
                  "highlight-current-row": "",
                  size: "medium",
                  hasNO: false,
                  height: "300",
                },
                on: { "row-click": _vm.rowClick },
              },
              [
                _vm.multiple
                  ? _c("el-table-column", {
                      attrs: { width: "35" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-checkbox",
                                  {
                                    staticClass: "table-checkbox",
                                    attrs: {
                                      value: _vm.selectedIds.includes(
                                        scope.row[_vm.propsValue]
                                      ),
                                      disabled: "",
                                    },
                                  },
                                  [_vm._v(" ")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3892674859
                      ),
                    })
                  : _vm._e(),
                !_vm.multiple
                  ? _c("el-table-column", {
                      attrs: { width: "35" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: scope.row[_vm.propsValue] },
                                    model: {
                                      value: _vm.checked,
                                      callback: function ($$v) {
                                        _vm.checked = $$v
                                      },
                                      expression: "checked",
                                    },
                                  },
                                  [_vm._v(" ")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1674051710
                      ),
                    })
                  : _vm._e(),
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    width: "50",
                    label: "序号",
                    align: "center",
                  },
                }),
                _vm._l(_vm.columnOptions, function (item, i) {
                  return _c("el-table-column", {
                    key: i,
                    attrs: { prop: item.value, label: item.label },
                  })
                }),
              ],
              2
            ),
            _vm.hasPage
              ? _c("pagination", {
                  staticClass: "mb-10",
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.currentPage,
                    "pager-count": 5,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "currentPage", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: _vm.initData,
                  },
                })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "fr" },
              [
                _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.closePopover } },
                  [_vm._v("取消\n        ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.confirm()
                      },
                    },
                  },
                  [_vm._v("确定\n        ")]
                ),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }