import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';



export const trainGroupSceneSubmit = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainGroupScene/submit`,
    method: 'post',
    data
  })
}
export const trainContentAdd = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainContent/submit`,
    method: 'post',
    data
  })
}
export const retentionTime = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainContent/retentionTime`,
    method: 'post',
    data
  })
}
export const getTrainGroupScenePage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_+'/trainGroupScene/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const trainGroupScenDetail = (id,taskId,type) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ + '/trainGroupScene/detail',
    method: 'post',
    params: {
      id,
      taskId,
      type
    }
  })
}
export const trainContentRemove = (trainId,trainContentId) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ + '/trainContent/remove',
    method: 'post',
    params: {
      trainId,
      trainContentId
    }
  })
}
export const enableInvalidation = (id,status) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ + '/trainGroupScene/enableInvalidation',
    method: 'post',
    params: {
      id,
      status
    }
  })
}

export const twoDimensionalCode = (id) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ + '/trainGroupScene/twoDimensionalCode',
    method: 'post',
    params: {
      id
    }
  })
}
export const trainContentSubmit = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainContent/submit`,
    method: 'post',
    data
  })
}
export const saveCourse = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainContent/saveCourse`,
    method: 'post',
    data
  })
}
export const categoryHeadTree = (parentId) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/categoryHead/tree`,
    method: 'get',
    params: {
      parentId
    }
  })
}
export const onTheJobTraininglist = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/info/onTheJobTraininglist`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

// 现场培训最后一步保存图片
export const nowTrainSaveImage = (data) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_+'/trainingRecording/saveImage',
    method: 'post',
    data
  })
}

// 现场培训最后一步删除图片
export const nowTrainDeleteImage = (id) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_+'/trainingRecording/removeImageById?id=' + id,
    method: 'post'
  })
}
export const ipUtil = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingTask/ipUtil`,
    method: 'post',
  })
}
// 现场培训最后一步完成培训
export const nowTrainUpdate = (id,type,siteTrainingId) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_+'/trainingRecording/updateByFinish?id=' + id+'&type='+type+'&siteTrainingId='+siteTrainingId,
    method: 'get'
  })
}
export const trainingTaskSelect = (trainWay) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_+'/trainingTask/select?trainWay=' + trainWay,
    method: 'get'
  })
}
//试卷下载
export const fileDownload = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingTask/fileDownload`,
    data: row,
    method: 'post'
  })
}
// 现场培训最后一步完成培训
export const getThreeTrainImage = (id) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_+'/trainingRecording/getImageBySiteTrainingId?siteTrainingId=' + id,
    method: 'get'
  })
}
