export const options = (safe) => {
  return {
    index: true,
    column: [{
      label: safe.$t("cip.plat.sys.client.field.clientId"),
      prop: 'clientId',
      search: true,
      align: "left",
      width: 240,
      overHidden: true,
      rules: [{
        required: true,
        message: safe.$t("cip.cmn.rule.inputWarning") + safe.$t("cip.plat.sys.client.field.clientId"),
        trigger: "blur"
      }]
    },
      {
        label: safe.$t("cip.plat.sys.client.field.clientSecret"),
        prop: "clientSecret",
        align: "left",
        width: 240,
        overHidden: true,
        search: true,
        rules: [{
          required: true,
          message: safe.$t("cip.cmn.rule.inputWarning") + safe.$t("cip.plat.sys.client.field.clientSecret"),
          trigger: "blur"
        }]
      },
      {
        label: safe.$t("cip.plat.sys.client.field.authType"),
        prop: "authorizedGrantTypes",
        type: "checkbox",
        align: "left",
        overHidden: true,
        value: "refresh_token,password,authorization_code",
        dicData: [{
          label: "refresh_token",
          value: "refresh_token"
        },
          {
            label: "password",
            value: "password"
          },
          {
            label: "authorization_code",
            value: "authorization_code"
          },
          {
            label: "captcha",
            value: "captcha"
          },
          {
            label: "social",
            value: "social"
          }
        ],
        rules: [{
          required: true,
          message: safe.$t("cip.cmn.rule.selectWarning") + safe.$t("cip.plat.sys.client.field.authType"),
          trigger: "blur"
        }]
      },
      {
        label: safe.$t("cip.plat.sys.client.field.authScope"),
        prop: "scope",
        value: "all",
        align: "right",
        width: 130,
        overHidden: true,
        rules: [{
          required: true,
          message: safe.$t("cip.cmn.rule.inputWarning") + safe.$t("cip.plat.sys.client.field.authScope"),
          trigger: "blur"
        }]
      },
      {
        label: safe.$t("cip.plat.sys.client.field.accessTokenValidity"),
        prop: "accessTokenValidity",
        type: "number",
        value: 3600,
        width: 130,
        overHidden: true,
        align: "right",
        rules: [{
          required: true,
          message: safe.$t("cip.cmn.rule.inputWarning") + safe.$t("cip.plat.sys.client.field.accessTokenValidity"),
          trigger: "blur"
        }]
      },
      {
        label: safe.$t("cip.plat.sys.client.field.refreshTokenValidity"),
        prop: "refreshTokenValidity",
        type: "number",
        value: 604800,
        hide: true,
        width: 240,
        overHidden: true,
        align: "right",
        rules: [{
          required: true,
          message: safe.$t("cip.cmn.rule.inputWarning") + safe.$t("cip.plat.sys.client.field.refreshTokenValidity"),
          trigger: "blur"
        }]
      },
      {
        label: safe.$t("cip.plat.sys.client.field.webServerRedirectUri"),
        prop: "webServerRedirectUri",
        hide: true,
        width: 240,
        overHidden: true,
        align: "right",
        rules: [{
          required: true,
          message: safe.$t("cip.cmn.rule.inputWarning") + safe.$t("cip.plat.sys.client.field.webServerRedirectUri"),
          trigger: "blur"
        }]
      },
      {
        label: safe.$t("cip.plat.sys.client.field.resourceIds"),
        prop: "resourceIds",
        hide: true,
        align: "right",
        width: 240,
        overHidden: true,
        rules: [{
          message: safe.$t("cip.cmn.rule.inputWarning") + safe.$t("cip.plat.sys.client.field.resourceIds"),
          trigger: "blur"
        }]
      },
      {
        label: safe.$t("cip.plat.sys.client.field.authorities"),
        prop: "authorities",
        hide: true,
        align: "right",
        width: 240,
        overHidden: true,
        rules: [{
          message: safe.$t("cip.cmn.rule.inputWarning") + safe.$t("cip.plat.sys.client.field.authorities"),
          trigger: "blur"
        }]
      },
      {
        label: safe.$t("cip.plat.sys.client.field.autoapprove"),
        prop: "autoapprove",
        hide: true,
        align: "right",
        width: 240,
        overHidden: true,
        rules: [{
          message: safe.$t("cip.cmn.rule.inputWarning") + safe.$t("cip.plat.sys.client.field.autoapprove"),
          trigger: "blur"
        }]
      },
      {
        label: safe.$t("cip.plat.sys.client.field.additionalInformation"),
        hide: true,
        prop: "additionalInformation",
        span: 24,
        align: "right",
        width: 240,
        overHidden: true,
        rules: [{
          message: safe.$t("cip.cmn.rule.inputWarning") + safe.$t("cip.plat.sys.client.field.additionalInformation"),
          trigger: "blur"
        }]
      }
    ]
  }
};
export const searchColumns = (safe) => {
  return [
    {
      prop: "clientId",
      search: true,
      placeholder: safe.$t("cip.cmn.rule.inputWarning") + safe.$t("cip.plat.sys.client.field.clientId"),
    },
    {
      placeholder: safe.$t("cip.cmn.rule.inputWarning") + safe.$t("cip.plat.sys.client.field.clientSecret"),
      prop: "clientSecret",
      search: true,
    }
  ]
};
