var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.detailed
        ? _c("el-input", {
            attrs: {
              value: _vm.value || _vm.innerValue,
              placeholder: _vm.placeholder,
              readonly: "",
            },
          })
        : _c("p", [_vm._v(_vm._s(_vm.value || _vm.innerValue))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }