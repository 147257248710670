import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";


export const getPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SINOMA_INTERFACE_ + "/serviceType/page",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};
export const list = (params) => {
  return request({
    url: ServerNameEnum.SERVER_SINOMA_INTERFACE_ + "/serviceType/list",
    method: "get",
    params: {
      ...params,
    },
  });
};
export const tree = ( params) => {
  return request({
    url: ServerNameEnum.SERVER_SINOMA_INTERFACE_ + "/serviceType/tree",
    method: "get",
    params: {
      ...params,
    },
  });
};

export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_SINOMA_INTERFACE_ + "/serviceType/detail",
    method: "get",
    params: {
      id,
    },
  });
};

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_SINOMA_INTERFACE_ + "/serviceType/remove",
    method: "get",
    params: {
      ids,
    },
  });
};


export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SINOMA_INTERFACE_ + "/serviceType/saveOrUpdate",
    method: "post",
    data: row,
  });
};
