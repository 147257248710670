import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const region = (params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/region/lazy-tree',
    method: 'get',
    params: {
      ...params,
    }
  })
}

export const lazyListTree = (params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/region/lazy-list',
    method: 'get',
    params: {
      ...params,
    }
  })
}

export const select = (params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/region/select',
    method: 'get',
    params: {
      ...params,
    }
  })
}
export const selectCheck = (params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/region/selectCheck',
    method: 'post',
    data: params
  })
}

export const getNationality = (nationality) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/region/getNationality',
    method: 'get',
    params: {
      nationality,
    }
  })
}
