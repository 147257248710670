import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
/** 安全组织 */
/** 头表接口 */
export const mettingHeadPage = (current, size, params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/grsafetymeetinghead/page`,
      method: 'get',
      params: {
        current,
        size,
        ...params
      }
    })
}

// 获取组织列表（不分页）
export const getOrgList = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/soorg/list`,
    method: 'get',
    params
  })
}

export const orgSubmit = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/soorg/submit`,
        method: 'post',
        data: params
    })
}

export const orgRemove = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/soorg/remove`,
        method: 'post',
        params: {ids}
    })
}

// 获取节点列表（不分页）
export const getNodeList = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/soorgnode/tree`,
    method: 'get',
    params
  })
}

export const nodeSubmit = (params) => {
  return request({
      url: `${ServerNameEnum.SERVER_FX}/soorgnode/submit`,
      method: 'post',
      data: params
  })
}

export const nodeRemove = (ids) => {
  return request({
      url: `${ServerNameEnum.SERVER_FX}/soorgnode/remove`,
      method: 'post',
      params: {ids}
  })
}

// 根据安全组织获取人员清单列表（不分页）
export const getPersonList = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/soorguser/list`,
    method: 'get',
    params
  })
}

// 根据安全组织获取人员清单列表（分页）
export const getPersonPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/soorguser/page`,
    method: 'get',
    params: {
      current,
      size,
      ...params
    }
  })
}

export const personSubmit = (params) => {
  return request({
      url: `${ServerNameEnum.SERVER_FX}/soorguser/submit`,
      method: 'post',
      data: params
  })
}

export const personRemove = (ids) => {
  return request({
      url: `${ServerNameEnum.SERVER_FX}/soorguser/remove`,
      method: 'post',
      params: {ids}
  })
}

// export const mettingHeadDetail = (params) => {
//     return request({
//         url: `${ServerNameEnum.SERVER_FX}/grsafetymeetinghead/detail`,
//         method: 'get',
//         params
//     })
// }

/** 行表 */
export const mettingLinePage  = (current, size, params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/grsafetymeetingline/page`,
      method: 'get',
      params: {
        current,
        size,
        ...params
      }
    })
}

export const mettingLineSubmit = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grsafetymeetingline/submit`,
        method: 'post',
        data: params
    })
}

export const mettingLineRemove = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grsafetymeetingline/remove`,
        method: 'post',
        params: {ids}
    })
}
