/*
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-09-20 10:02:53
 */
import request from '@/router/axios';

export const getTree = (row) => {
  return request({
    url: '/api/sinoma-maas-center/node/tree',
    method: 'post',
    data: row
  })
}
export const getById = (row) => {
  return request({
    url: '/api/sinoma-maas-center/node/getById',
    method: 'post',
    data: row
  })
}

export const getServerById = (row) => {
  return request({
    url: '/api/sinoma-maas-center/server/getById',
    method: 'post',
    data: row
  })
}
export const add = (row) => {
  return request({
    url: '/api/sinoma-maas-center/node/add',
    method: 'post',
    data: row
  })
}

export const remove = (row) => {
  return request({
    url: '/api/sinoma-maas-center/node/del',
    method: 'post',
    data: row
  })
}
export const edit = (row) => {
  return request({
    url: '/api/sinoma-maas-center/node/edit',
    method: 'post',
    data: row
  })
}
export const move = (row) => {
  return request({
    url: '/api/sinoma-maas-center/node/move',
    method: 'post',
    data: row
  })
}
export const serverEdit = (row) => {
  return request({
    url: '/api/sinoma-maas-center/server/edit',
    method: 'post',
    data: row
  })
}
export const serverAdd = (row) => {
  return request({
    url: '/api/sinoma-maas-center/server/add',
    method: 'post',
    data: row
  })
}

export const serverRemove = (row) => {
  return request({
    url: '/api/sinoma-maas-center/server/del',
    method: 'post',
    data: row
  })
}
//算法任务
export const taskPageList = (row) => {
  return request({
    url: '/api/sinoma-maas-center/task/pageList',
    method: 'post',
    data: row
  })
}

//批量刷新任务状态
export const taskStatus = (row) => {
  return request({
    url: '/api/sinoma-maas-center/task/status',
    method: 'post',
    data: row
  })
}

//任务启动
export const taskBatchOpt = (row) => {
  return request({
    url: '/api/sinoma-maas-center/task/batchOpt',
    method: 'post',
    data: row
  })
}
export const taskGetById = (row) => {
  return request({
    url: '/api/sinoma-maas-center/task/getById',
    method: 'post',
    data: row
  })
}

export const taskAdd = (row) => {
  return request({
    url: '/api/sinoma-maas-center/task/add',
    method: 'post',
    data: row
  })
}

export const taskRemove = (row) => {
  return request({
    url: '/api/sinoma-maas-center/task/del',
    method: 'post',
    data: row
  })
}
export const taskEdit = (row) => {
  return request({
    url: '/api/sinoma-maas-center/task/edit',
    method: 'post',
    data: row
  })
}

export const taskEditConfig = (row) => {
  return request({
    url: '/api/sinoma-maas-center/task/editConfig',
    method: 'post',
    data: row
  })
}

//任务查询摄像头
export const listByServer = (row) => {
  return request({
    url: '/api/sinoma-maas-center/camera/listByServer',
    method: 'post',
    data: row
  })
}

//任务查询算法模型
export const usableModel = (row) => {
  return request({
    url: '/api/sinoma-maas-center//server-algo-ref/usableModel',
    method: 'post',
    data: row
  })
}

//算法模型管理
export const serverPageList = (row) => {
  return request({
    url: '/api/sinoma-maas-center/server-algo-ref/pageList',
    method: 'post',
    data: row
  })
}
//算法模型状态查询
export const serverPageStatus = (row) => {
  return request({
    url: '/api/sinoma-maas-center/server-algo-ref/status',
    method: 'post',
    data: row
  })
}
//模型管理删除
export const serverREfDel = (row) => {
  return request({
    url: '/api/sinoma-maas-center/server-algo-ref/del',
    method: 'post',
    data: row
  })
}

//模型管理部署
export const serverREfBatchOpt = (row) => {
  return request({
    url: '/api/sinoma-maas-center/server-algo-ref/batchOpt',
    method: 'post',
    data: row
  })
}


export const availableModel = (row) => {
  return request({
    url: '/api/sinoma-maas-center/server-algo-ref/availableModel',
    method: 'post',
    data: row
  })
}

export const serverBatchAdd = (row) => {
  return request({
    url: '/api/sinoma-maas-center/server-algo-ref/batchAdd',
    method: 'post',
    data: row
  })
}
