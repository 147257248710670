import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";

export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_+'/environment/insideOrOutside/fillSummaryPage',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const reportReview = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_+'/environment/insideOrOutside/reportReview',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}


export const reviewAndUrging = (data) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_+'/acquisition/attributeFixation/reviewAndUrging',
    method: 'post',
    data
  })
}
