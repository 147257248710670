import Layout from "@/page/index/";

export default [
  //安全全息
  {
    path: "/safeatyHolography",
    component: Layout,
    name: "安全全息",
    children: [
      {
        path: "/business/safe/safeatyHolography/riskDetail",
        name: "风险库详情",
        component: () =>
          import("@/views/business/safe/safeatyHolography/riskDetail.vue"),
      },
    ],
  },
];
