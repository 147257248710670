import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getTree = () => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dept/multiOrganization`,
    method: 'get',
  })
}

export const getPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/getPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const getAuditPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/getAuditPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const getDetail = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/detail`,
    method: 'post',
    data
  })
}

export const submit = (row, type) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/submit`,
    method: 'post',
    data: row,
    params: {
      type
    }
  })
}

export const update = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/update`,
    method: 'post',
    data: row,
  })
}


export const saveDomestic = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/saveDomestic`,
    method: 'post',
    data: row
  })
}

export const submissionsSave = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/submissionsSave`,
    method: 'post',
    data: data
  })
}

export const getDomesticDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/getDomesticDetail?id=` + id,
    method: 'get',
  })
}


export const summaryGetPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/summaryGetPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const summaryClick = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/summaryClick`,
    method: 'post',
    data: row
  })
}

export const summaryClickDataState = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/summaryClickDataState`,
    method: 'post',
    data: row
  })
}

export const submitSummary = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/submitSummary`,
    method: 'post',
    data
  })
}

export const submitSummaryByDomestic = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/submitSummaryByDomestic`,
    method: 'post',
    data
  })
}

export const reportReviewTree = (code) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/reportReviewTree?code=` + code,
    method: 'get',
  })
}

export const submitVerify = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/submitVerify`,
    method: 'post',
    data
  })
}

export const summaryGetData = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/summaryGetData`,
    method: 'post',
    data
  })
}


export const reportTree = (code, reportType,deptIds,dataTime) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rpReportDistribution/reportTree?code=` + code + "&reportType="
      + reportType + "&deptIds=" +deptIds +"&dataTime=" +dataTime,
    method: 'get',
  })
}


export const reportTreeAll = (code) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/reportTree?code=` + code,
    method: 'get',
  })
}

export const getDetailByDate = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/getDetailByDate`,
    method: 'post',
    data
  })
}

export const getDataStatus = () => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dict-biz/dictionary?code=data_status_report`,
    method: 'get',
  })
}

export const getPrjStatus = () => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dict-biz/dictionary?code=prj_state`,
    method: 'get',
  })
}

export const rejectSendingMessage = (row,value,templateCode) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/rejectSendingMessage`,
    method: 'post',
    data:row,
    params:{
      value,
      templateCode
    }
  })
}

export const getListData = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rptSafeActionStat/listDataIds`,
    method: 'post',
    params: {
      ids,
    }
  })
}
