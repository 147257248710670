import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

/**
 * 数据服务
 * @param params
 * @returns {*}
 */
export const queryNameList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/query/get-all-query-name-page',
    method: 'post',
    data:params
  })
}
export const dictByDictCode = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/mdm-dict/get-dict-by-dict-code',
    method: 'post',
    data:params
  })
}


//todo 服务类别
export const queryTypeList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/query/get-all-query-class',
    method: 'post',
    data:params
  })
}
//todo 新增服务类别
export const updateType = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/query/create-or-update-query-class',
    method: 'post',
    data:params
  })
}

//todo 服务类别图片列表
export const updateImgList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/upload/get-all',
    method: 'post',
    data:params
  })
}
//todo 保存服务类别图片
export const updateClassImg = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/query/update-query-class',
    method: 'post',
    data:params
  })
}

/**
 * 数据模型
 * */
//todo 左侧模型列表
export const getAllList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/model/get-all-list',
    method: 'post',
    data:params
  })
}
//todo 新增编辑模型
export const createOrUpdateModel = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/model/create-or-update-model',
    method: 'post',
    data:params
  })
}
//todo 删除模型
// {model_id:''}
export const deleteModel = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/model/delete-model',
    method: 'post',
    data:params
  })
}
//todo 点击模型 查询列表
// { "startIndex": 0, "perPage": 0, "host_id": "", "table_name": ""}
export const getList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/model-table/get-table-list-with-param',
    method: 'post',
    data:params
  })
}
export const getModelToMetadataTable = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/tables/get_model_to_metadata_table',
    method: 'post',
    data:params
  })
}
//todo 添加数据源按钮
export const getTablelistWithParam = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/model-table/get-table-list-with-param',
    method: 'post',
    data:params
  })
}
//todo 列纬度下拉
export const lieSelect = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/mdm-dict/get-all',
    method: 'post',
    data:params
  })
}
//todo 详情
export const getModelTableById = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/model-table/get-model-table-by-id',
    method: 'post',
    data:params
  })
}
//todo 数据标准下拉
export const getAllTableCode = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/code-table/get-all',
    method: 'post',
    data:params
  })
}
//todo 保存
export const createModelTable = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/model-table/create-model-table',
    method: 'post',
    data:params
  })
}
//todo 查询数据源下拉
export const listAll = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/report-server/db/list-all',
    method: 'get',
    params:{
      ...params
    }
  })
}

//todo 查询token源下拉
export const listAll2 = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/conf-token/list-not-page',
    method: 'post',
    data:params
  })
}

export const saveModelToMetadataTable = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/tables/save_model_to_metadata_table',
    method: 'post',
    data:params
  })
}
export const updataModelToMetadataTable = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/tables/update_model_to_metadata_table',
    method: 'post',
    data:params
  })
}
export const getMetadataTableById = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/tables/get_metadata_table_by_id',
    method: 'get',
    params:{
      ...params
    }
  })
}

