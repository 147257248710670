<template>
  <div class="avue-top-sidebar" id="avue-top-sidebar">
    <!--    <div class="topSidderLogo">-->
    <!--      <img src="@/assets/images/sidder-logo.png">-->
    <!--    </div>-->
    <el-menu :collapse="isCollapse"
             ref="topSidderMenu"
             @select="topSidderSelect"
             :default-active="nowTagValue"
             :unique-opened="true"
             mode="horizontal"
    >
      <topsidebarItem :collapse="isCollapse"
                      :menu="list"
                      :props="website.menu.props"
                      :screen="screen"
                      first
      ></topsidebarItem>
    </el-menu>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import logo from "../logo";
import topsidebarItem from "@/page/index/sidebar/topsidebarItem";

export default {
  name: "topsidebar",
  components: {topsidebarItem, logo},
  inject: ["index"],
  data() {
    return {
      screenWidth: '',
      list:[],
    };
  },
  computed: {
    ...mapGetters(["website", "menu", "isCollapse", "screen",'language']),
    nowTagValue() {
      return this.$router.$avueRouter.getValue(this.$route);
    },
    menuList() {
      return this.menu;
    }
  },
  created() {
    setTimeout(() => {
      this.getMenuList();
    }, 1000);
  },
  methods:{
    // 确保window.open打开新页面时不会高亮
    topSidderSelect() {
      this.$nextTick(() => {
        const path = this.$route.path;
        this.$refs.topSidderMenu.activeIndex = path;
      })
    },
    getMenuList() {
      let _this = this;
      _this.$nextTick(() => {
        // 监听页面宽度变化
        let width = document.body.offsetWidth - 707;
        let newList = []
        let moreItem = {
          path: "/menuMore",
          children: [],
          name: _this.$t('moreMenu'),
          vueName: 'iconfont icon-tuichuquanping',
          icon: ''
        }
        let number = 0
        if(this.language === 'en') {
          number = Math.floor(width / 160)
        } else {
          number = Math.floor(width / 116)
        }
        let length = _this.menuList.length
        if (number >= length) return _this.list = _this.menuList
        for (let i = 0; i < length; i++) {
          const item = _this.menuList[i];
          if (i < number - 1) {
            newList.push(item)
          } else {
            moreItem.children.push(item)
          }
        }
        if (moreItem.children.length) newList.push(moreItem)
        _this.list = newList
      })
      window.onresize = () => {
        this.getMenuList()
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.avue-top-sidebar {
  display: flex;
}

.topSidderLogo {
  width: 210px;
  height: 60px;

  img {
    width: 100%;
    height: 100%;
  }
}
::v-deep .el-submenu__title {
  font-size: 16px;
}
</style>

