export default {
  plat: {
    sys: {
      top: {
        field: {
          navigationMode: "navigationMode",
          colorBlock: "colorBlock",
          themeColors: "themeColors",
          otherSettings: "otherSettings",
          multiTabOpeningMode: "multiTabOpeningMode",
          language: "Display language selection",
          search: "Display page search box",
          stripe: "Display table zebra print",
          broadside: "Side navigation",
          top: "Top Navigation",
          mix: "Hybrid Navigation",
          dark: "Dark mode",
          brightening: "Brightening mode",
          blue: "Classic Blue",
          purple: "Rose Purple",
          azure: "azure blue",
          ocean: 'Ocean',
          green: "Vitality",
          yellow: 'Harvest',
          orange: "Yang Orange",
          red: "Enthusiasm",
          appqrcode: "app qr code"
        },
      },
      //租户管理
      tenant: {
        field: {
          min: "Data that cannot be less than 1",
          authTitle: "Tenant Authorization Configuration",
          dataSourceTitle: "Tenant Data Source Configuration",
          packageSetTitle: "Tenant Product Package Configuration",
          packageManageTitle: "Tenant Product Package Management",
          datasource: "datasource",
          packageName: "packageName",
          menuView: "menuView",
          tenantId: "tenantId",
          tenantName: "tenantName",
          linkman: "linkman",
          contactNumber: "contactNumber",
          address: "address",
          accountNumber: "accountNumber",
          expireTime: "expireTime",
          domainUrl: "domainUrl",
          tenantIdMessage: "Automatically generate tenant ID after saving",
          backgroundUrl: "backgroundUrl"
        },
        title: {
          indexHeadTitle: "Tenant Management",
          editHeadTitle: "Tenant ManagementEdit",
          addHeadTitle: "Tenant ManagementAdd",
        },
        btn: {
          authSetting: "authSetting",
          dataSourceSetting: "dataSourceSetting",
          packageSetting: "packageSetting",
          packageManage: "packageManage",
        }
      },
      user: {
        field: {
          subjectCode: "subjectCode",
          subjectName: "subjectName",
          postLevelCode: "postLevelCode",
          postLevelName: "postLevelName",
          dutyCode: "dutyCode",
          dutyName: "dutyName",
          shiftTypeCode: "shiftTypeCode",
          shiftTypeName: "shiftTypeName",
          areaCode: "areaCode",
          areaName: "areaName",
          workSectCode: "workSectCode",
          workSectName: "workSectName",
          classCode: "classCode",
          className: "className",
          extend: "extend",
          roleConfig: "roleConfig",
          userImport: "userImport",
          download: "download",
          platformConfig: "platformConfig",
          title: "title",
          customize: "customize",
          account: "account",
          realName: "realName",
          realCode: "realCode",
          userType: "userType",
          tenantName: "tenantName",
          roleName: "roleCode",
          deptName: "deptName",
          detailInfo: "detailInfo",
          name: "name",
          phone: "phone",
          email: "email",
          sex: "sex",
          man: "man",
          women: "women",
          unknow: "unknow",
          enable: "enable",
          disable: "disable",
          birthday: "birthday",
          statusName: "statusName",
          dutyInfo: "dutyInfo",
          additionalInfo: "additionalInfo",
          code: "code",
          postId: "postId",
          password: "password",
          password2: "password2",
          inputPassword: "inputPassword",
          checkPassword: "Please input a password",
          uncheckPassword: "The passwords entered twice are inconsistent",
          config: "config",
          contactNumber: "Please enter the correct contact number",
          userExt: "userExt",
          excelFile: "Template Upload",
          waiting: "Template uploading, please wait",
          format: "Please upload. xls,. xlsx standard format files",
          isCovered: "Data coverage",
          yes: "yes",
          no: "no",
          excelTemplate: "Template download",
          restAccount: "Confirm to unseal the selected account?",
          exportData: "Whether to export user data?",
          notice: "notice",
          userData: "userData",
          userDataTemplate: "userDataTemplate",
          gestureUnlock: "gestureUnlock",
          passwordUnlock: "passwordUnlock",
          isEnabled: "isEnabled",
          voluntarily: "Auto brought in by App",
          contentFormat: "Parameter rules",
          operate: "operate",
          identityCard: "identityCard",
        },
        btn: {
          restPassword: "Reset password",
          accountConfig: "Account blocked ",
          enable: "enable",
          disable: "disable",
          export: "export",
          import: "import ",
          roleConfig: "Role Configuration",
        },
        title: {
          baseInfoTitle: "Basic Information",
          dutyInfoTitle: "Responsibility Information",
          detailInfoTitle: "Details",
          treeTitle: "organization list ",
          indexHeadTitle: "User Management ",
          editHeadTitle: "User edit ",
          addHeadTitle: "User added ",
          extendTitle: "User Extension ",
        },
        msg: {
          nameLength: "Name length: 2~50 characters ",
          nameLengths: "Name length: 2~20 characters ",
          restPassword: "Sure to reset the password of this account to 123456",
          nickName: "The length of the user nickname cannot be greater than 20",
          iPSWarning: " Initial password success ",
          dlocWarning: " Account unlocked successfully ",
          CfgWarning: " Account unlocked successfully ",
          onlyEnglish: "Only English case and numeric input is supported",
          content: "Please enter content",
          enable: "Confirm to enable the selected account?",
          disable: "Confirm to disable the selected account?",
          enableSuccess: "Account enabled successfully",
          disableSuccess: "Account disabled successfully",
          SupportNumbers: "Please enter the correct tenant (consisting of 6 digits)",
          enterTenant: "Please enter the tenant ID",
          userName: "Please enter a username",
          passWord: "Please enter the password",
          passwordLength: "The minimum password length is 6 digits",
          updatePassword: "Successfully modified password!",
        }
      },
      dept: {
        field: {
          deptName: 'deptName',
          tenantId: 'tenantId',
          fullName: 'fullName',
          parentId: 'parentId',
          deptCategory: 'deptCategory',
          deptCode: 'deptCode',
          enterpriseType: "enterpriseType",
          enterpriseLevel: "enterpriseLevel",
          companyType: "companyType",
          businessType: "businessType",
          companyLocation: "location",
          isEnable: "isEnable",
          yes: "yes",
          no: "no",
          sort: 'sort',
          remark: 'remark',
        },
        title: {
          indexHeadTitle: "Dept Management ",
          editHeadTitle: "Dept edit ",
          addHeadTitle: "Dept added ",
        },
        msg: {
          isEnable: "A subordinate organization is currently in use. Please close the subordinate organization first",
        }
      },
      post: {
        field: {
          category: 'category',
          postCode: 'postCode',
          postName: "postName",
          tenantId: 'tenantId',
          sort: 'sort',
          remark: 'remark',
          isEnable: 'isEnable',
        },
        title: {
          indexHeadTitle: "Post Management ",
          editHeadTitle: "Post edit ",
          addHeadTitle: "Post added ",
        },
      },
      dict: {
        field: {
          code: 'Dictionary number',
          dictValue: 'Dictionary name',
          sort: 'Dictionary sorting',
          isSealed: 'isEnabled',
          remark: 'remark',
          parentId: "parentId",
          dictKey: 'dictKey',
          yes: 'yes',
          no: 'no'
        },
        title: {
          indexHeadTitle: "System dictionary",
          editHeadTitle: "System dictionary Edit",
          addHeadTitle: "System dictionary Add",
          bizIndexHeadTitle: "Business Dictionary",
          bizEditHeadTitle: "Business Dictionary Edit",
          bizAddHeadTitle: "Business Dictionary Add",
          indexConfigHeadTitle: "Dictionary Configuration",
          editConfigHeadTitle: "Dictionary Configuration Edit",
          addConfigHeadTitle: "Dictionary Configuration Add",
        },
      },
      menu: {
        field: {
          typeSetting: 'Typography',
          isLink: "isLink",
          platform: 'platform',
          name: 'name',
          path: 'path',
          parentId: 'parentId',
          source: 'source',
          code: 'code',
          category: 'category',
          catelog: 'catelog',
          filePath: 'filePath',
          loadText: 'loadText',
          color: 'color',
          menus: "menus",
          button: "button",
          alias: 'alias',
          isOpen: 'isOpen',
          status: 'status',
          yes: "yes",
          no: 'no',
          sort: 'sort',
          remark: 'remark',
          isOnline: "isOnline"
        },
        title: {
          indexHeadTitle: "Menu Management",
          editHeadTitle: "Menu Edit",
          addHeadTitle: "Menu Add",
        },
      },
      topMenu: {
        field: {
          menuId: "menu bind",
          name: 'name',
          code: 'code',
          source: 'source',
          sort: 'sort',
          yes: 'yes',
          no: 'no',
        },
        btn: {
          menuSetting: "Menu Configuration"
        },
        title: {
          indexHeadTitle: "Top menu",
          subDialogTitle: 'subDialogTitle'
        },
      },
      param: {
        field: {
          paramName: 'paramName',
          paramKey: 'paramKey',
          paramValue: 'paramValue',
        },
        title: {
          indexHeadTitle: "Param Management",
          editHeadTitle: "Param Edit",
          addHeadTitle: "Param Add",
          viewHeadTitle: "Param View",
        },
      },
      knowledge: {
        field: {
          remark: 'remark',
          filePath: 'filePath',
          knowledgeCode: 'knowledgeCode',
          knowledgeName: 'knowledgeName',
          parentCode: 'parentCode',
          knowledgeTypeCode: 'knowledgeTypeCode',
          knowledgeTypeName: 'typeName',
        },
        title: {
          indexHeadTitle: "Knowledge Management",
          editHeadTitle: "Knowledge Edit",
          addHeadTitle: "Knowledge Add",
          viewHeadTitle: "Knowledge View",
          treeTitle: 'Knowledge Type',
          treeHeadCateTitle: 'Knowledge Type Management',
          knowledgeTitle: "Knowledge",
        },
        msg: {
          loadText: "Image upload in progress, please wait",
        }
      },
      client: {
        field: {
          clientId: 'clientId',
          clientSecret: 'clientSecret',
          authType: 'authorization type',
          authScope: 'authorization scope',
          accessTokenValidity: 'accessTokenValidity',
          refreshTokenValidity: 'refreshTokenValidity',
          webServerRedirectUri: 'webServerRedirectUri',
          resourceIds: 'resourceIds',
          authorities: 'authorities',
          autoapprove: 'autoapprove',
          additionalInformation: 'additionalInformation'
        },
        title: {
          indexHeadTitle: "Client Management",
          editHeadTitle: "Client Edit",
          addHeadTitle: "Client Add",
          viewHeadTitle: "Client View",
        },
      },
      params: {
        field: {
          content: 'content',
          paramCode: 'paramCode',
          paramName: 'paramName',
          filePath: 'filePath',
          loadText: "Image uploading, please wait",
          typeError: "The template is malformed",
          paramType: 'paramType',
          paramValue: 'paramValue',
          releaseTime: 'releaseTime',
          textareaRich: 'textareaRich',
          remark: 'remark',
          status: 'status',
          textarea: 'textarea',
          enable: 'enable',
          disable: 'disable',
          disenble: "disenble"
        },
        title: {
          indexHeadTitle: "Systeam Param Management ",
          editHeadTitle: "Systeam Param edit ",
          addHeadTitle: "Systeam Param added ",
        },
      },
      onlineUser: {
        field: {
          username: 'username',
          realname: 'realName',
          account: 'account',
          client_ip: 'clientIP',
          login_time: 'logintime',
        },
        btn: {
          unlineBtn: "inserting coil"
        },
        title: {
          indexHeadTitle: "Online User",
        },
        msg: {
          unline_tip: 'Confirm to offline this user?',
        }
      },
      international: {
        field: {
          name: "name",
          content: "content",
          url: "url",
        },
        title: {
          indexHeadTitle: "Internationalized File List",
          editHeadTitle: "Internationalized File Edit",
          viewHeadTitle: "Internationalized File View",
        },
      },
      serialNumber: {
        field: {
          ruleCode: 'ruleCode',
          ruleName: 'ruleName',
          ruleType: 'Serial number reset rules',
          serialLength: 'serialLength',
          currentValue: 'currentValue',
          initValue: 'initValue',
          step: 'step',
          prefix: 'prefix',
          curTime: 'curTime',
          systemTime: 'systemTime',
          split: 'split',
        },
        title: {
          indexHeadTitle: "Encoding generation rules",
          editHeadTitle: "Encoding generation rules Edit",
          addHeadTitle: "Encoding generation rules Add",
          viewHeadTitle: "Encoding generation rules View",
          baseTitle: "essential information",
        },
        msg: {
          select_warning: 'Please enter the rule number',
        },
      },
      feedback: {
        field: {
          feedback: 'feedback',
          disposeResult: 'disposeResult',
          handler: 'handler',
          opinion: 'opinion',
          screenshot: 'screenshot',
          disposeTime: 'disposeTime',
          createTime: "createTime",
          submitter: 'submitter',
        },
        title: {
          indexHeadTitle: "Feedback",
          editHeadTitle: "Feedback Edit",
        },
        msg: {
          suggest: 'Please tell us about any problems or suggestions',
        }
      },
      webDesign: {},
      dataLink: {
        field: {
          name: 'Name',
          host: 'Host',
          port: 'Port',
          driver: 'Driver',
          user: 'userName',
          password: 'password',
          dbName: 'DB Name',
          linkName: 'Link Name',
          sort: 'sort',
          remark: "remark"
        },
        btn: {
          testBtn: "Test Connection"
        },
        title: {
          indexHeadTitle: "Data Connection",
          editHeadTitle: "Data Connection Edit",
          addHeadTitle: "Data Connection Add",
        },
      },
      dataInterface: {
        field: {
          handling: "data handling",
          Query: "Query",
          Add: "Add",
          Modify: "Modify",
          Delete: "Delete",
          staticData: "static Data",
          SQLOperations: "SQL Operations ",
          APIOperations: "API Operations",
          type: "type",
          action: 'Action',
          sort: 'sort',
          status: 'status',
          description: 'remark',
          prev: 'prev',
          next: 'next',
          dataSource: 'Data Source',
          categoryId: 'Category',
          fullName: 'Interface Name',
          enCode: 'Interface Code',
          dataType: 'Interface Type',
          responseType: 'Response Type',
          requestMethod: 'Request Method',
          changeDB: 'Choose DB',
          SQL: 'SQL sentence',
          // content: '支持SQL语句&存储过程语句',
          systemVariables: 'System Variables',
          param: 'Interface Param',
          reviceMeth: 'Revice Method:Body/json',
          drag: 'drag',
          clickDrag: 'Click Drag',
          paramName: 'Param Name',
          paramType: 'Param Type',
          paramDescription: 'Param Description',
          char: 'char',
          int: 'int',
          date: 'date',
          decimal: 'decimal',
          bigint: 'bigint',
          text: 'text',
          make: 'operate',
          addParam: 'Add Param',
          interfaceType: 'Interface Type',
          interfacePath: 'Interface Path',
          addHeader: 'Add headers',
          interfaceParam: 'Interface Param',
          defaultValue: 'Default Value',
          isRequired: "Is it Required",
          user: "user",
          organization: "organization",
          currentOrganizationAndSuborganization: "currentOrganizationAndSuborganization",
        },
        btn: {
          previewBtn: "Preview",
          TestBtn: "Test Interface",
        },
        title: {
          indexHeadTitle: "Data Interface",
          editHeadTitle: "Data Interface Edit",
          addHeadTitle: "Data Interface Add",
          treeTitle: 'Data Interface Type',
          addDialogTitle: "Add Parameter",
          editDialogTitle: "Edit Parameter",
          previewTitle: "Interface Preview - ",
          basicInfo: 'Basic Info',
          dataConf: 'Data Configuration',
        },
        msg: {
          chooseCategory: 'Please select an interface classification',
          disabled: "The interface has been disabled. Please enable the interface first",
          repeat: "Duplicate parameter name",
          ipAddress: "Please enter domain names, multiple domain names separated by commas",
          msg: 'The current system variable only supports internal interface references',
          JSmsg: '1、 Scripts that support JavaScript',
          JSmsg1: '2、The mini program does not support online JS scripts',
          statement: "Please enter SQL query statement or static data",
          dataSource: "Please add a data source!",
          delParam: "This operation will delete the parameter. Do you want to continue?",
          data: "(data) => {\r\n    // Processing data logic\r\n\r\n    // Return the required data\r\n    return data\r\n}",
        }
      },
      visual3: {
        field: {
          customPath: "Custom Path",
          confCustomPath: "Configure static page addresses",
          page: "Page Design",
          drag: "Dragging to generate a portal",
          name: "Portal Name",
          portal: "Classification",
          enCode: "Portal encoding",
          sort: "sort",
          status: "status",
          remark: "remark",
          basicSet: "Basic Settings",
          addPortal: "New Portal",
          linkType: "linkType",
          linkPath: "linkPath",
          title: "title",
          dataType: "dataType",
          StaticData: "StaticData",
          RemoteData: "RemoteData",
          dataInterface: "dataInterface",
          numericalValue: "numericalValue",
          Icon: "Icon",
          ComponentProperties: "ComponentProperties",
          option: "option",
          AddOptions: "AddOptions",
          DataSettings: "DataSettings",
          select: "select",
          fullName: "Interface Name",
          orderNumber: "orderNumber",
          ProcessName: "ProcessName",
          ProcessCode: "ProcessCode",
          renovate: "renovate",
          nameInterface: "name",
          code: "code",
          type: "type",
          custom: "custom",
          system: "system",
          paramName: "paramName",
          paramType: "paramType",
          defaultValue: "defaultValue",
        },
        title: {
          indexHeadTitle: "Portal Design",
          PreviewTitle: "Portal Preview",
          dataSettingsTitle: "Data Settings",
          basicControlTitle: "Basic Control",
          chartControlTitle: "Chart Control",
          allocationTitle: "Permission Assignment",
        },
        msg: {
          titleWarning: "Please enter a title",
          menuWarning: "Please select the menu",
          numericalWarning: "Please enter a numerical value",
          iconWarning: "Please enter an icon name",
          continueWarning: "At least two options must be retained",
          InterfaceWarning: "Please select a data interface",
          processWarning: "Please select a sub process",
          enterWarning: "Please enter keywords for query",
          clear: "Are you sure you want to clear all?",
        },
        btn: {
          inquireBtn: "inquire",
          resettingBtn: "resetting",
          previewBtn: "Preview",
          emptyBtn: "empty",
        },
      },
      portal: {
        field: {
          customPath: "customPath",
          confCustomPath: "confCustomPath",
          page: "page",
          drag: "drag",
          name: "name",
          portal: "portal",
          enCode: "enCode",
          sort: "sort",
          status: "status",
          remark: "remark",
          basicSet: "basicSet",
          addPortal: "addPortal",
          linkType: "linkType",
          linkPath: "linkPath",
        },
        title: {
          indexHeadTitle: "Portal Design",
          previewTitle: "Portal Preview",
        },
        msg: {
          name: "Portal name cannot be empty",
          enCode: "Portal code cannot be empty",
          portal: "Portal classification cannot be empty",
        },
      }
    },
    authority: {
      role: {
        field: {
          rolePermissions: 'rolePermissions',
          webMenumissions: 'webMenumissions',
          appMenumissions: 'appMenumissions',
          dataPermissions: 'dataPermissions',
          protPermissions: 'protPermissions',
          roleName: "roleName",
          tenantId: "tenantId",
          roleAlias: 'roleAlias',
          roleType: 'roleType',
          parentId: "parentId",
          sort: 'sort',
          name: "name",
          path: "path",
          parentIds: "parentIds",
          source: "icon",
          code: "code",
        },
        title: {
          indexHeadTitle: "Role Management",
          editHeadTitle: "Role Edit",
          addHeadTitle: "Role Add",
        },
        msg: {
          role: "Role and user binding cannot be deleted",
        }
      },
      dataScope: {
        field: {
          scopeName: 'scopeName',
          resourceCode: 'resourceCode',
          scopeColumn: 'scopeColumn',
          scopeType: 'scopeType',
          scopeField: 'scopeField',
          scopeClass: 'scopeClass',
          scopeValue: 'scopeValue',
          remark: 'remark',
          name: 'name',
          path: 'path',
          parentIds: "parentIds",
          source: 'source',
          code: 'code',
          category: 'category',
          alias: 'alias',
          action: 'action',
          sorts: 'sorts',
          isOpen: 'isOpen',
          remarks: 'remarks',
          menu: 'menu',
          button: 'button',
          toolBar: 'toolBar',
          actionBar: 'actionBar',
          toolActionBar: 'toolActionBar',
          yes: 'yes',
          no: 'no',
          allVisible: 'All Visible',
          personallyVisible: 'Personally Visible',
          deptVisible: 'Visible to the organization',
          deptAndChildVisible: 'Visible to the organization and its children',
          custom: 'Custom',
        },
        btn: {
          scopeConfBtn: 'Permission configuration',
        },
        title: {
          indexHeadTitle: "Data Permissions",
          confHeadTitle: "Data Permission Configuration",
        },
        msg: {
          scopeClass: "Please enter the full class name path of the corresponding method for MybatisMapper",
        }
      },
      apiScope: {
        field: {
          scopeName: 'scopeName',
          resourceCode: 'resourceCode',
          scopeType: 'scopeType',
          scopePath: 'scopePath',
          remark: 'remark',
          name: 'name',
          path: 'path',
          parentIds: "parentIds",
          source: 'source',
          code: 'code',
          category: 'category',
          alias: 'alias',
          action: 'action',
          sorts: 'sorts',
          isOpen: 'isOpen',
          remarks: 'remarks',
          menu: 'menu',
          button: 'button',
          toolBar: 'toolBar',
          actionBar: 'actionBar',
          toolActionBar: 'toolActionBar',
          yes: 'yes',
          no: 'no',
        },
        btn: {
          scopeConfBtn: 'Permission configuration',
        },
        title: {
          indexHeadTitle: "Interface Permissions",
          confHeadTitle: "Interface Permission Configuration",
        },
      },
    },
    // 系统运维
    sysOps: {
      stat: {
        field: {
          functionName: "functionName",
          functionCode: "functionCode",
          operationButton: "operationButton",
          operationUser: "operationUser",
          createTime: "createTime",
          usageNumber: "usageNumber",
        },
        title: {
          functionRecordTitle: "Function Record",
          buttonRecordTitle: "Button Record",
          functionFrequencyTitle: "Function Frequency",
          buttonFrequencyTitle: "Button Frequency",
        },
      },
    },
    wfDesign: {
      model: {
        field: {
          category: 'category',
          icon: 'icon',
          modelKey: 'modelKey',
          name: 'name',
          categoryId: 'categoryId',
          description: 'categoryId',
          version: 'version',
          fullScreen: 'Full Screen',
          selectForm: 'Select Form',
          formType: 'Form Type',
          builtInForm: 'BuiltIn Form',
          externalForm: 'External Form',
          nodeIndependentForm: 'Node Independent Form',
          formView: 'Form View',
          exFormKey: 'exFormKey',
          formKey: 'formKey',
          label: 'label',
          prop: 'prop',
          readable: 'readable',
          writable: 'writable',
        },
        btn: {
          changeTypeBtn: "Change Type",
          designBtn: "Design",
          deployBtn: "Deploy",
          permissionsBtn: "Permissions",
          moreBtn: "more",
          iconBtn: "icon",
          historysBtn: "historys",
          copyBtn: "copy",
          returnBtn: "Fallback version",
          allRemoveBtn: "All Remove",
          previewBtn: "Preview",
          mainBtn: "Set main version",
          prev: 'prev',
          next: 'next',
        },
        title: {
          indexHeadTitle: "Model Management",
          arrangeHeadTitle: "Select Process Classification",
          uploadIconHeadTitle: "Upload Icon",
          viewIconHeadTitle: "Model Preview",
          designFormsHeadTitle: "Design Form",
          designProcessHeadTitle: "Design Process",
          doneHeadTitle: "Done",
        },
        msg: {
          suggestedSize: "Suggested Size：50 * 50",
          requireIcon: "Icon must be filled in",
          changeType: "Please change the classification first",
          setIcon: "Successfully set icon",
          copy: "Successfully copied XML",
          deploy: "Successfully deployed",
          remove: "Delete all versions or roll back to the last version?",
          main: "The current main version will be automatically saved to history. Are you sure you want to make this version the main version?",
          form: "Don't have the form you want? Click to design",
          settings: "Can be used to control the explicit or implicit configuration of external form fields. Ignore this field if you want to control it yourself",
          script: "Understand the hazards of scripts before using them. If you are sure to use them, please refer to the documentation to release the configuration. <br>1. The JVM is fully accessible in the script. <br>2. Script execution blocks many system resources. <br>3, the script execution is dead loop/a lot of memory, and so on will cause the program to crash.",
          shell: "Please be aware of the hazards of Shell before using it. If you are sure to use it, please refer to the documentation to release this configuration. <br> Because you are not sure whether a dangerous command can be executed, for example, rm -rf *, understand the command well before using it.",
          node: "The node does not configure the form correctly",
          save: "Do you want to save this model as a new version? This means you can go back to a previous version",
        }
      },
      form: {
        field: {
          formKey: 'formKey',
          name: 'formName',
          remark: 'remark',
          status: 'status',
          categoryId: 'category',
          version: 'version',
          available: 'available',
          forbidden: 'forbidden',
        },
        btn: {
          changeTypeBtn: "Change Type",
          designBtn: "Design",
          historysBtn: "historys",
          copyBtn: "copy",
          returnBtn: "Fallback version",
          allRemoveBtn: "All Remove",
          previewBtn: "Preview",
          mainBtn: "Set main version",
        },
        title: {
          indexHeadTitle: "Form Management",
          editHeadTitle: "Form Management Edit",
          addHeadTitle: "Form Management Add",
          arrangeHeadTitle: "Select Classification",
          viewIconHeadTitle: "Form Preview",
          copyHeadTitle: "Copy Form",
        },
        msg: {
          remove: "Delete all versions or roll back to the last version?",
          main: "The current main version will be automatically saved to history. Are you sure you want to make this version the main version?",
          save: "Do you want to save this form as a new version? This means that you can return to the previous version",
        }
      },
      //部署管理
      deployment: {
        field: {
          name: 'formkey',
          key: 'formName',
          category: 'category',
          version: 'version',
          status: 'status',
          deployTime: 'deployTime',
          activate: 'activate',
          pending: 'pending',
        },
        btn: {
          changeTypeBtn: "changeType",
          activateBtn: 'activate',
          pendingBtn: 'pending',
        },
        title: {
          indexHeadTitle: "Deployment Management",
          arrangeHeadTitle: "Select Type",
        },
        msg: {
          remove: "This action cascades down the process instance that is currently in progress and cannot be recovered. Are you sure you want to remove it?",
          reconfirm: "Double hiddenTrouble! This action cascades down the process instance that is currently in progress and cannot be recovered. Are you sure you want to remove it?",
        }
      },
      //流程按钮
      button: {
        field: {
          buttonKey: "key",
          name: "name",
          display: "isDisplay",
          sort: "sort",
          status: "status",
          yes: "yes",
          no: "no",
          available: "available",
          forbidden: "forbidden",
        },
        title: {
          indexHeadTitle: "Flow Button",
          editHeadTitle: "Flow Button Edit",
          addHeadTitle: "Flow Button Add",
        },
      },
      //流程分类
      category: {
        field: {
          name: "categoryName",
          parentId: "parentCategory",
          sort: "sort",
          status: "status",
          isEnable: "isEnable",
          available: "available",
          forbidden: "forbidden",
          all: "all",
        },
        title: {
          indexHeadTitle: "Process classification",
          editHeadTitle: "Process classification Edit",
          addHeadTitle: "Process classification Add",
        },
      },
      //表单默认值
      defaultValues: {
        field: {
          content: "content",
          name: "typeName",
          fieldType: "fieldType",
        },
        btn: {
          copy: "copy"
        },
        title: {
          indexHeadTitle: "Default Values",
          editHeadTitle: "Default Values Edit",
          addHeadTitle: "Default Values Add",
        },
        msg: {
          content: "Please enter the content. If the package is ${}, it means executing the code. Please ensure that the code is executable",
        }
      },
      //流程表达式
      condition: {
        field: {
          name: "name",
          expression: "expression",
          type: "type",
          status: "status",
          personnelConfiguration: "personnelConfiguration",
          flowCondition: "flowCondition",
          available: "available",
          forbidden: "forbidden",
        },
        title: {
          indexHeadTitle: "Process expression",
          editHeadTitle: "Process expression Edit",
          addHeadTitle: "Process expression Add",
        },
      },
    },
    wfOps: {
      //运维调度
      ops: {
        field: {
          nameOfTheProcess: "nameOfTheProcess",
          ProcessIdentification: "ProcessIdentification",
          SerialNumber: "SerialNumber",
          ApplicationTime: "ApplicationTime",
          category: "category",
          TheCurrentNode: "TheCurrentNode",
          Approver: "Approver",
          applicant: "applicant",
          StartTime: "StartTime",
          EndTime: "EndTime",
          Status: "Status",
          TimeRange: "TimeRange",
          activation: "activation",
          Suspend: "Suspend",
          node: "node",
        },
        btn: {
          flowBtn: "flowChart",
          crossBtn: "cross",
          rejectBtn: "reject",
          transferBtn: "transfer",
          entrustBtn: "entrust",
          stopBtn: "stop",
          copyBtn: "copy",
          pressBtn: "press",
          bgrBtn: "Reviewer",
        },
        title: {
          indexHeadTitle: "Maintenance Scheduling",
          flowChartHeadTitle: "Flow Chart",
          selectHeadTitle: "Select Node",
        },
        msg: {
          signOff: "Are you sure you want to reduce the signature of the selected task?",
          signOffSuccess: "Successfully reduced signature",
          pressSuccess: "Urged successfully",
          stopSuccess: "Terminated successfully",
          transferSuccess: "Successfully transferred",
          delegateSuccess: "Delegated successfully",
          copySuccess: "Copy successfully",
          addMultiInstanceSuccess: "Successfully signed",
          cross: "Are you sure you want to <span style='color: red;'>pass</span> all the selected tasks?",
          reject: "Are you sure you want to <span style='color: red;'>reject</span> all the selected tasks?",
          press: "Are you sure you want to urge the selected task? This operation is invalid if the task has no reviewer",
          stop: "Are you sure you want to terminate the selected task process?",
          bgr: "Make sure to change the selected task reviewer to ",
          transfer: "Make sure to transfer the selected task to ",
          delegate: "Make sure to delegate the selected task to ",
          copy: "Be sure to Copy the selected task to ",
          addMultiInstance: "Are you sure you want to sign the selected person?",
        }
      },
      //办结流程
      done: {
        field: {
          nameOfTheProcess: "nameOfTheProcess",
          ProcessIdentification: "ProcessIdentification",
          SerialNumber: "SerialNumber",
          category: "category",
          applicant: "applicant",
          StartTime: "StartTime",
          EndTime: "EndTime",
          Status: "Status",
          finished: "finished",
          terminate: "terminate",
          withdraw: "withdraw",
          deleted: "deleted",
          applicationInfo: "Application Information",
          flowInfo: "Flow Information",
          processTrace: "Process Trace",
        },
        btn: {
          flowBtn: "flow Chart",
          detailBtn: "detail",
        },
        title: {
          indexHeadTitle: "Closing Process",
          flowChartHeadTitle: "Flow Chart",
        },
        msg: {
          allName: "Please enter the exact process name",
        }
      },
      //流程列表
      process: {
        field: {
          nameOfTheProcess: "nameOfTheProcess",
          ProcessIdentification: "ProcessIdentification",
          SerialNumber: "SerialNumber",
          category: "category",
          applicant: "applicant",
          StartTime: "StartTime",
          EndTime: "EndTime",
          ProcessStatus: "ProcessStatus",
          detailedCirculation: "detailedCirculation",
          unfinished: "unfinished",
          finished: "finished",
          terminate: "terminate",
          withdraw: "withdraw",
          recall: "recall",
          reject: "reject",
          deleted: "deleted",
        },
        btn: {
          flowBtn: "flowChart",
          detailBtn: "detail",
          processProgressBtn: "Process Progress",
          logicalDeletionBtn: "Logical Deletion",
          physicalDeletionBtn: "Physical Deletion",
        },
        title: {
          indexHeadTitle: "Process List",
          flowChartHeadTitle: "Flow Chart",
          processProgressTitle: "Process Progress",
        },
        msg: {
          allName: "Please enter the exact process name",
          remove: "<p><span style='color: red;'>Logical Deletion</span>：The ongoing process ends directly, but historical data is retained and marked as deleted. After deletion, it cannot be restored as in progress.</p><p><span style='color: red;'>Physical Deletion</span>：Completely delete all data and cannot be restored.</p>",
          reason: "Please enter the reason for deletion",
          reasonSuccess: "Successfully deleted",
          reconfirm: "Second confirmation! This operation will permanently delete process data and cannot be restored!",
          drafts: "Save to Draftbox and Close?",
        }
      },
      //流程代理
      proxy: {
        field: {
          client: "client",
          agent: "agent",
          process: "process",
          specifyTheProcess: "specifyTheProcess",
          type: "type",
          startTime: 'startTime',
          endTime: 'endTime',
          effectiveTime: "effectiveTime",
          status: "status",
          total: "total",
          appoint: "appoint",
          permanent: "permanent",
          timing: "timing",
          available: "available",
          forbidden: "forbidden",
        },
        title: {
          indexHeadTitle: "Process Proxy",
          editHeadTitle: "Process Proxy Edit",
          addHeadTitle: "Process Proxy Add",
        },
      },
    },
    workflow: {
      components: {
        userOption: {
          field: {
            type: "type",
            value: "value",
            user: "user",
            role: "role",
            dept: "dept",
            post: "post",
            platform: "platform",
            h5: "Mobile terminal H5",
            mpWX: "WeChat mini program",
            android: "android",
          },
          title: {
            indexHeadTitle: "Permission configuration",
          },
        },
        userSelect: {
          field: {
            avatar: "avatar",
            name: "name",
            deptName: "dept",
            postName: "post",
          },
          title: {
            indexHeadTitle: "Personnel selection",
          },
        },
      },
    },
    reportcenter: {
      //报表管理
      report: {
        field: {
          reportName: "reportName",
          reportType: "reportType",
          roleName: "roleName",
          sort: "sort",
          remark: "remark",
          reportCode: "reportCode",
          parentCode: "parentCode",
          reportTypeCode: "reportTypeCode",
          reportTypeName: "reportTypeName",
          reportTypeId: "reportType",
          reportReviewPath: "reportReviewPath",
          reportFillPath: "reportFillPath"
        },
        btn: {
          settingsBtn: "Permission Settings",
          filePath: "filePath",
          otherFilePath: "otherFilePath",
          reportView: "reportView",
        },
        title: {
          indexHeadTitle: "Report Management",
          editHeadTitle: "Report Management Edit",
          addHeadTitle: "Report Management Add",
          viewHeadTitle: "Report Management View",
          headCateTitle: "Report Category Management",
          roleNameTitle: "Role",
          deptNameTitle: "Dept",
        },
        msg: {
          only: "(Only the Runqian report template file can be uploaded)",
          loadText: "Report uploading, please wait",
          typeError: "Incorrect template format",
          report: "Please save the data after uploading the report file!",
          exceed: "Exceeded the upload limit for the number of files",
        },
      },
      //我的报表
      myReport: {
        field: {
          reportName: "reportName",
          reportTypeName: "reportTypeName",
        },
        title: {
          treeTitle: "Report Type",
          headTitle: "My Report",
        },
      },
      //中屏管理
      mediumScreen: {
        field: {
          name: "name",
          urlPath: "urlPath",
          code: "code",
          remark: "remark"
        },
        btn: {
          settingsBtn: "Permission Settings",
        },
        title: {
          indexHeadTitle: "Middle Screen Management",
          editHeadTitle: "Middle Screen Management Edit",
          addHeadTitle: "Middle Screen Management Add",
          viewHeadTitle: "Middle Screen Management View",
          roleNameTitle: "Role",
        },
      },
    },
    monitor: {
      log: {
        usual: {
          field: {
            serviceId: "serviceId",
            serverHost: "serverHost",
            serverIp: "serverIp",
            env: "Software environment",
            logLevel: "Log Level",
            logId: "LogID",
            requestUri: "Request interface",
            createTime: "Log time",
            userAgent: "User Agent",
            logData: "Log Data",
            params: "Request Data",
          },
          title: {
            indexHeadTitle: "General Log",
          },
        },
        api: {
          field: {
            serviceId: "serviceId",
            serverHost: "serverHost",
            serverIp: "serverIp",
            env: "Software environment",
            title: "Log name",
            method: "Request Method",
            requestUri: "requestUri",
            createTime: "createTime",
            userAgent: "userAgent",
            logData: "logData",
            params: "params",
          },
          title: {
            indexHeadTitle: "Interface Log",
          },
        },
        error: {
          field: {
            errorstack: "errorstack",
            content: "content",
            time: "time",
            type: "type",
            address: "address",
            uploadServer: "uploadServer",
            clearLocalLog: "clearLocalLog",
            localErrorLog: "localErrorLog",
            serviceId: "serviceId",
            serverHost: "serverHost",
            serverIp: "serverIp",
            env: "software environment",
            method: "Request Method",
            requestUri: "requestUri",
            createTime: "createTime",
            userAgent: "userAgent",
            params: "params",
            stackTrace: "stackTrace",
          },
          title: {
            indexHeadTitle: "Interface Log",
          },
          msg: {
            upload: "Confirm uploading local logs to the server?",
            clear: "Confirm clearing local log records?",
          },
        },
      }
    },
    intf: {
      interface: {
        field: {
          name: "name",
          code: "code",
          devName: "devName",
          devUrl: "devUrl",
          consumerName: "consumerName",
          isRetry: "isRetry",
          status: "status",
          maxRetryCount: "maxRetryCount",
          timeout: "timeout",
          inputMethodName: "inputMethodName",
          outputName: "outputName",
          outputMethodName: "outputMethodName",
          outputClassName: "outputClassName",
          outputFormat: "outputFormat",
          paramFormat: "paramFormat",
          serviceOutput: "serviceOutput",
          inputParamFormat: "inputParamFormat",
          ipAddress: "ipAddress",
          dialogTitle: "dialogTitle",
          inputStatus: "inputStatus",
          outputStatus: "outputStatus",
          responseTimeExt: "responseTimeExt",
          inputParams: "inputParams",
          params: "params",
          outputParams: "outputParams",
          failedReason: "failedReason",
          serviceType: "serviceType",
          title: "title",
          type: "type",
          ip: "ip",
          port: "port"
        },
        title: {
          indexHeadTitle: "Interface Management ",
          editHeadTitle: "Interface Management Edit ",
          addHeadTitle: "Interface Management added",
          treeTitle: "System information ",
          headCateTitle: "Service Consumers",
          logListHeadTitle: "Interface log list",
        },
      },
      serviceType: {
        field: {
          name: "name",
          code: "code",
          type: "type",
          ip: "ip",
          port: "port"
        },
        title: {
          indexHeadTitle: "System Information ",
          editHeadTitle: "Edit System information ",
          addHeadTitle: "System Information added ",
          headCateTitle: "systematic classification ",
          interfaceCate: "Interface Category",
          parentCate: 'Parent Category'
        },
      },
    },
    app: {
      appMenu: {
        field: {
          filePath: 'filePath',
          loadText: 'loadText',
          color: 'color',
          platform: 'platform',
          typeSetting: 'Typography',
          isLink: "isLink",
          list: 'Menu management',
          name: 'name',
          path: 'path',
          parentId: 'parentId',
          source: 'source',
          code: 'code',
          category: 'category',
          catelog: 'catelog',
          menus: "menus",
          button: "button",
          alias: 'alias',
          isOpen: 'isOpen',
          status: 'status',
          yes: "yes",
          no: 'no',
          sort: 'sort',
          remark: 'remark',
        },
        title: {
          indexHeadTitle: "APP Menu Management",
          editHeadTitle: "APP Menu Edit",
          addHeadTitle: "APP Menu Add",
        },
      },
      appVersion: {
        field: {
          versionCode: "versionCode",
          versionName: "versionName",
          enableFlag: "enableFlag",
          versionInfo: "versionInfo",
          updateType: "updateType",
          downloadUrl: "downloadUrl",
          appUrl: "repositoryPath",
          remark: "remark",
        },
        title: {
          indexHeadTitle: "App version",
          editHeadTitle: "App versionEdit",
          addHeadTitle: "App versionAdd",
        },
      },
      bottomMenu: {
        field: {
          menuName: "menuName",
          menuType: "menuType",
          filePath: "filePath",
          loadText: "loadText",
          typeError: "typeError",
          sort: "sort",
          remark: "remark",
          roleIds: "roleIds",
          alias: "alias",
          brightPath: "brightPath",
        },
        title: {
          indexHeadTitle: "app Bottom Menu Management ",
          editHeadTitle: "app bottom menu management edit ",
          addHeadTitle: "app bottom menu management added ",

        },
      },
      aboutUs: {
        field: {
          verCode: "verCode",
          urlPath: "urlPath",
          introduce: "introduce",
          textarea: "textarea",
          loadText: "loadText",
          image: "image",
        },
        title: {
          indexHeadTitle: "About Us",
          editHeadTitle: " About our edit ",
          addHeadTitle: " About our new ",
        },

      },
      attendance: {
        field: {
          address: "address",
          code: "name",
          longitude: "longitude",
          dimension: "dimension",
          clockRange: "clockRange",
          workShiftTime: "workShiftTime",
          workUnshiftTime: "workUnshiftTime",
          remark: "remark"
        },
        title: {
          indexHeadTitle: "Set the clock ",
          editHeadTitle: " Set and edit ",
          addHeadTitle: " punch in set new ",
        },
        msg: {
          address: "Please enter detailed address",
        },
      },
      clock: {
        field: {
          address: "address",
          username: "username",
          clockTime: "clockInTime",
          clockOutTime: "clockOutTime"
        },
        title: {
          indexHeadTitle: "clocking record ",
          editHeadTitle: "punch record edit",
          addHeadTitle: "New punch record",
        },
      },
      adInfo: {
        field: {
          adName: "adName",
          adType: "adType",
          filePath: "filePath",
          loadText: "loadText",
          typeError: "typeError",
          sort: "sort",
          remark: "content",
          urlPath: "urlPath",
        },
        title: {
          indexHeadTitle: "Rotation Management ",
          editHeadTitle: " Managing editor for rotation ",
          addHeadTitle: " Rotation management added ",
        },
        msg: {
          avater: "Only jpg/png user avatars can be uploaded, and the size should not exceed 10M",
        },
      },
      broadcast: {
        field: {
          vcn: "sound ray",
          speed: "Speech speed",
          volume: "volume",
          url: "Speaker URL",
          address: "Speaker position",
          isOpen: "Enable or not",
        },
        title: {
          indexHeadTitle: "broadcast management",
          editHeadTitle: "Broadcast Management Editor",
          addHeadTitle: "New Broadcast Management",
        },
        menu: {
          selectBroadcast: 'Broadcasting equipment',
        }
      },
    },
    message: {
      //通道管理
      channel: {
        field: {
          channelCode: "channelNumber",
          channelName: "channelName",
          channelType: "channelType",
          ChannelUserIDSource: "ChannelUserIDSource",
          channelDescribe: "channelDescribe",
          channelParameter: "channelParameter",
          status: "isEnable",
          sort: "sort",
          remark: "remark",
          createTime: "createTime",
        },
        title: {
          indexHeadTitle: "Channel Management",
          editHeadTitle: "Channel Management Edit",
          addHeadTitle: "Channel Management Add",
          viewHeadTitle: "Channel Management View",
        },
        msg:{
          signName: "Please enter signName",
          accessKeyId: "Please enter accessKeyId",
          accessKeySecret: "Please enter accessKeySecret",
          templateCode: "Please enter templateCode",
          host: "Please enter host",
          user: "Please enter user",
          from: "Please enter from",
          pass: "Please enter pass",
          appKey: "Please enter appKey",
          masterSecret: "Please enter masterSecret",
          appSecret: "Please enter appSecret",
          agentId: "Please enter agentId",
          appId: "Please enter appId",
          secret: "Please enter secret",
          templateId: "Please enter templateId",
        }
      },
      //消息模板管理
      template: {
        field: {
          templateCode: "templateCode",
          templateName: "templateName",
          templateType: "templateType",
          templateMode: "templateMode",
          statusStr: "statusStr",
          status: "isEnable",
          sort: "sort",
          remark: "remark",
          taskStatuStr: "taskStatuStr",
          createTime: "createTime",
          listen: "listen",
          cronExpressions: "cronExpressions",
          TemplateDescription: "TemplateDescription",
          Enabled: "Enabled",
          notEnabled: "notEnabled",
          msgTypeEncoding: "messageTypeEncoding",
          msgTypeName: "messageTypeName",
          parentName: "parentName",
          appUrl: "appUrl",
          pcUrl: "pcUrl",
        },
        btn: {
          start: "start",
          stop: "stop",
        },
        title: {
          indexHeadTitle: "Message Template Management",
          editHeadTitle: "Message Template Management Edit",
          addHeadTitle: "Message Template Management Add",
          viewHeadTitle: "Message Template Management View",
          headItemTitle: "Message Type",
        },
        msg: {
          start: "Are you sure you want to start selecting a data template?",
          stop: "Are you sure you want to stop selecting a data template?",
          select: "Please select a message classification code!",
        },
        //输入参数
        parameter: {
          field: {
            list: "Parameter management",
            paramName: "paramName",
            paramKey: "paramKey",
            paramValue: "paramValue",
            paramRequired: "isRequired",
            paramRule: "paramRule",
          },
          title: {
            indexHeadTitle: "input parameter",
          },
          msg: {
            listNotEmpty: "The saved parameter list is empty!",
            ending: "ending",
            templateMode: "The sending mode is timed, and the parameter cannot be set as mandatory!",
            repeated: "Key cannot be repeated",
          },
        },
        //相关方
        parties: {
          field: {
            sendFromType: "senderSource",
            sendAccount: "sender",
            receiveFromType: "recipientSource",
            presetpPersonnel: "presetpPersonnel",
            inputParameter: "inputParameter",
            receiveSetType: "Personnel filtering method",
            sort: "sort",
            remark: "remark",
            role: "role",
            organ: "organ",
            parentId: "parentRole",
            parentDept: "parentDept",
            postId: "post",
            userCode: "userCode",
            userName: "userName",
            deptName: "deptName",
            contentFormat: "parameterRules",
            operate: "operate",
          },
          title: {
            indexHeadTitle: "Related parties",
            appointHeadTitle: "Designated personnel",
          },
          msg: {
            save: "When the message template sending mode is timed, the sender and receiver sources must select preset personnel!",
          },
        },
        //消息规则
        rule: {
          field: {
            startTime: "startTime",
            endTime: "endTime",
            maxFrequency: "maxFrequency(day)",
            keywordFiltering: "keywordFiltering",
          },
          title: {
            indexHeadTitle: "Message Rules",
          },
        },
        //发送通道
        channel: {
          field: {
            channelCode: "channelCode",
            channelName: "channelName",
            channelType: "channelType",
            titleFormat: "titleFormat",
            contentFormat: "contentFormat",
            content: "content",
            createTime: "createTime",
          },
          btn: {
            editBtn: "Edit",
          },
          title: {
            indexHeadTitle: "Send Aisle",
            channelHeadTitle: "Channel management",
            headItemTitle: "Message Type",
          },
          msg: {
            listNotEmpty: "The saved parameter list is empty!",
            title: "The title in format editing cannot be empty",
            connect: "The content in format editing cannot be empty",
            all: "When the template is in timed mode, the title and content cannot have similarities",
          },
        },
      },
      //消息清单
      sendRecord: {
        field: {
          msgCode: "messageID",
          msgForm: "messageSource",
          msgType: "messageType",
          sendModel: "sendModel",
          sendNum: "sendNum",
          sendNumCensus: "sendingStatistics(totalNumber/Success/Failure)",
          sendTime: "sendTime",
          sendStatus: "sendStatus",
          startDate: "startDate",
          endDate: "endDate",
          templateName: "messageTemplate",
          templateNames: "templateName",
          templateCode: "templateCode",
          templateCronExpr: "templateCronExpr",
          successMsgNum: "successMsgNum",
          failMsgNum: "failMsgNum",
          messageTemplateId: "templateID",
          titleParam: "messageTitleParameters",
          contentParam: "messageContentParameters",
          sendParam: "senderRelatedParameters",
          receiveParam: "receiverRelatedParameters",
        },
        title: {
          indexHeadTitle: "Message List",
          addHeadTitle: "Message Add",
          viewHeadTitle: "Message Basic Information",
        },
        //发送记录
        sendRecord: {
          field: {
            detailCode: "recordID",
            channelName: "channelName",
            sendAccount: "sendAccount",
            receiveAccount: "receiveAccount",
            msgTile: "Tile",
            msgContent: "Content",
            success: "success",
            fail: "fail",
          },
          btn: {
            resend: "resend",
          },
          title: {
            indexHeadTitle: "Send Record",
            viewHeadTitle: "Message Sending Record Details",
          },
        },
      },
      //我的消息
      list: {
        field: {
          msgTile: "Message Title",
          msgContent: "Message Content",
          isRead: "isRead",
          createTime: "Reception time",
          attachment: "attachment",
          picture: "picture",
          video: "video",
          buttonName: "Functional link"
        },
        title: {
          indexHeadTitle: "My Message",
          mailTitle: "mail",
          msgTypeTitle: "Message Type",
          msgSettingTitle: "Message Setting",
          notifySettingTitle: "Notification Setting",
          bcstSettingTitle: "Broadcast Setting",
        },
        btn: {
          readBtn: "Mark Read",
          readAllBtn: "One Click Read",
          messageSettingBtn: "Message Setting",
        },
        msg: {
          read: "Confirm to select message read?",
          readAll: "Confirm that all messages have been read?",
          mail: "Received a message, please hiddenTrouble it promptly",
          removeNotRead: "This message is unread, confirm to delete?",
          removeContainNotRead: "Selected data contains unread messages, confirm deletion?",
        }
      },
    },
    resource: {
      //对象存储
      oss: {
        field: {
          classify: "classify",
          ossCode: "resourceNumber",
          endpoint: "resourceAddress",
          bucketName: "spaceName",
          accessKey: "accessKey",
          secretKey: "secretKey",
          appId: "appId",
          region: "region",
          status: "isEnable",
          remark: "remark",
          backgroundUrl: "backgroundUrl",
          code: "Resource number",
        },
        btn: {
          debugBtn: "debug",
        },
        title: {
          indexHeadTitle: "Object Storage",
          editHeadTitle: "Object Storage Edit",
          addHeadTitle: "Object Storage Add",
          viewHeadTitle: "Object Storage View",
          debugHeadTitle: "Object Storage Upload Debugging",
        },
        msg: {
          enable: "Are you sure to enable this configuration?",
          img: "Obtain image address:",
        }
      },
      //附件管理
      attach: {
        field: {
          currentLocation: "currentLocation",
          filename: "filename",
          type: "type",
          size: "size(KB)",
          dateUpload: "dateUpload",
          fileSize: "fileSize(KB)",
          creationTime: "creationTime",
          attachmentDomain: "attachmentDomain",
          attachmentExtension: "attachmentExtension",
          attachmentAddress: "attachmentAddress",
          attachmentName: "attachmentName",
          attachmentSize: "attachmentSize",
          formerly: "formerly",
          management: "management",
          list: "list",
          network: "network",
          myPapers: "myPapers",
          all: "all",
          picture: "picture",
          document: "document",
          video: "video",
          music: "music",
          other: "other",
          upload: "upload",
          attachmentUpload: "attachmentUpload",
          clickToUpload: "clickToUpload",
        },
        btn: {
          upOneLevelBtn: "upOneLevel",
          howToPresentBtn: "howToPresent",
          UploadBtn: "Upload"
        },
        title: {
          viewHeadTitle: "Details",
          pictureHeadTitle: "Picture preview",
          videoHeadTitle: "Video preview",
          audioHeadTitle: "Music preview",
        },
        msg: {
          templateUploaded: "Template upload in progress, please wait",
        }
      },
      //短信配置
      sms: {
        field: {
          classify: "classify",
          smsCode: "resourceNumber",
          templateId: "templateID",
          accessKey: "accessKey",
          secretKey: "secretKey",
          regionId: "regionId",
          signName: "SMS Signature",
          status: "isEnable",
          remark: "remark",
          phone: "cell-phone",
          param: "push parameter",
          TemplateContent: "Template content",
        },
        btn: {
          debugBtn: "debug",
          submitBtn: "send",
        },
        title: {
          indexHeadTitle: "SMS Configuration",
          debugHeadTitle: "Debugging of SMS sending on mobile phones",
        },
        msg: {
          phone: "Please enter the phone number to send to",
          param: "Example: {'code': 2333, 'title': 'notification title'}",
          enable: "Are you sure to enable this configuration?",
          sent: "Successfully sent",
        }
      },
      //文件夹管理
      folder: {
        field: {
          name: "folderName",
          code: "folderCode",
          parentId: "parentFolder",
        },
        title: {
          indexHeadTitle: "Folder Management",
          addHeadTitle: "Folder Add",
        },
      },
    },
    xxljob: {
      //任务列表
      task: {
        field: {
          all: "all",
          stopIt: "stopIt",
          initiate: "initiate",
          id: "taskID",
          jobGroup: "actuator",
          jobCron: "CRON Expression",
          jobDesc: "taskDescription",
          addTime: "NewTime",
          updateTime: "updateTime",
          author: "author",
          alarmEmail: "alarmEmail",
          executorRouteStrategy: "executorRouteStrategy",
          executorHandler: "executorHandler",
          executorParam: "executorParam",
          executorBlockStrategy: "executorBlockStrategy",
          executorTimeout: "executorTimeout",
          executorFailRetryCount: "executorFailRetryCount",
          glueType: "runMode",
          glueSource: "GLUESource",
          glueRemark: "GLUERemark",
          glueUpdatetime: "GLUEUpdatetime",
          triggerStatus: "triggerStatus",
          triggerLastTime: "triggerLastTime",
          triggerNextTime: "triggerNextTime",
          childJobId: "childJobId",
        },
        btn: {
          moreBtn: "more",
          startBtn: "start",
          stopBtn: "stop",
          firstBtn: "first",
          logBtn: "queryLog",
          registerBtn: "register",
          nextBtn: "nextExecutionTime",
        },
        title: {
          indexHeadTitle: "Task Management",
          editHeadTitle: "Task Edit",
          addHeadTitle: "Task Add",
          executeOnceHeadTitle: "Execute Once",
          nodeHeadTitle: "Register Node",
          nextHeadTitle: "Next Execution Time",
          actator: 'job Category'
        },
        msg: {
          start: "Confirm to start the selected task?",
          stop: "Confirm to stop the selection task?",
          childJobMessage: "Please enter subtasks separated by commas for multiple tasks",
        }
      },
      headTitle: "USER",
      username: "username",
      password: "password",
      role: "role",
      permission: "permission",
      //调度日志
      dispatch: {
        field: {
          jobId: "taskId",
          triggerTime: "Schedule-Time",
          triggerCode: "Scheduling-Result",
          success: "success",
          fail: "fail",
          handleTime: "Execution-Time",
          handleCode: "Execution-Status",
          type: "Job trigger type",
          adminAdress: "Scheduling machines",
          exeRegtype: "Executor-Registration Method",
          exeRegaddress: "ACTUATOR-Address List",
          routeStrategy: "Routing Policy",
          blockStrategy: "Blocking handling strategy",
          timeout: "Task timeout",
          failRetryCount: "Number of failed retries",
          divider: "<span style='color:#00c0ef;' > >>>>>>>>>>>Trigger scheduling<<<<<<<<<<< </span>",
          triggerScheduling: "Trigger scheduling",
        },
        btn: {
          triggerLogBtn: "dispatch",
          handleLogBtn: "execution",
        },
        title: {
          indexHeadTitle: "Dispatch Log",
          dispatchRemarkHeadTitle: "Dispatch Remark",
          executionLogHeadTitle: "Execution Log",
        },
        msg: {
          error: "Go to the rowLog method and make changes",
        }
      },
      //执行器管理
      actuator: {
        field: {
          appName: "AppName",
          name: "name",
          order: "sort",
          addressType: "registrationMethod",
          addressList: "machineAddress",
          manualEntry: "manualEntry",
          automaticLogon: "automaticLogon",
        },
        title: {
          indexHeadTitle: "Actuator Management",
          editHeadTitle: "Actuator Edit",
          addHeadTitle: "Actuator Add",
        },
        msg: {
          addressMessage: "Please enter a list of actuator addresses separated by commas for multiple addresses",
        }
      },
    },
  },
  desk: {
    portalSwitch: "portalSwitch",
    im: {
      field: {
        chat: "chat",
        msg: "msg",
        contacts: "contacts",
        search: "Search: Please enter keywords",
        noData: "noData",
        me: "me",
        sendEmo: "sendEmo",
        sendImg: "sendImg",
        chatRecord: "chatRecord",
        enterMsg: "Click here to directly enter a message for consultation",
        send: "send",
        noChatRecord: "noChatRecord",
        remove: "remove",
        removeCharRecord: "removeCharRecord"
      },
      title: {
        indexHeadTitle: "Internal chat",
      }
    },
    personalSharing: {
      field: {
        title: "title",
        shareTime: "shareTime",
        shareLink: "shareLink",
      },
      title: {
        indexHeadTitle: "Personal Sharing",
        addHeadTitle: "Personal Sharing Add",
        editHeadTitle: "Personal Sharing Edit",
        viewHeadTitle: "Personal Sharing View",
      }
    },
    notice: {
      field: {
        title: 'Notification title ',
        category: 'Notification Type ',
        releaseTimeRange: 'Notification time ',
        publishTime: 'Publish time ',
        releaseTime: 'notice date ',
        content: 'Notice content ',
        publish_status: 'Publication status ',
        sound: "sound",
        maleVoice: "maleVoice",
        femaleVoice: "femaleVoice",
        speed: "speed",
        volume: "volume",
        responsiveMode: "responsiveMode",
        synchronousMode: "synchronousMode",
        immediateResponse: "immediateResponse",
        repeat: "Number of repetitions played",
        time: "Repeated playback interval",
      },
      btn: {
        broadcastBtn: "Broadcast",
        settingBtn: "Broadcast Settings",
      },
      title: {
        addHeadTitle: " Notice notice added ",
        editHeadTitle: " Notification notification editor ",
        viewHeadTitle: " Notice notice view ",
        indexHeadTitle: "Notice and Announcement ",
        settingHeadTitle: "Broadcast Settings",
      },
      msg: {
        synchronousMode: "Respond after the language is played",
        immediateResponse: "Respond without waiting for playback to complete",
        repeat: "Stop after reaching the specified number of times for this parameter (unit: times)",
        time: "The switching interval time for repeated playback (in seconds), if left blank, it defaults to 2 seconds",
        success: "Successfully Set",
        frequently: "Frequent operations, please try again later",
        broadcast: "Please select a broadcasting device",
      }
    },
    workflow: {
      field: {
        icon: 'icon',
        ProcessDesign: 'ProcessDesign ',
        ProcessIdentification: 'Process identification ',
        ProcessClassification: "Processclassification",
        version: 'Version ',
        state: 'state ',
        Suspend: "Suspend",
        nameOfTheProcess: 'process name ',
        SerialNumber: 'serial number ',
        TheCurrentNode: 'current node ',
        applicant: 'Applicant',
        ApplicationTime: 'Application time ',
        start: 'New process ',
        days: "days",
        creator: "creator",
        createDept: "createDept",
        datetime: "datetime",
        todo: 'To-do list',
        MyDone: 'I have done it',
        send: 'My request ',
        claim: 'Transaction to be signed ',
        copy: 'Cc matter',
        done: 'Settle matters',
        serialNumber: " Serial number",
        ProcessStatus: "ProcessStatus",
        OperationsNode: "OperationsNode",
        OperationTime: "OperationTime",
        category: "category",
        title: "title",
        Initiator: "Initiator",
        CCTime: "CCTime",
        reason: "Reason",
        originalName: "originalName",
      },
      msg: {
        beginTime: "The start time must be entered",
        unfinished: "underway",
        finished: "off the stocks",
        terminate: "Terminated",
        withdraw: "rescinded",
        recall: "Withdrawn",
        reject: "Rejected",
        deleted: "Deleted"
      },
      title: {
        indexHeadTitle: "New Process ",
        indexHead1Title: "My Request ",
        indexHeadTodo: 'To-do List ',
        taskTodo: "To-do Task",
        indexHeadVisible: 'Flow chart ',
        indexHeadFirst: " Application Information ",
        indexHeadSecond: " Flow Information ",
        indexHeadThird: " Process Tracking ",
        indexHeadMyDone: "My done",
        indexHeadCopy: "CC Matters",
        indexHeadDone: "Completion matters",
        indexHeadClaim: "Pending matters",
        indexHeadDetails: "Process Details",
        indexHeadMyTodo: "My To-do",
      },
      btn: {
        flowBtn: "flowChart",
        detailBtn: "Details",
        crossBtn: "cross",
        rejectBtn: "reject",
        transferBtn: "transfer",
        entrustBtn: "entrust",
        stopBtn: "stop",
        sealBtn: "seal",
        copyBtn: "copy",
        pressBtn: "press",
        bgrBtn: "Reviewer",
      },

    },
    todo:{//待办
      field: {
        // icon: "图标",
        // ProcessDesign: "流程设计",
        // ProcessIdentification: "流程标识",
        ProcessClassification: "To-do classification",
        // version: "版本",
        // state: "状态",
        // activation: "激活",
        // Suspend: "挂起",
        nameOfTheProcess: "To-do name",
        businessType:"Business classification",
        // SerialNumber: "流水号",
        // TheCurrentNode: "当前节点",
        // applicant: "申请人",
        ApplicationTime: "Issuance time",
        Issuer:"Issuer",
        // start: "新建流程",
        // todo: "待办事宜",
        // send: "我的请求",
        // MyDone: "我的已办",
        // claim: "待签事务",
        // copy: "抄送事宜",
        // done: "办结事宜",
        // serialNumber: "流水号",
        // creator: "创建人",
        // createDept: "创建部门",
        // datetime: "请假时间",
        // ProcessStatus: "流程状态",
        // OperationsNode: "操作节点",
        // days: "请假天数",
        // OperationTime: "操作时间",
        // category: "流程分类",
        // title: "标题",
        // Initiator: "发起者",
        // CCTime: "抄送时间",
        // reason: "请假理由",
        // originalName: "附件",
      },
    }
  },
  dig: {
    guration: {
      field: {
        notEnabled: "notEnabled",
        enable: "enable",
        name: "name",
        content: "content",
      },
      title: {
        indexHeadTitle: "ConfigurationList",
      },
    },
  },
}
