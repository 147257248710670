import Layout from '@/page/index/'
//CPS
export default [{
  path: '/knowledge',
  component: Layout,
  name: '知识库',
  children: [
    {
      path: '/knowledge/knowledgeSetting/knowledgeClassification/form',
      name: '维度分类详情',
      meta: {
        title: '维度分类详情',
        icon: ''
      },
      component: () =>
        import('@/views/business/knowledge/knowledgeSetting/knowledgeClassification/form.vue'),
    },
    {
      path: '/knowledge/knowledgeSetting/knowledgeClassification',
      name: '知识库分类',
      meta: {
        title: '知识库分类',
        icon: ''
      },
      component: () =>
        import('@/views/business/knowledge/knowledgeSetting/knowledgeClassification'),
    },

    {
      path: '/knowledge/knowledgeSetting/hotSearchManagement',
      name: '置顶管理',
      meta: {
        title: '置顶管理',
        icon: '',
        code:'hotSearchManagement'
      },
      component: () =>
        import('@/views/business/knowledge/knowledgeSetting/hotSearchManagement'),
    },


    {
      path: '/knowledge/myKnowledgeBase',
      name: '我的知识库',
      meta: {
        title: '我的知识库',
        icon: '',
        code:'myKnowledgeBase'
      },
      component: () =>
        import('@/views/business/knowledge/myKnowledgeBase'),
    },
    {
      path: '/knowledge/knowledgeUpload',
      name: '知识上传',
      meta: {
        title: '知识上传',
        icon: '',
        code:'knowledgeUpload'
      },
      component: () =>
        import('@/views/business/knowledge/myKnowledgeBase/knowledgeUpload'),
    },
    {
      path: '/knowledge/myCollect',
      name: '我的收藏',
      meta: {
        title: '我的收藏',
        icon: ''
      },
      component: () =>
        import('@/views/business/knowledge/myKnowledgeBase/myCollect'),
    },
    {
      path: '/knowledge/myShare',
      name: '我的分享',
      meta: {
        title: '我的分享',
        icon: ''
      },
      component: () =>
        import('@/views/business/knowledge/myKnowledgeBase/myShare'),
    },
    {
      path: '/knowledge/browsingHistory',
      name: '浏览历史',
      meta: {
        title: '浏览历史',
        icon: ''
      },
      component: () =>
        import('@/views/business/knowledge/myKnowledgeBase/browsingHistory'),
    },
    {
      path: '/knowledge/knowledgeSearch',
      name: '知识检索',
      meta: {
        title: '知识检索',
        icon: '',
        code:'knowledgeSearch'
      },
      component: () =>
        import('@/views/business/knowledge/knowledgeSearch'),
    },
    {
      path: '/knowledge/searchList',
      name: '知识搜索',
      meta: {
        title: '知识搜索',
        icon: ''
      },
      component: () =>
        import('@/views/business/knowledge/knowledgeSearch/searchList'),
    },
    {
      path: '/knowledge/intelligent',
      name: '智能搜索',
      meta: {
        title: '智能搜索',
        icon: ''
      },
      component: () =>
        import('@/views/business/knowledge/knowledgeSearch/intelligent'),
    },
    {
      path: '/knowledge/browse',
      name: '知识浏览',
      meta: {
        title: '知识浏览',
        icon: ''
      },
      component: () =>
        import('@/views/business/knowledge/knowledgeSearch/browse'),
    },
  ]
}]
