import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 查询字典数据详细
export function getDicts (dictCode) {
    return request({
      url: ServerNameEnum.SERVER_ZT_+'/sys-dict/get-dict-list?dictCode=' + dictCode,
      method: 'get'
    })
  }
  // 查询系统字典
export function getDic(code) {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/dict/dictionary?code=' + code,
    method: 'get'
  })
}
  // 查询业务字典
  export function getDicBiz(code) {
    return request({
      url: ServerNameEnum.SERVER_SYSTEM_+'/dict-biz/dictionary?code=' + code,
      method: 'get'
    })
  }
