import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaper/page`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaper/detail`,
    method: 'get',
    params: {
      id
    }
  })
}
export const questionDetails = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaper/questionDetails`,
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaper/remove`,
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaper/submit`,
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaper/submit`,
    method: 'post',
    data: row
  })
}

export const checkNameExist = (paperName,id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaper/checkNameExist`,
    method: 'post',
    params: {
      paperName,
      id
    }
  })
}

export const generatePaper = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaper/generatePaper`,
    method: 'post',
    data: row
  })
}

export const getEduList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaper/getEduList`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const topicPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaper/topicPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const saveOrUpdate = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaper/saveOrUpdate`,
    method: 'post',
    data: row
  })
}

export const removeByEduPaperLine = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaper/removeByEduPaperLine`,
    method: 'post',
    params: {
      ids,
    }
  })
}

export const publishEduPaper = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaper/publishEduPaper`,
    method: 'post',
    params: {
      id,
    }
  })
}

export const eduPaperLapse = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaper/eduPaperLapse`,
    method: 'post',
    params: {
      id,
    }
  })
}

export const generateTopic = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaper/generateTopic`,
    method: 'post',
    data: row
  })
}
// /edupaper/groupByType?id=试题id
// 获取统计数据
export const groupStatistics = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/edupaper/groupByType?id=${id}`,
    method: 'get',
  })
}
