const filters = {
  // 数据字典转换
  fmtText(value, arr) {
    let textArr = arr.filter(item => item.dictKey == value);
    return textArr.length > 0 && textArr[0].dictValue
  },
}
export default (Vue) => {
  Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
  })
}
