import httpService from "@/router/axios";

// 查看危险源详情
export const getHazardDetail = (id) => {
  return httpService({
    url: `/api/ivystar-wisdom-security/hazardsrisk/detail/${id}`,
    method: "get",
  });
};

// 查询风险因素列表

export const getHazardDetailList = (params) => {
  return httpService({
    url: `/api/ivystar-wisdom-security/hazardsrisk/list`,
    method: "get",
    params
  });
};
// // 项目基础资料中查看危险源详情
// export const getItemshazardsriskDetail = (params) => {
//   return httpService({
//     url: `/api/ivystar-wisdom-security/itemshazardsrisk/detail`,
//     method: "get",
//     params
//   });
// };

// 获取所有危险源类型
export const getHazardType = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/hazardtype/list2",
    method: "get",
    params,
  });
};

// 获取风险等级公式接口，不分页
export const getRiskLevelsAllList = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/risklevel/list2",
    method: "get",
    params,
  });
};

// 新增危险源配置
export const addHazard = (data) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/hazards/save",
    method: "post",
    data,
  });
};

// 修改危险源配置
export const editHazard = (data) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/hazards/update",
    method: "post",
    data,
  });
};

// 给危险源新增风险因素
export const addRisk = (data) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/hazardsrisk/save",
    method: "post",
    data,
  });
};

// 编辑风险因素
export const editRisk = (data) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/hazardsrisk/update",
    method: "post",
    data,
  });
};

// 编辑项目基础管理中的风险因素
export const updateRisk = (data) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/itemshazardsrisk/update",
    method: "post",
    data,
  });
};

// 删除风险因素
export const delRisk = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/hazardsrisk/remove",
    method: "post",
    params,
  });
};
