import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

/** 危害分析 */
export const ohhazardList = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/ohhazard/list`,
      method: 'get',
      params,
    })
}

// 新增/编辑
export const ohhazardSubmit = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/ohhazard/submit`,
      method: 'post',
      data: params
    })
}

// 删除
export const ohhazardRemove = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/ohhazard/remove`,
        method: 'post',
        params: {ids}
    })
}

// 项目风险地图标名称列表
export const areaList = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmriskmap/listAreaName`,
    method: 'get',
    params,
  })
}