import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

/** 职业病病历 */
export const medicalRecordPage = (current, size, params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/ohoccupationdisease/page`,
      method: 'get',
      params: {
        current,
        size,
        ...params
      },
    })
}

// 新增/编辑
export const medicalRecordSubmit = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/ohoccupationdisease/submit`,
      method: 'post',
      data: params
    })
}

// 删除
export const medicalRecordRemove = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/ohoccupationdisease/remove`,
        method: 'post',
        params: {ids}
    })
}
