import Layout from '@/page/index/'

export default [
  {
    path: '/train',
    component: Layout,
    name:'成绩补录',
    children: [
      {
        path: '/business/safetyenment/train/trainRecordScores/edit',
        name: '成绩补录编辑',
        component: () => import('@/views/business/safetyenment/train/trainRecordScores/add.vue'),

        meta: {
          title: '成绩补录编辑',
          icon: ''
        }
      },
      {
        path: '/business/safetyenment/train/trainRecordScores/view',
        name: '成绩补录查看',
        component: () => import('@/views/business/safetyenment/train/trainRecordScores/add.vue'),

        meta: {
          title: '成绩补录查看',
          icon: ''
        }
      },
    ]
  }
]
