import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 本级组织的项目人员汇总信息
export const levelPageList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/summaryGetDataPage`,
    method: 'get',
    params: {
      current,
      size,
      ...params
    }
  })
}

// 下级组织及项目人员信息
export const belowPageList = (current, size, params) => {
    return request({
      url: `${ServerNameEnum.SERVER_REPORT_}/environment/insideOrOutside/summaryGetPage`,
      method: 'get',
      params: {
        current,
        size,
        ...params
      }
    })
  }
