import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 据查询条件查询法人列表
export const getList = (current, size, params) => {
    return request({
        url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/selectCompanyGroupList',
        method: 'post',
        data: {
            sinomaLegalEntity: {
                ...params
            },
            query: {
                current: current,
                size: size
            }
        }
    })
}

// 根据pcode查询法人组织列表,pcode为空查询所有数据
export const getLazyList = (current, size, pcode) => {
    return request({
        url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/selectCompanyGroup',
        method: 'post',
        data: {
            //     // "pcode":"ZCGJ-FR-0001",
            pcode: pcode,
            query: {
                current: current,
                size: size
            }
        }
    })
}


//  * 获取上级树
export const tree = (code) => {
    return request({
        url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/treeList',
        method: "post",
        data:
        {
            code: code,
        }
    });
};

// 新增或修改
// export const submit = (row) => {
//     return request({
//         url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/sinomaLegalEntity',
//         method: 'post',
//         data: row

//     })
// }
export const submit = (row) => {
    return request({
        url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/sinomaLegalEntityNew',
        method: 'post',
        data: row

    })
}

// 根据code删除法人信息，若有子项则不允许删除
export const remove = (code) => {
    return request({
        url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/delCompanyGroup',
        method: 'post',
        data:
        {
            code: code,
        }
    })
}

