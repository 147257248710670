import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';///api/sinoma-hse-prj

export const list = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/steqtype/typeTree',
    method: 'get',
    params
  })
}
export const submit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/steqtype/submit',
    method: 'post',
    data
  })
}
export const remove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/steqtype/remove',
    method: 'post',
    data
  })
}

//检查对象
export const getListObject = (current,size,params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/steqcheckobject/page',
    method: 'get',
    params:{
      current,
      size,
      ...params
    }
  })
}
export const stworkcheckObjectSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/steqcheckobject/submit',
    method: 'post',
    data
  })
}
export const stworkcheckObjectRemove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/steqcheckobject/remove',
    method: 'post',
    data
  })
}

//检查列表
export const getList = (current,size,params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/steqchecklist/page',
    method: 'get',
    params:{
      current,
      size,
      ...params
    }
  })
}
export const stworkchecklistSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/steqchecklist/submit',
    method: 'post',
    data
  })
}
export const stworkchecklistRemove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/steqchecklist/remove',
    method: 'post',
    data
  })
}
