import Layout from '@/page/index/'

export default [
  {
    path: '/licenceEmployees',
    component: Layout,
    children: [
      {
        path: '/business/responsibility/licenceEmployees/form',
        name: '职员证照详情',
        meta: {},
        component: () =>
          import('@/views/business/responsibility/licenceEmployees/form.vue')
      },
      {
        path: '/business/responsibility/relatedCompanyEnterprise/form',
        name: '相关方企业证照详情',
        meta: {},
        component: () =>
          import('@/views/business/responsibility/relatedCompanyEnterprise/form.vue')
      },
      {
        path: '/business/responsibility/securityMetting/add',
        name: '安全会议新增',
        meta: {},
        component: () =>
          import('@/views/business/responsibility/securityMetting/form.vue')
      },
      {
        path: '/business/responsibility/securityMetting/edit',
        name: '安全会议编辑',
        meta: {},
        component: () =>
          import('@/views/business/responsibility/securityMetting/form.vue')
      },
      {
        path: '/business/responsibility/securityMetting/view',
        name: '安全会议详情',
        meta: {},
        component: () =>
          import('@/views/business/responsibility/securityMetting/form.vue')
      },
      {
        path: '/business/responsibility/securityCost/add',
        name: '安全费用新增',
        meta: {},
        component: () =>
          import('@/views/business/responsibility/securityCost/form.vue')
      },
      {
        path: '/business/responsibility/securityCost/edit',
        name: '安全费用编辑',
        meta: {},
        component: () =>
          import('@/views/business/responsibility/securityCost/form.vue')
      },
      {
        path: '/business/responsibility/securityCost/view',
        name: '安全费用详情',
        meta: {},
        component: () =>
          import('@/views/business/responsibility/securityCost/form.vue')
      },
      {
        path: '/business/responsibility/administrativePenalty/add',
        name: '行政处罚新增',
        meta: {},
        component: () =>
          import('@/views/business/responsibility/administrativePenalty/add.vue')
      },
      {
        path: '/business/responsibility/administrativePenalty/edit',
        name: '行政处罚编辑',
        meta: {},
        component: () =>
          import('@/views/business/responsibility/administrativePenalty/add.vue')
      },
      {
        path: '/business/responsibility/administrativePenalty/view',
        name: '行政处罚查看',
        meta: {},
        component: () =>
          import('@/views/business/responsibility/administrativePenalty/add.vue')
      },
      {
        path: '/business/responsibility/grsafetydecide/add',
        name: '安全奖惩新增',
        meta: {},
        component: () =>
          import('@/views/business/responsibility/grsafetydecide/form.vue')
      },
      {
        path: '/business/responsibility/grsafetydecide/edit',
        name: '安全奖惩编辑',
        meta: {},
        component: () =>
          import('@/views/business/responsibility/grsafetydecide/form.vue')
      },
      {
        path: '/business/responsibility/grsafetydecide/view',
        name: '安全奖惩详情',
        meta: {},
        component: () =>
          import('@/views/business/responsibility/grsafetydecide/form.vue')
      },
    ]
  }
]
