<template>
  <div class="searchFormBox">
    <el-row id="gridHeadLayout" class="searchFormRow" ref="searchFormRow" :gutter="5">
      <div class="flex-container flex-wrap-w">
        <el-col :span="item.span" v-for="(item,index) in jsonList" :key="index" v-show="isShow(item)">
          <component ref="component" @change="handleChange(item)" @keyup.enter.native="handleSearchSubmit()"
                     v-model="searchForm[item.prop]" v-bind="componentAttrs(item)"></component>
        </el-col>
        <el-col :span="searchBtnSpan" v-if="searchBtnShow && jsonList.length>0">
          <div class="flex-container flex-align-c white-space">
            <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearchSubmit"></el-button>
            <el-button size="mini" icon="reset-refresh icon-refresh" @click="handleSearchEmpty"></el-button>
          </div>
        </el-col>
        <!--       (pageType=='add' || pageType=='edit' || pageType=='') && 这个判断是否多余-->
        <el-col span="optionBtnSpan" style="text-align: right;" v-if=" gridHeadBtn.length > 0">
          <el-button-group>
            <el-button size="mini" :type="rowBtn.btnOptType == 'save' ? 'primary'  :''"
                       v-for="(rowBtn,index) in gridHeadBtn"
                       :key="index"
                       :loading="rowBtn.loading"
                       :disabled="rowBtn.disabled"
                       @click="headBtnClick(rowBtn)">{{ btnText(rowBtn) }}
            </el-button>
          </el-button-group>
        </el-col>
      </div>
    </el-row>
  </div>
</template>
<script>
import request from '@/router/axios';
import components from './index.js';

export default {
  name: "grid-head-layout",
  provide() {
    // 使用函数的形式，可以访问到 `this`
    return {
      app: this
    }
  },
  model:{
    event:'change',
    prop:'searchForm'
  },
  components: {
    ...components
  },
  props: {
    searchColumns: {
      type: Array,
      default: []
    },
    gridHeadBtn: {
      type: Array,
      default: []
    },
    searchOptions: {
      type: Object,
      default: () => ({
        searchBtnShow: true,
        searchFormShow: true,
        optionBtnSpan: 4
      })
    },
    searchSpan: { // 搜索按钮span
      type: String
    },
    btnSpan: {
      type: String
    },
    searchForm:{
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    const TYPE = {
      tree: {is: 'avue-input-tree',  expandOnClickNode: false},
      select: {is: 'avue-select', collapseTags: true},
      cascader: {is: 'avue-cascader', checkStrictly: false},
      date: {is: 'el-date-picker',type:'date',},
      'date-format': {is: 'el-date-picker',type:'date', format: 'yyyy-MM-dd', valueFormat: 'yyyy-MM-dd'},
      'custom-date': {type:'date', is: 'el-date-picker'},
      year: {is: 'el-date-picker',type:'year', format: 'yyyy', valueFormat: 'yyyy'},
      datetimerange: {
        type:'datetimerange',
        is: 'el-date-picker',
        defaultTime: ['00:00:00', '23:59:59'],
        startPlaceholder: '开始时间',
        endPlaceholder: '结束时间'
      },
      daterange: {
        type:'daterange',
        is: 'el-date-picker',
        startPlaceholder: '开始时间',
        endPlaceholder: '结束时间',
        format: 'yyyy-MM-dd',
        valueFormat: 'yyyy-MM-dd'
      },
      monthrange: {is: 'el-date-picker',type:'monthrange', defaultTime: ['00:00:00', '23:59:59']},
      month: {is: 'el-date-picker',type:'month'},
      datetime: {is: 'el-date-picker',type:'datetime', format: 'yyyy-MM-dd HH:mm:ss', valueFormat: 'yyyy-MM-dd HH:mm:ss'},
      input: {is: 'avue-input'},
      number: {is: 'avue-input-number', controls: false},
      numberRange: {is: 'number-range'},
      switch: {is: 'el-switch'},
    }
    return {
      TYPE,
      formItems:undefined,
      searchFormShow: this.searchOptions.searchFormShow ? this.searchOptions.searchFormShow : false,
      searchBtnShow: this.searchOptions.searchBtnShow ? this.searchOptions.searchBtnShow : false,
      pageType: 'add',
      searchBtnSpan: 4,
      optionBtnSpan: this.searchOptions.optionBtnSpan ? this.searchOptions.optionBtnSpan : 4,
    };
  },

  mounted() {
    // if (this.$route.query.hasOwnProperty("pageType")) {
    //   this.pageType = this.$route.query.pageType;
    // }
  },
  computed: {
    jsonList(){
      return this.formItems || this.searchColumns
    },
  },
  created() {
    this.initSearch();
  },
  methods: {
    btnText(rowBtn) {
      let btn = {
        'add': this.$t("cip.cmn.btn.addBtn"),
        'edit': this.$t("cip.cmn.btn.editBtn"),
        'remove': this.$t("cip.cmn.btn.delBtn"),
        'view': this.$t("cip.cmn.btn.viewBtn"),
        'import': this.$t("cip.cmn.btn.importBtn"),
        'export': this.$t("cip.cmn.btn.exportBtn"),
      }
      return btn[rowBtn.btnOptType] || rowBtn.label
    },
    isShow(item) {
      if (!item.display) return true
      return typeof item.display == 'function' && item.display(item)
    },
    handleChange(item) {
      //判断type是历史遗留问题
      if (item.type == 'switch') {
        this.$emit('switchClick', this.searchForm)
      } else if (item.type == 'input' && item.readonly && item.readonly == true) {
        this.$emit('inputClick', item)
      }
    },
    async initSearch() {
      const _this = this;
      if (this.searchColumns.length == 0) {
        this.optionBtnSpan = 24;
        return
      }
        let searchOptionSpan = 0
        for (let i=0;i<this.searchColumns.length;i++) {
          let item=this.searchColumns[i]
          // if (item.type == 'select' || item.type == 'tree' || item.type == 'cascader') {
          if (item.dicUrl) {
            await _this.dicInit(item).then(res => {
              if (res.data.success) {
                if (item.props && item.props.res) {
                  let temp = res.data
                  for (let name of item.props.res.split(".")) {
                    temp = temp[name]
                  }
                  this.$set(item, 'dicData', temp)
                } else {
                  let data = typeof item.dicFormatter == 'function' ? item.dicFormatter(res.data.data) : res.data.data
                  this.$set(item, 'dicData', data)
                }
                if (typeof (item.afterLoading) === "function") {
                  item.afterLoading(item["dicData"], this.searchForm)
                }
              }
            });
            // }
          }
          item.span = item.span || 4
          searchOptionSpan += item.span
        }
        this.formItems=this.searchColumns
        // 搜索按钮所占比例 默认2 有传值就是传值的数值
        this.searchBtnSpan = this.searchSpan || 4
        // 其他按钮所占比例 默认2 有传值就是传值的数值
        this.optionBtnSpan = this.btnSpan != undefined ? this.btnSpan : (this.searchOptions.optionBtnSpan ? this.searchOptions.optionBtnSpan : 4)

    },
    handleSearchEmpty() {
      Object.keys(this.searchForm).forEach((key) => (this.searchForm[key] = ''))
      //对数字输入框重置--针对作业票模板处理
      if (this.searchForm.hasOwnProperty('version')) delete this.searchForm.version
      this.$refs.component?.forEach(item => {
        item.reset && item.reset()
      })
      this.$emit('change',{})
      this.$emit("grid-head-empty", this.searchForm)
    },
    handleSearchSubmit() {
      this.$emit('change',this.searchForm)
      this.$emit("grid-head-search", this.searchForm)
    },
    headBtnClick(btn) {
      this.$emit(btn.emit)
    },
    dicInit(option) {
      return request({
        url: option.dicUrl,
        method: option.dicMethod || "get",
        params: option.dicQuery || {}
      })
    },
    componentAttrs(item, form) { // 重组组件属性
      const {type = 'input'} = item
      const defaultAttrs = {
        size: 'mini',
        opts:typeof item.opts == 'function' ?  item.opts(item) : typeof item.opts == 'object' ? item.opts:[],
        dic:item.dicData,
        clearable: true,
      }
      return {...this.TYPE[type],...defaultAttrs, ...item}
    },
  },
}
</script>

<style lang="scss">
.searchFormBox {
  overflow: hidden;

  .avue-form__group {
    height: 30px !important;
  }

  .searchFormRow {
    background-color: #FFFFFF;
    padding: 10px 12px;
  }

  .el-button {
    height: 28px;
  }

  .el-date-editor--daterange.el-input__inner {
    width: 100% !important;
  }

  .el-range-editor--mini.el-input__inner {
    width: 100%;
  }

  .el-input-number--mini {
    width: 100%;
  }

  .el-input-number .el-input__inner {
    text-align: left !important;
  }
}


</style>
