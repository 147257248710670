import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';


// 安全巡检执行统计
export const hiddenDanger = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/responsibility/hd_hidden_danger/month_statistics`,
      method: 'get',
      params
    })
  }
// 安全巡检执行统计根据人员或区域
export const hiddenDangerList = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/responsibility/hd_hidden_danger/type_statistics`,
      method: 'get',
      params
    })
  }

// 安全巡检执行统计根据人员或区域表格分页
export const hiddenDangerTable = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/responsibility/hd_hidden_danger/page_type_statistics`,
      method: 'get',
      params
    })
  }
// 安全巡检执行统计巡检记录分页
export const inspectionRecord = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/responsibility/hd_hidden_danger/inspection_record_statistics`,
      method: 'get',
      params
    })
  }
// 安全巡检执行统计不合格记录分页
export const unqualifiedRecord = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/responsibility/hd_hidden_danger/unqualified_statistics`,
      method: 'get',
      params
    })
  }
// 作业许可图表统计
export const workTicketChart = (data) => {
return request({
    url: `${ServerNameEnum.SERVER_FX}/responsibility/workTicket/echarts`,
    method: 'post',
    data
})
}

// 作业许可图表统计列表
export const workTicketList = (data) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/responsibility/workTicket/page`,
        method: 'post',
        data
    })
    }

// 班前会图表统计
export const beforeClassChart = (data) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/responsibility/before_class/week_statistics`,
        method: 'get',
        params:data
    })
}

// 班前会列表
export const beforeClassList = (data) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/responsibility/before_class/page_week_statistics`,
        method: 'get',
        params:data
    })
}

// 巡检详情
export const detail = (params) => {
  return request({
      url: `${ServerNameEnum.SERVER_FX}/responsibility/security_inspection/detail`,
      method: 'get',
      params
  })
}

// 不合格巡检详情
export const belowStandardDetail = (params) => {
  return request({
      url: `${ServerNameEnum.SERVER_FX}/responsibility/security_inspection_detail/detail`,
      method: 'get',
      params
  })
}

// 根据项目查询隐患治理列表
export const getList = (params) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/hdhiddendanger/projectPage',
    method: 'get',
    params
  })
}

// 绑定关联记录
export const bindRecord = (params) => { 
  return request({
    url: '/api/sinoma-hse-prj/responsibility/hd_hidden_danger/bind',
    method: 'get',
    params
  })
}

  