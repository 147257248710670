import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 人员全息-培训记录
export const personnelTrainRecord = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/education/myTrainingPage`,
    method: 'get',
    params
  })
}


// 人员全息-考试记录
export const personnelExamRecord = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/eduuserexam/examinationPaperPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

// 人员全息-人员证照
export const personelLicencePage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/grcertificatecontroluserline/page`,
    method: 'get',
    params: {
      current,
      size,
      ...params
    }
  })
}
