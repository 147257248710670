/*
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-10-16 13:35:44
 */
import request from '@/router/axios';

export const getList = (current, size, params) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/almpushrule/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const getTreeByUserType = () => { //分页
  return request({
    url: '/api/sinoma-system/role/list?current=1&size=9999',
    method: 'get',

  })
}

export const save = (data) => { //新增
  return request({
    url: '/api/sinoma-hse-prj/almpushrule/save',
    method: 'POST',
    data
  })
}
export const update = (data) => { //编辑
  return request({
    url: '/api/sinoma-hse-prj/almpushrule/update',
    method: 'POST',
    data
  })
}
export const remove = (ids) => { //删除
  return request({
    url: '/api/sinoma-hse-prj/almpushrule/remove',
    method: 'POST',
    params: {
      ids,
    }
  })
}
