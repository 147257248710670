import request from "@/router/axios";

/**
 * 文件流返回
 * @param url 接口地址
 */
export const exportBlob = (url) => {
  return request({
    url: url,
    method: 'get',
    responseType: 'blob'
  })
}
export const downloadFile = (fileName,fileUrl) => {
  return request({
    url: `/api/sinoma-resource/client/file/download?fileName=${fileName}&fileUrl=${fileUrl}`,
    method: 'get',
    responseType: 'blob'
  })
}

// 获取列设置
export const getColumnsByModuleId = (moduleId) => {
  return request({
    url: `/api/permission/Authority/GetColumnsByModuleId/${moduleId}`,
    method: 'GET'
  })
}
// 获取列设置
export const getCommonUrl = (url,params) => {
  return request({
    url:url,
    params,
    method: 'GET'
  })
}
