import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
let reqUrl = ServerNameEnum.SERVER_FX + "/dbtconstructionscheduling";
// let reqUrl = ServerNameEnum.SERVER_FX + "/dbtbeforeclass";
/** 环保突发事件-分页 */
export const eventPage = (params) => {
  return request({
    url: reqUrl + '/page',
    method: 'get',
    params
  })
}
export const eventList = (params) => {
  return request({
    url: reqUrl + '/list',
    method: 'get',
    params
  })
}

/** 环保突发事件-详情 */
export const eventDetail = (params) => {
  return request({
    url:  reqUrl + '/detail',
    method: 'get',
    params
  })
}
export const eventSubmit = (data) => {
  return request({
    url:  reqUrl + '/save',
    method: 'post',
    data
  })
}
export const eventRemove = (ids) => {
  return request({
    url:  reqUrl + '/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const removeByClassIds = (ids) => {
  return request({
    url:  reqUrl + `/delete/${ids}`,
    method: 'delete'
  })
}



export const eventImportLibrary = (data) => {
  return request({
    url:  reqUrl + '/importLibrary',
    method: 'post',
    data
  })
}
