import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

/**
 * 左侧树
 * @param params
 * @returns {*}
 */
export const treeList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/db-manager/auth-list-all',
    method: 'get',
    data:params
  })
}
/**
 * 右侧列表
 * @param {dbType:'',host_id:'',}
 * @returns {*}
 */
export const getTableListMap = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/db-table-column/get-table-list-map',
    method: 'post',
    data:params
  })
}
/**
 * 数据目录列表
 * @param params
 * @returns {*}
 */
export const getAiModelList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/tables/get_model_to_metadata_table',
    method: 'post',
    data:params
  })
}
/**
 * 根据数据源查询表
 */
export const modelList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/db-table-column/get-ai-model-list',
    method: 'post',
    data:params
  })
}
/**
 * 保存数据目录
 */
export const saveDataCatalog = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/tables/save_data_catalog',
    method: 'post',
    data:params
  })
}
/**
 * 数据目录描述中下拉框
 * @param params
 * @returns {*}
 */
export const getDtaDirectoryAllByPid = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/data-directory/get-data-directory-all-by-pid',
    method: 'post',
    data:params
  })
}
/**
 * 描述中元数据
 * @params {dbType:'',host_id:'',table_name:'',}
 */

export const getTableColumnList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/tables/get_data_catalog_detail',
    method: 'get',
    params:{
      ...params
    }
  })
}
/**
 * 浏览数据
 */
export const glanceData = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/tables/glance_data',
    method: 'post',
    data:params
  })
}
/**
 * 数据血源查询
 * @param {table_id:''} 详情里面table_id
 * @returns {*}
 */
export const getTableRelationshipById = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/model-table/get-table-relation-ship-by-id',
    method: 'post',
    data:params
  })
}
/**
 * 元数据修改
 * @params
 */
export const updateTableColumn = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/model-table/save-or-update-table-column',
    method: 'post',
    data:params
  })
}
/**
 * 浏览数据
 * @params
 */
export const getHostTable = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/data-asset/get-value-by-host-and-table',
    method: 'post',
    data:params
  })
}
/**
 * 删除
 * @params
 */
export const delTable = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/storage/model-table/delete-table-by-param',
    method: 'post',
    data:params
  })
}
/**
 * 描述数据查询
 */
//数据目录删除
export const directoryDel= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/tables/remove_metadata_table_directory',
    method: 'post',
    data:params
  })
}