import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
const CONTROLLER_NAME = "msgChannel";
/**
 * 分页查询
 * @param current 页码
 * @param size  每页数量
 * @param params  查询条件
 * @returns AxiosPromise
 */
export const selectPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/${CONTROLLER_NAME}/selectPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
/**
 * 修改或新增方法，如果有id为修改，没有id为新增
 * @param row 修改或新增的数据
 * @returns AxiosPromise
 */
export const saveOrUpdate = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/${CONTROLLER_NAME}/saveOrUpdate`,
    method: 'post',
    data: row
  })
}
/**
 * 根据id查询通道信息
 * @param id
 * @returns AxiosPromise
 */
export const selectById = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/${CONTROLLER_NAME}/selectById`,
    method: 'get',
    params: {
      id
    }
  })
}
/**
 *
 * @param ids 要删除的id集合，多个id之间用英文都好(,)隔开
 * @returns AxiosPromise
 */
export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/${CONTROLLER_NAME}/remove`,
    method: 'post',
    params: {
      ids,
    }
  })
}
