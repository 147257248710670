/*
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-10-30 14:29:11
 */
import Layout from "@/page/index/";

export default [
    //各模块应用分析
    {
        path: "/dbtAppliedTatistics",
        component: Layout,
        name: "各模块应用分析",
        children: [
            {
                name: "系统应用对比分析",
                path: "/dbtAppliedTatistics/view/index",
                component: () => import('@/views/dbtAppliedTatistics/view/index'),
                meta: {
                    i18n: '系统应用对比分析',
                    code:"dbtAppliedTatistics"
                }
            },
            {
                name: "各模块应用统计分析基础配置",
                path: "/dbtAppliedTatistics/configure/index",
                component: () => import('@/views/dbtAppliedTatistics/configure/index'),
                meta: {
                    i18n: '各模块应用统计分析基础配置'
                }
            },
            {
                name: "新增各模块应用统计分析基础配置",
                path: "/dbtAppliedTatistics/configure/edit",
                component: () => import('@/views/dbtAppliedTatistics/configure/edit'),
                meta: {
                    i18n: '各模块应用统计分析基础配置'
                }
            },
        ],
    },
];
