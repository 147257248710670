import Vue from 'vue';
import axios from './router/axios';
import VueAxios from 'vue-axios';
import App from './App';
import router from './router/router';
import ServerNameEnum from '@/util/ServerNameEnum';
import './permission'; // 权限
import './error'; // 日志
import './cache';//页面缓存
const sinoma = require('./util/sinoma').default
import store from './store';
import {loadStyle} from './util/util'
import * as urls from '@/config/env';
import Element from 'element-ui';
import "./assets/scss/darksidder.scss";
import "./assets/scss/bluesidder.scss";
import {
  iconfontUrl,
  iconfontVersion
} from '@/config/env';
import i18n from './lang'; // Internationalization
import './styles/common.scss';
import basicContainer from './components/basic-container/main';
import HeadLayout from "@/views/components/layout/head-layout"
import GridHeadLayout from "@/components/searchForm/searchForm.vue";
import fileView from '@/views/business/components/viewImage'
import GridLayout from "@/views/components/layout/grid-layout";
import FormLayout from "@/views/components/layout/form-layout";
import avueUeditor from 'avue-plugin-ueditor';
import website from '@/config/website';
import crudCommon from '@/mixins/crud';
import mixinsIndex from '@/mixins/index';
import JsonViewer from 'vue-json-viewer';
import * as filters from './filters'
import ZmTreeOrg from 'zm-tree-org';
import CommonTreeNew from '@/views/components/com_tree_new/index.vue';
import "zm-tree-org/lib/zm-tree-org.css";
import syButton from '@/components/button'


// 全局注册过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
// 业务组件
import tenantPackage from './views/system/tenantpackage';

import { randomNum } from "./util/koi";
Vue.prototype.randomNum = randomNum;
import dataV from '@jiaminghi/data-view'
import './assets/scss/common.scss'

import fixZoomTooltip from "@/util/fix-zoom-tooltip";
Vue.use(fixZoomTooltip)
//文件分片
import uploader from 'vue-simple-uploader'
Vue.use(uploader)
//百度地图
import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  ak: 'aLZH4kzk0RG0vH6NGoABEYH8pjGfSC6j'
})
// 高德地图
import AMapLoader from "@amap/amap-jsapi-loader";
AMapLoader.load({
  'key': 'f0ebdb07b19a4100b883b6e207d1f7e7',
  'version': '2.0',   // 指定要加载的 JSAPI 的版本，缺少时默认为 1.4.15
  'plugins': ['AMap.Scale','AMap.GeoJSON','AMap.AutoComplete','AMap.PlaceSearch','AMap.Geocoder','AMap.MarkerCluster'],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等，更多插件请看官方文档
  "Loca":{  //是否加载 Loca,缺省不加载
    "version": '2.0.0'  //Loca 版本,缺省1.3.2
  }
}).then((AMap) => {
  Vue.use(AMap)
})

//全局引入
import VueCron from 'vue-cron'
Vue.use(VueCron);

Vue.use(ZmTreeOrg);

Vue.use(dataV)

Vue.prototype.$EventBus = new Vue()
Vue.prototype.$echarts = window.echarts
Vue.prototype.sinoma = sinoma
Vue.mixin(mixinsIndex)
// 注册全局crud驱动
window.$crudCommon = crudCommon;
// 加载Vue拓展
Vue.use(router);
Vue.use(ServerNameEnum);
Vue.use(JsonViewer);
Vue.use(VueAxios, axios);
Vue.use(syButton);
Vue.use(Element, {
  size:'small',
  i18n: (key, value) => i18n.t(key, value)
});
Element.Dialog.props.closeOnClickModal.default = false; // 全局关闭点遮罩关闭弹框

Vue.use(window.AVUE, {
  size: 'small',
  tableSize: 'small',
  calcHeight: 21,
  i18n: (key, value) => i18n.t(key, value)
});
// 注册全局容器
Vue.component('basicContainer', basicContainer);
Vue.component('HeadLayout', HeadLayout);
Vue.component('GridHeadLayout', GridHeadLayout);
Vue.component('GridLayout', GridLayout);
Vue.component('FormLayout', FormLayout);
Vue.use(avueUeditor);
Vue.component('tenantPackage', tenantPackage);
Vue.component('fileView', fileView);
Vue.component('CommonTreeNew',CommonTreeNew);
// 加载相关url地址
Object.keys(urls).forEach(key => {
  Vue.prototype[key] = urls[key];
});
//批量引入组件
import components from './components'
Vue.use(components)

Vue.prototype.website = website;
// 动态加载阿里云字体库
iconfontVersion.forEach(ele => {
  loadStyle(iconfontUrl.replace('$key', ele));
});

Vue.use(window.AvueFormDesign)
Vue.use(window.WfDesign.default, {
  i18n: (key, value) => i18n.t(key, value)
})
import directive from "@/directive";
Vue.use(directive);

// 添加实例属性
Object.assign(Vue.prototype, {
  sinoma, // 公共方法
})

// import VueAMap from 'vue-amap'
// Vue.use(VueAMap)
// VueAMap.initAMapApiLoader({
//   key: '43c7fdad99473bb2f52c78c08abf01af',
//   plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder'],
//   v: '1.4.4',
//   uiVersion: '1.0'
// })


Vue.config.productionTip = false;
Vue.config.devtools = true;
import func from "@/util/func";
Vue.prototype.$func = func

// 新流程添加
import { message } from './util/message';
const snows = require('./util/snows').default
// 添加实例属性
Object.assign(Vue.prototype, {
  define: require('./util/define'), // 常量
  snows, // 公共方法
  formValidate: require('./util/formValidate').default, // 表单验证
  $message: message
})

export default new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$videos = Video
/*//判断配置文件是否开启日志调试 是否输出日志 True 输出 False 不输出      打印不输出，用于在生产环境下铁山港
var logDebug = false;
console.log = (function (oriLogFunc) {
  return function () {
    if (logDebug) {
      oriLogFunc.apply(this, arguments);
    }
  }
})(console.log);*/
// 全局数据字典转换
import filtersList from './util/filters'
filtersList(Vue)
