import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";

const CONTROLLER_NAME = "/sys/defaultConfig";

/**
 * 分页查询
 */
export const getList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/list`,
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

/**
 * 新增
 */
export const submit = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}${CONTROLLER_NAME}/submit`,
    method: 'post',
    data: row
  })
}

/**
 * 获取详情
 */
export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/detail`,
    method: "get",
    params: {
      id,
    },
  });
};

/**
 * 删除
 */
export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/remove`,
    method: 'post',
    params: {
      ids
    }
  })
};
