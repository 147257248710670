import httpService from "@/router/axios";

//开始云台控制
export const $_ptzStart = (params) => {
    return httpService({
        url: '/api/ivystar-ehs/camera/ptzStart',
        method: 'get',
        params
    })
}

//结束云台控制
export const $_ptzStop = (params) => {
    return httpService({
        url: '/api/ivystar-ehs/camera/ptzStop',
        method: 'get',
        params
    })
}
