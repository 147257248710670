/*
 * @Author: absorbedyy@163.com
 * @Date: 2024-06-05 10:23:41
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-06-05 10:25:23
 * @Description: 
 */
import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
/** 证照管理- 相关方企业 */
export const relatedEnterprisePage = (current, size, params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/grcertificatecontrolthird/page`,
      method: 'get',
      params: {
        current,
        size,
        ...params
      }
    })
}

export const relatedEnterpriseSubmit = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grcertificatecontrolthird/submit`,
        method: 'post',
        data: params
    })
}

export const relatedEnterpriseRemove = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grcertificatecontrolthird/remove`,
        method: 'post',
        params: {ids}
    })
}

export const relatedEnterpriseDetail = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grcertificatecontrolthird/detail`,
        method: 'post',
        params: {ids}
    })
}

export const relatedEnterpriseOCR = (params) => {
    return request({
        url: `/api/sinoma-agent/textSummary/ocrRecognition`,
        method: 'post',
        data: params
    })
}