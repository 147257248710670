import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";

const CONTROLLER_NAME = '/onlineuser'
/**
 * 分页查询
 * @param current
 * @param size
 * @param params
 * @returns {*}
 */
export const getList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}${CONTROLLER_NAME}/oauth/userinfo`,
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};
/**
 * 下线
 * @param current
 * @param size
 * @param params
 * @returns {*}
 */
export const unline = (token) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}${CONTROLLER_NAME}/oauth/unline`,
    method: "post",
    params: {
      token
    },
  });
};
