import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 获取风险类型树
export const getTree = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmriskSourceType/tree`,
    method: 'get',
    params
  })
}
// 获取风险类型树风险管控使用
export const getSourcetree = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmriskSourceType/sourcetree`,
    method: 'get',
    params
  })
}
// 新增或编辑
export const addTree = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmriskSourceType/submit`,
    method: 'post',
    data
  })
}
// 风险类型详情
export const getTreeDetail = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmriskSourceType/detail`,
    method: 'get',
    params
  })
}
// 风险源详情
export const getTreesDetail = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmrisksource/detail`,
    method: 'get',
    params
  })
}


// 删除风险源分类树
export const deleteTree = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmriskSourceType/remove?ids=${data.ids}`,
    method: 'post',
  })
}
// 删除风险源分类树
export const deleteTrees = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmrisksource/remove?ids=${data.ids}`,
    method: 'post',
  })
}

// 获取风险库列表
export const getPage = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmrisklibrary/page`,
    method: 'get',
    params
  })
}
// 获取风险库列表 风险管控用
export const getPageRiskManagementAndControl = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmrisklibrary/pageRiskManagementAndControl`,
    method: 'get',
    params
  })
}
// 新增风险库列表数据
export const addPage = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmrisklibrary/submit`,
    method: 'post',
    data
  })
}
// 删除风险库列表数据
export const deletePage = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmrisklibrary/remove?ids=${data.ids}`,
    method: 'post',
  })
}
// 风险库列表数据详情
export const PageDetail = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmrisklibrary/detail`,
    method: 'get',
    params
  })
}
// 新增风险源表
export const addTreeList = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmrisksource/submit`,
    method: 'post',
    data
  })
}
//   获取饼图
export const getChartPie = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmrisklibrary/getPie`,
    method: 'get',
    params
  })
}
// 获取编码
export const getPageCode = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmrisklibrary/getCode`,
    method: 'get',
    params
  })
}


// 获取措施分类树
export const getTreatmentTree = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmriskTreatmentType/tree`,
    method: 'get',
    params
  })
}
// 新增措施分类
export const addTreatmentTree = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmriskTreatmentType/submit`,
    method: 'post',
    data
  })
}


// 删除措施分类树节点
export const deleteTreatmentTree = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmriskTreatmentType/remove?ids=${data.ids}`,
    method: 'post',
  })
}

// 获取措施列表（分页）
export const getRisktreatmentPage = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmriskTreatment/list`,
    method: 'get',
    params
  })
}
// 新增措施列表
export const addRisktreatmentPage = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmriskTreatment/submit`,
    method: 'post',
    data
  })
}
// 新增措施列表 风险管控
export const pmRiskMeasureSubmit = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmRiskMeasure/submit`,
    method: 'post',
    data
  })
}
// 获取措施列表（分页）风险管控
export const pmRiskMeasurePage = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmRiskMeasure/page`,
    method: 'get',
    params
  })
}
// 获取措施详情 风险管控
export const pmRiskMeasureDetail = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmRiskMeasure/detail`,
    method: 'get',
    params
  })
}
// 删除措施列表item 风险管控
export const deletePmRiskMeasure = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmRiskMeasure/remove?ids=${data.ids}`,
    method: 'post',
  })
}
// 获取措施详情
export const getRisktreatmentDetail = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmriskTreatment/detail`,
    method: 'get',
    params
  })
}
// 删除措施列表item
export const deleteRisktreatmentPage = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmriskTreatment/remove?ids=${data.ids}`,
    method: 'post',
  })
}

// 获取风险源列表（不分页）
export const getRisksourceList = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmrisksource/list`,
    method: 'get',
    params
  })
}
// 获取风险源列表（不分页）风险管控
export const getRiskManagementAndControLlist = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmrisksource/riskManagementAndControLlist`,
    method: 'get',
    params
  })
}

// 导出
export const exportList = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmrisklibrary/export`,
    method: 'get',
    params
  })
}

// 获取分类树
export const getRiskSourceTypeTree = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmriskSourceType/typeTree`,
    method: 'get',
    params
  })
}

// 获取措施分类树详情
export const getRiskTreatmentTypeDetail = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmriskTreatmentType/detail`,
    method: 'get',
    params
  })
}

//
// 引入公司列表
export const improtList = (data, organizationId, sourceId) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmrisklibrary/import/company?organizationId=${organizationId}&sourceId=${sourceId}`,
    method: 'post',
    data
  })

}
// /api/sinoma-system/dept/parentDetail?id=1722789594114084878
export const getParentDet = (params) => {
  return request({
    url: `/api/sinoma-system/dept/parentDetail`,
    method: 'get',
    params
  })
}
// /api/sinoma-system-lys/dept/treeByCategorys?categorys=5,1
export const getDetTree = (params) => {
  return request({
    url: `/api/sinoma-system/dept/treeByCategorys`,
    method: 'get',
    params
  })
}
// 获取图片
export const getPmriskmap = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/pmriskmap/detail`,
    method: 'get',
    params
  })
}

// 风险库季度列表
export const riskVersionList = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskVersion/ListAll`,
    method: 'get',
    params
  })
}

// 风险库季度删除
export const riskVersionDelete = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskVersion/delete`,
    method: 'post',
    params: data
  })
}

// 风险库季度新增
export const riskVersionAdd = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskVersion/add`,
    method: 'post',
    data
  })
}

// 引入风险库
export const riskListDeptAdd = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/managementRiskPool/riskListDeptAdd`,
    method: 'post',
    params: data
  })
}

// 获取引用标识库分类
export const getCurrentPlate = () => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/managementRiskPool/getCurrentPlate`,
    method: 'get'
  })
}

// 发起审批后修改状态
export const updateTaskId = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/managementRiskPool/updateTaskId`,
    method: 'post',
    data
  })
}

// 获取最新已发布版本
export const riskLastVersion = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskVersion/latestVersion`,
    method: 'get',
    params: data
  })
}



// 根据组织获取版本列表
export const riskVersionPageByOrgId = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskVersion/ListByOrganizationId`,
    method: 'get',
    params: data
  })
}
// 发布----新版
export const publishRiks = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskpublihmain/submit`,
    method: 'post',
    data
  })
}
// 发布列表
export const riskPublishPage = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskpublihmain/page`,
    method: 'get',
    params: data
  })
}
// 发布风险分类树
export const riskPublishTypeTree = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskpublishtree/sourcetree`,
    method: 'get',
    params: data
  })
}
// 发布风险清单
export const riskPublishListPage = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskpublishlist/page`,
    method: 'get',
    params: data
  })
}
