import request from '@/router/axios';
import ServerNameEnum from "@/util/ServerNameEnum";

// 相关方注册
export function prjuserregisterPage(current, size, params) {
  return request({
    url: ServerNameEnum.SERVER_FX+ `/prjuserregister/page`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

// 审核
export function prjuserregisterUpdate(params) {
    return request({
      url: ServerNameEnum.SERVER_FX+ `/prjuserregister/update`,
      method: 'post',
      data: params
    })
  }

// 批量审批
export function prjuserregisterUpdateBatch(params) {
  return request({
    url: ServerNameEnum.SERVER_FX+ `/prjuserregister/updateBatch`,
    method: 'post',
    data: params
  })
}