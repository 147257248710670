<!--
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-10-24 17:08:43
-->
<template>
  <el-dialog v-dialog-drag :title="title" :modal="true" append-to-body v-if="showFileDialog" :close-on-click-modal="false" :visible.sync="showFileDialog" width="70%">
    <video v-if="soureFileType=='video'" :src="link" controls class="chatList__msg--video"></video>
      <iframe v-else ref="fileIframe" id="iframe" :src="link" frameborder="0" style="width: 100%; height: 100%"></iframe>

    <span slot="footer">
      <el-button size="small" @click="showFileDialog = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: "图片预览",
      },
      fileUrl: {
        type: String,
        default: "",
      },
      soureFileType: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        showFileDialog: false,
        iframeLoading: false,
        link: "",
      };
    },
    methods: {
      close() {
        this.showFileDialog = false;
      },
      showFile(url) {
        this.link = this.soureFileType&&this.soureFileType == 'video' ? url :
          process.env.VUE_APP_BASE_KKFILEURL + "?url=" +
          encodeURIComponent(Base64.encode(url));
        this.showFileDialog = true;
        //exls表格预览隐藏打印按钮
        if (process.env.NODE_ENV === "production") {
          const iframe = this.$refs.fileIframe;
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;
          // 在 iframe 内部隐藏元素
          if (iframeDocument) {
            const elementToHide = iframeDocument.getElementById("button-area");
            if (elementToHide) {
              elementToHide.style.display = "none";
            }
          }
        }
      },
      downFile(url) {
        this.link = this.soureFileType == 'video' ? url :
          process.env.VUE_APP_BASE_KKFILEURL +
          "?url=" +
          encodeURIComponent(Base64.encode(url));
        //exls表格预览隐藏打印按钮
        if (process.env.NODE_ENV === "production") {
          const iframe = this.$refs.fileIframe;
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;
          // 在 iframe 内部隐藏元素
          if (iframeDocument) {
            const elementToHide = iframeDocument.getElementById("button-area");
            if (elementToHide) {
              elementToHide.style.display = "none";
            }
          }
        }
      },
    },
  };

</script>

<style scoped>
  .el-dialog__wrapper {
    overflow: hidden;
  }

  ::v-deep .el-dialog {
    margin-top: 30px !important;
    height: 90%;

  }

  ::v-deep .el-dialog__body {
    height: 80%;
  }

  .chatList__msg--video {
    position: relative;
    max-width: 100%;
    /* min-width: 200px; */
    width: 100%;
    height: 100%;
    /* margin: 10px 0; */
    overflow: hidden;
    border-radius: 5px;
    cursor: pointer;
    display: block;
  }

</style>
