import Layout from '@/page/index/'

export default [
  //数据采集
  {
    path: '/siteManagement',
    component: Layout,
    name: '现场管理',
    children: [

      //相关方出入境新增
      {
        path: '/siteManagement/collaborativeEAExit/edit',
        name: '相关方出入境新增',
        component: () =>
          import('@/views/siteManagement/collaborativeEAExit/form'),
        meta: {
          i18n: '相关方出入境新增'
        }
      },
      {
        path: '/siteManagement/personEAExit/add',
        name: '人员出入境新增',
        component: () =>
          import('@/views/siteManagement/personEAExit/form'),
        meta: {
          i18n: '人员出入境新增'
        }
      },
      {
        path: '/siteManagement/securityLog/edit',
        name: '安全日志',
        component: () =>
          import('@/views/siteManagement/securityLog/edit.vue'),
        meta: {
          i18n: '安全日志'
        }
      },





    ]
  },

]
