import Layout from '@/page/index/'

export default [
  //数据采集
  {
    path: '/hiddenTrouble',
    component: Layout,
    name: '隐患治理',
    children: [
      {
        path: '/eventManagement/edit',
        name: '事故详情',
        component: () =>
          import('@/views/eventManagement/form'),
        meta: {
          i18n: '事故详情'
        }
      },
      {
        path: '/abortive/edit',
        name: '未遂事件',
        component: () =>
          import('@/views/abortive/form'),
        meta: {
          i18n: '未遂事件'
        }
      },
    ]
  }
  ]
