import request from "@/router/axios";

export const getList = (params) => { //详情
  return request({
    url: '/api/sinoma-hse-prj/cnshot/page',
    method: 'get',
    params
  })
}
export const detail = (params) => { //详情
  return request({
    url: '/api/sinoma-hse-prj/cnshot/detail',
    method: 'get',
    params
  })
}
export const save = (data) => { //保存
  return request({
    url: '/api/sinoma-hse-prj/cnshot/save',
    method: 'post',
    data
  })
}
export const update = (data) => { //编辑
  return request({
    url: '/api/sinoma-hse-prj/cnshot/update',
    method: 'post',
    data
  })
}
export const SHOT_TYPE = (key) => {
  return request({
    url: `/api/sinoma-system/dict-biz/dictionary?code=${key}`,
    method: 'get',
  })
}

