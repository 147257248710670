import Layout from '@/page/index/'

export default [
  {
    path: '/cip',
    component: Layout,
    redirect: '/cip/index',
    children: [{
      path: 'index',
      name: '首页',
      meta: {
        i18n: 'dashboard'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/cip/index')
    }]
  },
  // {
  //   path: '/basic',
  //   component: Layout,
  //   redirect: '/basic/dashboard',
  //   children: [{
  //     path: 'dashboard',
  //     name: '门户展示',
  //     meta: {
  //       i18n: 'dashboard'
  //     },
  //     component: () =>
  //       import( /* webpackChunkName: "views" */ '@/views/basic/dashboard')
  //   }]
  // },
  // {
  //   path: '/test',
  //   component: Layout,
  //   redirect: '/test/index',
  //   children: [{
  //     path: 'index',h
  //     name: '测试页',
  //     meta: {
  //       i18n: 'test'
  //     },
  //     component: () =>
  //       import( /* webpackChunkName: "views" */ '@/views/util/test')
  //   }]
  // },
  {
    path: '/safetyAssessment',
    component: Layout,
    redirect: '/add',
    children: [{
      path: 'add',
      name: '安全考核新增',
      meta: {
        i18n: ''
      },
      component: () =>
        import( '@/views/safetyAssessment/add')
    }, {
      path: 'edit',
      name: '安全考核编辑',
      meta: {
        i18n: ''
      },
      component: () =>
        import( '@/views/safetyAssessment/add')
    }, {
      path: 'detail',
      name: '安全考核查看',
      meta: {
        i18n: ''
      },
      component: () =>
        import( '@/views/safetyAssessment/add')
    }]
  },
  {
    path: '/reportSet',
    component: Layout,
    redirect: '/reportSet/index',
    children: [{
      path: 'index',
      name: '填报设置',
      meta: {
        i18n: '填报设置'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/business/reportSet')
    }]
  },
  {
    path: '/reportManager',
    component: Layout,
    children: [

      {
        path: '/reportManager/reportTemplate/add',
        name: '填报模板新增',
        component: () =>
          import('@/views/business/reportManager/reportTemplate/form'),
        meta: {
          i18n: '填报模板新增'
        }
      },
      {
        path: '/reportManager/reportTemplate/edit',
        name: '填报模板编辑',
        component: () =>
          import('@/views/business/reportManager/reportTemplate/form'),
        meta: {
          i18n: '填报模板编辑'
        }
      },
      {
        path: '/reportManager/reportTemplate/view',
        name: '填报模板查看',
        component: () =>
          import('@/views/business/reportManager/reportTemplate/form'),
        meta: {
          i18n: '填报模板查看'
        }
      },

      {
        path: '/reportManager/taskSet/add',
        name: '任务设置新增',
        component: () =>
          import('@/views/business/reportManager/taskSet/form'),
        meta: {
          i18n: '任务设置新增'
        }
      },
      {
        path: '/reportManager/taskSet/edit',
        name: '任务设置编辑',
        component: () =>
          import('@/views/business/reportManager/taskSet/form'),
        meta: {
          i18n: '任务设置编辑'
        }
      },
      {
        path: '/reportManager/taskSet/view',
        name: '任务设置查看',
        component: () =>
          import('@/views/business/reportManager/taskSet/form'),
        meta: {
          i18n: '任务设置查看'
        }
      },

      {
        path: '/reportManager/distributeTasks/detail',
        name: '下发任务详情',
        component: () =>
          import('@/views/business/reportManager/distributeTasks/detail'),
        meta: {
          i18n: '下发任务详情'
        }
      },
      {
        path: '/reportManager/periodicTasks/index',
        name: '周期任务',
        component: () =>
          import('@/views/business/reportManager/periodicTasks/index'),
        meta: {
          i18n: '周期任务'
        }
      },
      {
        path: '/reportManager/periodicTasks/manage',
        name: '任务管理',
        component: () =>
          import('@/views/business/reportManager/periodicTasks/manage'),
        meta: {
          i18n: '任务管理'
        }
      },
      {
        path: '/reportManager/temporaryTasks/index',
        name: '临时任务',
        component: () =>
          import('@/views/business/reportManager/temporaryTasks/index'),
        meta: {
          i18n: '临时任务'
        }
      },

      {
        path: '/reportManager/reportWrite/edit',
        name: '数据填报编辑',
        component: () =>
          import('@/views/business/reportManager/reportWrite/form'),
        meta: {
          i18n: '数据填报编辑'
        }
      },
      {
        path: '/reportManager/reportWrite/view',
        name: '数据填报查看',
        component: () =>
          import('@/views/business/reportManager/reportWrite/form'),
        meta: {
          i18n: '数据填报查看'
        }
      },
      {
        path: '/reportManager/reportWrite/form',
        name: '报表审核',
        component: () =>
          import('@/views/business/reportManager/reportWrite/form'),
      },


      {
        path: '/business/reportManager/fillReport/ecologicalProductionMonthly/add',
        name: '生态环保月报报表',
        component: () => import('@/views/business/reportManager/fillReport/ecologicalProductionMonthly/add.vue'),
        meta: {
          title: '生态环保月报报表',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/fillReport/ecologicalProductionMonthly/view',
        name: '生态环保月报查看',
        component: () => import('@/views/business/reportManager/fillReport/ecologicalProductionMonthly/add.vue'),
        meta: {
          title: '生态环保月报查看',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/fillReport/ecologicalProductionMonthly/edit',
        name: '生态环保月报编辑',
        component: () => import('@/views/business/reportManager/fillReport/ecologicalProductionMonthly/add.vue'),
        meta: {
          title: '生态环保月报编辑',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/reportDistribution/add',
        name: '任务下发详情',
        component: () => import('@/views/business/reportManager/reportDistribution/add.vue'),
        meta: {
          title: '任务下发详情',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/reportDistribution/index',
        name: '任务下发',
        component: () => import('@/views/business/reportManager/reportDistribution/index.vue'),
        meta: {
          title: '任务下发',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/summaryReport/productionEcologicalSummary/index',
        name: '本级项目人员汇总信息',
        component: () => import('@/views/business/reportManager/summaryReport/productionEcologicalSummary'),
        meta: {
          title: '本级项目人员汇总信息',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/fillReport/safeAccidentStatFilling/add',
        name: '安全生产事故报表',
        component: () => import('@/views/business/reportManager/fillReport/safeAccidentStatFilling/add.vue'),
        meta: {
          title: '安全生产事故报表',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/fillReport/safeAccidentStatFilling/view',
        name: '安全生产事故查看',
        component: () => import('@/views/business/reportManager/fillReport/safeAccidentStatFilling/add.vue'),
        meta: {
          title: '安全生产事故查看',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/fillReport/safeAccidentStatFilling/edit',
        name: '安全生产事故编辑',
        component: () => import('@/views/business/reportManager/fillReport/safeAccidentStatFilling/add.vue'),
        meta: {
          title: '安全生产事故编辑',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/summaryReport/safeAccidentStatSummary/index',
        name: '安全生产事故汇总信息',
        component: () => import('@/views/business/reportManager/summaryReport/safeAccidentStatSummary'),
        meta: {
          title: '安全生产事故汇总信息',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/fillReport/safetActionStat/add',
        name: '安全活动统计报表',
        component: () => import('@/views/business/reportManager/fillReport/safetActionStat/add.vue'),
        meta: {
          title: '安全活动统计报表',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/fillReport/safetActionStat/view',
        name: '安全活动统计查看',
        component: () => import('@/views/business/reportManager/fillReport/safetActionStat/add.vue'),
        meta: {
          title: '安全活动统计查看',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/fillReport/safetActionStat/edit',
        name: '安全活动统计编辑',
        component: () => import('@/views/business/reportManager/fillReport/safetActionStat/add.vue'),
        meta: {
          title: '安全活动统计编辑',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/summaryReport/safetActionStatSummary/index',
        name: '安全活动统计汇总信息',
        component: () => import('@/views/business/reportManager/summaryReport/safetActionStatSummary'),
        meta: {
          title: '安全活动统计汇总信息',
          icon: ''
        }
      },

      {
        path: '/business/reportManager/fillReport/hazardInvestigationFilling/add',
        name: '隐患排查治理统计新增',
        component: () => import('@/views/business/reportManager/fillReport/hazardInvestigationFilling/add.vue'),
        meta: {
          title: '隐患排查治理统计新增',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/fillReport/hazardInvestigationFilling/view',
        name: '隐患排查治理统计查看',
        component: () => import('@/views/business/reportManager/fillReport/hazardInvestigationFilling/add.vue'),
        meta: {
          title: '隐患排查治理统计查看',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/fillReport/hazardInvestigationFilling/edit',
        name: '隐患排查治理统计编辑',
        component: () => import('@/views/business/reportManager/fillReport/hazardInvestigationFilling/add.vue'),
        meta: {
          title: '隐患排查治理统计编辑',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/summaryReport/hazardInvestigationSummary/index',
        name: '隐患排查治理统计汇总信息',
        component: () => import('@/views/business/reportManager/summaryReport/hazardInvestigationSummary/index.vue'),
        meta: {
          title: '隐患排查治理统计汇总信息',
          icon: ''
        }
      },

      {
        path: '/business/reportManager/fillReport/governmentDepartmentSafetyProductionFilling/add',
        name: '政府部门生态环保检查及处罚情况新增',
        component: () => import('@/views/business/reportManager/fillReport/governmentDepartmentSafetyProductionFilling/add.vue'),
        meta: {
          title: '政府部门生态环保检查及处罚情况新增',
          icon: ''
        }
      }, {
        path: '/business/reportManager/fillReport/governmentDepartmentSafetyProductionFilling/view',
        name: '政府部门生态环保检查及处罚情况查看',
        component: () => import('@/views/business/reportManager/fillReport/governmentDepartmentSafetyProductionFilling/add.vue'),
        meta: {
          title: '政府部门生态环保检查及处罚情况查看',
          icon: ''
        }
      }, {
        path: '/business/reportManager/fillReport/governmentDepartmentSafetyProductionFilling/edit',
        name: '政府部门生态环保检查及处罚情况编辑',
        component: () => import('@/views/business/reportManager/fillReport/governmentDepartmentSafetyProductionFilling/add.vue'),
        meta: {
          title: '政府部门生态环保检查及处罚情况编辑',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/summaryReport/governmentDepartmentSafetyProductionSummary/index',
        name: '政府部门生态环保检查及处罚情况汇总信息',
        component: () => import('@/views/business/reportManager/summaryReport/governmentDepartmentSafetyProductionSummary/index.vue'),
        meta: {
          title: '政府部门生态环保检查及处罚情况汇总信息',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/fillReport/securityProtectionSafetyFilling/add',
        name: '政府部门安全环保检查及处罚情况新增',
        component: () => import('@/views/business/reportManager/fillReport/securityProtectionSafetyFilling/add.vue'),
        meta: {
          title: '政府部门安全环保检查及处罚情况新增',
          icon: ''
        }
      }, {
        path: '/business/reportManager/fillReport/securityProtectionSafetyFilling/view',
        name: '政府部门安全环保检查及处罚情况查看',
        component: () => import('@/views/business/reportManager/fillReport/securityProtectionSafetyFilling/add.vue'),
        meta: {
          title: '政府部门安全环保检查及处罚情况查看',
          icon: ''
        }
      }, {
        path: '/business/reportManager/fillReport/securityProtectionSafetyFilling/edit',
        name: '政府部门安全环保检查及处罚情况编辑',
        component: () => import('@/views/business/reportManager/fillReport/securityProtectionSafetyFilling/add.vue'),
        meta: {
          title: '政府部门安全环保检查及处罚情况编辑',
          icon: ''
        }
      },
      {
        path: '/business/reportManager/summaryReport/environmentalSafetyProtectionSummary/index',
        name: '政府部门安全环保检查及处罚情况汇总信息',
        component: () => import('@/views/business/reportManager/summaryReport/environmentalSafetyProtectionSummary/index.vue'),
        meta: {
          title: '政府部门安全环保检查及处罚情况汇总信息',
          icon: ''
        }
      },
      {
        path: '/reportManager/reportSummary/form',
        name: '汇总清单',
        component: () =>
          import('@/views/business/reportManager/reportSummary/form'),
        meta: {
          i18n: '汇总清单'
        }
      },
      {
        path: '/reportManager/reportSummary/view',
        name: '汇总清单查看',
        component: () =>
          import('@/views/business/reportManager/reportSummary/form'),
        meta: {
          i18n: '汇总清单查看'
        }
      },
      {
        path: '/reportManager/fillReport/overseasProjectFilling/edit',
        name: '境外项目人员信息编辑',
        component: () =>
          import('@/views/business/reportManager/fillReport/overseasProjectFilling/add.vue'),
        meta: {
          i18n: '境外项目人员信息编辑'
        }
      },
      {
        path: '/reportManager/fillReport/overseasProjectFilling/view',
        name: '境外项目人员信息查看',
        component: () =>
          import('@/views/business/reportManager/fillReport/overseasProjectFilling/add.vue'),
        meta: {
          i18n: '境外项目人员信息查看'
        }
      },
      {
        path: '/business/reportManager/fillReport/domesticProjectFilling/index',
        name: '境内项目人员填报信息',
        component: () =>
          import('@/views/business/reportManager/fillReport/domesticProjectFilling/index.vue'),
        meta: {
          i18n: '境内项目人员填报信息'
        }
      },
      {
        path: '/business/domesticProjectFilling/edit',
        name: '境内项目人员信息编辑',
        component: () =>
          import('@/views/business/reportManager/fillReport/domesticProjectFilling/edit.vue'),
        meta: {
          i18n: '境内项目人员信息编辑'
        }
      },
      {
        path: '/business/domesticProjectFilling/view',
        name: '境内项目人员信息查看',
        component: () =>
          import('@/views/business/reportManager/fillReport/domesticProjectFilling/view.vue'),
        meta: {
          i18n: '境内项目人员信息查看'
        }
      },
      {
        path: '/business/reportManager/summaryReport/domesticProjectSummary/index',
        name: '境内项目人员汇总信息',
        component: () => import('@/views/business/reportManager/summaryReport/domesticProjectSummary/index.vue'),
        meta: {}
      },
      {
        path: '/business/reportManager/summaryReport/overseasProjectSummary/index',
        name: '境外项目人员汇总信息',
        component: () => import('@/views/business/reportManager/summaryReport/overseasProjectSummary/index.vue'),
        meta: {}
      },
      {
        path: '/business/reportManager/fillReportAllocation/index',
        name: '填报报表配置',
        component: () => import('@/views/business/reportManager/fillReportAllocation/index.vue'),
        meta: {}
      },
      {
        path: '/business/reportManager/reportBackspaceLog/index',
        name: '报表回退日志',
        component: () => import('@/views/business/reportManager/reportBackspaceLog/index.vue'),
        meta: {}
      },
    ]
  },
  {
    path: '/previewModel',
    component: Layout,
    children: [{
      path: 'dynamicModel/index',
      name: 'previewModel',
      meta: {
        title: 'previewModel',
        affix: false,
        zhTitle: '功能预览',
        icon: 'icon-ym icon-ym-btn-preview',
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/basic/dynamicModel')
    }
    ]
  },
  {
    path: '/safetyTtandard',
    component: Layout,
    children: [
      {
        path: '/safetyTtandard/jobTicketSecurityMeasures/index',
        name: '作业票安全措施',
        component: () =>
          import('@/views/business/safetyTtandard/jobTicketSecurityMeasures/index'),
        meta: {
          i18n: '作业票安全措施'
        }
      }
    ]
  },
  {
    path: '/safe',
    component: Layout,
    children: [
      {
        path: '/safe/itemList/index/edit',
        name: '项目设置',
        component: () =>
          import('@/views/business/safe/itemList/edit'),
        meta: {
          i18n: '项目设置'
        }
      },
      {
        path: '/safe/itemList/index/addThirdPartyOrganization',
        name: '相关方组织',
        component: () =>
          import('@/views/business/safe/itemList/addThirdPartyOrganization'),
        meta: {
          i18n: '相关方组织'
        }
      }, {
        path: '/safe/itemList/index/addThirduser',
        name: '项目人员',
        component: () =>
          import('@/views/business/safe/itemList/addThirduser'),
        meta: {
          i18n: '项目人员'
        }
      },
      {
        path: '/safe/blacklistOrganization/index/add',
        name: '黑名单组织',
        component: () =>
          import('@/views/business/safe/blacklistOrganization/add'),
        meta: {
          i18n: '黑名单组织'
        }
      },
      {
        path: '/safe/blacklistPersonnel/index/add',
        name: '黑名单人员',
        component: () =>
          import('@/views/business/safe/blacklistPersonnel/add'),
        meta: {
          i18n: '黑名单人员'
        }
      },
    ]
  },
  {
    path: '/dict-horizontal',
    component: Layout,
    redirect: '/dict-horizontal/index',
    children: [{
      path: 'index',
      name: '字典管理',
      meta: {
        i18n: 'dict'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/util/demo/dict-horizontal')
    }]
  }, {
    path: '/dict-vertical',
    component: Layout,
    redirect: '/dict-vertical/index',
    children: [{
      path: 'index',
      name: '字典管理',
      meta: {
        i18n: 'dict'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/util/demo/dict-vertical')
    }]
  }, {
    path: '/monitor',
    component: Layout,
    redirect: '/monitor',
    children: [{
      path: '/monitor/log/usual',
      name: '通用日志',
      meta: {
        i18n: 'usualLog'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/monitor/log/usual')
    }, {
      path: '/log/usualEdit',
      name: '通用日志',
      meta: {
        i18n: 'usualLog'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/monitor/log/usualEdit')
    }, {
      path: '/monitor/log/api',
      name: '接口日志',
      meta: {
        i18n: 'apiLog'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/monitor/log/api')
    },
      {
        path: '/log/apiEdit',
        name: '接口日志',
        meta: {
          i18n: 'apiLog'
        },
        component: () =>
          import( /* webpackChunkName: "views" */ '@/views/monitor/log/apiEdit')
      }, {
        path: '/monitor/log/error',
        name: '错误日志',
        meta: {
          i18n: 'errorLog'
        },
        component: () =>
          import( /* webpackChunkName: "views" */ '@/views/monitor/log/error')
      },
      {
        path: '/log/errorEdit',
        name: '错误日志',
        meta: {
          i18n: 'errorLog'
        },
        component: () =>
          import( /* webpackChunkName: "views" */ '@/views/monitor/log/errorEdit')
      }]
  }, {
    path: '/info',
    component: Layout,
    redirect: '/info/index',
    children: [{
      path: 'index',
      name: '个人信息',
      meta: {
        i18n: 'info'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/system/userinfo')
    }]
  }, {
    path: "prjSafetyHandover",
    component: Layout,
    children: [
      {
        path: "/business/prjSafetyHandover/form",
        name: "安全技术交底",
        component: () =>
          import("@/views/business/prjSafetyHandover/form.vue"),
        // meta: {
        //   title: "安全技术交底",
        //   icon: "",
        // },
      },
    ],
  }, {
    path: "synthesis/index",
    component: Layout,
    children: [
      {
        path: "/synthesis/index",
        name: "项目概览",
        component: () =>
          import("@/views/synthesis/index.vue"),
        // meta: {
        //   title: "安全技术交底",
        //   icon: "",
        // },
      },
    ],
  },
  {
    path: "dangerousProjectList/form",
    component: Layout,
    children: [
      {
        path: "/business/safe/dangerousProjectList/form",
        name: "危大工程清单",
        component: () =>
          import("@/views/business/safe/dangerousProjectList/form.vue"),
        // meta: {
        //   title: "安全技术交底",
        //   icon: "",
        // },
      },
    ],
  },
]

/*{
  path: '/system',
    component: Layout,
  redirect: '/system',
  children: [{
  path: '/user',
  name: '用户管理',
  meta: {
    i18n: 'user'
  },
  component: () =>
    import( /!* webpackChunkName: "views" *!/ '@/views/system/user')
}]
}*/
