<template>
  <div style="padding: 0 15px;" @click="toggleClick">
    <i class="hamburger" :class="isActive ? 'el-icon-s-unfold':'el-icon-s-fold'"></i>
  </div>
</template>

<script>
export default {
  name: 'Hamburger',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleClick() {
      this.$emit('toggleClick')
    }
  }
}
</script>

<style scoped>
.hamburger {
  display: inline-block;
  vertical-align: middle;
  font-size: 26px;
  color: #000000;
  cursor: pointer;
}

.hamburger.is-active {
  transform: rotate(180deg);
}
</style>
