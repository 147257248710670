var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-container syButton" },
    [
      _vm._l(_vm.buttonList, function (item, index) {
        return _vm.isShow(item)
          ? _c(
              "el-button-group",
              { key: index, staticClass: "item padd-l-10 padd-r-10" },
              [
                item.slot
                  ? _vm._t(item.slot, null, null, item)
                  : _c(
                      "el-button",
                      {
                        key: index,
                        staticClass: "margin-r-5",
                        attrs: {
                          size: item.size || _vm.size,
                          loading: item.loading,
                          type: item.type || "text",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.btnHandle(item)
                          },
                        },
                      },
                      [
                        item.icon ? _c("i", { class: item.icon }) : _vm._e(),
                        item.CustomIcon
                          ? _c(
                              "svg-icon",
                              _vm._b(
                                {
                                  attrs: {
                                    className: "svg-icon-box",
                                    "icon-class": item.CustomIcon,
                                    size: "16",
                                  },
                                },
                                "svg-icon",
                                item,
                                false
                              )
                            )
                          : _vm._e(),
                        _vm._v("\n      " + _vm._s(_vm.labelHandle(item))),
                      ],
                      1
                    ),
              ],
              2
            )
          : _vm._e()
      }),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }