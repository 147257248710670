import Layout from '@/page/index/'
export default [{
  path: '/datacenter',
  component: Layout,
  children: [
    {
      path: 'programManage/edit',
      name: '方案管理',
      meta: {
        icon:'',
        title: "方案管理"
      },
      component: () =>
        import('@/views/business/datacenter/datacenter/programManage/add.vue'),
    },
    // {
    //   path: 'metadata/datasource',
    //   name: '数据源',
    //   meta: {
    //     icon:'',
    //     title: "数据源"
    //   },
    //   component: () =>
    //     import('@/views/metadata/datasource/index.vue'),
    // },
    // {
    //   path: 'metadata/datacolumn',
    //   name: '元数据',
    //   meta: {
    //     icon:'',
    //     title: "元数据"
    //   },
    //   component: () =>
    //     import('@/views/metadata/datacolumn/index.vue'),
    // },
    {
      path: '/business/datacenter/datacenter/market/dataapi/index',
      name: '数据服务',
      meta: {
        i18n: 'dataapi'
      },
      component: () =>
        import('@/views//business/datacenter/datacenter/market/dataapi/index'),
    },
    // {
    //   path: 'metadata/changerecord',
    //   name: '变更记录',
    //   meta: {
    //     icon:'',
    //     title: "变更记录"
    //   },
    //   component: () =>
    //     import('@/views/metadata/changerecord/index.vue'),
    // }
  ]
}]
