import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
const opinion = ServerNameEnum.SERVER_WORK_FLOW_+'/process/opinion'

//查询全部-用于下拉框
export const getList = () => {
  return request({
    url: `${opinion}/list`,
    method: 'get',
  })
}

//查询分页
export const getPage = (current, size, params) => {
  return request({
    url: `${opinion}/page`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

//提交数据
export const submit = (row) => {
  return request({
    url: `${opinion}/submit`,
    method: 'post',
    data: row
  })
}

//删除
export const remove = (ids) => {
  return request({
    url: `${opinion}/remove`,
    method: 'post',
    params: {
      ids,
    }
  })
}

