var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SNOWS-table-box", class: [_vm.config.__config__.type] },
    [
      _vm.config.__config__.showTitle && _vm.config.__config__.label
        ? _c("div", { staticClass: "SNOWS-common-title" }, [
            _c("h2", [_vm._v(_vm._s(_vm.config.__config__.label))]),
          ])
        : _vm._e(),
      _c(
        "el-table",
        _vm._b(
          {
            ref: "formTable",
            staticClass: "SNOWS-common-table",
            attrs: {
              data: _vm.tableFormData,
              "show-summary": _vm.config["show-summary"],
              "summary-method": _vm.getTableSummaries,
              size: "mini",
            },
            on: { "cell-click": _vm.focusInput },
          },
          "el-table",
          _vm.config.tableConf || {},
          false
        ),
        [
          _c("el-table-column", {
            attrs: { width: "50", align: "center", label: "序号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "row-action" }, [
                      _c(
                        "span",
                        {
                          staticClass: "index",
                          class: { "btn-disabled": _vm.disabled },
                        },
                        [_vm._v(" " + _vm._s(scope.$index + 1))]
                      ),
                      !_vm.config.disabled
                        ? _c("i", {
                            staticClass: "el-icon-delete delete-btn",
                            staticStyle: {
                              color: "#C5211B",
                              "margin-left": "6px",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.removeRow(scope.$index)
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._l(_vm.tableData, function (head, cIndex) {
            return [
              !head.__config__.noShow &&
              (!head.__config__.visibility ||
                (Array.isArray(head.__config__.visibility) &&
                  head.__config__.visibility.includes("pc")))
                ? _c(
                    "el-table-column",
                    {
                      key: head.__config__.formId,
                      attrs: {
                        "min-width": head["min-width"],
                        prop: head.__vModel__,
                        width: head.__config__.columnWidth,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                ["select", "checkbox", "radio"].includes(
                                  head.__config__.jnpfKey
                                )
                                  ? [
                                      _c(
                                        "el-select",
                                        _vm._b(
                                          {
                                            attrs: {
                                              filterable: "",
                                              rowIndex: scope.$index,
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.onFormBlur(
                                                  scope.$index,
                                                  cIndex,
                                                  "el-select"
                                                )
                                              },
                                              change: function ($event) {
                                                return _vm.onFormDataChange(
                                                  scope.$index,
                                                  cIndex,
                                                  "el-select",
                                                  arguments
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.tableFormData[scope.$index][
                                                  cIndex
                                                ].value,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.tableFormData[
                                                    scope.$index
                                                  ][cIndex],
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tableFormData[scope.$index][cIndex].value",
                                            },
                                          },
                                          "el-select",
                                          _vm.getConfById(
                                            head.__config__.formId,
                                            scope.$index
                                          ),
                                          false
                                        ),
                                        _vm._l(
                                          _vm.tableFormData[scope.$index][
                                            cIndex
                                          ].options,
                                          function (opt, oIndex) {
                                            return _c("el-option", {
                                              key: oIndex,
                                              attrs: {
                                                label:
                                                  opt[
                                                    head.__config__.props.label
                                                  ],
                                                value:
                                                  opt[
                                                    head.__config__.props.value
                                                  ],
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  : head.__config__.jnpfKey === "comInput"
                                  ? [
                                      _c(
                                        "el-input",
                                        _vm._b(
                                          {
                                            attrs: { rowIndex: scope.$index },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.onFormBlur(
                                                  scope.$index,
                                                  cIndex,
                                                  "el-input"
                                                )
                                              },
                                              change: function ($event) {
                                                return _vm.onFormDataChange(
                                                  scope.$index,
                                                  cIndex,
                                                  "el-input",
                                                  arguments
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.tableFormData[scope.$index][
                                                  cIndex
                                                ].value,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.tableFormData[
                                                    scope.$index
                                                  ][cIndex],
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tableFormData[scope.$index][cIndex].value",
                                            },
                                          },
                                          "el-input",
                                          _vm.getConfById(
                                            head.__config__.formId,
                                            scope.$index
                                          ),
                                          false
                                        ),
                                        [
                                          head.__slot__
                                            ? [
                                                head.__slot__.prepend
                                                  ? _c(
                                                      "template",
                                                      { slot: "prepend" },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              head.__slot__
                                                                .prepend
                                                            ) +
                                                            "\n                "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                head.__slot__.append
                                                  ? _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              head.__slot__
                                                                .append
                                                            ) +
                                                            "\n                "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  : head.__config__.jnpfKey === "treeSelect"
                                  ? [
                                      _c("SNOWS-TreeSelect", {
                                        attrs: {
                                          options:
                                            _vm.tableFormData[scope.$index][
                                              cIndex
                                            ].options,
                                          props: head.props.props,
                                          placeholder: head.placeholder,
                                          clearable: head.clearable,
                                          multiple: head.multiple,
                                          filterable: head.filterable,
                                          disabled: head.disabled,
                                        },
                                        model: {
                                          value:
                                            _vm.tableFormData[scope.$index][
                                              cIndex
                                            ].value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tableFormData[scope.$index][
                                                cIndex
                                              ],
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tableFormData[scope.$index][cIndex].value",
                                        },
                                      }),
                                    ]
                                  : head.__config__.jnpfKey === "cascader"
                                  ? [
                                      _c("el-cascader", {
                                        attrs: {
                                          options:
                                            _vm.tableFormData[scope.$index][
                                              cIndex
                                            ].options,
                                          props: head.props.props,
                                          placeholder: head.placeholder,
                                          clearable: head.clearable,
                                          "show-all-levels":
                                            head["show-all-levels"],
                                          separator: head.separator,
                                          filterable: head.filterable,
                                          disabled: head.disabled,
                                        },
                                        model: {
                                          value:
                                            _vm.tableFormData[scope.$index][
                                              cIndex
                                            ].value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tableFormData[scope.$index][
                                                cIndex
                                              ],
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tableFormData[scope.$index][cIndex].value",
                                        },
                                      }),
                                    ]
                                  : _c(
                                      head.__config__.tag,
                                      _vm._b(
                                        {
                                          tag: "component",
                                          attrs: {
                                            rowIndex: scope.$index,
                                            tableVModel: _vm.config.__vModel__,
                                            componentVModel: head.__vModel__,
                                            formData: _vm.formData,
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.onFormBlur(
                                                scope.$index,
                                                cIndex,
                                                head.__config__.tag
                                              )
                                            },
                                            change: function ($event) {
                                              return _vm.onFormDataChange(
                                                scope.$index,
                                                cIndex,
                                                head.__config__.tag,
                                                arguments
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.tableFormData[scope.$index][
                                                cIndex
                                              ].value,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tableFormData[scope.$index][
                                                  cIndex
                                                ],
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tableFormData[scope.$index][cIndex].value",
                                          },
                                        },
                                        "component",
                                        _vm.getConfById(
                                          head.__config__.formId,
                                          scope.$index
                                        ),
                                        false
                                      )
                                    ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          !_vm.tableFormData[scope.$index][
                                            cIndex
                                          ].valid,
                                        expression:
                                          "!tableFormData[scope.$index][cIndex].valid",
                                      },
                                    ],
                                    staticClass: "error-tip",
                                  },
                                  [_vm._v("\n            不能为空\n          ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.tableFormData[scope.$index][
                                            cIndex
                                          ].valid &&
                                          !_vm.tableFormData[scope.$index][
                                            cIndex
                                          ].regValid,
                                        expression:
                                          "tableFormData[scope.$index][cIndex].valid && !tableFormData[scope.$index][cIndex].regValid",
                                      },
                                    ],
                                    staticClass: "error-tip",
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.tableFormData[scope.$index][
                                            cIndex
                                          ].regErrorText
                                        ) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        head.__config__.required
                          ? _c("span", { staticStyle: { color: "#f56c6c" } }, [
                              _vm._v("*"),
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n          " +
                            _vm._s(head.__config__["label"]) +
                            "\n        "
                        ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      !_vm.disabled
        ? _c(
            "div",
            {
              staticClass: "table-actions",
              on: {
                click: function ($event) {
                  return _vm.addItem()
                },
              },
            },
            [
              _c(
                "el-button",
                { attrs: { type: "text", icon: "el-icon-plus" } },
                [_vm._v(" " + _vm._s(_vm.config.actionText))]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.selectDialogVisible
        ? _c("SelectDialog", {
            ref: "selectDialog",
            attrs: { config: _vm.config.addTableConf, formData: _vm.formData },
            on: { select: _vm.addForSelect },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }