import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_NAME = "/appIndexIntf";
/**
 * 分页查询
 * @param current 页码
 * @param size  每页数量
 * @param params  查询条件
 */
export const selectPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}/${CONTROLLER_NAME}/selectPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
/**
 * 分页查询
 * @param current 页码
 * @param size  每页数量
 * @param params  查询条件
 */
export const selectLinePage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}/${CONTROLLER_NAME}/selectLinePage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

/**
 * 检查接口编码是否存在
 * @param params  编码
 * @returns AxiosPromise
 */
export const checkInterfaceCodeExits = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/checkInterfaceCodeExits`,
    method: 'post',
    data
  })
}
/**
 * 删除
 * @param
 * @returns AxiosPromise
 */
export const remove= (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/remove`,
    method: 'post',
    params: {
      ids,
    }
  })
}

/**
 * 新增
 * @param
 * @returns AxiosPromise
 */
export const saveOrUpdate= (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/saveOrUpdate`,
    method: 'post',
    data
  })
}

export const selectById = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/detail`,
    method: 'get',
    params: {
      id
    }
  })
}
