export default {
  plat: {
    sys: {
      top: {
        field: {
          navigationMode: "导航模式",
          colorBlock: "颜色区块",
          themeColors: "主题颜色",
          otherSettings: "其他设置",
          multiTabOpeningMode: "多页签开启模式",
          language: "显示多语言选择",
          search: "显示页面搜索框",
          stripe: "显示表格斑马纹",
          broadside: "侧边导航",
          top: "顶部导航",
          mix: "混合导航",
          dark: "暗黑模式",
          brightening: "亮白模式",
          blue: "经典蓝",
          purple: "玫紫",
          azure: "湛蓝",
          ocean: "海洋",
          green: "生机",
          yellow: "丰收",
          orange: "阳橙",
          red: "热情"
        },
      },
      user: {
        field: {
          selectDept: "选择部门",
          subjectCode: "专业编码",
          subjectName: "专业名称",
          postLevelCode: "职级编码",
          postLevelName: "职级名称",
          dutyCode: "职责编码",
          dutyName: "职责名称",
          shiftTypeCode: "轮班方式编码",
          shiftTypeName: "轮班方式名称",
          areaCode: "管理区域编码",
          areaName: "管理区域名称",
          workSectCode: "工段编码",
          workSectName: "工段名称",
          classCode: "班组编码",
          className: "班组名称",
          extend: "用户拓展",
          roleConfig: "用户角色配置",
          userImport: "用户数据导入",
          download: "点击下载",
          platformConfig: "用户平台配置",
          title: "标题",
          customize: "自定义项",
          account: "登陆账号",
          realName: "用户姓名",
          realCode: "用户编码",
          userType: "用户平台",
          tenantName: "所属租户",
          roleName: "所属角色",
          deptName: "所属部门",
          dept:"部门",
          detailInfo: "详细信息",
          name: "用户昵称",
          phone: "手机号码",
          email: "email",
          sex: "用户性别",
          man: "男",
          women: "女",
          unknow: "未知",
          enable: "启用",
          disable: "失效",
          birthday: "用户生日",
          statusName: "账号状态",
          dutyInfo: "职责信息",
          additionalInfo: "附加信息",
          code: "用户编号",
          postId: "所属岗位",
          password: "密码",
          password2: "确认密码",
          inputPassword: "请输入密码",
          checkPassword: "请再次输入密码",
          uncheckPassword: "两次输入密码不一致",
          contactNumber: "请输入正确的手机号码",
          config: "配置",
          userExt: "用户拓展",
          excelFile: "模板上传",
          waiting: "模板上传中，请稍等",
          format: "请上传 .xls,.xlsx 标准格式文件",
          isCovered: "数据覆盖",
          yes: "是",
          no: "否",
          excelTemplate: "模板下载",
          restAccount: "确定将选择账号解封?",
          exportData: "是否导出用户数据?",
          notice: "提示",
          userData: "用户数据表",
          userDataTemplate: "用户数据模板",
          gestureUnlock: "手势解锁",
          passwordUnlock: "密码解锁",
          isEnabled: "是否启用",
          voluntarily: "App自动带入",
          contentFormat: "参数规则",
          operate: "操作",
          identityCard: "身份证号",
        },
        btn: {
          restPassword: "密码重置",
          accountConfig: "账号解封",
          enable: "启用",
          disable: "失效",
          export: "导出",
          import: "导入",
          roleConfig: "角色配置",
        },
        title: {
          baseInfoTitle: "基础信息",
          dutyInfoTitle: "职责信息",
          detailInfoTitle: "详细信息",
          treeTitle: "机构列表",
          indexHeadTitle: "用户管理",
          editHeadTitle: "用户编辑",
          addHeadTitle: "用户新增",
          extendTitle: "用户拓展",
        },
        msg: {
          nameLength: "姓名长度在2~50个字符",
          nameLengths: "姓名长度在2~20个字符",
          restPassword: "确定将该账号密码重置为123456",
          nickName: "用户昵称长度不能大于20",
          iPSWarning: "初始密码成功",
          dlocWarning: "账号解锁成功",
          CfgWarning: "账号解锁成功",
          ioaWarning: "权限配置成功",
          onlyEnglish: "仅支持英语的大小写和数字输入",
          enable: "确定将选择账号启用?",
          disable: "确定将选择账号失效?",
          enableSuccess: "账号启用成功",
          disableSuccess: "账号禁用成功",
          SupportNumbers: "请输入正确的租户(6位数字组成)",
          enterTenant: "请输入租户ID",
          userName: "请输入用户名",
          passWord: "请输入密码",
          passwordLength: "密码长度最少为6位",
          updatePassword: "修改密码成功!",
        }
      },
      //租户管理
      tenant: {
        field: {
          min: "不能小于1的数据",
          authTitle: "租户授权配置",
          dataSourceTitle: "租户数据源配置",
          packageSetTitle: "租户产品包配置",
          packageManageTitle: "租户产品包管理",
          datasource: "数据源",
          packageName: "产品包",
          menuView: "菜单预览",
          tenantId: "租户编码",
          tenantName: "租户名称",
          linkman: "联系人",
          contactNumber: "联系电话",
          address: "联系地址",
          accountNumber: "账号额度",
          expireTime: "过期时间",
          domainUrl: "绑定域名",
          tenantIdMessage: "租户Id保存后自动生成",
          backgroundUrl: "系统背景"
        },
        title: {
          indexHeadTitle: "租户管理",
          editHeadTitle: "租户管理编辑",
          addHeadTitle: "租户管理新增",
        },
        btn: {
          authSetting: "授权配置",
          dataSourceSetting: "数据源配置",
          packageSetting: "产品包配置",
          packageManage: "产品包管理",
        }
      },
      dept: {
        field: {
          deptName: "机构名称",
          tenantId: "所属租户",
          fullName: "机构全称",
          parentId: "上级机构",
          deptCategory: "机构类型",
          deptCode: "机构编码",
          enterpriseType: "企业类型",
          enterpriseLevel: "企业层级",
          companyType: "公司类型",
          businessType: "业务类型",
          companyLocation: "公司位置",
          isEnable: "是否启用",
          yes: "是",
          no: "否",
          sort: "排序",
          remark: "备注",
        },
        title: {
          indexHeadTitle: "机构管理",
          editHeadTitle: "机构编辑",
          addHeadTitle: "机构新增",
        },
        msg: {
          isEnable: "有下属机构正在启用，请先关闭下属机构",
        }
      },
      post: {
        field: {
          category: "岗位类型",
          postCode: "岗位编号",
          postName: "岗位名称",
          tenantId: "所属租户",
          sort: "岗位排序",
          remark: "岗位备注",
          isEnable: "是否启用",
        },
        title: {
          indexHeadTitle: "岗位管理",
          editHeadTitle: "岗位编辑",
          addHeadTitle: "岗位新增",
        },
      },
      dict: {
        field: {
          code: "字典编号",
          dictValue: "字典名称",
          sort: "字典排序",
          isSealed: "是否启用",
          remark: "字典备注",
          parentId: "上级字典",
          dictKey: "字典键值",
          yes: "是",
          no: "否"
        },
        title: {
          indexHeadTitle: "系统字典",
          editHeadTitle: "系统字典编辑",
          addHeadTitle: "系统字典新增",
          bizIndexHeadTitle: "业务字典",
          bizEditHeadTitle: "业务字典编辑",
          bizAddHeadTitle: "业务字典新增",
          indexConfigHeadTitle: "字典项配置",
          editConfigHeadTitle: "字典配置编辑",
          addConfigHeadTitle: "字典配置新增",
        },
      },
      menu: {
        field: {
          typeSetting: "排版方式",
          isLink: "是否外链",
          platform: "使用平台",
          name: "菜单名称",
          path: "路由地址",
          parentId: "上级菜单",
          source: "菜单图标",
          code: "菜单编号",
          category: "菜单类型",
          catelog: "目录",
          filePath: "图片上传",
          loadText: "点击上传",
          color: "背景色",
          menus: "菜单",
          button: "按钮",
          alias: "菜单别名",
          isOpen: "新窗口",
          status: "是否启用",
          yes: "是",
          no: "否",
          sort: "菜单排序",
          remark: "菜单备注",
          isOnline: "是否在线表单",
        },
        title: {
          indexHeadTitle: "菜单管理",
          editHeadTitle: "菜单编辑",
          addHeadTitle: "菜单新增",
        },
      },
      topMenu: {
        field: {
          menuId: "绑定菜单",
          name: "菜单名称",
          code: "菜单编号",
          source: "菜单图标",
          sort: "菜单排序",
          yes: "是",
          no: "否",
        },
        btn: {
          menuSetting: "菜单配置"
        },
        title: {
          indexHeadTitle: "顶部菜单",
          subDialogTitle: "下级菜单配置"
        },
      },
      param: {
        field: {
          paramName: "参数名称",
          paramKey: "参数键名",
          paramValue: "参数键值",
        },
        title: {
          indexHeadTitle: "参数管理",
          editHeadTitle: "参数编辑",
          addHeadTitle: "参数新增",
          viewHeadTitle: "参数查看",
        },
      },
      knowledge: {
        field: {
          remark: "备注",
          filePath: "文件上传",
          knowledgeCode: "知识库编码",
          knowledgeName: "知识库名称",
          parentCode: "上级类型",
          knowledgeTypeCode: "类型编码",
          knowledgeTypeName: "类型名称",
        },
        title: {
          indexHeadTitle: "知识库管理",
          editHeadTitle: "知识库编辑",
          addHeadTitle: "知识库新增",
          viewHeadTitle: "知识库查看",
          treeTitle: "知识库类型",
          treeHeadCateTitle: "题库分类管理",
          knowledgeTitle: "知识库",
        },
        msg: {
          loadText: "图片上传中，请稍等",
        }
      },
      defaultConfig: {
        field: {
          configName: "配置名称",
          navigateMode: "导航模式",
          colorBlock: "颜色区块",
          themeClass: "主题颜色",
          multiTab: "多页签模式",
          searchBox: "页面搜索框",
          tableStyle: "表格斑马纹",
          isEnable: "状态",
          remark: "备注",
        },
        title: {
          indexHeadTitle: "默认配置",
          editHeadTitle: "默认配置编辑",
          addHeadTitle: "默认配置新增",
          viewHeadTitle: "默认配置查看",
        },
        msg: {
          config: "默认配置正在启用中，请禁用后删除",
        }
      },
      dataStandard: {
        field: {
          dictCode: "字典编码",
          dictName: "字典名称",
          englishAbbreviation: "英文简称",
          englishName: "英文名称",
          synonym: "同义词",
          remark: "备注",
        },
        title: {
          indexHeadTitle: "数据标准",
          editHeadTitle: "数据标准编辑",
          addHeadTitle: "数据标准新增",
          viewHeadTitle: "数据标准查看",
        },
        msg: {
          dictCode: "字典编码保存后自动生成",
        }
      },
      client: {
        field: {
          clientId: "应用ID",
          clientSecret: "应用秘钥",
          authType: "授权类型",
          authScope: "授权范围",
          accessTokenValidity: "令牌秒数",
          refreshTokenValidity: "刷新秒数",
          webServerRedirectUri: "回调地址",
          resourceIds: "资源集合",
          authorities: "权限",
          autoapprove: "自动授权",
          additionalInformation: "附加说明"
        },
        title: {
          indexHeadTitle: "应用管理",
          editHeadTitle: "应用编辑",
          addHeadTitle: "应用新增",
          viewHeadTitle: "应用查看",
        },
      },
      params: {
        field: {
          content: "富文本内容",
          paramCode: "参数编码",
          paramName: "参数名称",
          filePath: "图片上传",
          loadText: "图片上传中，请稍等",
          typeError: "模板格式有误",
          paramType: "参数类型",
          paramValue: "参数值",
          releaseTime: "日期",
          textareaRich: "富文本参数值",
          remark: "备注",
          status: "状态",
          textarea: "英文",
          enable: "启用",
          disable: "禁用",
          disenble: "未启用"
        },
        title: {
          indexHeadTitle: "系统参数",
          editHeadTitle: "系统参数编辑",
          addHeadTitle: "系统参数新增",
        },
      },
      onlineUser: {
        field: {
          username: "用户名",
          realname: "真实姓名",
          account: "登录账号",
          client_ip: "登录IP",
          login_time: "登录时间",
        },
        btn: {
          unlineBtn: "下线"
        },
        title: {
          indexHeadTitle: "在线用户",
        },
        msg: {
          unline_tip: "确认将此用户下线？",
        }
      },
      international: {
        field: {
          name: "名称",
          content: "内容",
          url: "路径",
        },
        title: {
          indexHeadTitle: "国际化文件列表",
          editHeadTitle: "国际化文件编辑",
          viewHeadTitle: "国际化文件查看",
        },
      },
      serialNumber: {
        field: {
          ruleCode: "规则编码",
          ruleName: "规则名称",
          ruleType: "流水号重置规则",
          serialLength: "流水号长度",
          currentValue: "当前值",
          initValue: "初始值",
          step: "步长",
          prefix: "前缀",
          curTime: "当前时间",
          systemTime: "系统时间",
          split: "分割符",
        },
        title: {
          indexHeadTitle: "编码生成规则",
          editHeadTitle: "编码生成规则编辑",
          addHeadTitle: "编码生成规则新增",
          viewHeadTitle: "编码生成规则查看",
          baseTitle: "基本信息",
        },
        msg: {
          select_warning: "请输入规则编号",
        },
      },
      feedback: {
        field: {
          feedback: "意见反馈",
          disposeResult: "处理结果",
          handler: "处理人",
          opinion: "意见",
          screenshot: "屏幕截图",
          disposeTime: "处理时间",
          createTime: "提交时间",
          submitter: "提交人",
        },
        title: {
          indexHeadTitle: "意见反馈",
          editHeadTitle: "意见反馈编辑",
        },
        msg: {
          suggest: "请告诉我们遇到的问题或建议",
        }
      },
      webDesign: {},
      dataLink: {
        field: {
          name: "名称",
          host: "主机",
          port: "端口",
          driver: "驱动",
          user: "用户名",
          password: "密码",
          dbName: "数据库名称",
          linkName: "连接名称",
          sort: "排序",
          remark: "备注"
        },
        btn: {
          testBtn: "测试连接"
        },
        title: {
          indexHeadTitle: "数据连接",
          editHeadTitle: "数据连接编辑",
          addHeadTitle: "数据连接新增",
        },
      },
      dataInterface: {
        field: {
          handling: "数据处理",
          Query: "查询",
          Add: "增加",
          Modify: "修改",
          Delete: "删除",
          staticData: "静态数据",
          SQLOperations: "SQL操作 ",
          APIOperations: "API操作",
          type: "类型",
          action: "动作",
          sort: "排序",
          status: "状态",
          description: "备注",
          prev: "上一步",
          next: "下一步",
          dataSource: "数据源",
          categoryId: "分类",
          fullName: "接口名称",
          enCode: "接口编码",
          dataType: "接口类型",
          responseType: "返回类型",
          requestMethod: "请求方式",
          changeDB: "选择数据库",
          SQL: "SQL语句",
          // content: "支持SQL语句&存储过程语句",
          systemVariables: "系统变量",
          param: "接口参数",
          reviceMeth: "接收方式:Body/json",
          drag: "拖动",
          clickDrag: "点击拖动",
          paramName: "参数名称",
          paramType: "参数类型",
          paramDescription: "参数说明",
          char: "字符串",
          int: "整型",
          date: "日期时间",
          decimal: "浮点",
          bigint: "长整型",
          text: "文本",
          make: "操作",
          addParam: "添加参数",
          interfaceType: "接口类型",
          interfacePath: "接口路径",
          addHeader: "添加headers",
          interfaceParam: "接口参数",
          defaultValue: "默认值",
          isRequired: "是否必填",
          user: "当前用户",
          organization: "当前组织",
          currentOrganizationAndSuborganization: "当前组织及子组织",
        },
        btn: {
          previewBtn: "预览",
          TestBtn: "测试接口",
        },
        title: {
          indexHeadTitle: "数据接口",
          editHeadTitle: "数据接口编辑",
          addHeadTitle: "数据接口新增",
          treeTitle: "数据接口分类",
          addDialogTitle: "新建参数",
          editDialogTitle: "编辑参数",
          previewTitle: "接口预览 - ",
          basicInfo: "基本信息",
          dataConf: "数据配置",
        },
        msg: {
          chooseCategory: "请选择接口分类",
          disabled: "接口已被禁用，请先开启接口",
          repeat: "参数名称重复",
          ipAddress: "请输入域名，多个域名用逗号隔开",
          msg: "当前系统变量仅支持内部接口引用",
          JSmsg: "1、支持JavaScript的脚本",
          JSmsg1: "2、小程序不支持在线JS脚本",
          statement: "请输入SQL查询语句或静态数据",
          dataSource: "请添加数据源！",
          delParam: "此操作将删除该参数, 是否继续?",
          data: "(data) => {\r\n    // 处理数据逻辑\r\n\r\n    // 返回所需的数据\r\n    return data\r\n}",
        }
      },
      visual3: {
        field: {
          customPath: "自定义路径",
          confCustomPath: "配置静态页面地址",
          page: "页面设计",
          drag: "拖拽生成门户",
          name: "门户名称",
          portal: "所属分类",
          enCode: "门户编码",
          sort: "排序",
          status: "状态",
          remark: "备注",
          basicSet: "基础设置",
          addPortal: "新建门户",
          linkType: "链接类型",
          linkPath: "链接地址",
          title: "标题",
          dataType: "数据类型",
          StaticData: "静态数据",
          RemoteData: "远端数据",
          dataInterface: "数据接口",
          numericalValue: "数值",
          Icon: "图标",
          ComponentProperties: "组件属性",
          option: "选项",
          AddOptions: "添加选项",
          DataSettings: "数据设置",
          select: "选择",
          fullName: "接口名称",
          orderNumber: "序号",
          ProcessName: "流程名称",
          ProcessCode: "流程编码",
          renovate: "刷新",
          nameInterface: "名称",
          code: "编码",
          type: "类型",
          custom: "自定义",
          system: "系统",
          paramName: "参数名称",
          paramType: "参数类型",
          defaultValue: "默认值",
        },
        title: {
          indexHeadTitle: "门户设计",
          PreviewTitle: "门户预览",
          dataSettingsTitle: "数据设置",
          basicControlTitle: "基础控件",
          chartControlTitle: "图表控件",
          allocationTitle: "权限分配",
        },
        msg: {
          titleWarning: "请输入标题",
          menuWarning: "请选择菜单",
          numericalWarning: "请输入数值",
          iconWarning: "请输入图标名称",
          continueWarning: "选项最少要保留两项",
          InterfaceWarning: "请选择数据接口",
          processWarning: "请选择子流程",
          enterWarning: "请输入关键词查询",
          clear: "确定要清空所有吗？",
        },
        btn: {
          inquireBtn: "查询",
          resettingBtn: "重置",
          previewBtn: "预览",
          emptyBtn: "清空",
        },
      },
      portal: {
        field: {
          customPath: "自定义路径",
          confCustomPath: "配置静态页面地址",
          page: "页面设计",
          drag: "拖拽生成门户",
          name: "门户名称",
          portal: "所属分类",
          enCode: "门户编码",
          sort: "排序",
          status: "状态",
          remark: "备注",
          basicSet: "基础设置",
          addPortal: "新建门户",
          linkType: "链接类型",
          linkPath: "链接地址",
        },
        title: {
          indexHeadTitle: "门户设计",
          previewTitle: "门户预览",
        },
        msg: {
          name: "门户名称不能为空",
          enCode: "门户编码不能为空",
          portal: "门户分类不能为空",
        },
      }
    },
    authority: {
      role: {
        field: {
          rolePermissions: "角色权限配置",
          webMenumissions: "WEB菜单权限",
          appMenumissions: "移动端菜单权限",
          dataPermissions: "数据权限",
          protPermissions: "接口权限",
          roleName: "角色名称",
          tenantId: "所属租户",
          roleAlias: "角色编码",
          roleType: "角色平台",
          parentId: "上级角色",
          sort: "角色排序",
          name: "菜单名称",
          path: "路由地址",
          parentIds: "上级菜单",
          source: "菜单图标",
          code: "菜单编码",
        },
        title: {
          indexHeadTitle: "角色清单",
          editHeadTitle: "角色编辑",
          addHeadTitle: "角色新增",
        },
        msg: {
          role: "角色与用户绑定 不可删除",
        }
      },
      dataScope: {
        field: {
          scopeName: "权限名称",
          resourceCode: "权限编号",
          scopeColumn: "权限字段",
          scopeType: "规则类型",
          scopeField: "可见字段",
          scopeClass: "权限类名",
          scopeValue: "规则值",
          remark: "备注",
          name: "菜单名称",
          path: "路由地址",
          parentIds: "上级菜单",
          source: "菜单图标",
          code: "菜单编号",
          category: "菜单类型",
          alias: "菜单别名",
          action: "按钮功能",
          sorts: "菜单排序",
          isOpen: "新窗口",
          remarks: "菜单备注",
          menu: "菜单",
          button: "按钮",
          toolBar: "工具栏",
          actionBar: "操作栏",
          toolActionBar: "工具操作栏",
          yes: "是",
          no: "否",
          allVisible: "全部可见",
          personallyVisible: "本人可见",
          deptVisible: "所在机构可见",
          deptAndChildVisible: "所在机构可见及子级可见",
          custom: "自定义",
        },
        btn: {
          scopeConfBtn: "权限配置",
        },
        title: {
          indexHeadTitle: "数据权限",
          confHeadTitle: "数据权限配置",
        },
        msg: {
          scopeClass: "请输入MybatisMapper对应方法的完整类名路径",
        }
      },
      apiScope: {
        field: {
          scopeName: "权限名称",
          resourceCode: "权限编号",
          scopeType: "接口类型",
          scopePath: "权限路径",
          remark: "备注",
          name: "菜单名称",
          path: "路由地址",
          parentIds: "上级菜单",
          source: "菜单图标",
          code: "菜单编号",
          category: "菜单类型",
          alias: "菜单别名",
          action: "按钮功能",
          sorts: "菜单排序",
          isOpen: "新窗口",
          remarks: "菜单备注",
          menu: "菜单",
          button: "按钮",
          toolBar: "工具栏",
          actionBar: "操作栏",
          toolActionBar: "工具操作栏",
          yes: "是",
          no: "否",
        },
        btn: {
          scopeConfBtn: "权限配置",
        },
        title: {
          indexHeadTitle: "接口权限",
          confHeadTitle: "接口权限配置",
        },
      },
    },
    // 系统运维
    sysOps: {
      stat: {
        field: {
          functionName: "功能名称",
          functionCode: "功能编码",
          operationButton: "操作按钮",
          operationUser: "操作人",
          createTime: "操作时间",
          usageNumber: "使用次数",
        },
        title: {
          functionRecordTitle: "功能记录",
          buttonRecordTitle: "按钮记录",
          functionFrequencyTitle: "功能频次",
          buttonFrequencyTitle: "按钮频次",
        },
      },
    },
    wfOps: {
      //运维调度
      ops: {
        field: {
          nameOfTheProcess: "流程名称",
          ProcessIdentification: "流程标识",
          SerialNumber: "流水号",
          category: "流程分类",
          TheCurrentNode: "当前节点",
          ApplicationTime: "申请时间",
          Approver: "审核人",
          applicant: "申请人",
          StartTime: "开始时间",
          EndTime: "结束时间",
          Status: "状态",
          TimeRange: "时间范围",
          activation: "激活",
          Suspend: "挂起",
          node: "节点",
        },
        btn: {
          flowBtn: "流程图",
          crossBtn: "通过",
          rejectBtn: "驳回",
          transferBtn: "转办",
          entrustBtn: "委托",
          stopBtn: "终止",
          copyBtn: "抄送",
          pressBtn: "催办",
          bgrBtn: "变更审核人",
        },
        title: {
          indexHeadTitle: "运维调度",
          flowChartHeadTitle: "流程图",
          selectHeadTitle: "选择节点",
        },
        msg: {
          signOff: "确定要将选中的任务减签吗？",
          signOffSuccess: "减签成功",
          pressSuccess: "催办成功",
          stopSuccess: "终止成功",
          transferSuccess: "转办成功",
          delegateSuccess: "委托成功",
          copySuccess: "抄送成功",
          addMultiInstanceSuccess: "加签成功",
          cross: "确定要将选中的任务全部<span style='color: red;'>通过</span>吗？",
          reject: "确定要将选中的任务全部<span style='color: red;'>驳回</span>吗？",
          press: "确定要将催办选中的任务吗？若任务没有审核人此操作无效",
          stop: "确定要将选中的任务流程终止吗？",
          bgr: "确定要将选中的任务审核人变更为",
          transfer: "确定要将选中的任务转办给",
          delegate: "确定要将选中的任务委托给",
          copy: "确定要将选中的任务抄送给",
          addMultiInstance: "确定要将选中的人员加签吗？",
        }
      },
      //办结流程
      done: {
        field: {
          nameOfTheProcess: "流程名称",
          ProcessIdentification: "流程标识",
          SerialNumber: "流水号",
          category: "流程分类",
          applicant: "申请人",
          StartTime: "开始时间",
          EndTime: "结束时间",
          Status: "状态",
          finished: "已完成",
          terminate: "已终结",
          withdraw: "已撤销",
          deleted: "已删除",
          applicationInfo: "申请信息",
          flowInfo: "流转信息",
          processTrace: "流程跟踪",
        },
        btn: {
          flowBtn: "流程图",
          detailBtn: "详情",
        },
        title: {
          indexHeadTitle: "办结流程",
          flowChartHeadTitle: "流程图",
        },
        msg: {
          allName: "请输入精确流程名称",
        }
      },
      //流程列表
      process: {
        field: {
          nameOfTheProcess: "流程名称",
          ProcessIdentification: "流程标识",
          SerialNumber: "流水号",
          category: "流程分类",
          applicant: "申请人",
          StartTime: "开始时间",
          EndTime: "结束时间",
          ProcessStatus: "状态",
          detailedCirculation: "流转详细",
          unfinished: "进行中",
          finished: "已完成",
          terminate: "已终结",
          withdraw: "已撤销",
          recall: "已撤回",
          reject: "被驳回",
          deleted: "已删除",
        },
        btn: {
          flowBtn: "流程图",
          detailBtn: "详情",
          processProgressBtn: "流程进度",
          logicalDeletionBtn: "逻辑删除",
          physicalDeletionBtn: "物理删除",
        },
        title: {
          indexHeadTitle: "流程列表",
          flowChartHeadTitle: "流程图",
          processProgressTitle: "流程进度",
        },
        msg: {
          allName: "请输入精确流程名称",
          remove: "<p><span style='color: red;'>逻辑删除</span>：进行中的流程直接结束，但会保留历史数据，标记为已删除。删除后无法恢复成进行中。</p><p><span style='color: red;'>物理删除</span>：完全删除所有数据，无法恢复。</p>",
          reason: "请输入删除原因",
          reasonSuccess: "删除成功",
          reconfirm: "二次确认！此操作将永久删除流程数据且无法恢复！",
          drafts: "保存至草稿箱并关闭?",
        }
      },
      //流程代理
      proxy: {
        field: {
          client: "委托人",
          agent: "代理人",
          process: "流程",
          specifyTheProcess: "指定流程",
          type: "类型",
          startTime: '开始时间',
          endTime: '结束时间',
          effectiveTime: "有效时间",
          status: "状态",
          total: "全部",
          appoint: "指定",
          permanent: "永久",
          timing: "定时",
          available: "可用",
          forbidden: "禁用",
        },
        title: {
          indexHeadTitle: "流程代理",
          editHeadTitle: "流程代理编辑",
          addHeadTitle: "流程代理新增",
        },
      },
    },
    wfDesign: {
      //模型
      model: {
        field: {
          category: "流程分类",
          icon: "图标",
          modelKey: "模型Key",
          name: "模型名称",
          categoryId: "类型",
          description: "描述",
          version: "版本",
          fullScreen: "全屏",
          selectForm: "选择表单",
          formType: "表单类型",
          builtInForm: "内置表单",
          externalForm: "外置表单",
          nodeIndependentForm: "节点独立表单",
          formView: "表单预览",
          exFormKey: "外置表单key",
          formKey: "表单",
          label: "字段",
          prop: "属性",
          readable: "默认可读",
          writable: "默认可写",
        },
        btn: {
          changeTypeBtn: "更改分类",
          designBtn: "设计",
          deployBtn: "部署",
          permissionsBtn: "权限",
          moreBtn: "更多",
          iconBtn: "图标",
          historysBtn: "历史",
          copyBtn: "复制",
          returnBtn: "回退",
          allRemoveBtn: "全部删除",
          previewBtn: "预览",
          mainBtn: "设为主版本",
          prev: "上一步",
          next: "下一步",
        },
        title: {
          indexHeadTitle: "模型设计",
          arrangeHeadTitle: "选择流程分类",
          uploadIconHeadTitle: "上传图标",
          viewIconHeadTitle: "模型预览",
          designFormsHeadTitle: "设计表单",
          designProcessHeadTitle: "设计流程",
          doneHeadTitle: "完成",
        },
        msg: {
          suggestedSize: "建议尺寸：50 * 50",
          requireIcon: "图标必须填写",
          changeType: "请先更改分类",
          setIcon: "设置图标成功",
          copy: "拷贝xml成功",
          deploy: "部署成功",
          remove: "删除全部版本或者回退到最后版本?",
          main: "当前主版本会自动保存到历史，确定要将此版本设为主版本吗?",
          form: "没有想要的表单？点击去设计",
          settings: "可用于控制外置表单字段的显隐配置，如果希望自己控制请忽略此字段",
          script: "使用之前请先了解脚本会带来的危害，若确定使用请参考文档放开此配置。<br>1、脚本中可以完全访问JVM。<br>2、脚本执行时阻塞许多系统资源。<br>3、脚本执行死循环/占用大量内存等会导致程序崩溃。",
          shell: "使用之前请先了解Shell会带来的危害,若确定使用请参考文档放开此配置。<br>因不确定是否可执行危险命令,如rm -rf *，请充分了解之后再使用。",
          node: "节点未正确配置表单",
          save: "是否将此模型保存为新版本？这意味着可以返回到以前的版本",
        }
      },
      //表单
      form: {
        field: {
          formKey: "表单key",
          name: "表单名称",
          remark: "备注",
          status: "状态",
          categoryId: "分类",
          version: "版本",
          available: "可用",
          forbidden: "禁用",
        },
        btn: {
          changeTypeBtn: "更改分类",
          designBtn: "设计",
          historysBtn: "历史",
          copyBtn: "复制",
          returnBtn: "回退",
          allRemoveBtn: "全部删除",
          previewBtn: "预览",
          mainBtn: "设为主版本",
        },
        title: {
          indexHeadTitle: "表单设计",
          editHeadTitle: "表单设计编辑",
          addHeadTitle: "表单设计新增",
          arrangeHeadTitle: "选择分类",
          viewIconHeadTitle: "表单预览",
          copyHeadTitle: "拷贝表单",
        },
        msg: {
          remove: "删除全部版本或者回退到最后版本?",
          main: "当前主版本会自动保存到历史，确定要将此版本设为主版本吗?",
          save: "是否将此表单保存为新版本？这意味着可以返回到以前的版本",
        }
      },
      //部署管理
      deployment: {
        field: {
          name: "表单key",
          key: "表单名称",
          category: "分类",
          version: "版本",
          status: "状态",
          deployTime: "部署时间",
          activate: "激活",
          pending: "挂起",
        },
        btn: {
          changeTypeBtn: "更改分类",
          activateBtn: "激活",
          pendingBtn: "挂起",
        },
        title: {
          indexHeadTitle: "部署管理",
          arrangeHeadTitle: "选择分类",
        },
        msg: {
          remove: "此操作会级联删除当前正在进行的流程实例，且无法恢复，确定要删除吗?",
          reconfirm: "二次确认！此操作会级联删除当前正在进行的流程实例，且无法恢复，确定要删除吗?",
        }
      },
      //流程按钮
      button: {
        field: {
          buttonKey: "key",
          name: "名称",
          display: "是否默认展示",
          sort: "排序",
          status: "状态",
          yes: "是",
          no: "否",
          available: "可用",
          forbidden: "禁用",
        },
        title: {
          indexHeadTitle: "流程按钮",
          editHeadTitle: "流程按钮编辑",
          addHeadTitle: "流程按钮新增",
        },
      },
      //流程分类
      category: {
        field: {
          name: "分类名称",
          parentId: "上级分类",
          sort: "排序",
          status: "状态",
          isEnable: "是否启用",
          available: "可用",
          forbidden: "禁用",
          all: "全部",
        },
        title: {
          indexHeadTitle: "流程分类",
          editHeadTitle: "流程分类编辑",
          addHeadTitle: "流程分类新增",
        },
      },
      //表单默认值
      defaultValues: {
        field: {
          content: "内容",
          name: "分类名称",
          fieldType: "字段类型",
        },
        btn: {
          copy: "复制"
        },
        title: {
          indexHeadTitle: "表单默认值",
          editHeadTitle: "表单默认值编辑",
          addHeadTitle: "表单默认值新增",
        },
        msg: {
          content: "请输入内容，以${}包裹则表示执行代码，请确保代码可执行",
        }
      },
      //流程表达式
      condition: {
        field: {
          name: "名称",
          expression: "表达式",
          type: "类型",
          status: "状态",
          personnelConfiguration: "人员配置",
          flowCondition: "流转条件",
          available: "可用",
          forbidden: "禁用",
        },
        title: {
          indexHeadTitle: "流程表达式",
          editHeadTitle: "流程表达式编辑",
          addHeadTitle: "流程表达式新增",
        },
      },
    },
    workflow: {
      components: {
        userOption: {
          field: {
            type: "类型",
            value: "值",
            user: "用户",
            role: "角色",
            dept: "部门",
            post: "职位",
            platform: "平台",
            h5: "移动端H5",
            mpWX: "微信小程序",
            android: "安卓",
          },
          title: {
            indexHeadTitle: "权限配置",
          },
        },
        userSelect: {
          field: {
            avatar: "头像",
            name: "姓名",
            deptName: "部门",
            postName: "职位",
          },
          title: {
            indexHeadTitle: "人员选择",
          },
        },
      },
    },
    monitor: {
      log: {
        usual: {
          field: {
            serviceId: "服务id",
            serverHost: "服务host",
            serverIp: "服务ip",
            env: "软件环境",
            logLevel: "日志级别",
            logId: "日志id",
            requestUri: "请求接口",
            createTime: "日志时间",
            userAgent: "用户代理",
            logData: "日志数据",
            params: "请求数据",
          },
          title: {
            indexHeadTitle: "通用日志",
          },
        },
        api: {
          field: {
            serviceId: "服务id",
            serverHost: "服务host",
            serverIp: "服务ip",
            env: "软件环境",
            title: "日志名",
            method: "请求方法",
            requestUri: "请求接口",
            createTime: "日志时间",
            userAgent: "用户代理",
            logData: "日志数据",
            params: "请求数据",
          },
          title: {
            indexHeadTitle: "接口日志",
          },
        },
        error: {
          field: {
            errorstack: "错误堆栈",
            content: "内容",
            time: "时间",
            type: "类型",
            address: "地址",
            uploadServer: "上传服务器",
            clearLocalLog: "清空本地日志",
            localErrorLog: "本地错误日志",
            serviceId: "服务id",
            serverHost: "服务host",
            serverIp: "服务ip",
            env: "软件环境",
            method: "请求方法",
            requestUri: "请求接口",
            createTime: "日志时间",
            userAgent: "用户代理",
            params: "请求数据",
            stackTrace: "日志数据",
          },
          title: {
            indexHeadTitle: "错误日志",
          },
          msg: {
            upload: "确定上传本地日志到服务器?",
            clear: "确定清空本地日志记录?",
          },
        },
      }
    },
    intf: {
      interface: {
        field: {
          name: "名称",
          code: "编码",
          devName: "生产者名称",
          devUrl: "生产者地址",
          consumerName: "消费者名称",
          isRetry: "是否重试",
          status: "是否启用",
          maxRetryCount: "重试次数",
          timeout: "超时时长",
          inputMethodName: "生产者方法",
          outputName: "消费者名称",
          outputMethodName: "消费者方法",
          outputClassName: "消费者类名",
          outputFormat: "消费者参数格式",
          paramFormat: "参数格式",
          serviceOutput: "服务消费者",
          inputParamFormat: "生产者参数格式",
          ipAddress: "ip访问设置",
          dialogTitle: "获取组织信息接口",
          inputStatus: "生产者调用状态",
          outputStatus: "消费者调用状态",
          responseTimeExt: "响应时间",
          inputParams: "生产者调用参数",
          params: "调用参数",
          outputParams: "消费者调用参数",
          failedReason: "失败原因",
          serviceType: "接口分类",
          title: "系统信息",
          type: "类型",
          ip: "ip地址",
          port: "端口"
        },
        title: {
          indexHeadTitle: "接口管理",
          editHeadTitle: "接口管理编辑",
          addHeadTitle: "接口管理新增",
          treeTitle: "系统信息",
          headCateTitle: "服务消费者",
          logListHeadTitle: "接口日志列表",
        },
      },
      serviceType: {
        field: {
          name: "名称",
          code: "编码",
          type: "类型",
          ip: "ip地址",
          port: "端口"
        },
        title: {
          indexHeadTitle: "系统信息",
          editHeadTitle: "系统信息编辑",
          addHeadTitle: "系统信息新增",
          headCateTitle: "系统分类",
          interfaceCate: "接口分类",
          parentCate: '上级分类'
        },
      },
    },
    app: {
      appMenu: {
        field: {
          typeSetting: "排版方式",
          isLink: "是否外链",
          platform: "使用平台",
          name: "菜单名称",
          path: "路由地址",
          parentId: "上级菜单",
          source: "菜单图标",
          code: "菜单编号",
          category: "菜单类型",
          catelog: "目录",
          filePath: "图片上传",
          loadText: "点击上传",
          color: "背景色",
          menus: "菜单",
          button: "按钮",
          alias: "菜单别名",
          isOpen: "新窗口",
          status: "是否启用",
          yes: "是",
          no: "否",
          sort: "菜单排序",
          remark: "菜单备注",
        },
        title: {
          indexHeadTitle: "手机端菜单管理",
          editHeadTitle: "手机端菜单管理编辑",
          addHeadTitle: "手机端菜单管理新增",
        },
      },
      appVersion: {
        field: {
          versionCode: "版本号",
          versionName: "版本名称",
          enableFlag: "启用状态",
          versionInfo: "更新内容",
          updateType: "更新类型",
          downloadUrl: "下载路径",
          appUrl: "存放路径",
          remark: "备注",
        },
        title: {
          indexHeadTitle: "app版本",
          editHeadTitle: "app版本编辑",
          addHeadTitle: "app版本新增",

        },
      },
      bottomMenu: {
        field: {
          menuName: "菜单名称",
          menuType: "菜单类型",
          filePath: "默认图片",
          loadText: "图片上传中，请稍等",
          typeError: "模板格式有误",
          sort: "排序",
          remark: "备注",
          roleIds: "使用角色",
          alias: "菜单别名",
          brightPath: "高亮图片",
        },
        title: {
          indexHeadTitle: "app底部菜单管理",
          editHeadTitle: "app底部菜单管理编辑",
          addHeadTitle: "app底部菜单管理新增",

        },
      },
      aboutUs: {
        field: {
          title: "关于我们",
          verCode: "版本号",
          urlPath: "图片",
          introduce: "介绍",
          textarea: "英文介绍",
          loadText: "点击上传",
          image: "只能上传jpg/png格式，且不超过10M",
        },
        title: {
          indexHeadTitle: "关于我们",
          editHeadTitle: "关于我们编辑",
          addHeadTitle: "关于我们新增",

        },
      },
      attendance: {
        field: {
          address: "公司地址",
          code: "名称",
          longitude: "公司经度",
          dimension: "公司维度",
          clockRange: "打卡范围",
          workShiftTime: "上班打卡时间",
          workUnshiftTime: "下班打卡时间",
          remark: "备注"
        },
        title: {
          indexHeadTitle: "打卡设置",
          editHeadTitle: "打卡设置编辑",
          addHeadTitle: "打卡设置新增",
        },
        msg: {
          address: "请输入详细地址",
        },
      },
      clock: {
        field: {
          address: "打卡地址",
          username: "用户名",
          clockTime: "上班打卡时间",
          clockOutTime: "下班打卡时间"
        },
        title: {
          indexHeadTitle: "打卡记录",
          editHeadTitle: "打卡记录编辑",
          addHeadTitle: "打卡记录新增",

        },
      },
      adInfo: {
        field: {
          adName: "轮播名称",
          adType: "轮播类型",
          filePath: "图片上传",
          loadText: "图片上传中，请稍等",
          typeError: "模板格式有误",
          sort: "排序",
          remark: "内容",
          urlPath: "跳转链接",
        },
        title: {
          indexHeadTitle: "轮播管理",
          editHeadTitle: "轮播管理编辑",
          addHeadTitle: "轮播管理新增",
        },
        msg: {
          avater: "只能上传jpg/png用户头像，且不超过10M",
        },
      },
      broadcast: {
        field: {
          vcn: "声线",
          speed: "语速",
          volume: "音量",
          url: "音箱url",
          address: "音箱位置",
          isOpen: "是否启用",
        },
        title: {
          indexHeadTitle: "广播管理",
          editHeadTitle: "广播管理编辑",
          addHeadTitle: "广播管理新增",
        },
        menu: {
          selectBroadcast: '广播设备',
        }
      },
    },
    reportcenter: {
      //报表管理
      report: {
        field: {
          reportName: "报表名称",
          roleName: "角色名称",
          reportType: "报表类型",
          sort: "排序",
          remark: "备注",
          reportCode: "报表编码",
          parentCode: "上级类型",
          reportTypeCode: "类型编码",
          reportTypeName: "报表类别",
          reportTypeId: "左侧报表类别",
          reportReviewPath: "报表预览路径",
          reportFillPath: "报表填报路径",
        },
        btn: {
          settingsBtn: "权限设置",
          mainFilePath: "主报表",
          otherFilePath: "其他报表",
          reportView: "报表查看"
        },
        title: {
          indexHeadTitle: "报表管理",
          editHeadTitle: "报表管理编辑",
          addHeadTitle: "报表管理新增",
          viewHeadTitle: "报表管理查看",
          headCateTitle: "报表类别管理",
          roleNameTitle: "所属角色",
          deptNameTitle: "所属部门",
        },
        msg: {
          only: "（主报表只能上传一个润乾报表模板文件）",
          loadText: "报表上传中，请稍等",
          typeError: "请点击报表查看按钮查看",
          report: "上传报表文件后请保存数据！",
          exceed: "超出上传限制文件数量",
        },
      },
      //我的报表
      myReport: {
        field: {
          reportName: "报表名称",
          reportTypeName: "报表类型",
        },
        title: {
          treeTitle: "报表分类",
          headTitle: "我的报表",
        },
      },
      //中屏管理
      mediumScreen: {
        field: {
          name: "名称",
          urlPath: "跳转地址",
          code: "编码",
          remark: "备注"
        },
        btn: {
          settingsBtn: "权限设置",
        },
        title: {
          indexHeadTitle: "看板管理",
          editHeadTitle: "看板管理编辑",
          addHeadTitle: "看板管理新增",
          viewHeadTitle: "看板管理查看",
          roleNameTitle: "所属角色",
        },
      },
    },
    message: {
      //通道管理
      channel: {
        field: {
          channelCode: "通道编号",
          channelName: "通道名称",
          channelType: "通道类型",
          ChannelUserIDSource: "渠道用户ID来源",
          channelDescribe: "通道描述",
          channelParameter: "通道参数",
          status: "是否启用",
          sort: "排序",
          remark: "备注",
          createTime: "创建时间",
        },
        title: {
          indexHeadTitle: "通道管理",
          editHeadTitle: "通道管理编辑",
          addHeadTitle: "通道管理新增",
          viewHeadTitle: "通道管理查看",
        },
        msg:{
          signName: "请输入signName",
          accessKeyId: "请输入accessKeyId",
          accessKeySecret: "请输入accessKeySecret",
          templateCode: "请输入templateCode",
          host: "请输入host",
          user: "请输入user",
          from: "请输入from",
          pass: "请输入pass",
          appKey: "请输入appKey",
          masterSecret: "请输入masterSecret",
          appSecret: "请输入appSecret",
          agentId: "请输入agentId",
          appId: "请输入appId",
          secret: "请输入secret",
          templateId: "请输入templateId",
        }
      },
      //消息模板管理
      template: {
        field: {
          templateCode: "模板编号",
          templateName: "模板名称",
          templateType: "消息类型",
          templateMode: "发送模式",
          statusStr: "状态",
          status: "是否启用",
          sort: "排序",
          remark: "备注",
          taskStatuStr: "任务状态",
          createTime: "创建时间",
          listen: "监听",
          cronExpressions: "cron表达式",
          TemplateDescription: "模板描述",
          Enabled: "已启用",
          notEnabled: "未启用",
          msgTypeEncoding: "消息类型编码",
          msgTypeName: "消息类型名称",
          parentName: "父类名称",
          appUrl: "app路径",
          pcUrl: "pc路径",
        },
        btn: {
          start: "启动",
          stop: "停止",
        },
        title: {
          indexHeadTitle: "消息模板管理",
          editHeadTitle: "消息模板管理编辑",
          addHeadTitle: "消息模板管理新增",
          viewHeadTitle: "消息模板管理查看",
          headItemTitle: "消息类型",
        },
        msg: {
          start: "确定将选择数据模板启动？",
          stop: "确定将选择数据模板停止？",
          select: "请选择消息分类编码！",
        },
        //输入参数
        parameter: {
          field: {
            list: "参数管理",
            paramName: "参数名称",
            paramKey: "参数键名",
            paramValue: "参数键值",
            paramRequired: "是否必填",
            paramRule: "参数规则",
          },
          title: {
            indexHeadTitle: "输入参数",
          },
          msg: {
            listNotEmpty: "请勾选要保存的通道！",
            ending: "ending",
            templateMode: "发送模式为定时，参数不可设置为必填！",
            repeated: "键不能重复",
          },
        },
        //相关方
        parties: {
          field: {
            sendFromType: "发送方来源",
            sendAccount: "发送方",
            receiveFromType: "接收方来源",
            presetpPersonnel: "预设人员",
            inputParameter: "输入参数",
            receiveSetType: "人员过滤方式",
            sort: "排序",
            remark: "备注",
            role: "角色",
            organ: "组织",
            parentId: "上级角色",
            parentDept: "上级机构",
            postId: "所属岗位",
            userCode: "成员编码",
            userName: "成员名称",
            deptName: "所属组织",
            contentFormat: "参数规则",
            operate: "操作",
          },
          title: {
            indexHeadTitle: "相关方",
            appointHeadTitle: "指定人员",
          },
          msg: {
            save: "当消息模板发送模式为定时，发送方和接收方来源必须选择预设人员！",
          },
        },
        //消息规则
        rule: {
          field: {
            startTime: "开始时间",
            endTime: "结束时间",
            maxFrequency: "频次（天）",
            keywordFiltering: "关键字过滤",
          },
          title: {
            indexHeadTitle: "消息规则",
          },
        },
        //发送通道
        channel: {
          field: {
            channelCode: "通道编号",
            channelName: "通道名称",
            channelType: "通道类型",
            titleFormat: "标题格式",
            contentFormat: "内容格式",
            content: "内容",
            createTime: "创建时间",
          },
          btn: {
            editBtn: "格式编辑",
          },
          title: {
            indexHeadTitle: "发送通道",
            channelHeadTitle: "通道管理",
            headItemTitle: "消息内容",
          },
          msg: {
            listNotEmpty: "保存参数列表为空！",
            title: "格式编辑中的标题不能为空",
            connect: "格式编辑中的内容不能为空",
            all: "模板为定时模式时，标题和内容不能存在类似",
          },
        },
      },
      //消息清单
      sendRecord: {
        field: {
          msgCode: "消息ID",
          msgForm: "消息来源",
          msgType: "消息类型",
          sendModel: "发送模式",
          sendNum: "总消息数",
          sendNumCensus: "发送统计(总数/成功/失败)",
          sendTime: "发送时间",
          sendStatus: "发送状态",
          startDate: "开始日期",
          endDate: "结束日期",
          detailsHeadTitle: "消息基本信息",
          templateName: "消息模板",
          templateNames: "模板名称",
          templateCode: "模板编号",
          templateCronExpr: "cron表达式",
          successMsgNum: "成功消息数",
          failMsgNum: "失败消息数",
          msgDetailList: "发送记录",
          detailInfoTitle: "消息发送记录详情",
          messageTemplateId: "模板ID",
          titleParam: "消息标题参数",
          contentParam: "消息内容参数",
          sendParam: "发送方相关参数",
          receiveParam: "接收方相关参数",
        },
        title: {
          indexHeadTitle: "消息清单",
          addHeadTitle: "消息新增",
          viewHeadTitle: "消息基本信息",
        },
        //发送记录
        sendRecord: {
          field: {
            detailCode: "记录ID",
            channelName: "通道名称",
            sendAccount: "发送方",
            receiveAccount: "接收方",
            msgTile: "标题",
            msgContent: "内容",
            success: "成功",
            fail: "失败",
          },
          btn: {
            resend: "重发",
          },
          title: {
            indexHeadTitle: "发送记录",
            viewHeadTitle: "消息发送记录详情",
          },
        },
      },
      //我的消息
      list: {
        field: {
          msgTile: "消息标题",
          msgContent: "消息内容",
          isRead: "是否已读",
          createTime: "接收时间",
          attachment: "附件",
          picture: "图片",
          video: "视频",
          buttonName: "功能链接"
        },
        title: {
          indexHeadTitle: "我的消息",
          mailTitle: "站内信",
          msgTypeTitle: "消息类型",
          msgSettingTitle: "消息设置",
          notifySettingTitle: "通知弹窗设置",
          bcstSettingTitle: "广播设置",
        },
        btn: {
          readBtn: "标为已读",
          readAllBtn: "一键已读",
          messageSettingBtn: "消息设置",
        },
        msg: {
          read: "确定将选择消息已读?",
          readAll: "确定将所有消息已读?",
          mail: "收到一条消息，请及时查看",
          removeNotRead: "该消息未读,确定删除?",
          removeContainNotRead: "已选择数据包含未读消息,确定删除?",
        }
      },
    },
    resource: {
      //对象存储
      oss: {
        field: {
          classify: "分类",
          ossCode: "资源编号",
          endpoint: "资源地址",
          bucketName: "空间名",
          accessKey: "accessKey",
          secretKey: "secretKey",
          appId: "appId",
          region: "region",
          status: "是否启用",
          remark: "备注",
          backgroundUrl: "上传背景",
          code: "资源编号",
        },
        btn: {
          debugBtn: "调试",
        },
        title: {
          indexHeadTitle: "对象存储",
          editHeadTitle: "对象存储编辑",
          addHeadTitle: "对象存储新增",
          viewHeadTitle: "对象存储查看",
          debugHeadTitle: "对象存储上传调试",
        },
        msg: {
          enable: "是否确定启用这条配置?",
          img: "获取到图片地址:",
        }
      },
      //附件管理
      attach: {
        field: {
          currentLocation: "位置",
          filename: "文件名",
          type: "类型",
          size: "大小(KB)",
          dateUpload: "上传日期",
          fileSize: "文件大小(KB)",
          creationTime: "创建时间",
          attachmentDomain: "附件域名",
          attachmentExtension: "附件拓展名",
          attachmentAddress: "附件地址",
          attachmentName: "附件名称",
          attachmentSize: "附件大小",
          formerly: "附件原名",
          management: "附件管理",
          list: "列表",
          network: "网络",
          myPapers: "我的文件",
          all: "全部",
          picture: "图片",
          document: "文档",
          video: "视频",
          music: "音乐",
          other: "其他",
          upload: "其他",
          attachmentUpload: "附件上传",
          clickToUpload: "点击上传",
        },
        btn: {
          upOneLevelBtn: "上一级",
          howToPresentBtn: "展现方式",
          UploadBtn: "上传",
        },
        title: {
          viewHeadTitle: "详情信息",
          pictureHeadTitle: "图片预览",
          videoHeadTitle: "视频预览",
          audioHeadTitle: "音频预览",
        },
        msg: {
          templateUploaded: "模板上传中，请稍等",
        }
      },
      //短信配置
      sms: {
        field: {
          classify: "分类",
          smsCode: "资源编号",
          templateId: "模版ID",
          accessKey: "accessKey",
          secretKey: "secretKey",
          regionId: "regionId",
          signName: "短信签名",
          status: "是否启用",
          remark: "备注",
          phone: "手机号",
          param: "发送参数",
          TemplateContent: "模版内容",
        },
        btn: {
          debugBtn: "调试",
          submitBtn: "发送",
        },
        title: {
          indexHeadTitle: "短信配置",
          debugHeadTitle: "手机短信发送调试",
        },
        msg: {
          phone: "请输入要发送到的手机号码",
          param: "例: {'code':2333,'title':'通知标题'}",
          enable: "是否确定启用这条配置?",
          sent: "发送成功",
        }
      },
      //文件夹管理
      folder: {
        field: {
          name: "文件夹名称",
          code: "文件夹编码",
          parentId: "上级文件夹",
        },
        title: {
          indexHeadTitle: "文件夹管理",
          addHeadTitle: "文件夹新增",
        },
      },
    },
    xxljob: {
      //任务列表
      task: {
        field: {
          all: "全部",
          stopIt: "停止",
          initiate: "启动",
          id: "任务ID",
          jobGroup: "执行器",
          jobCron: "CRON表达式",
          jobDesc: "任务描述",
          addTime: "新增时间",
          updateTime: "更新时间",
          author: "负责人",
          alarmEmail: "报警邮件",
          executorRouteStrategy: "执行器路由策略",
          executorHandler: "执行器Handler",
          executorParam: "任务参数",
          executorBlockStrategy: "阻塞处理策略",
          executorTimeout: "任务执行超时时间",
          executorFailRetryCount: "失败重试次数",
          glueType: "运行模式",
          glueSource: "GLUE源代码",
          glueRemark: "GLUE备注",
          glueUpdatetime: "GLUE更新时间",
          triggerStatus: "调度状态",
          triggerLastTime: "上次调度时间",
          triggerNextTime: "下次调度时间",
          childJobId: "子任务ID",
        },
        headTitle: "用户管理",
        username: "账号",
        password: "密码",
        role: "角色",
        permission: "权限",
        btn: {
          moreBtn: "更多",
          startBtn: "启动",
          stopBtn: "停止",
          firstBtn: "执行一次",
          logBtn: "查询日志",
          registerBtn: "注册节点",
          nextBtn: "下次执行时间",
        },
        title: {
          actator: '任务分类',
          indexHeadTitle: "任务管理",
          editHeadTitle: "任务编辑",
          addHeadTitle: "任务新增",
          executeOnceHeadTitle: "执行一次",
          nodeHeadTitle: "注册节点",
          nextHeadTitle: "下次执行时间",
        },
        msg: {
          start: "确定将选择任务启动?",
          stop: "确定将选择任务停止?",
          childJobMessage: "请输入子任务，多任务逗号分隔",
        }
      },
      //调度日志
      dispatch: {
        field: {
          jobId: "任务Id",
          triggerTime: "调度-时间",
          triggerCode: "调度-结果",
          success: "成功",
          fail: "失败",
          handleTime: "执行-时间",
          handleCode: "执行-状态",
          type: "任务触发类型",
          adminAdress: "调度机器",
          exeRegtype: "执行器-注册方式",
          exeRegaddress: "执行器-地址列表",
          routeStrategy: "路由策略",
          blockStrategy: "阻塞处理策略",
          timeout: "任务超时时间",
          failRetryCount: "失败重试次数",
          divider: "<span style='color:#00c0ef;' > >>>>>>>>>>>触发调度<<<<<<<<<<< </span>",
          triggerScheduling: "触发调度",
        },
        btn: {
          triggerLogBtn: "调度备注",
          handleLogBtn: "执行备注",
        },
        title: {
          indexHeadTitle: "调度日志",
          dispatchRemarkHeadTitle: "调度备注",
          executionLogHeadTitle: "执行日志",
        },
        msg: {
          error: "到rowLog方法进行修改",
        }
      },
      //执行器管理
      actuator: {
        field: {
          appName: "AppName",
          name: "名称",
          order: "排序",
          addressType: "注册方式",
          addressList: "机器地址",
          manualEntry: "手动录入",
          automaticLogon: "自动注册",
        },
        title: {
          indexHeadTitle: "执行器管理",
          editHeadTitle: "执行器编辑",
          addHeadTitle: "执行器新增",
        },
        msg: {
          addressMessage: "请输入执行器地址列表，多地址逗号分隔",
        }
      },
    },
    sync: {
      //部门管理
      dept:{
        field: {
          shortName: "单位简称",
          name: "名称",
          deptRank: "排序",
          unity: "单位性质",
          disType: "类型",
          code: "编码",
          enName: "英文全称",
          socialCreditCode: "社会信用代码",
          pcode: "上级机构",
          country: "国家",
          area: "省份/直辖市",
          city: "城市",
          address: "办公地址",
          postCode: "邮政编码",
          tel: "联系电话",
          status: "是否启用",
        },
        title: {
          indexHeadTitle: "机构数据",
          editHeadTitle: "机构编辑",
          addHeadTitle: "机构新增",
          viewHeadTitle: "机构查看",
        },
        msg: {}
      },
      //项目管理
      project:{
        field: {
          disType: "分发类型",
          menuName: "菜单名称",
          operateType: "操作类型",
          sourceId: "系统源头ID",
          source: "系统源头",
          code: "项目编码",
          name: "项目名称",
          projectArea: "境内外",
          businessTypeName: "业务类型名称",
          serviceTypeName: "工程类型名称",
          subdivisionTypeName: "项目细分类型名称",
          constructionModeName: "项目承建模式名称",
          producelineNum: "生产线条数",
          bidMethod: "招标方式",
          bidDeadline: "投标截止日",
          budgetAmount: "预计项目总金额（万元/万美元）",
          isResponsibleArea: "是否在责任区域",
          isResponsibleCustomer: "业主是否为责任客户",
          regionCode: "洲编码",
          regionName: "洲名称",
          areaCode: "区域编码",
          areaName: "区域名称",
          countryCode: "国家编码",
          countryName: "国家名称",
          provinceCode: "省/直辖市编码",
          provinceName: "省/直辖市名称",
          cityCode: "城市编码",
          cityName: "城市名称",
          responsibleUnitCode: "项目区域责任单位编码",
          responsibleUnitName: "项目区域责任单位名称",
          projectProfile: "项目情况简介",
          projectIntroduce: "项目跟踪情况介绍",
          isAgent: "是否有代理人",
          agentCompany: "代理人公司名称",
          agentContact: "代理联系人",
          agentEmail: "代理人邮箱",
          regHumTel: "备案人联系方式",
          regHumUnitCode: "创建单位编码",
          regHumUnitName: "创建单位名称",
          domesticCompetitor: "项目竞争对手公司名称(国内)",
          abroadCompetitor: "项目竞争对手公司名称(国外)",
          clientCode: "业主公司编码",
          clientName: "业主公司名称",
          clientTypeName: "业主公司性质名称",
          clientContact: "业主联系人",
          clientTel: "业主联系人电话",
          headUnitCode: "总公司编码",
          headUnitName: "总公司名称",
          headAddress: "总公司地址",
          mainCode: "主项目编号",
          mainName: "主项目名称",
          sourceFunds: "资金来源",
          tenderRate: "投标毛利率",
          longitude: "经度",
          latitude: "纬度",
          address: "项目详细地址",
          isComplete: "是否为完整生产线或粉磨站",
          scale: "项目规模",
          recordStatus: "备案状态",
          projectStatusName: "项目状态",
          planStartDate: "计划开工时间",
          planEndDate: "计划完工时间",
          actStartDate: "实际开工时间",
          actEndDate: "实际完工日期",
          durationMonth: "工期(月)",
          bidProfitRate: "中标毛利率",
          bidRate: "投标毛利率",
          oldProjectCode: "本单位项目号",
          productTypeName: "产品分类名称",
          industryTypeName: "行业分类名称",
          pathProjectName: "所属项目路径",
          projectRank: "序号",
          regHumCode: "录入人编号",
          regHumName: "录入人名称",
          regDate: "录入日期",
          remark: "备注",
          pmdDate: "项目经理部设立时间",
          pmCode: "项目经理编码",
          pmName: "项目经理",
          contractorCode: "承接单位编码",
          contractorName: "承接单位名称",
          clientProjectCode: "客户项目编号",
          contractCode: "总部合同编号",
          contractName: "合同名称",
          currencyName: "结算币种名称",
          contractAmount: "合同总金额",
          contractSignDate: "合同签订日期",
          contractStartDate: "合同开始日期",
          contractEndDate: "合同结束日期",
          projectRole: "项目角色",
          department: "部门",
          levels: "职级",
          phoneNumber: "联系电话",
          email: "联系邮箱",
          mailAddress: "通讯地址",
          status: "是否启用",
        },
        title: {
          indexHeadTitle: "项目数据",
          editHeadTitle: "项目编辑",
          addHeadTitle: "项目新增",
          viewHeadTitle: "项目查看",
          contractHeadTitle: "合同信息",
          orgHeadTitle: "组织项目信息",
        },
        msg: {

        }
      },
      //用户管理
      user:{
        field: {
          code: "编码",
          name: "员工姓名",
          account: "员工账号",
          rank: "排序",
          sex: "性别",
          entryTime: "入职时间",
          nation: "民族",
          nativePlace: "籍贯",
          politics: "政治面貌",
          email: "邮箱",
          officePhone: "办公电话",
          birthday: "出生日期",
          idNum: "身份证号",
          passportNo: "护照号",
          phone: "手机号码",
          highLevel: "学历",
          idcardAddr: "家庭地址",
          urgentName: "紧急联系人",
          urgentPhone: "紧急联系人电话",
          insured: "是否购买保险",
          maritalStatus: "婚姻状态",
          contractStart: "工龄起算日",
          disType: "类型",
          status: "是否启用",
        },
        title: {
          indexHeadTitle: "用户数据",
          editHeadTitle: "用户编辑",
          addHeadTitle: "用户添加",
          viewHeadTitle: "用户查看",
        },
        msg: {}
      },
    },
  },
  desk: {
    portalSwitch: "门户切换",
    im: {
      field: {
        chat: "聊天",
        msg: "消息",
        contacts: "联系人",
        search: "搜索：请输入关键词",
        noData: "无数据",
        me: "我",
        sendEmo: "发送表情",
        sendImg: "发送图片",
        chatRecord: "聊天记录",
        enterMsg: "点击这里，直接输入消息咨询",
        send: "发 送",
        noChatRecord: "暂无聊天信息",
        remove: "移除",
        removeCharRecord: "删除聊天记录"
      },
      title: {
        indexHeadTitle: "内部聊天",
      }
    },
    personalSharing: {
      field: {
        title: "标题",
        shareTime: "分享时间",
        shareLink: "分享链接",
      },
      title: {
        indexHeadTitle: "内部分享",
        addHeadTitle: "内部分享新增",
        editHeadTitle: "内部分享编辑",
        viewHeadTitle: "内部分享查看",
      }
    },
    notice: {
      field: {
        title: "通知标题",
        category: "通知类型",
        releaseTimeRange: "通知时间",
        publishTime: "发布时间",
        releaseTime: "通知日期",
        content: "通知内容",
        publish_status: "发布状态",
        sound: "声音",
        maleVoice: "男声",
        femaleVoice: "女声",
        speed: "语速",
        volume: "音量",
        responsiveMode: "响应模式",
        synchronousMode: "同步模式",
        immediateResponse: "即时响应",
        repeat: "重复播放次数",
        time: "重复播放间歇时⻓",
      },
      btn: {
        broadcastBtn: "广播",
        settingBtn: "广播设置",
      },
      title: {
        addHeadTitle: "系统公告新增",
        editHeadTitle: "系统公告编辑",
        viewHeadTitle: "系统公告查看",
        indexHeadTitle: "系统公告",
        settingHeadTitle: "广播设置",
      },
      msg: {
        synchronousMode: "语⾳播放完毕后再响应",
        immediateResponse: "不等待播放完成就响应",
        repeat: "达到此参数指定的次数后停⽌(单位:次)",
        time: "重复播放的切换间歇时间(单位:秒),不填则默认为 2 秒",
        success: "设置成功",
        frequently: "操作频繁请稍后尝试",
        broadcast: "请选择广播设备",
      }
    },
    workflow: {
      field: {
        icon: "图标",
        ProcessDesign: "流程设计",
        ProcessIdentification: "流程标识",
        ProcessClassification: "流程分类",
        version: "版本",
        state: "状态",
        activation: "激活",
        Suspend: "挂起",
        nameOfTheProcess: "流程名称",
        SerialNumber: "流水号",
        TheCurrentNode: "当前节点",
        applicant: "申请人",
        ApplicationTime: "申请时间",
        start: "新建流程",
        todo: "待办事宜",
        send: "我的请求",
        MyDone: "我的已办",
        claim: "待签事务",
        copy: "抄送事宜",
        done: "办结事宜",
        serialNumber: "流水号",
        creator: "创建人",
        createDept: "创建部门",
        datetime: "请假时间",
        ProcessStatus: "流程状态",
        OperationsNode: "操作节点",
        days: "请假天数",
        OperationTime: "操作时间",
        category: "流程分类",
        title: "标题",
        Initiator: "发起者",
        CCTime: "抄送时间",
        reason: "请假理由",
        originalName: "附件",
      },
      msg: {
        beginTime: "开始时间必须填写",
        askLeaveTime: "请假天数必须填写",
        askReasonTime: "请假天数必须填写",
        unfinished: "进行中",
        finished: "已完成",
        terminate: "已终结",
        withdraw: "已撤销",
        recall: "已撤回",
        reject: "被驳回",
        deleted: "已删除"
      },
      title: {
        indexHeadTitle: "新建流程",
        indexHead1Title: "我的请求",
        indexHeadTodo: "待办事宜",
        taskTodo: "任务待办",
        indexHeadVisible: "流程图",
        indexHeadFirst: "申请信息",
        indexHeadDone: "办结事宜",
        indexHeadSecond: "流转信息",
        indexHeadClaim: "办结事宜",
        indexHeadThird: "流程跟踪",
        indexHeadDetails: "流程详情",
        indexHeadMyDone: "我的已办",
        indexHeadCopy: "抄送事宜",
        indexHeadMyTodo: "我的待办",
      },
      btn: {
        flowBtn: "流程图",
        detailBtn: "详情",
        crossBtn: "通过",
        rejectBtn: "驳回",
        transferBtn: "转办",
        entrustBtn: "委托",
        stopBtn: "终止",
        sealBtn: "打印",
        copyBtn: "抄送",
        pressBtn: "催办",
        bgrBtn: "变更审核人",
      },
    },
    todo:{//待办
      field: {
        // icon: "图标",
        // ProcessDesign: "流程设计",
        // ProcessIdentification: "流程标识",
        ProcessClassification: "待办分类",
        // version: "版本",
        // state: "状态",
        // activation: "激活",
        // Suspend: "挂起",
        nameOfTheProcess: "待办名称",
        businessType:"业务分类",
        // SerialNumber: "流水号",
        // TheCurrentNode: "当前节点",
        // applicant: "申请人",
        ApplicationTime: "下发时间",
        Issuer:"下发人",
        // start: "新建流程",
        // todo: "待办事宜",
        // send: "我的请求",
        // MyDone: "我的已办",
        // claim: "待签事务",
        // copy: "抄送事宜",
        // done: "办结事宜",
        // serialNumber: "流水号",
        // creator: "创建人",
        // createDept: "创建部门",
        // datetime: "请假时间",
        // ProcessStatus: "流程状态",
        // OperationsNode: "操作节点",
        // days: "请假天数",
        // OperationTime: "操作时间",
        // category: "流程分类",
        // title: "标题",
        // Initiator: "发起者",
        // CCTime: "抄送时间",
        // reason: "请假理由",
        // originalName: "附件",
      },
    }
  },
  dig: {
    guration: {
      field: {
        notEnabled: "失效",
        enable: "启用",
        name: "面板名称",
        content: "内容",
      },
      title: {
        indexHeadTitle: "组态列表",
      },
    },
  },
}
