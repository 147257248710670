var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "avue-crud",
    {
      ref: "crud",
      attrs: {
        option: _vm.option,
        "table-loading": _vm.loading,
        data: _vm.data,
        page: _vm.page,
        permission: _vm.permissionList,
        "before-open": _vm.beforeOpen,
      },
      on: {
        "update:page": function ($event) {
          _vm.page = $event
        },
        "row-update": _vm.rowUpdate,
        "row-save": _vm.rowSave,
        "row-del": _vm.rowDel,
        "search-change": _vm.searchChange,
        "search-reset": _vm.searchReset,
        "selection-change": _vm.selectionChange,
        "current-change": _vm.currentChange,
        "size-change": _vm.sizeChange,
        "refresh-change": _vm.refreshChange,
        "on-load": _vm.onLoad,
      },
      model: {
        value: _vm.form,
        callback: function ($$v) {
          _vm.form = $$v
        },
        expression: "form",
      },
    },
    [
      _c(
        "template",
        { slot: "menuLeft" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "danger",
                size: "small",
                icon: "el-icon-delete",
                plain: "",
              },
              on: { click: _vm.handleDelete },
            },
            [_vm._v("删 除\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }