import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_NAME = "/index/themeCategory";

/**
 * 分类树
 * @param params
 * @returns {*}
 */
export const tree = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/tree`,
    method: 'get',
    params
  })
}

/**
 * 主题分类编辑新增
 * @param
 * @returns AxiosPromise
 */
export const save= (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/save`,
    method: 'post',
    data

  })
}

/**
 * 删除
 * @param ids  删除的id集合
 * @returns AxiosPromise
 */
export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/remove`,
    method: 'get',
    params: {
      ids
    }
  })
}
