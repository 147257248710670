/*
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-09-04 18:57:00
 */
import request from '@/router/axios';

export const pageList = (row) => {
  return request({
    url: '/api/sinoma-maas-center/recognition-config/pageList',
    method: 'post',
    data: row
  })
}
export const getById = (row) => {
  return request({
    url: '/api/sinoma-maas-center/recognition-config/getById',
    method: 'post',
    data: row
  })
}
export const add = (row) => {
  return request({
    url: '/api/sinoma-maas-center/recognition-config/add',
    method: 'post',
    data: row
  })
}

export const remove = (row) => {
  return request({
    url: '/api/sinoma-maas-center/recognition-config/del',
    method: 'post',
    data: row
  })
}
export const edit = (row) => {
  return request({
    url: '/api/sinoma-maas-center/recognition-config/edit',
    method: 'post',
    data: row
  })
}
export const asDefault = (row) => {
  return request({
    url: '/api/sinoma-maas-center/recognition-config/asDefault',
    method: 'post',
    data: row
  })
}

