import request from '@/router/axios';
const SERVER_FX = '/api/sinoma-hse-prj'
//项目相关方人员中拉黑/
export const prjblacklistuserheadSubmit = (params) => {
    return request({
        url: SERVER_FX + '/prjblacklistuserhead/submit',
        method: 'post',
        data: params
    })
}

// 项目中黑名单人员列表
export const prjblacklistuserheadPage = (params) => {
    return request({
        url: SERVER_FX + '/prjblacklistuserhead/page',
        method: 'get',
        params: params
    })
}

// 黑名单人员功能列表
export const getList = (current, size, params) => {
    return request({
      url: SERVER_FX + '/prjblacklistuserhead/page',
      method: 'get',
      params: {
        ...params,
        current,
        size,
      }
    })
  }

//   黑名单组织详情列表
export const prjblacklistuserlist = (params) => {
    return request({
      url: SERVER_FX + '/prjblacklistuserlist/list',
      method: 'get',
      params: {
        ...params
      }
    })
  }

//   黑名单组织详情修改
export const prjblacklistuserlistSubmit = (params) => {
    return request({
        url: SERVER_FX + '/prjblacklistuserlist/submit',
        method: 'post',
        data: params
    })
}