var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "SNOWS-dialog SNOWS-dialog_center SNOWS-dialog-tree",
      attrs: {
        title: "选择" + _vm.title,
        "close-on-click-modal": false,
        visible: _vm.visible,
        "lock-scroll": "",
        "append-to-body": "",
        width: "450px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-input",
        {
          attrs: { placeholder: "输入姓名或者编号进行过滤", clearable: "" },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.getList.apply(null, arguments)
            },
          },
          model: {
            value: _vm.keyword,
            callback: function ($$v) {
              _vm.keyword = $$v
            },
            expression: "keyword",
          },
        },
        [
          _c("el-button", {
            attrs: { slot: "append", icon: "el-icon-search" },
            on: { click: _vm.getList },
            slot: "append",
          }),
        ],
        1
      ),
      _c("el-tree", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "SNOWS-common-el-tree",
        attrs: {
          data: _vm.treeData,
          props: _vm.props,
          "highlight-current": "",
          "expand-on-click-node": false,
          "check-on-click-node": "",
          "node-key": "id",
          lazy: "",
          load: _vm.loadNode,
        },
        on: { "node-click": _vm.handleNodeClick },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ node, data }) {
              return _c("span", { staticClass: "custom-tree-node" }, [
                _c("i", { class: data.icon }),
                _c("span", { staticClass: "text" }, [
                  _vm._v(_vm._s(node.label)),
                ]),
              ])
            },
          },
        ]),
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.dataFormSubmit()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }