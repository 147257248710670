/*
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-11-09 18:33:34
 */
import httpService from "@/router/axios";

// 任务管理-表单填报计划左侧数据-列表
export const $_getTreeList = (params) => {
  return httpService({
    url: "/api/sinoma-report/tableplan/list",
    method: "get",
    params,
  });
};

// 任务管理-表单填报计划右侧数据-列表
export const $_getList = (params) => {
  return httpService({
    url: "/api/sinoma-report/fillreporttask/page",
    method: "get",
    params,
  });
};

// 任务管理-表单填报计划右侧的下拉框数据
export const $_getBatchList = (params) => {
  return httpService({
    url: "/api/sinoma-report/fillreporttask/batch/list",
    method: "get",
    params,
  });
};

// 安全工作-表单填报计划停用
export const $_formFillingUnEnable = (data) => {
  return httpService({
    url: "/api/sinoma-report/tableplan/unEnable",
    method: "post",
    data,
  });
};

// 任务管理-表单填报计划左侧列表-详情
export const $_getDetail = (params) => {
  return httpService({
    url: `/api/sinoma-report/tableplan/detail`,
    method: "get",
    params
  });
};

// 任务管理-表单填报计划右侧列表任务-详情
export const $_getTaskDetail = (params) => {
  return httpService({
    url: `/api/sinoma-report/fillreporttask/detail`,
    method: "get",
    params
  });
};

// 任务管理-表单填报计划启用
export const $_formFillingEnable = (data) => {
  return httpService({
    url: "/api/sinoma-report/tableplan/enable",
    method: "post",
    data,
  });
};

// 任务管理-表单填报计划发起计划新增
export const $_formFillingAdd = (data) => {
  return httpService({
    url: "/api/sinoma-report/tableplan/save",
    method: "post",
    data,
  });
};

// 任务管理-表单填报计划发起计划编辑
export const $_formFillingEdit = (data) => {
  return httpService({
    url: "/api/sinoma-report/tableplan/update",
    method: "post",
    data,
  });
};

