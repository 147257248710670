import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getPage = (current, size, params)=>{
  return request({
    url:ServerNameEnum.SERVER_FX+'/process/page',
    method:'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const submit = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX +'/process/submit',
    method: 'post',
    data:params
  })
}


export const detail = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_FX+'/process/detail',
    method:'get',
    params: params
  })
}

export const remove = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_FX+'/process/remove',
    method:'post',
    data:params
  })
}

export const getDeptHistory = (current, size, params)=>{
  return request({
    url:ServerNameEnum.SERVER_FX+'/process/getDeptHistory',
    method:'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
