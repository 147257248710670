export const options = (safe)=>  {
  return {
    index: true,
    indexLabel:safe.$t("datacenter.dataQuality.index"),
    viewBtn:false,
    column: [
      {
        label: safe.$t("datacenter.dataQuality.programName"),
        prop: "schemeName"
      },
      {
        label: safe.$t("datacenter.dataQuality.monitoringRecord.templateName"),
        prop: "ruleTemplateName"
      },
      {
        label: safe.$t("datacenter.dataQuality.monitoringRecord.executeBeginTime"),
        prop: "executeBeginTime"
      },
      {
        label: safe.$t("datacenter.dataQuality.monitoringRecord.executeEndTime"),
        prop: "executeEndTime"
      }
    ]
  }
};
export const searchColumns = (safe)=>  {
  return [
    {
      prop: "schemeName",
      search: true,
      placeholder: safe.$t('cip.cmn.rule.inputWarning') + safe.$t("datacenter.dataQuality.programName"),
      span:8
    },
    {
      type: "date",
      placeholder: safe.$t('cip.cmn.rule.selectWarning') + safe.$t("datacenter.dataQuality.monitoringRecord.programExecutionDate"),
      prop: "executeDate",
      search: true,
      span:8
    }
  ]
};
