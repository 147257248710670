import Layout from '@/page/index/'
export default [{
  path: '/authority',
  component: Layout,
  children: [
    {
      path: '/role/edit',
      name: ' 角色管理',
      component: () =>
        import('@/views/authority/roleEdit'),
      meta: {
        i18n: 'roleEdit'
      },
    },

    {
      path: '/authority/role',
      name: ' 角色管理',
      component: () =>
        import('@/views/authority/role.vue'),
      meta: {
        i18n: 'role'
      },
    },
    {
      path: '/authority/classificationRole',
      name: ' 角色配置',
      component: () =>
        import('@/views/authority/classificationRole.vue'),
      meta: {
        i18n: 'classificationRole'
      },
    },

    {
      path: '/authority/datascope',
      name: ' 数据权限',
      component: () =>
        import('@/views/authority/datascope.vue'),
      meta: {
        i18n: 'datascope'
      },
    },
    {
      path: '/authority/apiscope',
      name: ' 数据权限',
      component: () =>
        import('@/views/authority/apiscope.vue'),
      meta: {
        i18n: 'apiscope'
      },
    },




  ]
}]
