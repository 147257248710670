import {calculateAge} from "@/util/util";

export default {
  data(){
    return {

    }
  },
  computed:{
    renderKey() {
      return new Date().getTime()
    },
  },
  methods:{
    //获取年龄
    getAge(data) {
      if(!data)return  ''
      return calculateAge(data);
    },
    getFirstDeepestNode(tree) {
      // 定义一个递归函数来遍历树
      function traverse(tree) {
        for (let i = 0; i < tree.length; i++) {
          let node = tree[i]
          // 如果当前节点是项目，则返回该节点
          if (node.deptCategory== 5) {
            return node;
          }
          // 否则，递归遍历第一个子节点

          return  node.children && traverse(node.children);
        }
      }
      // 从根节点开始遍历
      return traverse(tree);
    }
  },
}
