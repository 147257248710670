import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/tenant/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/tenant/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/tenant/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/tenant/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/tenant/submit',
    method: 'post',
    data: row
  })
}

export const setting = (ids, form) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/tenant/setting',
    method: 'post',
    params: {
      ...form,
      ids
    }
  })
}

export const datasource = (tenantId, datasourceId) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/tenant/datasource',
    method: 'post',
    params: {
      tenantId,
      datasourceId
    }
  })
}

export const info = (domain) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/tenant/info',
    method: 'get',
    params: {
      domain
    }
  })
}

export const packageInfo = (tenantId) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/tenant/package-detail',
    method: 'get',
    params: {
      tenantId
    }
  })
}

export const packageSetting = (tenantId, packageId) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/tenant/package-setting',
    method: 'post',
    params: {
      tenantId,
      packageId
    }
  })
}
