import request from '@/router/axios';
export const getPolicyDocumentList = (params) => { //统计项目视图-隐患清单-环形图
  return request({
    url: '/api/sinoma-hse-prj/cncivinorm/statistics',
    method: 'get',
    params
  })
}
export const getStatistics = (params) => { //统计项目视图-隐患清单-折线图
  return request({
    url: '/api/sinoma-hse-prj/cncivinorm/line/statistics',
    method: 'get',
    params
  })
}
export const getList = (params) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/cncivinorm/page',
    method: 'get',
    params
  })
}
export const remove = (data) => { //删除
  return request({
    url: '/api/sinoma-hse-prj/cncivinorm/remove',
    method: 'POST',
    data
  })
}
export const detail = (params) => { //详情
  return request({
    url: '/api/sinoma-hse-prj/cncivinorm/detail',
    method: 'get',
    params
  })
}
export const save = (data) => { //保存
  return request({
    url: '/api/sinoma-hse-prj/cncivinorm/save',
    method: 'post',
    data
  })
}
export const update = (data) => { //编辑
  return request({
    url: '/api/sinoma-hse-prj/cncivinorm/update',
    method: 'post',
    data
  })
}
export const exportFile = (data) => { //编辑
  return request({
    url: '/api/sinoma-hse-prj/cncivinorm/export',
    method: 'post',
    responseType:'arraybuffer',
    data
  })
}
export const HD_SOURCE_TYPE = (key) => {
  return request({
    url: `/api/sinoma-system/dict-biz/dictionary?code=${key}`,
    method: 'get',
  })
}

