import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";

const CONTROLLER_NAME = "/sys/cfg/param";

/**
 * 分页查询
 * @param current
 * @param size
 * @param params
 * @returns {*}
 */
export const getList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/page`,
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};



/**
 * 动态参数 预留接口
 */

export const getLogo = () => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/getLogo`,
    method: 'get',
  });
};


/**
 * 全部查询
 * @param data
 * @returns {*}
 */
export const list = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/list`,
    method: 'GET',
    params: {
      data
    }
  })
}

/**
 * 回显数据
 * @param id
 * @returns {*}
 */
export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/detail`,
    method: "get",
    params: {
      id,
    },
  });
};


export const getValid = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/update`,
    method: "post",
    data: row
  });
};

/**
 * 新增
 * @param data
 * @returns {*}
 */
export const add= (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}${CONTROLLER_NAME}/saveOrUpdate`,
    method: 'post',
    data: row
  })
}

/**
 * 修改
 * @param row
 * @returns {*}
 */
export const update = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}${CONTROLLER_NAME}/update`,
    method: 'post',
    data: row
  })
}


/**
 * 删除
 * @param ids
 * @returns {*}
 */
export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/remove`,
    method: 'post',
    params: {
      ids
    }
  })
};
