import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";


//新增或修改 供应商联系信息
export const conInfoSubmit = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_SYNC}/supplier/updateSupplierDo`,
        method: 'post',
        data: {
            coninfo: {
                ...params
            }
        }
    })
}
// 删除 供应商联系信息
export const conInfoRemove = (id) => {
    return request({
        url: `${ServerNameEnum.SERVER_SYNC}/supplier/delSupplierDoList`,
        method: 'post',
        data:
        {
            supplier: {
                name: "coninfo",
                id: id
            }
        }
    })
};

//新增或修改 供应商资质信息
export const qualInfoSubmit = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_SYNC}/supplier/updateSupplierDo`,
        method: 'post',
        data: {
            qualinfo: {
                ...params
            }
        }
    })
}

// 删除 供应商资质信息
export const qualInfoRemove = (id) => {
    return request({
        url: `${ServerNameEnum.SERVER_SYNC}/supplier/delSupplierDoList`,
        method: 'post',
        data:
        {
            supplier: {
                name: "qualinfo",
                id: id
            }
        }
    })
};

//新增或修改 供应商财务情况
export const finInfoSubmit = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_SYNC}/supplier/updateSupplierDo`,
        method: 'post',
        data: {
            fininfo: {
                ...params
            }
        }
    })
}

// 删除 供应商财务情况
export const finInfoRemove = (id) => {
    return request({
        url: `${ServerNameEnum.SERVER_SYNC}/supplier/delSupplierDoList`,
        method: 'post',
        data:
        {
            supplier: {
                name: "fininfo",
                id: id
            },
        }
    })
};

//新增或修改 供应商收款信息
export const bankInfoSubmit = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_SYNC}/supplier/updateSupplierDo`,
        method: 'post',
        data: {
            bankinfo: {
                ...params
            }
        }
    })
}

// 删除 供应商收款信息
export const bankInfoRemove = (id) => {
    return request({
        url: `${ServerNameEnum.SERVER_SYNC}/supplier/delSupplierDoList`,
        method: 'post',
        data:
        {
            supplier: {
                name: "bankinfo",
                id: id
            },
        }
    })
};

//新增或修改 供应商产品目录
export const proInfoSubmit = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_SYNC}/supplier/updateSupplierDo`,
        method: 'post',
        data: {
            proinfo: {
                ...params
            }
        }
    })
}

// 删除 供应商产品目录
export const proInfoRemove = (id) => {
    return request({
        url: `${ServerNameEnum.SERVER_SYNC}/supplier/delSupplierDoList`,
        method: 'post',
        data:
        {
            supplier: {
                name: "proinfo",
                id: id
            },
        }
    })
}
