import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";

export const getList = (current, size,params) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/sysfiletree/treeList',
    method: 'get',
    data: {
      ...params,
      current,
      size,
    }
  })
}
export const page = (current, size,params) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/sysfiletree/page',
    method: 'post',
    data: {
      ...params,
      current,
      size,
    }
  })
}
export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/sysfiletree/saveOrUpdate',
    method: 'post',
    data: {
      ...row
    }
  })
}
export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/sysfiletree/delete',
    method: 'post',
    params: {
      ids,
    },

  })
}
