import request from '@/router/axios';
export const getPolicyDocumentList = (params) => { //统计项目视图-隐患清单-环形图
  return request({
    url: '/api/sinoma-hse-prj/hdhiddendanger/statistics',
    method: 'get',
    params
  })
}
export const statusStatistics = (params) => { //统计项目视图-隐患清单-状态饼图
  return request({
    url: '/api/sinoma-hse-prj/hdhiddendanger/status/statistics',
    method: 'get',
    params
  })
}
export const levelStatistics = (params) => { //统计项目视图-隐患清单-状态饼图
  return request({
    url: '/api/sinoma-hse-prj/hdhiddendanger/level/statistics',
    method: 'get',
    params
  })
}
export const getStatistics = (params) => { //统计项目视图-隐患清单-折线图
  return request({
    url: '/api/sinoma-hse-prj/hdhiddendanger/line/statistics',
    method: 'get',
    params
  })
}
export const getList = (params) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/hdhiddendanger/pageNew',
    method: 'get',
    params
  })
}
export const remove = (data) => { //删除
  return request({
    url: '/api/sinoma-hse-prj/hdhiddendanger/remove',
    method: 'POST',
    data
  })
}
export const detail = (params) => { //详情
  return request({
    url: '/api/sinoma-hse-prj/hdhiddendanger/detail',
    method: 'get',
    params
  })
}
export const save = (data) => { //保存
  return request({
    url: '/api/sinoma-hse-prj/hdhiddendanger/save',
    method: 'post',
    data
  })
}
export const update = (data) => { //编辑
  return request({
    url: '/api/sinoma-hse-prj/hdhiddendanger/update',
    method: 'post',
    data
  })
}
export const exportFile = (data) => { //编辑
  return request({
    url: '/api/sinoma-hse-prj/hdhiddendanger/export',
    method: 'post',
    responseType:'arraybuffer',
    data
  })
}
export const HD_SOURCE_TYPE = (key) => {
  return request({
    url: `/api/sinoma-system/dict-biz/dictionary?code=${key}`,
    method: 'get',
  })
}

// 根据被通知项目获取上一次人员信息
export const getCheckHistory = (params) => {
  return request({
    url: `/api/sinoma-hse-prj/hdhiddendanger/getCheckHistory`,
    method: 'get',
    params
  })
}

// 隐患撤销
export const checkRevoke = (params) => {
  return request({
    url: `/api/sinoma-hse-prj/hdhiddendanger/revoke`,
    method: 'get',
    params
  })
}



