import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export function pageApiLog(data) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data/api/apiLogs/page',
    method: 'get',
    params: data
  })
}

export function getApiLog(id) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data/api/apiLogs/' + id,
    method: 'get'
  })
}

export function delApiLog(id) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data/api/apiLogs/' + id,
    method: 'delete'
  })
}

export function delApiLogs(ids) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data/api/apiLogs/batch/' + ids,
    method: 'delete'
  })
}
