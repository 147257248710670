import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_NAME = "/warning/taskDeviceMeasurementPoints/";


export const detail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_WARNING_}${CONTROLLER_NAME}detail`,
    method: 'get',
    params: {
      id
    },
  })
}

export const list = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_WARNING_}${CONTROLLER_NAME}list`,
    method: 'get',
    params,
  })
}
export const page = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_WARNING_}${CONTROLLER_NAME}page`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_WARNING_}${CONTROLLER_NAME}remove`,
    method: 'post',
    params: {
      ids,
    }
  })
}

export const saveOrUpdate = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_WARNING_}${CONTROLLER_NAME}saveOrUpdate`,
    method: 'post',
    data,
  })
}



export const saveOrUpdateList = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_WARNING_}${CONTROLLER_NAME}saveOrUpdateList`,
    method: 'post',
    data,
  })
}
