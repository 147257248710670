import {mapGetters} from "vuex";

export default (app, option = {}) => {
  const mixins = {
    data() {
      return {
        selectionList: [],
        data: [],
        form: {},
        searchForm:{},
        params: {},
        loading: false,
        dataTotal:null,
        api: require(`@/api/${option.name}`),
        page: {
          pageSizes: [10, 20,30, 40, 50, 100],
          pageSize: 20,
          currentPage:1
        },
        // headBtnOptions: [{
        //   label: this.$t('cip.cmn.btn.addBtn'),
        //   emit: "head-add",
        //   type: "button",
        //   icon: ""
        // }, {
        //   label: this.$t('cip.cmn.btn.delBtn'),
        //   emit: "head-romve",
        //   type: "button",
        //   icon: ""
        // }],
        // gridRowBtn: [
        //   {
        //     label: this.$t('cip.cmn.btn.viewBtn'),
        //     emit: "grid-view",
        //     type: "text",
        //     icon: ""
        //   },
        //   {
        //     label: this.$t('cip.cmn.btn.editBtn'),
        //     emit: "grid-edit",
        //     type: "text",
        //     icon: ""
        //   }, {
        //     label: this.$t('cip.cmn.btn.delBtn'),
        //     emit: "grid-romve",
        //     type: "text",
        //     icon: ""
        //   }
        // ]
      }
    },
    computed: {
      ...mapGetters(['userInfo', 'permission', 'roles']),
      option(){
        return require(`@/option/${option.name}`).options(this)
      },
      searchColumns(){
        return require(`@/option/${option.name}`).searchColumns(this);
      },
      ids() {
        const ids = [];
        this.selectionList.forEach(ele => {
          ids.push(ele[this.rowKey]);
        });
        return ids.join(",");
      },
      bindVal() {
        return {
          'gridRowBtn':this.gridRowBtn,
          'ref': 'gridLayOut',
          'tableOptions': this.option,
          'tableData': this.data,
          'tableLoading': this.loading,
          'dataTotal':this.dataTotal,
          'beforeOpen':this.beforeOpen
        }
      },
      onEvent() {
        return {
          'page-current-change':this.getList,
          'page-refresh-change':this.getList,
          'page-size-change': this.getList,
          'grid-edit':this.clickRowEdit,
          'grid-view':this.rowView,
          'submit-add': this.rowSave,
          'submit-edit': this.rowUpdate,
          'grid-romve': this.rowDel,
          'gird-handle-select-click': this.selectionChange
        }
      },
      rowKey() {
        return this.option.rowKey || option.rowKey || 'id'
      },
      onHeadEvent() {
        return {
          'head-add': this.clickHeadAdd,
          'head-romve': this.clickHeadRomve
        }
      },
      onSearchEvent() {
        return {
          'grid-head-search': this.searchChange,
          'grid-head-empty': this.searchReset
        }
      }
    },
    created() {
      this.getList()
    },
    methods: {
      // beforeOpen(done, type) {
      //   if (["view"].includes(type)) {
      //     this.api[option.detail || 'getDetail'](this.$refs.gridLayOut.currentRow.id).then(res => {
      //       const data = res.data.data;
      //       this.$refs.gridLayOut.currentRow = data;
      //     });
      //   }
      //   done();
      // },
      rowView(row,index){
        this.$refs.gridLayOut.$refs.grid.rowView(row,index);
      },
      clickRowEdit(row, index){
        this.$refs.gridLayOut.$refs.grid.rowEdit(row, index);
      },
      clickHeadAdd() {
        this.$refs.gridLayOut.$refs.grid.rowAdd();
      },
      getList(page) {
        const callback = () => {
          this.loading = true;
          if (page) this.page = page;
          this.api[option.list || 'getList'](this.page.currentPage, this.page.pageSize, this.params).then(res => {
            let data;
            if (option.res) {
              data = option.res(res.data);
            } else {
              data = res.data.data;
            }
            this.page.total = data[option.total || 'total'] || 0;
            this.data = data[option.records || 'records'];
            this.dataTotal = data[option.total || 'total'] || 0;
            if (this.listAfter) {
              this.listAfter(data);
            }
            this.loading = false;
          })
        }
        if (this.listBefore) {
          this.listBefore();
        }
        callback();
      },
      rowSave(row, done, loading) {
        const callback = () => {
          this.api[option.add || 'add'](this.$refs.gridLayOut.currentRow).then((data) => {
            this.getList();
            if (this.addAfter) {
              this.addAfter(data);
            } else {
              this.$message.success(this.$t('cip.cmn.msg.success.addSuccess'));
            }
            done();
          }).catch(() => {
            loading();
          })
        }
        if (this.addBefore) {
          this.addBefore();
        }
        callback();
      },
      rowUpdate(row, index, done, loading) {
        const callback = () => {
          this.api[option.update || 'update'](this.$refs.gridLayOut.currentRow).then((data) => {
            this.getList();
            if (this.updateAfter) {
              this.updateAfter(data);
            } else {
              this.$message.success(this.$t('cip.cmn.msg.success.updataSuccess'));
            }
            done();
          }).catch(() => {
            loading();
          })
        }
        if (this.updateBefore) {
          this.updateBefore();
        }
        callback();
      },
      rowDel(row, index) {
        const callback = () => {
          this.api[option.del || 'remove'](row[this.rowKey], row).then((data) => {
            this.getList();
            if (this.delAfter) {
              this.delAfter(data, row, index)
            } else {
              this.$message.success(this.$t('cip.cmn.msg.success.delSuccess'));
            }
          })
        }
        if (this.delBefore) {
          this.delBefore();
          callback();
        } else {
          this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), this.$t('tip'), {
            confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
            cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
            type: 'warning'
          }).then(() => {
            callback();
          })
        }
      },
      clickHeadRomve() {
        if (this.selectionList.length === 0) {
          this.$message.warning(this.$t('cip.cmn.msg.warning.chooseAtLeastOne'));
          return;
        }
        this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: "warning"
        })
          .then(() => {
            this.api[option.del || 'remove'](this.ids).then((data) => {
              this.getList();
              if (this.delMultiAfter) {
                this.delMultiAfter(data, this.ids);
              } else {
                this.$message.success(this.$t('cip.cmn.msg.success.delSuccess'));
              }
            });
          });
      },
      searchReset() {
        this.params = {};
        this.getList();
      },
      searchChange(params) {
        this.params = params;
        this.page.currentPage = 1;
        this.getList();
      },
      // searchChange(params, done) {
      //   if (done) done();
      //   if (this.validatenull(params)) {
      //     Object.keys(this.params).forEach(ele => {
      //       if (!['createTime_dategt', 'createTime_datelt'].includes(ele)) {
      //         delete this.params[ele];
      //       }
      //     })
      //   } else {
      //     Object.keys(params).forEach(ele => {
      //       if (this.validatenull(params[ele])) {
      //         delete this.params[ele];
      //         delete params[ele];
      //       }
      //     })
      //   }
      //   this.params = Object.assign(this.params, params);
      //   this.page.currentPage = 1;
      //   this.getList();
      // },
      dateChange(date) {
        if (date) {
          this.params.createTime_dategt = date[0];
          this.params.createTime_datelt = date[1];
        } else {
          delete this.params.createTime_dategt;
          delete this.params.createTime_datelt;
        }
        this.page.currentPage = 1;
        this.getList();
      },
      selectionChange(list) {
        this.selectionList = list;
      },
      selectionClear() {
        this.selectionList = [];
        this.$refs.gridLayOut.toggleSelection();
      }
    }
  }
  app.mixins = app.mixins || [];
  app.mixins.push(mixins);
  return app;
}
