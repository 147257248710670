var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isStorage == 1
    ? _c("el-input", {
        attrs: {
          readonly: "",
          placeholder: "用于展示计算结果，且数据不会保存",
        },
        model: {
          value: _vm.innerValue,
          callback: function ($$v) {
            _vm.innerValue = $$v
          },
          expression: "innerValue",
        },
      })
    : _c("el-input", {
        attrs: {
          readonly: "",
          placeholder: "用于展示计算结果，且数据同时会保存入库",
        },
        model: {
          value: _vm.innerValue,
          callback: function ($$v) {
            _vm.innerValue = $$v
          },
          expression: "innerValue",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }