import Layout from '@/page/index/'

export default [

  //安全环境
  {
    path: '/ExamSet',
    component: Layout,
    name:'考试设置',
    children: [
      {
        path: '/business/safetyenment/ExamSet/questionBank',
        name: '题库管理',
        component: () => import('@/views/business/safetyenment/ExamSet/questionBank/index.vue'),

        meta: {
          title: '题库管理',
          icon: ''
        }
      },
    ]
  },
  {
    path: '/training',
    component: Layout,
    name:'教育培训',
    children: [
      {
        path: '/business/safetyenment/train/recording/index',
        name: '培训记录',
        meta: {},
        component: () =>
          import(  '@/views/business/training/recording/index.vue')
      },
      {
        path: '/business/safetyenment/train/recording/form',
        name: '培训记录详情',
        meta: {},
        component: () =>
          import(  '@/views/business/training/recording/form.vue')
      },
      {
        path: '/business/safetyenment/train/recording/edit',
        name: '培训记录编辑',
        meta: {},
        component: () =>
          import(  '@/views/business/training/recording/edit.vue')
      },
      // {
      //   path: '/business/safetyenment/train/plan',
      //   name: '培训计划',
      //   component: () => import('@/views/business/safetyenment/train/plan/index.vue'),
      // },
      {
        path: '/business/safetyenment/train/trainingEducationStatistics/index',
        name: '我的培训统计',
        meta: {},
        component: () =>
          import(  '@/views/business/training/trainingEducationStatistics/index.vue')
      },
      {
        path: '/business/safetyenment/train/trainingEducation/index',
        name: '我的培训',
        meta: {
        code:'trainingEducation'},
        component: () =>
          import(  '@/views/business/training/trainingEducation/index.vue')
      },
      {
        path: '/business/safetyenment/train/trainingEducation/form',
        name: '我的培训详情',
        meta: {},
        component: () =>
          import(  '@/views/business/training/trainingEducation/form.vue')
      },
      {
        path: '/business/safetyenment/train/trainingEducation/view',
        name: '我的培训查看',
        meta: {},
        component: () =>
          import(  '@/views/business/training/trainingEducation/view.vue')
      },
      {
        path: '/business/safetyenment/train/trainingEducation/eduexampaper',
        name: '我的培训考试',
        meta: {},
        component: () =>
          import(  '@/views/business/training/trainingEducation/eduexampaper.vue')
      },

      {
        path: '/business/safetyenment/train/planAdd',
        name: '培训计划新增',
        component: () => import('@/views/business/safetyenment/train/plan/add.vue'),

        meta: {
          title: '培训计划新增',
          icon: ''
        }
      },
      {
        path: '/business/safetyenment/train/planView',
        name: '培训计划查看',
        component: () => import('@/views/business/safetyenment/train/plan/add.vue'),

        meta: {
          title: '培训计划查看',
          icon: ''
        }
      },
      {
        path: '/business/safetyenment/train/DeptGroup',
        name: '单位分组',
        component: () => import('@/views/business/safetyenment/train/DeptGroup/index.vue'),

        meta: {
          title: '单位分组',
          icon: ''
        }
      },

      {
        path: '/business/safetyenment/train/DeptGroupAdd',
        name: '单位分组新增',
        component: () => import('@/views/business/safetyenment/train/DeptGroup/add.vue'),

        meta: {
          title: '单位分组新增',
          icon: ''
        }
      },

      {
        path: '/business/safetyenment/train/trainingTask',
        name: '任务统计',
        component: () => import('@/views/business/safetyenment/train/trainingTask/index.vue'),

        meta: {
          title: '任务统计',
          icon: ''
        }
      },

      {
        path: '/business/safetyenment/train/trainingTaskAdd',
        name: '任务详情',
        component: () => import('@/views/business/safetyenment/train/trainingTask/add.vue'),

        meta: {
          title: '任务详情',
          icon: ''
        }
      },


      {
        path: '/business/safetyenment/train/trainingTaskListPageAdd',
        name: '培训任务新增',
        component: () => import('@/views/business/safetyenment/train/trainingTaskList/add.vue'),

        meta: {
          title: '培训任务新增',
          icon: ''
        }
      },
      {
        path: '/business/safetyenment/train/trainingTaskListPageView',
        name: '培训任务详情',
        component: () => import('@/views/business/safetyenment/train/trainingTaskList/add.vue'),

        meta: {
          title: '培训任务详情',
          icon: ''
        }
      },
      {
        path: '/business/safetyenment/train/trainingTaskListPageEdit',
        name: '培训任务编辑',
        component: () => import('@/views/business/safetyenment/train/trainingTaskList/add.vue'),

        meta: {
          title: '培训任务编辑',
          icon: ''
        }
      },
      {
        path: '/business/safetyenment/train/trainingUseTaskPage',
        name: '我的培训',
        component: () => import('@/views/business/safetyenment/train/trainingTaskUseList/index.vue'),

        meta: {
          title: '我的培训',
          icon: ''
        }
      },
      {
        path: '/business/knowledge/onTheJobTraining/add',
        name: '平台课程',
        component: () => import('@/views/business/knowledge/onTheJobTraining/addNew.vue'),

        meta: {
          title: '平台课程新增',
          icon: ''
        }
      },
      {
        path: '/business/knowledge/onTheJobTrainingScene/add',
        name: '自定义课程',
        component: () => import('@/views/business/knowledge/onTheJobTrainingScene/add.vue'),

        meta: {
          title: '自定义课程新增',
          icon: ''
        }
      },

    ]
  }
]
