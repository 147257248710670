import Layout from '@/page/index/'
export default [{
    path: '/contingency',
    component: Layout,
    children: [
        {
            path: '/business/contingency/emergencyPlan/add',
            name: '应急预案新增',
            meta: {
                i18n: ''
            },
            component: () =>
                import( /* webpackChunkName: "views" */ '@/views/business/contingency/emergencyPlan/add'),
        },
        {
            path: '/business/contingency/emergencyPlan/edit',
            name: '应急预案编辑',
            meta: {
                i18n: ''
            },
            component: () =>
                import( /* webpackChunkName: "views" */ '@/views/business/contingency/emergencyPlan/add'),
        },
        {
            path: '/business/contingency/emergencyPlan/view',
            name: '应急预案查看',
            meta: {
                i18n: ''
            },
            component: () =>
                import( /* webpackChunkName: "views" */ '@/views/business/contingency/emergencyPlan/add'),
        },
        {
            path: '/business/contingency/emergencyDrill/add',
            name: '应急演练新增',
            meta: {
                i18n: ''
            },
            component: () =>
                import( /* webpackChunkName: "views" */ '@/views/business/contingency/emergencyDrill/add'),
        },
        {
            path: '/business/contingency/emergencyDrill/edit',
            name: '应急演练编辑',
            meta: {
                i18n: ''
            },
            component: () =>
                import( /* webpackChunkName: "views" */ '@/views/business/contingency/emergencyDrill/add'),
        },
        {
            path: '/business/contingency/emergencyDrill/view',
            name: '应急演练查看',
            meta: {
                i18n: ''
            },
            component: () =>
                import( /* webpackChunkName: "views" */ '@/views/business/contingency/emergencyDrill/add'),
        },
    ]
}]