import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getUsualList = (current, size) => {
  return request({
    url: ServerNameEnum.SERVER_LOG_+'/usual/list',
    method: 'get',
    params: {
      current,
      size
    }
  })
}

export const getApiList = (current, size,params) => {
  return request({
    url: ServerNameEnum.SERVER_LOG_+'/api/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getErrorList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_LOG_+'/error/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}


export const getUsualLogs = (id) => {
  return request({
    url: ServerNameEnum.SERVER_LOG_+'/usual/detail',
    method: 'get',
    params: {
      id,
    }
  })
}
export const getApiLogs = (id) => {
  return request({
    url: ServerNameEnum.SERVER_LOG_+'/api/detail',
    method: 'get',
    params: {
      id,
    }
  })
}
export const getErrorLogs = (id) => {
  return request({
    url: ServerNameEnum.SERVER_LOG_+'/error/detail',
    method: 'get',
    params: {
      id,
    }
  })
}

