<template>
  <!--    <el-popover placement="bottom" width="350" trigger="click" v-model="visible">
          <div class="noticeTitle">
              {{ $t("navbar.mail") }}
          </div>-->
  <!--        <el-scrollbar style="height: 570px">

              <div class="infinite-list-wrapper" style="overflow:auto">
                  <ul class="list" v-infinite-scroll="load">
                      <li v-for="(item,index) in data" :key="index" class="list-item" @click="handleClick(item)">
                          <div class="disflex">
                              <div class="bellBox">
                                  <i class="el-icon-bell"></i>
                              </div>
                              <div style="margin-left:14px;">
                                  <div class="msgTile">{{item.msgTile}}</div>
                                  <div class="msgContent">{{item.msgContent}}</div>
                              </div>
                          </div>
                          <div style="width:32%">
                            <el-tag class="UnreadBox" v-if="item.isRead===0">已读</el-tag>
                            <el-tag class="UnreadBox" v-if="item.isRead===1">未读</el-tag>
                            <div class="msgContent" style="width:auto">{{item.createTime}}</div>
                          </div>
                      </li>
                  </ul>
              </div>
          </el-scrollbar>-->
  <!--  slot="reference"-->
  <div>
    <el-badge :hidden=isRead :value="value">
      <div class="suitem" @click="handleClick(item)">
        <img height="36" src="../../../../public/img/top/notice.png" width="36" />
        <span>{{ $t("navbar.notice") }}</span>
      </div>
    </el-badge>
  </div>
  <!--    </el-popover>-->

</template>

<script>

import {selectMsgByAccount} from "@/api/message/sendInfo";
import {mapGetters} from "vuex";

/**
 *
 * {
 *   img: '/img/bg/vip1.png',
 *   title: '史蒂夫·乔布斯 关注了你',
 *   subtitle: '05-08 15:08',
 *   tag: '已经开始',
 *   status: 0
 * }
 * */
export default {
  name: "top-notice",
  props: ['visible'],
  data() {
    return {
      dialogVisible: false,

      activeName: 'first',
      query: {
        type: '110',
        isRead: '1'
      },
      option: {
        props: {
          img: 'img',
          title: 'title',
          subtitle: 'subtitle',
          tag: 'tag',
          status: 'status'
        },
      },
      data: [],
      isRead: true,
      png: require('@/assets/images/message.png'),
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      value: ''
    }
  },
  mounted() {
    this.getChannel(this.page, this.query)
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    // 滚动获取数据
    load() {
      this.page.currentPage++;
      this.getChannel(this.page, this.query)
    },
    // 点击信息后切换页面
    handleClick(data) {
      this.$router.push({
        path: `/message/list`,
        query: {
          data: data,
        }
      })
      // this.$props.visible = false;
      this.$emit('transfer', false)
    },
    getMessage() {
      this.data = []
    },
    getRandom(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    getChannel(page, params = {}) {
      selectMsgByAccount(page.currentPage, page.pageSize, Object.assign(params, this.type)).then((res) => {
        let data = res.data.data.records;
        data.forEach((item) => {
          Object.assign(item, {img: this.png})
          if (item.isRead == 1) {
            this.isRead = false
          }
        })
        this.value = res.data.data.total;
        this.data = this.data.concat(data);
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.suitem {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  img {
    margin-bottom: 3px;
    display: block;
  }
}

.noticeTitle {
  width: 100%;
  color: #333333;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  font-size: 20px;
}

.list {
  margin-left: -40px;
}

.infinite-list-wrapper {
  height: 550px;
}

.list-item {
  list-style: none;
  display: flex;
  justify-content: space-between;
  // margin-bottom: 16px;
  border-bottom: 1px solid #f5f7f9;
  // height: 60px;
  padding: 12px 0;
  cursor: pointer;
}

.messagePng {
  width: 36px;
  height: 36px;
}

.disflex {
  display: flex;
  width: 60%;
  overflow: hidden;
  flex-shrink: 0;
}

.msgTile {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

.msgContent {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  color: #999;
}

.bellBox {
  flex-shrink: 0;
  width: 42px;
  height: 42px;
  border-radius: 50px;
  text-align: center;
  background-color: #1890ff;
}

.bellBox i {
  font-size: 22px;
  color: #fff;
  line-height: 42px;
}

.UnreadBox {
  height: 20px;
  line-height: 20px;
  margin-bottom: 5px;
  margin-left: 55%;
}
</style>
