<template>
  <div>
    <el-drawer
      :visible.sync="showShortcutMenu"
      direction="rtl"
      :with-header="false"
      size="350px"
      :before-close="handleClose"
      :modal="false"
    >
      <card
        :title="this.$t('tagsView.shortcutMenu')"
        :showMore="false"
        :showSet="true"
        @set="openMenuDialog"
        style="margin-bottom: 10px"
      >
        <template slot="content">
          <div class="quick-app-list">
            <div
              class="quick-app-item"
              v-for="(item, index) of menuData"
              :key="index"
              @click="menuClick(item)"
              @contextmenu.prevent="rightClick(item)"
            >
              <div
                class="imageBox"
                :style="`background:${quickAppBg[index % 4]}`"
              >
                <i :class="item.menuSource"></i>
              </div>
              <el-tooltip effect="dark" :content="item.menuNameEn || item.menuNameZh" placement="bottom">
                <span>{{ item.menuNameEn || item.menuNameZh }}</span>
              </el-tooltip>
            </div>
          </div>
        </template>
      </card>
    </el-drawer>
    <el-dialog
      :title="this.$t('tagsView.shortcutMenu')"
      custom-class="noticeClass"
      :visible.sync="menuShow"
      :close-on-click-modal="false"
      width="60%"
      :modal-append-to-body="false"
      append-to-body
      @close="closeMenuDialog"
    >
      <menu-dialog ref="menuDialog" :disabledData="menuData" @add="addMenu"></menu-dialog>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getList as getMenuList, remove} from "@/api/system/shortcutMenu";
import card from "@/views/cip/components/card";
import menuDialog from "@/components/menu/menuDialog";
import website from '@/config/website'

export default {
  name: "shortcutMenu",
  components: {
    card,
    menuDialog
  },
  data() {
    return {
      menuShow: false,
      quickAppBg: ['#0EC971', '#F6A153', '#FF7060', '#5DA0F4'],
      knowledgePage: {
        pageSize: 100,
        currentPage: 1,
        total: 0
      },
      knowledgeData: [],
      menuData: [],
      defaultMenu:
        [
          {
            // menuPath: this.website.webUrl+"/workflow/#/plugin/workflow/process/index",
            menuPath: "/plugin/workflow/process/todo",
            menuNameEn: this.$t('cip.desk.workflow.title.indexHeadMyTodo'),
            menuSource: "iconfont iconicon_savememo"
          },
          {menuPath: '/system/myReport', menuNameEn: this.$t('cip.plat.reportcenter.myReport.title.headTitle'),menuSource: 'el-icon-s-data'},
          {menuPath: '/system/knowledge', menuNameEn: this.$t('cip.plat.sys.knowledge.title.knowledgeTitle'), menuSource: "iconfont iconicon_star"},
          {menuPath: '/desk/notice', menuNameEn: this.$t('cip.desk.notice.title.indexHeadTitle'), menuSource: "iconfont iconicon_sms"},
        ],
    }
  },
  computed: {
    ...mapGetters(["showShortcutMenu"])
  },
  watch:{
    showShortcutMenu(val){
      if(val){
        this.getMenuListFn()
      }
    }
  },
  created() {
    this.getMenuListFn()
  },
  methods: {
    handleClose() {
      this.$store.commit('SET_SHORTCUTMENU')
    },
    getMenuListFn() {
      // this.menuData = this.defaultMenu;
      getMenuList(this.knowledgePage.currentPage, this.knowledgePage.pageSize).then(res => {
        this.menuData = this.defaultMenu.concat(res.data.data.records);
      })
    },
    // 快捷菜单点击
    menuClick(item) {
      let urlHeader = item.menuPath.slice(0, 4);
      if ("http" != urlHeader) {
        this.$router.push({
          path: item.menuPath
        })
      } else {
        this.$router.push({path: `/myiframe/urlPath?name=${item.menuName}&src=${item.menuPath}`});
      }
      this.$store.commit('SET_SHORTCUTMENU', false)
    },
    rightClick(item) {
      let menuName = item.menuNameEn!=''&&item.menuNameEn!=undefined?item.menuNameEn:item.menuNameZh
      if(item.id==undefined){
        this.$message({
          type: 'warning',
          message: '固定菜单不可删除!'
        });
        return;
      }
      this.$confirm('此操作将删除' + " " + menuName + '菜单, 是否继续?', '提示(删除后请刷新本页面)', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        remove(item.id).then(() => {
          this.getMenuListFn();
          this.$message({
            type: 'success',
            message: '删除成功!'
          });

        });
        // 这里写的是确定删除的数据刷新 也就是 快捷菜单的新数据

      });
    },
    addMenu() {
      this.getMenuListFn();
    },
    closeMenuDialog() {
      this.getMenuListFn();
    },
    openMenuDialog() {
      this.menuShow = true;
      this.$refs.menuDialog.onLoad();
    },
  }
}
</script>

<style lang="scss" scoped>
.quick-app-list {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 14px;
  box-sizing: border-box;
  width: 100%;
  overflow-y: scroll;

  .quick-app-item {
    width: 25%;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .imageBox {
      width: 40px;
      height: 40px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      i {
        color: #ffffff;
        font-weight: bold;
        font-size: 20px;
      }
    }

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 80px;
    }
  }
}
</style>
