import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_NAME = "/index/themeMeasure";

/**
 * 查询度量列表
 * @param params
 * @returns {*}
 */
export const listByThemeId = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/listByThemeId`,
    method: 'get',
    params: {
      ...params,
    }
  })
}

/**
 * 查询度量列表
 * @param params
 * @returns {*}
 */
export const getMeasureByCuboid = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/listByCuboid`,
    method: 'get',
    params: {
      ...params,
    }
  })
}

