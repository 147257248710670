/**
 * 通用工具类
 */
export default class func {
  /**
   * 不为空
   * @param val
   * @returns {boolean}
   */
  static notEmpty(val) {
    return !this.isEmpty(val);
  }

  /**
   * 是否为定义
   * @param val
   * @returns {boolean}
   */
  static isUndefined(val) {
    return val === null || typeof val === 'undefined';
  }

  /**
   * 为空
   * @param val
   * @returns {boolean}
   */
  static isEmpty(val) {
    if (
      val === null ||
      typeof val === 'undefined' ||
      (typeof val === 'string' && val === '' && val !== 'undefined')
    ) {
      return true;
    }
    return false;
  }

  /**
   * 强转int型
   * @param val
   * @param defaultValue
   * @returns {number}
   */
  static toInt(val, defaultValue) {
    if (this.isEmpty(val)) {
      return defaultValue === undefined ? -1 : defaultValue;
    }
    const num = parseInt(val, 0);
    return Number.isNaN(num) ? (defaultValue === undefined ? -1 : defaultValue) : num;
  }

  /**
   * Json强转为Form类型
   * @param obj
   * @returns {FormData}
   */
  static toFormData(obj) {
    const data = new FormData();
    Object.keys(obj).forEach(key => {
      data.append(key, Array.isArray(obj[key]) ? obj[key].join(',') : obj[key]);
    });
    return data;
  }

  /**
   * date类转为字符串格式
   * @param date
   * @param format
   * @returns {null}
   */
  static format(date, format = 'YYYY-MM-DD HH:mm:ss') {
    return date ? date.format(format) : null;
  }

  /**
   * 根据逗号联合
   * @param arr
   * @returns {string}
   */
  static join(arr) {
    return arr ? arr.join(',') : '';
  }

  /**
   * 根据逗号分隔
   * @param str
   * @returns {string}
   */
  static split(str) {
    return str ? String(str).split(',') : '';
  }

  /**
   * 行编辑y
   */
  static findParent(obj) {
    return obj.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent;
  }

  static deleteJsonByKey(obj, key_prefix) {
    Object.keys(obj).forEach(function (key) {
      if (key.indexOf(key_prefix) != -1) {
        delete obj[key];
      }
    });
    return obj
  }

  static deleteEmptyProperty(object) {
    for (var i in object) {
      var value = object[i];
      if (typeof value === 'object') {
        if (Array.isArray(value)) {
          if (value.length == 0) {
            delete object[i];
            continue;
          }
        }
        this.deleteEmptyProperty(value);
        if (this.isEmpty(value)) {
          delete object[i];
        }
      } else {
        if (value === '' || value === null || value === undefined) {
          delete object[i];
        } else {
        }
      }
    }
  }

  static arrayGroupBy(list,f){
    const groups = {}
    list.forEach(function (re) {
      const group = f(re);
      groups[group] = groups[group] || [];
      groups[group].push(re);
    });
    return Object.keys(groups).map(function (group) {
      let groupLabel = group.split(',') //根据“，”切割成数组（可自定义）
      return {
        provinceCode:groupLabel[0], //主套餐号
        provinceName:groupLabel[1], //备注说明
        list:groups[group] //
      }
    });
    return groups;
  }
}




