var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.showShortcutMenu,
            direction: "rtl",
            "with-header": false,
            size: "350px",
            "before-close": _vm.handleClose,
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showShortcutMenu = $event
            },
          },
        },
        [
          _c(
            "card",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: {
                title: this.$t("tagsView.shortcutMenu"),
                showMore: false,
                showSet: true,
              },
              on: { set: _vm.openMenuDialog },
            },
            [
              _c("template", { slot: "content" }, [
                _c(
                  "div",
                  { staticClass: "quick-app-list" },
                  _vm._l(_vm.menuData, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "quick-app-item",
                        on: {
                          click: function ($event) {
                            return _vm.menuClick(item)
                          },
                          contextmenu: function ($event) {
                            $event.preventDefault()
                            return _vm.rightClick(item)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "imageBox",
                            style: `background:${_vm.quickAppBg[index % 4]}`,
                          },
                          [_c("i", { class: item.menuSource })]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: item.menuNameEn || item.menuNameZh,
                              placement: "bottom",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(item.menuNameEn || item.menuNameZh)
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: this.$t("tagsView.shortcutMenu"),
            "custom-class": "noticeClass",
            visible: _vm.menuShow,
            "close-on-click-modal": false,
            width: "60%",
            "modal-append-to-body": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.menuShow = $event
            },
            close: _vm.closeMenuDialog,
          },
        },
        [
          _c("menu-dialog", {
            ref: "menuDialog",
            attrs: { disabledData: _vm.menuData },
            on: { add: _vm.addMenu },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }