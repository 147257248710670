import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";

export const getPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rpReportDistribution/getPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const getDetailPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rpReportDistribution/getDetailPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}


// 填报模板详情
export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rpReportDistribution/detail?id=` + id,
    method: 'get'
  })
}

export const tree = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rpReportDistribution/tree`,
    method: 'get',
    params: data
  })
}
