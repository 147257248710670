import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const CONTROLLER_NAME = "/warning/application/";


export const list = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_WARNING_}${CONTROLLER_NAME}list`,
    method: 'get',
    params,
  })
}

export const tree = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_WARNING_}${CONTROLLER_NAME}tree`,
    method: 'get',
    params,
  })
}

export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_WARNING_}${CONTROLLER_NAME}remove`,
    method: 'post',
    params: {
      ids,
    }
  })
}

export const saveOrUpdate = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_WARNING_}${CONTROLLER_NAME}saveOrUpdate`,
    method: 'post',
    data,
  })
}
