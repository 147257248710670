/*
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-01-31 15:46:41
 */
import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getList = (current, size, params) => {
  return request({
    url: '/api/sinoma-hse-prj/thirduser/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/sinoma-hse-prj/thirduser/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/sinoma-hse-prj/thirduser/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/sinoma-hse-prj/thirduser/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/sinoma-hse-prj/thirduser/submit',
    method: 'post',
    data: row
  })
}

export const batchSubmit = (row) => {
  return request({
    url: '/api/sinoma-hse-prj/prjthirduser/batchSubmit',
    method: 'post',
    data: row
  })
}


//项目相关方人员--分页
export const thirduserPage = (current,size,params)=>{
  return request({
    url:'/api/sinoma-hse-prj/prjthirduser/page',
    method:'get',
    params: {
      current,
      size,
      ...params,
    }
  })
}
