import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_FEEDBACK = "/feedback";
export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+CONTROLLER_FEEDBACK+'/submit',
    method: 'post',
    data: row
  })
}
export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+CONTROLLER_FEEDBACK+'/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const getUserInfo = (data) => {
  return request({
    url: ServerNameEnum.SERVER_USER_+'/getOnlineUser',
    method: 'get',
    params: {
      data
    }

  })
}
export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+CONTROLLER_FEEDBACK+'/detail',
    method: 'get',
    params: {
      id
    }
  })
}
export const submit = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+CONTROLLER_FEEDBACK+'/submit',
    method: 'post',
    data: row
  })
}
export const base64 = (screenshot) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+CONTROLLER_FEEDBACK+'/base64',
    method: 'post',
      data:screenshot,
  })
}


export const putFile = (data) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_ + '/oss/endpoint/upload-base64/feedback',
    method: 'post',
    data,
  })
}
