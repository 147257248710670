export default {
  pm: {
    monthProducePlan: {
      title: {
        monthPurchasePlan: "monthPurchasePlan",
        monthPurchaseAddPlan: "monthPurchaseAddPlan",
        monthPurchaseEditTitle: "monthPurchaseEditTitle",
        monthPurchaseViewTitle: "monthPurchaseViewTitle",
        monthSalePlan: "monthSalePlan",
        monthSaleAddPlan: "monthSaleAddPlan",
        monthSaleEditTitle: "monthSaleEditTitle",
        monthSaleViewTitle: "monthSaleViewTitle",
        monthProducePlan: "monthProducePlan",
        monthProduceAddPlan: "monthProduceAddPlan",
        monthProduceEditTitle: "monthProduceEditTitle",
        monthProduceViewTitle: "monthProduceViewTitle",
        planDetails: "planDetails",
      },
      field: {
        Difference: "Difference",
        productName: "productName",
        planState: "planState",
        planNum: "planNum",
        planYear: "planYear",
        planMonth: "planMonth",
        itemName: "itemName",
        month: "month",
        planName: "planName",
        userName: "userName",
        yearTotal: "yearTotal",
        yearTotalAct: "yearTotalAct",
        month1Plan: "month1Plan",
        month2Plan: "month2Plan",
        month3Plan: "month3Plan",
        month4Plan: "month4Plan",
        month5Plan: "month5Plan",
        month6Plan: "month6Plan",
        month7Plan: "month7Plan",
        month8Plan: "month8Plan",
        month9Plan: "month9Plan",
        month10Plan: "month10Plan",
        month11Plan: "month11Plan",
        month12Plan: "month12Plan",
        month1Act: "month1Act",
        month2Act: "month2Act",
        month3Act: "month3Act",
        month4Act: "month4Act",
        month5Act: "month5Act",
        month6Act: "month6Act",
        month7Act: "month7Act",
        month8Act: "month8Act",
        month9Act: "month9Act",
        month10Act: "month10Act",
        month11Act: "month11Act",
        month12Act: "month12Act",
      }
    },
    yearProducePlan: {
      title: {
        yearPurchasePlan: "yearPurchasePlan",
        yearPurchaseAddPlan: "yearPurchaseAddPlan",
        yearPurchaseEditTitle: "yearPurchaseEditTitle",
        yearPurchaseViewTitle: "yearPurchaseViewTitle",
        yearSalePlan: "yearSalePlan",
        yearSaleAddPlan: "yearSaleAddPlan",
        yearSaleEditTitle: "yearSaleEditTitle",
        yearSaleViewTitle: "yearSaleViewTitle",
        yearProducePlan: "yearProducePlan",
        yearProduceAddPlan: "yearProduceAddPlan",
        yearProduceEditTitle: "yearProduceEditTitle",
        yearProduceViewTitle: "yearProduceViewTitle",
        planDetails: "planDetails",
        planDetail: "planDetail"
      },
      field: {
        inputData: "Please enter data",
        AGASaving: "Automatically generated after saving",
        planData: "planData",
        productName: "productName",
        planForm: "yearXXPlanForm",
        monthForm: "monthXXPlanForm",
        planState: "planState",
        planNum: "planNum",
        planYear: "planYear",
        itemName: "itemName",
        year: "year",
        planName: "planName",
        userName: "userName",
        yearTotal: "yearTotal",
        month1: "month1",
        month2: "month2",
        month3: "month3",
        month4: "month4",
        month5: "month5",
        month6: "month6",
        month7: "month7",
        month8: "month8",
        month9: "month9",
        month10: "month10",
        month11: "month11",
        month12: "month12",
        dataError: "There is an error in the data format,Please confirm that the data can only hold up to two decimal places",
        fileOr: "Drag the file here, or",
        clickUpload: "click upload",
        initialValue: "InitialValue",
        correctionValue: "CorrectionValue",
      },
      btn: {
        importBtn: "import",
        exportPlan: "export",
      },
      msg: {
        itemDetailsForACurrentPlan: "Item details for a current plan that is under review or has passed approval cannot be deleted",
        processIsNotModified: "The current plan is under review or has passed review, and modifications are not supported",
        notYearProducePlan: "The operation failed, make sure there is no corresponding annual plan",
        notAddSameItemRepeatedly: "Save failed, do not add the same item repeatedly",
        saveDetails: "If the import fails, save the header data before importing the detailed data",
        detailsIsNull: "Import failed, make sure that the schedule details data for the page are empty",
        savePlanToSubmit: "Please save the plan before you can submit it",
        pleaseFillInThePlanName: "Please fill in the plan name",
        addPlanDetailsSubmit: "Please add the plan details and submit",
        submits: "Verification is complete, please continue with submission",
        submitByProduce: "Tip, the annual production plan total for the following items is less than the annual sales plan total: ",
        submitByPurchase: "Tip, the total annual purchase volume of the following items is less than the material requirements of the total annual planned production: ",
        selectRightForm: "Please select the correct form to import and upload",
        selectRightFormHead: "Please upload the form file with the correct header",
        notItemData: "Write failed, there is material data in the table that does not exist in the system: ",
        thereItemCode: "Write failed, there is item code as: ",
        lineDataRepeat: "of rows of duplicate data",
        itemNameDataNotFilled: "Write failed, one or more lines of item name data are not filled in",
        itemCodeDataNotFilled: "Write failed, one or more lines of item code data are not filled in",
        month1DataIssue: "Write failed, There are data less than zero in January",
        month2DataIssue: "Write failed, There are data less than zero in February",
        month3DataIssue: "Write failed, There are data less than zero in March",
        month4DataIssue: "Write failed, There are data less than zero in April",
        month5DataIssue: "Write failed, There are data less than zero in May",
        month6DataIssue: "Write failed, There are data less than zero in June",
        month7DataIssue: "Write failed, There are data less than zero in July",
        month8DataIssue: "Write failed, There are data less than zero in August",
        month9DataIssue: "Write failed, There are data less than zero in September",
        month10DataIssue: "Write failed, There are data less than zero in October",
        month11DataIssue: "Write failed, There are data less than zero in November",
        month12DataIssue: "Write failed, There are data less than zero in December",
        uploadSuccessful: "The upload is successful",
        notYearPlan: "The upload failed, and the corresponding annual plan was not queried",
        planDataDoesNotMatch: "The write failed and there is an item data that does not match the corresponding year plan",
        planNotDelete: "Plans that entered the process cannot be deleted",
        notOrRepeatYearPlan: "The reason for the failure to add may be that the month plan is added repeatedly, the plan of the previous month is not added, or the corresponding annual plan is reviewed first",
        addJanuary: "Row data saving failed, please add the January production plan first",
        addDetails: "Please add the plan details and submit",
        selectPlan: "Please select the corresponding plan file",
        specificationUpload: "The table title is not standardized, please modify the specification and upload it",

      }
    },
    //能管配置
    PmIndexCfg: {
      title: {
        title: "Management index Configuration"
      },
      btn: {
        exportMonitoring: "Export Configuration",
      },
      msg: {
        exportPmIndexCfg: "Export tube indicator configuration",
        template: "Export tube indicator configuration template"
      },
      field: {
        indexCode: "index code",
        indexName: "indexName",
        sectName: "sectName",
        sectCode: "sectCode",
        processName: "processName",
        processCode: "processCode",
        equipCode: "equipCode",
        equipName: "equipName",
        itemName: "itemName",
        itemCode: "itemCode",
        recordType: "recordType",
        type: "Time data type",
        dcsType: "dcsType",
      }
    },
    // 一卡通出厂
    oneCardStockOut: {
      field: {
        outbound: "outbound",
        baseInfo: "baseInfo",
        soNo: "soNo",
        optType: "optType",
        customerName: "customerName",
        customerCode: "customerCode",
        carrierName: "carrierName ",
        carrierCode: "carrierCode",
        carNo: "carNo",
        extItemName: "extItemName",
        extItemCode: "extItemCode",
        unitCode: "extItemCode",
        unitName: "unitName",
        unitPrice: "unitPrice",
        transType: "transType",
        tareWeight: "tareWeight(dun)",
        grossWeight: "grossWeight(dun)",
        netWeight: "netWeight(dun)",
        inTime: "inTime",
        outTime: "outTime",
        batchCode: "batchCode",
        optFlag: "optFlag",
        optMsg: "optMsg",
        itemCodeAndName: "itemCodeAndName",
        status: "status",
      },
      title: {
        labelTitle: "One card factory",
        headTitle: "One card factory",
        textTitle: "One Card Information",
        indexHeadTitle: "One card factory",
        addHeadTitle: "One card factoryAdd",
        editHeadTitle: "One card factoryEdit",
        viewHeadTitle: "One card factoryView",
      },
    },
    // 一卡通入场
    oneCardStockIn: {
      field: {
        warehouse: "warehousing",
        poNo: "poNo",
        optType: "optType",
        supplierName: "supplierName",
        supplierCode: "supplierCode",
        carrierName: "carrierName ",
        carrierCode: "carrierCode",
        miningName: "miningName",
        extItemName: "extItemName",
        extItemCode: "extItemCode",
        unitCode: "unitCode",
        unitName: "unitName",
        unitPrice: "unitPrice",
        transType: "transType",
        tareWeight: "tareWeight(dun)",
        grossWeight: "grossWeight(dun)",
        netWeight: "netWeight(dun)",
        carNo: "carNo",
        inTime: "inTime",
        outTime: "outTime",
        inTimeStart: "Starting time of entering the factory",
        inTimeOut: "End time of entering the factory",
        outTimeStart: "Factory start time",
        outTimeOut: "Factory end time",
        batchCode: "batchCode",
        optFlag: "optFlag",
        optMsg: "optMsg",
        itemCodeAndName: "itemCodeAndName",
        status: "status",
        summaryOfNetWeight: "Summary Of Net Weight",
        ton: "ton",
      },
      title: {
        labelTitle: "One card entry into the factory",
        headTitle: "One card entry into the factory",
        textTitle: "One Card Information",
        indexHeadTitle: "One card entry into the factory",
        addHeadTitle: "One card entry into the factoryAdd",
        editHeadTitle: "One card entry into the factoryEdit",
        viewHeadTitle: "One card entry into the factoryView",
      },
    },
    //生产调度
    dispatch: {
      field: {
        icon: "icon",
        name: "processName",
        key: "processIdentification",
        category: "processCategory",
        version: "version",
        status: "status",
        activation: "activation",
        pending: "pending",
      },
      btn: {
        initiateBtn: "initiate",
      },
      title: {
        indexHeadTitle: "Dispatch List",
      },
    },
    //计划管理
    plan: {
      field: {
        year: 'year',
        month: "month",
        productCode: 'productCode',
        productName: 'productName',
        unitName: 'unitName',
        totalQty: 'totalQty',
        sum: 'sum',
        janQty: 'janQty',
        febQty: 'febQty',
        marQty: 'febQty',
        aprQty: 'aprQty',
        mayQty: 'mayQty',
        junQty: 'junQty',
        julQty: 'julQty',
        augQty: 'augQty',
        sepQty: 'sepQty',
        octQty: 'octQty',
        novQty: 'novQty',
        decQty: 'decQty',
        status: "status",
        documentNumber: "documentNumber",
        months: "month",
        documenter: "documenter",
        documentationDate: "documentationDate",
        remark: "remark",
        index: "index",
        yearMonth: "year month",
        deptName: "Reporting department",
        billDate: "Filling time",
        userName: "applicant"
      },
      title: {
        demandTitle: "Demand planning",
        saleTitle: "Sale planning",
        produceTitle: "Produce planning",
        operateTitle: "Operate planning",
        operateDetailTitle: "Operate planning Detail",
        itemTitle: "Production items",
        basicInformation: "Basic information",
        productionoperation: "Production operation plan"
      },
      msg: {
        itemTip: "Please Select Product",
        totalQtyTip: "Please enter the planned quantity in the correct format",
        productRepeat: "Each product can only save one piece of data per year",
      }
    },
    //物料入库
    stockIn: {
      field: {
        stockInCode: 'stockInCode',
        itemCodeAndName: 'itemCodeAndName',
        warehouseCodeAndName: 'warehouseCodeAndName',
        startDate: 'startDate',
        endDate: 'endDate',
        stockTime: 'stockTime',
        itemCode: 'itemCode',
        itemName: 'itemName',
        unitName: 'unitName',
        stockInQty: 'stockInQty',
        warehouseName: 'warehouseName',
        carNo: 'carNo',
        stockTransationType: 'stockTransationType',
        logisticsCode: 'logisticsCode',
        supplierCode: 'supplierCode',
        supplierName: "supplierName",
        extItemCode: 'extItemCode',
        extItemName: 'extItemName',
        status: 'status',
        remark: 'remark',
        dcsCode: "DCS Code",
        stockUser: "Inbound User",
      },
      btn: {
        confirmStockInBtn: "Stock In",
      },
      title: {
        logisticsTitle: "One Card Storage",
        logisticsAddTitle: "One Card Storage Add",
        logisticsEditTitle: "One Card Storage Edit",
        logisticsViewTitle: "One Card Storage View",
        dcsTitle: "DCS Warehousing",
        dcsAddTitle: "DCS Warehousing Add",
        dcsEditTitle: "DCS Warehousing Edit",
        dcsViewTitle: "DCS Warehousing View",
        manualTitle: "Manual Warehousing",
        manualAddTitle: "Manual Warehousing Add",
        manualEditTitle: "Manual Warehousing Edit",
        manualViewTitle: "Manual Warehousing View",
        baseInfoTitle: "Base Info",
        oneCardInfoTitle: "One Card Info",
        productionMaterialsTitle: "Production Materials",
        supplierTitle: "Supplier",
        ERPMaterialsTitle: "ERP Materials",
        wareHouseInfoTitle: "WareHouse Info",
      },
      msg: {
        tipDetermineHaveFinishInStatus: 'There is data that has been deposited and cannot be deleted!',
        tipDetermineHaveFinishInStatusRow: 'The current data is in the database and cannot be deleted!',
        tipDetermineHaveFinishIn: 'The current item is put into storage!',
        tip_determine_have_finish_out: 'The current item is put into storage!',
        startDateMustLessThanEndDate: "The start time cannot be greater than the end time",
        startDateMustLessThan: "Start time or end time If all must be selected",
        stockInFinished: "The warehouse receipt has already been stored!",
        stockInNotExist: "No warehouse receipt information found!",
      }
    },
    //物料出库
    stockOut: {
      field: {
        stockOutCode: 'stockOutCode',
        stockTransationType: 'stockTransationType',
        itemCodeAndName: 'itemCodeAndName',
        warehouseCodeAndName: 'warehouseCodeAndName',
        startDate: 'startDate',
        endDate: 'endDate',
        stockTime: 'stockTime',
        itemCode: 'itemCode',
        itemName: 'itemName',
        unitName: 'unitName',
        stockOutQty: 'stockOutQty',
        warehouseName: 'warehouseName',
        carNo: 'carNo',
        logisticsCode: 'logisticsCode',
        customerCode: 'customerCode',
        customerName: "customerName",
        extItemCode: 'extItemCode',
        extItemName: 'extItemName',
        remark: 'remark',
        status: 'status',
        dcsCode: "DCS Code",
        stockUser: "Outbound User",
      },
      btn: {
        confirmStockOutBtn: "Outbound",
      },
      title: {
        logisticsTitle: "One card outbound",
        logisticsAddTitle: "One Card outbound Add",
        logisticsEditTitle: "One Card outbound Edit",
        logisticsViewTitle: "One Card outbound View",
        dcsTitle: "DCS Outbound",
        dcsAddTitle: "DCS Outbound Add",
        dcsEditTitle: "DCS Outbound Edit",
        dcsViewTitle: "DCS Outbound View",
        manualTitle: "Manual Outbound",
        manualAddTitle: "Manual Outbound Add",
        manualEditTitle: "Manual Outbound Edit",
        manualViewTitle: "Manual Outbound View",
        baseInfoTitle: "Base Info",
        oneCardInfoTitle: "One Card Info",
        productionMaterialsTitle: "Production Materials",
        customerTitle: "Customer",
        ERPMaterialsTitle: "ERP Materials",
        wareHouseInfoTitle: "WareHouse Info",
      },
      msg: {
        tipDetermineHaveFinishOutStatus: "There is data that has already been shipped out and cannot be deleted!",
        tipDetermineHaveFinishOutStatusRow: "The current data has been shipped out and cannot be deleted!",
        tip_determine_have_finish_in: 'The current item is put into storage!',
        tipDetermineHaveFinishOut: 'The current item is put into storage!',
        startDateMustLessThanEndDate: "The start time cannot be greater than the end time",
        serial_number_code_error: "Encoding generation failed",
        startDateMustLessThan: "Start time or end time If all must be selected",
        stockOutFinished: "The delivery order has already been shipped out!",
        stockOutNotExist: "No outbound order information found!",
        startDateMustLessThansealDate: "The sealing time cannot be longer than the unsealing time",
      }
    },
    //物料盘点
    stockCheck: {
      field: {
        stockCheckCode: 'stockCheckCode',
        startTime: 'startTime',
        endTime: 'endTime',
        userName: "userName",
        realName: 'realName',
        participant: "participant",
        stockCheckTime: "inventoryTime",
        status: 'status',
        stockCheckType: 'stockCheckType',
        remark: "remark",
        warehouseCode: 'warehouseCode',
        warehouseName: 'warehouseName',
        itemCode: 'itemCode',
        itemName: 'itemName',
        itemDensity: "itemDensity",
        stockQty: 'stockQty',
        stockCheckQty: 'stockCheckQty',
        stockTransationQty: 'stockTransationQty',
        locationCode: 'locationCode',
        locationName: 'locationName',
        paramList: "paramList",
        warehouseInfo: "Warehouse Information",
        locationInfo: "Warehouse Location Information",
        formulaText: "Formula",
        inventoryCheck: "Inventory Check",
        cal: "Count",
        index: "Serial Number",
        code: "Parameter number",
        name: "Parameter",
        defVal: "Default value",
        value: "Parameter value",
        dailyInventory: "dailyInventory",
        monthlyInventory: "monthlyInventory",
        summary: "Summary Result",
        storageLocation: "Storage Location",
        record: "record",
      },
      btn: {
        adjustQtyBtn: "AdjustQty",
        selWarehouseBtn: "Select Warehouse",
        checkBtn: "Inventory",
        addCheckBtn: "Add Detail",
      },
      title: {
        stockCheckTitle: "Material Inventory",
        inventoryRecordsAddTitle: "Material Inventory Add",
        inventoryRecordsEditTitle: "Material Inventory Edit",
        inventoryRecordsViewTitle: "Material Inventory View",
        lineTitle: "Inventory List",
        baseTitle: "Basic Information",
        summaryTitle: "Summary Title",
        inventoryDetailTitle: "Inventory Detail",
        warehouseTitle: "Select Storage Location",
      },
      msg: {
        blank: "Please fill out the complete inventory list",
        delWarningBlank: "Please select at least one inventory list",
        noDelBlank: "Cannot delete inventory records with inventory status being approved or completed!",
        limitation: "The integer range of inventory quantity is within 1-8 digits",
        hasMonth: "There is a monthly inventory in this month's Accounting period, please re select",
        noFormula: "The warehouse has not yet configured formula parameters",
        cal: "The inventory result must be greater than or equal to 0",
        submit: "The inventory result for each line of inventory details must be filled in and must be greater than 0",
        warehouse: "Please select a storage location first",
        index: "index",
        indexError: "row data error,",
        warehouseCode: "No corresponding physical warehouse code found",
        warehouseCodeAndItemCode: "The material information was not found based on the corresponding warehouse code and material code",
      }
    },
    //库存查询
    stock: {
      field: {
        bzCode: "code",
        itemCodeAndName: "materialCode/name",
        warehouseCodeAndName: "warehouseCode/name",
        startDate: "startDate",
        endDate: "endDate",
        stockTime: "time",
        itemCode: "materialCode",
        itemName: "materialName",
        unitName: "unit",
        stockQty: "number",
        inventoryQty: "inventoryQuantity",
        warehouseName: "warehouseName",
        warehouseCode: "warehouseCode",
        month: "plan month",
      },
      title: {
        rawMaterialTitle: "Raw Material Warehousing",
        factoryTitle: "Factory Products",
        materialTitle: "Material Consumption",
        productOutputTitle: "Product Output",
        stockTakingTitle: "Inventory Count",
        stockListTitle: "Inventory List",
      },
    },
    //测点订阅
    centralControl: {
      field: {
        classify: "classify",
        importLabel: "importLabel",
        upload: "upload",
        categoryIndexCode: "categoryIndexCode",
        deviceCode: "deviceCode",
        devicePropertyCode: "devicePropertyCode",
        devicePropertyName: "devicePropertyName",
        categoryCode: "categoryCode",
        categoryName: "categoryName",
        categoryFormula: "categoryFormula",
        remark: "remark",
        standardPointClass: "standardPointClass",
        standardPointType: "standardPointType",
      },
      btn: {
        selectFileBtn: 'Select File',
      },
      title: {
        labelTitle: "Central control measurement point",
        headTitle: "List of central measurement points",
        menuTitle: "Classification properties",
        headCatTitle: "The classification of central control measurement points has been added",
        headTypeTitle: "Classification of measurement point subscription types",
        importTitle: 'Import',
        addMaterialTitle: 'Add Material',
        dialogTitle: "Device Attribute Selection",
        deviceTitle: "Device Information",
      },
      msg: {
        topicBusiTypeSelect: 'Please select a measurement point subscription category!',
        uploadInfo: 'Please select the uploaded measurement point file!',
        deletePmCategoryRecord: "Please delete the current classification central control record first!",
        deletePmCategoryIndex: "Please delete the current classification measurement point subscription list first!",
        importLoading: "Importing data, please wait",
        importFormat: "Support for importing. xlsx format files",
        tip_determine_no_select_cate: "Please select a category first",
        templateType: "The input file type does not match. Please use the export template type",
      }
    },
    //交接班记录
    classReport: {
      btn: {
        classAdd: "classAdd",
      },
      field: {
        classCode: "teamCode",
        className: "teamName",
        classCatCode: "teamTypeCode",
        classCatName: "teamType",
        deviceCode: "deviceCode",
        deviceName: "deviceName",
        calendarDate: "rosterDate",
        startCalendarDate: "roster start date",
        endCalendarDate: "roster end date",
        checkDate: "The start date cannot be longer than the end date",
        classShiftCode: "classShiftCode",
        classShiftName: "classShiftName",
        startTime: "startTime",
        endTime: "endTime",
        optUserName: "handoverPerson",
        optUserCode: "handoverPersonCode",
        createTime: "shiftHandoverTime",
        workTime: "workHours",
        classReportText: "shiftHandoverContent",
        to: " to ",
        statusType: "status"
      },
      title: {
        classReportTitle: "Team daily",
        classReportAddTitle: "Team daily Add",
        classReportEditTitle: "Team daily Edit",
        classReportViewTitle: "Team daily View",
      },
      msg: {
        data_empty: "There is no data for the current shift",
        delClassReportFail: "Failed to delete team daily report data!",
        warehouseCodeNotExist: "The warehouse code is missing and cannot be stocked in or out!",
        hasSubmit: "Submitted, please do not resubmit!!!",
        schedulingCalendarNotFound: "Scheduling calendar not found",
      },
      //生产明细
      classReportLine: {
        field: {
          order: "order",
          equipName: "device",
          productName: "product",
          warehouseName: "Transfer out of warehouse",
          outputValInit: "Yield (initial value)",
          outputVal: "Yield (corrected value)",
          runTimesInit: "Running time (initial value)",
          runTimes: "Running time (corrected value)",
          usePower: "powerConsumption",
          hour: " hour ",
          itemCode: "materialCode",
          itemName: "materialName",
          itemInput: "Consumption (corrected value)",
          itemInputInit: "Consumption (initial value)",
        },
        btn: {
          consumeBtn: "Consumption",
        },
        title: {
          productionDetailsTitle: "Production Details",
          consumeTitle: "Material Consumption",
        },
      },
      //设备开停机情况
      deviceSwitchRecord: {
        field: {
          order: "order",
          deviceCode: "deviceCode",
          deviceName: "deviceName",
          deviceStatus: "eventType",
          optTime: "eventTime",
          eventClass: "eventClass",
          eventDesc: "eventDescription"
        },
        title: {
          EquipmentTitle: "Equipment Startup And Shutdown Status",
        },
      },
      //其他事件
      classReportEvent: {
        field: {
          order: "order",
          equipCode: "deviceCode",
          equipName: "deviceName",
          deviceStatus: "eventType",
          optTime: "eventTime",
          eventClass: "eventClass",
          eventDesc: "eventDescription",
        },
        title: {
          otherEventTitle: "Other Event",
        },
        msg: {
          equipNameTip: "Please select device name",
          deviceStatusTip: "Please select event type",
        },
      },
    },
    //开停机查询
    deviceQuery: {
      field: {
        deviceCode: "deviceCode",
        deviceName: "deviceName",
        startTime: "startTime",
        endTime: "endTime",
        runTime: "runTime",
        shutdownCode: "shutdownCode",
        shutdownInstruction: "shutdownInstruction",
        deviceFaultCatName: "deviceFaultCatName",
        deviceFaultDetailName: "deviceFaultDetailName",
      },
      title: {
        queryTitle: "Start/Stop Query",
      },
    },
    //设备状态
    deviceStatus: {
      field: {
        deviceCode: "deviceCode",
        deviceName: "deviceName",
        deviceStatus: "deviceStatus",
        optTime: "Operation Time",
      },
      title: {
        statusTitle: "Device Status",
        infoTitle: "Device Information",
        statusAddTitle: "Device Status Add",
        statusEditTitle: "Device Status Edit",
        statusViewTitle: "Device Status View",
      },
    },
    //生产采集修改
    classReportDtlHour: {
      field: {
        equipName: "equipName",
        dcsType: "dcsType",
        dcsName: "dcsName",
        date: "date",
        optTime: "optTime",
        dcsVal: "dcsVal",
        init: "init",
        update: "update",
        proItemName: "proItemName",
      },
      title: {
        indexTitle: "Production Collection Modification",
      },
      msg: {
        timeNotNull: "Time query criteria cannot be empty!",
        submit: "After clicking submit, all data for today cannot be modified again",
      }
    },
    //设备开停机记录
    deviceRecord: {
      field: {
        deviceCode: "deviceCode",
        deviceName: "deviceName",
        deviceStatus: "deviceStatus",
        optTime: "optTime",
        remark: "remark",
        operate: "operate"
      },
      title: {
        deviceRecordTitle: "Equipment Startup/Shutdown Record",
        deviceRecordAddTitle: "Equipment Startup/Shutdown Record Add",
        deviceRecordEditTitle: "Equipment Startup/Shutdown Record Edit",
        deviceRecordViewTitle: "Equipment Startup/Shutdown Record View",
        infoTitle: "Device Information",
      },
    },
    //交接班记录修改
    classReportDtl: {
      field: {
        equipName: "equipName",
        classShiftName: "classShiftName",
        dcsType: "dcsType",
        dcsName: "dcsName",
        calendarDate: "calendarDate",
        classCatName: "classCatName",
        className: "className",
        dcsVal: "dcsVal",
        init: "init",
        update: "update",
      },
      title: {
        classReportDtlTitle: "Modification Of Team daily Report",
      },
      msg: {
        submit: "After clicking submit, all team daily report data for this day cannot be modified again",
      }
    },
    //上班签到
    workRecord: {
      field: {
        calendarDate: "calendarDate",
        classCatName: "classCatName",
        className: "className",
        classShiftName: "classShiftName",
        teamLeader: "teamLeader",
        userName: "personnel",
        isRequired: "isRequired",
        isTeamLeader: "isTeamLeader",
        remark: "remark",
        workDate: "clockInTime",
        bringOut: "bringOut",
        deptName: "deptName",
        isWork: "isWork",
        workType: "workType",
      },
      title: {
        workRecordTitle: "Work Record",
        classCalendarTitle: "Scheduling Calendar",
        classCalendarAddTitle: "Scheduling Calendar Add",
      },
      btn: {
        onDutyPersonnelBtn: "On Duty Personnel",
      },
      msg: {
        multiple_class_error: "There are multiple team information, please hiddenTrouble!",
        onlyOneLeader: "A team can only have one team leader",
        multiple_class_calendar_error: "There are multiple team classification information, please hiddenTrouble!",
        no_class_calendar_error: "The current logged in user does not have a team classification",
      }
    },
    // 物料平衡
    itemBalance: {
      field: {
        itemBalanceCode: 'itemBalanceCode',
        month: 'Planned Monthly',
        status: 'status',
        userCode: 'userCode',
        userName: 'Creator',
        deptCode: 'deptCode',
        deptName: 'dept',
        remark: 'remark',
        index: "index",
        createTime: "Preparation time",
      },
      placeholder: {
        autoValue: 'automatically',
      },
      title: {
        itemBalanceTitle: 'Item Balance',
        baseInfoTitle: 'Base Info',
      },
      tip: {
        tip_determine_submit: 'Confirm submitting the data?',
      },
      status: {
        draft: 'draft',
        submitted: 'submitted',
      },
      msg:{
        monthlyInventory:"Monthly inventory not done this month!",
        checksThisMonth:"There are multiple monthly inventory checks this month!"
      }
    },
    // 物料平衡明细
    itemBalanceDtl: {
      field: {
        index: "index",
        itemBalanceCode: 'itemBalanceCode',
        groupCode: 'groupCode',
        itemCode: 'itemCode',
        itemName: 'itemName',
        theMonthStatistics: 'theMonthStatistics',
        stockBefore: 'stockBefore',
        stockAfter: 'stockAfter',
        beginNum: 'beginNum(t)',
        inNum: 'inNum(t)',
        outNum: 'outNum(t)',
        produceNum: 'produceNum(t)',
        useNum: 'useNum(t)',
        stock: 'stock',
        stockNumInit: 'stockNumInit',
        stockNum: 'stockNum',
        checkNum: 'checkNum',
        balanceNum: 'balanceNum',
        balanceRate: 'balanceRate(%)',
        paramControl: 'paramControl',
        paramRate: 'paramRate(%)',
        paramRateOnoff: 'paramRateOnoff',
        paramLock: 'paramLock',

        stockBookNum: 'stockBookNum',
        produce: 'produce',
        use: 'use',
        in: 'in',
        out: 'out',

        yield: 'yield',
        consume: 'consume',

        adjust: 'adjust',
        adjusted: 'adjusted',
        total: 'total',
      },
      txt: {
        lock: "lock",
        unlock: "unlock",
        open: "open",
        close: "close",
        type: 'type',
        breed: 'breed',
        yield: 'yield',
        totalRatio: 'totalRatio',
        dryBasisRatio: 'dryBasisRatio',
        dryBasisRatioConsumption: 'dryBasisRatioConsumption',
        wetBasisRatioConsumption: 'wetBasisRatioConsumption',
        total: 'total',
        itemWater: 'itemWater',
      },
      btn: {
        materialRatioQuery: 'materialRatioQuery',
      },
      title: {
        title: 'Material balance details',
        itemBalance: 'material ratio',
      },
    }
  },
}
