import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getList = (current,size,params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjentryexitpartner/page',
    method: 'get',
    params:{
      current,
      size,
      ...params
    }
  })
}
export const detail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjentryexitpartner/detail',
    method: 'get',
    params
  })
}
export const update = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjentryexitpartner/actFinish',
    method: 'post',
    data
  })
}
export const submit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjentryexitpartner/submit',
    method: 'post',
    data
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/prjentryexitpartner/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
// 项目相关方人员
export const getPartnerList = (current, size, params) => {
  return request({
    url: '/api/sinoma-hse-prj/thirdcompany/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

// 项目相关方组织---可根据相关方人、项目来查
export const getOrgListByPersonProject = (current, size, params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjthirdcompany/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

// 项目相关人员---可根据相关方组织、项目来查
export const getPersonListByOrgProject = (current, size, params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjthirduser/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

