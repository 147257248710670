import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 赋码管理
export const basecodePage = (current, size, params) => {
    return request({
      url: `${ServerNameEnum.SERVER_SYSTEM_}/basecode/page`,
      method: 'get',
      params: {
        current, 
        size, 
        ...params
      }
    })
}

export const basecodeSubmit = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_SYSTEM_}/basecode/submit`,
      method: 'post',
      data: params
    })
}

export const basecodeRemove = (ids) => {
    return request({
      url: `${ServerNameEnum.SERVER_SYSTEM_}/basecode/remove`,
      method: 'post',
      params: {ids}
    })
}

export const basecodeDetail = (params) => {
    return request({
      url: `${ServerNameEnum.SERVER_SYSTEM_}/basecode/detail`,
      method: 'post',
      params
    })
}

// 导出word
export const basecodeExportDoc = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/basecode/downDoc`,
    method: 'post',
    responseType: 'blob',
    params: {ids}
  })
}