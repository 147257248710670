export default {

  cps: {
    title: {
      device: '设备管理',
      deviceAdd: '设备管理新增',
      deviceView: '设备管理查看',
      deviceEdit: '设备管理编辑',
      model: '模型管理',
      modelAdd: '模型管理新增',
      modelView: '模型管理查看',
      modelEdit: '模型管理编辑',
      protocol: '协议管理',
      protocolAdd: '协议管理新增',
      protocolView: '协议管理查看',
      protocolEdit: '协议管理编辑',
      monitoring: '数采监控',
      monitoringEdit: '数采监控编辑',
    },
    msg: {
      removeSpaces: "请删除前后空格",
      tipDetermineRemove: "确定将选择数据移除?",
      importFormat: "支持.xlsx格式文件导入",
      importLoading: "正在导入数据，请稍等...",
      saveHead: "请先保存头信息",
    },
    field: {
      createTime: "创建时间",
      remark: "备注",
      serial: "序号",
    },
    btn: {
      importData: "导入数据",
      exportModel: "导出模型",
      exportMonitoring: "导出终端",
      exportDevice: "导出设备",
      exportPoint: "导出测点",
      improtDevice: "导入设备数据",
      improtPoint: "导入测点数据",
      exportDownload: "导出设备模板",
      download: "模板下载",
      exportDeviceTemplate: "导出设备模板",
    },
    device: {
      deviceProperty: {
        devicePropertyCode: "属性编码",
        devicePropertyName: "属性名称",
        dataType: "数据类型",
        variableType: "变量类型",
        standardPointCode: "标准测点编码",
        rangeMax: "范围上限",
        rangeMin: "范围下限",
        dcsCode: "DCS信息编码",
        isEnable: "是否启用",
        isPush: "是否推送",
      },
      deviceEvent: {
        deviceEventCode: "事件编码",
        deviceEventName: "事件名称",
        dataType: "数据类型",
        variableType: "变量类型",
        standardPointCode: "标准测点编码",
        deviceEventType: "事件类型",
        dcsCode: "DCS信息编码",
        isEnable: "是否启用",
        gaugingType: "测点类型",
        isPush: "是否推送",
      },
      deviceService: {
        deviceServiceCode: "服务编码",
        deviceServiceName: "服务名称",
        dataType: "数据类型",
        variableType: "变量类型",
        standardPointCode: "标准测点编码",
        rangeMax: "范围上限",
        rangeMin: "范围下限",
        dcsCode: "DCS信息编码",
        isEnable: "是否启用",
        isPush: "是否推送",
      },
      deviceCalc: {
        selectInputParam: "请选择输入参数",
        deviceCalcCode: "计算编码",
        deviceCalcName: "计算名称",
        resultDataType: "结果数据类型",
        calcMethod: "计算函数",
        inputParam: "输入参数",
        isSave: "是否保存",
        isPush: "是否推送",
        isEnable: "是否启用",
      },
      title: {
        netType: "逻辑位置分类",
        locationType: "物理位置分类",
      },
      msg: {
        chinese: "请勿输入汉字或其他非法字符",
        exportData: "确定导出设备数据？",
        modelUpdate: "确定根据关联的模型重新生成设备明细信息？旧明细信息将被覆盖",
        selectprotocolParams: "请选择协议配置",
        checkModel: "请先选择模型",
      },
      field: {
        netType: {
          netCode: "逻辑位置编码",
          netName: "逻辑位置名称",
          orderNum: "排序",
          isEnable: "是否启用",
          heartbeatTopic: "采集周期"
        },
        locationType: {
          parentName: "上级分类名称",
          locationCode: "物理位置编码",
          locationName: "物理位置名称",
          orderNum: "排序",
          isEnable: "是否启用",
          ten: "10位以内的数字",
        },
        netTitle: "逻辑位置分类",
        locTitle: "物理位置分类",
        deviceData: "【数采平台】设备数采数据",
        deviceTemplate: "【数采平台】设备数采导入模板",
        autoValue: "默认自动生成",
        list: "设备",
        CSV: "链接到CSV图表",
        deviceCode: "设备编码",
        deviceName: "设备名称",
        productCode: "产品编码",
        productName: "产品名称",
        modelCode: "模型编码",
        modelName: "模型名称",
        subModelCode: "子模型编码",
        subModelName: "子模型名称",
        selectModel: "选择模型",
        deviceType: "设备类型",
        deviceKey: "设备key",
        protocolCode: "协议编码",
        protocolName: "协议名称",
        locationName: "物理位置名称",
        netName: "逻辑位置名称",
        deviceTags: "标签",
        topicUrl: "消息主题",
        protocolParams: "协议配置",
        isEnable: "是否启用",
        property: "属性",
        event: "事件",
        service: "服务",
        calc: "流计算",
        select: "设备",
        normal: "测点正常数量",
        abnormal: "测点异常数量",
        enableDeviceCount: "设备正常数量",
        disableDeviceCount: "设备异常数量",
        dragin: "默认自动带入",
        updateModel: "更新模型",
        mainDeviceCat: "主设备分类",
        mainDevice: "主设备管理",
        mainDeviceAdd: "主设备新增",
        deviceCatConf: "设备分类关系配置",
        subDeviceCat: "子设备分类",
        subDevice: "子设备管理",
        mainDeviceCatAdd: "主设备分类新增",
        subDeviceCatAdd: "子设备分类新增",
        subDeviceAdd: "子设备新增",
        mainDeviceCatCode: "主设备分类编码",
        mainDeviceCatName: "主设备分类名称",
        optMode: "运行形态",
        isSpecial: "是否特种设备",
        isEnvPort: "是否环保设备",
        powerType: "动力类型",
        portAtt: "生产属性",
        importLevel: "重要级别",
        subDeviceCatCode: "子设备分类编码",
        subDeviceCatName: "子设备分类名称",
        adminDept: "管辖部门",
        connectLocation: "连接位置",
        mainAndSubRelation: "请选择子设备",
        mainDeviceName: "主设备名称",
        mainDeviceCode: "主设备编码",
        processName: "工序名称",
        subDeviceCode: "子设备编码",
        subDeviceName: "子设备名称",
        sparepartsCode: "备件编码",
        part: "备件清单",
        addPart: "新增备件清单",
        sparepartsName: "备件名称",
        remark: "备注",
        specs: "规格",
        value: "值",
        unit: "单元",
        specsMan: "规格管理"
      }
    },
    model: {
      modelType: {
        title: "模型分类",
        parentCode: "上级分类编码",
        parentName: "上级分类名称",
        modelTypeCode: "模型类型编码",
        modelTypeName: "模型类型名称",
        orderNum: "排序",
        isEnable: "是否启用",
        ten: "10位以内的数字",
      },
      modelProperty: {
        modelPropertyCode: "属性编码",
        modelPropertyName: "属性名称",
        dataType: "数据类型",
        variableType: "变量类型",
        standardPointCode: "标准测点编码",
        rangeMax: "范围上限",
        rangeMin: "范围下限",
        isEnable: "是否启用",
        isPush: "是否推送",
      },
      modelEvent: {
        modelEventCode: "事件编码",
        modelEventName: "事件名称",
        dataType: "数据类型",
        variableType: "变量类型",
        standardPointCode: "标准测点编码",
        modelEventType: "事件类型",
        isEnable: "是否启用",
        isPush: "是否推送",
      },
      modelService: {
        modelServiceCode: "服务编码",
        modelServiceName: "服务名称",
        dataType: "数据类型",
        variableType: "变量类型",
        standardPointCode: "标准测点编码",
        rangeMax: "范围上限",
        rangeMin: "范围下限",
        isEnable: "是否启用",
        isPush: "是否推送",
      },
      modelCalc: {
        selectInputParam: "请选择输入参数",
        modelCalcCode: "计算编码",
        modelCalcName: "计算名称",
        resultDataType: "结果数据类型",
        calcMethod: "计算函数",
        inputParam: "输入参数",
        isSave: "是否保存",
        isPush: "是否推送",
        isEnable: "是否启用",
      },
      msg: {
        exportData: "确定导出模型数据？",
        select: "请选择模型",
        picture: "只能上传jpg/png模型图片，且不超过500kb",
      },
      title: {
        title: "模型",
        model: "模型分类",
      },
      field: {
        modelData: "【数采平台】设备模型数据",
        modelTemplate: "【数采平台】设备模型导入模板",
        modelCode: "模型编码",
        modelName: "模型名称",
        subModelCode: "子模型编码",
        subModelName: "子模型名称",
        isEnable: "是否启用",
        version: "当前版本",
        list: "模型管理",
        property: "属性",
        event: "事件",
        service: "服务",
        calc: "流计算",
        devicePicture: "模型图片",
        modelClassify: "模型分类"
      },
    },
    protocol: {
      title: {
        title: "协议管理",
      },
      msg: {
        select: "请选择协议",
      },
      field: {
        protocolCode: "协议编码",
        protocolName: "协议名称",
        protocolType: "协议类型",
        protocolParams: "协议配置参数",
        isEnable: "是否启用",
        heartbeatTopic: "采集周期"
      }
    },
    monitoring: {
      title: {
        title: "终端",
      },
      msg: {
        currenttime: "结束时间不能比当前时间大",
        exceed: "时间范围不允许超出一天",
        exceedMonth: "时间范围不允许超出一个月",
        template: "【数采平台】设备数采导出模板",
        exportMonitoring: "【数采平台】设备数采数据",
        leastOne: "请至少选择一条数据",
      },
      field: {
        second: "秒",
        terminalCoding: "终端编码",
        terminalName: "终端名称",
        agreementName: "协议名称",
        isEnable: "是否启用",
        createTime: "创建时间：",
        isStatus: "当前状态",
        heartbeatTopic: "采集周期",
        select: "请选择协议管理",
        connect: "已连接",
        break: "已断开",
        protocolParams: "协议配置参数",
        equipment: "采集正常设备数量",
        cardPattern: "卡片模式",
        listPattern: "列表模式",
        getAI: "AI",
        getDI: "DI",
        getAO: "AO",
        getDO: "DO",
        getST: "ST",
        standardPointCode: "DCS信息编码：",
        eventualCreateTime: "最后采集时间：",
        ipLocation: "IP",
        clsid: "CLSID",
        clientId: "CLIENTID",
        deviceCode: "设备编号",
        gaugingType: "测点编号",
        remark: "备注",
        today: "今日",
        week: "本周",
        month: "本月",
        until: "至",
        beginTime: "开始日期",
        endTime: "结束日期",
        noData: "无数据",
        exportPoint: "历史数据",
        deviceCard: "设备",
        DeviceSummation: "测点",
        deviceName: "设备名称",
        deviceEventCode: "测点编码",
        stationName: "测点名称",
        stationCode: "测点编码",
        variableType: "变量类型",
        modelName: "模型名称",
        netName: "逻辑位置",
        locationName: "物理位置",
        enable: "已启用",
        notEnabled: "未启用",
        inputParam: "输入参数:",
        calcMethod: "计算函数:",
        protocolParamsSelect: "请选择协议配置参数",
      },
      btn: {
        history: "历史",
        chart: "实时",
        earlyWarning: "预警",
        remove: "移除测点",
        station: "选择测点",
        device: "选择设备",
        protocolParams: "协议配置",
      },
    },
    cache: {
      btn: {
        importData: "导入数据",
        exportModel: "导出模型",
        exportMonitoring: "导出终端",
        export: "导出缓存数据",
        exportPoint: "导出测点",
        improtDevice: "导入设备数据",
        improtPoint: "导入测点数据",
        exportDownload: "导出设备模板",
        download: "模板下载",
        exportDcsTemplate: "导出模板",
        cancel: "取消",
        screening: "筛选测点",
        select: "查询",
      },
      cachePoint: {
        tree: {
          title: "OPC服务列表",
          dialogOPC: "OPC服务",
          serverCode: "服务编码",
          ip: "ip",
          clsId: "clsId",
          progId: "progId",
          username: "账号",
          password: "密码",
          domain: "域",
          frequency: "采集频率",
          dbPath: "缓存文件存储路径",
          remark: "备注",
          hasChildrenWarning: "有子项未删除!",
        },
        btn: {
          issueBtn: "生成配置"
        },
        title: {
          title: "缓存配置",
          dialogTree: "选择测点",
        },
        msg: {
          select: "请选择协议",
          delivery: "请选择下发模式",
          frequency: "请选择频率",
          generateFile: "确定生成缓存配置文件？",
          addPoints: "确定将选择的点位加入到当前OPC服务？",
          treeCode: "请在左侧选择一条OPC服务"
        },
        field: {
          deviceCode: "设备编码",
          deviceName: "设备名称",
          pointCode: "测点编码",
          pointName: "测点名称",
          dataType: "数据类型",
          gaugingType: "测点类型",
          variableType: '变量类型',
          dcsCode: 'DCS信息编码',
          issueConfiguration: "配置下发",
          exportPointConfig: "【DCS缓存测点配置】测点数据导出",
          template: "【DCS缓存测点配置】测点模板导出",
          configFile: "缓存点位配置",
          delivery: "下发模式",
          all: "全部",
          portion: "部分",
          frequency: "频率",
          selectFrequency: "请选择频率",
          minute: "分钟"
        }
      },
      cacheData: {
        title: {
          title: "缓存查询",
          selectWarning: "请筛选需要查询的点位"
        },
        field: {
          time: "缓存时间",
          pointCode: "DCS编码",
          pointValue: "DCS值",
          exportCacheData: "【DCS测点缓存数据】导出",
          until: "至",
          beginTime: "开始日期",
          endTime: "结束日期",
        }
      }
    }
  },

}
