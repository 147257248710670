var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "numRange" },
    [
      _c("el-input-number", {
        attrs: { controls: false, placeholder: "最小值" },
        on: { change: _vm.onChange },
        model: {
          value: _vm.min,
          callback: function ($$v) {
            _vm.min = $$v
          },
          expression: "min",
        },
      }),
      _c("span", { staticClass: "separator" }, [_vm._v("-")]),
      _c("el-input-number", {
        attrs: { controls: false, placeholder: "最大值" },
        on: { change: _vm.onChange },
        model: {
          value: _vm.max,
          callback: function ($$v) {
            _vm.max = $$v
          },
          expression: "max",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }