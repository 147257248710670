var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "UploadFile-container" },
    [
      !_vm.detailed
        ? [
            _c(
              "el-upload",
              {
                staticClass: "upload-demo",
                attrs: {
                  action:
                    "api/sinoma-resource/oss/endpoint/put-file-attach-name-file",
                  headers: _vm.headers,
                  file: "",
                  limit: 3,
                  "show-file-list": false,
                  "on-success": _vm.handleAvatarSuccess,
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "small",
                      icon: "el-icon-upload",
                      disabled: _vm.disabled,
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.buttonText) + "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm.showTip
              ? _c("div", { staticClass: "el-upload__tip" }, [
                  _vm._v(
                    "\n        只能上传不超过" +
                      _vm._s(_vm.fileSize) +
                      _vm._s(_vm.sizeUnit) +
                      "的" +
                      _vm._s(_vm.acceptText) +
                      "文件\n      "
                  ),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.fileList.length
        ? [
            _c(
              "ul",
              {
                staticClass:
                  "el-upload-list el-upload-list el-upload-list--text",
              },
              _vm._l(_vm.fileList, function (file, index) {
                return _c(
                  "li",
                  {
                    key: file.fileId,
                    staticClass: "el-upload-list__item is-success",
                    class: { "el-upload-list__item_detail": _vm.detailed },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "el-upload-list__item-name",
                        style: { color: !_vm.showIcon ? "#409eff" : "" },
                      },
                      [
                        _vm.showIcon
                          ? _c("i", { staticClass: "el-icon-paperclip" })
                          : _vm._e(),
                        _vm._v(
                          "\n            " +
                            _vm._s(file.originalName) +
                            "\n          "
                        ),
                      ]
                    ),
                    _c("i", {
                      staticClass: "el-icon-download",
                      attrs: { title: "下载" },
                      on: {
                        click: function ($event) {
                          return _vm.handleClick(file)
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "el-upload-list__item-status-label",
                        class: { disabled: _vm.disabled },
                      },
                      [
                        _c("i", {
                          staticClass:
                            "el-icon-upload-success el-icon-circle-check",
                        }),
                      ]
                    ),
                    !_vm.disabled
                      ? _c("i", {
                          staticClass: "el-icon-close",
                          attrs: { title: "删除" },
                          on: {
                            click: function ($event) {
                              return _vm.handleRemove(index)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm._e(),
      _c(
        "fileUploader",
        _vm._b(
          { ref: "fileUploader", on: { fileSuccess: _vm.fileSuccess } },
          "fileUploader",
          _vm.$props,
          false
        )
      ),
      _c("Preview", {
        attrs: { visible: _vm.previewVisible, file: _vm.activeFile },
        on: {
          "update:visible": function ($event) {
            _vm.previewVisible = $event
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }