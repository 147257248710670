import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const classificationRoleGetList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/role/classificationRole/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/role/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/role/detail',
    method: 'get',
    params: {
      id
    }
  })
}
export const grantTree = () => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/menu/grant-tree',
    method: 'get',
  })
}

export const grant = (roleIds, menuIds, dataScopeIds, apiScopeIds) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/role/grant',
    method: 'post',
    data: {
      roleIds,
      menuIds,
      dataScopeIds,
      apiScopeIds
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/role/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/role/submit',
    method: 'post',
    data: row
  })
}
export const classificationRoleSubmit = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/role/classificationRole/submit',
    method: 'post',
    params: {
      ids,
    }
  })
}
export const classificationRoleRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/role/classificationRole/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/role/submit',
    method: 'post',
    data: row
  })
}


export const getRole = (roleIds) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/menu/role-tree-keys',
    method: 'get',
    params: {
      roleIds,
    }
  })
}

export const getRoleTree = (tenantId,userType) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/role/treeByUserType',
    method: 'get',
    params: {
      tenantId,
      userType
    }
  })
}

export const getRolesTree = (tenantId) => {
    return request({
        url: ServerNameEnum.SERVER_SYSTEM_ + '/role/tree',
        method: 'get',
        params: {
            tenantId,
        }
    })
}

export const getRoleTreeById = (roleId) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/role/tree-by-id',
    method: 'get',
    params: {
      roleId,
    }
  })
}

export const getRoleTreeDicData = (code) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dict-biz/dictionary?code=` + code,
    method: 'get'
  })
}

export const saveRoleTypeTree = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/roleTypeTree/saveOrUpdate`,
    method: 'post',
    data
  })
}

export const getRoleTypeTree = () => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/roleTypeTree/tree`,
    method: 'get',
  })
}

export const removeRoleTypeTree = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/roleTypeTree/remove`,
    method: 'post',
    params: {
      id
    }
  })
}

export const saveRoleType = (ids, id) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/roleTypeTree/saveRoleType`,
    method: 'post',
    params: {
      ids,
      id
    }
  })
}


export const roleTypeRemove = (ids, id) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/roleTypeTree/removeRole',
    method: 'post',
    params: {
      ids,
      id
    }
  })
}

export const getAllByTypeId = (id) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/roleTypeTree/getAll',
    method: 'post',
    params: {
      id
    }
  })
}
