import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";

export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/processTest/page",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

export const getPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/processTest/page",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/processTest/detail",
    method: "get",
    params: {
      id,
    },
  });
};

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/processTest/remove",
    method: "post",
    params: {
      ids,
    },
  });
};

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/processTest/submit",
    method: "post",
    data: row,
  });
};

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/processTest/submit",
    method: "post",
    data: row,
  });
};
