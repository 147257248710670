import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';


export const getTrainingRducationSettingTreeTree = () => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingRducationSettingTree/tree`,
    method: 'get',
  })
}

export const submitTrainingRducationSetting = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingRducationSettingTree/submit`,
    method: 'post',
    data
  })
}

export const removeTrainingRducationSetting = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingRducationSettingTree/remove`,
    method: 'post',
    params: {
      id
    }
  })
}
export const trainingEducationSetHeadSubmit = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingEducationSetHead/submit`,
    method: 'post',
    data
  })
}
export const trainingEducationSetLineSubmit = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingEducationSetLine/submit`,
    method: 'post',
    data
  })
}
export const getTrainingEducationSetHeadePage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_+'/trainingEducationSetHead/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
// 应急预案基本信息详情
export const trainingEducationSetHeadDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ + '/trainingEducationSetHead/detail',
    method: 'post',
    params: {
      id
    }
  })
}
export const trainingEducationSetLineRetentionTime = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingEducationSetLine/retentionTime`,
    method: 'post',
    data
  })
}
export const trainingEducationSetDetilRemoveLine = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingEducationSetDetil/remove`,
    method: 'post',
    params: {
      ids,
    }
  })
}
export const gettrainingEducationSetDetilList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingEducationSetDetil/page`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const trainingEducationSetHeadEnableInvalidation = (id,status) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ + '/trainingEducationSetHead/enableInvalidation',
    method: 'post',
    params: {
      id,
      status
    }
  })
}
export const trainingEducationSetHeadRemoveLine = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingEducationSetHead/remove`,
    method: 'post',
    params: {
      ids,
    }
  })
}
// 应急预案基本信息删除
export const trainingEducationSetLineRemove = (trainId,trainContentId) => {
  return request({
    url: ServerNameEnum.SERVER_TRAINING_ + '/trainingEducationSetLine/remove',
    method: 'post',
    params: {
      trainId,
      trainContentId
    }
  })
}
// 培训信息详情
export const trainingTaskDetail = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_TRAINING_+'/trainingTask/detail',
    method:'get',
    params
  })
}

