import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 作业票模板
export function ticketTemplateList(current, size, params) {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTemplate/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const ticketTemplateRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTemplate/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
export const ticketTemplateSave = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTemplate/saveOrUpdate',
    method: 'post',
    data
  })
}

export const ticketTemplateDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTemplate/detail',
    method: 'get',
    params: {
      id
    }
  })
}
export const templateCode = () => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTemplate/getCode',
    method: 'get',
  })
}
export const findNextProcessAssignee = (data) => {
  return request({
    url: ServerNameEnum.SERVER_WORK_FLOW_ + '/process/findNextProcessAssignee',
    method: 'get',
    params: data
  })
}

/**
 * 可发起流程列表
 */
export const processList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_WORK_FLOW_ + '/process/processList',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const getVersion = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTemplate/getVersion',
    method: 'get',
    params: {
      ...params,
    }
  })
}
export const enable = (id) => {
  return request({
    url: ServerNameEnum.SERVER_FX + `/wtTemplate/enable?id=${id}`,
    method: 'get'
  })
}
export const updateEnable = (id) => {
  return request({
    url: ServerNameEnum.SERVER_FX + `/wtTemplate/updateEnable?id=${id}`,
    method: 'get'
  })
}
export const disEnableTemplate = (id) => {
  return request({
    url: ServerNameEnum.SERVER_FX + `/wtTemplate/disEnable?id=${id}`,
    method: 'get'
  })
}

//作业票清单
export function ticketInventoryList(current, size, params) {
  return request({
    url: ServerNameEnum.SERVER_FX + '/workTicket/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const removeWorkTicket = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/workTicketTask/removeWorkTicket',
    method: 'post',
    params: {
      ids,
    }
  })
}
export const removeTask = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/workTicketTask/removeTask',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const ticketInventorySave = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/workTicket/saveOrUpdate',
    method: 'post',
    data
  })
}

export const ticketInventoryDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/workTicket/detail',
    method: 'get',
    params: {
      id
    }
  })
}
export const ticketInventoryType = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/workTicket/selectType',
    method: 'post',
    data
  })
}
export const ticketInventorySign = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/workTicketTask/forwardAndSign',
    method: 'post',
    data
  })
}

export const ticketInventoryMyPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/workTicket/getMyPage',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const myWtTicketPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTicket/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const currentlyOrganized = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTicket/currentlyOrganized',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const myWtTicketDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTicket/detail?id=' + id,
    method: 'get',
  })
}
//myWtTicketDetail
export const getWtTicketDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTicket/getWtTicketDetail?id=' + id,
    method: 'get',
  })
}


export const myWtTicketSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTicket/saveOrUpdate',
    method: 'post',
    data
  })
}
export const notSubmittedForSignature = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTicket/notSubmittedForSignature?ticketCode='+data,
    method: 'post',
    data
  })
}

export const notSubmittedForSignatureSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTicket/notSubmittedForSignatureSubmit?ticketCode='+data,
    method: 'post',
    data
  })
}

//作业票类型字段
export const ticketType = (key) => {
  return request({
    url: `/api/sinoma-system/dict-biz/dictionary?code=${key}`,
    method: 'get',
  })
}
export const ticketInventoryTask = (workTicketId) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/workTicketTask/getTask',
    method: 'get',
    params: {
      workTicketId
    }
  })
}

export const ticketInventorySubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/workTicketTask/submit',
    method: 'post',
    data
  })
}
export const ticketInventoryTaskDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/workTicketTask/detail',
    method: 'get',
    params: {
      id
    }
  })
}
export const ticketSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/workTicket/submit',
    method: 'post',
    data
  })
}

//作业票控件

export const ticketControlSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/workTicketControl/submit',
    method: 'post',
    data
  })
}

export const ticketControlDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/workTicketControl/detail',
    method: 'get',
    params: {
      id
    }
  })
}
export const ticketControlPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/workTicketControl/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const lazyTree = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/workTicketControl/lazyTree',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}


export const removeTicketControl = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/workTicketControl/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const workTicketControlTree = (category) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/workTicketControl/tree',
    method: 'get',
    params: {
      category,
    }
  })
}

// 作业票风险辨识结果
export const wtRiskAll = (type) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtRisk/all',
    method: 'get',
    params: {
      type,
    }
  })
}

// 作业票动火方式
export const wtDhTypeAll = () => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtDhType/all',
    method: 'get'
  })
}

// 作业票安全措施
export const wtSecurityPreventionAll = (type) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtSecurityPrevention/all',
    method: 'get',
    params: {
      type,
    }
  })
}

// 动火作业票保存
export const wtBaseDhSave = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseDh/saveOrUpdate',
    method: 'post',
    data
  })
}
// 动火作业票转发
export const retransmission = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseDh/retransmission',
    method: 'post',
    data
  })
}
// 盲板抽堵作业票转发
export const wtBaseBppRetransmission = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseBpp/retransmission',
    method: 'post',
    data
  })
}
// 动土作业票基本信息表转发
export const wtBaseDirtRetransmission = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseDirt/retransmission',
    method: 'post',
    data
  })
}
// 断路作业票转发
export const retransmissionOc = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseOc/retransmission',
    method: 'post',
    data
  })
}
// 断路作业票转发的提交
export const retransmissionSubmitOc = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseOc/retransmission',
    method: 'post',
    data
  })
}
// 高处作业票转发
export const wtBaseHeightRetransmission = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseHeight/retransmission',
    method: 'post',
    data
  })
}
// 高处作业票转发的提交
export const wtBaseHeightRetransmissionSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseHeight/retransmissionSubmit',
    method: 'post',
    data
  })
}
// 临时用电作业票转发
export const wtBaseElectricRetransmission = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseElectric/retransmission',
    method: 'post',
    data
  })
}
// 临时用电作业票转发的提交
export const wtBaseElectricRetransmissionSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseElectric/retransmissionSubmit',
    method: 'post',
    data
  })
}

// 临时用电作业票保存
export const wtBaseElectricSave = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseElectric/saveOrUpdate',
    method: 'post',
    data
  })
}
// 高处安全作业票保存
export const wtBaseHeightSave = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseHeight/saveOrUpdate',
    method: 'post',
    data
  })
}
// 盲板抽堵作业报错
export const wtBaseBppSave = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseBpp/saveOrUpdate',
    method: 'post',
    data
  })
}
// 动土作业票基本信息表
export const wtBaseDirtSave = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseDirt/saveOrUpdate',
    method: 'post',
    data
  })
}
// 动火作业票气体取样分析表 删除
export const removeWtDhGas = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtDhGas/remove?ids=' + data,
    method: 'post',
  })
}
// 临时用电作业票可燃气体分析表 删除
export const removeWtElectricGas = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtElectricGas/remove?ids=' + data,
    method: 'post',
  })
}
// 动火作业票气体取样分析表 删除
export const removeWtDisclosure = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtDisclosure/remove?ids=' + data,
    method: 'post',
  })
}
// 作业票安全措施 删除
export const removeWtUser = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtUser/remove?ids=' + data,
    method: 'post',
  })
}
// 作业票安全措施 删除
export const removeWtSecurityPrevention = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtSecurityPrevention/remove?ids=' + data,
    method: 'post',
  })
}
// 作业票表 删除
export const wtTicketRemove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTicket/remove?ids=' + data,
    method: 'post',
  })
}
// 爆破作业票保存
export const wtBaseExplodeSave = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseExplode/saveOrUpdate',
    method: 'post',
    data
  })
}

// 爆破作业票转发
export const explodeRetransmission = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseExplode/retransmission',
    method: 'post',
    data
  })
}

// 爆破作业票转发
export const otherRetransmission = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseOther/retransmission',
    method: 'post',
    data
  })
}

// 其他作业票保存
export const wtBaseOtherSave = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseOther/saveOrUpdate',
    method: 'post',
    data
  })
}
// 吊装安全作业转发
export const wtBaseHoistRetransmission = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseHoist/retransmission',
    method: 'post',
    data
  })
}


// 动火作业票转发的提交
export const wtBaseHoistRetransmissionSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseHoist/retransmissionSubmit',
    method: 'post',
    data
  })
}

// 断路作业票保存
export const wtBaseOcSave = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseOc/saveOrUpdate',
    method: 'post',
    data
  })
}

// 吊装安全作业票保存
export const wtBaseHoist = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtBaseHoist/saveOrUpdate',
    method: 'post',
    data
  })
}

// 删除安全监管人
export const safetySupervisionRemove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTicket/safetySupervisionRemove?ids=' + data,
    method: 'post'
  })
}
// 删除安全交底人
export const securityDisclosureRemove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTicket/securityDisclosureRemove?ids=' + data,
    method: 'post'
  })
}
// 删除照片
export const deleteImg = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTicket/deleteImg?ticketCode=' + data,
    method: 'get'
  })
}

// 作业票提交
export const submitWtTicket = (ticketCode) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTicket/submitWtTicket?ticketCode=' + ticketCode,
    method: 'post'
  })
}

// 安全交底提交
export const submitSecurityDisclosure = (ticketCode) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTicket/submitSecurityDisclosure?ticketCode=' + ticketCode,
    method: 'post'
  })
}

// 安全监管提交
export const submitSafetySupervision = (ticketCode) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTicket/submitSafetySupervision?ticketCode=' + ticketCode,
    method: 'post'
  })
}

// 验收确认提交
export const submitAcceptanceConfirmation = (ticketCode) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTicket/submitAcceptanceConfirmation?ticketCode=' + ticketCode,
    method: 'post'
  })
}
// 作业票线下保存
export const supervisionCompletionSaveOrUpdate = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/supervisionCompletion/saveOrUpdate',
    method: 'post',
    data
  })
}

export const copy = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtTicket/copy',
    method: 'post',
    data
  })
}
