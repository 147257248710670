import {httpSecurity} from "@/router/axios";

/**
 * @des 作业风险库
 */

/* 作业类型 */
// 不分页
export const jobtypeList = (params) => { return httpSecurity({ url: "/jobtype/list", method: "get", params, }); };
// 新增
export const jobtypeAdd = (params) => { return httpSecurity({ url: "/jobtype/save", method: "post", data: params, }); };
// 删除
export const jobtypeDel = (params) => { return httpSecurity({ url: "/jobtype/remove", method: "post", params, }); };
// 模板
export const jobtypeTemplate = () => { return '/api/ivystar-wisdom-security/jobtype/import-template' }
// 导入
export const jobtypeImport = () => { return '/api/ivystar-wisdom-security/jobtype/import' }


/* 作业风险库列表--风险措施 */
// 分页
export const jobmeasurePage = (params) => { return httpSecurity({ url: "/jobmeasure/page", method: "get", params, }); };
// 不分页
export const jobmeasureList = (params) => { return httpSecurity({ url: "/jobmeasure/list", method: "get", params, }); };
// 新增
export const jobmeasureAdd = (params) => { return httpSecurity({ url: "/jobmeasure/save", method: "post", data: params, }); };
// 编辑
export const jobmeasureEdit = (params) => { return httpSecurity({ url: "/jobmeasure/update", method: "post", data: params, }); };
// 详情
export const jobmeasureDetail = (params) => { return httpSecurity({ url: "/jobmeasure/detail", method: "get", params, }); };
// 删除
export const jobmeasureDel = (params) => { return httpSecurity({ url: "/jobmeasure/remove", method: "post", params, }); };

