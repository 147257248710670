export default {

  md: {
    cmn: {
      field: {
        name: "名称",
        code: "编号",
        remark: "备注",
        driver: "司机",
        manage: "管理",
      }
    },
    //水分系数
    waterRate: {
      msg: {
        month: "请选择月份",
      },
      field: {
        enable: "启用",
        disable: "停用",
        lastYear: "前一年",
        nextYear: "后一年",
        status: "状态",
        versionCode: "版本号",
        jan: "1月",
        feb: "2月",
        mar: "3月",
        apr: "4月",
        may: "5月",
        jun: "6月",
        jul: "7月",
        aug: "8月",
        sep: "9月",
        oct: "10月",
        nov: "11月",
        dec: "12月",
        waterRateValRef: "水份系数(%)采集参考",
        rateVal: "水份系数(%)修正值",
        remark: "备注",
        moistureCoefficient: "水分系数",
        time: "时间",
        qualityType: "质检类型",
        itemCode: "物料编码",
        itemName: "物料名称",
        coefficient: "水份系数(%)",
        num: "质检次数",
        totalWeight: "批次总重量",
        weight: "批次重量",
      },
      title: {
        moistureCoefficientTitle: "水份系数",
        chooseTitle: "请选择版本",
        detailtitle: "水份系数明细",
        productionMaterialTitle: "生产物料",
        automaticCollectionTitle: "自动采集",
        qualityInspectionDetailTitle: "质检明细",
      },
      btn: {
        quoteBtn: "确认引用",
      },
    },
    //工序管理
    process: {
      field: {
        processCode: "工序编码",
        processName: "工序名称",
        shortName: "简称",
        parentId: "上级ID",
        remark: "备注",
        isEnable: "是否启用",
        sort: "工序排序",
      },
      title: {
        processManagementTitle: "工序管理",
        processAddTitle: "工序新增",
        processEditTitle: "工序编辑",
      },
    },
    //区域维护
    monitor: {
      field: {
        areaCode: "区域编码",
        areaName: "区域名称",
        parentArea: "上级区域",
        isMonitor: "是否监控区域",
        isEnable: "是否启用",
        parentId: "上级ID",
        parentCode: "上级编码",
        areaType: "区域类型",
        processName: "工序名称",
      },
      title: {
        regionalMaintenanceTitle: "区域维护",
        regionalMaintenanceAddTitle: "区域维护新增",
        regionalMaintenanceEditTitle: "区域维护编辑",
      },
    },
    //点位配置
    monitoring: {
      //摄像头信息
      camera: {
        field: {
          camreaCode: "摄像头编码",
          camreaName: "摄像头名称",
          funct: "部署功能",
          url: "访问地址",
          remark: "备注",
        },
        title: {
          cameraTitle: "摄像头信息",
          cameraAddTitle: "摄像头信息新增",
          cameraEditTitle: "摄像头信息编辑",
        },
        msg: {
          // 以下三条为后端路径
          deleteArea: "请先删除数据对应的区域监控点位数据",
          deleteDevice: "请先删除数据对应的设备监控点位数据",
          deleteVideo: "请先删除数据对应的大屏视屏配置数据",
        },
      },
      //区域监控点位
      monitoring: {
        field: {
          areaName: "区域名称",
          areaCode: "区域编码",
          processCode: "工序编码",
          processName: "工序名称",
          camreaCode: "摄像头编码",
          camreaName: "摄像头名称",
          funct: "部署功能",
          url: "访问地址",
        },
        title: {
          RMPTitle: "区域监控点位",
          RMPAddTitle: "区域监控点位新增",
          RMPEditTitle: "区域监控点位编辑",
          treeTitle: "区域分类",
        },
        msg: {
          camreaName: "选择摄像头名称后自动带出",
        },
      },
      //设备监控点位
      deviceMonitoring: {
        field: {
          mainCode: "主设备编码",
          mainName: "主设备名称",
          camreaCode: "摄像头编码",
          camreaName: "摄像头名称",
          funct: "部署功能",
          url: "访问地址",
          mainDeviceCode: "主设备分类编码",
          mainDeviceName: "主设备分类名称",
        },
        title: {
          EMPTitle: "设备监控点位",
          EMPAddTitle: "设备监控点位新增",
          EMPEditTitle: "设备监控点位编辑",
          treeTitle: "工序分类",
        },
        msg: {
          camreaName: "选择摄像头名称后自动带出",
        },
      },
      //大屏视频配置
      video: {
        field: {
          lsCode: "大屏展示编码",
          cameraCode: "摄像头编码",
          cameraName: "摄像头名称",
          funct: "部署功能",
          areaName: "区域名称",
          url: "访问地址",
          remark: "备注",
        },
        title: {
          videoTitle: "大屏视频配置",
          videoAddTitle: "大屏视频配置新增",
          videoEditTitle: "大屏视频配置编辑",
          selectCameraTitle: "选择摄像头",
        },
        msg: {
          code: "该大屏展示编码已经存在！",
        },
      },
    },
    //设备管理
    device: {
      field: {
        mainDeviceCatCode: "主设备分类编码",
        mainDeviceCatName: "主设备分类名称",
        importLevel: "重要级别",
        sort: "序号",
        optMode: "运行形态",
        isSpecial: "是否特种设备",
        isEnvPort: "是否环保设备",
        powerType: "动力类型",
        portAtt: "生产属性",
        remark: "备注",
        subDeviceCatCode: "子设备分类编码",
        subDeviceCatName: "子设备分类名称",
        adminDept: "管辖部门",
        connectLocation: "连接位置",
        mainDeviceCode: "主设备编码",
        mainDeviceName: "主设备名称",
        processName: "工序名称",
        subDeviceCode: "子设备编码",
        subDeviceName: "子设备名称",
        sparepartsCode: "备件编码",
        sparepartsName: "备件名称",
        specs: "规格",
        value: "值",
        unit: "单元",
      },
      title: {
        mainDeviceCateTitle: "主设备分类",
        mainDeviceCateAddTitle: "主设备分类新增",
        mainDeviceCateEditTitle: "主设备分类编辑",
        subDeviceCateTitle: "子设备分类",
        subDeviceCateAddTitle: "子设备分类新增",
        subDeviceCateEditTitle: "子设备分类编辑",
        mainAndSubDeviceRelationTitle: "设备分类关系配置",
        mainAndSubRelationTitle: "选择子设备",
        mainDeviceTitle: "主设备管理",
        processCateTitle: "工序分类",
        mainDeviceAddTitle: "主设备新增",
        mainDeviceEditTitle: "主设备编辑",
        subDeviceTitle: "子设备管理",
        subDeviceAddTitle: "子设备新增",
        subDeviceEditTitle: "子设备编辑",
        sparePartTitle: "备件清单",
        sparePartAddTitle: "备件清单新增",
        sparePartEditTitle: "备件清单编辑",
      },
      msg: {
        selectMainDeviceCate: "请选择主设备分类",
        selectsubDeviceCate: "请选择子设备分类",
        selectProcessCate: "请选择工序分类",
        selectMainDevice: "请选择主设备",
      }
    },
    //机构管理
    mdpostLevel: {
      field: {
        postLevelCode: "职级编码",
        postLevelName: "职级名称",
        remark: "描述",
        sectCode: "管理编码",
        sectName: "管理工段",
        processName: "管理范围",
        subjectCode: "专业编码",
        subjectName: "专业名称",
        dutyCode: "职责编码",
        dutyName: "职责名称",
        subjectId: "管理专业编码",
        shiftTypeCode: "轮班方式编码",
        shiftTypeName: "轮班方式名称",
        shiftType: "轮班方式",
        blockTrain: "班制",
        dateList: "班次",
        morning: "早班",
        midday: "午班",
        evening: "晚班",
        startTime: "开始时间",
        endTime: "结束时间",
      },
      title: {
        rankTitle: "职级管理",
        rankAddTitle: "职级新增",
        rankEditTitle: "职级编辑",
        sectionTitle: "管理工段",
        sectionAddTitle: "管理工段新增",
        sectionEditTitle: "管理工段编辑",
        majorTitle: "管理专业",
        majorAddTitle: "管理专业新增",
        majorEditTitle: "管理专业编辑",
        responsibilityTitle: "职责管理",
        responsibilityAddTitle: "职责新增",
        responsibilityEditTitle: "职责编辑",
        shiftModeTitle: "轮班方式",
        shiftModeAddTitle: "轮班方式新增",
        shiftModeEditTitle: "轮班方式编辑",
      },
      msg: {
        selectWarning: "请先选择管理专业",
      }
    },
    //生产配方
    itemFormula: {
      field: {
        itemCode: "物料编码",
        itemName: "物料名称",
        versionCode: "版本号",
        isEnable: "是否启用",
        bomItemName: "物料名称",
        rateVal: "配比用量(%)",
        consume: "生产消耗(%)",
        remark: "备注",
      },
      title: {
        itemFormulaTitle: "生产配方",
        itemFormulaEditTitle: "生产配方编辑",
        itemFormulaViewTitle: "生产配方查看",
        selectTitle: "选择配方产品",
        recipeVersionTitle: "配方版本",
        recipeVersionAddTitle: "新增配方版本",
        recipeVersionEditTitle: "编辑配方版本",
        recipeVersionCopyTitle: "复制配方版本",
        formulaDetailTitle: "配方明细",
        selectMaterialTitle: "选择配方物料",
        queryTitle: "生产配比参考查询",
      },
      btn: {
        productFormulaBtn: "生产配方",
        productSearchBtn: "生产配比参考查询",
        selectItemBtn: "选择物料",
      },
      msg: {
        selectWarning: "请先选择配方版本!",
        rateVal: "配比用量需要和为100%",
        deleteRateVal: "删除该物料需要将配比用量分配给其他物料",
        copyFailed: "复制失败",
        itemCodeNotEmpty: "物料编码不能为空",
        recipeVersionNotEmpty: "配方版本不能为空",
        recipeVersionHas: "该生产配方版本已存在",
        recipeVersionHasEnable: "已经存在已启用的生产配方版本",
      }
    },
    //生产物料
    item: {
      field: {
        labelText: "标题",
        parentCode: "父类别",
        parentName: "上级分类名称",
        isEnable: "是否启用",
        itemCode: "物料编码",
        itemName: "物料名称",
        itemCatCode: "物料分类编码",
        itemCatName: "物料分类名称",
        upperLimit: "安全库存(上限)",
        lowerLimit: "安全库存(下限)",
        itemDensity: "物料密度",
        remark: "备注",
        processName: "工艺名称",
        code: "物料编码",
        name: "物料名称",
        unitName: "单位名称",
        isProduct: "是否产成品",
        itemCodeOrName: "物料编码/名称",
        serialNumber: "序号",
        bomItemName: "BOM物料名称",
        bomItemCode: "BOM物料编号",
        extItemCodeOrName: "ERP物料编码/名称",
        extItemCode: "ERP物料编码",
        extItemName: "ERP物料名称",
        extItemCatCode: "物料分类编码",
        extItemCatName: "物料分类名称",
        warehouseCodeAndName: "仓库编码/仓库名称",
        stockInOut: "出入库类型",
        warehouseCode: "仓库编码",
        warehouseName: "仓库名称",
        stockDataSource: "数据来源",
        stockDcs: "DCS测点",
        select: "选择",
        warehouseShapeCode: "仓库形状",
        storageDays:"封存天数",
        day: "天",
      },
      title: {
        productionMaterialTitle: "生产物料",
        productionMaterialAddTitle: "生产物料新增",
        productionMaterialEditTitle: "生产物料编辑",
        productionMaterialViewTitle: "生产物料查看",
        proMaterialClassTitle: "生产物料分类",
        materialMappingTitle: "物料映射",
        materialBOMTitle: "物料BOM",
        erpMaterialTitle: "ERP物料",
        selectMaterialTitle: "选择物料",
        selectERPMaterialsTitle: "选择ERP物料",
        materialViewTitle: "查看物料",
        materialEditTitle: "更新物料",
        materialAddTitle: "新增物料",
        materialWarehouseTitle: "物料仓库",
        itemWarehouseHeadTitle: "选择仓库",
      },
      msg: {
        exportMaterialUnit: "是否导出物料单位数据?",
        exportMaterialSubstitute: "是否导出物料替代品数据?",
        exportMaterialSupplier: "是否导出物料供应商数据?",
        exportMaterial: "是否导出物料数据?",
        notSupported: "不支持多选",
        notSelect: "该条数据不允许选择！",
        erpBindOneItem: "一个erp物料只能绑定一个生产物料",
        storageDays: "封存天数必须大于0",
        warn: "安全库存(下限)不能超过安全库存(上限)",
      }
    },
    //仓库管理
    warehouse: {
      field: {
        code: "仓库编号",
        name: "仓库名称",
        isEnable: "是否启用",
        itemCode: "物料编码",
        itemName: "物料名称",
        isEnableEarlyWarning: "开启预警",
        earlyWarningThreshold: "预警阈值",
        locationCode: "库位编码",
        locationName: "库位名称",
        formula: "计算公式",
        remark: "备注",
        defaultWarehouse: '一卡通默认仓库',
        formulaCode: "参数编码",
        formulaName: "参数名称",
        formulaValue: "默认值",
        describe: "描述",
        warnType: '预警类型',
        stationCode: '测点编码',
        warnRange: '预警范围',
      },
      title: {
        warehouseManagementTitle: "仓库管理",
        warehouseAddTitle: "仓库新增",
        warehouseEditTitle: "仓库编辑",
        warehouseViewTitle: "仓库查看",
        baseTitle: "基本信息",
        locationTitle: "库位管理",
        warehouseWarnTitle: '仓库预警',
        productionMaterialTitle: "生产物料",
        formulaTitle: "计算公式",
        formulaParameterListTitle: "公式参数列表",
      },
      btn: {
        formulaBtn: "计算公式",
      },
      msg: {
        selectWarning: "请先保存库位信息",
        addWarning: "请先保存基础信息",
        saveWarning: "请选择需要保存的库位信息",
        locationCodeBlank: "请输入库位编码",
        locationNameBlank: "请输入库位名称",
        codeSame: "编码已存在",
        formulaTextBlank: "请填写计算公式",
        formulaCodeBlank: "请完善公式参数编码信息",
        formulaNameBlank: "请完善公式参数名称信息",
        formulaValueBlank: "请完善公式参数默认值信息",
        formulaParam1Blank: "参数列表包含公式中未使用的参数",
        formulaParam2Blank: "公式中使用了参数列表中未添加的参数",
        onlyOneWarning: "只能存在一条库存量预警！",
        codeBlank: "请输入仓库编号",
        nameBlank: "请输入仓库名称",
        itemCodeBlank: "请输入物料编码",
        itemNameBlank: "请输入物料名称",
        defaultWarehouse: "请选择一卡通默认仓库",
        saveFailBecauseHave: "保存失败，当前仓库已经配置过物料",
      }
    },
    //物理仓库管理
    physicalWarehouse: {
      field: {
        code: "仓库编号",
        name: "仓库名称",
        type: "仓库类型",
        isEnable: "是否启用",
        createTime: "创建时间",
        location: "仓库位置",
        capacity: "仓库容积",
        formula: "库存计算公式",
        dcsPoint: "库存计量点位",
        remark: "备注",
        itemCode: "物料编码",
        itemName: "物料名称",
        formulaCode: "参数编码",
        formulaName: "参数名称",
        formulaValue: "默认值",
        describe: "描述",
      },
      title: {
        warehouseManagementTitle: "物理仓库管理",
        warehouseAddTitle: "物理仓库新增",
        warehouseEditTitle: "物理仓库编辑",
        warehouseViewTitle: "物理仓库查看",
        baseTitle: "基本信息",
        itemTitle: "可能存放的物料",
        itemDialogTitle: "生产物料",
      },
      btn: {
        formulaBtn: "计算公式",
      },
      msg: {
        selectWarning: "请先保存库位信息",
        formula: "请点击配置库存计算公式",
        code: "未填写则自动生成",
      }
    },
    //班组
    class: {
      field: {
        classCode: "班组编码",
        className: "班组名称",
        isEnable: "是否启用",
        classCatCode: "班组分类编码",
        classCatName: "班组分类名称",
        parentName: "父类名称",
        remark: "备注",
        userCode: "成员编码",
        userName: "成员名称",
        isLeader: "是否组长",
      },
      title: {
        classTitle: "班组",
        classAddTitle: "班组新增",
        classEditTitle: "班组编辑",
        classViewTitle: "班组查看",
        teamClassificationTitle: "班组分类",
        baseTitle: "基本信息",
        classMemberTitle: "班组成员",
        selectMemberTitle: "选择成员",
      },
      msg: {
        firstSaveData: "请先保存班组成员中的数据",
        onlyOneLeader: "一个班组只能有一个组长",
        memberIsEmpty: "班组成员不能为空",
        memberDuplicated: "班组成员不能重复",
      }
    },
    //班次
    classShift: {
      field: {
        classShiftCatCode: "班次分类编码",
        classShiftCatName: "班次分类名称",
        classShiftCode: "班次编码",
        classShiftName: "班次名称",
        startTime: "开始时间",
        endTime: "结束时间",
        classShiftDateType: "日期类别",
        remark: "备注",
      },
      title: {
        classShiftTitle: "班次",
        classShiftCatTitle: "班次分类",
        classShiftEditTitle: "班次编辑",
        classShiftViewTitle: "班次查看",
      },
      msg: {
        tipDetermineInit: "确认初始化数据?",
        classShiftCat: "请选择班次分类",
        classShiftCode: "班次编码自动生成",
      }
    },
    //排班规则
    classShiftRule: {
      field: {
        classShiftRuleCode: "排班规则编码",
        classShiftRuleName: "排班规则名称",
        classCatCode: "班组分类编码",
        classCatName: "班组分类名称",
        startDate: "开始日期",
        endDate: "结束日期",
        remark: "备注",
        classShiftRuleSort: "排班规则排序",
        className: "班组名称",
        classShiftName: "班次名称",
        classShiftType: "班次类型",
        shiftSchedulingDay: "排班天数",
      },
      title: {
        classShiftRuleTitle: "排班规则",
        classShiftRuleAddTitle: "排班规则新增",
        classShiftRuleEditTitle: "排班规则编辑",
        classShiftRuleViewTitle: "排班规则查看",
        baseTitle: "基本信息",
        classShiftRuleLineTitle: "排班规则行",
        calendarGenerationTitle: "日历生成",
      },
      msg: {
        lineIsEmpty: "该排班规则没有行信息",
        ruleTimeIsEmpty: "该排班规则时间为空",
        ruleTimeError: "该排班规则时间开始时间大于结束时间",
        firstSaveData: "请先保存排班规则行中的数据",
        only_one: "排班规则行中的班组名称重复！",
        tip_determine_gen: "确认生成数据?",
        ruleDay: "该排班天数小于排班规则天数",
        dateRepetition: "本次排班时间段与历史排班有重复日期",
        startDate: "开始时间不能为空",
        endDate: "结束时间不能为空",
      }
    },
    //排班日历
    classCalendar: {
      field: {
        calendarDate: "日历日期",
        classCatName: "班组分类名称",
        className: "班组名称",
        calendarEndDate: "日历结束日期",
        classShiftName: "班次名称",
        remark: "备注",
        userName: "成员名称",
        isLeader: "是否组长",
      },
      title: {
        classCalendarTitle: "排班日历",
        classCalendarViewTitle: "排班日历查看",
        baseTitle: "基本信息",
        classMemberTitle: "班组成员",
        classMemberAddTitle: "新增当班人员",
      },
    },
    //工艺配置
    processSetting: {
      field: {
        processCode: "工序编码",
        processName: "工序名称",
        equipCode: "设备编码",
        equipName: "设备名称",
        classCatName: "班组分类名称",
        isEnable: "是否启用",
        processOutitemFormulaType: "产出计算公式",
        remark: "备注",
        itemCode: "物料编码",
        itemName: "物料名称",
        warehouseName: "仓库名称",
        dcsType: "测点类型",
        dcsName: "测点名称",
        dcsCode: "测点编码",
        itemGroupCode: "物料组",
        weightRatio: "混称比例(%)",
        //selectDcsTable页面的测点订阅
        categoryIndexCode: "测点订阅编码",
        deviceCode: "设备编码",
        devicePropertyCode: "设备属性编码",
        devicePropertyName: "设备属性名称",
        categoryName: "测点订阅分类名称",
        categoryCode: "测点订阅分类编码",
      },
      title: {
        processSettingTitle: "工艺配置",
        processSettingAddTitle: "工艺配置新增",
        processSettingEditTitle: "工艺配置编辑",
        productionMaterialTitle: "生产物料",
        labelTitle: "测点订阅",
        lineTitle: "物料消耗",
        equipmentSettingTitle: "设备运行设置",
        headTitle: "ERP物料管理",
      },
      msg: {
        decCode: "同一种测点编码混成比例之和必须等于100",
        decName: "同一种测点名称必须一致",
        decNameNull: "请输入测点名称",
        number: "小数点后只能输入两位",
        warehouse_name_blank: "请选择仓库名称",
        dcsType_name_blank: "请选择测点类型",
        dcs_code_blank: "请选择测点编码",
        dataHaveExist: "此设备已存在配置",
      }
    },
    //项目管理
    project: {
      field: {
        projectCode: "项目编码",
        projectName: "项目名称",
        projectAddr: "项目地址",
        address: "详细地址",
        longitude: "经度",
        dimension: "纬度",
        companyName: "所属公司名称",
        remark: "项目说明",
      },
      title: {
        indexTitle: "项目管理",
        addTitle: "项目新增",
        editTitle: "项目编辑",
        viewTitle: "项目查看",
      },
    },
    itemOrg: {
      field: {
        itemOrgs: "组织名称或组织编码",
        itemDetail: "物料详情",
        orgErr: "该组织已有映射！",
        itemErr: "物料不能重复，请重新选择!",
      },
      title: {
        indexTitle: "物料组织映射",
      }
    },
    virtualOrg: {
      field: {
        inSide: "包含",
        title: "标题",
        outSide: "不包含",
        orgCode: "组织编码",
        orgName: "组织名称",
        companyName: "企业名称",
        orgType: "组织类型",
        remark: "备注"
      },
      title: {
        indexTitle: "组织管理",
      }
    },
    saleArea: {
      field: {
        tagName: "销售流向区域",
        areaName: "区域名称",
        areaCode: "区域编码",
        createPeople: "创建人",
        createTime: "创建时间",
        belongArea: "所属区域",
        remarks: "备注",
        cityName: "区名",
        cityCode: "区编码",
      },
    },
    projectFiles: {
      field: {
        tagName: "项目档案",
        projectName: "名称",
        projectCode: "编码",
        createPeople: "创建人",
        createTime: "创建时间",
        projectType: "项目类型",
        belongCompany: "隶属公司",
        remarks: "备注"
      },
      title: {}
    },
  },
  business: {
    components: {
      common: {
        treeTableDialog: {
          msg: {
            selected: "已选中",
          }
        }
      },
      //ERP物料
      mdExtItemDialog: {
        field: {
          extItemCode: "ERP物料编码",
          extItemName: "ERP物料名称",
          logisticsCode: "一卡通编码",
          batchCode: "批次编号",
          carNo: "车/船号",

        },
        title: {
          erpMaterialTitle: "ERP物料",
        },
      },
      //ERP物料管理
      mdItemDialog: {
        field: {
          itemCode: "物料编码",
          itemName: "物料名称",
          unitName: "单位名称",
        },
        title: {
          headTitle: "ERP物料管理",
        },
      },
      //仓库信息
      mdWarehouseDialog: {
        field: {
          code: "仓库编号",
          name: "仓库名称",
          isEnable: "是否启用",
        },
        title: {
          wareHouseTitle: "仓库信息",
        },
      },
      //仓库信息
      mdWarehouseQytDialog: {
        field: {
          warehouseName: "仓库名称",
          warehouseCode: "仓库编码",
          itemName: "物料名称",
          itemCode: "物料编码",
          stockQty: "库存数量",
          unitName: "单位名称",
        },
        title: {
          wareHouseTitle: "仓库信息",
        },
      },
      //设备信息
      pmDeviceDialog: {
        field: {
          deviceCode: "设备编码",
          deviceName: "设备名称",
        },
        title: {
          deviceTitle: "设备信息",
        },
      },
    },
  },
  base: {
    md: {
      unit: {
        field: {
          unitCode: "单位编码",
          unitName: "单位名称",
          isEnable: "是否启用",
          remark: "备注",
        },
        title: {
          indexHeadTitle: "单位管理",
          addHeadTitle: "单位管理新增",
          editHeadTitle: "单位管理编辑",
          viewHeadTitle: "单位管理查看",
        }
      },
      period: {
        field: {
          periodCode: "期间编码",
          year: "年",
          month: "月",
          startDate: "开始日期",
          endDate: "结束日期",
          isEnable: "是否启用",
          periodDate: "期间日期",
          generateDate: "生成日期",
          viewDate: "查看日期",
          dateTitle: "期间日期管理",
          detail: "详情",
          select_warning: "请选中需要保存的期间信息",
          year_blank: "年份不能为空",
          month_blank: "月份不能为空",
          start_blank: "开始日期不能为空",
          start_end: "结束日期不能小于开始日期",
          end_blank: "结束日期不能为空",
          // isEnable_blank: "是否启用不能为空",
          date_blank: "期间日期不能为空",
        },
        title: {
          indexHeadTitle: "期间管理",
        },
        msg: {
          checkPeriod: "禁止保存重复区间时间！",
          checkPeriodDate: "日期已生成，无法重复操作!",
          periodDateNotExist: "期间日期不存在，请添加期间日期!",
        }
      },
      fgp: {
        field: {
          startDate: "开始日期",
          endDate: "结束日期",
          remark: "备注",
          add_warning: "请先保存基础信息",
          start_blank: "开始时间不能为空",
          end_blank: "结束时间不能为空",
        },
        title: {
          baseTitle: "基本信息",
          indexHeadTitle: "峰谷平列表",
          addHeadTitle: "峰谷平列表新增",
          editHeadTitle: "峰谷平列表编辑",
          viewHeadTitle: "峰谷平列表查看",
        },
      },
      fgpLine: {
        field: {
          fgpType: "峰谷平类型",
          startTime: "开始时间",
          endTime: "结束时间",
          price: "价格",
          remark: "备注",
          select_warning: "请选择需要保存的峰谷平设置",
          type_blank: "峰谷平类型不能为空",
          start_blank: "开始时间不能为空",
          end_blank: "结束时间不能为空",
          price_blank: "价格不能为空",
        },
        title: {
          indexHeadTitle: "峰谷平时间",
          addHeadTitle: "峰谷平时间新增",
          editHeadTitle: "峰谷平时间编辑",
          viewHeadTitle: "峰谷平时间查看",
        },
        msg: {
          checkPeriod: "峰谷平时间不是同一天的连续24小时，请修改后再保存!",
        }
      },
      param: {
        field: {
          paramCode: "参数编码",
          paramName: "参数名称",
          paramValue: "参数值",
          remark: "备注",
        },
        title: {
          indexHeadTitle: "参数管理",
          addHeadTitle: "参数管理新增",
          editHeadTitle: "参数管理编辑",
          viewHeadTitle: "参数管理查看",
        },
        msg: {
          check: "参数值是范围在0.00-1.00的两位小数，可无补位",
        },
      },
    },
  },

}
