import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getList = (current,size,params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckact/list',
    method: 'get',
    params:{
      current,
      size,
      ...params
    }
  })
}
export const detail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckact/detail',
    method: 'get',
    params
  })
}
export const queryTask = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckact/queryTask',
    method: 'post',
    data
  })
}
export const changeOrPunish = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckact/changeOrPunish',
    method: 'get',
    params
  })
}
export const update = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckact/actFinish',
    method: 'post',
    data
  })
}
export const submit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckact/submit',
    method: 'post',
    data
  })
}
export const hdcheckindexSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckindex/submit',
    method: 'post',
    data
  })
}
export const hdcheckindexsaveList = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckindex/saveList',
    method: 'post',
    data
  })
}
export const hdcheckindexList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckindex/list',
    method: 'get',
    params
  })
}
export const hdcheckindexRemove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckindex/remove',
    method: 'post',
    data
  })
}
export const hdcheckindextype = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckindextype/submit',
    method: 'post',
    data
  })
}
export const hdcheckindexObj = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckobject/submit',
    method: 'post',
    data
  })
}
export const hdcheckindexObjRemove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckobject/remove',
    method: 'post',
    data
  })
}
export const hdcheckindexSaveList = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckindextype/submitList',
    method: 'post',
    data
  })
}
export const remove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckact/remove',
    method: 'post',
    data
  })
}
export const copy = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckact/copy',
    method: 'post',
    data
  })
}
export const getCode = () => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckact/getCode',
    method: 'get',
  })
}
export const getTypeTree = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckindextype/typeObjectTree',
    method: 'get',params
  })
}
 // 获取检查项分类 
  // getTreeNodes
  export const getTreeTypes = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/hdcheckindextype/typeTree',
      method: 'get',
      params
    })
  }
export const TypeTreeRemove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckindextype/remove',
    method: 'post',
    data
  })
}


//检查人员表接口
export const hdcheckpersonPage = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckperson/page',
    method: 'get',
    params
  })
}
export const hdcheckpersonList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckperson/list',
    method: 'get',
    params
  })
}
export const hdcheckpersonDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckperson/detail',
    method: 'get',
    params
  })
}
export const hdcheckpersonSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckperson/submit',
    method: 'post',
    data
  })
}
export const hdcheckpersonremove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckperson/remove',
    method: 'post',
    data
  })
}
//相关资料

export const hdcheckmaterialRemove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckmaterial/remove',
    method: 'post',
    data
  })
}
export const hdcheckmaterialSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckmaterial/submit',
    method: 'post',
    data
  })
}
export const hdcheckmaterialList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckmaterial//listNoPage',
    method: 'GET',
    params
  })
}
//项目风险应对措施表接口
export const pmriskTreatment = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/pmriskTreatment/checkImportList',
    method: 'GET',
    params
  })
}
//风险应对措施表接口
export const riskTreatment = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/riskTreatment/checkImportList',
    method: 'GET',
    params
  })
}
export const hdchecktaskproPage = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdchecktaskpro/problemPage',
    method: 'GET',
    params
  })
}
export const hdchecktaskproRemove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdchecktaskpro/remove',
    method: 'post',
    data
  })
}


export const hdcheckactSubmitEq = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdcheckact/submitEq',
    method: 'post',
    data
  })
}

export const hdchecktaskproList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdchecktaskpro/problemList',
    method: 'GET',
    params
  })
}