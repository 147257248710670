import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

/** 设备检查 */
/** 通过对象id查询日常检查计划 */
export const selectPlansByObjectId = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/hdcheckplan/selectPlansByObjectId',
      method: 'get',
      params
    })
}

/** 检查记录 */
export const taskRecord = (current,size,params) =>{
  return request({
    url: ServerNameEnum.SERVER_FX + '/hdchecktask/recordPage',
    method: 'get',
    params: {
      current,
      size,
      ...params
    }
  })
}