/*
 * @Author: absorbedyy@163.com
 * @Date: 2024-05-09 09:23:15
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-05-11 15:30:08
 * @Description: 
 */
import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

/** 环保突发事件-分页 */
export const epeventPage = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epevent/page',
    method: 'get',
    params
  })
}
/** 环保突发事件-详情 */
export const epeventDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epevent/detail',
    method: 'get',
    params
  })
}
export const epeventSubmit= (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epevent/submit',
    method: 'post',
    data
  })
}
export const epeventRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/epevent/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
/** 环保突发事件- end */

