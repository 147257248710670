var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    _vm._g(_vm._b({}, "el-form-item", _vm.$attrs, false), _vm.$listeners),
    [
      _c(
        "span",
        { attrs: { slot: "label" }, slot: "label" },
        [
          _vm._v(_vm._s(_vm.label) + "\n    "),
          _vm.tipLabel
            ? [
                _c("el-tooltip", { attrs: { placement: "top" } }, [
                  _c("div", {
                    attrs: { slot: "content" },
                    domProps: { innerHTML: _vm._s(_vm.tipLabel) },
                    slot: "content",
                  }),
                  _c("a", { class: _vm.tipIcon }),
                ]),
              ]
            : _c("a", { staticClass: "empty-icon" }),
        ],
        2
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }