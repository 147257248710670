import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_LOCATION = "/deviceLocation";

export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_LOCATION+'/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_LOCATION+'/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_LOCATION+'/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_LOCATION+'/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_LOCATION+'/update',
    method: 'post',
    data: row
  })
}

export const submit = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_LOCATION+'/submit',
    method: 'post',
    data: row
  })
}

export const getModelLazyTree = (parentId) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_LOCATION+'/lazy-tree',
    method: 'get',
    params: {
      parentId
    }
  })
}

export const tree = () => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_LOCATION+'/tree',
    method: 'get'
  })
}

// export const devicetree = () => {
//   return request({
//     url: ServerNameEnum.SERVER_CPS_+'/client/devicetree',
//     method: 'get',
//   })
// }

