import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const userDeptList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/userDeptList',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const userDepartmentSeparationList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/userDepartmentSeparation',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getLazyTree = ( params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/dept/lazy-tree',
    method: 'get',
    params
  })
}
export const getLazyList = (current, size, parentId, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/lazy-list',
    method: 'get',
    params: {
      ...params,
      parentId,
      current,
      size,
    }
  })
}
export const getLazyListDept = (current, size, parentId, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/lazy-list-dept',
    method: 'get',
    params: {
      ...params,
      parentId,
      current,
      size,
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/submit',
    method: 'post',
    data: row
  })
}

export const getDept = (id) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/detail',
    method: 'get',
    params: {
      id,
    }
  })
}

export const getDeptTree = (tenantId, deptCategory,parentId,params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/tree',
    method: 'get',
    params: {
      tenantId,
      deptCategory,parentId,...params
    }
  })
}

export const getLazyDeptTree = (params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/lazy-tree-category',
    method: 'get',
    params
  })
}


export const treeByUserId = (tenantId) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/treeByUserId',
    method: 'get',
    params: {
      tenantId,
    }
  })
}

export const getDeptLazyTree = (parentId,params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/lazy-tree',
    method: 'get',
    params: {
      parentId,...params
    }
  })
}
export const getDeptLazyTreeAll = (parentId,params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/lazy-tree-all',
    method: 'get',
    params: {
      parentId,...params
    }
  })
}
export const getPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const selectChildren = (deptCategory) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/selectChildren',
    method: 'get',
    params: {
      ...deptCategory
    }
  })
}

export const selectParentTree = () => request({
  url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/selectParentTree',
  method: 'get',
});

export const DeptList = () => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/deptReportSettings/getDeptList',
    method: 'post',
  })
}

export const getDeptSetting = (reportId) => request({
  url: ServerNameEnum.SERVER_SYSTEM_ + '/deptReportSettings/list',
  method: 'get',
  params: {
    reportId: reportId
  }
});

export const submitDeptSetting = (row, id) => request({
  url: ServerNameEnum.SERVER_SYSTEM_ + '/deptReportSettings/submit?reportId=' + id,
  method: 'post',
  data: row
});


export const companyProjects = () => request({
  url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/companyProjects',
  method: 'get',
});

export const multiOrganization = () => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}//dept/multiOrganization`,
    method: 'get',
  })
}

export const treeByCategorys = (deptCategory) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/treeByCategorys',
    method: 'get',
    params: {
      ...deptCategory
    }
  })
}

export const treeDeptWithoutProject = () => request({
  url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/treeDeptWithoutProject',
  method: 'get',
});

export const selectUserChildrenWithoudProject = () => request({
  url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/selectUserChildrenWithoudProject',
  method: 'get',
});

// 用于指标管理-指标数据
export const selectUserChildrenWithProject = () => request({
  url: ServerNameEnum.SERVER_SYSTEM_ + '/dept/selectUserChildrenWithProject',
  method: 'get',
});



