import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getMyTaskList = (myTask) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/myTasks/getList",
    method: 'post',
    data: myTask
  })
}

export const getPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/myTasks/getPage",
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  })
}
