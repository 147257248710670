/*
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-11-12 10:30:09
 */
import request from '@/router/axios';
//导出模板国内
export function sendMessage(data) {
  return request({
    url: '/api/sinoma-report/fillreporttask/sendMessage',
    method: "POST",
    data
  })
}
export function temporaryData(data) {
  return request({
    url: '/api/sinoma-report/fillreporttask/temporary/data',
    method: "POST",
    data
  })
}
export function submitData(data) {
  return request({
    url: '/api/sinoma-report/fillreporttask/submit/data',
    method: "POST",
    data
  })
}
export function fillreporttaskDetail(params) {
  return request({
    url: '/api/sinoma-report/fillreporttask/detail',
    method: "get",
    params
  })
}
// 管理端
export function fillreporttaskManaPage(params) {
  return request({
    url: '/api/sinoma-report/fillreporttask/project/getData',
    method: "get",
    params
  })
}
// 项目端
export function fillreporttaskProjPage(params) {
  return request({
    url: '/api/sinoma-report/fillreporttask/project/getData',
    method: "get",
    params
  })
}
export function batchList(params) {
  return request({
    url: '/api/sinoma-report/fillreporttask/batch/list',
    method: "get",
    params
  })
}
export function fillTypeList(params) {
  return request({
    url: '/api/sinoma-report/fillreporttask/fillType/list',
    method: "get",
    params
  })
}
export function retrace(params) {
  return request({
    url: '/api/sinoma-report/fillreporttask/retrace',
    method: "get",
    params
  })
}
export function assist(params) {
  return request({
    url: '/api/sinoma-report/fillreporttask/assist',
    method: "get",
    params
  })
}
export function exportDate(data) {//查询导出
  return request({
    url: `/api/sinoma-report/fillreporttask/exportDate?batchNumber=${data.batchNumber}`,
    method: "POST",
    data
  })
}
export function assistSave(data) {//查询导出
  return request({
    url: `/api/sinoma-report/fillreporttask/assist/save?rowNum=0&dataBeginRow=3`,
    method: "POST",
    data
  })
}
export function update(data) {//查询导出
  return request({
    url: `/api/sinoma-report/fillreporttask/update`,
    method: "POST",
    data
  })
}
