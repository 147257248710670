import request from '@/router/axios';
import ServerNameEnum from "@/util/ServerNameEnum";

export const getProcessId = (modelKey) => {
  return request({
    url: ServerNameEnum.SERVER_WORK_FLOW_ + '/process/process/getProcessById',
    method: 'get',
    params: {
      modelKey
    }
  })
}

export const categoryTree = () => {
  return request({
    url: ServerNameEnum.SERVER_WORK_FLOW_ + '/design/category/tree',
    method: 'get'
  })
}
