import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
const SERVER_FX = '/api/sinoma-hse-prj'

// 查询
export const LogList = (current, size, params) => {
    return request({
        url: SERVER_FX + '/logrecord/page',
        method: 'get',
        params: {
            ...params,
            current,
            size,
        }
    })
}

// 查询详情
export const getLogDetail = (id) => {
    return request({
        url: SERVER_FX + '/logrecord/detail',
        method: 'get',
        params
            : {
            id
        }
    })
}

// 新增 修改
export const addLog = (row) => {
    return request({
        url: SERVER_FX + '/logrecord/submit',
        method: 'post',
        data: row
    })
}

// 删除
export const remove = (ids) => {
    return request({
        url: SERVER_FX + '/logrecord/remove',
        method: 'post',
        params: {
            ids
        }
    })
}

// 自动获取编码
export const getCode = (current, size, params) => {
    return request({
        url: SERVER_FX + '/logrecord/getCode',
        method: 'get',
        params: {
            ...params,
            current,
            size,
        }
    })
}

// 附件列表查询
export const LogFilesList = (logId) => {
    return request({
        url: SERVER_FX + '/logattachments/list',
        method: 'get',
        params: {logId},

    })
}

// 附件列 新增 修改
export const addLogFiles = (row) => {
    return request({
        url: SERVER_FX + '/logattachments/submit',
        method: 'post',
        data: row
    })
}

// 附件 删除
export const logFilesRemove = (ids) => {
    return request({
        url: SERVER_FX + '/logattachments/remove',
        method: 'post',
        params: {
            ids
        }
    })
}

// 问题清单查询
export const LogProblemList = (params) => {
    return request({
        url: SERVER_FX + '/logproblem/list',
        method: 'get',
        params: {
            ...params,
        }
    })
}

// 问题清单 新增 修改
export const addLogProblem = (row) => {
    return request({
        url: SERVER_FX + '/logproblem/submit',
        method: 'post',
        data: row
    })
}

// 问题清单批量处理
export const addLogProblemBatchSubmit = (data) => {
    return request({
        url: SERVER_FX + '/logproblem/batchSubmit',
        method: 'post',
        data
    })
}
// 问题清单 删除
export const logProblemRemove = (ids) => {
    return request({
        url: SERVER_FX + '/logproblem/remove',
        method: 'post',
        params: {
            ids
        }
    })
}

// 安全日志清单--获取上一次日志内容
export const LogGetLastData = (params) => {
    return request({
        url: SERVER_FX + '/logrecord/getLast',
        method: 'get',
        params: {
            ...params,
        }
    })
}


// 更新流程id
export const LogUpdateProcessId = (params) => {
    return request({
        url: SERVER_FX + '/logrecord/updateProcessId',
        method: 'post',
        data: params
    })
}