/*
 * @Author: absorbedyy@163.com
 * @Date: 2024-06-11 09:14:48
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-06-17 09:02:21
 * @Description: 
 */
import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 安全全息-隐患
export const holographyHdPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/data/dangerPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

// 安全全息-风险
export const holographyRiskPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/data/riskPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

// 安全全息-事故
export const holographyAdPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/data/accRecordPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

// 安全全息-应急
export const holographyEgPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/data/erPlanPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

// 安全全息-作业票
export const holographyHtPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/workTicket/page`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}


