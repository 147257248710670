import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";

const CONTROLLER_NAME = "/app/about";

/**
 * 分页查询
 * @param current
 * @param size
 * @param params
 * @returns {*}
 */
export const getList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/getList`,
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};
/**
 * 新增
 * @param data
 * @returns {*}
 */
export const add= (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}${CONTROLLER_NAME}/submit`,
    method: 'post',
    data: row
  })
}

/**
 * 回显数据
 * @param id
 * @returns {*}
 */
export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/detail`,
    method: "get",
    params: {
      id,
    },
  });
};

/**
 * 删除
 * @param ids
 * @returns {*}
 */
export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/remove`,
    method: 'post',
    params: {
      ids
    }
  })
};
