export const fieldInfo = {
    Words4: { length:80, align:"center"},//4个字及以下
    Words5: { length:95, align:"center"},//5个字
    Words6: { length:115, align:"center"},//6个字
    date: { length: 120, align:"center"},//日期
    time: {length: 80, align:"center"},//日期时间
    datetime: {length: 170, align:"center"},//日期时间
    name_person: {length:120,align:"center"},//姓名
    phoneNumber: {length:180,align:"center"},//电话号码
    USCI: {length:185,align:"center"},//统一社会信用代码：Unified Social Credit Identifier
    IdentificationNumber: {length:185,align:"center"},//身份证号
    name_company: {length:200,align:"left"},//公司名称
    fullAddress: {length:150,align:"left"},//详细地址
    job: {length:150,align:"center"}, //岗位
    name_country: {length:170,align:"center"}, //国家名称：最长10个字
    name_file:{length:170,align:"left"},//文件名称
    name_regionAll:{length:200,align:"left"},//亚洲 / 中国 / 青海省 / 果洛藏族自治州 / 玛沁县
    amount:{length:100,align:"right"},//金额
}