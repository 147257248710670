import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_NAME = "/knowledgeCate";

export const tree = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}${CONTROLLER_NAME}/tree`,
    method: 'get',
    params
  })
}

export const saveOrUpdate= (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}${CONTROLLER_NAME}/saveOrUpdate`,
    method: 'post',
    data

  })
}

export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}${CONTROLLER_NAME}/remove`,
    method: 'get',
    params: {
      ids
    }
  })
}
