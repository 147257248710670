var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "number-range flex-container flex-jus-sb" },
    [
      _c(
        "avue-input-number",
        _vm._b(
          {
            attrs: {
              placeholder: _vm.startPlaceholder,
              clearable: true,
              controls: false,
            },
            on: {
              change: function ($event) {
                return _vm.handleChange($event, _vm.attrs.startProp)
              },
            },
            model: {
              value: _vm.startProp,
              callback: function ($$v) {
                _vm.startProp = $$v
              },
              expression: "startProp",
            },
          },
          "avue-input-number",
          _vm.attrs,
          false
        )
      ),
      _vm._v("-\n  "),
      _c(
        "avue-input-number",
        _vm._b(
          {
            attrs: {
              placeholder: _vm.endPlaceholder,
              min: _vm.startProp,
              clearable: true,
              controls: false,
            },
            on: {
              change: function ($event) {
                return _vm.handleChange($event, _vm.attrs.endProp)
              },
            },
            model: {
              value: _vm.endProp,
              callback: function ($$v) {
                _vm.endProp = $$v
              },
              expression: "endProp",
            },
          },
          "avue-input-number",
          _vm.attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }