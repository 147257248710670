var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      staticClass: "SNOWS-common-drawer columnSettings-drawer",
      attrs: {
        title: "列表显示设置",
        visible: _vm.drawerVisible,
        wrapperClosable: false,
        size: "320px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.drawerVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "SNOWS-flex-main" },
        [
          _c(
            "el-scrollbar",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "column-list",
            },
            [
              _vm.list.length
                ? [
                    _c(
                      "draggable",
                      {
                        attrs: {
                          list: _vm.list,
                          animation: 340,
                          handle: ".column-item-icon",
                        },
                      },
                      _vm._l(_vm.list, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.prop,
                            staticClass: "column-item",
                            on: {
                              click: function ($event) {
                                if ($event.target !== $event.currentTarget)
                                  return null
                                item.columnVisible = !item.columnVisible
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "column-item-label" }, [
                              _c("i", {
                                staticClass:
                                  "icon-ym icon-ym-darg column-item-icon",
                              }),
                              _c("span", [_vm._v(_vm._s(item.label))]),
                            ]),
                            _c("el-checkbox", {
                              staticClass: "check-box",
                              model: {
                                value: item.columnVisible,
                                callback: function ($$v) {
                                  _vm.$set(item, "columnVisible", $$v)
                                },
                                expression: "item.columnVisible",
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                : _c("el-empty", {
                    attrs: { description: "暂无数据", "image-size": 120 },
                  }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.drawerVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveSettings } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }