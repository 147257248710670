import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";


/**
 * 分页查询
 */
export const getList = (current, size, params) => {
    return request({
        url: `${ServerNameEnum.SERVER_SYNC}/supplier/selectSupplierGroup`,
        method: "post",
        data: {
            sinomaSupplierMain: {
                ...params
            },
            query: {
                current: current,
                size: size
            }
        }
    });
};

/**
 * 新增或修改
 */
// export const submit = (row) => {
//     return request({
//         url: `${ServerNameEnum.SERVER_SYNC}/supplier/sync`,
//         method: 'post',
//         data: row
//     })
// }
export const submit = (row) => {
    return request({
        url: `${ServerNameEnum.SERVER_SYNC}/supplier/syncSupplier`,
        method: 'post',
        data: row
    })
}


/**
 * 删除
 */
export const remove = (code) => {
    return request({
        url: `${ServerNameEnum.SERVER_SYNC}/supplier/delSupplier`,
        method: 'post',
        data:
        {
            supplier: {
                code: code
            },
        }
    })
};
