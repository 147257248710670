import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 查询
export const getRecordList = (current, size, params) => {
    return request({
        url: ServerNameEnum.SERVER_SYSTEM_ + '/si_sign_record/list',
        method: 'get',
        params: {
            ...params,
            current,
            size,
        }
    })
}

// 查询详情
export const getRecordDetail = (id) => {
    return request({
        url: ServerNameEnum.SERVER_SYSTEM_ + '/si_sign_record/detail',
        method: 'get',
        params
            : {
            id
        }
    })
}

// 新增 修改
export const addRecord = (row) => {
    return request({
        url: ServerNameEnum.SERVER_SYSTEM_ + '/si_sign_record/submit',
        method: 'post',
        data: row
    })
}

// 删除
export const remove = (ids) => {
    return request({
        url: ServerNameEnum.SERVER_SYSTEM_ + '/si_sign_record/remove',
        method: 'post',
        params: {
            ids
        }
    })
}