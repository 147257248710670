export default {
  pm: {
    monthProducePlan: {
      title: {
        monthPurchasePlan: "月度采购计划",
        monthPurchaseAddPlan: "月度采购计划新增",
        monthPurchaseEditTitle: "月度采购计划编辑",
        monthPurchaseViewTitle: "月度采购计划查看",
        monthSalePlan: "月度销售计划",
        monthSaleAddPlan: "月度销售新增计划",
        monthSaleEditTitle: "月度销售计划编辑",
        monthSaleViewTitle: "月度销售计划查看",
        monthProducePlan: "月度生产计划",
        monthProduceAddPlan: "月度生产计划新增",
        monthProduceEditTitle: "月度生产编辑",
        monthProduceViewTitle: "月度生产查看",
        planDetails: "计划明细",
      },
      field: {
        Difference: "差值",
        productName: "产品名称",
        planState: "状态",
        planNum: "计划单号",
        planYear: "计划年份",
        planMonth: "月份",
        month: "月份",
        itemName: "生产物料",
        planName: "计划名称",
        userName: "制单人",
        yearTotal: "年度总计划量",
        yearTotalAct: "年度实际总量",
        month1Plan: "1月份计划量",
        month2Plan: "2月份计划量",
        month3Plan: "3月份计划量",
        month4Plan: "4月份计划量",
        month5Plan: "5月份计划量",
        month6Plan: "6月份计划量",
        month7Plan: "7月份计划量",
        month8Plan: "8月份计划量",
        month9Plan: "9月份计划量",
        month10Plan: "10月份计划量",
        month11Plan: "11月份计划量",
        month12Plan: "12月份计划量",
        month1Act: "1月份实际量",
        month2Act: "2月份实际量",
        month3Act: "3月份实际量",
        month4Act: "4月份实际量",
        month5Act: "5月份实际量",
        month6Act: "6月份实际量",
        month7Act: "7月份实际量",
        month8Act: "8月份实际量",
        month9Act: "9月份实际量",
        month10Act: "10月份实际量",
        month11Act: "11月份实际量",
        month12Act: "12月份实际量",
      }
    },
    yearProducePlan: {
      title: {
        yearPurchasePlan: "年度采购计划",
        yearPurchaseAddPlan: "年度采购计划新增",
        yearPurchaseEditTitle: "年度采购计划编辑",
        yearPurchaseViewTitle: "年度采购计划查看",
        yearSalePlan: "年度销售计划",
        yearSaleAddPlan: "年度销售新增计划",
        yearSaleEditTitle: "年度销售计划编辑",
        yearSaleViewTitle: "年度销售计划查看",
        yearProducePlan: "年度生产计划",
        yearProduceAddPlan: "年度生产计划新增",
        yearProduceEditTitle: "年度生产编辑",
        yearProduceViewTitle: "年度生产查看",
        planDetails: "计划明细",
        planDetail: "计划详情"
      },
      field: {
        inputData: "请输入数据",
        AGASaving: "保存后自动生成",
        planData: "计划数据",
        planForm: "年度XX计划表",
        monthForm: "月度XX计划表",
        productName: "产品名称",
        planState: "状态",
        planNum: "计划单号",
        planYear: "计划年份",
        year: "年度",
        planName: "计划名称",
        userName: "制单人",
        yearTotal: "年度总计划量",
        itemName: "生产物料",
        month1: "1月份",
        month2: "2月份",
        month3: "3月份",
        month4: "4月份",
        month5: "5月份",
        month6: "6月份",
        month7: "7月份",
        month8: "8月份",
        month9: "9月份",
        month10: "10月份",
        month11: "11月份",
        month12: "12月份",
        dataError: "有数据格式有误,请确认数据最多只保留两位小数",
        fileOr: "将文件拖到此处，或",
        clickUpload: "点击上传",
        initialValue: "初始值",
        correctionValue: "修正值",
      },
      btn: {
        importBtn: "导入",
        exportPlan: "导出计划",
      },
      msg: {
        itemDetailsForACurrentPlan: "当前计划正在审核或已通过审核不可删除其物料明细",
        processIsNotModified: "当前计划正在审核或已通过审核不支持修改",
        notYearProducePlan: "操作失败，请确保没有对应的年度计划",
        notAddSameItemRepeatedly: "保存失败，请勿重复添加同一物料",
        saveDetails: "导入失败，请先保存头部数据在导入明细数据",
        detailsIsNull: "导入失败，请确保该页面的计划明细数据为空",
        savePlanToSubmit: "请先保存计划才能提交",
        pleaseFillInThePlanName: "请填写计划名称",
        addPlanDetailsSubmit: "请添加计划明细后提交",
        submits: "验证完成请继续提交",
        submitByProduce: "提示，以下物料的年生产计划总量小于年销售计划总量：",
        submitByPurchase: "提示，以下物料的年生采购总量小于年计划生产总量的物资需求：",
        selectRightForm: "请选择正确的表格导入上传",
        selectRightFormHead: "请上传正确表格头的表格文件",
        notItemData: "写入失败，表格中有系统不存在的物资数据：",
        thereItemCode: "写入失败，有物料编码为：",
        lineDataRepeat: "的行数据重复",
        itemNameDataNotFilled: "写入失败，存在一行或多行物料名称数据未填",
        itemCodeDataNotFilled: "写入失败，存在一行或多行物料编码数据未填",
        month1DataIssue: "写入失败，1月份有数据小于0",
        month2DataIssue: "写入失败，2月份有数据小于0",
        month3DataIssue: "写入失败，3月份有数据小于0",
        month4DataIssue: "写入失败，4月份有数据小于0",
        month5DataIssue: "写入失败，5月份有数据小于0",
        month6DataIssue: "写入失败，6月份有数据小于0",
        month7DataIssue: "写入失败，7月份有数据小于0",
        month8DataIssue: "写入失败，8月份有数据小于0",
        month9DataIssue: "写入失败，9月份有数据小于0",
        month10DataIssue: "写入失败，10月份有数据小于0",
        month11DataIssue: "写入失败，11月份有数据小于0",
        month12DataIssue: "写入失败，12月份有数据小于0",
        uploadSuccessful: "上传成功",
        notYearPlan: "上传失败，未查询到对应的年度计划",
        planDataDoesNotMatch: "写入失败，有物料数据与对应年份计划不匹配",
        planNotDelete: "进入流程的计划不能被删除",
        notOrRepeatYearPlan: "添加失败有可能的原因是：重复添加修改月份计划，未添加上一个月的计划或请先审核对应年度计划",
        addJanuary: "行数据保存失败，请先添加一月生产计划",
        addDetails: "请添加计划明细后提交",
        selectPlan: "请选择对应的计划文件",
        specificationUpload: "表格标题不合规范，请修改规范后上传",


      }
    },
    //能管配置
    PmIndexCfg: {
      title: {
        title: "指标配置"
      },
      btn: {
        exportMonitoring: "导出配置",
      },
      msg: {
        exportPmIndexCfg: "导出指标配置",
        template: "导出指标配置模板"
      },
      field: {
        indexCode: "指标编码",
        indexName: "指标名称",
        sectName: "工段名称",
        sectCode: "工段编号",
        processName: "工序名称",
        processCode: "工序编码",
        equipCode: "设备编码",
        equipName: "设备名称",
        itemName: "物料名称",
        itemCode: "物料编码",
        recordType: "记录类型",
        type: "时间数据类型",
        dcsType: "生产类型",
      }
    },
    // 一卡通出厂
    oneCardStockOut: {
      field: {
        outbound: "出库",
        baseInfo: "基础信息",
        soNo: "销售订单编号",
        optType: "刷卡类型",
        customerName: "客户名称",
        customerCode: "客户编码",
        carrierName: "承运商名称 ",
        carrierCode: "承运商编码",
        carNo: "车牌号",
        extItemName: "物料名称",
        extItemCode: "物料编码",
        unitCode: "单位编码",
        unitName: "单位名称",
        unitPrice: "单价",
        transType: "运输方式",
        tareWeight: "皮重(吨)",
        grossWeight: "毛重(吨)",
        netWeight: "净重(吨)",
        inTime: "进厂时间",
        outTime: "出厂时间",
        batchCode: "批次编号",
        optFlag: "业务处处理标志",
        optMsg: "处理信息",
        itemCodeAndName: "物料编码/名称",
        status: "状态",
        busiType: "业务类型",
      },
      title: {
        labelTitle: "一卡通出厂",
        headTitle: "一卡通出厂",
        textTitle: "一卡通信息",
        indexHeadTitle: "一卡通出厂",
        addHeadTitle: "一卡通出厂新增",
        editHeadTitle: "一卡通出厂编辑",
        viewHeadTitle: "一卡通出厂查看",
      },
    },
    // 一卡通入场
    oneCardStockIn: {
      field: {
        warehouse: "入库",
        poNo: "采购订单编号",
        optType: "刷卡类型",
        supplierName: "供应商名称",
        supplierCode: "供应商编码",
        carrierName: "承运商名称 ",
        carrierCode: "承运商编码",
        miningName: "矿点名称",
        extItemName: "物料名称",
        extItemCode: "物料编码",
        unitCode: "单位编码",
        unitName: "单位名称",
        unitPrice: "单价",
        transType: "运输方式",
        tareWeight: "皮重(吨)",
        grossWeight: "毛重(吨)",
        netWeight: "净重(吨)",
        carNo: "车牌号",
        inTime: "进厂时间",
        outTime: "出厂时间",
        inTimeStart: "进厂开始时间",
        inTimeOut: "进厂结束时间",
        outTimeStart: "出厂开始时间",
        outTimeOut: "出厂结束时间",
        batchCode: "批次编号",
        optFlag: "业务处处理标志",
        optMsg: "处理信息",
        itemCodeAndName: "物料编码/物料名称",
        status: "状态",
        summaryOfNetWeight: "净重汇总",
        ton: "吨",
      },
      title: {
        labelTitle: "一卡通入厂",
        headTitle: "一卡通入厂",
        textTitle: "一卡通信息",
        indexHeadTitle: "一卡通入厂",
        addHeadTitle: "一卡通入厂新增",
        editHeadTitle: "一卡通入厂编辑",
        viewHeadTitle: "一卡通入厂查看",
      },
    },
    //生产调度
    dispatch: {
      field: {
        icon: "图标",
        name: "流程名称",
        key: "流程标识",
        category: "流程分类",
        version: "版本",
        status: "状态",
        activation: "激活",
        pending: "挂起",
      },
      btn: {
        initiateBtn: "发起",
      },
      title: {
        indexHeadTitle: "调度单",
      },
    },
    //计划管理
    plan: {
      //字段
      field: {
        year: "年",
        month: "月",
        productCode: "产品编码",
        productName: "产品名称",
        unitName: "计量单位",
        totalQty: "全年计划",
        sum: "合计",
        janQty: "1月",
        febQty: "2月",
        marQty: "3月",
        aprQty: "4月",
        mayQty: "5月",
        junQty: "6月",
        julQty: "7月",
        augQty: "8月",
        sepQty: "9月",
        octQty: "10月",
        novQty: "11月",
        decQty: "12月",
        status: "状态",
        documentNumber: "单据编号",
        months: "月份",
        documenter: "制单人",
        documentationDate: "制单日期",
        remark: "备注",
        index: "序号",
        yearMonth: "年月",
        deptName: "填报部门",
        billDate: "填报时间",
        userName: "填报人"
      },
      //标题
      title: {
        demandTitle: "需求计划",
        saleTitle: "销售计划",
        produceTitle: "生产计划",
        operateTitle: "经营计划",
        operateDetailTitle: "经营计划详情",
        itemTitle: "生产物料",
        basicInformation: "基本信息",
        productionoperation: "生产经营计划"
      },
      //消息
      msg: {
        itemTip: "请选择产品",
        totalQtyTip: "请输入正确格式的计划数量",
        productRepeat: "每种产品每年度，只能保存一条数据",
      }
    },
    //物料入库
    stockIn: {
      field: {
        stockInCode: "入库单号",
        itemCodeAndName: "物料编码/名称",
        warehouseCodeAndName: "仓库编码/名称",
        startDate: "开始日期",
        endDate: "结束日期",
        stockTime: "入库日期",
        itemCode: "物料编码",
        itemName: "物料名称",
        unitName: "单位",
        stockInQty: "数量",
        warehouseName: "仓库名称",
        carNo: "车/船牌号",
        stockTransationType: "业务类型",
        logisticsCode: "一卡通编码",
        supplierCode: "供应商编码",
        supplierName: "供应商名称",
        extItemCode: "ERP物料编码",
        extItemName: "ERP物料名称",
        status: "入库状态",
        remark: "备注",
        dcsCode: "DCS编码",
        stockUser: "入库用户",
      },
      btn: {
        confirmStockInBtn: "入库",
      },
      title: {
        logisticsTitle: "一卡通入库",
        logisticsAddTitle: "一卡通入库新增",
        logisticsEditTitle: "一卡通入库编辑",
        logisticsViewTitle: "一卡通入库查看",
        dcsTitle: "DCS入库",
        dcsAddTitle: "DCS入库新增",
        dcsEditTitle: "DCS入库编辑",
        dcsViewTitle: "DCS入库查看",
        manualTitle: "手工入库",
        manualAddTitle: "手工入库新增",
        manualEditTitle: "手工入库编辑",
        manualViewTitle: "手工入库查看",
        baseInfoTitle: "基础信息",
        oneCardInfoTitle: "一卡通信息",
        productionMaterialsTitle: "生产物料",
        supplierTitle: "供应商",
        ERPMaterialsTitle: "ERP物料",
        wareHouseInfoTitle: "仓库信息",
      },
      msg: {
        tipDetermineHaveFinishInStatus: "存在已经入库的数据，无法删除!",
        tipDetermineHaveFinishInStatusRow: "当前数据已入库，无法删除！",
        tipDetermineHaveFinishIn: "当前物料已入库！",
        tip_determine_have_finish_out: "当前物料已出库！",
        startDateMustLessThanEndDate: "开始时间不能大于结束时间",
        startDateMustLessThan: "开始时间或结束时间如果必须全部都选中",
        stockInFinished: "该入库单已经入库！",
        stockInNotExist: "未查询到入库单信息！",
        startDateMustLessThansealDate: "封存时间不能大于解封时间",
      }
    },
    //物料出库
    stockOut: {
      field: {
        stockOutCode: "出库单号",
        stockTransationType: "业务类型",
        itemCodeAndName: "物料编码/名称",
        warehouseCodeAndName: "仓库编码/名称",
        startDate: "开始日期",
        endDate: "结束日期",
        stockTime: "出库日期",
        itemCode: "物料编码",
        itemName: "物料名称",
        unitName: "单位",
        stockOutQty: "数量",
        warehouseName: "仓库名称",
        carNo: "车/船牌号",
        logisticsCode: "一卡通编码",
        customerCode: "客户编码",
        customerName: "客户名称",
        extItemCode: "ERP物料编码",
        extItemName: "ERP物料名称",
        remark: "备注",
        status: "出库状态",
        dcsCode: "DCS编码",
        stockUser: "出库用户",
      },
      btn: {
        confirmStockOutBtn: "出库",
      },
      title: {
        logisticsTitle: "一卡通出库",
        logisticsAddTitle: "一卡通出库新增",
        logisticsEditTitle: "一卡通出库编辑",
        logisticsViewTitle: "一卡通出库查看",
        dcsTitle: "DCS出库",
        dcsAddTitle: "DCS出库新增",
        dcsEditTitle: "DCS出库编辑",
        dcsViewTitle: "DCS出库查看",
        manualTitle: "手工出库",
        manualAddTitle: "手工出库新增",
        manualEditTitle: "手工出库编辑",
        manualViewTitle: "手工出库查看",
        baseInfoTitle: "基础信息",
        oneCardInfoTitle: "一卡通信息",
        productionMaterialsTitle: "生产物料",
        customerTitle: "客户",
        ERPMaterialsTitle: "ERP物料",
        wareHouseInfoTitle: "仓库信息",
      },
      msg: {
        tipDetermineHaveFinishOutStatus: "存在已经出库的数据，无法删除!",
        tipDetermineHaveFinishOutStatusRow: "当前数据已出库，无法删除!",
        tip_determine_have_finish_in: "当前物料已入库！",
        tipDetermineHaveFinishOut: "当前物料已出库！",
        startDateMustLessThan: "开始时间或结束时间如果必须全部都选中",
        startDateMustLessThanEndDate: "开始时间不能大于结束时间",
        serial_number_code_error: "编码生成失败",
        stockOutFinished: "该出库单已经出库！",
        stockOutNotExist: "未查询到出库单信息！",
      }
    },
    //物料盘点
    stockCheck: {
      field: {
        stockCheckCode: "盘点编码",
        startTime: "开始日期",
        endTime: "结束日期",
        userName: "制单人",
        realName: "制单人",
        participant: "参与人",
        stockCheckTime: "盘点时间",
        status: "盘点状态",
        remark: "备注",
        stockCheckType: "盘点类型",
        warehouseCode: "仓库编码",
        warehouseName: "仓库名称",
        itemCode: "物料编码",
        itemName: "物料名称",
        itemDensity: "物料密度",
        stockQty: "盘点结果",
        // stockCheckQty: "库存量",
        // stockTransationQty: "差值",
        locationCode: "库位编码",
        locationName: "库位名称",
        paramList: "参数列表",
        warehouseInfo: "仓库信息",
        locationInfo: "库位信息",
        formulaText: "计算公式",
        inventoryCheck: "盘点库存",
        cal: "计算",
        index: "序号",
        code: "参数编号",
        name: "参数名称",
        defVal: "默认值",
        value: "参数值",
        dailyInventory: "日常盘点",
        monthlyInventory: "月度盘点",
        summary: "盘点汇总结果",
        storageLocation: "存储位置",
        record: "盘点记录",
      },
      btn: {
        adjustQtyBtn: "调整库存",
        selWarehouseBtn: "选择仓库",
        checkBtn: "盘点",
        addCheckBtn: "添明细",
      },
      title: {
        stockCheckTitle: "物料盘点",
        inventoryRecordsAddTitle: "物料盘点新增",
        inventoryRecordsEditTitle: "物料盘点编辑",
        inventoryRecordsViewTitle: "物料盘点查看",
        lineTitle: "盘点清单",
        baseTitle: "基本信息",
        summaryTitle: "统计汇总结果",
        inventoryDetailTitle: "盘点明细",
        warehouseTitle: "选择存储位置",
      },
      msg: {
        blank: "请填写完整盘点清单",
        delWarningBlank: "请至少选择一个盘点清单",
        noDelBlank: "不能删除盘点状态为审核中或已完成的盘点记录!",
        limitation: "盘点量整数位范围在1~8位之内",
        hasMonth: "本月会计期间已有月度盘点，请重新选择",
        noFormula: "该仓库尚未配置公式参数",
        cal: "盘点结果需大于等于0",
        submit: "每一行盘点明细的盘点结果必填且必须大于0",
        warehouse: "请先选择存储位置",
        index: "序号",
        indexError: "行数据错误,",
        warehouseCode: "未查询到对应的物理仓库编码",
        warehouseCodeAndItemCode: "未根据对应的仓库编码和物料编码查询到该物料信息",
      }
    },
    //库存查询
    stock: {
      field: {
        bzCode: "编号",
        itemCodeAndName: "物料编码/名称",
        warehouseCodeAndName: "仓库编码/名称",
        startDate: "开始日期",
        endDate: "结束日期",
        stockTime: "时间",
        itemCode: "物料编码",
        itemName: "物料名称",
        unitName: "单位",
        stockQty: "数量",
        inventoryQty: "库存数量",
        warehouseName: "仓库名称",
        warehouseCode: "仓库编码",
        month: "期间月份"
      },
      title: {
        rawMaterialTitle: "原材料入库",
        factoryTitle: "出厂产品",
        materialTitle: "物料消耗",
        productOutputTitle: "产品产出",
        stockTakingTitle: "库存盘点",
        stockListTitle: "库存现有量列表",
      },
    },
    //测点订阅
    centralControl: {
      field: {
        classify: "测点订阅分类",
        supconClassify: "中控测点订阅分类",
        importLabel: "测点导入",
        upload: "上传文件",
        categoryIndexCode: "测点订阅编码",
        deviceCode: "设备编码",
        devicePropertyCode: "设备属性编码",
        devicePropertyName: "设备属性名称",
        categoryCode: "测点订阅分类编码",
        categoryName: "测点订阅分类名称",
        categoryFormula: "投料状态判断公式",
        remark: "备注",
        standardPointClass: "测点分类",
        standardPointType: "测点类型",
      },
      btn: {
        selectFileBtn: "选取文件",
      },
      title: {
        labelTitle: "测点订阅",
        headTitle: "测点订阅列表",
        menuTitle: "分类属性",
        headCatTitle: "测点订阅分类新增",
        headTypeTitle: "测点订阅类型分类",
        importTitle: "导入",
        addMaterialTitle: "添加物料",
        dialogTitle: "设备属性选择",
        deviceTitle: "设备信息",
      },
      msg: {
        topicBusiTypeSelect: "请选择测点订阅分类！",
        categorySelect: "请选择中控测点订阅分类！",
        uploadInfo: "请选择上传的测点文件！",
        deletePmCategoryRecord: "请先删除当前分类中控记录！",
        deletePmCategoryIndex: "请先删除当前分类测点订阅列表！",
        importLoading: "正在导入数据，请稍等...",
        importFormat: "支持.xlsx格式文件导入",
        tip_determine_no_select_cate: "请先选中分类",
        templateType: "传入文件类型不符,请使用导出模版类型",
      }
    },
    //交接班记录
    classReport: {
      btn: {
        classAdd: "日报补录",
      },
      field: {
        classCode: "班组编码",
        className: "班组名称",
        classCatCode: "班组类型编码",
        classCatName: "班组类型",
        deviceCode: "设备编码",
        deviceName: "设备名称",
        calendarDate: "排班日期",
        startCalendarDate: "排班开始日期",
        endCalendarDate: "排班结束日期",
        checkDate: "开始日期不能大于结束日期",
        classShiftCode: "班次编码",
        classShiftName: "班次名称",
        classShiftCatName: "班次分类名称",
        startTime: "开始时间",
        endTime: "结束时间",
        optUserName: "交班人",
        optUserCode: "交班人编码",
        createTime: "交班时间",
        workTime: "上班时间",
        classReportText: "交班内容",
        to: " 至 ",
        statusType: "业务状态",
      },
      title: {
        classReportTitle: "交接班记录",
        classReportAddTitle: "交接班记录新增",
        classReportEditTitle: "交接班记录编辑",
        classReportViewTitle: "交接班记录查看",
      },
      msg: {
        data_empty: "当前班次没有数据",
        delClassReportFail: "删除交接班记录数据失败!",
        warehouseCodeNotExist: "仓库编码缺失, 无法出入库!",
        hasSubmit: "已提交,请勿重复提交!!!",
        schedulingCalendarNotFound: "排班日历未查询到",
      },
      //生产明细
      classReportLine: {
        field: {
          order: "序号",
          equipName: "设备",
          productName: "产成品",
          warehouseName: "调出仓库",
          outputValInit: "产量（初始值）",
          outputVal: "产量（修正值）",
          runTimesInit: "运行时间（初始值）",
          runTimes: "运行时间（修正值）",
          usePower: "用电量",
          hour: " 小时 ",
          itemCode: "物料编码",
          itemName: "物料名称",
          itemInput: "消耗量（修正值）",
          itemInputInit: "消耗量（初始值）",
        },
        btn: {
          consumeBtn: "消耗量",
        },
        title: {
          productionDetailsTitle: "生产明细",
          consumeTitle: "物料消耗",
        },
      },
      //设备开停机情况
      deviceSwitchRecord: {
        field: {
          order: "序号",
          deviceCode: "设备编码",
          deviceName: "设备名称",
          deviceStatus: "事件类型",
          optTime: "事件时间",
          eventClass: "事件分类",
          eventDesc: "事件描述"
        },
        title: {
          EquipmentTitle: "设备开停机情况",
        },
      },
      //其他事件
      classReportEvent: {
        field: {
          order: "序号",
          equipCode: "设备编码",
          equipName: "设备名称",
          deviceStatus: "事件类型",
          optTime: "事件时间",
          eventClass: "事件分类",
          eventDesc: "事件描述",
        },
        title: {
          otherEventTitle: "其他事件",
        },
        msg: {
          equipNameTip: "请选择设备名称",
          deviceStatusTip: "请选择事件类型",
        },
      },
    },
    //开停机查询
    deviceQuery: {
      field: {
        deviceCode: "设备编码",
        deviceName: "设备名称",
        startTime: "开机时间",
        endTime: "关机时间",
        runTime: "运行时间",
        deviceFaultCatName: "停机分类名称",
        deviceFaultDetailName: "故障名称",
      },
      title: {
        queryTitle: "开停机查询",
      },
    },
    //设备状态
    deviceStatus: {
      field: {
        deviceCode: "设备编码",
        deviceName: "设备名称",
        deviceStatus: "设备状态",
        optTime: "操作时间",
      },
      title: {
        statusTitle: "设备状态",
        infoTitle: "设备信息",
        statusAddTitle: "设备状态新增",
        statusEditTitle: "设备状态编辑",
        statusViewTitle: "设备状态查看",
      },
    },
    //生产采集修改
    classReportDtlHour: {
      field: {
        equipName: "设备名称",
        dcsType: "指标类型",
        dcsName: "指标名称",
        date: "日期",
        optTime: "时间",
        dcsVal: "测点值",
        init: "初始值",
        update: "修改值",
        proItemName: "生产名称",
      },
      title: {
        indexTitle: "生产采集修改",
      },
      msg: {
        timeNotNull: "时间查询条件不能为空!",
        submit: "点击提交后,本日的所有数据不可再被修改",
      }
    },
    //设备开停机记录
    deviceRecord: {
      field: {
        deviceCode: "设备编码",
        deviceName: "设备名称",
        deviceStatus: "设备状态",
        optTime: "操作时间",
        remark: "备注",
        operate:"操作"
      },
      title: {
        deviceRecordTitle: "设备开停机记录",
        deviceRecordAddTitle: "设备开停机记录新增",
        deviceRecordEditTitle: "设备开停机记录编辑",
        deviceRecordViewTitle: "设备开停机记录查看",
        infoTitle: "设备信息",
      },
    },
    //交接班记录修改
    classReportDtl: {
      field: {
        equipName: "设备名称",
        classShiftName: "班次名称",
        dcsType: "指标类型",
        dcsName: "指标名称",
        calendarDate: "排版日期",
        classCatName: "班组分类名称",
        className: "班组名称",
        dcsVal: "测点值",
        init: "初始值",
        update: "修改值",
      },
      title: {
        classReportDtlTitle: "交接班记录修改",
      },
      msg: {
        submit: "点击提交后,本日的所有交接班记录数据不可再被修改",
      }
    },
    //上班签到
    workRecord: {
      field: {
        calendarDate: "排班日期",
        classCatName: "班组分类",
        className: "班组名称",
        classShiftName: "班次名称",
        teamLeader: "班组长",
        userName: "人员",
        isRequired: "是必填的",
        isTeamLeader: "是否组长",
        remark: "备注",
        workDate: "打卡时间",
        bringOut: "带出",
        deptName: "部门名称",
        isWork: "是否补卡",
        workType: "打卡类型",
      },
      title: {
        workRecordTitle: "上班签到",
        classCalendarTitle: "排班日历",
        classCalendarAddTitle: "排班日历新增",
      },
      btn: {
        onDutyPersonnelBtn: "当班人员",
      },
      msg: {
        multiple_class_error: "有多个班组信息，请检查！",
        onlyOneLeader: "一个班组只能有一个组长",
        multiple_class_calendar_error: "有多个班组分类信息，请检查！",
        no_class_calendar_error: "当前登录用户没有班组分类",
      }
    },
    // 物料平衡
    itemBalance: {
      field: {
        itemBalanceCode: '单据编号',
        month: '计划月度',
        status: '状态',
        userCode: '用户编码',
        userName: '制单人',
        deptCode: '部门编码',
        deptName: '部门',
        remark: '备注',
        index: "序号",
        createTime: "制单时间",
      },
      placeholder: {
        autoValue: '自动生成',
      },
      title: {
        itemBalanceTitle: '物料平衡',
        baseInfoTitle: '基本信息',
      },
      tip: {
        tip_determine_submit: '确定提交该数据?',
      },
      status: {
        draft: '草稿',
        submitted: '已提交',
      },
      msg:{
        monthlyInventory:"本月未做月度盘点!",
        checksThisMonth:"本月有多个月度盘点"
      }
    },
    // 物料平衡明细
    itemBalanceDtl: {
      field: {
        index: "序号",
        itemBalanceCode: '单据编号',
        groupCode: '分组编号',
        itemCode: '物料编码',
        itemName: '物料名称',
        theMonthStatistics: '当月统计',
        stockBefore: '盘点前',
        stockAfter: '盘点后',
        beginNum: '上期库存(t)',
        inNum: '进厂(t)',
        outNum: '出厂(t)',
        produceNum: '生产(t)',
        useNum: '消耗(t)',
        stock: '库存统计',
        stockNumInit: '库存现有量',
        stockNum: '实际库存',
        checkNum: '盘点库存',
        balanceNum: '盈亏',
        balanceRate: '盈亏率(%)',
        paramControl: '参数控制',
        paramRate: '平衡量控制率(%)',
        paramRateOnoff: '平衡量控制率_开关',
        paramLock: '是否锁定',

        stockBookNum: '账面值',
        produce: '生产',
        use: '消耗',
        in: '进厂',
        out: '出厂',

        yield: '产量',
        consume: '消耗',

        adjust: '调整',
        adjusted: '调整后',
        total: '合计',
      },
      txt: {
        lock: "锁定",
        unlock: "解除",
        open: "打开",
        close: "关闭",
        type: '类型',
        breed: '品种',
        yield: '产量',
        totalRatio: '配比合计',
        dryBasisRatio: '干基配比',
        dryBasisRatioConsumption: '干基配比消耗',
        wetBasisRatioConsumption: '湿基配比消耗',
        total: '合计',
        itemWater: '物料水分',
      },
      btn: {
        materialRatioQuery: '物料配比查询',
      },
      title: {
        title: '物料平衡明细',
        itemBalance: '物料配比',
      },
    }
  },
}
