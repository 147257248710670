var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "snows-opts" },
    [
      _vm._t("left"),
      _vm.isJudgePer
        ? [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "has",
                    rawName: "v-has",
                    value: _vm.addPerCode,
                    expression: "addPerCode",
                  },
                ],
                attrs: { size: "small", type: "primary", icon: "el-icon-plus" },
                on: { click: _vm.add },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.addText === "新建" ? "新建" : _vm.addText) +
                    "\n    "
                ),
              ]
            ),
          ]
        : [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary", icon: "el-icon-plus" },
                on: { click: _vm.add },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.addText === "新建" ? "新建" : _vm.addText) +
                    "\n    "
                ),
              ]
            ),
          ],
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }