import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";

export const getList = () => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/broadcast/list",
    method: "get"
  });
};

export const getPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/broadcast/page",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};

export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/broadcast/detail",
    method: "get",
    params: {
      id,
    },
  });
};

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/broadcast/remove",
    method: "post",
    params: {
      ids,
    },
  });
};

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/broadcast/submit",
    method: "post",
    data: row,
  });
};

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/broadcast/submit",
    method: "post",
    data: row,
  });
};

export const play = (ids, text) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/broadcast/play",
    method: 'post',
    params: {
      ids,
      text
    },
  })
}
export const submitMsgBroadcast = (ids, MsgTemplateId) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/broadcast/submitMsgBroadcast",
    method: "post",
    params: {
      ids,
      MsgTemplateId
    },
  });
};

export const selectMsgBroadcast = (MsgTemplateId) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/broadcast/selectMsgBroadcast",
    method: "post",
    params: {
      MsgTemplateId
    },
  });
};
