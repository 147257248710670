import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_BATCH_}/realTime/list`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_BATCH_}/realTime/detail`,
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_BATCH_}/batch/delete`,
    method: 'post',
    params: {
      ids,
    }
  })
}

export const saveOrUpdate = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_BATCH_}/batch/saveOrUpdate`,
    method: 'post',
    data: row
  })
}



