import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 获取业务字典
export const dictionaryBiz = (code) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dict-biz/dictionary?code=` + code,
    method: 'get'
  })
}

// 数据填报列表
export const rpTaskAttributeList = (current, size, dataStatus,templateCode, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rpTaskAttribute/summaryClassification`,
    method: 'get',
    params: {
      current,
      size,
      dataStatus,
      templateCode,
      ...params
    }
  })
}

// 数据填报详情-模板
export const rpTaskAttributeDetail = (id,type) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rpTaskAttribute/detail?id=` + id + "&type=" + type +'',
    method: 'get'
  })
}

// 数据填报详情-报表
export const rpTaskAttributeDetailReport = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/report/detail?id=` + id,
    method: 'get'
  })
}

// 数据填报详情
export const rpTaskAttributeView = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rpTaskAttribute/view?id=` + id,
    method: 'get'
  })
}

// 数据填报保存
export const fillingOrSummarizing = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rpTaskAttribute/fillingOrSummarizing`,
    method: 'post',
    data
  })
}

// 数据填报提交
export const rpTaskAttributeSubmit = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rpTaskAttribute/submit`,
    method: 'post',
    data
  })
}

// 数据填报汇总
export const rpTaskAttributeReportClick = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rpTaskAttribute/reportClick`,
    method: 'post',
    data
  })
}

// 数据填报保存
export const rpTaskAttributeSaveSummarize = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rpTaskAttribute/saveSummarize`,
    method: 'post',
    data
  })
}

// 汇总清单
export const summaryDetailsList = (current,size,captureFormId,captureTaskId,unitId,templateCode,templateClassify,params) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rpTaskAttribute/summaryDetails`,
    method: 'get',
    params: {
      current,
      size,
      captureFormId,
      captureTaskId,
      unitId,
      templateCode,
      templateClassify,
      ...params
    }
  })
}
// 汇总清单
export const summaryAllList = (unitId, captureTaskId) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rpTaskAttribute/summaryAll`,
    method: 'get',
    params: {
      unitId,
      captureTaskId
    }
  })
}

// 数据填报保存
export const getTemplateTree = (acquisitionTaskType) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/template/getTemplateTree?acquisitionTaskType=` + acquisitionTaskType,
    method: 'get',
  })
}
