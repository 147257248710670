import request from '@/router/axios';
import ServerNameEnum from "@/util/ServerNameEnum";

// IM通讯获取用户
export const getImUser = (current, size, params) => {
  return request({
    url:  ServerNameEnum.SERVER_USER_+'/page',
    method: 'GET',
    params: {
      ...params,
      current,
      size,
    }
  })
}
// IM通讯获取消息
export const getIMReply = (current, size, params) => {
  return request({
    url:  ServerNameEnum.SERVER_USER_+'/imReply/imReplyService',
    method: 'GET',
    params: {
      ...params,
      current,
      size,
    }
  })
}



//删除聊天记录
export function deleteChatRecord(id) {
  return request({
    url:  ServerNameEnum.SERVER_USER_+`/imReply/deleteChatRecord/${id}`,
    method: 'DELETE'
  })
}

//移除
export function relocation(id) {
  return request({
    url: ServerNameEnum.SERVER_USER_+`/imReply/relocation/${id}`,
    method: 'DELETE'
  })
}
