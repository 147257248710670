import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/topmenu/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/topmenu/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/topmenu/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/topmenu/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/topmenu/submit',
    method: 'post',
    data: row
  })
}

export const grantTree = () => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/menu/grant-top-tree',
    method: 'get',
  })
}

export const getTopTree = (topMenuIds) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/menu/top-tree-keys',
    method: 'get',
    params: {
      topMenuIds,
    }
  })
}

export const grant = (topMenuIds, menuIds) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/topmenu/grant',
    method: 'post',
    data: {
      topMenuIds,
      menuIds,
    }
  })
}

export const getRoutes = (topMenuId) => request({
  url: ServerNameEnum.SERVER_SYSTEM_+'/menu/routes',
  method: 'get',
  params: {
    topMenuId,
  }
});
