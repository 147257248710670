import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 获取编码
export const getCode = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/sinoma-hse-prj/stpost/getCode',
      method: 'get',
      params
    })
}
// 新增或编辑岗位工种配置
export const jobSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/sinoma-hse-prj/stpost/submit',
    method: 'post',
    data
  })
}

// 岗位工种配置详情
export const jobDetail = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/sinoma-hse-prj/stpost/detail',
      method: 'get',
      params
    })
}

// 岗位工种配置列表
export const jobList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/sinoma-hse-prj/stpost/typeTree',
    method: 'get',
    params
  })
}

// 删除岗位工种配置
export const jobRemove = (data) => {
    return request({
      url: ServerNameEnum.SERVER_FX + `/sinoma-hse-prj/stpost/remove?ids=${data.ids}`,
      method: 'post',
      data
    })
  }



  //证书表
//   获取证书列表
  export const cateList = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/sinoma-hse-prj/stpostcertificate/list',
      method: 'get',
      params
    })
  }
  // 保存证书表
  export const cateSubmit = (data) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/sinoma-hse-prj/stpostcertificate/submit',
      method: 'post',
      data
    })
  }
// 批量保存
  export const cateSubmitBatch = (data) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/sinoma-hse-prj/stpostcertificate/submitBatch',
      method: 'post',
      data
    })
  }


  // 证书详情
  export const cateDetail = (params) => {
      return request({
        url: ServerNameEnum.SERVER_FX + '/sinoma-hse-prj/stpostcertificate/detail',
        method: 'get',
        params
      })
    }


  // 删除证书列表
  export const cateRemove = (data) => {
      return request({
        url: ServerNameEnum.SERVER_FX + `/sinoma-hse-prj/stpostcertificate/remove?ids=${data.ids}`,
        method: 'post',
        data
      })
    }

// 培训课程表
// 获取课程表
export const stpostList = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/sinoma-hse-prj/stposteducate/list',
      method: 'get',
      params
    })
  }
  // 保存课程表
  export const stpostSubmit = (data) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/sinoma-hse-prj/stposteducate/submit',
      method: 'post',
      data
    })
  }
  // 批量保存
  export const stpostSubmitBatch = (data) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/sinoma-hse-prj/stposteducate/submitBatch',
      method: 'post',
      data
    })
  }


  // 课程详情
  export const stpostDetail = (params) => {
      return request({
        url: ServerNameEnum.SERVER_FX + '/sinoma-hse-prj/stposteducate/detail',
        method: 'get',
        params
      })
    }


  // 删除课程表
  export const stpostRemove = (data) => {
      return request({
        url: ServerNameEnum.SERVER_FX + `/sinoma-hse-prj/stposteducate/remove?ids=${data.ids}`,
        method: 'post',
        data
      })
    }


// 风险告知卡表
// 获取风险告知卡表
export const cardList = (params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/sinoma-hse-prj/stpostcard/list',
      method: 'get',
      params
    })
  }
  // 保存风险告知卡
  export const cardSubmit = (data) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/sinoma-hse-prj/stpostcard/submit',
      method: 'post',
      data
    })
  }

  // 风险告知卡详情
  export const cardDetail = (params) => {
      return request({
        url: ServerNameEnum.SERVER_FX + '/sinoma-hse-prj/stpostcard/detail',
        method: 'get',
        params
      })
    }


  // 删除风险告知卡
  export const cardRemove = (data) => {
      return request({
        url: ServerNameEnum.SERVER_FX + `/sinoma-hse-prj/stpostcard/remove?ids=${data.ids}`,
        method: 'post',
        data
      })
    }
