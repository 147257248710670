import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getPage = (current, size, params)=>{
  return request({
    url:ServerNameEnum.SERVER_FX+'/recept/page',
    method:'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const getReceptHistory = (current, size, params)=>{
  return request({
    url:ServerNameEnum.SERVER_FX+'/recept/getReceptHistory',
    method:'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const submit = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX +'/recept/submit',
    method: 'post',
    data:params
  })
}


export const detail = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_FX+'/recept/detail',
    method:'get',
    params: params
  })
}

export const remove = (ids)=>{
  return request({
    url:ServerNameEnum.SERVER_FX+'/recept/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const wastePage = (current, size, params)=>{
  return request({
    url:ServerNameEnum.SERVER_FX+'/recept/wastePage',
    method:'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}