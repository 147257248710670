<template>
  <div class="flex-container syButton">
    <el-button-group v-if="isShow(item)" :key="index" v-for="(item,index) in buttonList"  class="item padd-l-10 padd-r-10">
     <slot v-if="item.slot" :name="item.slot" v-bind="item"></slot>
      <el-button v-else  :size="item.size || size" :loading="item.loading"  :key="index"  :type="item.type || 'text'"  class="margin-r-5"  @click="btnHandle(item)">
        <i :class="item.icon" v-if="item.icon"></i>
        <svg-icon v-bind="item" v-if="item.CustomIcon" className="svg-icon-box" :icon-class="item.CustomIcon" size="16"></svg-icon>
        {{ labelHandle(item) }}</el-button>
    </el-button-group>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'syButton',
  props:{
    size:{
      type:String,
      default:()=>'mini'
    },
    row:Object,
    index:Number,
    buttonList:Array,
  },
  data() {
    return {
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  methods: {
    labelHandle(item){
      return typeof item.label == 'function' ?item.label(this.row) :item.label
    },
    btnHandle(item){
      if(item.handle){
        item.handle(this.row,this.index)
      }
    },
    isShow(item) {
      if (!item.show) return true
      return typeof item.show === 'function' &&  item.show(this.row)
    }
  }
}
</script>
<style lang="scss">
.syButton{
  .el-button{
    padding: 6.5px 15px;
  }
  .el-button--text{
    padding: 0;
  }
  .CustomIcon{
    vertical-align: bottom;
  }
  .padd-l-10{
    padding-left: 10px;
  }
  .padd-r-10{
    padding-right: 10px;
  }
}

</style>
