import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const CONTROLLER_NAME = "/jobinfo/";
export const JOBINFOCATE = '/jobInfoCate/'

/**
 *  获取任务列表
 */
export const pageList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_JOB_}${CONTROLLER_NAME}pageList`,
    method: 'post',
    params:{
      ...params,
      start:(current - 1) * size,
      length:size
    }
  })
}

export const login = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_JOB_}/login`,
    method: 'post',
    params:params
  })
}
export const update = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_JOB_}${CONTROLLER_NAME}update`,
    method: "post",
    data: row
  });
};

export const submit = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_JOB_}${CONTROLLER_NAME}add`,
    method: "post",
    data: row
  });
};

export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_JOB_}${CONTROLLER_NAME}loadById`,
    method: "post",
    params: {
      id,
    },
  });
};
export const remove = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_JOB_}${CONTROLLER_NAME}remove`,
    method: "post",
    params: {
      id:id
    },
  });
};

export const stop = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_JOB_}${CONTROLLER_NAME}stop`,
    method: "post",
    params: {
      id,
    },
  });
};

export const start = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_JOB_}${CONTROLLER_NAME}start`,
    method: "post",
    params: {
      id,
    },
  });
};

export const trigger = (id,executorParam) => {
  return request({
    url: `${ServerNameEnum.SERVER_JOB_}${CONTROLLER_NAME}trigger`,
    method: "post",
    params: {
      id,
      executorParam
    },
  });
};


export const nextTriggerTime = (cron) => {
  return request({
    url: `${ServerNameEnum.SERVER_JOB_}${CONTROLLER_NAME}nextTriggerTime`,
    method: "post",
    params: {
      cron
    },
  });
};

export const tree = ()=>{
  return request({
    url: `${ServerNameEnum.SERVER_JOB_}${JOBINFOCATE}tree`,
  })
}

export const jobInfoTree = ()=>{
  return request({
    url: `${ServerNameEnum.SERVER_JOB_}${JOBINFOCATE}jobInfoTree`,
  })
}

export const addCate = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_JOB_}${JOBINFOCATE}add`,
    method: "post",
    data: row,
  });
};
export const updateCate = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_JOB_}${JOBINFOCATE}update`,
    method: "post",
    data: row,
  });
};
export const deleteCate = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_JOB_}${JOBINFOCATE}delete`,
    method: "post",
    data: row,
  });
};
