var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tableContainer" },
    [
      !_vm.refreshTable
        ? _c(
            "el-table",
            _vm._g(
              _vm._b(
                {
                  ref: "SNOWSTable",
                  staticClass: "SNOWS-common-table",
                  attrs: {
                    data: _vm.data,
                    height: _vm.height,
                    "element-loading-text": _vm.$t("common.loadingText"),
                    border: _vm.border,
                    "row-style": { height: "50px" },
                    "header-row-style": { height: "50px" },
                  },
                },
                "el-table",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            ),
            [
              _vm.hasC
                ? _c("el-table-column", {
                    attrs: {
                      prop: "selection",
                      type: "selection",
                      width: "50",
                      align: "center",
                      selectable: _vm.checkSelectable,
                    },
                  })
                : _vm._e(),
              _vm.hasNO
                ? _c("el-table-column", {
                    attrs: {
                      prop: "index",
                      type: "index",
                      width: "50",
                      label: "序号",
                      fixed: _vm.hasNOFixed,
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm.customColumn
                ? _c("snows-table-column", {
                    attrs: { columns: _vm.columns, columnList: _vm.columnList },
                  })
                : [_vm._t("default")],
              _vm.customColumn
                ? _c(
                    "el-table-column",
                    { attrs: { align: "center", fixed: "right", width: "40" } },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: { content: "表头设置", placement: "top" },
                            },
                            [
                              _c("el-link", {
                                attrs: {
                                  icon: "icon-ym icon-ym-shezhi SNOWS-common-head-icon",
                                  underline: false,
                                },
                                on: { click: _vm.showDrawer },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "template",
                { slot: "empty" },
                [
                  _c("el-empty", {
                    attrs: { description: "暂无数据", "image-size": 120 },
                  }),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.drawerVisible
        ? _c("ColumnSettings", {
            ref: "columnSettings",
            attrs: {
              defaultColumns: _vm.defaultColumns,
              columnList: _vm.columnList,
            },
            on: { setColumn: _vm.setColumn },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }