var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("avue-form", {
        ref: "form",
        staticStyle: { padding: "10px" },
        attrs: {
          option: _vm.option,
          "upload-after": _vm.uploadAfter,
          "upload-preview": _vm.uploadPreview,
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.globalSlotList, function (item) {
              return {
                key: item.prop,
                fn: function (scope) {
                  return [
                    item.read === true
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  value: _vm.getGlobalItemVal(item.prop),
                                  readonly: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.changeGlobalVal(
                                      item.prop,
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                _c("template", { slot: "suffix" }, [
                                  _c("i", {
                                    staticClass: "iconfont icon-zhongyingwen",
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showGlobalBox(scope.column)
                                      },
                                    },
                                  }),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  value: _vm.getGlobalItemVal(item.prop),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.changeGlobalVal(
                                      item.prop,
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                _c("template", { slot: "suffix" }, [
                                  _c("i", {
                                    staticClass: "iconfont icon-zhongyingwen",
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showGlobalBox(scope.column)
                                      },
                                    },
                                  }),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                  ]
                },
              }
            }),
            {
              key: "jobCron",
              fn: function ({ disabled, size }) {
                return [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom",
                        trigger: "click",
                        width: "500",
                      },
                      model: {
                        value: _vm.cronPopover,
                        callback: function ($$v) {
                          _vm.cronPopover = $$v
                        },
                        expression: "cronPopover",
                      },
                    },
                    [
                      _c("vcrontab", {
                        attrs: { expression: _vm.dataForm.jobCron },
                        on: { fill: _vm.crontabFill, hide: _vm.handleHide },
                      }),
                      _c("el-input", {
                        attrs: {
                          slot: "reference",
                          placeholder: _vm.$t(
                            "cip.plat.xxljob.task.field.jobCron"
                          ),
                        },
                        slot: "reference",
                        model: {
                          value: _vm.dataForm.jobCron,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "jobCron", $$v)
                          },
                          expression: "dataForm.jobCron",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.dataForm,
          callback: function ($$v) {
            _vm.dataForm = $$v
          },
          expression: "dataForm",
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.globalBox,
            "append-to-body": "",
            width: "345px",
            title: this.$t(
              "cip.cmn.components.internationalizedData.title.internationalizedDataTitle"
            ),
          },
          on: {
            "update:visible": function ($event) {
              _vm.globalBox = $event
            },
            close: _vm.globalBoxClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "globalForm",
              attrs: { model: _vm.globalForm, "label-width": "70px" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    rules: [
                      {
                        required: this.language === "en",
                        message: this.$t(
                          "cip.cmn.components.internationalizedData.msg.required"
                        ),
                        trigger: "submit",
                      },
                      {
                        min: 0,
                        max: 200,
                        message: this.$t("cip.cmn.rule.nameLength20Warning"),
                        trigger: "blur",
                      },
                    ],
                    label: this.$t(
                      "cip.cmn.components.internationalizedData.field.en"
                    ),
                    prop: "en",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.globalForm.en,
                      callback: function ($$v) {
                        _vm.$set(_vm.globalForm, "en", $$v)
                      },
                      expression: "globalForm.en",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    rules: [
                      {
                        required: this.language === "zh",
                        message: this.$t(
                          "cip.cmn.components.internationalizedData.msg.required"
                        ),
                        trigger: "submit",
                      },
                      {
                        min: 0,
                        max: 200,
                        message: this.$t("cip.cmn.rule.nameLength20Warning"),
                        trigger: "blur",
                      },
                    ],
                    label: this.$t(
                      "cip.cmn.components.internationalizedData.field.zh"
                    ),
                    prop: "zh",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.globalForm.zh,
                      callback: function ($$v) {
                        _vm.$set(_vm.globalForm, "zh", $$v)
                      },
                      expression: "globalForm.zh",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.globalBox = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitGlobal },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.defBtn")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("fileView", { ref: "fileView", attrs: { title: "附件预览" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }