import httpService from "@/router/axios";
// 系统管理-安全作业类型配置   分页
export const $_getPageList = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/safetyworktype/page",
    params,
  });
};

// 系统管理-安全作业类型配置   分页
export const $_getSafetyworktypeList2 = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/safetyworktype/list2",
    method: "get",
    params,
  });
};

// 系统管理-安全作业类型配置   新增
export const $_addSaveList = (row) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/safetyworktype/save",
    method: "post",
    data: row,
  });
};

// 系统管理-安全作业类型配置   修改
export const $_updateData = (row) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/safetyworktype/update",
    method: "post",
    data: row,
  });
};

// 系统管理-安全作业类型配置   详情
export const $_getEdit = (id) => {
  return httpService({
    url: `/api/ivystar-wisdom-security/safetyworktype/detail/${id}`,
    method: "get",
  });
};

// 作业许可   新增
export const $_addSaveWorkP = (row) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/workoperationlicense/save",
    method: "post",
    data: row,
  });
};

//   编码生成
export const $_getGenerateCode = (params) => {
  return httpService({
    url: `/api/ivystar-wisdom-security/workoperationlicense/generateCode`,
    method: "get",
    params,
  });
};

// 获取流程列表
export const $_getDeploymentList = (params) => {
  return httpService({
    url: "/api/ivystar-workflow/design/deployment/list",
    params,
  });
};
