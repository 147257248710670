/*
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-09-03 17:17:11
 */
import request from '@/router/axios';

export const pageList = (row) => {
  return request({
    url: '/api/sinoma-maas-center/tags/pageList',
    method: 'post',
    data: row
  })
}
export const getById = (row) => {
  return request({
    url: '/api/sinoma-maas-center/tags/getById',
    method: 'post',
    data: row
  })
}
export const add = (row) => {
  return request({
    url: '/api/sinoma-maas-center/tags/add',
    method: 'post',
    data: row
  })
}

export const remove = (row) => {
  return request({
    url: '/api/sinoma-maas-center/tags/del',
    method: 'post',
    data: row
  })
}
export const edit = (row) => {
  return request({
    url: '/api/sinoma-maas-center/tags/edit',
    method: 'post',
    data: row
  })
}

