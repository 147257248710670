import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";

const CONTROLLER_NAME = "/mediumscreen";

/**
 * 分页查询
 * @param current
 * @param size
 * @param params
 * @returns {*}
 */
export const getList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/page`,
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};
/**
 * 新增
 * @param data
 * @returns {*}
 */
export const add= (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}${CONTROLLER_NAME}/submit`,
    method: 'post',
    data: row
  })
}

/**
 * 回显数据
 * @param id
 * @returns {*}
 */
export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/detail`,
    method: "get",
    params: {
      id,
    },
  });
};

/**
 * 删除
 * @param ids
 * @returns {*}
 */
export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/remove`,
    method: 'post',
    params: {
      ids
    }
  })
};

export const getRoleSetting = (screenId) => request({
  url: ServerNameEnum.SERVER_SYSTEM_ + '/screenSettings/list',
  method: 'get',
  params: {
    screenId: screenId
  }
});

export const submitRoleSetting = (screenId, setIds) => request({
  url: ServerNameEnum.SERVER_SYSTEM_ + '/screenSettings/submit',
  method: 'post',
  params: {
    screenId: screenId,
    setIds: setIds
  }
});