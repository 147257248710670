import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';///api/sinoma-hse-prj

export const list = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/dangerousprojectset/list',
    method: 'get',
    params
  })
}

export const page = (current,size,params) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/dangerousprojectset/page',
      method: 'get',
      params:{
        current,
        size,
        ...params
      }
    })
  }

export const submitRemark = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/dangerousprojectset/submitRemark',
    method: 'post',
    data
  })
}

export const submitType = (data) => {
    return request({
      url: ServerNameEnum.SERVER_FX + '/dangerousprojectset/submitType',
      method: 'post',
      data
    })
  }

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/dangerousprojectset/remove',
    method: 'post',
    params: {ids}
  })
}