import Layout from '@/page/index/'
//生产
export default [{
  path: '/resource',
  component: Layout,
  children: [
    {
      path: '/resource/download',
      name: '附件下载',
      meta: {
        i18n: 'attach'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/resource/download.vue'),
    },
    {
      path: '/resource/attach',
      name: '附件管理',
      meta: {
        i18n: 'attach'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/resource/attach.vue'),
    },
    {
      path: '/resource/oss',
      name: '对象存储',
      meta: {
        i18n: 'oss'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/resource/oss.vue'),
    },

    {
      path: '/resource/sms',
      name: '短信配置',
      meta: {
        i18n: 'sms'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/resource/sms.vue'),
    },

    {
      path: '/resource/folder',
      name: '文件夹管理',
      meta: {
        i18n: 'folderManage'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/resource/folder.vue'),
    },
    {
      path: '/folder/edit',
      name: '文件夹新增',
      component: () =>
        import('@/views/resource/folderEdit'),
      meta: {
        i18n: 'folderManage'
      },
    }]
}]
