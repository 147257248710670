import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_MODELEVENT = "/modelEvent";

export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_MODELEVENT+'/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_MODELEVENT+'/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_MODELEVENT+'/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_MODELEVENT+'/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_MODELEVENT+'/update',
    method: 'post',
    data: row
  })
}

export const submit = (row) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_MODELEVENT+'/submit',
    method: 'post',
    data: row
  })
}
export const batchModelEventStatus = (ids,isEnable) => {
  return request({
    url: ServerNameEnum.SERVER_CPS_+CONTROLLER_MODELEVENT+'/batchModelEventStatus',
    method: 'get',
    params: {
      ids,
      isEnable
    }
  })
}

