import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";
import { getDeptTree } from "@/api/system/dept";

/** 项目列表 */
export const treePro = params => {
  return request({
    url: ServerNameEnum.SERVER_FX + "/dbtcomon/treepro",
    method: "post",
    params
  });
};

/** 项目树 */
export const treeToProject = (params, callback) => {
  var parentId = params.parentId;
  getDeptTree("", "5,2", parentId).then(res => {
    var data = [];
    if (res.data.success) {
      data = res.data.data;
      // let filterTree = function (tree) {
      //   return tree
      //     .filter(node => {
      //       // 如果 deptCategory 为 5，保留节点
      //       if (node.deptCategory === 5) return true;
      //       // 如果 deptCategory 为 2 且没有子节点，过滤掉节点
      //       //if (node.deptCategory === "2" && !node.hasChildren) return false;
      //       // 如果 deptCategory 为 2 且有子节点，递归检查子节点
      //       if ( node.deptCategory === 2 && node.children && node.children.length > 0 )
      //       {
      //         // 递归调用 filterTree 并赋值给子节点
      //         node.children = filterTree(node.children);
      //         // 如果子节点数组不为空，保留当前节点
      //         return node.children.length > 0;
      //       }
      //       // 默认情况下过滤掉其他节点
      //       return false;
      //     })
      //     .map(node => {
      //       // 清理没有用到的 hasChildren 属性
      //       delete node.hasChildren;
      //       return node;
      //     });
      // };
      // let filteredTree = filterTree(data);
      if (callback) callback.call(this, data);
    }
  });
};
