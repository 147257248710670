import request from '@/router/axios'
import ServerNameEnum from '@/util/ServerNameEnum';
const prefix = ServerNameEnum.SERVER_WORK_FLOW_+'/design/model/scope'

export const getList = (params) => {
  return request({
    url: `${prefix}/list`,
    method: 'get',
    params
  })
}

export const submit = (row) => {
  return request({
    url: `${prefix}/submit`,
    method: 'post',
    data: row
  })
}
