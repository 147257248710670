import httpService from "@/router/axios";
// 系统管理-隐患流程配置   分页
export const $_getPageList = (params) => {
    return httpService({
        url: "/api/ivystar-wisdom-security/accidentworkflow/page",
        params,
    });
};

// 系统管理-隐患流程配置   新增
export const $_addSaveList = (row) => {
    return httpService({
        url: "/api/ivystar-wisdom-security/accidentworkflow/save",
        method: "post",
        data: row,
    });
};

// 系统管理-隐患流程配置   修改
export const $_updateData = (row) => {
    return httpService({
        url: "/api/ivystar-wisdom-security/accidentworkflow/update",
        method: "post",
        data: row,
    });
};

// 系统管理-隐患流程配置   详情
export const $_getEdit = (id) => {
    return httpService({
        url: `/api/ivystar-wisdom-security/accidentworkflow/detail?id=${id}`,
        method: "get",
    });
};

