import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/file/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getNoPageList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/attach/listNoPage',
    method: 'get',
    params: {
      ...params,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/attach/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/attach/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/attach/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/attach/submit',
    method: 'post',
    data: row
  })
}
export const updateRows = (rows) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/attach/updateRows',
    method: 'post',
    data: rows
  })
}

export const pageByQuery = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/attach/pageByQuery',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const taskInfo = (identifier) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/file/'+identifier,
    method: 'get'
  })
}
export const preSignUrl = (identifier,partNumber) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+`/file/${identifier}/${partNumber}`,
    method: 'get'
  })
}
export const generateCover = (id) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+`/file/generateCover/${id}`,
    method: 'get'
  })
}
export const initTask = (data) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/file/initTask',
    method: 'post',
    data
  })
}

export const merge = (identifier) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/file/merge/'+identifier,
    method: 'post'
  })
}
export const download = (id) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_+'/file/download/'+id,
    method: 'get',
    responseType: 'blob'
  })
}
