import httpService from '@/router/axios';
// 系统管理-风险等级公式接口   分页
export const $_getPageList = (params) => {
    return httpService({
        url: '/api/ivystar-wisdom-security/risklevel/page',
        method: 'get',
        params
    })
}

// 系统管理-风险等级公式接口   新增
export const $_addSaveList = (row) => {
    return httpService({
        url: '/api/ivystar-wisdom-security/risklevel/save',
        method: 'post',
        data: row
    })
}

// 系统管理-风险等级公式接口   修改
export const $_updateData = (row) => {
    return httpService({
        url: '/api/ivystar-wisdom-security/risklevel/update',
        method: 'post',
        data: row
    })
}

// 系统管理-风险等级公式接口   删除
export const $_removeData = (params) => {
    return httpService({
        url: '/api/ivystar-wisdom-security/risklevel/remove',
        method: 'post',
        params
    })
}
