var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      attrs: {
        effect: "dark",
        content: _vm.isFullscreen
          ? _vm.$t("common.outFullScreen")
          : _vm.$t("common.fullScreen"),
        placement: "top",
      },
    },
    [
      _c("el-link", {
        attrs: {
          icon: `icon-ym ${
            _vm.isFullscreen ? "icon-ym-compress-screen" : "icon-ym-full-screen"
          } SNOWS-common-head-icon`,
          underline: false,
        },
        on: {
          click: function ($event) {
            return _vm.click()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }