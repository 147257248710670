import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_NAME = "/index/theme";

/**
 * 查询右侧列表
 * @param params
 * @returns {*}
 */
export const selectPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/page`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

/**
 * 删除
 * @param ids  删除的id集合
 * @returns AxiosPromise
 */
export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/lineRemove`,
    method: 'post',
    params: {
      ids
    }
  })
}

/**
 * 保存基础信息
 * @param data  需要保存的数据
 * @returns AxiosPromise
 */
export const saveOrUpdate = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/saveTheme`,
    method: 'post',
    data
  })
}

/**
 * 保存模型配置
 * @param data  需要保存的数据
 * @returns AxiosPromise
 */
export const saveConfig = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/saveConfig`,
    method: 'post',
    data
  })
}
/**
 * 详情
 * @param params  查询条件
 * @returns AxiosPromise
 */
export const detail = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/detail`,
    method: 'get',
    params:{
      ...params
    }
  })
}
/**
 * 查看宽表
 * @param params  查询条件
 * @returns AxiosPromise
 */
export const wideTable = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/wideTable`,
    method: 'get',
    params:{
      ...params
    }
  })
}
/**
 * 创建宽表视图
 * @param data  需要保存的数据
 * @returns AxiosPromise
 */
export const createdWideTable = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_INDEX_}${CONTROLLER_NAME}/createdWideTable`,
    method: 'post',
    data
  })
}

