import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

/*
* 状态下拉
* */
export const kafaColStaus=()=>{
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/dict-biz/dictionary?code=datacenter_kafka_col_status',
    method: 'get',

  })

}

/*
 * -kafka数据采集-主源字段类型

 * */
export const kafaMainfieldTypes=()=>{
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/dict-biz/dictionary?code=datacenter_kafka_mainfield_type',
    method: 'get',

  })

}

/*
 *  数据中台-kafka数据采集-子源字段类型

 * */
export const kafaChildfieldType=()=>{
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/dict-biz/dictionary?code=datacenter_kafka_childfield_type',
    method: 'get',

  })

}

export  const saveKafkaData=(data)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/kafka_data_collect/save_kafka_data_collect',
    method:'post',
    data
  })
}

export  const updateKafkaData=(data)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/kafka_data_collect/update_Kafka_data_collect',
    method:'post',
    data
  })
}

export  const delectKafkaData=(id)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/kafka_data_collect/delete_Kafka_data_collect?id='+id,
    method:'get',

  })
}

//kafka采集信息编辑回显信息
export const getKafkaData= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/kafka_data_collect/get_kafka_data_collect_by_id',
    method: 'get',
    params
  })
}


export  const pageKafkaDataCollect=(data)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/kafka_data_collect/page_kafka_data_collect',
    method:'post',
    data
  })
}

export const startKafka= (id) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/kafka_data_collect_add/start?id='+id,
    method: 'get'
  })
}

export const stopKafka= (id) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/kafka_data_collect_add/stop?id='+id,
    method: 'get'
  })
}
