import Layout from "@/page/index/";

export default [
  //环境保护
  {
    path: "/beforeclass",
    component: Layout,
    name: "班前会",
    children: [
      {
        path: "/beforeclass/edit",
        name: "班前会",
        component: () => import('@/views/beforeclass/edit'),
        meta: {
          i18n: '班前会'
        }
      },
    ],
  },
];
