import httpService from "@/router/axios";

export const cameraPage = (params) => {
  return httpService({
    url: '/api/sinoma-sync/camera/page',
    method: 'get',
     params,
  })
}
export const camerabasePage = (params) => {
  return httpService({
    url: '/api/sinoma-sync/camerabase/page?excludeBound=true',
    method: 'get',
     params,
  })
}
export const cameraSave = (data) => {
  return httpService({
    url: '/api/sinoma-sync/camera/save',
    method: 'post',
     data,
  })
}
export const cameraUpdate = (data) => {
  return httpService({
    url: '/api/sinoma-sync/camera/update',
    method: 'post',
     data,
  })
}
export const remove = (data) => {
  return httpService({
    url: '/api/sinoma-sync/camera/remove',
    method: 'post',
     data,
  })
}
