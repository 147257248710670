import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
const CONTROLLER_NAME = "msgTemplateParam";

export const getInfoList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/msgTemplateParam/selectList`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const removeLine = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/msgTemplateParam/remove`,
    method: 'post',
    params: {
      ids,
    }
  })
}
export const saveOrUpdate = (rows) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/msgTemplateParam/saveOrUpdate`,
    method: 'post',
    data: rows
  })
}
