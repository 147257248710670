<template>
  <div ref="gridLayOutDiv" id="gridLayout">
    <!--如果需要列表高度根据多标签的显示/隐藏动态修改高度，还需要同时修改各个功能页面对应的curd高度，否则样式会错乱-->
    <!--:class="tagsView?'avueCrud':'avueCrudNoTag'"-->
    <avue-crud
      v-if="isShow"
      ref="grid"
      :key="renderKey"
      :class="tagsView?'avueCrud':'avueCrudNoTag'"
      v-model="currentRow"
      :before-open="beforeOpen"
      :disabled ="disabled"
      :cell-class-name="cellClassName"
      :cell-style="cellStyle"
      :data="tableData"
      :header-cell-class-name="headerBorderColor"
      :option="option"
      :page.sync="page"
      :span-method="spanMethod"
      :table-loading="tableLoading"
      size="mini"
      @clearSelection="selectionClear"
      @row-click="rowClick"
      @selection-change="selectionChange"
      @select="selection"
      @current-change="currentChange"
      @current-row-change="currentRowChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @tree-load="treeLoad"
      @cell-click="cellClick"
      @row-update="rowUpdate"
      @row-save="rowSave"
      @cell-dblclick="cellDblclick"
      @cell-mouse-enter="cellMouseEnter"
      @row-dblclick="rowDblclick"
      @header-dragend="headerDragend"
    >
      <template slot="picList" slot-scope="scope">
        <el-image
          v-for="(item,index) of scope.row.picList"
          :preview-src-list="[item.picPath]"
          :src="item.picPath"
          style="width: 70px;height: 70px;margin: 5px">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </template>

      <template
          v-if="tableOptions.distributionLinklabel"
          v-for="(item,index) in tableOptions.distributionLinklabel"
          :slot="item"
          slot-scope="scope">
        <el-link :style="{ color: getColor(index) ,textDecoration: 'underline'}" style="text-decoration: none;" @click="rowDistribution(scope.row,item)">
          {{ scope.row[item] }}
        </el-link>
      </template>
      <template
        v-if="tableOptions.linklabel "
        :slot="tableOptions.linklabel"
        slot-scope="scope">
          <el-link :style="{color: themeColor}" style="text-decoration: none;" @click="rowDetail(scope.row)">
            {{ scope.row[tableOptions.linklabel] }}
          </el-link>
      </template>
      <template slot="expand" slot-scope="scope">
        <slot :data="scope" name="expand"></slot>
      </template>
      <template slot="menu" slot-scope="{ row, index }">
        <span v-for="(rowBtn, idx) in gridRowBtn" :key="idx" style="margin:0 3px;">
          <el-dropdown
            v-if="rowBtn.dropList"
            trigger="click"
            @command="getMoreBtn">
            <el-button
              size="small"
              type="text"
              :disabled="rowBtn.disabled"
              @click="btnRowClick(rowBtn, row, index)">
              {{
                rowBtn.label
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, indet) of rowBtn.dropList"
                :key="indet"
                :command="item"
              >{{ item.label }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            v-if="!rowBtn.dropList && (!rowBtn.cellEdit || (!rowBtn.cellBtn && !row.$cellEdit) || (rowBtn.cellBtn && row.$cellEdit))"
            size="small"
            type="text"
            :disabled="rowBtn.disabled"
            @click.stop="btnRowClick(rowBtn, row, index)"
          >
            {{ rowBtn.label }}
          </el-button>
        </span>
        <slot :row="row" :index="index" name="customBtn"></slot>
      </template>
      <template
        v-for="item in tableOptions.column"
        :slot="true === item.slot ? item.prop : ''"
        slot-scope="scope"
      >
        <slot
          v-if="item.slot"
          :index="scope.index"
          :name="item.prop"
          :row="scope.row"
          :scope="scope"
        />
      </template>
      <!--国际化表单输入框-->
      <template
        v-for="item in globalSlotList"
        :slot="item.prop + 'Form'"
        slot-scope="scope"
      >
        <el-input
          :value="getGlobalItemVal(item.prop)"
          @input="changeGlobalVal(item.prop, $event)"
        >
          <template slot="suffix">
            <i
              class="iconfont icon-zhongyingwen"
              style="cursor: pointer"
              @click="showGlobalBox(scope.column)"
            ></i>
          </template>
        </el-input>
      </template>
    </avue-crud>
    <el-dialog
      :visible.sync="globalBox"
      append-to-body
      :title="this.$t('cip.cmn.components.internationalizedData.title.internationalizedDataTitle')"
      width="345px"
      @close="globalBoxClose"
    >
      <el-form ref="globalForm" :model="globalForm" label-width="70px">
        <el-form-item
          :rules="[
            {
              required: this.language === 'en',
              message: this.$t('cip.cmn.components.internationalizedData.msg.required'),
              trigger: 'submit',
            },
          ]"
          :label="this.$t('cip.cmn.components.internationalizedData.field.en')"
          prop="en"
        >
          <el-input
            v-model="globalForm.en"
            autocomplete="off"
            clearable
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item
          :rules="[
            {
              required: this.language === 'zh',
              message: this.$t('cip.cmn.components.internationalizedData.msg.required'),
              trigger: 'submit',
            },
          ]"
          :label="this.$t('cip.cmn.components.internationalizedData.field.zh')"
          prop="zh"
        >
          <el-input
            v-model="globalForm.zh"
            autocomplete="off"
            clearable
            size="small"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="globalBox = false">{{$t('cip.cmn.btn.celBtn')}}</el-button>
        <el-button size="mini" type="primary" @click="submitGlobal"
        >{{$t('cip.cmn.btn.defBtn')}}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import request from '@/router/axios';
import {mapGetters} from "vuex";
import {isJSON} from "@/util/util";

export default {
  props: {
    tableOptions: {
      type: Object,
      default: () => ({
        tip: false,
        searchShow: false,
        card: false,
        border: true,
        index: false,
        viewBtn: false,
        addBtn: false,
        removeBtn: false,
        refreshBtn: false,
        searchBtn: false,
        columnBtn: false,
        searchShowBtn: false,
        emptyBtn: false,
        delBtn: false,
        editBtn: false,
        selection: true,
        filterBtn: false,
        header: true,
        dialogClickModal: false,
        lazy: false,
        addRowBtn: false,
        selectable: () => {
          return true
        },
        menu: true,
        indexWidth: 80,
        column: []
      })
    },
    tableData: {
      type: Array
    },
    dataTotal: {
      type: Number,
      default: 0
    },
    tableLoading: {
      type: Boolean,
      default: true
    },
    disabled:{
      type: Boolean,
      default: false
    },
    searchColumns: {
      type: Array,
      default: () => ([])
    },
    gridHeadBtn: {
      type: Array,
      default: () => ([])
    },
    gridRowBtn: {
      type: Array,
      default: () => ([])
    },
    beforeOpen: {
      type: Function,
      default: (done) => {
        done();
      }
    },
    spanMethod: {
      type: Function,
      default: () => {
      }
    },
    cellDblclick: {
      type: Function,
      default: (row, column, cell, event) => {
      }
    },
    cellClassName: {
      type: Function,
      default: (row, column, rowIndex, columnIndex) => {
      }
    },
    cellStyle: {
      type: Function,
      default: (row, column, rowIndex, columnIndex) => {
      }
    },
    headTitle: {
      type: String,
      default: ""
    },
    name:{
      type: String,
      default: ""
    },
    page: {
      type: Object,
      default: () => ({
        pageSize: 20,
        pageSizes: [10, 20, 30, 40, 50, 100],
        currentPage: 1,
        total: 0
      })
    }
  },
  computed: {
    globalSlotList() {
      let _list = [];
      if (this.tableOptions.group && this.tableOptions.group.length > 0) {
        let columns = [];
        this.tableOptions.group.forEach(item => {
          columns = columns.concat(item.column);
        })
        _list = columns.filter(item => {
          return item.isGlobal === true;
        })
      } else {
        _list = this.tableOptions.column.filter(item => {
          return item.isGlobal === true;
        })
      }
      return _list;
    },
    tagsView: {
      get() {
        return this.$store.state.common.tagsView;
      },
    },
    ...mapGetters(["language", "themeColor", "showStripe"])
  },
  data() {
    return {
      compentRow: {},//drop当前行数据
      compentIdx: null,//drop当前行idx
      globalBox: false,
      globalForm: {},
      globalItem: null,
      grid: null,
      clientHeight: "",
      selectionList: [],
      option: {
        expand: this.tableOptions.expand ? this.tableOptions.expand : false,
        height: this.tableOptions.height ? this.tableOptions.height : 'auto',
        headerAlign: this.tableOptions.align ? this.tableOptions.align : 'center',
        menuWidth: this.tableOptions.menuWidth ? this.tableOptions.menuWidth : 130,
        align: this.tableOptions.headerAlign ? this.tableOptions.headerAlign : 'left',
        tip: this.tableOptions.tip ? this.tableOptions.tip : false,
        searchShow: this.tableOptions.searchShow ? this.tableOptions.searchShow : false,
        card: this.tableOptions.card ? this.tableOptions.card : false,
        border: this.tableOptions.border ? this.tableOptions.border : true,
        index: this.tableOptions.index ? this.tableOptions.index : false,
        indexFixed:this.tableOptions.indexFixed ? this.tableOptions.indexFixed : false,
        indexLabel: this.tableOptions.indexLabel ? this.tableOptions.indexLabel : '#',
        viewBtn: this.tableOptions.viewBtn ? this.tableOptions.viewBtn : false,
        addBtn: this.tableOptions.addBtn ? this.tableOptions.addBtn : false,
        removeBtn: this.tableOptions.removeBtn ? this.tableOptions.removeBtn : false,
        refreshBtn: this.tableOptions.refreshBtn ? this.tableOptions.refreshBtn : false,
        searchBtn: this.tableOptions.searchBtn ? this.tableOptions.searchBtn : false,
        columnBtn: this.tableOptions.columnBtn ? this.tableOptions.columnBtn : false,
        searchShowBtn: this.tableOptions.searchShowBtn ? this.tableOptions.searchShowBtn : false,
        emptyBtn: this.tableOptions.emptyBtn ? this.tableOptions.emptyBtn : false,
        delBtn: this.tableOptions.delBtn ? this.tableOptions.delBtn : false,
        editBtn: this.tableOptions.delBtn ? this.tableOptions.delBtn : false,
        selection: this.tableOptions.selection === false ? this.tableOptions.selection : true,
        filterBtn: this.tableOptions.filterBtn ? this.tableOptions.filterBtn : false,
        header: this.tableOptions.header === false ? this.tableOptions.header : true,
        dialogClickModal: this.tableOptions.dialogClickModal ? this.tableOptions.dialogClickModal : false,
        lazy: this.tableOptions.lazy,
        selectable: this.tableOptions.selectable,
        menu: this.tableOptions.menu,
        indexWidth: this.tableOptions.indexWidth,
        // menuFixed: this.tableOptions.menuFixed ? this.tableOptions.menuFixed : false,
        menuFixed: 'right',
        rowKey: this.tableOptions.rowKey ? this.tableOptions.rowKey : 'id',
        reserveSelection: this.tableOptions.reserveSelection ? this.tableOptions.reserveSelection : false,
        highlightCurrentRow: this.tableOptions.highlightCurrentRow ? this.tableOptions.highlightCurrentRow : false,
        cellBtn: this.tableOptions.cellBtn ? this.tableOptions.cellBtn : false,
        addRowBtn: false,
        maxHeight: this.tableOptions.maxHeight ? this.tableOptions.maxHeight : 'auto',
        column: [],
      },
      searchForm: {},
      currentRow: {},
      searchOption: {
        gutter: 1,
        labelWidth: 1,
        menuSpan: 3,
        emptyBtn: false,
        submitBtn: false,
        column: this.searchColumns
      },
      pageType: "",
      isShow: true
    };
  },
  created() {
    this.initColumn();
    this.pageType = this.$route.query.pageType;
    if (this.pageType == 'detail') {
      this.option.menu = false
    }
  },
  activated() {
    let that=this;
    setTimeout(()=>{
      that.getTableHeight()
    },500)
  },
  mounted() {
    let that=this;
    setTimeout(()=>{
      that.getTableHeight()
    },500)
    window.addEventListener('resize', this.getTableHeight, false)
  },
  watch: {
    tableData: {
      deep: true,
      immediate: true,
      handler(val) {
        this.$emit("update:tableData", val)
      }
    },
    dataTotal: {
      handler(newVal) {
        this.page.total = newVal;
      }
    },
    showStripe: {
      immediate: true,
      handler(val) {
        this.option.stripe = val;
      }
    },
    tableOptions:{
      deep: true,
      immediate: true,
      handler(val) {
        this.option.selection = val.selection == false ?val.selection :true
        this.option.menu = val.menu
        this.option.column = val.column
      }
    }
  },
  methods: {
    getTableHeight(){
       if(!this.$refs.grid)return
       let  {top} =this.$refs.grid?.$el.getBoundingClientRect()
       let  tablePage =this.$refs.grid?.$refs.tablePage
       let offsetHeight=0
       if(tablePage){
         offsetHeight=(tablePage.offsetHeight || 42)+20
       }
       this.option.height=document.documentElement.clientHeight- top- offsetHeight
       this.$refs.grid.tableHeight=this.option.height;
       this.$refs.grid.doLayout()
    },
    getGrid() {
      return this.$refs.grid
    },
    initColumn() {
      let _this = this;
      _this.option.column = _this.tableOptions.column;
      _this.option.column.forEach(function (item) {
        //是否国际化
        if (item.isGlobal === true) {
          item.clearable = false;
          item.readonly = true;
        }
      })
    },
    getData(url, methodType) {
      return request({
        url: url,
        method: methodType
      })
    },
    getSelectData() {
      return this.$refs.crud.tableSelect;
    },
    currentChange(currentPage) {
      let page = {
        pageSize: this.page.pageSize,
        currentPage: currentPage,
        total: this.dataTotal
      }
      this.$emit('page-current-change', page, this.searchForm)
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.$emit('page-size-change', this.page, this.searchForm)
    },
    refreshChange() {
      this.$emit('page-refresh-change', this.page, this.searchForm)
    },
    selectionChange(list) {
      this.selectionList = list;
      this.$emit('gird-handle-select-click', list);
    },
    selection(selection,row) {
      this.$emit('select-click', selection,row);
    },
    currentRowChange(data){
      this.$emit('current-row-change', data);
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.grid.toggleSelection();
    },
    rowDetail(row) {
      this.$emit("grid-row-detail-click", row)
    },

    getColor(index) {
      // 定义颜色规则
      const colors = ['black', 'green', 'red'];
      return colors[index % colors.length];  // 依次循环颜色
    },
    //item  字段prop 可根据 prop跳转对应不同页面 携带不同参数
    rowDistribution(row,item) {
      this.$emit("grid-row-detail-click-distribution", row,item)
    },
    cellClick(row, column, cell, event) {
      this.$emit("grid-cell-click", {"row": row, "column": column, "cell": cell, "event": event})
    },
    rowClick(row, column, event) {
      this.$emit("grid-row-click", {"row": row, "column": column, "event": event})
    },
    handleRowClick(row) {
      this.$refs.grid.rowCell(row, row.$index)
    },
    cellMouseEnter(row, column, cell, event) {
      this.$emit("grid-cell-mouse-enter", {"row": row, "column": column, "cell": cell, "event": event})
    },
    rowDblclick(row,column,event) {
      this.$emit("row-dblclick", {"row": row, "column": column, "event": event})
    },
    headerDragend(newWidth, oldWidth, column, event){
      this.$refs.grid.refreshTable();
    },
    btnClick(btn) {
      this.$emit(btn.emit)
    },
    toggleRowExpansion(expandRowKeys,expanded){
        this.$refs.grid.toggleRowExpansion(expandRowKeys,expanded)
    },
    getMoreBtn(command) {
      let data = {
        command: command,
        row: this.compentRow,
        idx: this.compentIdx
      }
      this.$emit('commandClick', data)
    },
    btnRowClick(btn, row, index) {
      if (btn.dropList) {
        this.compentRow = row;
        this.compentIdx = index;
      } else {
        this.$emit(btn.emit, row, index,this.name)
      }
    },
    treeLoad(tree, treeNode, resolve) {
      this.$emit('tree-load', tree, treeNode, resolve)
    },
    rowUpdate(row, index, done, loading) {
      this.$emit("submit-edit", row, index, done, loading);
    },
    rowSave(row, done, loading) {
      this.$emit("submit-add", row, done, loading);
    },
    //获取国际化字段当前语言的值
    getGlobalItemVal(prop) {
      let dataForm = this.$refs.grid.$refs.dialogForm.$refs.tableForm.form;
      let v = dataForm[prop];
      let l = this.language;
      if (isJSON(v)) {
        return JSON.parse(v)[l];
      } else {
        return v;
      }
    },
    //修改国际化字段
    changeGlobalVal(prop, val) {
      let dataForm = this.$refs.grid.$refs.dialogForm.$refs.tableForm.form;
      let vObj = isJSON(v) ? JSON.parse(v) : {[this.language]: v};
      this.globalForm = vObj;
      this.globalForm[this.language] = val;
      dataForm[prop] = JSON.stringify(this.globalForm);
    },
    //显示国际化弹窗
    showGlobalBox(column) {
      let dataForm = this.$refs.grid.$refs.dialogForm.$refs.tableForm.form;
      let v = dataForm[column.prop];
      let vObj = isJSON(v) ? JSON.parse(v) : {[this.language]: v};
      this.globalForm = vObj;
      this.globalBox = true;
      this.globalItem = column;
    },
    //国际化弹窗关闭
    globalBoxClose() {
      this.globalItem = null;
      this.globalForm = {};
      this.$refs['globalForm'].resetFields();
    },
    //国际化弹窗提交
    submitGlobal() {
      let dataForm = this.$refs.grid.$refs.dialogForm.$refs.tableForm.form;
      this.$refs['globalForm'].validate((valid) => {
        if (valid) {
          dataForm[this.globalItem.prop] = JSON.stringify(this.globalForm);
          this.globalBox = false;
        }
      })
    },
    headerBorderColor({row, roeIndex}) {
      return 'borderColor'
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .cell {
  font-size: 14px;
}
.avue-form {
  height: 44px;
}

.avue-crud::v-deep .avue-crud__menu {
  min-height: 0 !important;
  margin: 0 !important;
}

::v-deep .avue-crud .el-table {
  //height: calc(100vh - 274px) !important;
  //max-height: calc(100vh - 274px) !important;
}

// 如果需要列表高度根据多标签的显示/隐藏动态修改高度，还需要同时修改各个功能页面对应的curd高度，否则样式会错乱
// 误删
::v-deep .avueCrudNoTag .el-table {
  height: calc(100vh - 234px) !important;
  max-height: calc(100vh - 234px) !important;
}

.avue-crud__pagination {
  background-color: #ffffff !important;
}

.el-link {
  //color: #1b1b1b;
  text-decoration: underline;
  text-decoration-color: #1b1b1b;
}

.el-link:hover {
  color: #6c757d;
  text-decoration: none;
}

//修改表格边框颜色
::v-deep .avue-crud .el-table th {
  background: #fafafa;
}

::v-deep .el-table--border, .el-table--group {
  border-color: #cccccc !important;
}

::v-deep .borderColor {
  border-color: #cccccc !important;
  border-right: 1px solid #cccccc !important;
}

::v-deep .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
  border-color: #cccccc !important;
}

::v-deep .el-table__cell{
  border-left: 1px solid #cccccc !important;
}

::v-deep .el-table__fixed-right::before {
  background-color: #ffffff !important;
}

::v-deep .el-table__fixed::before {
  background-color: #ffffff !important;
}

::v-deep .el-table--border::after, .el-table--group::after, .el-table::before {
  background-color: #cccccc !important;
}

::v-deep .el-table--border {
  border-bottom: 1px solid #cccccc !important;
}

::v-deep .el-icon-circle-close {
  display: none;
}
::v-deep .el-icon-circle-plus-outline {
  display: none;
}
::v-deep .el-button + .el-button {
      margin-left: 10px;
}
</style>
