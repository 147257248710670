/*
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-08-29 09:52:28
 */
import request from '@/router/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjeqgatemachine/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const getDetail = (id) => {
  return request({
    url: '/api/sinoma-hse-prj/prjeqgatemachine/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/sinoma-hse-prj/prjeqgatemachine/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/sinoma-hse-prj/prjeqgatemachine/submit',
    method: 'post',
    data: row
  })
}

//人员接口
export const getUserList = ( params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjeqgatemachineuser/list',
    method: 'get',
    params: {
      ...params,
    }
  })
}

export const getUserInitList = (current, size, params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjeqgatemachineuser/selectPage',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

//批量保存闸机管理人员
export const saveBatch = (row) => {
  return request({
    url: '/api//sinoma-hse-prj/prjeqgatemachineuser/saveBatch',
    method: 'post',
    data: row
  })
}

export const getEmployee = (current, size, params) => {
  return request({
    url: '/api/sinoma-hse-prj/prjeqthroughrecord/employee',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
