import request from '@/router/axios'
import ServerNameEnum from '../../util/ServerNameEnum'

/** 设备分类树-头表接口 */
export const getClassList = ( params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/bsdeviceclasshead/list',
    method: 'get',
    params: {
      ...params,
    }
  })
}

export const getClassTree = ( params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/bsdeviceclasshead/tree',
    method: 'get',
    params: {
      ...params,
    }
  })
}

export const getClassSubmit = ( params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/bsdeviceclasshead/submit',
    method: 'post',
    data: params,
  })
}

export const getClassRemove = ( ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/bsdeviceclasshead/remove',
    method: 'post',
    params: {ids},
  })
}

/** 设备-行表接口 */
export const getClassLinPage = (current,size,params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/bsdeviceclassline/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getClassLinSubmit = ( params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/bsdeviceclassline/submit',
    method: 'post',
    data: params,
  })
}

export const getClassLinRemove = ( ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/bsdeviceclassline/remove',
    method: 'post',
    params: {ids},
  })
}
// 导出
export const getClassLinExport = (data) => { 
  return request({
    url: '/api/sinoma-hse-prj/bsdeviceclassline/export',
    method: 'get',
    data
  })
}