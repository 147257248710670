import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getDataSourceList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/sys-data-source/list',
    method: 'post',
    data:params
  })
}
export const saveDataSource = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/sys-data-source/save',
    method: 'post',
    data:params
  })
}
export const updateDataSource = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/sys-data-source/update',
    method: 'post',
    data:params
  })
}
export const deleteDataSource = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/sys-data-source/delete',
    method: 'post',
    data:params
  })
}
export const detailDataSource = (id) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/sys-data-source/detail?id='+id,
    method: 'get',

  })
}
//数据库采集
export const gatherlist = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/mock-inf/list',
    method: 'post',
    data:params
  })
}
export const gatherUp= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/mock-inf/save-or-update',
    method: 'post',
    data:params
  })
}
export const gatherdel= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/mock-inf/delete',
    method: 'post',
    data:params
  })
}
export const mockInfDetail= (id) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/mock-inf/detail?id='+id,
    method: 'get',

  })
}

//获取默认编码
export const getAutoCode= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/mock-inf/get-auto-code',
    method: 'get',
    data:params
  })
}

//数据源下拉
export const datSoSel= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/sys-data-source/list-sys-data-source',
    method: 'get',
    data:params
  })
}
//todo 增量接口采集
export const itInfList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/conf-it-inf/list',
    method: 'post',
    data:params
  })
}
export const itInfUp= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/conf-it-inf/save-or-update',
    method: 'post',
    data:params
  })
}
export const itIndel= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/conf-it-inf/delete',
    method: 'post',
    data:params
  })
}
export const itInDetail= (id) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/conf-it-inf/detail?id='+id,
    method: 'get',

  })
}
export const itInCode= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/conf-it-inf/get-auto-code',
    method: 'get',
    data:params
  })
}
//todo 数据汇聚配置
export const dataConvergeList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-converge/list',
    method: 'post',
    data:params
  })
}
export const dataConvergeListUp= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-converge/save-update',
    method: 'post',
    data:params
  })
}
export const dataConvergeListDel= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-converge/delete',
    method: 'post',
    data:params
  })
}
//todo 数据汇聚分组
export const dataGroupList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-converge-group/list',
    method: 'post',
    data:params
  })
}
export const dataGroupListUp= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-converge-group/save-update',
    method: 'post',
    data:params
  })
}
export const dataGroupListDel= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-converge-group/delete',
    method: 'post',
    data:params
  })
}
//todo 数据汇聚输出
export const dataOutList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-converge-output/list',
    method: 'post',
    data:params
  })
}
export const dataOutUp= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-converge-output/save-update',
    method: 'post',
    data:params
  })
}
export const dataOutDel= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-converge-output/delete',
    method: 'post',
    data:params
  })
}
//todo 数据注入
export const injectionList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-injection/list',
    method: 'post',
    data:params
  })
}
export const injectionUp= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-injection/save-update',
    method: 'post',
    data:params
  })
}
export const injectionDel= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-injection/delete',
    method: 'post',
    data:params
  })
}
//todo 数据分流
export const dataSplitList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-split/list',
    method: 'post',
    data:params
  })
}
export const dataSplitUp= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-split/save-update',
    method: 'post',
    data:params
  })
}
export const dataSplitDel= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-split/delete',
    method: 'post',
    data:params
  })
}
export const dataSplitSel= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-split/list-conf-inf',
    method: 'post',
    data:params
  })
}
//todo 数据转换


export const getzhcode = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/conf/data-split/select_ittrans_code_list',
    method:'get',
    params
  })
}
export const itfTransList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/itf-trans/list',
    method: 'post',
    data:params
  })
}
export const itfTransUp= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/itf-trans/save-update',
    method: 'post',
    data:params
  })
}
export const itfTransDel= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/itf-trans/delete',
    method: 'post',
    data:params
  })
}
export const dataItfMapSel= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/itf-trans/list-map-code',
    method: 'post',
    data:params
  })
}

export const dataItfUnitSel= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/itf-trans/list-map-unit',
    method: 'post',
    data:params
  })
}
//todo 全量接口采集功能
export const totalCoList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/total-collect/list',
    method: 'post',
    data:params
  })
}
export const totalCollectUp= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/total-collect/save-or-update',
    method: 'post',
    data:params
  })
}
export const totalCollectDel= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/total-collect/delete',
    method: 'post',
    data:params
  })
}
export const totalCollectDetail= (id) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/total-collect/detail?id='+id,
    method: 'get',

  })
}



export const getqlcode = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/total-collect/get_auto_code',
    method: 'post',
    data:params
  })
}






//todo token刷新配置
export const tokenList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/conf-token/list',
    method: 'post',
    data:params
  })
}
export const tokentUp= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/conf-token/save-or-update',
    method: 'post',
    data:params
  })
}
export const tokentDel= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/conf-token/delete',
    method: 'post',
    data:params
  })
}
export const tokenInitcode= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/conf-token/get-auto-code',
    method: 'get',
    data:params
  })
}

export const tokenDetail= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/conf-token/detail?id='+params,
    method: 'get',

  })
}

// Excel导入数据库
export const dbConnectionList=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/gather/db-manager/list-all-database',
    method:'get',
    data:params
  })
}

// Excel导入数据库
export const dbConnectionList2=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/gather/db-manager/get_source_to_excel',
    method:'get',
    data:params
  })
}


export const AiModelList=()=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'storage/db-table-column/get-ai-model-list',
    method:'post',
    data: {host_id: "form_bigdata", dbType: "Mysql", id: "1623207192013107202"}
  })
}
export const importExcelData=(params)=> {
  return request({
    url: ServerNameEnum.SERVER_ZT_ + '/gather/excel-import//import-excel-data',
    method: 'post',
    data: params
  })
}
export const projectList=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/report-server/selectsql/get-project-list',
    method:'post',
    data:params
  })
}
export const getHistory=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/report-server/selectsql/get-history',
    method:'post',
    data:params
  })
}

export const getTriggers=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/report-server/selectsql/get-triggers-list',
    method:'post',
    data:params
  })
}
export const getExecutor=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/report-server/selectsql/get-executor-list',
    method:'post',
    data:params
  })
}
//删除定时任务
export const delScheduleByScheduId=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/report-server/selectsql/del-schedule-by-scheduId',
    method:'post',
    data:params
  })
}

//调度任务
export const runFlow=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/report-server/selectsql/run-flow',
    method:'post',
    data:params
  })
}

//任务详情
export const flowDetail=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/report-server/selectsql/fetchexecflow',
    method:'post',
    data:params
  })
}

//修改保存任务
export const createFile=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/report-server/selectsql/create-file-by-web',
    method:'post',
    data:params
  })
}

//发布任务
export const releaseProjectZip=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/report-server/selectsql/release-project-zip',
    method:'post',
    data:params
  })
}


//删除任务
export const DelTask=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/report-server/selectsql/delete-all-script-directory-by-id',
    method:'post',
    data:params
  })
}


//任务管理
export const TaskGl=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/report-server/selectsql/get-project-data-by-id',
    method:'post',
    data:params
  })
}


//保存任务管理详情
export const SaveTaskGl=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/report-server/selectsql/create-file-by-web',
    method:'post',
    data:params
  })
}

// reportServer/selectsql/createFileByweb



//项目管理列表获取
export const getScriptDictList=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/report-server/selectsql/get-script-dict-all-by-pid',
    method:'post',
    data:params
  })
}


//新增 和编辑
export const AddDirectory=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/report-server/selectsql/create-update-script-directory',
    method:'post',
    data:params
  })
}




//提交定时调度
export const ScheduleByCronEXEaFlow=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/report-server/selectsql/schedule-by-cron-ex-ea-flow',
    method:'post',
    data:params
  })
}




// /dataquality/data_object/glance_data

//数据对象  预览
export const seeData=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/dataquality/data_object/glance_data',
    method:'post',
    data:params
  })
}



//数据对象 tree 接口
export const getDataTree=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/storage/data-directory/get-data-directory-all-by-pid',
    method:'post',
    data:params
  })
}
//新增保存数据对象类型（tree）
export const SaveAddTree=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/dataquality/data_object_type/save',
    method:'post',
    data:params
  })
}

//删除数据对象类型（tree）
export const DelObjTree=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/dataquality/data_object_type/delete',
    method:'post',
    data:params
  })
}

//数据对象分页列表
export const ObjectList=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/dataquality/data_object/list_page',
    method:'post',
    data:params
  })
}



export const ObjectList2 = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/dataquality/data_object/list_all_data_object',
    method:'get',
    params
  })
}



//新增数据数据对象
export const AddObjectList=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/dataquality/data_object/save',
    method:'post',
    data:params
  })
}

//删除数据对象
export const DelObjectList=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/dataquality/data_object/delete',
    method:'post',
    data:params
  })
}



// 数据对象 数据库
export const getSourcesList=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/sources/list',
    method:'get',
    data:params
  })
}
// 数据对象 数据库
export const getSourcesList2 = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/dataquality/data_object/get_sources_by_model_id',
    method:'get',
    params
  })
}


// 数据对象 数据库表
export const getSourcesTableList=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/tables/list',
    method:'get',
    params:params
  })
}

// 数据对象 数据库表
export const getSourcesTableList2=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/dataquality/data_object/get_table_by_source_id_and_model_id',
    method:'get',
    params:params
  })
}

// 数据对象 表字段
export const getColumnsList=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/columns/list',
    method:'get',
    params:params
  })
}





// 数据对象 api 接口列表
export const getDataApisList=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/dataApis/list',
    method:'get',
    data:params
  })
}

// 数据对象 api 详情
export const getDataApisXQ=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+`/dataApis/${params}` ,
    method:'get',
  })
}


// 数据对象 详情
export const getDataOBJXQ=(params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+`/dataquality/data_object/${params}` ,
    method:'get',
  })
}



export const getDataOBJXQ2 = (params)=>{
  return request({
    url:ServerNameEnum.SERVER_ZT_+'/dataquality/data_object/get_data_object_by_id',
    method:'get',
    params
  })
}


export const getDTime=(params)=>{
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/report-server/mdm-dict/get-dict-value-by-dict-code',
    method: 'post',
    data:params
  })
}
// /storage/mdm-dict/get-dict-by-dict-code？dict_code


export const delAimodel= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/algorithm/ai-algorithm/delete-all-model-by-id',
    method: 'get',
    params
  })
}

//数据质量检测任务列表接口-分页
export const qualityCheckTask=(params)=>{
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_check_task/list_for_page',
    method: 'post',
    data:params
  })
}
//保存检测任务
export const SaveTask= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_check_task/save',
    method: 'post',
    data:params
  })
}

export const qualityTemplate=(id)=>{
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_template/list_data_quality_template?template_type_id='+id,
    method: 'get',
    
  })
  
}
//模版分类接口
export const listTemplateType= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_template/list_template_type',
    method: 'get',
    params
  })
}



//检测任务详情
export const TaskDetail= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_check_task/get_data_quality_check_task_detail_by_id',
    method: 'get',
    params
  })
}

//执行任务
export const ExecuteTask= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_check_task/execute_data_quality_check_task_by_id',
    method: 'get',
    params
  })
}

//删除任务
export const DelCheckTask= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_check_task/delete_data_quality_check_task_by_id',
    method: 'get',
    params
  })
}


//测任务执行记录列表-分页
export const checkTaskRecordList= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_check_task/check_task_record_list_page',
    method: 'post',
    data:params
  })
}
//检测任务执行记录查看详情
export const SeeTaskRecordDetail= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_check_task/check_task_record_detail_by_id',
    method: 'get',
    params
  })
}


//检测任务执行记录 删除
export const DelTaskRecordDetail= (params) => {
   return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_check_task/delete_check_task_record_by_id',
    method: 'get',
    params
  })
}

// export const DelTaskRecordDetail= (params) => {
//   return request({
//     url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_check_task/delete_check_task_record_by_id',
//     method: 'post',
//     data:params
//   })
// }


//判定任务列表-分页
export const judgeList= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_judge_config/list_for_page',
    method: 'post',
    data:params
  })
}

//判定方法配置保存接口
export const SaveJudgeRecord= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_judge_config/save',
    method: 'post',
    data:params
  })
}

//任务列表
export const checkTaskList= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_check_task/list_all_check_task',
    method: 'post',
    data:params
  })
}
//判定关系编码
export const relationEnum= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_judge_config/list_judge_relation_enum',
    method: 'get',
    params
  })
}
//判定方法列表-执行
export const executeSchedule= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_judge_config/execute_schedule',
    method: 'post',
    data:params
  })
}
//判定方法列表-删除
export const delJudge= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_judge_config/delete',
    method: 'get',
    params
  })
}

//判定方法列表-查看
export const SeeJudge= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_judge_config/get_id',
    method: 'get',
    params
  })
}

//判定结果-判定记录列表
export const judgeRecordList= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_judge_config/list_data_quality_judge_record_for_page',
    method: 'post',
    data:params
  })
}
//判定结果-查看
export const JudgeResult = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_judge_config/get_data_quality_judge_record_by_id',
    method: 'get',
    params
  })
}


//数据服务 班组树接口

export const dataApisTree = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/dataApis/list_data_api_index_tree',
    method: 'get',
    params
  })
}

export const dataApisTree2= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/index/model/config/list_all_data_api_index_tree',
    method: 'get',
    params
  })
}
// /index/model/config/list_all_data_api_index_tree



//班组查询数据服务
export const apiTreeData= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/dataApis/list_data_api_index_tree_query_data_by_xmbh',
    method: 'post',
    data:params
  })
}

//天查询数据服务

export const xmsjDay= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/dataApis/list_dbcj_xmsj_day_by_xmbh_db',
    method: 'post',
    data:params
  })
}
//小时查询数据服务
export const xmsjHour= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/dataApis/list_dbcj_xmsj_hour_by_xmbh_db',
    method: 'post',
    data:params
  })
}

//小时查询数据服务
export const xmsjHour2= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/dataApis/list_dbcj_xmsj_hour_by_xmbhs',
    method: 'post',
    data:params
  })
}


//查询指标应用列表
export const ZBList= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/index/model/config/list_page',
    method: 'post',
    data:params
  })
}
//查看指标数据分析

export const SeeZBFX = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/index/model/config/get_by_id',
    method: 'get',
    params
  })
}


//查询指标数
export const ZBtreeLIst = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/index/model/config/list_tree',
    method: 'post',
    data:params
  })
}

//保存指标数据分析配置
export const SaveZBset = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/index/model/config/save',
    method: 'post',
    data:params
  })
}
//删除指标数据分析
export const DelZBFX = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/index/model/config/delete_by_id',
    method: 'get',
    params
  })
}

//看板主数据信息接口
export const starList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/index/model/config/app_star_main_info_by_id',
    method: 'post',
    data:params
  })
}
//查询项目截止目前7天数据接口
export const listxmsjDay = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/index/model/config/list_dbcj_xmsj_day_by_xmbhs',
    method: 'post',
    data:params
  })
}
//解析cron表达式
export const jxCron = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/data_quality/data_quality_judge_config/list_top_cron',
    method: 'post',
    data:params
  })
}


//接口系统配置列表
export const InterfaceSystemList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/conf-system/page_conf_system',
    method: 'post',
    data:params
  })
}

//接口系统配置保存新增
export const InterfaceSystemUpdate = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/conf-system/save-or-update',
    method: 'post',
    data:params
  })
}

//接口系统配置详情
export const InterfaceSystemDeatil = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/conf-system/get_by_id',
    method: 'get',
    params
  })
}

//接口系统配置删除
export const InterfaceSystemDel = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/conf-system/delete',
    method: 'get',
    params
  })
}


//保存接口采集列表
export const SavenewitInfList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/interface_collect/save_interface_collect',
    method: 'post',
    data:params
  })
}

//接口采集详情
export const newitInfdetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/interface_collect/get_detail',
    method: 'get',
    params
  })
}


//接口采集执行
export const zxnewitInf = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/interface_collect/execute_interface_collect',
    method: 'get',
    params
  })
}

//接口采集启动或者暂停
export const runnewitInf = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/interface_collect/firing_interface_collect',
    method: 'get',
    params
  })
}

//接口采集  系统下拉
export const InfsystemList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/conf-system/get_list',
    method: 'post',
    data:params
  })
}


//excel导入列表
export const excelPageList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/excel-import/list_metadata_excel_page',
    method: 'post',
    data:params
  })
}

//excel导入列表浏览数据（支持分页）
export const excelmetadataList= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/excel-import/list_metadata_excel_data_for_page',
    method: 'post',
    data:params
  })
}

//excel列表详情
export const excelListDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/gather/excel-import/get_metadata_excel_by_id',
    method: 'get',
    params
  })
}


//更新标准值接口
export const updateNhfxSummary= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/index/model/config/update_nhfx_summary_by_id',
    method: 'post',
    data:params
  })
}

//原料磨对比树
export const YLMcomptree = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/index/model/config/app_star_main_info_tree',
    method: 'get',
    params
  })
}

//获取数据对象api类型预览列名
export const dataObjectSF = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/dataquality/data_object/list_data_object_save_field',
    method: 'get',
    params
  })
}

//组件管理列表
export const componentManageList= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/component_manage/page_list',
    method: 'post',
    data:params
  })
}

//组件管理保存
export const SavecomponentManage= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/component_manage/save_or_update',
    method: 'post',
    data:params
  })
}

//组件管理详情
export const componentManageDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/component_manage/get_detail',
    method: 'get',
    params
  })
}

//组件管理删除
export const componentManageDel = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/component_manage/delete',
    method: 'get',
    params
  })
}


//组件管理根据sql解析列信息
export const zjgetSqlParse= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/report-server/selectsql/get_sql_parse',
    method: 'post',
    data:params
  })
}



//组件管理数据输出列表
export const getOutputResultSetList= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-processing/get_output_result_set',
    method: 'post',
    data:params
  })
}

//数据对象指标对接查询指标信息
export const objgetzb = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/dataquality/data_object/list_index_interface',
    method: 'get',
    params
  })
}


//数据对象指标对接查询指标接口信息
export const objgetzbjk = (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/dataquality/data_object/list_index_intf',
    method: 'get',
    params
  })
}

//组件管理数据输出列表
export const getzbfieldList= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/dataquality/data_object/list_index_interface_field',
    method: 'get',
    params
  })
}


//根据指标接口code查询列
export const getzbjkfieldList= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/dataquality/data_object/list_index_inft_column',
    method: 'get',
    params
  })
}

//一、大块图片信息
export const getlargeblockshow= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/large-block-show/list_last_modified_picture',
    method: 'post',
    data:params
  })
}



//二、阀值、最大值图
export const getlistLargeBlockThreshold= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/large-block-show/list_large_block_threshold',
    method: 'post',
    data:params
  })
}




//主子查询列表
export const getMarketApiResult= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/dataApis/get_market_api_result_page',
    method: 'post',
    data:params
  })
}


//查询原服务下拉
export const selectMarketApiLlist= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/dataApis/select_market_api_list',
    method: 'post',
    data:params
  })
}

//保存数据服务关系

export const saveMarketApiResult= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/dataApis/save_market_api_result',
    method: 'post',
    data:params
  })
}

//数据服务主子 编辑回显
export const getMarketApiResultById= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/dataApis/get_market_api_result_by_id',
    method: 'get',
    params
  })
}

//数据服务主子 删除
export const deleteMarketApiResult= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/dataApis/delete_market_api_result',
    method: 'get',
    params
  })
}

//数据对比列表

export const dataCompareList= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-compare/list_data_compare_info',
    method: 'post',
    data:params
  })
}

//保存数据对比

export const SavedataCompare= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-compare/save_data_compare',
    method: 'post',
    data:params
  })
}


//数据服务详情
export const getcompareInfo= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-compare/get_dataset_compare_info_by_id',
    method: 'get',
    params
  })
}


//更新数据对比

export const updateDataCompare= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-compare/update_data_compare',
    method: 'post',
    data:params
  })
}


//删除数据服务
export const delcompare= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-compare/delete_data_compare',
    method: 'get',
    params
  })
}


//执行数据对比

export const execDataCompare= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-compare/exec_data_compare?id='+params.id,
    method: 'post',
    data:params
  })
}


//启动数据对比
export const startDataCompare= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-compare/start_off_data_compare?id='+params.id+'&&runState='+params.runState,
    method: 'post',
    data:params
  })
}


//查看数据对比记录

export const getCompareDiff= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/conf/data-compare/list_dataset_compare_diff_record',
    method: 'post',
    data:params
  })
}