import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/client/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/client/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/client/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/client/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/client/submit',
    method: 'post',
    data: row
  })
}


// 根据名称获取ids（隐患清单用）
export const roleIds = (params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/client/role-ids',
    method: 'get',
    params
  })
}

// 根据获取到的角色id及部门（项目）id获取人员
export const getUsersByRoleIdAndDeptId = (params) => {
  return request({
    url: '/api/sinoma-user/getUsersByRoleIdAndDeptId',
    method: 'get',
    params
  })
}
