import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 获取风险类型树
export const getTree = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskSourceType/tree`,
    method: 'get',
    params
  })
}
// 新增或编辑
export const addTree = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskSourceType/submit`,
    method: 'post',
    data
  })
}
// 风险类型详情
export const getTreeDetail = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskSourceType/detail`,
    method: 'get',
    params
  })
}
// 风险源详情
export const getTreesDetail = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/risksource/detail`,
    method: 'get',
    params
  })
}


// 删除风险源分类树
export const deleteTree = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskSourceType/remove?ids=${data.ids}`,
    method: 'post',
  })
}
// 删除风险源分类树
export const deleteTrees = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/risksource/remove?ids=${data.ids}`,
    method: 'post',
  })
}

// 获取风险库列表
export const getPage = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/risklibrary/page`,
    method: 'get',
    params
  })
}
// 新增风险库列表数据
export const addPage = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/risklibrary/submit`,
    method: 'post',
    data
  })
}
// 删除风险库列表数据
export const deletePage = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/risklibrary/remove?ids=${data.ids}`,
    method: 'post',
  })
}
  // 风险库列表数据详情
export const PageDetail = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/risklibrary/detail`,
    method: 'get',
    params
  })
}
// 新增风险源表
export const addTreeList = (data) =>{
  return request({
    url: `${ServerNameEnum.SERVER_FX}/risksource/submit`,
    method: 'post',
    data
  })
}
//   获取饼图
export const getChartPie = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/risklibrary/getPie`,
    method: 'get',
    params
  })
}
// 获取编码
export const getPageCode = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/risklibrary/getCode`,
    method: 'get',
    params
  })
}


// 获取措施分类树
export const getTreatmentTree = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskTreatmentType/tree`,
    method: 'get',
    params
  })
}
// 新增措施分类
export const addTreatmentTree = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskTreatmentType/submit`,
    method: 'post',
    data
  })
}


// 删除措施分类树节点
export const deleteTreatmentTree = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskTreatmentType/remove?ids=${data.ids}`,
    method: 'post',
  })
}

// 获取措施列表（分页）
export const getRisktreatmentPage = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskTreatment/list`,
    method: 'get',
    params
  })
}
// 新增措施列表
export const addRisktreatmentPage = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskTreatment/submit`,
    method: 'post',
    data
  })
}
// 获取措施详情
export const getRisktreatmentDetail = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskTreatment/detail`,
    method: 'get',
    params
  })
}
// 删除措施列表item
export const deleteRisktreatmentPage = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskTreatment/remove?ids=${data.ids}`,
    method: 'post',
  })
}

// 获取风险源列表（不分页）
export const getRisksourceList = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/risksource/list`,
    method: 'get',
    params
  })
}


// 导出
export const exportList = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/risklibrary/export`,
    method: 'get',
    params
  })
}

// 获取分类树
export const getRiskSourceTypeTree = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskSourceType/typeTree`,
    method: 'get',
    params
  })
}
// 获取措施分类树详情
export const getRiskTreatmentTypeDetail = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/riskTreatmentType/detail`,
    method: 'get',
    params
  })
}

// 获取矩形树图
export const getTreemap = (params) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/risklibrary/treemap`,
    method: 'get',
    params
  })
}