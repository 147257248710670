import request from '@/router/axios';
import ServerNameEnum from "@/util/ServerNameEnum";

export function getDataSourceList(current, size, params) {
  return request({
    url: '/api/system/DataSource',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  })
}
export function DataSourceCreate(data) {
  return request({
    url: '/api/system/DataSource',
    method: 'post',
    data
  })
}
export function DataSourceDelete(id) {
  return request({
    url: `/api/system/DataSource/${id}`,
    method: 'delete',
  })
}
export function DataSourceInfo(id) {
  return request({
    url: `/api/system/DataSource/${id}`,
    method: 'get',
  })
}
export function DataSourceUpdate(data) {
  return request({
    url: `/api/system/DataSource/${data.id}`,
    method: 'put',
    data
  })
}
export function TestDbConnection(data) {
  return request({
    url: `/api/system/DataSource/Actions/Test`,
    method: 'post',
    data
  })
}
export function getDataSourceListAll() {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + "/dblink/list",
    method: "get",
  });
}
export function Execute(data) {
  return request({
    url: `/api/system/DataSync/Actions/Execute`,
    method: 'post',
    data
  })
}
export function DataSync(data) {
  return request({
    url: `/api/system/DataSync`,
    method: 'post',
    data
  })
}



//数据批量同步
export function batchExecute(data) {
  return request({
    url: `/api/system/DataSync/Actions/batchExecute`,
    method: 'post',
    data
  })
}
// 获取数据库表列表
export function checkDbLink(data) {
  return request({
    url: `/api/system/DataSync/Actions/checkDbLink`,
    method: 'post',
    data
  })
}
