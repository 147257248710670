import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

// 获取业务字典
export const dictionaryBiz = (code) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dict-biz/dictionary?code=` + code,
    method: 'get'
  })
}

export const dictionary = (code) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dict/dictionary?code=` + code,
    method: 'get'
  })
}

// 填报模板列表
export const templateList = (current, size, createUser, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/template/getPage`,
    method: 'get',
    params: {
      current,
      size,
      createUser,
      ...params,
    }
  })
}

// 填报模板删除
export const templateRemove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/template/remove?ids=` + ids,
    method: 'post'
  })
}
// 填报模板删除
export const getCopy = (id,templateName) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/template/getCopy?id=` + id+`&templateName=`+templateName,
    method: 'post'
  })
}


// 填报模板保存
export const templateSave = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/template/saveOrUpdate`,
    method: 'post',
    data
  })
}

// 填报模板详情
export const templateDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/template/detail?id=` + id,
    method: 'get'
  })
}

// 填报模板发布
export const templatePublish = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/template/effectSwitch`,
    method: 'post',
    data
  })
}

// 填报模板发布
export const addField = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/template/addField`,
    method: 'post',
    data
  })
}

// 查询当前用户组织及以下
export const deptChildTree = () => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dept/deptChildTree`,
    method: 'get'
  })
}
// 查询当前用户组织及以下包括项目等等
export const deptChildTreeItem = () => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dept/deptChildTreeItem`,
    method: 'get'
  })
}
// 查询当前用户多组织及以下
export const multiOrganizationCombinationTree = () => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dept/multiOrganizationCombinationTree`,
    method: 'post'
  })
}
export const deptChildIds = (deptId) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dept/deptIds?deptId=` + deptId,
    method: 'get'
  })
}
// 下发任务列表
export const distributePage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/distributeAttribute/page`,
    method: 'get',
    params: {
      current,
      size,
      ...params,
    }
  })
}

// 下发任务详情
export const distributeDetail = (current, size, captureFormId, captureTaskId, templateCode, unitId, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rpTaskAttribute/page`,
    method: 'get',
    params: {
      current,
      size,
      captureFormId,
      captureTaskId,
      templateCode,
      unitId,
      ...params,
    }
  })
}

// 通过模板编码查详情
export const templatePreview = (templateCode) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/template/preview?templateCode=` + templateCode,
    method: 'post'
  })
}

// 数据填报利列表
export const reportWriteList = (current,size,dataStatus,templateCode,params) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rpTaskAttribute/fillClassification`,
    method: 'get',
    params: {
      current,
      size,
      dataStatus,
      templateCode,
      ...params,
    }
  })
}

// 查询当前用户的组织架构及用户
export const mergeDeptUser = (roleId) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dept/mergeDeptUser?roleIds=` + roleId,
    method: 'get'
  })
}

// 查询当前用户的组织架构
export const mergeDept = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dept/mergeDept`,
    method: 'post',
    data
  })
}
// 查询当前用户的组织架构
export const domesticAndForeign = (isDomestic) => {
  return request({
    url: `${ServerNameEnum.SERVER_FX}/prjbasicinfo/selectBasicInfoByDomesticAndForeign?isDomestic=` + isDomestic,
    method: 'get',
  })
}

// 通过组织ID和角色ID查询填报、汇总单位及对应人员
export const mergeMultipleDeptUsers = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dept/mergeMultipleDeptUsers`,
    method: 'post',
    data
  });
}

export const getDetailByTaskId = (taskId) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rpTaskAttribute/getDetailByTaskId`,
    method: 'get',
    params: {
      taskId
    }
  })
}

export const reportConfigurationProcess = (reportTemplateCode) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/acquisition/attributeFixation/reportConfigurationProcess?reportTemplateCode=` + reportTemplateCode,
    method: 'get',
  })
}


export const reportList = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/deptReportSettings/getReport`,
    method: 'post',
    params: {
      ...data
    }
  })
}

export const treeReport = () => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/reportCate/treeReport`,
    method: 'get',
  })
}

export const projectAutomatic = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_SYSTEM_}/dept/projectAutomatic`,
    method: 'post',
    // params:data
    data
  })
}
