import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_+'/notice/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_+'/notice/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_+'/notice/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_+'/notice/submit',
    method: 'post',
    data: row
  })
}

export const getNotice = (id) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + '/notice/' + id,
    method: 'get'
  })
}

export const getNoticeDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + '/notice/detail',
    method: 'get',
    params: {
      id
    }
  })
}
export const publish = (row) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + '/notice/publish',
    method: 'post',
    data: row
  })
}
/**
 * 流程通过
 * @param id
 * @param data
 * @returns {AxiosPromise}
 */
export const audit = (id,data) => {
  return request({
    url: ServerNameEnum.SERVER_FLOW+'/api/workflow/Engine/FlowBefore/Audit/'+id,
    method: 'post',
    data: data
  })
}
export const preAudit = (id,data) => {
  return request({
    url: ServerNameEnum.SERVER_FLOW+'/api/workflow/Engine/FlowBefore/Candidates/'+id,
    method: 'post',
    data: data
  })
}

