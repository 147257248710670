import { addResizeListener, removeResizeListener } from 'element-ui/src/utils/resize-event'
const doResize = (el, binding, vnode) => {
  const { componentInstance: $table } = vnode
  // 获取调用传递过来的数据
  const { value } = binding
  // if (!$table.height) {
  const bottomOffset = (value && value.bottomOffset) || 100
  const height = window.innerHeight - el.getBoundingClientRect().top - bottomOffset
  if (!$table) {
    el.style.height = height +'px'
    return
  }
  // 计算列表高度并设置
  $table.layout.setHeight(height)
  el.style.overflowY = 'scroll'
  $table.doLayout()
}
export const tableHe = {
  bind(el, binding, vnode) {
    // 设置resize监听方法
    el.resizeListener = () => {
      doResize(el, binding, vnode)
    }
    let domEl = document.getElementsByClassName('head-container')[0]
    if (domEl) {
      addResizeListener(domEl, el.resizeListener)
    }
    addResizeListener(window.document.body, el.resizeListener)
  },
  // 绑定默认高度
  inserted(el, binding, vnode) {
    doResize(el, binding, vnode)
  },
  // 销毁时设置
  unbind(el) {
    removeResizeListener(window.document.body, el.resizeListener)
    // 移除resize监听
    window.removeEventListener('resize', el.resizeListener)
  }
}
const doResize1 = (el, binding, vnode) => {
  const { componentInstance: $table } = vnode
  // 获取调用传递过来的数据
  const { value } = binding
  // if (!$table.height) {
  const bottomOffset = (value && value.bottomOffset) || 60
  // 计算列表高度并设置
  const height = window.innerHeight - el.getBoundingClientRect().top - bottomOffset
  el.style.height  =height+'px'
  el.style.overflowY = 'scroll'

}
export const bodyHe = {
  bind(el, binding, vnode) {
    // 设置resize监听方法
    el.resizeListener = () => {

      doResize1(el, binding, vnode)
    }
    let domEl = document.getElementsByClassName('head-container')[0]
    if (domEl) {
      addResizeListener(domEl, el.resizeListener)
    }
    addResizeListener(window.document.body, el.resizeListener)

  },
  // 绑定默认高度
  inserted(el, binding, vnode) {
    doResize1(el, binding, vnode)
  },
  // 销毁时设置
  unbind(el) {
    removeResizeListener(window.document.body, el.resizeListener)
    // 移除resize监听
    window.removeEventListener('resize', el.resizeListener)
  }
}
