import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const whetherToStartTraining = (id,status) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainGroupScene/whetherToStartTraining?id=`+ id+"&status="+status,
    method: 'post',
  })
}

export const getList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainGroupScene/trainingPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const myCollection = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainGroupScene/myCollection`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
//查询所有的培训记录
export const searchTrainingRecords = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingTask/searchTrainingRecords`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const saveCorrespondingData = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingRecording/saveCorrespondingData`,
    method: 'post',
    data: row
  })
}


export const getCorrespondingData = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingRecording/getCorrespondingData?id=`+ id,
    method: 'get',
  })
}

export const saveTopRecord = (row) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainGroupScene/saveTopRecord`,
    method: 'post',
    data: row
  })
}

export const cancelTop = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainGroupScene/cancelTop?id=` + id,
    method: 'post',
  })
}
