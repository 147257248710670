import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const reInitiated = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingPlan/reInitiated?id=` + id,
    method: 'post'
  })
}

export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/trainingPlan/getDetail?id=` + id,
    method: 'post'
  }) 
}
