import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getList = (params) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_+'/cameraBoard/list',
    method: 'get',
    params: {
      ...params,
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_+'/cameraBoard/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_+'/cameraBoard/submit',
    method: 'post',
    data: row
  })
}


export const getcameraBoardDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + '/cameraBoard/detail',
    method: 'get',
    params: {
      id
    }
  })
}


