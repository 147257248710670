import Layout from '@/page/index/'

export default [
  {
    path: '/riskManage',
    component: Layout,
    children: [
      {
        path: 'riskManageIndex',
        name: '风险库',
        meta: {
        },
        component: () =>
          import('@/views/business/riskManage/index')
      },
      {
        path: 'riskManageAdd',
        name: '风险库新增',
        meta: {
        },
        component: () =>
          import('@/views/business/riskManage/add')
      },
      {
        path: 'riskManageEdit',
        name: '风险库修改',
        meta: {
        },
        component: () =>
          import('@/views/business/riskManage/add')
      },
      {
        path: 'riskManageDetail',
        name: '风险库详情',
        meta: {
        },
        component: () =>
          import('@/views/business/riskManage/add')
      },
    ]
  }
]
