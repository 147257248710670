import request from '@/router/axios';
import ServerNameEnum from "@/util/ServerNameEnum";

// 获取我的待办
export function getFlowTodoCount() {
  return request({
    url: '/api/visualdev/Dashboard/FlowTodoCount',
    method: 'get'
  })
}
// 获取系统公告
export function getNotice() {
  return request({
    url: ServerNameEnum.SERVER_DESK_ + '/notice/list',
    method: 'get'
  })
}
// 获取未读邮件
export function getEmail() {
  return request({
    url: '/api/visualdev/Dashboard/Email',
    method: 'get'
  })
}
// 获取待办事项
export function getFlowTodo() {
  return request({
    url: '/api/visualdev/Dashboard/FlowTodo',
    method: 'get'
  })
}
// 获取我的待办事项
export function getMyFlowTodo() {
  return request({
    url: '/api/visualdev/Dashboard/MyFlowTodo',
    method: 'get'
  })
}
// 短token换基础信息
export function getTokenByShort(params) {
  return request({
    url: '/api/sinoma-auth/oauth/getTokenByShort',
    method: 'get',
    params
  })
}
