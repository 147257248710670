

const modulesFiles = require.context('./base', true, /.vue$/)
/*
 * description:
 * param {*} modulesFiles
 * param {*} modulePath
 * return {*}
 */
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  //
  const moduleName = modulePath.replace(/^.\/(.*)\.vue/, '$1')
  // 执行modulesFiles函数，返回一个对象{default: {// 文件内容}, _esModule: true}
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})
export default modules
