import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export function listDataColumn(data) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/columns/list',
    method: 'get',
    params: data
  })
}

// export function pageDataColumn(data) {
//   return request({
//     url: ServerNameEnum.SERVER_ZT_+'/columns/page',
//     method: 'get',
//     params: data
//   })
// }

export const pageDataColumn= (params) => {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/columns/page',
    method: 'post',
    data:params
  })
}

export function getDataColumn(id) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/columns/' + id,
    method: 'get'
  })
}

export function delDataColumn(id) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/columns/' + id,
    method: 'delete'
  })
}

export function delDataColumns(ids) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/columns/batch/' + ids,
    method: 'delete'
  })
}

export function addDataColumn(data) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/columns',
    method: 'post',
    data: data
  })
}

export function updateDataColumn(data) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/columns/' + data.id,
    method: 'put',
    data: data
  })
}

export function getDataMetadataTree(level, data) {
  return request({
    url: ServerNameEnum.SERVER_ZT_+'/columns/tree/' + level,
    method: 'get',
    params: data
  })
}
