import Layout from "@/page/index/";

export default [
  //环境保护
  {
    path: "/environmentProtect",
    component: Layout,
    name: "环境保护",
    children: [
      {
        path: "/environmentProtect/publicSentiment/publicOpinion/edit",
        name: "环保舆情新增编辑",
        component: () =>
          import(
            "@/views/environmentProtect/publicSentiment/publicOpinion/form"
          ),
        meta: {
          i18n: "环保舆情新增编辑",
        },
      },
      {
        path: "/environmentProtect/publicSentiment/complain/edit",
        name: "环保投诉新增编辑",
        component: () =>
          import("@/views/environmentProtect/publicSentiment/complain/form"),
        meta: {
          i18n: "环保投诉新增编辑",
        },
      },
      {
        path: "/environmentProtect/environmentalEmergencies/edit",
        name: "突发环境事件新增调查",
        component: () =>
          import("@/views/environmentProtect/environmentalEmergencies/form"),
        meta: {
          i18n: "突发环境事件新增调查",
        },
      },
      {
        path: "/environmentProtect/pollutionEmissions/pollutionRecord/edit",
        name: "排放记录新增编辑",
        component: () =>
          import("@/views/environmentProtect/pollutionEmissions/pollutionRecord/form"),
        meta: {
          i18n: "排放记录新增编辑",
        },
      },
    ],
  },
];
