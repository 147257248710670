import Layout from '@/page/index/'

export default [
  //数据采集
  {
    path: '/dataAcquisition',
    component: Layout,
    name:'数据采集',
    children: [
      {
        path: 'dataConfig',
        name: '多数据源配置',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/dataConfig'),
        // meta: {
        //   title: '多数据源配置',
        //   icon: '',
        // },
        meta: {
          i18n: 'DataConfig'
        },
        children:[

        ]
      },
      {
        path: 'dataConfigAdd',
        name: '多数据源配置新增',
        component: () => import('@/views/business/datacenter/dataAcquisition/dataCoonfigAdd'),
        // meta: {
        //   title: '多数据源管理新增',
        //   icon: '',
        // }
        meta: {
          i18n: 'DataConfigEdit'
        },
      },
      {
        path: 'sjcj',
        name: '数据采集',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/sjcj'),
        // meta: {
        //   title: '数据采集',
        //   icon: '',
        // }
        meta: {
          i18n: 'sjcj'
        },
      },
      {
        path: '/business/datacenter/dataObject/index',
        name: '数据对象',
        component: () =>
          import('@/views/business/datacenter/dataObject/index'),
        meta: {
          i18n: 'DataObject'
        },
      },
      {
        path: '/dataObject/add',
        name: '数据对象新增',
        component: () =>
          import('@/views/business/datacenter/dataObject/add'),
        meta: {
          i18n: 'DataObject'
        },
      },
      {
        path: 'sjcjAdd',
        name: '数据采集新增',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/sjcjAdd'),
        // meta: {
        //   title: '数据采集',
        //   icon: '',
        // }
        meta: {
          i18n: 'sjcjEdit'
        },
      },

      {
        path: '/business/datacenter/dataAcquisition/newInterfaceAc',
        name: '接口采集',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/newInterfaceAc'),
        meta: {
          i18n: 'zInterfaceAc'
        },
      },
      {
        path: 'newInterfaceAcAdd',
        name: '接口采集新增',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/newInterfaceAcAdd'),
        // meta: {
        //   title: '增量接口采集',
        //   i18n: 'asdasdasd',
        // }
        meta: {
          i18n: 'zInterfaceAc'
        },
      },

      


      



      {
        path: 'zInterfaceAc',
        name: '增量接口采集',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/zInterfaceAc'),
        // meta: {
        //   title: '增量接口采集',
        //   i18n: 'asdasdasd',
        // }
        meta: {
          i18n: 'zInterfaceAc'
        },
      },
      {
        path: 'zInterfaceAcAdd',
        name: '增量接口采集新增',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/zInterfaceAcAdd'),
        // meta: {
        //   title: '增量接口采集',
        //   icon: '',
        // }
        meta: {
          i18n: 'zInterfaceAcEdit'
        },
      },
      {
        path: 'qInterfaceAc',
        name: '全量接口采集',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/qInterfaceAc'),
        // meta: {
        //   title: '全量接口采集',
        //   icon: '',
        // },
        meta: {
          i18n: 'qInterfaceAc'
        },
      },
      {
        path: 'qInterfaceAcAdd',
        name: '全量接口采集新增',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/qInterfaceAcAdd'),
        // meta: {
        //   title: '全量接口采集新增',
        //   icon: '',
        // }
        meta: {
          i18n: 'qInterfaceAcEdit'
        },
      },

      {
        path: '/business/datacenter/dataAcquisition/InterfaceSystem',
        name: '业务系统配置',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/InterfaceSystem'),
          meta: {
            i18n: 'InterfaceSystemConfiguration'
          },
        // meta: {
        //   i18n: 'InterfaceSystemConfiguration'
        // },
      },

      {
        path: 'InterfaceSystemAdd',
        name: '接口系统配置新增',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/InterfaceSystemAdd'),

        meta: {
          i18n: 'InterfaceSystemConfiguration'
        },
      },


      {
        path: 'tokenRe',
        name: 'Token刷新配置',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/tokenRe'),
        // meta: {
        //   title: 'Token刷新配置',
        //   icon: '',
        // }
        meta: {
          i18n: 'tokenRe'
        },
      },
      {
        path: 'tokenReAdd',
        name: 'Token刷新配置新增',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/tokenReAdd'),
        // meta: {
        //   title: 'Token刷新配置新增',
        //   icon: '',
        // }
        meta: {
          i18n: 'tokenReEdit'
        },
      },
      {
        path: 'kafKa',
        name: 'Kafka数据采集',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/kafKa'),
        meta: {
          title: 'Kafka数据采集',
          icon: '',
        }

      },
      {
        path: '/business/datacenter/dataAcquisition/kafKaList',
        name: 'Kafka数据列表',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/kafKaList'),
        meta: {
          title: 'Kafka数据列表',
          icon: '',
        }

      },
      {
        path: 'kafKaAdd',
        name: 'Kafka数据采集信息',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/kafKaAdd'),
        meta: {
          title: 'Kafka数据采集信息',
          icon: '',
        }

      },

      {
        path: 'offline',
        name: '离线同步',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/offline'),

        meta: {
          i18n: 'offline'
        },
      },
      {
        path: '/business/datacenter/dataAcquisition/offlineList',
        name: '离线数据采集列表',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/offlineList'),

        meta: {
          i18n: 'offline'
        },
      },
      {
        path: 'offlineLog',
        name: '离线数据采集日志',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/offlineLog'),
        
        meta: {
          i18n: 'zInterfaceAc'
        },
      },


      {
        path: '/business/datacenter/dataAcquisition/dataComparison',
        name: '数据对比',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/dataComparison'),

        meta: {
          i18n: 'offline'
        },
      },


      {
        path: '/business/datacenter/dataAcquisition/dataComparisonAdd',
        name: '数据对比新增',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/dataComparisonAdd.vue'),
        meta: {
          i18n: 'offline'
        },
      },


      {
        path: '/business/datacenter/dataAcquisition/dataComparisonLog',
        name: '数据对比记录',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/dataComparisonLog'),

        meta: {
          i18n: 'offline'
        },
      },

      

      {
        path: '/business/datacenter/dataAcquisition/excelForNavicat',
        name: 'EXCLE数据导入',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/excelForNavicat'),

        meta: {
          i18n: 'excelForNavicat'
        },
      },
      {
        path: '/business/datacenter/dataAcquisition/excelList',
        name: 'EXCLE数据导入列表',
        component: () =>
          import('@/views/business/datacenter/dataAcquisition/excelList'),

        meta: {
          i18n: 'excelList'
        },
      }
    ]
  },
  //数据处理
  {
    path: '/dataHandle',
    component: Layout,
    name:'数据处理',
    children: [
      {
        path: 'shunt',
        name: '数据分流',
        component: () =>
          import('@/views/business/datacenter/dataHandle/shunt'),
        // meta: {
        //   title: '数据分流',
        //   icon: '',
        // }
        meta: {
          i18n: 'shunt'
        },
      },
      {
        path: 'shuntAdd',
        name: '数据分流',
        component: () =>
          import('@/views/business/datacenter/dataHandle/shuntAdd'),
        // meta: {
        //   title: '数据分流',
        //   icon: '',
        // }
        meta: {
          i18n: 'shuntEdit'
        },
      },
      {
        path: 'transformation',
        name: '数据转换',
        component: () => import('@/views/business/datacenter/dataHandle/transformation'),
        // meta: {
        //   title: '数据转换',
        //   icon: '',
        // }
        meta: {
          i18n: 'transformation'
        },
      },
      {
        path: 'transformationAdd',
        name: '数据转换新增',
        component: () => import('@/views/business/datacenter/dataHandle/transformationAdd'),
        // meta: {
        //   title: '数据转换',
        //   icon: '',
        // }
        meta: {
          i18n: 'transformationEdit'
        },
      },
      {
        path: 'injection',
        name: '数据注入',
        component: () => import('@/views/business/datacenter/dataHandle/injection'),
        // meta: {
        //   title: '数据注入',
        //   icon: '',
        // },
        meta: {
          i18n: 'injection'
        },
      },
      {
        path: 'injectionAdd',
        name: '数据注入新增',
        component: () =>
          import('@/views/business/datacenter/dataHandle/injectionAdd'),
        // meta: {
        //   title: '数据注入新增',
        //   icon: '',
        // }
        meta: {
          i18n: 'injectionEdit'
        },
      }
    ]
  },
  //数据汇聚
  {
    path: '/dataConvergence',
    component: Layout,
    name:'数据汇聚',
    children: [
      {
        path: 'config',
        name: '数据汇聚配置',
        component: () => import('@/views/business/datacenter/dataConvergence/config'),
        // meta: {
        //   title: '数据汇聚配置',
        //   icon: '',
        // }
        meta: {
          i18n: 'dataConvergence'
        },
      },
      {
        path: 'configAdd',
        name: '数据汇聚配置添加',
        component: () => import('@/views/business/datacenter/dataConvergence/configAdd'),
        // meta: {
        //   title: '数据汇聚配置',
        //   icon: '',
        // }
        meta: {
          i18n: 'configEdit'
        },
      },
      {
        path: 'grouping',
        name: '数据汇聚分组',
        component: () =>
          import('@/views/business/datacenter/dataConvergence/grouping'),
        meta: {
          title: '数据汇聚分组',
          icon: '',
        }
      },
      {
        path: '/dataConvergence/index',
        name: '数据汇聚',
        component: () =>
          import('@/views/business/datacenter/dataConvergence/index'),
        // meta: {
        //   title: '数据汇聚分组',
        //   icon: '',
        // }
        // meta: {
        //   i18n: 'DataAggregation'
        // },
        meta: {
          i18n: 'dataConvergence'
        }
      },
      {
        path: 'groupingAdd',
        name: '数据汇聚分组添加',
        component: () =>
          import('@/views/business/datacenter/dataConvergence/groupingAdd'),
        // meta: {
        //   title: '数据汇聚分组',
        //   icon: '',
        // }
        meta: {
          i18n: 'groupingEdit'
        },
      },
      {
        path: 'zInterfaceAc',
        name: '数据汇聚输出',
        component: () =>
          import('@/views/business/datacenter/dataConvergence/zInterfaceAc'),
        meta: {
          title: '数据汇聚输出',
          icon: '',
        }
      },
      {
        path: 'zInterfaceAcAdd',
        name: '数据汇聚输出添加',
        component: () =>
          import('@/views/business/datacenter/dataConvergence/zInterfaceAcAdd'),
        // meta: {
        //   title: '数据汇聚输出添加',
        //   icon: '',
        // }
        meta: {
          i18n: 'DataAggregationoutputEdit'
        },
      },
    ]
  },
  {
    path: '/DataAssets',
    component: Layout,
    name:'数据资产',
    reject:'dataSubject',
    children: [
      {
        path: '/business/datacenter/datacenter/metadata/datasource/index',
        name: '数据源管理',
        component: () => import('@/views/business/datacenter/datacenter/metadata/datasource/index'),
        // meta: {
        //   title: '数据源管理',
        //   icon: '',
        // }
        meta: {
          i18n: 'datasource'
        },
      },
      {
        path: '/metadata/datasource/index2',
        name: '数据源管理新增',
        component: () => import('@/views/business/datacenter/datacenter/metadata/datasource/index2'),
        // meta: {
        //   title: '数据源管理新增',
        //   icon: '',
        // }
        meta: {
          i18n: 'datasourceEdit'
        },
      },

      {
        path: '/business/datacenter/datacenter/metadata/datacolumn/index',
        name: '元数据管理',
        component: () => import('@/views//business/datacenter/datacenter/metadata/datacolumn/index'),

        meta: {
          i18n: 'datacolumn'
        },
      },




      {
        path: '/business/datacenter/DataAssets/dataSubject',
        name: '数据主题',
        component: () => import('@/views/business/datacenter/DataAssets/dataSubject'),
        meta: {
          i18n: 'dataSubject'
        },
      },
      {
        path: 'dataSubjectAdd',
        name: '数据主题新增',
        component: () => import('@/views/business/datacenter/DataAssets/dataSubjectAdd'),
        // meta: {
        //   title: '数据主题新增',
        //   icon: '',
        // }
        meta: {
          i18n: 'dataSubjectEdit'
        },
      },
      {
        path: '/business/datacenter/DataAssets/dataDictionary',
        name: '数据字典',
        component: () => import('@/views/business/datacenter/DataAssets/dataDictionary'),
        // meta: {
        //   title: '数据字典',
        //   icon: '',
        // }
        meta: {
          i18n: 'dataDictionary'
        },
      },
      {
        path: 'dataDictionaryAdd',
        name: '数据字典新增',
        component: () => import('@/views/business/datacenter/DataAssets/dataDictionaryAdd'),
        // meta: {
        //   title: '数据字典新增',
        //   icon: '',
        // }
        meta: {
          i18n: 'dataDictionaryEdit'
        },
      },
      {
        path: '/business/datacenter/DataAssets/DataDirectory',
        name: '数据目录',
        component: () => import('@/views/business/datacenter/DataAssets/DataDirectory'),
        // meta: {
        //   title: '数据目录',
        //   icon: '',
        // }
        meta: {
          i18n: 'DataDirectory'
        },
      },
      {
        path: '/business/datacenter/DataAssets/Primordial',
        name: '数据血缘',
        component: () => import('@/views/business/datacenter/DataAssets/Primordial'),
        // meta: {
        //   title: '数据血缘',
        //   icon: '',
        // }
        meta: {
          i18n: 'Primordial'
        },
      },
      {
        path: 'PrimordialAdd',
        name: '数据血缘新增',
        component: () => import('@/views/business/datacenter/DataAssets/PrimordialAdd'),
        // meta: {
        //   title: '数据血缘新增',
        //   icon: '',
        // }
        meta: {
          i18n: 'PrimordialEdit'
        },
      },
      {
        path: '/business/datacenter/DataAssets/PrimordialMold',
        name: '数据血缘展示图',
        component: () => import('@/views/business/datacenter/DataAssets/PrimordialMold'),
        // meta: {
        //   title: '数据血缘展示图',
        //   icon: '',
        // }
        meta: {
          i18n: 'PrimordialMold'
        },
      },

    ]
  },
  {
    path: '/DataServices',
    component: Layout,
    name:'数据服务',
    reject:'dataServices',
    children: [
      {
        path: '/business/datacenter/datacenter/market/BlockRecognition/index',
        name: '大块识别展示',
        component: () => import('@/views/business/datacenter/datacenter/market/BlockRecognition/index'),
        meta: {
          title: '大块识别展示',
          icon: '',
        }
      },

      {
        path: '/business/datacenter/datacenter/market/MasterOrSub/index',
        name: '主子查询',
        component: () => import('@/views/business/datacenter/datacenter/market/MasterOrSub/index'),
        meta: {
          title: '主子查询',
          icon: '',
        }
      },
      {
        path: '/business/datacenter/datacenter/market/MasterOrSub/add',
        name: '主子查询新增',
        component: () => import('@/views/business/datacenter/datacenter/market/MasterOrSub/add'),
        meta: {
          title: '主子查询新增',
          icon: '',
        }
      },

      {
        path: '/business/datacenter/datacenter/market/TeamIndicators/index',
        name: '班组指标',
        component: () => import('@/views/business/datacenter/datacenter/market/TeamIndicators/index'),
        meta: {
          title: '班组指标',
          icon: '',
        }
      },
      {
        path: '/business/datacenter/datacenter/market/ApplicationList/index',
        name: '应用列表',
        component: () => import('@/views/business/datacenter/datacenter/market/ApplicationList/index'),
        meta: {
          title: '应用列表',
          icon: '',
        }
      },
      {
        path: '/datacenter/market/IndicatorAnalysis/index',
        name: '烧成系统分析',
        component: () => import('@/views/business/datacenter/datacenter/market/IndicatorAnalysis/index'),
        meta: {
          title: '烧成系统分析',
          icon: '',
        }
      },
      {
        path: '/datacenter/market/IndicatorAnalysis/index2',
        name: '1#原料磨分析',
        component: () => import('@/views/business/datacenter/datacenter/market/IndicatorAnalysis/index2'),
        meta: {
          title: '1#原料磨分析',
          icon: '',
        }
      },
      {
        path: '/datacenter/market/IndicatorAnalysis/ylmComparison',
        name: '原料磨分析对比',
        component: () => import('@/views/business/datacenter/datacenter/market/IndicatorAnalysis/ylmComparison'),
        meta: {
          title: '原料磨分析对比',
          icon: '',
        }
      },



      {
        path: '/datacenter/market/IndicatorAnalysis/index3',
        name: '2#原料磨分析',
        component: () => import('@/views/business/datacenter/datacenter/market/IndicatorAnalysis/index3'),
        meta: {
          title: '2#原料磨分析',
          icon: '',
        }
      },

      {
        path: '/datacenter/market/ApplicationList/detail',
        name: '应用列表新增',
        component: () => import('@/views/business/datacenter/datacenter/market/ApplicationList/detail'),
        meta: {
          title: '应用列表新增',
          icon: '',
        }
      },

      {
        path: '/datacenter/market/ApplicationIndicators/index',
        name: '应用指标',
        component: () => import('@/views/business/datacenter/datacenter/market/ApplicationIndicators/index'),
        meta: {
          title: '应用指标',
          icon: '',
        }
      },



      {
        path: 'dataServices',
        name: '数据服务',
        component: () => import('@/views/business/datacenter/DataServices/DataServices'),
        meta: {
          title: '数据服务',
          icon: '',
        }
      },
      {
        path: 'dataServicesType',
        name: '服务类别管理',
        component: () => import('@/views/business/datacenter/DataServices/dataServicesType'),
        meta: {
          title: '服务类别管理',
          icon: '',
        }
      },
      {
        path: 'NewDataModel',
        name: '数据模型',
        component: () => import('@/views/business/datacenter/DataServices/NewDataModel'),
        meta: {
          i18n: 'NewDataModel'
        },
      },
    ]
  },
  // 数据开发
  {
    path: '/Datadevelopment',
    component: Layout,
    name:'数据开发',
    children: [
      {
        path: '/business/datacenter/Datadevelopment/componentManagement',
        name: '组件管理',
        component: () => import('@/views/business/datacenter/Datadevelopment/componentManagement/index'),
        // meta: {
        //   title: '任务调度',
        //   icon: '',
        // }
        meta: {
          i18n: 'componentManagement'
        },

      },
      {
        path: '/Datadevelopment/componentManagementAdd',
        name: '组件管理新增',
        component: () => import('@/views/business/datacenter/Datadevelopment/componentManagement/add'),
        // meta: {
        //   title: '任务调度',
        //   icon: '',
        // }
        meta: {
          i18n: 'componentManagement'
        },

      },


      {
        path: '/business/datacenter/Datadevelopment/taskScheduling',
        name: '任务调度',
        component: () => import('@/views/business/datacenter/Datadevelopment/taskScheduling'),
        meta: {
          i18n: 'taskScheduling'
        },

      },
      {
        path: 'projectList',
        name: '项目列表',
        component: () => import('@/views/business/datacenter/Datadevelopment/projectList'),
        // meta: {
        //   title: '项目管理',
        //   icon: '',
        // }
        meta: {
          i18n: 'ProjectList'
        },

      },
      {
        path: '/business/datacenter/Datadevelopment/projectList2/index',
        name: '新项目列表',
        component: () => import('@/views/business/datacenter/Datadevelopment/projectList2/index.vue'),
        meta: {
          i18n: 'ProjectList'
        },

      },
      {
        path: '/business/datacenter/Datadevelopment/projectList2/g6',
        name: '任务管理',
        component: () => import('@/views/business/datacenter/Datadevelopment/projectList2/g6.vue'),
        meta: {
          i18n: 'ProjectList'
        },

      },


    ]
  },
  //数据质量
  {
    path: '/dataQuality',
    component: Layout,
    name:'数据质量',
    children: [
      {
        path: '/business/datacenter/Quality/testingTask',
        name: '检测任务管理',
        component: () => import('@/views/business/datacenter/Quality/testingTask'),
        meta: {
          i18n:'testingTask'
        }
      },
      {
        path: '/Quality/testingTaskAdd',
        name: '检测任务管理新增',
        component: () => import('@/views/business/datacenter/Quality/testingTask/add'),
        meta: {
         i18n:'testingTaskAdd'
        }
      },
      {
        path: '/business/datacenter/Quality/taskRecordDetail',
        name: '查看检测记录',
        component: () => import('@/views/business/datacenter/Quality/taskRecordDetail/index'),
        meta: {
         i18n:'taskRecordDetail'
        }
      },
      {
        path: '/Quality/taskRecordDetailSee',
        name: '查看检测记录详情',
        component: () => import('@/views/business/datacenter/Quality/taskRecordDetail/see'),
        meta: {
          title: '查看检测记录详情',
          icon: ''
        }
      },
      {
        path: '/business/datacenter/Quality/judgeTask',
        name: '配置判定任务',
        component: () => import('@/views/business/datacenter/Quality/judgeTask/index'),
        meta: {
         i18n:"judgeTask"
        }
      },
      {
        path: '/Quality/judgeTaskAdd',
        name: '配置判定任务新增',
        component: () => import('@/views/business/datacenter/Quality/judgeTask/add'),
        meta: {
          i18n:"judgeTaskadd"
        }
      },
      {
        path: '/business/datacenter/Quality/JudgeResults',
        name: '判定结果管理',
        component: () => import('@/views/business/datacenter/Quality/JudgeResults/index'),
        meta: {
          i18n: 'JudgeResults'
        }
      },

      {
        path: '/business/datacenter/Quality/qualityTemplate/index',
        name: '质量检测模版',
        component: () => import('@/views/business/datacenter/Quality/qualityTemplate/index'),
        meta: {
          i18n: 'qualityTemplate'
        },
      },
      {
        path: '/business/datacenter/programManage/index',
        name: '质量监测任务',
        component: () => import('@/views/business/datacenter/datacenter/programManage/index'),
        meta: {
          title: '质量监测任务',
          icon: ''
        }
      },

      {
        path: '/business/datacenter/programManage/record',
        name: '质量监测记录',
        component: () => import('@/views/business/datacenter/datacenter/programManage/record'),
        meta: {
          title: '质量监测记录',
          icon: '',
        }
      },

    ]
  }
]
