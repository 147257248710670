import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
/** 行政处罚 */
export const grgovpunishPage = (current, size, params) => {
    return request({
      url: `${ServerNameEnum.SERVER_FX}/grgovpunish/page`,
      method: 'get',
      params: {
        current,
        size,
        ...params
      }
    })
}

export const grgovpunishSubmit = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grgovpunish/submit`,
        method: 'post',
        data: params
    })
}

export const grgovpunishRemove = (ids) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grgovpunish/remove`,
        method: 'post',
        params: {ids}
    })
}

export const grgovpunishDetail = (params) => {
    return request({
        url: `${ServerNameEnum.SERVER_FX}/grgovpunish/detail`,
        method: 'get',
        params
    })
}
