import httpService from "@/router/axios";
// 系统管理-隐患流程配置   分页
export const $_getPageList = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/hiddenworkflow/list",
    params,
  });
};

// 系统管理-隐患流程配置   新增
export const $_addSaveList = (row) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/hiddenworkflow/save",
    method: "post",
    data: row,
  });
};

// 系统管理-隐患流程配置   修改
export const $_updateData = (row) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/hiddenworkflow/update",
    method: "post",
    data: row,
  });
};

// 系统管理-隐患流程配置   详情
export const $_getEdit = (id) => {
  return httpService({
    url: `/api/ivystar-wisdom-security/hiddenworkflow/detail/${id}`,
    method: "get",
  });
};

// 获取流程列表
export const $_getDeploymentList = (params) => {
  return httpService({
    url: "/api/ivystar-workflow/design/model/list",
    params,
  });
};

// 系统管理-隐患类型配置   不分页
export const $_getHiddentypeList2 = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/hiddentype/list2",
    params,
  });
};

// 系统管理-隐患级别配置   不分页
export const $_getHiddenlevelsList2 = (params) => {
  return httpService({
    url: "/api/ivystar-wisdom-security/hiddenlevels/list2",
    params,
  });
};
