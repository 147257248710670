import request from '@/router/axios';
const SERVER_FX = '/api/sinoma-hse-prj'
//项目相关方组织中拉黑/黑名单组织拉黑
export const prjblacklistorgheadSubmit = (params) => {
    return request({
        url: SERVER_FX + '/prjblacklistorghead/submit',
        method: 'post',
        data: params
    })
}

// 项目中黑名单组织列表
export const prjblacklistorgheadPage = (params) => {
    return request({
        url: SERVER_FX + '/prjblacklistorghead/page',
        method: 'get',
        params: params
    })
}

// 黑名单组织功能列表
export const getList = (current, size, params) => {
    return request({
      url: SERVER_FX + '/prjblacklistorghead/page',
      method: 'get',
      params: {
        ...params,
        current,
        size,
      }
    })
  }

//   黑名单组织详情列表
export const prjblacklistorglist = (params) => {
    return request({
      url: SERVER_FX + '/prjblacklistorglist/list',
      method: 'get',
      params: {
        ...params
      }
    })
  }

//   黑名单组织详情修改
export const prjblacklistorglistSubmit = (params) => {
    return request({
        url: SERVER_FX + '/prjblacklistorglist/submit',
        method: 'post',
        data: params
    })
}