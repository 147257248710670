import Layout from '@/page/index/'
export default [{
  path: '/desk',
  component: Layout,
  children: [
    {
      path: '/notice/edit',
      name: ' 系统公告编辑',
      component: () =>
        import('@/views/desk/noticeEdit'),
      meta: {
        i18n: 'noticeEdit'
      }
    },
    {
      path: '/notice/detail',
      name: ' 系统公告详情',
      component: () => import('@/views/desk/noticeDetail'),
    },
    {
      path: '/notice/add',
      name: ' 系统公告新增',
      component: () =>
        import('@/views/desk/noticeEdit'),
      meta: {
        i18n: 'noticeAdd'
      },
    },
    {
      path: '/desk/notice',
      name: ' 系统公告',
      component: () =>
        import('@/views/desk/notice.vue'),
      meta: {
        i18n: 'notices'
      },
    },
    {
      path: '/desk/personalSharing',
      name: ' 内部分享',
      component: () =>
        import('@/views/desk/personalSharing'),
      meta: {
        i18n: 'personalSharing'
      }
    },
    {
      path: '/personalSharing/edit',
      name: ' 内部分享编辑',
      component: () =>
        import('@/views/desk/personalSharingEdit'),
      meta: {
        i18n: 'personalSharingEdit'
      }
    },
    {
      path: '/personalSharing/add',
      name: ' 内部分享新增',
      component: () =>
        import('@/views/desk/personalSharingEdit'),
      meta: {
        i18n: 'personalSharingAdd'
      },
    },
    {
      path: '/desk/videoPlayEdit',
      name: ' 视频数据编辑',
      component: () =>
        import('@/views/desk/videoPlayEdit'),
    },
    {
      path: '/desk/videoPlayerConfig/add',
      name: '摄像头新增',
      component: () =>
        import('@/views/desk/videoPlayerConfigAdd'),
    },
    {
      path: '/desk/taskTodo',
      name: ' 任务待办',
      component: () =>
        import('@/views/desk/taskTodo'),
    },
    {
      path: '/formTemplate',
      name: ' 自定义表单模板',
      component: () =>
        import('@/views/formTemplate/index.vue'),
    },
    {
      path: '/formTemplate/addTemplate',
      name: ' 模板制作',
      component: () =>
        import('@/views/formTemplate/addTemplate.vue'),
    },
    {
      path: "/formTemplate/detail/:code",
      name: '模板详情',
      component: () =>
        import(`@/views/formTemplate/templateDetail.vue`),
    },
    {
      path: "/formTemplate/newDetail",
      name: '模板详情',
      component: () =>
        import(`@/views/formTemplate/newDetails.vue`),
    },
  ]
}]

