import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';



export const  Remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtLimitSpaceLedger/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
export const  Save = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtLimitSpaceLedger/saveOrUpdate',
    method: 'post',
    data
  })
}

export const  Detail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtLimitSpaceLedger/detail',
    method: 'get',
    params: {
      id
    }
  })
}
export const  List = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/wtLimitSpaceLedger/list',
    method: 'get',
    params: {
      ...params,
    }
  })
}
