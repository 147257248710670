import request from '@/router/axios';

export const getList = (params) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/hdcheckpro/page',
    method: 'get',
    params
  })
}
export const submit = (data) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/hdcheckpro/submit',
    method: 'post',
    data
  })
}
export const detail = (params) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/hdcheckpro/detail',
    method: 'get',
    params
  })
}
export const remove = (data) => { //分页
  return request({
    url: '/api/sinoma-hse-prj/hdcheckpro/remove',
    method: 'post',
    data
  })
}
