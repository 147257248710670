import httpService from "@/router/axios";

//  组织管理 tree
export const $_tenantTree = (params) => {
  return httpService({
    url: `/api/ivystar-system/tenant/tree`,
    method: "get",
    params
  });
};

//  组织管理  新增或修改
export const $_tenantSubmit = (params) => {
  return httpService({
    url: "/api/ivystar-system/tenant/submit",
    method: "post",
    data: params,
  });
};

//  组织管理  删除
export const $_tenantRemove = (ids) => {
  return httpService({
    url: '/api/ivystar-system/tenant/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
