import Layout from '@/page/index/'
export default [{
  path: '/system',
  component: Layout,
  redirect: '/system',
  children: [
    {
      path: '/system/user',
      name: '用户管理',
      meta: {
        i18n: 'user'
      },
      component: () =>
        import('@/views/system/user.vue'),
    },
    {
      path: '/system/guration/index',
      name: '组态列表',
      meta: {
        i18n: 'guration'
      },
      component: () =>
        import('@/views/system/guration/index.vue'),
    },
    {
      path: '/system/guration/add',
      name: '组态新增',
      meta: {
        i18n: 'gurationEdit'
      },
      component: () =>
        import('@/views/system/guration/add.vue'),
    },
    {
      path: '/system/serialNumber/index',
      name: '编码规则',
      meta: {
        i18n: 'serialNumber'
      },
      component: () => import('@/views/system/serialNumber/index.vue'),
    },
    {
      path: '/system/serialNumber/add',
      name: '编码规则新增',
      meta: {
        i18n: 'serialNumberEdit'
      },
      component: () => import('@/views/system/serialNumber/edit'),
    },
    {
      path: '/system/serialNumber/edit',
      name: '编码规则编辑',
      meta: {
        i18n: 'serialNumberEdit'
      },
      component: () => import('@/views/system/serialNumber/edit'),
    },
    {
      path: '/system/serialNumber/view',
      name: '编码规则查看',
      meta: {
        i18n: 'serialNumberView'
      },
      component: () => import('@/views/system/serialNumber/edit'),
    },
    {
      path: '/system/international/index',
      name: '国际化修改',
      meta: {
        i18n: 'international'
      },
      component: () =>
        import('@/views/system/international/index.vue'),
    },

    {
      path: '/international/add',
      name: '国际化修改',
      meta: {
        i18n: 'international'
      },
      component: () =>
        import('@/views/system/international/add.vue'),
    },
    {
      path: '/system/attendance/index',
      name: '打卡设置',
      meta: {
        i18n: 'attendance'
      },
      component: () =>
        import('@/views/system/attendance/index.vue'),
    },
    {
      path: '/attendance/add',
      name: '打卡设置新增',
      meta: {
        i18n: 'attendanceAdd'
      },
      component: () =>
        import('@/views/system/attendance/add'),
    },
    {
      path: '/system/attendance/clockingRecord',
      name: '打卡记录',
      meta: {
        i18n: 'clockingRecord'
      },
      component: () =>
        import('@/views/system/attendance/clockingRecord.vue'),
    },
    {
      path: '/user/add',
      name: '用户管理新增',
      meta: {
        i18n: 'userAdd'
      },
      component: () =>
        import('@/views/system/userEdit.vue'),
    },
    {
      path: '/user/edit',
      name: '用户管理编辑',
      meta: {
        i18n: 'userEdit'
      },
      component: () =>
        import('@/views/system/userEdit.vue'),
    },
    {
      path: '/user/add1',
      name: '用户管理新增',
      meta: {
        i18n: 'userAdd'
      },
      component: () =>
        import('@/views/system/userEdit1.vue'),
    },
    {
      path: '/user/edit1',
      name: '用户管理编辑',
      meta: {
        i18n: 'userEdit'
      },
      component: () =>
        import('@/views/system/userEdit1.vue'),
    },
    {
      path: '/user/add2',
      name: '用户管理新增',
      meta: {
        i18n: 'userAdd'
      },
      component: () =>
        import('@/views/system/userEdit2.vue'),
    },
    {
      path: '/user/edit2',
      name: '用户管理编辑',
      meta: {
        i18n: 'userEdit'
      },
      component: () =>
        import('@/views/system/userEdit2.vue'),
    },
    {
      path: '/system/report',
      name: '报表管理',
      meta: {
        i18n: 'report'
      },
      component: () =>
        import('@/views/system/report.vue'),
    },
    {
      path: '/system/broadcast',
      name: '广播管理',
      meta: {
        i18n: 'broadcast'
      },
      component: () =>
        import('@/views/system/broadcast.vue'),
    },
    {
      path: '/system/broadcastEdit',
      name: '广播管理编辑',
      meta: {
        i18n: 'broadcastEdit'
      },
      component: () =>
        import('@/views/system/broadcastEdit.vue'),
    },
    {
      path: '/system/myReport',
      name: '我的报表',
      meta: {
        i18n: 'myReport'
      },
      component: () =>
        import('@/views/system/myReport.vue'),
    },
    {
      path: '/system/intfServiceType/index',
      name: '服务类型',
      meta: {
        i18n: 'serviceType'
      },
      component: () =>
        import('@/views/system/intfServiceType/index.vue'),
    },
    {
      path: '/system/intfServiceType/edit',
      name: '服务类型',
      meta: {
        i18n: 'serviceType'
      },
      component: () =>
        import('@/views/system/intfServiceType/edit.vue'),
    },
    {
      path: '/system/adInfo',
      name: '轮播管理',
      meta: {
        i18n: 'adInfo'
      },
      component: () =>
        import('@/views/system/adInfo.vue'),
    },
    {
      path: '/monitor/log/usual',
      name: '通用日志',
      meta: {
        i18n: 'usual'
      },
      component: () =>
        import('@/views/monitor/log/usual'),
    },
    {
      path: '/monitor/log/api',
      name: '接口日志',
      meta: {
        i18n: 'apis'
      },
      component: () =>
        import('@/views/monitor/log/api'),
    },
    {
      path: '/monitor/log/error',
      name: '错误日志',
      meta: {
        i18n: 'errors'
      },
      component: () =>
        import('@/views/monitor/log/error'),
    },
    {
      path: '/system/appMenu',
      name: '手机端菜单管理',
      meta: {
        i18n: 'appMenu'
      },
      component: () =>
        import('@/views/system/appMenu'),
    },
    {
      path: '/menu/appMenuAdd',
      name: 'APP菜单管理新增',
      meta: {
        i18n: 'appMenuAdd'
      },
      component: () =>
        import('@/views/system/appMenuEdit'),
    },
    {
      path: '/menu/appMenuEdit',
      name: 'APP菜单管理编辑',
      meta: {
        i18n: 'appMenuEdit'
      },
      component: () =>
        import('@/views/system/appMenuEdit'),
    },
    {
      path: '/system/appVersion/index',
      name: 'app版本',
      meta: {
        i18n: 'appVersion'
      },
      component: () =>
        import('@/views/system/appVersion/index.vue'),
    },
    {
      path: '/system/appVersion/add',
      name: 'app版本新增',
      meta: {
        i18n: 'appVersionadd'
      },
      component: () =>
        import('@/views/system/appVersion/edit.vue'),
    },
    {
      path: '/system/user',
      name: '用户管理',
      meta: {
        i18n: 'user'
      },
      component: () =>
        import('@/views/system/user.vue'),
    },
    {
      path: '/system/dept',
      name: '机构管理',
      meta: {
        i18n: 'dept'
      },
      component: () =>
        import('@/views/system/dept.vue'),
    },
    {
      path: '/dept/deptEdit',
      name: '机构管理',
      meta: {
        i18n: 'deptEdit'
      },
      component: () =>
        import('@/views/system/deptEdit.vue'),
    },
    {
      path: '/system/param/index',
      name: '系统参数',
      meta: {
        i18n: 'parameter'
      },
      component: () =>
        import('@/views/system/param/index.vue'),
    },
    {
      path: '/system/add',
      name: '系统参数新增',
      meta: {
        i18n: 'meterEdit'
      },
      component: () =>
        import('@/views/system/param/add.vue'),
    },
    {
      path: '/system/post',
      name: '岗位管理',
      meta: {
        i18n: 'post'
      },
      component: () =>
        import('@/views/system/post.vue'),
    },
    {
      path: '/post/postEdit',
      name: '岗位管理新增',
      meta: {
        i18n: 'postEdit'
      },
      component: () =>
        import('@/views/system/postEdit.vue'),
    },
    {
      path: '/system/dict',
      name: '系统字典',
      meta: {
        i18n: 'dict'
      },
      component: () =>
        import('@/views/system/dict.vue'),
    },
    {
      path: '/dict/dictEdit',
      name: '系统字典新增',
      meta: {
        i18n: 'dictEdit'
      },
      component: () =>
        import('@/views/system/dictEdit.vue'),
    },
    {
      path: '/system/dictbiz',
      name: '业务字典',
      meta: {
        i18n: 'dictbiz'
      },
      component: () =>
        import('@/views/system/dictbiz.vue'),
    },
    {
      path: '/dictbiz/dictbizEdit',
      name: '业务字典新增',
      meta: {
        i18n: 'dictbizEdit'
      },
      component: () =>
        import('@/views/system/dictbizEdit.vue'),
    },
    {
      path: '/system/menu',
      name: 'web菜单管理',
      meta: {
        i18n: 'menu'
      },
      component: () =>
        import('@/views/system/menu.vue'),
    },
    {
      path: '/menu/menuEdit',
      name: 'web菜单管理新增',
      meta: {
        i18n: 'menuEdit',
        keepAlive: true
      },
      component: () =>
        import('@/views/system/menuEdit.vue'),
    },
    {
      path: '/system/bottomMenu',
      name: 'app底部菜单管理',
      meta: {
        i18n: 'bottomMenu'
      },
      component: () =>
        import('@/views/system/bottomMenu.vue'),
    },
    {
      path: '/system/bottomMenuEdit',
      name: 'app底部菜单管理新增',
      meta: {
        i18n: 'bottomMenuEdit'
      },
      component: () =>
        import('@/views/system/bottomMenuEdit.vue'),
    },
    {
      path: '/dict/config',
      name: '字典配置',
      meta: {
        i18n: 'config'
      },
      component: () =>
        import('@/views/system/dictConfig.vue'),
    },
    {
      path: '/dict/configEdit',
      name: '字典配置',
      meta: {
        i18n: 'dictConfig'
      },
      component: () =>
        import('@/views/system/dictConfigEdit.vue'),
    },
    {
      path: '/dictbiz/config',
      name: '字典配置',
      meta: {
        i18n: 'dictbizConfig'
      },
      component: () =>

        import('@/views/system/dictbizConfig.vue'),
    },

    {
      path: '/system/mediumscreen/index',
      name: '中屏管理',
      meta: {
        i18n: 'mediumscreen'
      },
      component: () => import('@/views/system/mediumscreen/index.vue'),
    },
    {
      path: '/system/onlineUser/index',
      name: '在线用户',
      meta: {
        i18n: 'onineUser'//这是重点
      },
      component: () => import('@/views/system/onlineUser/index.vue'),
    },
    {
      path: '/system/mediumscreenmanage/add',
      name: '中屏管理新增',
      meta: {
        i18n: 'mediumscreenmanageAdd'
      },
      component: () => import('@/views/system/mediumscreen/add.vue'),
    },
    {
      path: '/system/mediumscreenmanage/edit',
      name: '中屏管理编辑',
      meta: {
        i18n: 'mediumscreenmanageEdit'
      },
      component: () => import('@/views/system/mediumscreen/add.vue'),
    },
    {
      path: '/system/mediumscreenmanage/view',
      name: '中屏管理查看',
      meta: {
        i18n: 'mediumscreenmanageView'
      },
      component: () => import('@/views/system/mediumscreen/add.vue'),
    },

    {
      path: '/system/aboutUs/index',
      name: '关于我们',
      meta: {
        i18n: 'aboutUs'
      },
      component: () => import('@/views/system/aboutUs/index.vue'),
    },

    {
      path: '/system/aboutUs/add',
      name: '关于我们新增',
      meta: {
        i18n: 'aboutUsEdit'
      },
      component: () => import('@/views/system/aboutUs/add.vue'),
    },


    {
      path: '/dictbiz/configEdit',
      name: '字典配置',
      meta: {
        i18n: 'dictbiz'
      },
      component: () =>

        import('@/views/system/dictbizConfigEdit.vue'),
    },

    {
      path: '/report/reportEdit/add',
      name: '报表管理新增',
      meta: {
        i18n: 'reportAdd'
      },
      component: () =>
        import('@/views/system/reportEdit.vue'),
    },
    {
      path: '/report/reportEdit/edit',
      name: '报表管理编辑',
      meta: {
        i18n: 'reportEdit'
      },
      component: () =>
        import('@/views/system/reportEdit.vue'),
    },
    {
      path: '/report/reportEdit/view',
      name: '报表管理查看',
      meta: {
        i18n: 'reportView'
      },
      component: () =>
        import('@/views/system/reportEdit.vue'),
    },
    {
      path: '/system/knowledge',
      name: '知识库',
      meta: {
        i18n: 'knowledge'
      },
      component: () =>
        import('@/views/system/knowledge.vue'),
    },
    {
      path: '/knowledge/knowledgeEdit/add',
      name: '知识库新增',
      meta: {
        i18n: 'knowledgeAdd'
      },
      component: () =>
        import('@/views/system/knowledgeEdit.vue'),
    },
    {
      path: '/knowledge/knowledgeEdit/edit',
      name: '知识库编辑',
      meta: {
        i18n: 'knowledgeEdit'
      },
      component: () =>
        import('@/views/system/knowledgeEdit.vue'),
    },
    {
      path: '/knowledge/knowledgeEdit/view',
      name: '知识库查看',
      meta: {
        i18n: 'knowledgeView'
      },
      component: () =>
        import('@/views/system/knowledgeEdit.vue'),
    },
    {
      path: '/xxlJob/task/index',
      name: '任务列表',
      meta: {
        i18n: 'JobTask'
      },
      component: () =>
        import('@/views/xxlJob/task/index.vue'),
    },
    {
      path: '/xxlJob/task/add',
      name: '任务列表新增',
      meta: {
        i18n: 'JobTaskAdd'
      },
      component: () =>
        import('@/views/xxlJob/task/add.vue'),
    },
    {
      path: '/xxlJob/task/edit',
      name: '任务列表编辑',
      meta: {
        i18n: 'JobTaskEdit'
      },
      component: () =>
        import('@/views/xxlJob/task/edit.vue'),
    },

    {
      path: '/xxlJob/dispatch/index',
      name: '调度日志',
      meta: {
        i18n: 'dispatch'
      },
      component: () =>
        import('@/views/xxlJob/dispatch/index.vue'),
    },
    {
      path: '/xxlJob/dispatch/edit',
      name: '调度日志新增',
      meta: {
        i18n: 'dispatchEdit'
      },
      component: () =>
        import('@/views/xxlJob/dispatch/edit.vue'),
    },

    {
      path: '/xxlJob/actuator/index',
      name: '执行器管理',
      meta: {
        i18n: 'actuator'
      },
      component: () =>
        import('@/views/xxlJob/actuator/index.vue'),
    },
    {
      path: '/xxlJob/actuator/edit',
      name: '执行器管理新增',
      meta: {
        i18n: 'actuatorEdit'
      },
      component: () =>
        import('@/views/xxlJob/actuator/edit.vue'),
    },

    // {
    //   path: '/monitoring/add',
    //   name: '监控点位新增',
    //   meta: {
    //     i18n: 'monitoringEdit'
    //   },
    //   component: () =>
    //
    //     import('@/views/system/monitoring/add.vue'),
    // },


    {
      path: '/system/interfaceLog/interfaceList',
      name: '接口日志',
      meta: {
        i18n: 'interfaceLog'
      },
      component: () =>

        import('@/views/system/interfaceLog/interfaceList.vue'),
    },
    {
      path: '/system/interfaceLog/interfaceList',
      name: '接口日志新增',
      meta: {
        i18n: 'interfaceLogEdit'
      },
      component: () =>

        import('@/views/system/interfaceLog/interfaceList.vue'),
    },
    {
      path: '/ad/adInfoEdit',
      name: '轮播管理新增',
      meta: {
        i18n: 'adInfoEdit'
      },
      component: () =>

        import('@/views/system/adInfoEdit.vue'),
    },
    {
      path: '/system/interface/interfaceList',
      name: '接口管理',
      meta: {
        i18n: 'interfaceList'
      },
      component: () =>

        import('@/views/system/interface/interfaceList.vue'),
    },
    {
      path: '/system/interface/interfaceListEdit',
      name: '接口管理新增',
      meta: {
        i18n: 'interfaceListEdit'
      },
      component: () =>

        import('@/views/system/interface/interfaceListEdit.vue'),
    },

    {
      path: '/system/appVersion/index',
      name: 'app版本控制',
      meta: {
        icon: '',
        title: "app版本控制"
      },
      component: () =>

        import('@/views//system/appVersion/index'),
    },
    {
      path: '/system/onlineDev/visualPortal/index',
      name: '门户设计',
      meta: {
        i18n: 'visualPortal'
      },
      component: () =>

        import('@/views/system/onlineDev/visualPortal/index'),
    },
    {
      path: '/system/onlineDev/onlineDev/dataModel/index',
      name: '数据建模',
      meta: {
        i18n: 'dataModel'
      },
      component: () =>

        import('@/views/system/onlineDev/onlineDev/dataModel/index'),
    },
    {
      path: '/system/onlineDev/onlineDev/abilityDesign/index',
      name: '功能设计',
      meta: {
        i18n: 'abilityDesign'
      },
      component: () =>

        import('@/views/system/onlineDev/onlineDev/abilityDesign/index'),
    },
    {
      path: '/system/onlineDev/onlineDev/flowDesign/index',
      name: '流程设计',
      component: () =>
        import('@/views/system/onlineDev/onlineDev/flowDesign/index'),
    },
    {
      path: '/system/dataInterface/index',
      name: '数据接口',
      meta: {
        i18n: 'dataInterface'
      },
      component: () =>

        import('@/views/system/dataInterface/index.vue'),
    },
    {
      path: '/system/dataLink/index',
      name: '数据连接',
      meta: {
        i18n: 'dataLink'
      },
      component: () =>

        import('@/views/system/dataLink/index.vue'),
    },
    {
      path: '/system/dataLink/edit',
      name: '数据连接新增',
      meta: {
        i18n: 'dataLinkEdit'
      },
      component: () =>

        import('@/views/system/dataLink/edit.vue'),
    },
    {
      path: '/system/appVersion/view',
      name: 'app版本控制',
      meta: {
        title: 'app版本控制'
      },
      component: () =>
        import('@/views/system/appVersion/edit'),
      children: [
        {
          path: '/system/appVersion/edit',
        },
        {
          path: '/system/appVersion/add',
        },
        {
          path: '/system/appVersion/view',
        },
      ]
    },
    {
      path: '/system/param',
      name: '参数管理',
      meta: {
        i18n: 'params'
      },
      component: () =>
        import('@/views/system/param.vue'),
    },
    {
      path: '/system/param/add',
      name: '参数管理新增',
      meta: {
        i18n: 'paramAdd'
      },
      component: () =>
        import('@/views/system/paramEdit.vue'),
    },
    {
      path: '/system/defaultConfig',
      name: '默认配置',
      meta: {
        i18n: 'defaultConfig'
      },
      component: () =>
        import('@/views/system/defaultConfig/index.vue'),
    },
    {
      path: '/system/defaultConfig/add',
      name: '默认配置新增',
      meta: {
        i18n: 'defaultConfigAdd'
      },
      component: () =>
        import('@/views/system/defaultConfig/form.vue'),
    },
    {
      path: '/system/defaultConfig/edit',
      name: '默认配置编辑',
      meta: {
        i18n: 'defaultConfigEdit'
      },
      component: () =>
        import('@/views/system/defaultConfig/form.vue'),
    },
    {
      path: '/system/defaultConfig/view',
      name: '默认配置查看',
      meta: {
        i18n: 'defaultConfigView'
      },
      component: () =>
        import('@/views/system/defaultConfig/form.vue'),
    },
    {
      path: '/system/dataStandard',
      name: '数据标准',
      meta: {
        i18n: 'dataStandard'
      },
      component: () =>
        import('@/views/system/dataStandard/index.vue'),
    },
    {
      path: '/system/dataStandard/add',
      name: '数据标准新增',
      meta: {
        i18n: 'dataStandardAdd'
      },
      component: () =>
        import('@/views/system/dataStandard/form.vue'),
    },
    {
      path: '/system/dataStandard/edit',
      name: '数据标准编辑',
      meta: {
        i18n: 'dataStandardEdit'
      },
      component: () =>
        import('@/views/system/dataStandard/form.vue'),
    },
    {
      path: '/system/dataStandard/view',
      name: '数据标准查看',
      meta: {
        i18n: 'dataStandardView'
      },
      component: () =>
        import('@/views/system/dataStandard/form.vue'),
    },
    {
      path: '/system/sync/dept',
      name: '机构数据',
      meta: {
        i18n: 'syncDept'
      },
      component: () =>
        import('@/views/system/sync/dept/index.vue'),
    },
    {
      path: '/system/sync/dept/add',
      name: '机构新增',
      meta: {
        i18n: 'syncDeptAdd'
      },
      component: () =>
        import('@/views/system/sync/dept/edit.vue'),
    },
    {
      path: '/system/sync/dept/edit',
      name: '机构编辑',
      meta: {
        i18n: 'syncDeptEdit'
      },
      component: () =>
        import('@/views/system/sync/dept/edit.vue'),
    },
    {
      path: '/system/sync/dept/view',
      name: '机构查看',
      meta: {
        i18n: 'syncDeptView'
      },
      component: () =>
        import('@/views/system/sync/dept/edit.vue'),
    },
    {
      path: '/system/sync/user',
      name: '用户数据',
      meta: {
        i18n: 'syncUser'
      },
      component: () =>
        import('@/views/system/sync/user/index.vue'),
    },
    {
      path: '/system/sync/user/add',
      name: '用户新增',
      meta: {
        i18n: 'syncUserAdd'
      },
      component: () =>
        import('@/views/system/sync/user/edit.vue'),
    },
    {
      path: '/system/sync/user/edit',
      name: '用户编辑',
      meta: {
        i18n: 'syncUserEdit'
      },
      component: () =>
        import('@/views/system/sync/user/edit.vue'),
    },
    {
      path: '/system/sync/user/view',
      name: '用户查看',
      meta: {
        i18n: 'syncUserView'
      },
      component: () =>
        import('@/views/system/sync/user/edit.vue'),
    },
    {
      path: '/system/sync/project',
      name: '项目数据',
      meta: {
        i18n: 'syncProject'
      },
      component: () =>
        import('@/views/system/sync/project/index.vue'),
    },
    {
      path: '/system/sync/project/add',
      name: '项目新增',
      meta: {
        i18n: 'syncProjectAdd'
      },
      component: () =>
        import('@/views/system/sync/project/edit.vue'),
    },
    {
      path: '/system/sync/project/edit',
      name: '项目编辑',
      meta: {
        i18n: 'syncProjectEdit'
      },
      component: () =>
        import('@/views/system/sync/project/edit.vue'),
    },
    {
      path: '/system/sync/project/view',
      name: '项目查看',
      meta: {
        i18n: 'syncProjectView'
      },
      component: () =>
        import('@/views/system/sync/project/edit.vue'),
    },
    {
      path: '/system/sync/corpnOrg',
      name: '法人组织管理',
      meta: {
        i18n: 'syncCorpnOrg'
      },
      component: () =>
        import('@/views/system/sync/corpnOrg/index.vue'),
    },
    {
      path: '/system/sync/corpnOrg/add',
      name: '法人组织管理新增',
      meta: {
        i18n: 'syncCorpnOrgAdd'
      },
      component: () =>
        import('@/views/system/sync/corpnOrg/edit.vue'),
    },
    {
      path: '/system/sync/corpnOrg/edit',
      name: '法人组织管理编辑',
      meta: {
        i18n: 'syncCorpnOrgEdit'
      },
      component: () =>
        import('@/views/system/sync/corpnOrg/edit.vue'),
    },
    {
      path: '/system/sync/corpnOrg/view',
      name: '法人组织管理查看',
      meta: {
        i18n: 'syncCorpnOrgView'
      },
      component: () =>
        import('@/views/system/sync/corpnOrg/edit.vue'),
    },
    {
      path: '/system/sync/supplier',
      name: '供应商管理',
      meta: {
        i18n: 'syncSupplier'
      },
      component: () =>
        import('@/views/system/sync/supplier/index.vue'),
    },
    {
      path: '/system/sync/supplier/add',
      name: '供应商管理新增',
      meta: {
        i18n: 'syncSupplierAdd'
      },
      component: () =>
        import('@/views/system/sync/supplier/edit.vue'),
    },
    {
      path: '/system/sync/supplier/edit',
      name: '供应商管理编辑',
      meta: {
        i18n: 'syncSupplierEdit'
      },
      component: () =>
        import('@/views/system/sync/supplier/edit.vue'),
    },
    {
      path: '/system/sync/supplier/view',
      name: '供应商管理查看',
      meta: {
        i18n: 'syncSupplierView'
      },
      component: () =>
        import('@/views/system/sync/supplier/edit.vue'),
    },
    {
      path: '/siteManagement/safetySigns/index',
      name: '安全标识设置',
      meta: {
        i18n: 'safetySigns'
      },
      component: () =>
        import('@/views/siteManagement/safetySigns/index.vue'),
    },
    {
      path: '/siteManagement/safetySigns/add',
      name: '安全标识设置新增',
      meta: {
        i18n: 'safetySignsAdd'
      },
      component: () =>
        import('@/views/siteManagement/safetySigns/edit.vue'),
    },
    {
      path: '/siteManagement/safetySigns/edit',
      name: '安全标识设置编辑',
      meta: {
        i18n: 'safetySignsEdit'
      },
      component: () =>
        import('@/views/siteManagement/safetySigns/edit.vue'),
    },
    {
      path: '/siteManagement/safetySigns/view',
      name: '安全标识设置查看',
      meta: {
        i18n: 'safetySignsView'
      },
      component: () =>
        import('@/views/siteManagement/safetySigns/edit.vue'),
    },
    {
      path: '/param/paramEdit',
      name: '参数管理新增',
      meta: {
        i18n: 'paramEdit'
      },
      component: () =>
        import('@/views/system/paramEdit.vue'),
    },
    {
      path: '/system/param/view',
      name: '参数管理',
      meta: {
        title: '参数管理'
      },
      component: () =>
        import('@/views/system/paramEdit'),
      children: [
        {
          path: '/system/param/edit',
        },
        {
          path: '/system/param/add',
        },
        {
          path: '/system/param/view',
        },
      ]
    },
    {
      path: '/system/tenant',
      name: '租户管理',
      meta: {
        i18n: 'tenant'
      },
      component: () =>
        import('@/views/system/tenant'),
    },
    {
      path: '/tenant/tenantEdit',
      name: '租户管理新增',
      meta: {
        i18n: 'tenantEdit'
      },
      component: () =>
        import('@/views/system/tenantEdit'),
    },
    {
      path: '/system/param/tenant',
      name: '租户管理',
      meta: {
        title: '租户管理'
      },
      component: () =>
        import('@/views/system/tenantEdit'),
      children: [
        {
          path: '/system/tenant/edit',
        },
        {
          path: '/system/tenant/add',
        },
        {
          path: '/system/tenant/view',
        },
      ]
    },
    {
      path: '/system/client',
      name: '应用管理',
      meta: {
        i18n: 'client'
      },
      component: () =>
        import('@/views/system/client.vue'),
    },
    {
      path: '/client/clientEdit',
      name: '应用管理新增',
      meta: {
        i18n: 'clientEdit'
      },
      component: () =>
        import('@/views/system/clientEdit.vue'),
    },
    {
      path: '/system/client/view',
      name: '应用管理',
      meta: {
        title: '应用管理'
      },
      component: () =>
        import('@/views/system/clientEdit'),
      children: [
        {
          path: '/system/client/edit',
        },
        {
          path: '/system/client/add',
        },
        {
          path: '/system/client/view',
        },
      ]
    },
    {
      path: '/system/feedback',
      name: '意见反馈',
      meta: {
        i18n: 'feedback'
      },
      component: () =>
        import('@/views/system/feedback.vue'),
    },
    {
      path: '/system/feedbackView',
      name: '意见反馈查看',
      meta: {
        i18n: 'feedbackView'
      },
      component: () =>
        import('@/views/system/FeedbackView.vue'),
    },
    {
      path: '/top/top-logs',
      name: '本地错误日志',
      meta: {
        i18n: 'localErrorLog'
      },
      component: () =>
        import('@/page/index/top/top-logs.vue'),
    },
    {
      path: '/stat/functionRecord',
      name: '功能记录',
      meta: {
        i18n: 'functionRecord'
      },
      component: () =>
        import('@/views/system/stat/functionRecord.vue'),
    },
    {
      path: '/stat/functionFrequency',
      name: '功能频次',
      meta: {
        i18n: 'functionFrequency'
      },
      component: () =>
        import('@/views/system/stat/functionFrequency.vue'),
    },
    {
      path: '/stat/buttonRecord',
      name: '按钮记录',
      meta: {
        i18n: 'buttonRecord'
      },
      component: () =>
        import('@/views/system/stat/buttonRecord.vue'),
    },
    {
      path: '/stat/buttonFrequency',
      name: '按钮频次',
      meta: {
        i18n: 'buttonFrequency'
      },
      component: () =>
        import('@/views/system/stat/buttonFrequency.vue'),
    },

  ],
}]
