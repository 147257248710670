<template>
  <el-dialog
    :before-close="refet"
    :title="this.$t('cip.cmn.components.chooseLangue.title.chooseLangueTitle')"
    :visible.sync="dialogVisible"
    center
    width="30%"
  >
    <el-radio-group v-model="radio" style="width:100%;text-aline:center">
      <el-radio :disabled="language === 'zh'" label="zh">{{ this.$t('cip.cmn.components.chooseLangue.field.zh') }}</el-radio>
      <el-radio :disabled="language === 'en'" label="en">{{ this.$t('cip.cmn.components.chooseLangue.field.en') }}</el-radio>
    </el-radio-group>
    <span slot="footer" class="dialog-footer">
      <el-button @click="refet">{{ this.$t('cip.cmn.btn.celBtn') }}</el-button>
      <el-button type="primary" @click="handleSetLanguage">{{ this.$t('cip.cmn.btn.defBtn') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "top-lang",
  data() {
    return {
      radio: "",
    };
  },
  created() {
    this.radio = this.language;
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["language", "tag"])
  },
  props: ["dialogVisible"],
  methods: {
    refet() {
      this.$emit('hideLang');
    },
    handleSetLanguage() {
      this.$set(this.$i18n, 'locale', this.radio)
      this.$store.commit("SET_LANGUAGE", this.radio);
      let tag = this.tag;
      let title = this.$router.$avueRouter.generateTitle(
        tag.label,
        (tag.meta || {}).i18n
      );
      //根据当前的标签也获取label的值动态设置浏览器标题
      this.$router.$avueRouter.setTitle(title);
      this.$emit('hideLang');
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
