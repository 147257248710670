import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const getTree = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/strisksourcetype/typeAndSourcetree',
    method: 'get',
    params
  })
}
export const getList = (current,size,params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/strisklibrary/page',
    method: 'get',
    params:{
      current,
      size,
      ...params
    }
  })
}
export const submit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/strisksourcetype/submit',
    method: 'POST',
    data
  })
}
export const strisksourceSubmit = (data) => {//风险源表接口
  return request({
    url: ServerNameEnum.SERVER_FX + '/strisksource/submit',
    method: 'POST',
    data
  })
}

export const list = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/strisksource/list',
    method: 'GET',
    params
  })
}
export const remove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/strisksourcetype/remove',
    method: 'post',
    data
  })
}
export const strisksourceRemove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/sinoma-hse-prj/strisksource/remove',
    method: 'post',
    data
  })
}
export const sthdlistRemove = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/strisklibrary/remove',
    method: 'post',
    data
  })
}
export const sthdlistDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/strisklibrary/detail',
    method: 'get',
    params
  })
}
export const sthdlistSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/strisklibrary/submit',
    method: 'post',
    data
  })
}

export const HD_SOURCE_TYPE = (key) => {
  return request({
    url: `/api/sinoma-system/dict-biz/dictionary?code=${key}`,
    method: 'get',
  })
}

// 获取风险分类树
export const getTypeTree = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/strisksourcetype/typeTree',
    method: 'get',
    params
  })
}
