import Layout from '@/page/index/'

export default [
  {
    path: '/riskProject',
    component: Layout,
    children: [
      {
        path: 'riskProject',
        name: '风险库',
        meta: {
        },
        component: () =>
          import('@/views/business/riskProject/index')
      },
      {
        path: 'riskProjectAdd',
        name: '风险库新增',
        meta: {
        },
        component: () =>
          import('@/views/business/riskProject/add')
      },
      {
        path: 'riskProjectEdit',
        name: '风险库修改',
        meta: {
        },
        component: () =>
          import('@/views/business/riskProject/add')
      },
      {
        path: 'riskProjectDetail',
        name: '风险库详情',
        meta: {
        },
        component: () =>
          import('@/views/business/riskProject/add')
      },
      {
        path: 'riskVersionDetail',
        name: '风险版本详情',
        meta: {
        },
        component: () =>
          import('@/views/business/riskProject/riskVersionDetail')
      },
    ]
  }
]
