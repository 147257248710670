var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    { style: _vm._style },
    [
      _c(
        "el-link",
        {
          attrs: { underline: false, type: "primary" },
          on: { click: _vm.onClickLink },
        },
        [_vm._v(_vm._s(_vm.content))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }