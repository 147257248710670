var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.prefixCls },
    [
      _c("quill-editor", {
        ref: "myQuillEditor",
        attrs: { content: _vm.value, options: _vm.editorOption },
        on: {
          blur: function ($event) {
            return _vm.onEditorBlur($event)
          },
          focus: function ($event) {
            return _vm.onEditorFocus($event)
          },
          ready: function ($event) {
            return _vm.onEditorReady($event)
          },
          change: function ($event) {
            return _vm.onEditorChange($event)
          },
        },
        model: {
          value: _vm.content,
          callback: function ($$v) {
            _vm.content = $$v
          },
          expression: "content",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }