import Layout from '@/page/index/'

export default [
  {
    path: '/riskPublish',
    component: Layout,
    children: [
      {
        path: 'riskPublish',
        name: '风险发布',
        meta: {
        },
        component: () =>
          import('@/views/business/riskPublish/index')
      },
      {
        path: 'riskPublishDetail',
        name: '风险发布详情',
        meta: {
        },
        component: () =>
          import('@/views/business/riskPublish/detail')
      },
    ]
  }
]
