import Layout from '@/page/index/'

export default [
    //数据采集
    {
        path: '/jobSlip',
        component: Layout,
        name: '作业票管理',
        children: [
            {
                path: '/jobSlip/jobSlipTemplate/index',
                name: '作业票模板',
                component: () =>
                    import('@/views/business/jobSlip/V1/jobSlipTemplate/index'),
                meta: {
                    i18n: '作业票模板'
                }
            },
            {
                path: '/jobSlip/jobSlipTemplate/add',
                name: '作业票模板新增',
                component: () =>
                    import('@/views/business/jobSlip/V1/jobSlipTemplate/add'),
                meta: {
                    i18n: '作业票模板新增'
                }
            },
            {
                path: '/jobSlip/jobSlipTemplate/edit',
                name: '作业票模板编辑',
                component: () =>
                    import('@/views/business/jobSlip/V1/jobSlipTemplate/add'),
                meta: {
                    i18n: '作业票模板编辑'
                }
            },
            {
                path: '/jobSlip/jobSlipTemplate/view',
                name: '作业票模板查看',
                component: () =>
                    import('@/views/business/jobSlip/V1/jobSlipTemplate/add'),
                meta: {
                    i18n: '作业票模板查看'
                }
            },
            {
                path: '/jobSlip/jobSlipInventory/index',
                name: '作业票清单',
                component: () =>
                    import('@/views/business/jobSlip/V1/jobSlipInventory/index'),
                meta: {
                    i18n: '作业票清单'
                }
            },
            {
                path: '/jobSlip/jobSlipInventory/add',
                name: '作业票清单新增',
                component: () =>
                    import('@/views/business/jobSlip/V1/jobSlipInventory/add'),
                meta: {
                    i18n: '作业票清单新增'
                }
            },
            {
                path: '/jobSlip/jobSlipInventory/edit',
                name: '作业票清单编辑',
                component: () =>
                    import('@/views/business/jobSlip/V1/jobSlipInventory/add'),
                meta: {
                    i18n: '作业票清单编辑'
                }
            },
            {
                path: '/jobSlip/jobSlipInventory/view',
                name: '作业票清单查看',
                component: () =>
                    import('@/views/business/jobSlip/V1/jobSlipInventory/add'),
                meta: {
                    i18n: '作业票清单查看'
                }
            },
            {
                path: '/jobSlip/jobSlipInventory/sign',
                name: '作业票清单签署',
                component: () =>
                    import('@/views/business/jobSlip/V1/jobSlipInventory/add'),
                meta: {
                    i18n: '作业票清单签署'
                }
            },
            {
                path: '/jobSlip/fieldSetting/index',
                name: '作业票控件维护',
                component: () =>
                    import('@/views/business/jobSlip/V1/fieldSetting/index'),
                meta: {
                    i18n: '作业票控件维护'
                }
            },
            {
                path: '/jobSlip/fieldSetting/add',
                name: '作业票控件维护新增',
                component: () =>
                    import('@/views/business/jobSlip/V1/fieldSetting/add'),
                meta: {
                    i18n: '作业票控件维护新增'
                }
            },
            {
                path: '/jobSlip/fieldSetting/edit',
                name: '作业票控件维护编辑',
                component: () =>
                    import('@/views/business/jobSlip/V1/fieldSetting/add'),
                meta: {
                    i18n: '作业票控件维护编辑'
                }
            },
            {
                path: '/jobSlip/fieldSetting/view',
                name: '作业票控件维护查看',
                component: () =>
                    import('@/views/business/jobSlip/V1/fieldSetting/add'),
                meta: {
                    i18n: '作业票控件维护查看'
                }
            },
        ]
    },

]
