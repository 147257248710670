/*
 * @Author: absorbedyy@163.com
 * @Date: 2024-05-09 09:23:15
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-08-06 14:21:47
 * @Description: 
 */
import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';


/** 设备设施台账分类树  */
export const eqLedgeClassTree = ( params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqequipmenttype/tree',
    method: 'get',
    params: {
      ...params,
    }
  })
}
export const eqLedgeClassSubmit = ( params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqequipmenttype/submit',
    method: 'post',
    data: params,
  })
}
export const eqLedgeClassRemove = ( ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqequipmenttype/remove',
    method: 'post',
    params: {ids},
  })
}


/** 设备设施清单-分页 */
export const eqLedgerInventoryPage = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqequipment/page',
    method: 'get',
    params
  })
}
export const eqLedgerInventoryCount = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqequipment/stat',
    method: 'get',
    params
  })
}
export const eqLedgerInventoryDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqequipment/detail',
    method: 'get',
    params
  })
}
export const eqLedgerInventorySubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqequipment/submit',
    method: 'post',
    data
  })
}
export const eqLedgerInventoryRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqequipment/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

/** 设备设施证书资料-分页 */
export const eqLedgerCertificatePage = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqequipmentmaterial/page',
    method: 'get',
    params
  })
}
export const eqLedgerCertificateDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqequipmentmaterial/detail',
    method: 'get',
    params
  })
}
export const eqLedgerCertificateSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqequipmentmaterial/submit',
    method: 'post',
    data
  })
}
export const eqLedgerCertificateRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqequipmentmaterial/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}


/** 设备设施保养记录-分页 */
export const eqLedgerMaintainPage = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqmaintrecord/page',
    method: 'get',
    params
  })
}
export const eqLedgerMaintainDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqmaintrecord/detail',
    method: 'get',
    params
  })
}
export const eqLedgerMaintainSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqmaintrecord/submit',
    method: 'post',
    data
  })
}
export const eqLedgerMaintainRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqmaintrecord/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}


/** 设备设施维修记录-分页 */
export const eqLedgerServicePage = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqrepairrecord/page',
    method: 'get',
    params
  })
}
export const eqLedgerServiceDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqrepairrecord/detail',
    method: 'get',
    params
  })
}
export const eqLedgerServiceSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqrepairrecord/submit',
    method: 'post',
    data
  })
}
export const eqLedgerServiceRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqrepairrecord/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}


/** 设备设施检验记录-分页 */
export const eqLedgerTestRecordPage = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqtestrecord/page',
    method: 'get',
    params
  })
}
export const eqLedgerTestRecordDetail = (params) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqtestrecord/detail',
    method: 'get',
    params
  })
}
export const eqLedgerTestRecordSubmit = (data) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqtestrecord/submit',
    method: 'post',
    data
  })
}
export const eqLedgerTestRecordRemove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_FX + '/eqtestrecord/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}





