import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

/**
 * 分页查询
 * @param current 页码
 * @param size  每页数量
 * @param params  查询条件
 * @returns AxiosPromise
 */
export const selectPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rpBackspaceLog/getLogPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
