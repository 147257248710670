import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_NAME = "categoryHead";
const COUNTROLLER_NAME_LINE = "categoryLine";
const KNOWLEDGE_UPLOAD = "info"
//知识维度
export const tree = () => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${CONTROLLER_NAME}/list`,
    method: 'get',
  })
}
export const categoryHeadPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${CONTROLLER_NAME}/getPage`,
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const getCategoryHeadDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${CONTROLLER_NAME}/getDetail`,
    params: {
      id
    }
  })
}

export const saveTree = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${CONTROLLER_NAME}/saveOrUpdate`,
    method: 'post',
    data
  })
}

export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${CONTROLLER_NAME}/remove`,
    method: 'post',
    params: {
      ids
    }
  })
}
export const getListAndChildren = (knowledgeClassify) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${CONTROLLER_NAME}/getListAndChildren?knowledgeClassifyId=` + knowledgeClassify,
    method: 'get',
  })
}
// 知识分类
export const getPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${COUNTROLLER_NAME_LINE}/getPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
export const detail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${COUNTROLLER_NAME_LINE}/getDetail`,
    method: 'get',
    params: {
      id
    }
  })
}

export const againSync = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${KNOWLEDGE_UPLOAD}/againSync`,
    method: 'get',
    params: {
      ids
    }
  })
}

export const saveList = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${COUNTROLLER_NAME_LINE}/saveOrUpdate`,
    method: 'post',
    data
  })
}

export const removeList = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${COUNTROLLER_NAME_LINE}/remove`,
    method: 'post',
    params: {
      ids
    }
  })
}

//知识上传
export const saveUpload = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${KNOWLEDGE_UPLOAD}/saveOrUpdate`,
    method: 'post',
    data
  })
}

// 热搜管理

export const listWithType = (current, size, fileName) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${KNOWLEDGE_UPLOAD}/listWithType`,
    method: 'get',
    params: {
      fileName,
      current,
      size,
    }
  })
}

export const pageList = (current, size, fileName) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${KNOWLEDGE_UPLOAD}/getPage`,
    method: 'get',
    params: {
      fileName,
      current,
      size,
    }
  })
}

export const pageByDeptList = (current, size) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${KNOWLEDGE_UPLOAD}/getPageByDept`,
    method: 'get',
    params: {
      current,
      size,
    }
  })
}

export const updateIsTrendingSearch = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${KNOWLEDGE_UPLOAD}/updateIsTrendingSearch`,
    method: 'post',
    params: {
      ...data
    }
  })
}

// 知识维护
export const listAll = (current, size, fileName, fileTypeId, knowledgeType, id) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${KNOWLEDGE_UPLOAD}/listAll`,
    method: 'get',
    params: {
      fileName,
      fileTypeId,
      current,
      size,
      id,
      knowledgeType
    }
  })
}
// 发布
export const updateIsPublish = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${KNOWLEDGE_UPLOAD}/updateIsPublish`,
    method: 'post',
    params: {
      ...data
    }
  })
}

// 浏览历史
export const listBrowse = (current, size, fileName, fileTypeId, knowledgeType) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${KNOWLEDGE_UPLOAD}/listBrowse`,
    method: 'get',
    params: {
      fileName,
      fileTypeId,
      current,
      size,
      knowledgeType
    }
  })
}
// 删除历史
export const removeBrowse = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/browseCount/remove`,
    method: 'post',
    params: {
      ids
    }
  })
}

export const removeBrowseAll = () => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/browseCount/removeAll`,
    method: 'post'
  })
}

// 新增浏览历史
export const browseCount = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/browseCount/saveOrUpdate`,
    method: 'post',
    data
  })
}

// 我的分享
export const listShare = (current, size, fileName, fileTypeId, knowledgeType) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${KNOWLEDGE_UPLOAD}/listShare`,
    method: 'get',
    params: {
      fileName,
      fileTypeId,
      current,
      size,
      knowledgeType
    }
  })
}

export const removeShare = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/shareCount/remove`,
    method: 'post',
    params: {
      ids
    }
  })
}

// 我的收藏

export const listCollect = (current, size, fileName, fileTypeId, knowledgeType) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${KNOWLEDGE_UPLOAD}/listCollect`,
    method: 'get',
    params: {
      fileName,
      fileTypeId,
      current,
      size,
      knowledgeType
    }
  })
}

export const removeCollect = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/collectCount/remove`,
    method: 'post',
    params: {
      ids
    }
  })
}

// 查询知识
export const listInfo = (current, size, fileName, statusList) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${KNOWLEDGE_UPLOAD}/listInfo`,
    method: 'get',
    params: {
      fileName,
      statusList,
      current,
      size,
    }
  })
}

// 查询知识
export const listInfoSync = (current, size, fileName,startDate,endDate,fileSuffixType,statusList) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${KNOWLEDGE_UPLOAD}/listInfoSync`,
    method: 'get',
    params: {
      fileName,
      startDate,
      endDate,
      fileSuffixType,
      statusList,
      current,
      size,
    }
  })
}

export const removeUpload = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/info/remove`,
    method: 'post',
    params: {
      ids
    }
  })
}

//知识关联
export const infoTypeTree = () => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/infoType/tree`,
    method: 'get',
  })
}
// 知识分类新增
export const saveInfoTypeTree = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/infoType/saveOrUpdate`,
    method: 'post',
    data
  })
}

// 知识分类删除
export const removeInfo = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/infoType/remove`,
    method: 'post',
    params: {
      id
    }
  })
}
// 知识关联列表
export const listRelation = (current, size, params, typeId) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${KNOWLEDGE_UPLOAD}/listRelation`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
      typeId,
    }
  })
}
//知识关联弹框列表
export const listAllInfo = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${KNOWLEDGE_UPLOAD}/listAllInfo`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

//知识详情
export const getDetail = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${KNOWLEDGE_UPLOAD}/getDetail`,
    method: 'get',
    params: {
      id
    }
  })
}
// 知识关联新增

export const saveInfoType = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/infoTypeRelation/saveOrUpdate`,
    method: 'post',
    params: {
      ...data
    }
  })
}

// 知识关联删除
export const removeInfoType = (ids, typeId) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/infoTypeRelation/remove`,
    method: 'post',
    params: {
      ids,
      typeId
    }
  })
}


// 知识搜索列表
export const searchList = (current, size, fileName, fileTypeId,knowledgeType) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${KNOWLEDGE_UPLOAD}/list`,
    method: 'get',
    params: {
      fileName,
      knowledgeType,
      fileTypeId,
      current,
      size,
    }
  })
}

// 收藏
export const collect = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/collectCount/saveOrUpdate`,
    method: 'post',
    data
  })
}

// 根据知识Id查询收藏结果
export const selectByInfoId = (knowInfoId) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/collectCount/selectByInfoId`,
    method: 'post',
    params: {
      knowInfoId
    }
  })
}

// 评价

export const saveAssessment = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/assessment/saveOrUpdate`,
    method: 'post',
    data
  })
}
// 下载记录

export const saveDownloadCount = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/downloadCount/saveOrUpdate`,
    method: 'post',
    data
  })
}
//评价详情

export const assessmentDetail = (knowInfoId) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/assessment/detail`,
    method: 'get',
    params: {
      knowInfoId
    }
  })
}

// 分享

export const saveShareCount = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/shareCount/saveOrUpdate`,
    method: 'post',
    data
  })
}

export const submitRecording = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/shareCount/submitRecording`,
    method: 'post',
    data
  })
}

// 删除上传附件
export const removeUploadFile = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_RESOURCE_ + '/attach/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}
// 知识浏览文档组合
export const infoTree = (id) => {
  return request({
    url: ServerNameEnum.SERVER_KNOWLEDGE + '/infoType/infoTree',
    method: 'get',
    params: {
      id
    }
  })
}

// 评价列表

export const assessmentList = (knowInfoId) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/assessment/getList`,
    method: 'get',
    params: {
      knowInfoId
    }
  })
}

// 分词搜索

export const search = (knowledge) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/esSearch/search?keyword=${knowledge}`,
    method: 'get',
  })
}

export const getKnowledgeClass = (knowledge) => {
  return request({
    url: `/api/sinoma-system/dict-biz/dictionary?code=knowledge_classification`,
    method: 'get'
  })
}

export const getKnowledgeClassifyTree = () => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/knowledgeClassify/tree`,
    method: 'get',
  })
}

export const saveKnowledgeClassifyTree = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/knowledgeClassify/saveOrUpdate`,
    method: 'post',
    data
  })
}

export const removeKnowledgeClassify = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/knowledgeClassify/remove`,
    method: 'post',
    params: {
      id
    }
  })
}
export const getSafetyTrainingTree = () => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/safetyTrainingTree/tree`,
    method: 'get',
  })
}
export const submitSafetyTrainingTree = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/safetyTrainingTree/submit`,
    method: 'post',
    data
  })
}
export const removeSafetyTrainingTree = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_TRAINING_}/safetyTrainingTree/remove`,
    method: 'post',
    params: {
      id
    }
  })
}
export const intelligentSummary = (formData) => {
  return request({
    headers: {
      "Content-Type": "multipart/form-data"
    },
    url: `${ServerNameEnum.SERVER_AGENT}/textSummary/documentSummary`,
    method: 'post',
    data: formData,
  })
}

// 智能搜索接口
export const inteligentSearch = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_AGENT}/textSummary/searchTest`,
    method: 'post',
    data
  })
}

// 知识搜索列表
export const knowledgeSearchList = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_KNOWLEDGE}/${KNOWLEDGE_UPLOAD}/knowledgeSearchList`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
