import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/dict-biz/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getParentList = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/dict-biz/parent-list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getChildList = (current, size, parentId, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/dict-biz/child-list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
      parentId: parentId,
    }
  })
}

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/dict-biz/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/dict-biz/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/dict-biz/submit',
    method: 'post',
    data: row
  })
}


export const getDict = (id) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/dict-biz/detail',
    method: 'get',
    params: {
      id,
    }
  })
}

export const getDictDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/dict-biz/detail_data',
    method: 'get',
    params: {
      id,
    }
  })
}

export const getDictTree = () => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/dict-biz/tree?code=DICT',
    method: 'get'
  })
}

export const getDictionary = (params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+'/dict-biz/dictionary',
    method: 'get',
    params,
  })
}
// /api/sinoma-system
export const getDictionaryCode = (code) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+`/dict-biz/dictionary?code=${code}`,
    method: 'get',
  })
}

export const getDictionaryTreeCode = (code) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_+`/dict-biz/dictionary-tree?code=${code}`,
    method: 'get',
  })
}
