import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
const CONTROLLER_NAME = "msgTemplateChannel";

export const selectPage = (current, size, params) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/${CONTROLLER_NAME}/selectList`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const selectById = (id) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/${CONTROLLER_NAME}/selectById`,
    method: 'get',
    params: {
      id
    }
  })
}
export const saveOrUpdate = (rows) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/${CONTROLLER_NAME}/saveOrUpdate`,
    method: 'post',
    data: rows
  })
}
export const remove = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_MESSAGE_}/${CONTROLLER_NAME}/remove`,
    method: 'post',
    params: {
      ids,
    }
  })
}
