import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';
export const getList = (code) => {
  return request({
    url: ServerNameEnum.SERVER_STAT_+'/policyDynamic/getPolicyDocumentList',
    method: 'post',
    params: {
    code
    }
  })
}

export const getDynamicWork = (code) => {
  return request({
    url: ServerNameEnum.SERVER_STAT_+'/policyDynamic/getDynamicWork',
    method: 'post',
    params: {
      code
    }
  })
}
export const getDynamicWorkPhoto = (code) => {
  return request({
    url: ServerNameEnum.SERVER_STAT_+'/policyDynamic/getDynamicWorkPhoto',
    method: 'post',
    params: {
      code
    }
  })
}

export const getPolicyFileType = () => {
  return request({
    url: `/api/sinoma-system/dict-biz/dictionary?code=POLICY_FILE_TYPE`,
    method: 'get',
  })
}

export const getAssessmentIndicators = () => {
  return request({
    url: ServerNameEnum.SERVER_STAT_+'/resultsEvaluation/getAssessmentIndicators',
    method: 'post',
    data: {}
  })
}
