import request from '@/router/axios';
import ServerNameEnum from '@/util/ServerNameEnum';

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + '/rptSafeAccidentStat/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

/**
 * 新增或修改
 * @param
 * @returns AxiosPromise
 */
export const saveOrUpdate = (data) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptSafeAccidentStat/save`,
    method: 'post',
    data,
  })
}

/**
 * 分页查询
 * @param current 页码
 * @param size  每页数量
 * @param params  查询条件
 * @returns AxiosPromise
 */
export const selectPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptSafeAccidentStat/reportPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

/**
 * 数据批量提交保存
 * @param
 * @returns AxiosPromise
 */
export const submissionsSave = (data) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rptSafeAccidentStat/submissionsSave`,
    method: 'post',
    data: data
  })
}

export const detail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptSafeAccidentStat/detail`,
    method: 'get',
    params: {
      'id': id
    }
  })
}

export const detailByBusiness = (data) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptSafeAccidentStat/detailByBusiness`,
    method: 'post',
    data: data
  })
}

export const summaryGetPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptSafeAccidentStat/summaryPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const summary = (data) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptSafeAccidentStat/summary`,
    method: 'get',
    params: data
  })
}

export const auditPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptSafeAccidentStat/auditPage`,
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const summaryClickDataState = (data) => {
  return request({
    url: ServerNameEnum.SERVER_REPORT_ + `/rptSafeAccidentStat/summaryClickDataState`,
    method: 'get',
    params: data
  })
}

export const rejectSendingMessage = (row,value,templateCode) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rptSafeAccidentStat/rejectSendingMessage`,
    method: 'post',
    data:row,
    params:{
      value,
      templateCode
    }
  })
}

export const getListData = (ids) => {
  return request({
    url: `${ServerNameEnum.SERVER_REPORT_}/rptSafeAccidentStat/listDataIds`,
    method: 'post',
    params: {
      ids,
    }
  })
}
