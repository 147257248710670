var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "im-container" },
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("el-avatar", { attrs: { size: 30, src: _vm.info.headIcon } }),
              _c("span", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.info.realName)),
              ]),
              _c("el-link", {
                attrs: { icon: "el-icon-close", underline: false },
                on: {
                  click: function ($event) {
                    _vm.visible = false
                  },
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "chatBox" }, [
              _c(
                "div",
                { ref: "chatList", staticClass: "chatList" },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "chatList-item",
                      class: {
                        "chatList-item--mine":
                          item.userId == _vm.userInfo.user_id,
                      },
                    },
                    [
                      item.userId == _vm.userInfo.user_id
                        ? _c(
                            "div",
                            { staticClass: "chatList-user" },
                            [
                              _c("el-avatar", {
                                attrs: { size: 40, src: _vm.userInfo.avatar },
                              }),
                              _c("cite", [
                                _c("i", [_vm._v(_vm._s(item.dateTime))]),
                                _vm._v(_vm._s(_vm.$t("cip.desk.im.field.me"))),
                              ]),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "chatList-user" },
                            [
                              _c("el-avatar", {
                                attrs: { size: 40, src: _vm.info.headIcon },
                              }),
                              _c("cite", [
                                _vm._v(_vm._s(_vm.info.realName)),
                                _c("i", [_vm._v(_vm._s(item.dateTime))]),
                              ]),
                            ],
                            1
                          ),
                      _c(
                        "div",
                        { staticClass: "chatList-text" },
                        [
                          _c("div", { staticClass: "chatList-arrow" }),
                          item.messageType == "text"
                            ? _c("p", {
                                staticClass: "chatList__msg--text",
                                domProps: { innerHTML: _vm._s(item.message) },
                              })
                            : _vm._e(),
                          item.messageType == "image" && item.message.path
                            ? _c("el-image", {
                                staticClass: "chatList__msg--img",
                                attrs: {
                                  src: item.message.path,
                                  "preview-src-list": [item.message.path],
                                  "z-index": 10000,
                                },
                              })
                            : _vm._e(),
                          item.messageType == "voice" && item.message.path
                            ? _c("audio", {
                                staticClass: "chatList__msg--audio",
                                attrs: { controls: "", src: item.message.path },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEvent(
                                      "voice",
                                      item.message.path
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          item.messageType == "video"
                            ? _c("video", {
                                staticClass: "chatList__msg--video",
                                attrs: { src: item.message, controls: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEvent(
                                      "video",
                                      item.message
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "toolBox" }, [
                _c(
                  "div",
                  { staticClass: "toolBox-left" },
                  [
                    _c(
                      "el-popover",
                      {
                        ref: "popover",
                        attrs: {
                          placement: "top-start",
                          trigger: "click",
                          "popper-class": "emjio-popper",
                        },
                        model: {
                          value: _vm.popoverVisible,
                          callback: function ($$v) {
                            _vm.popoverVisible = $$v
                          },
                          expression: "popoverVisible",
                        },
                      },
                      [
                        _c("div", { staticClass: "emjioBox" }, [
                          _c(
                            "ul",
                            { staticClass: "emjio" },
                            _vm._l(this.emojiList, function (item, i) {
                              return _c(
                                "li",
                                {
                                  key: i,
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectEmit(item)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm._f("urlFilter")(item.url),
                                    },
                                  }),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                        _c("el-link", {
                          attrs: {
                            slot: "reference",
                            icon: "ym-custom ym-custom-emoticon-neutral",
                            underline: false,
                            title: _vm.$t("cip.desk.im.field.sendEmo"),
                          },
                          slot: "reference",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-upload",
                      {
                        ref: "upload",
                        staticClass: "uploadImg-btn",
                        attrs: {
                          "show-file-list": false,
                          action:
                            "/api/sinoma-resource/oss/endpoint/put-file-attach-name/IM",
                          limit: 1,
                          headers: { Authorization: _vm.$store.getters.token },
                          "on-success": _vm.handleSuccess,
                          "before-upload": _vm.beforeUpload,
                          accept: "image/*",
                        },
                      },
                      [
                        _c("el-link", {
                          attrs: {
                            icon: "ym-custom ym-custom-image",
                            underline: false,
                            title: _vm.$t("cip.desk.im.field.sendImg"),
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "toolBox-right" },
                  [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          icon: "icon-ym icon-ym-generator-time",
                          underline: false,
                        },
                        on: { click: _vm.openHistory },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("cip.desk.im.field.chatRecord")) +
                            "\n            "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "writeBox" },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 3,
                      placeholder: _vm.$t("cip.desk.im.field.enterMsg"),
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.sendMessage.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.messageContent,
                      callback: function ($$v) {
                        _vm.messageContent =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "messageContent",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "btns" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.sendMessage()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("cip.desk.im.field.send")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm.showHistory
              ? _c(
                  "div",
                  { staticClass: "historyBox" },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "SNOWS-common-head-input",
                        attrs: {
                          placeholder: _vm.$t("cip.desk.im.field.search"),
                          clearable: "",
                        },
                        on: { change: _vm.searchHistory },
                        model: {
                          value: _vm.keyword,
                          callback: function ($$v) {
                            _vm.keyword = $$v
                          },
                          expression: "keyword",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-input__icon el-icon-search",
                          attrs: { slot: "suffix" },
                          on: {
                            click: function ($event) {
                              return _vm.searchHistory()
                            },
                          },
                          slot: "suffix",
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { ref: "historyListBox", staticClass: "historyList-box" },
                      [
                        _c(
                          "div",
                          { staticClass: "chatList historyList" },
                          _vm._l(_vm.historyList, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "chatList-item" },
                              [
                                item.userId == _vm.userInfo.user_id
                                  ? _c(
                                      "div",
                                      { staticClass: "chatList-user" },
                                      [
                                        _c("cite", [
                                          _vm._v("我"),
                                          _c("i", [
                                            _vm._v(_vm._s(item.dateTime)),
                                          ]),
                                        ]),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "chatList-user" },
                                      [
                                        _c("el-avatar", {
                                          attrs: {
                                            size: 40,
                                            src:
                                              _vm.define.comUrl +
                                              _vm.info.headIcon,
                                          },
                                        }),
                                        _c("cite", [
                                          _vm._v(_vm._s(_vm.info.realName)),
                                          _c("i", [
                                            _vm._v(_vm._s(item.dateTime)),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    ),
                                _c(
                                  "div",
                                  { staticClass: "chatList-text" },
                                  [
                                    _c("div", {
                                      staticClass: "chatList-arrow",
                                    }),
                                    item.messageType == "text"
                                      ? _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(item.message),
                                          },
                                        })
                                      : _vm._e(),
                                    item.messageType == "image" &&
                                    item.message.path
                                      ? _c("el-image", {
                                          staticClass: "chatList__msg--img",
                                          attrs: {
                                            src: item.message.path,
                                            "preview-src-list": [
                                              item.message.path,
                                            ],
                                            "z-index": 10000,
                                          },
                                        })
                                      : _vm._e(),
                                    item.messageType == "voice" &&
                                    item.message.path
                                      ? _c("audio", {
                                          staticClass: "chatList__msg--audio",
                                          attrs: {
                                            controls: "",
                                            src: item.message.path,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleEvent(
                                                "voice",
                                                item.message.path
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    item.messageType == "video"
                                      ? _c("video", {
                                          staticClass: "chatList__msg--video",
                                          attrs: {
                                            src: item.message,
                                            controls: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleEvent(
                                                "video",
                                                item.message
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "el-dialog",
            {
              staticClass: "SNOWS-dialog SNOWS-dialog_center",
              staticStyle: { "z-index": "3000" },
              attrs: {
                visible: _vm.show,
                width: "40%",
                "before-close": _vm.handleClose,
                "lock-scroll": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.show = $event
                },
              },
            },
            [
              _vm.videoSrc
                ? _c("video", {
                    staticStyle: { width: "100%", "object-fit": "cover" },
                    attrs: { src: _vm.videoSrc, controls: "controls" },
                  })
                : _vm._e(),
              _vm.audioSrc
                ? _c("audio", {
                    staticStyle: { width: "100%", "object-fit": "cover" },
                    attrs: { src: _vm.audioSrc, controls: "controls" },
                  })
                : _vm._e(),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }