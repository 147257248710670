export default {

  md: {
    cmn: {
      field: {
        name: "name",
        code: "code",
        remark: "remark",
        driver: "driver",
        manage: "manage",
      }
    },
    //水分系数
    waterRate: {
      msg: {
        month: "Please select a month",
      },
      field: {
        enable: "enable",
        disable: "disable",
        lastYear: 'lastYear',
        nextYear: 'nextYear',
        status: 'status',
        versionCode: 'versionCode',
        jan: "January",
        feb: "February",
        mar: "March",
        apr: "April",
        may: "May",
        jun: "June",
        jul: "July",
        aug: "August",
        sep: "September",
        oct: "October",
        nov: "November",
        dec: "December",
        waterRateValRef: 'Moisture Coefficient (%) Collection Reference',
        rateVal: 'Correction value of moisture coefficient (%)',
        remark: 'remark',
        moistureCoefficient: "moistureCoefficient",
        time: "time",
        qualityType: "qualityType",
        itemCode: "itemCode",
        itemName: "itemName",
        coefficient: "moistureCoefficient(%)",
        num: "qualityNumber",
        totalWeight: "totalWeight",
        weight: "batchWeight",
      },
      title: {
        moistureCoefficientTitle: 'Moisture Coefficient',
        chooseTitle: 'Please Select The Version',
        detailtitle: 'Moisture Coefficient Detail',
        productionMaterialTitle: "Production Material",
        automaticCollectionTitle: "Automatic Collection",
        qualityInspectionDetailTitle: "Quality Inspection Detail",
      },
      btn: {
        quoteBtn: "Confirm Reference",
      },
    },
    //工序管理
    process: {
      field: {
        processCode: "processCode",
        processName: "processName",
        shortName: "shortName",
        parentId: "parentId",
        remark: "remark",
        isEnable: "isEnable",
        sort: "processSort",
      },
      title: {
        processManagementTitle: "Process Management",
        processAddTitle: "Process Add",
        processEditTitle: "Process Edit",
      },
    },
    //区域维护
    monitor: {
      field: {
        areaCode: "areaCode",
        areaName: "areaName",
        parentArea: "parentArea",
        isMonitor: "isMonitorArea",
        isEnable: "isEnable",
        parentId: "parentId",
        parentCode: "parentCode",
        areaType: "areaType",
        processName: "processName",
      },
      title: {
        regionalMaintenanceTitle: "Regional Maintenance",
        regionalMaintenanceAddTitle: "Regional Maintenance Add",
        regionalMaintenanceEditTitle: "Regional Maintenance Edit",
      },
    },
    //点位配置
    monitoring: {
      //摄像头信息
      camera: {
        field: {
          camreaCode: "camreaCode",
          camreaName: "camreaName",
          funct: "deploymentFeature",
          url: "url",
          remark: "remark",
        },
        title: {
          cameraTitle: "Camera Information",
          cameraAddTitle: "Camera Information Add",
          cameraEditTitle: "Camera Information Edit",
        },
        msg: {
          // 以下三条为后端路径
          deleteArea: "Please delete the corresponding regional monitoring point data first",
          deleteDevice: "Please delete the device monitoring point data corresponding to the data first",
          deleteVideo: "Please delete the large screen video configuration data corresponding to the data first",
        },
      },
      //区域监控点位
      monitoring: {
        field: {
          areaName: "areaName",
          areaCode: "areaCode",
          processCode: "processCode",
          processName: "processName",
          camreaCode: "camreaCode",
          camreaName: "camreaName",
          funct: "deploymentFeature",
          url: "url",
        },
        title: {
          RMPTitle: "Regional Monitoring Point",
          RMPAddTitle: "Regional Monitoring Point Add",
          RMPEditTitle: "Regional Monitoring Point Edit",
          treeTitle: "Area Type",
        },
        msg: {
          camreaName: "Automatically brought out after selecting the camera name",
        },
      },
      //设备监控点位
      deviceMonitoring: {
        field: {
          mainCode: "mainCode",
          mainName: "mainName",
          camreaCode: "camreaCode",
          camreaName: "camreaName",
          funct: "deploymentFeature",
          url: "url",
          mainDeviceCode: "mainDeviceCode",
          mainDeviceName: "mainDeviceName",
        },
        title: {
          EMPTitle: "Equipment Monitoring Point",
          EMPAddTitle: "Equipment Monitoring Point Add",
          EMPEditTitle: "Equipment Monitoring Point Edit",
          treeTitle: "Process Type",
        },
        msg: {
          camreaName: "Automatically brought out after selecting the camera name",
        },
      },
      //大屏视频配置
      video: {
        field: {
          lsCode: "largeScreenDisplayCode",
          cameraCode: "cameraCode",
          cameraName: "cameraName",
          funct: "funct",
          areaName: "areaName",
          url: "url",
          remark: "remark",
        },
        title: {
          videoTitle: "Large Screen Video Configuration",
          videoAddTitle: "Large Screen Video Configuration Add",
          videoEditTitle: "Large Screen Video Configuration Edit",
          selectCameraTitle: "Select Camera",
        },
        msg: {
          code: "The large screen display code already exists!",
        },
      },
    },
    //设备管理
    device: {
      field: {
        mainDeviceCatCode: "mainDeviceCatCode",
        mainDeviceCatName: "mainDeviceCatName",
        importLevel: "importLevel",
        sort: "sort",
        optMode: "optMode",
        isSpecial: "isSpecial",
        isEnvPort: "isEnvPort",
        powerType: "powerType",
        portAtt: "portAtt",
        remark: "remark",
        subDeviceCatCode: "subDeviceCatCode",
        subDeviceCatName: "subDeviceCatName",
        adminDept: "adminDept",
        connectLocation: "connectLocation",
        mainDeviceCode: "mainDeviceCode",
        mainDeviceName: "mainDeviceName",
        processName: "processName",
        subDeviceCode: "subDeviceCode",
        subDeviceName: "subDeviceName",
        sparepartsCode: "sparepartsCode",
        sparepartsName: "sparepartsName",
        specs: "specs",
        value: "value",
        unit: "unit",
      },
      title: {
        mainDeviceCateTitle: "Main Device Cate",
        mainDeviceCateAddTitle: "Main Device Cate Add",
        mainDeviceCateEditTitle: "Main Device Cate Edit",
        subDeviceCateTitle: "Sub Device Cate",
        subDeviceCateAddTitle: "Sub Device Cate Add",
        subDeviceCateEditTitle: "Sub Device Cate Edit",
        mainAndSubDeviceRelationTitle: "Main And SubDevice Relation",
        mainAndSubRelationTitle: "Select Sub Device",
        mainDeviceTitle: "Main Device Management",
        processCateTitle: "Process Cate",
        mainDeviceAddTitle: "Main Device Add",
        mainDeviceEditTitle: "Main Device Edit",
        subDeviceTitle: "Sub Device Management",
        subDeviceAddTitle: "Sub Device Add",
        subDeviceEditTitle: "Sub Device Edit",
        sparePartTitle: "Spare Part List",
        sparePartAddTitle: "Spare Part List Add",
        sparePartEditTitle: "Spare Part List Edit",
      },
      msg: {
        selectMainDeviceCate: "Please select the main device cate",
        selectsubDeviceCate: "Please select the sub device cate",
        selectProcessCate: "Please select the process cate",
        selectMainDevice: "Please select the main device",
      }
    },
    //机构管理
    mdpostLevel: {
      field: {
        postLevelCode: "postLevelCode",
        postLevelName: "postLevelName",
        remark: "describe",
        sectCode: "sectCode",
        sectName: "sectName",
        processName: "processName",
        subjectCode: "subjectCode",
        subjectName: "subjectName",
        dutyCode: "dutyCode",
        dutyName: "dutyName",
        subjectId: "subjectId",
        shiftTypeCode: "shiftTypeCode",
        shiftTypeName: "shiftTypeName",
        shiftType: "shiftType",
        blockTrain: "blockTrain",
        dateList: "dateList",
        morning: "morning",
        midday: "midday",
        evening: "evening",
        startTime: "Start Time",
        endTime: "End Time",
      },
      title: {
        rankTitle: "Rank Management",
        rankAddTitle: "Rank Add",
        rankEditTitle: "Rank Edit",
        sectionTitle: "Management Section",
        sectionAddTitle: "Management Section Add",
        sectionEditTitle: "Management Section Edit",
        majorTitle: "Management Major",
        majorAddTitle: "Management Major Add",
        majorEditTitle: "Management Major Edit",
        responsibilityTitle: "Responsibility Management",
        responsibilityAddTitle: "Responsibility Add",
        responsibilityEditTitle: "Responsibility Edit",
        shiftModeTitle: "Shift Mode",
        shiftModeAddTitle: "Shift Mode Add",
        shiftModeEditTitle: "Shift Mode Edit",
      },
      msg: {
        selectWarning: "Please select the management major first",
      }
    },
    //生产配方
    itemFormula: {
      field: {
        itemCode: "materialCode",
        itemName: "materialName",
        versionCode: "versionNumber",
        isEnable: "isEnable",
        bomItemName: "materialName",
        rateVal: "proportionalDosage(%)",
        consume: "productionConsumption(%)",
        remark: "remark",
      },
      title: {
        itemFormulaTitle: "Production Formula",
        itemFormulaEditTitle: "Production Formula Edit",
        itemFormulaViewTitle: "Production Formula View",
        selectTitle: "Select Formula Product",
        recipeVersionTitle: "Recipe Version",
        recipeVersionAddTitle: "Recipe Version Add",
        recipeVersionEditTitle: "Recipe Version Edit",
        recipeVersionCopyTitle: "Recipe Version Copy",
        formulaDetailTitle: "Formula Detail",
        selectMaterialTitle: "Select Recipe Material",
        queryTitle: "Production Ratio Reference Query",
      },
      btn: {
        productFormulaBtn: "Production Formula",
        productSearchBtn: "Production Ratio Reference Query",
        selectItemBtn: "Select Material",
      },
      msg: {
        selectWarning: "Please select the recipe version first!",
        rateVal: "The ratio of usage needs to be 100%",
        deleteRateVal: "Deleting this material requires allocating the proportion usage to other materials",
        copyFailed: "Copy Failed",
        itemCodeNotEmpty: "Item code cannot be empty",
        recipeVersionNotEmpty: "Formula version cannot be empty",
        recipeVersionHas: "The production formula version already exists",
        recipeVersionHasEnable: "An enabled production recipe version already exists",
      }
    },
    //生产物料
    item: {
      field: {
        labelText: "title",
        parentCode: "parentCategory",
        parentName: "parentName",
        isEnable: "isEnable",
        itemCode: "materialCode",
        itemName: "materialName",
        itemCatCode: "itemCatCode",
        itemCatName: "itemCatName",
        upperLimit: "Safety stock (upper limit)",
        lowerLimit: "Safety stock (lower limit)",
        itemDensity: "itemDensity",
        remark: "remark",
        processName: "processName",
        code: "materialCode",
        name: "materialName",
        unitName: "unitName",
        isProduct: "isProduct",
        itemCodeOrName: "materialCode/Name",
        serialNumber: "serialNumber",
        bomItemName: "BOMMaterialName",
        bomItemCode: "BOMMaterialCode",
        extItemCodeOrName: "ERP materialCode/Name",
        extItemCode: "ERPMaterialCode",
        extItemName: "ERPMaterialName",
        extItemCatCode: "extItemCatCode",
        extItemCatName: "extItemCatName",
        warehouseCodeAndName: "warehouseCodeAndName",
        stockInOut: "inbound/outbound Type",
        warehouseCode: "warehouseCode",
        warehouseName: "warehouseName",
        stockDataSource: "dataSource",
        stockDcs: "DCS measuring point",
        select: "select",
        warehouseShapeCode: "warehouseShape",
        storageDays:"storageDays",
        day: "day",
      },
      title: {
        productionMaterialTitle: "Production Material",
        productionMaterialAddTitle: "Production Material Add",
        productionMaterialEditTitle: "Production Material Edit",
        productionMaterialViewTitle: "Production Material View",
        proMaterialClassTitle: "Production Material Classification",
        materialMappingTitle: "Material Mapping",
        materialBOMTitle: "Material BOM",
        erpMaterialTitle: "ERP Material",
        selectMaterialTitle: "Select Material",
        selectERPMaterialsTitle: "Select ERP Material",
        materialViewTitle: "View Material",
        materialEditTitle: "Edit Material",
        materialAddTitle: "Add Material",
        materialWarehouseTitle: "Material Warehouse",
        itemWarehouseHeadTitle: "Select Warehouse",
      },
      msg: {
        exportMaterialUnit: "Export material unit data?",
        exportMaterialSubstitute: "Export Material Substitute Data?",
        exportMaterialSupplier: "Export material supplier data?",
        exportMaterial: "Export Material Data?",
        notSupported: "Multiple selection not supported",
        notSelect: "This piece of data is not allowed to be selected!",
        erpBindOneItem: "An ERP material can only be bound to one production material",
        storageDays:"Sealing days must be greater than 0",
        warn: "Safety stock (lower limit) cannot exceed safety stock (upper limit)",
      }
    },
    //仓库管理
    warehouse: {
      field: {
        code: "wareHouseCode",
        name: "wareHouseName",
        isEnable: "isEnable",
        itemCode: "materialCode",
        itemName: "materialName",
        isEnableEarlyWarning: "enableAlert",
        earlyWarningThreshold: "warningThreshold",
        locationCode: "warehouseLocationCode",
        locationName: "warehouseLocationName",
        formula: "formula",
        remark: "remark",
        defaultWarehouse: 'oneCardDefaultWarehouse',
        formulaCode: "parameterCode",
        formulaName: "parameterName",
        formulaValue: "defaultValue",
        describe: "describe",
        warnType: 'warningType',
        stationCode: 'measurementPointCode',
        warnRange: 'warningRange',
      },
      title: {
        warehouseManagementTitle: "Warehouse Management",
        warehouseAddTitle: "Warehouse Add",
        warehouseEditTitle: "Warehouse Edit",
        warehouseViewTitle: "Warehouse View",
        baseTitle: "Basic Information",
        locationTitle: "Warehouse Location Management",
        warehouseWarnTitle: 'Warehouse Warning',
        productionMaterialTitle: "Production Material",
        formulaTitle: "Formula",
        formulaParameterListTitle: "Formula Parameter List",
      },
      btn: {
        formulaBtn: "Formula",
      },
      msg: {
        selectWarning: "Please save the location information first",
        addWarning: "Please save the basic information first",
        saveWarning: "Please select the location information that needs to be saved",
        locationCodeBlank: "Please enter the warehouse location code",
        locationNameBlank: "Please enter the warehouse location name",
        codeSame: "Encoding already exists",
        formulaTextBlank: "Please fill in the calculation formula",
        formulaCodeBlank: "Please improve the formula parameter encoding information",
        formulaNameBlank: "Please improve the formula parameter name information",
        formulaValueBlank: "Please improve the default value information of formula parameters",
        formulaParam1Blank: "The parameter list contains parameters that are not used in the formula",
        formulaParam2Blank: "The formula uses parameters that were not added in the parameter list",
        onlyOneWarning: "Only one inventory warning can exist!",
        codeBlank: "Please enter the warehouse number",
        nameBlank: "Please enter the warehouse name",
        itemCodeBlank: "Please enter the material code",
        itemNameBlank: "Please enter the material name",
        defaultWarehouse: "Please select the default warehouse for one card",
        saveFailBecauseHave: "Save failed, The current warehouse has already configured materials",
      }
    },      //仓库管理
    physicalWarehouse: {
      field: {
        code: "wareHouseCode",
        name: "wareHouseName",
        type: "wareHouseType",
        isEnable: "isEnable",
        createTime: "createTime",
        location: "location",
        capacity: "capacity",
        formula: "formula",
        dcsPoint: "dcsPoint",
        remark: "remark",
        itemCode: "materialCode",
        itemName: "materialName",
        formulaCode: "parameterCode",
        formulaName: "parameterName",
        formulaValue: "defaultValue",
        describe: "describe",
      },
      title: {
        warehouseManagementTitle: "Physical Warehouse Management",
        warehouseAddTitle: "Physical Warehouse Add",
        warehouseEditTitle: "Physical Warehouse Edit",
        warehouseViewTitle: "Physical Warehouse View",
        baseTitle: "Basic Information",
        itemTitle: "item Title",
        itemDialogTitle: "Production Materials",
      },
      btn: {
        formulaBtn: "Formula",
      },
      msg: {
        selectWarning: "Please save the location information first",
        formula: "Please click on the configuration inventory calculation formula",
        code: "Automatically generate if not filled in",
      }
    },
    //班组
    class: {
      field: {
        classCode: "classCode",
        className: "className",
        isEnable: "isEnable",
        classCatCode: "classCatCode",
        classCatName: "classCatName",
        parentName: "parentName",
        remark: "remark",
        userCode: "userCode",
        userName: "userName",
        isLeader: "isLeader",
      },
      title: {
        classTitle: "Team",
        classAddTitle: "Team Add",
        classEditTitle: "Team Edit",
        classViewTitle: "Team View",
        teamClassificationTitle: "Team Classification",
        baseTitle: "Basic Information",
        classMemberTitle: "Team Member",
        selectMemberTitle: "Select Member",
      },
      msg: {
        firstSaveData: "Please save the data in the team members first",
        onlyOneLeader: "A team can only have one team leader",
        memberIsEmpty: "Team members cannot be empty",
        memberDuplicated: "Team members cannot be duplicate",
      }
    },
    //班次
    classShift: {
      field: {
        classShiftCatCode: "classShiftCatCode",
        classShiftCatName: "classShiftCatName",
        classShiftCode: "classShiftCode",
        classShiftName: "classShiftName",
        startTime: "startTime",
        endTime: "endTime",
        classShiftDateType: "classShiftDateType",
        remark: "remark",
      },
      title: {
        classShiftTitle: "ClassShift",
        classShiftCatTitle: "classShiftCat",
        classShiftEditTitle: "ClassShift Edit",
        classShiftViewTitle: "ClassShift View",
      },
      msg: {
        tipDetermineInit: "Confirm initialization data?",
        classShiftCat: "Please select a shift classification",
        classShiftCode: "Automatic generation of classShiftCode",
      }
    },
    //排班规则
    classShiftRule: {
      field: {
        classShiftRuleCode: "classShiftRuleCode",
        classShiftRuleName: "classShiftRuleName",
        classCatCode: "classCatCode",
        classCatName: "classCatName",
        startDate: "startDate",
        endDate: "endDate",
        remark: "remark",
        classShiftRuleSort: "classShiftRuleSort",
        className: "className",
        classShiftName: "classShiftName",
        classShiftType: "classShiftType",
        shiftSchedulingDay: "shiftSchedulingDay",
      },
      title: {
        classShiftRuleTitle: "ClassShiftRule",
        classShiftRuleAddTitle: "ClassShiftRule Add",
        classShiftRuleEditTitle: "ClassShiftRule EDit",
        classShiftRuleViewTitle: "ClassShiftRule View",
        baseTitle: "Basic Information",
        classShiftRuleLineTitle: "ClassShiftRuleLine",
        calendarGenerationTitle: "Calendar Generation",
      },
      msg: {
        lineIsEmpty: "This layout rule has no row information",
        ruleTimeIsEmpty: "The scheduling rule time is empty",
        ruleTimeError: "The start time of this scheduling rule is greater than the end time",
        firstSaveData: "Please save the data in the scheduling rule row first",
        only_one: "The team name in the scheduling rule line is duplicate!",
        tip_determine_gen: "Confirm generation of data?",
        ruleDay: "The number of shift days is less than the number of shift rule days",
        dateRepetition: "There are duplicate dates between this scheduling period and historical scheduling",
        startDate: "Start time cannot be empty",
        endDate: "End time cannot be empty",
      }
    },
    //排班日历
    classCalendar: {
      field: {
        calendarDate: "calendarDate",
        classCatName: "classCatName",
        className: "className",
        calendarEndDate: "calendarEndDate",
        classShiftName: "classShiftName",
        remark: "remark",
        userName: "memberName",
        isLeader: "isLeader",
      },
      title: {
        classCalendarTitle: "Scheduling Calendar",
        classCalendarViewTitle: "Scheduling Calendar View",
        baseTitle: "Basic Information",
        classMemberTitle: "Team Member",
        classMemberAddTitle: "Add Personnel On Duty",
      },
    },
    //工艺配置
    processSetting: {
      field: {
        processCode: "processCode",
        processName: "processName",
        equipCode: "equipCode",
        equipName: "equipName",
        classCatName: "classCatName",
        isEnable: "isEnable",
        processOutitemFormulaType: "processOutitemFormulaType",
        remark: "remark",
        itemCode: "itemCode",
        dcsName: "dcsName",
        itemName: "itemName",
        warehouseName: "warehouseName",
        dcsType: "dcsType",
        dcsCode: "dcsCode",
        itemGroupCode: "itemGroupCode",
        weightRatio: "weightRatio(%)",
        //selectDcsTable页面的测点订阅
        categoryIndexCode: "categoryIndexCode",
        deviceCode: "deviceCode",
        devicePropertyCode: "devicePropertyCode",
        devicePropertyName: "devicePropertyName",
        categoryName: "categoryName",
        categoryCode: "categoryCode",
      },
      title: {
        processSettingTitle: "Process Setting",
        processSettingAddTitle: "Process Setting Add",
        processSettingEditTitle: "Process Setting Edit",
        productionMaterialTitle: "Production Material",
        labelTitle: "Measurement Point Subscription",
        lineTitle: "Material Consumption",
        equipmentSettingTitle: "Equipment Operation Setting",
        headTitle: "ERP Material Management",
      },
      msg: {
        decCode: "The sum of the proportions of the same point codes must be equal to 100",
        decName: "The name of the same measuring point must be the same",
        decNameNull: "Please enter the station name",
        number: "You can only enter two digits after the decimal point",
        warehouse_name_blank: "Please select a warehouse name",
        dcsType_name_blank: "Please select the type of measuring point",
        dcs_code_blank: "Please select the point code",
        dataHaveExist: "This device already has a configuration",
      }
    },
    //项目管理
    project: {
      field: {
        projectCode: "projectCode",
        projectName: "projectName",
        projectAddr: "projectAddr",
        address: "address",
        longitude: "longitude",
        dimension: "dimension",
        companyName: "companyName",
        remark: "projectDesc",
      },
      title: {
        indexTitle: "Project Management",
        addTitle: "Project Add",
        editTitle: "Project Edit",
        viewTitle: "Project View",
      },
    },
    itemOrg: {
      field: {
        itemOrgs: "itemOrgs",
        itemDetail: "itemDetail",
        orgErr: "orgErr！",
        itemErr: "Material cannot be duplicate, please reselect!",
      },
      title: {
        indexTitle: "Material Organization Mapping",
      }
    },
    virtualOrg: {
      field: {
        inSide: "inSide",
        title: "title",
        outSide: "outSide",
        orgCode: "orgCode",
        orgName: "orgName",
        companyName: "companyName",
        orgType: "orgType",
        remark: "remark"
      },
      title: {
        indexTitle: "Organizational management",
      }
    },
    saleArea: {
      field: {
        tagName: "tagName",
        areaName: "areaName",
        areaCode: "areaCode",
        createPeople: "createPeople",
        createTime: "createTime",
        belongArea: "belongArea",
        remarks: "remarks",
        cityName: "cityName",
        cityCode: "cityCode",
      },
    },
    projectFiles: {
      field: {
        tagName: "tagName",
        projectName: "projectName",
        projectCode: "projectCode",
        createPeople: "createPeople",
        createTime: "createTime",
        projectType: "projectType",
        belongCompany: "belongCompany",
        remarks: "remarks"
      },
      title: {}
    },
  },
  business: {
    components: {
      common: {
        treeTableDialog: {
          msg: {
            selected: "selected",
          }
        }
      },
      //ERP物料
      mdExtItemDialog: {
        field: {
          extItemCode: "ERP materialCode",
          extItemName: "ERP materialName",
          logisticsCode: "logisticsCode",
          batchCode: "batchCode",
          carNo: "carNo",
        },
        title: {
          erpMaterialTitle: "ERP Material",
        },
      },
      //ERP物料管理
      mdItemDialog: {
        field: {
          itemCode: "Material Code",
          itemName: "Material Name",
          unitName: "Unit Name",
        },
        title: {
          headTitle: "ERP Material Management",
        },
      },
      //仓库信息
      mdWarehouseDialog: {
        field: {
          code: "wareHouseCode",
          name: "wareHouseName",
          isEnable: "isEnable",
        },
        title: {
          wareHouseTitle: "Warehouse Information",
        },
      },
      //仓库信息
      mdWarehouseQytDialog: {
        field: {
          warehouseName: "warehouseName",
          warehouseCode: "warehouseCode",
          itemName: "Material Name",
          itemCode: "Material Code",
          stockQty: "Inventory Quantity",
          unitName: "Unit Name",
        },
        title: {
          wareHouseTitle: "Warehouse Information",
        },
      },
      //设备信息
      pmDeviceDialog: {
        field: {
          deviceCode: "deviceCode",
          deviceName: "deviceName",
        },
        title: {
          deviceTitle: "Device Information",
        },
      },
    },
  },
  base: {
    md: {
      unit: {
        field: {
          unitCode: "unitCode",
          unitName: "unitName",
          isEnable: "isEnable",
          remark: "remark",
        },
        title: {
          indexHeadTitle: "Units Manager",
          addHeadTitle: "Units ManagerAdd",
          editHeadTitle: "Units ManagerEdit",
          viewHeadTitle: "Units ManagerView",
        }
      },
      period: {
        field: {
          periodCode: "periodCode",
          year: "year",
          month: "month",
          startDate: "startDate",
          endDate: "endDate",
          isEnable: "isEnable",
          periodDate: "periodDate",
          generateDate: "generateDate",
          viewDate: "viewDate",
          dateTitle: "dateTitle",
          detail: "detail",
          select_warning: "Please select the period information that needs to be saved",
          year_blank: "year_blank",
          month_blank: "month_blank",
          start_blank: "start_blank",
          start_end: "The end date cannot be less than the start date",
          end_blank: "end_blank",
          // isEnable_blank: "Enable cannot be empty",
          date_blank: "Period date cannot be empty",
        },
        title: {
          indexHeadTitle: "Period management",
        },
        msg: {
          checkPeriod: "Prohibit saving duplicate interval time!",
          checkPeriodDate: "Date has been generated, cannot repeat operation!",
        }
      },
      fgp: {
        field: {
          startDate: "startDate",
          endDate: "endDate",
          remark: "remark",
          add_warning: "Please save the basic information first",
          start_blank: "start_blank",
          end_blank: "end_blank",
        },
        title: {
          baseTitle: "Basic information",
          indexHeadTitle: "Peak valley flat list",
          addHeadTitle: "Peak valley flat listAdd",
          editHeadTitle: "Peak valley flat listEdit",
          viewHeadTitle: "Peak valley flat listView",
        },
      },
      fgpLine: {
        field: {
          fgpType: "fgpType",
          startTime: "startTime",
          endTime: "endTime",
          price: "price",
          remark: "remark",
          select_warning: "Please select the peak valley level settings that need to be saved",
          type_blank: "Peak valley level type cannot be empty",
          start_blank: "Start time cannot be empty",
          end_blank: "Start time cannot be empty",
          price_blank: "price_blank",
          afterTip: {
            checkPeriod: "Peak valley average time is not a continuous 24-hour period on the same day. Please modify it before saving!",
          }
        },
        title: {
          indexHeadTitle: "Peak valley average time",
          addHeadTitle: "Peak valley average timeAdd",
          editHeadTitle: "Peak valley average timeEdit",
          viewHeadTitle: "Peak valley average timeView",
        }
      },
      param: {
        field: {
          paramCode: "paramCode",
          paramName: "paramName",
          paramValue: "paramValue",
          remark: "remark",
        },
        title: {
          indexHeadTitle: "parameter management",
          addHeadTitle: "parameter managementAdd",
          editHeadTitle: "parameter managementEdit",
          viewHeadTitle: "parameter managementView",
        },
        msg: {
          check: "The parameter value is a two decimal place range from 0.00 to 1.00, with padding allowed",
        },
      },
    },
  },

}
