import request from "@/router/axios";
import ServerNameEnum from "@/util/ServerNameEnum";


export const getClockPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_SYSTEM_ + "/clock/page",
    method: "post",
    data: {
      ...params,
      current,
      size,
    },
  });
};

export const getPage = (current, size, params) => {
  return request({
    url: ServerNameEnum.SERVER_USER_ + "/attendance/page",
    method: "post",
    data: {
      ...params,
      current,
      size,
    },
  });
};

export const getDetail = (id) => {
  return request({
    url: ServerNameEnum.SERVER_USER_ + "/attendance/detail",
    method: "post",
    params: {
      id,
    },
  });
};

export const remove = (ids) => {
  return request({
    url: ServerNameEnum.SERVER_USER_ + "/attendance/remove",
    method: "post",
    params: {
      ids,
    },
  });
};

export const add = (row) => {
  return request({
    url: ServerNameEnum.SERVER_USER_ + "/attendance/submit",
    method: "post",
    data: row,
  });
};

export const update = (row) => {
  return request({
    url: ServerNameEnum.SERVER_USER_ + "/attendance/submit",
    method: "post",
    data: row,
  });
};
