export default {
  qm: {
    business: {
      md: {
        codeRule: {
          field: {
            ruleCode: "规则编号",
            ruleName: "规则名称",
            resettingCycle: "重置周期",
            num: "流水号长度",
            curVal: "当前值",
            initVal: "初始值",
            isEnable: "是否启用",
            codeRuleBusiType: "业务类型",
            formtable: {
              paramType: "参数类型",
              paramValType: "参数名称",
              paramVal: "参数值",
              seq: "顺序",
              auto: "自动生成",
              param: "参数传入",
            }
          },
          title: {
            indexHeadTitle: "编码规则",
            addHeadTitle: "编码规则新增",
            editHeadTitle: "编码规则编辑",
            viewHeadTitle: "编码规则查看",
            formtableTitle: "编码组成",
          },
          msg: {
            paramList: "保存参数列表为空！",
            param: "参数值不能为空",
            paramNotRepeated: "参数值不能为空",
            paramType: "参数类型不能重复",
            order: "顺序不能为0",
            orderNotRepeated: "顺序不能重复",
          }
        },
        cusCar: {
          field: {
            cusCar: "运输车辆",
            carNo: "车牌号",
            remark: "备注",
            gpsSupplier: "GPS供应商档案",
            falseness: "不正确",
            black_name: "黑名单",
            companyName: "拉黑企业",
            userName: "拉黑/解除人",
            blackRemark: "黑名单备注",
            createUser: "创建人",
            createTime: "创建时间",
            blackUpdateUser: "最后更新人",
            updateTime: "最后更新时间",
            lastModifiedTime: "最后修改时间",
          },
          title: {
            headTitle: "车辆",
          }

        },
        class: {
          field: {
            shiftClassification: '班次分类',
            classCode: "班组编码",
            className: "班组名称",
            classCatCode: "班组分类编码",
            classCatName: "班组分类名称",
            isEnable: "是否启用",
            remark: "备注",
            afterTip: {
              firstSaveData: "请先保存班组成员中的数据",
            }
          },
          title: {
            indexHeadTitle: "班组",
            addHeadTitle: "班组新增",
            editHeadTitle: "班组编辑",
            viewHeadTitle: "班组查看",
          }
        },
        supplier: {
          field: {
            headTitle: "供应商基本信息",
            supplierCode: "供应商编码",
            supplierName: "供应商名称",
            contactPerson: "联系人",
            address: "公司地址",
            tel: "联系电话",
            isEnable: "是否启用",
            remark: "备注",
          },
          title: {
            indexHeadTitle: "供应商",
            addHeadTitle: "供应商新增",
            viewHeadTitle: "供应商查看",
            editHeadTitle: "供应商编辑",
          }
        },
        warehouseCfg: {
          field: {
            warehouseCode: "仓库编号",
            warehouseName: "仓库名称",
            itemCode: "物料编码",
            itemName: "物料名称",
            isEnable: "是否启用",
            itemCodeAndName: "物料编码/名称",
            warehouseCodeAndName: "仓库编码/名称",
          },
          title: {
            indexHeadTitle: "一卡通仓库配置",
          }
        },
        itemWarehouse: {
          field: {
            warehouseCodeAndName: "仓库编码/仓库名称",
            warehouseName: "仓库名称",
            warehouseCode: "仓库编码",
            stockInOut: "出入库类型",
            stockDataSource: "数据来源",
            stockDcs: "DCS测点",
            remark: "备注",
            isEnable: "是否启用",
            itemWarehouseHeadTitle: "选择仓库",
          },
          title: {
            indexHeadTitle: "物料仓库",
          }
        },
        extItemCate: {
          field: {
            headTitle: "ERP物料管理",
            headCatTitle: "ERP物料分类",
            pleaseInput: "请输入",
            parentCode: "父类别",
            parentName: "父类别名称",
            extItemName: "ERP物料名称",
            extItemCode: "ERP物料编码",
            extItemCatCode: "物料分类编码",
            extItemCatName: "物料分类名称",
            isEnable: "是否启用",
            remark: "备注",
            itemCateId: "ERP物料分类id",
            unitCode: "单位编码",
            unitName: "单位名称",
            hasChildren: "有子项无法删除!",
            afterTip: {
              unitError: "查询单位信息错误！",
            },
            title: "类别分类",
          },
          title: {
            indexHeadTitle: "ERP物料",
            addHeadTitle: "ERP物料新增",
            viewHeadTitle: "ERP物料查看",
            editHeadTitle: "ERP物料编辑",
          },
        },
        deviceFaultCat: {
          field: {
            headTitle: "停机原因管理",
            pleaseInput: "请输入",
            parentCode: "父类编码",
            deviceFaultDetailName: "故障名称",
            deviceFaultDetailCode: "故障码",
            deviceFaultCatCode: "停机分类编码",
            deviceFaultCatName: "停机分类名称",
            isEnable: "是否启用",
            remark: "备注",
            hasChildren: "有子项无法删除!",
            afterTip: {
              unitError: "查询单位信息错误！",
            }
          },
          title: {
            indexHeadTitle: "停机原因",
            addHeadTitle: "停机原因新增",
            viewHeadTitle: "停机原因查看",
            editHeadTitle: "停机原因编辑",
            headCatTitle: "停机原因分类",
          },
        },
        customer: {
          field: {
            customerName: "客户名称",
            customerCode: "客户编码",
            address: "公司地址",
            contactPerson: "联系人",
            tel: "联系电话",
            isEnable: "是否启用",
            remark: "备注",
            tip_determine_del: "确定将选择数据删除?",
          },
          title: {
            indexHeadTitle: "客户",
            headTitle: "客户管理",
            addHeadTitle: "客户新增",
            viewHeadTitle: "客户查看",
            editHeadTitle: "客户编辑",
          },
        },
        item: {
          field: {
            cateHeadTitle: "生产物料分类",
            parentCode: "父类别",
            parentName: "上级分类名称",
            labelText: "标题",
            superiorName: "上级名称",
            list: "物料资料",
            name: "物料名称",
            code: "物料编码",
            norms: "规格",
            model: "型号",
            charNo: "图号",
            unit: "单位",
            uname: "姓名",
            idCard: "身份证",
            black_name: "黑名单",
            black_name_remark: "黑名单备注",
            phone: "手机",
            wechat: "微信",
            itemName: "物料名称",
            itemCode: "物料编码",
            itemCatId: "物料分类id",
            itemCatCode: "物料分类编码",
            itemCatName: "物料分类名称",
            processName: "工艺名称",
            processCode: "工艺编码",
            isEnable: "是否启用",
            isProduct: "是否产成品",
            remark: "备注",
            itemCateId: "物料类别",
            unitCode: "单位编码",
            unitName: "单位名称",
          },
          title: {
            indexHeadTitle: "生产物料",
            addHeadTitle: "生产物料新增",
            viewHeadTitle: "生产物料查看",
            editHeadTitle: "生产物料编辑",
          },
          msg: {
            itemErr: "物料不能重复，请重新选择!",
          }
        },
      },
      qm: {
        dispatchModel: {
          title: {
            indexHeadTitle: '调度单模板',
            hedaTitleAdd: '调度单模板新增',
            headTitleEdit: '调度单模板编辑',
            headTitleDetail: '调度单模板详情',
          },
          columns: {
            modelName: '模板名称',
            modelCode: '模板编码',
            dispatchType: '调度单类型',
            updateBy: "最后修改时间",
            basicInfo: '基本信息',
            detailInfo: '明细信息',
            materialRatio: '物料投比'
          }
        },
        dispatch: {
          title: {
            indexHeadTitle: '质量调度单',
            receive: '接收',
            headTitleEdit: '质量调度单编辑',
            headTitleDetail: '质量调度单详情',
            hedaTitleAdd: '质量调度单新增',
            headTitleSubTitle: '通知单明细',
            selectItem: '选择物资',
            copyTitle: '确定复制该通知单',
          },
          tip: '请选择编制中的数据',
          tipRequired: '请填写必填信息',
          columns: {
            dispatchCode: '调度编号',
            startSendTimeQuery: '发送时间开始',
            endSendTimeQuery: '发送时间结束',
            startAssertTimeQuery: '生效时间开始',
            endAssertTimeQuery: '生效时间结束',
            dispatchStatus: '状态',
            sendTime: '发送时间',
            assertTime: '生效时间',
            sendName: '发送人',
            reviceDept: '接收部门',
            reviceName: '接收人',
            confirmTime: '确认时间',
            Automatically: '保存后自动生成',
            SchedulingContent: '调度内容'
          },
          discharge: {
            extItemName: '物资名称',
            dischargePosition: '卸料位置',
            controlExplain: '控制说明'
          },
          factoryMixRatio: {
            headTitle: '产品指标控制',
            itemName: '产品',
            outboundProportion: '出库比例',
            controlInstruct: '控制说明',
          },
          incomingMaterials: {
            supplierName: '供应商',
            timeStr: '进厂时间',
            batchCode: '批次号',
            totalWeight: '进厂量'
          },
          MaterialRatio: {
            chargeRatio:'投料配比',
            itemName: '原料',
            measuringScale:'计量秤',
            feedingProportion: '投料比例',
            feedingInstruct: '投料说明'
          },
          qualityControl: {
            productIndex: '产品指标控制',
            indexName: '指标名称',
            indexCode: '指标编码',
            teamName: '质检组',
            indexClass: '指标分类',
            conditionValue: '条件',
            targetValue: '目标值',
            floatingRange: '浮动范围',
            controlInstruct: '控制说明',
            deviceCode: '设备编号',
            itemCode: '生产物资',
            storageLocation: '入库位置'
          }
        },
        param: {
          field: {
            indexParamCode: "指标参数编码",
            indexParamName: "指标参数名称",
            indexDataType: "数据类型",
            indexDecimalDigit: "小数位数",
            unitCode: "单位编码",
            unitName: "单位名称",
            isEnable: "是否启用",
            remark: "备注",
          },
          title: {
            indexHeadTitle: "指标参数",
            title: "指标公式",
            addHeadTitle: "指标参数新增",
            editHeadTitle: "指标参数编辑",
            viewHeadTitle: "指标参数查看",
          }
        },
        indexFormula: {
          field: {
            formulaCode: "公式编码",
            formulaName: "公式名称",
            formulaDesc: "公式内容",
            remark: "备注",
            paramCode: "参数编码",
            paramName: "参数名称",
            paramDefaultVal: "默认值",
            formulaParam: "公式参数",
            add: "新增",
            indexCode: "指标编码",
            indexName: "指标名称",
            indexFormulaCode: "公式编码",
            indexFormulaName: "公式名称",
            indexFormulaDesc: "公式内容",
            indexFormulaButton: "公式配置",
          },
          title: {
            indexHeadTitle: "指标公式",
            addHeadTitle: "指标公式新增",
            editHeadTitle: "指标公式编辑",
            viewHeadTitle: "指标公式查看",
          }
        },
        templateIndex: {
          field: {
            templateCode: "模版编码",
            templateName: "模版名称",
            teamCode: "分组编码",
            teamName: "分组名称",
            indexCode: "指标编码",
            indexName: "指标名称",
            unitCode: "单位编码",
            unitName: "单位名称",
            limitRange: "合格范围",
            sortNum: "排序编号",
            indexFormulaCode: "公式编码",
            indexFormulaName: "公式名称",
            indexFormulaDesc: "公式内容",
            remark: "备注",
            select_warning: "请先保存行信息",
            afterTip: {
              defaultFormulaExist: "模板指标下已有默认的公式",
            }
          },
          title: {
            indexHeadTitle: "质检模版指标",
            addHeadTitle: "质检模版指标新增",
            editHeadTitle: "质检模版指标编辑",
            viewHeadTitle: "质检模版指标查看",
          }
        },
        templateIndexFormula: {
          field: {
            title: "模板公式",
            templateCode: "模板编码",
            indexCode: "指标编码",
            indexName: "指标名称",
            indexFormulaCode: "公式编码",
            indexFormulaName: "公式名称",
            indexFormulaDesc: "公式内容",
            isDefaultFormula: "是否默认公式",
            quote: "公式引用",
            remark: "备注",
            select_warning: "请先选择公式"
          },
          title: {
            indexHeadTitle: "质检指标",
            addHeadTitle: "质检指标新增",
            editHeadTitle: "质检指标编辑",
            viewHeadTitle: "质检指标查看",
          },
        },
        templateOrigIndex: {
          field: {
            templateCode: "模版编码",
            templateName: "模版名称",
            indexCode: "指标编码",
            indexName: "指标名称",
            indexFormulaCode: "公式编码",
            indexFormulaName: "公式名称",
            paramCode: "参数编码",
            paramName: "参数名称",
            remark: "备注",

            select_tree_blank: "请先选择公式",
          },
          title: {
            indexHeadTitle: "模板参数",
            addHeadTitle: "模板参数新增",
            editHeadTitle: "模板参数编辑",
            viewHeadTitle: "模板参数查看",
          },
        },
        templatePoint: {
          field: {
            templateCode: "模版编码",
            templateName: "模版名称",
            pointCode: "取样点编码",
            pointName: "取样点名称",
            itemCode: "物料编码",
            itemName: "物料名称",
            codeRuleCode: "编码规则编码",
            codeRuleName: "编码规则名称",
            batchRuleCode: "批次规则编码",
            batchRuleName: "批次规则名称",
            remark: "备注",
          },
          title: {
            indexHeadTitle: "质检模版取样点映射",
            propTitle: "取样点",
          }
        },
        index: {
          field: {
            indexCode: "指标编码",
            indexName: "指标名称",
            indexColumnCode: '指标编码',
            unitCode: "单位编码",
            unitName: "单位名称",
            teamCode: "质检组编码",
            teamName: "质检组名称",
            enableFormula: "启用公式",
            isEnable: "是否启用",
            remark: "备注",
          },
          title: {
            headTitle: "项目信息",
            indexHeadTitle: "质检指标",
            addHeadTitle: "质检指标新增",
            editHeadTitle: "质检指标编辑",
            viewHeadTitle: "质检指标查看",
          }
        },
        origIndex: {
          field: {
            indexCode: "指标编码",
            indexName: "指标名称",
            indexFormulaCode: "公式编码",
            indexFormulaName: "公式名称",
            paramCode: "参数编码",
            paramName: "参数名称",
            origDataType: "数据类型",
            remark: "备注",
          },
          title: {
            indexHeadTitle: "模板参数",
            addHeadTitle: "模板参数新增",
            editHeadTitle: "模板参数编辑",
            viewHeadTitle: "模板参数查看",
          }
        },
        qmTeamMember: {
          field: {
            teamCode: "分组编码",
            teamName: "分组名称",
            userCode: "成员编号",
            userName: "成员姓名",
            isGroupLeader: "是否组长",
            message: "不能重复选择",
          },
          title: {
            indexHeadTitle: "质检组",
            headTitle: "质检人员列表",
            teamIndexTitle: "质检指标",
            teamMemberTitle: "质检人员",
          }
        },
        qmTeam: {
          field: {
            teamCode: "分组编码",
            teamName: "分组名称",
            parentName: "父类编码",
            isEnable: "是否启用",
            remark: "备注",
            team: "请先选择分组",
            tip_determine_del: "该质检组存在关联信息，确定删除该质检组及关联信息？",
          },
          title: {
            indexHeadTitle: "质检组",
            addHeadTitle: "质检组新增",
            editHeadTitle: "质检组编辑",
            viewHeadTitle: "质检组查看",
            teamMemberTitle: "质检人员",
          }
        },
        qmTeamIndex: {
          field: {
            teamCode: "分组编码",
            teamName: "分组名称",
            indexCode: "指标编码",
            indexName: "指标名称",
            unitCode: "单位编码",
            unitName: "单位名称",
          },
          title: {
            indexHeadTitle: "质检指标列表",
            teamIndexTitle: "质检指标",
          }
        },
        point: {
          field: {
            labelText: "新增取样点分类",
            parentCode: "父类别编号",
            parentName: "父类别名称",
            pointCode: "取样点编码",
            pointName: "取样点名称",
            pointCatCode: "取样点分类编码",
            pointCatName: "取样点分类名称",
            isEnable: "是否启用",
            remark: "备注",
            equipCode: "设备编码",
            equipName: "设备名称",
            select_warning: "请先保存基础信息",
          },
          title: {
            indexHeadTitle: "取样点",
            pointClass: "取样点分类",
            addHeadTitle: "取样点新增",
            editHeadTitle: "取样点编辑",
            viewHeadTitle: "取样点查看",
          },
        },
        recordObj: {
          field: {
            recordObjCode: "质检对象编码",
            recordObjName: "质检对象名称",
            qualityType: "质检类型",
            itemName: "生产物料",
            templateName: "质检模板",
            pointName: "取样点",
            pointCatName: "取样点分类",
            add: "新增",
            sampleCodeRule: "样品编码规则",
            recordCodeRule: "质检编码规则",
            batchRule: "批次规则",
            targetType: "供应商/客户",
            packageFlag: "包装方式",
            batchSplitName: "切批规则",
            batchCodeRule: "批次编码规则",
            objIndex: "质检指标",
            standardType: "质检标准",
            formulaName: "计算公式",
            isMajorIndex: "关键指标",
            objIndexConfig: "质检指标配置",
            splitSidesType: "切批方式",
            color: "颜色",
            copyRecordObj: '复制质检对象指标',
            isObjYesNo: "是否需要质检",
          },
          title: {
            indexDispatch: '指标调度',
            headTitle: "生产物料",
            indexHeadTitle: "质检对象",
            addHeadTitle: "质检对象新增",
            editHeadTitle: "质检对象编辑",
            viewHeadTitle: "质检对象查看",
          },
          msg: {
            conflict: "切批方式不可重复!",
          }
        },
        itemIndex: {
          field: {
            recordCode: "质检对象编码",
            item: "物料",
            itemCode: "物料编码",
            itemName: "物料名称",
            indexCode: "指标编码",
            indexName: "指标名称",
            teamCode: "控制组",
            teamName: "质检组",
            unitName: "单位",
            isVisiable: "是否显示",
            itemIndexFormula: "指标公式",
            itemIndexStandard: "指标标准",
            isDefault: "是否默认",
            standardType: "标准类型",
            standardContent: "合格范围",
            indexClass: "指标分类",
            standardTypeOnly: "标准类型重复",
            sortNum: '排序',
            warnCfg: "预警配置",
          },
          title: {
            indexHeadTitle: "物料指标信息",
          },
        },
        warnCfg: {
          field: {
            consecutiveNum: '连续不合格次数',
            upperLimit: "预警上限值",
            lowerLimit: "预警下限值",
            isLimit: "启用超限预警",
            qualifiedRange: '合格范围',
            warningScope: '预警范围',
            isConsecutive: "是否连续不合格预警",
            isDefault: "是否默认",
          },
        },
        batchSplit: {
          field: {
            baseInfo: "基础信息",
            batchSplitCode: "切批规则编码",
            batchSplitName: "切批规则名称",
            isQualityCfg: "采样规则设置",
          },
          title: {
            indexHeadTitle: "切批规则设置",
            addHeadTitle: "切批规则设置新增",
            editHeadTitle: "切批规则设置编辑",
            viewHeadTitle: "切批规则设置查看",
          }
        },
        batchSplitParam: {
          field: {
            modeType: "切批方式",
            modeType2: "采样方式",
            param: "参数",
            errorModeType: "周期（班组小时），每XX小时切一批不能大于24",
            tip: {
              notNull: "不能为空!",
              nonRepeatable: "不能重复!"
            }
          },
          title: {
            title: "切批规则",
            title2: "采样规则",
          }
        },
        batch: {
          field: {
            baseInfo: "基础信息",
            batchCode: "批次编码",
            qualityType: "质检类型",
            customerCode: "供应商/客户编码",
            customerName: "供应商/客户名称",
            miningName: "矿点名称",
            itemCode: "物料编码",
            itemName: "物料名称",
            extItemCode: "erp物料编码",
            extItemName: "erp物料名称",
            sumWeight: "累计重量",
            sumNum: "车/船数",
            sumSampleNum: "采样数量",
            firstAcceptDate: "首次接收时间",
            lastAcceptDate: "最后接收时间",
            remark: "备注",
            status: "状态",
          },
          title: {
            indexHeadTitle: "批次信息",
            addHeadTitle: "批次信息新增",
            editHeadTitle: "批次信息编辑",
            viewHeadTitle: "批次信息查看",
            oldBatchCode: "当前批次编码",
            newBatchCode: "新批次编码",
            newBatchCodeBlank: "新批次编码不能为空",
            editBatchCode: "编辑批次编码"
          }
        },
        sampleLine: {
          field: {
            bzCode: "一卡通编码",
            batchCode: "批次编号",
            sampleCode: "样品编码",
            sampleBatchCode: "样品批次",
            carNo: "车/船号",
            extItemName: "ERP物料名称",
            extItemCode: "ERP物料编码",
          },
          title: {
            indexHeadTitle: "一卡通信息",
          }
        },
        sample: {
          field: {
            processingTime: "处理时间",
            deblockingDate: "到期时间",
            sealStatus: '封存状态',
            sealDate: '封存时间',
            itemCodeAndName: "物料编码/名称",
            pointCodeAndName: "取样点编码/名称",
            sampleCode: "样品编码",
            qualityType: "质检类型",
            batchDtlCode: "批次明细编号",
            inspectionType: "检测类型",
            recordObjCode: "质检对象编码",
            status: "状态",
            sampleDate: "取样日期",
            userName: "取样人名称",
            sampleType: "样品类型",
            pointCode: "取样点编码",
            pointName: "取样点",
            equipCode: "设备编号",
            equipName: "设备名称",
            itemCode: "物料编码",
            isRecord: "是否生成质检记录",
            itemName: "物料名称",
            remark: "备注",
            bzCode: "一卡通编码",
            batchCode: "批次编号",
            carNo: "车/船号",
            extItemCode: "ERP物料编码",
            extItemName: "ERP物料名称",
            customerCode: "客商编码",
            customerName: "客商名称",
            recordObj: "质检对象",
            projectName: "项目名称",
            periodicSpline: "周期样",
            picture: "图片",
            proportionScheme: "配比方案",
            materialCode: "原料编码",
            rawMaterial: "原料名称",
            ratio: "比例",
            pictureType: "图片类型",
            literalness: "文字说明",
            pictureUpload: "图片上传",
            sumWeight: '累计重量',
            inputType: "操作状态",
            sampleAddress:"样品地址",
            sampleName:"样品名称",
            afterTip: {
              rawMaterial: "请选择原料",
              ratio: "请选择比例",
              sample: "请先保存样品信息",
              pictureUpload: "请选择图片上传",
              literalness: "请输入文字说明",
              pictureType: "请选择图片类型",
              qualityTypeExist: "请先选择质检类型",
              qmRecordObjUnExist: "质检对象不存在",
              qmRecordObjIndexUnExist: "质检指标不存在",
              qmRecordObjFormulaUnExist: "质检指标公式不存在",
              qmRecordObjStandardUnExist: "指标标准不存在",
              isNotPeriodType: "当前质检对象不是周期样",
              dataHasExist: "质检记录已经生成",
              mdItemUnExist: "生产物料不存在",
            }
          },
          title: {
            save: "请先保存信息",
            selectBzCode: "请选择一卡通信息",
            selectRecordObj: "请选择质检类型",
            indexHeadTitle: "样品管理",
            recordHeadTitle: "质检检验报告单",
            sampleRecondTitle: "样品记录",
            labelText: "新增取样点分类",
            viewHeadTitle: "样品管理查看",
            addHeadTitle: "样品管理新增",
            editHeadTitle: "样品管理编辑",
            sampleRecondViewTitle: "样品记录查看",
            sampleRecondAddTitle: "样品记录新增",
            sampleRecondEditTitle: "样品记录编辑",
            oneCardInfoTitle: "一卡通信息",
            ProportioningScheme: "配比方案",
            issue: "此状态已经下发无法更改",
            oneCardTitle: "一卡通物料",
          },
          btn: {
            nonQualityInspectionBtn: "不质检",
            issuingQualityInspectionBtn: "下发质检",
            reportedBtn: "填报",
            filledBtn: "审批",
            auditedBtn: "打印",
          },
          msg: {
            batchCodeOne: "批次号需要保持一致",
            customerName:"客商名称需要保持一致",
            erpCodeOne:"erp物料需要保持一致",
            nonDraft: "包含非草稿状态的样品记录，不可删除",
          },
        },
        record: {
          field: {
            device: "质检设备",
            time: "日期",
            recordDate: "质检日期",
            recordStartDate: "质检开始日期",
            recordEndDate: "质检结束日期",
            equipCode: "设备编码",
            equipName: "设备名称",
            batchCode: "批次编号",
            status: "状态",
            operate: "操作",
            recordCode: "质检记录编码",
            sampleCode: "样品编码",
            templateCode: "模板编码",
            sampleBatchCode: "样品批次",
            sampleType: "样品类型",
            samplePointCode: "取样点编码",
            samplePointCatName: "取样点名称",
            itemCode: "物料编码",
            itemName: "物料名称",
            pointName: "取样点",
            qualityType: "质检类型",
            recordObjCode: "对象",
            teamName: "组别",
            remark: "备注",
            teamCode: "组别",
            toBeInspected: "待检验",
            inProgress: "进行中",
            submitted: "已提交",
            recordIndex: '质检指标',
            picture: '质检图片',
            recordStartHour: "汇总开始小时",
            recordStartYear: "汇总开始时间",
            recordEndYear: "汇总结束时间",
            recordEndHour: "汇总结束小时",
            recordEndAndrecordStart: "汇总开始时间或者汇总结束时间不能为空",
            recordEndAndrecordDate: "汇总开始时间或者汇总结束时间不能比当前质检对象时间大",
            collect: "汇总",
            sampleDate: "范围",
            CollectRecordCode: "质检编码",
            notCollect: "暂无汇总",
            tip: {
              noUpdate: "未修改质检指标信息!",
            }
          },
          btn: {
            autoCollect: '自动汇总'
          },
          title: {
            seal: "封存",
            handle: "处理",
            indexHeadTitle: "质检记录",
            addHeadTitle: "质检记录新增",
            editHeadTitle: "质检记录编辑",
            viewHeadTitle: "质检记录查看",
          },
          msg: {
            prompt: "请点击左侧树选择具体分类",
          }
        },
        stockCheckLine: {
          field: {
            locationName: "库位名称",
            formulaText: "计算公式",
            stockCheckQty: "盘点库存",
            cal: "计算",
            index: "序号",
            code: "参数编号",
            name: "参数名称",
            defVal: "默认值",
            value: "参数值",
            paramCode: "参数编码",
            paramName: "指标名称",
            paramValue: "指标值",
          }
        },
        // 周期质检
        periodQualityTest: {
          field: {
            teamCode: "质检组编码",
            teamName: "质检组名称",
          },
        },
        recordIndex: {
          field: {
            sampleCode: "样品编码",
            indexCode: "指标编码",
            indexName: "指标名称",
            indexValue: "指标值",
            standardType: "质量标准",
            standardContent: "标准范围",
            temIndexStandard: "指标标准",
            indexAdjValue: "指标调整值",
            limitRange: "合格范围",
            isQualified: "结论",
            select_warning: "请先保存行信息",
            indexFormulaDesc: "计算公式",
            indexFormulaName: "公式名称",
            indexValueCal: "计算结果",
            userName: "质检人",
            updateTime: "操作时间",
          },
          title: {
            infoTitle: "质检指标信息",
            listTitle: "质检指标列表",
          }
        },
        device: {
          file: {
            deviceName: "设备名称",
            deviceCode: "设备编码",
            deviceInfo: "备注"
          },
          title: {
            deviceTitle: "质检设备",
            add: "质检设备新增",
            edit: "质检设备编辑"
          }
        },
        recordV2: {
          tip: {
            del_success: "删除成功",
            back_success: "退回成功",
            submit_success: "提交成功",
            save_success: "保存成功",
            no_update: "质检记录未做修改!",
          },
          confirm: {
            del: "是否删除质检记录?",
            back: "是否退回质检记录?",
            submit: "是否提交质检记录?",
          },
          afterTip: {
            sampleCodeRuleNoSelect: "该质检记录对应的质检对象未选择样品编号规则 !",
          }
        },
        stockQyt: {
          field: {
            headTitle: "库存现有量",
            headListTitle: "库存现有量列表",
            itemCode: "物料编码",
            itemName: "物料名称",
            unitCode: "单位编码",
            unitName: "单位名称",
            warehouseCode: "仓库编码",
            warehouseName: "仓库名称",
            stockQty: "库存数量",
          },
          title: {
            title: "仓库信息",
            headTitle: "质检设备",
            headListTitle: "质检设备新增",
          }
        },
        template: {
          field: {
            templateCode: "模版编码",
            templateName: "模版名称",
            itemCode: "物料编码",
            itemName: "物料名称",
            isEnable: "是否启用",
            remark: "备注",
            isShi: "已启用",
            isFou: "已禁用"
          },
          title: {
            indexHeadTitle: "质检模版",
          }
        },
        arrivalLine: {
          field: {
            itemCode: "物料编码",
            itemName: "物料名称",
            norms: "规格",
            model: "型号",
            chartNo: "图号",
            unit: "单位",
            deliveryQuantity: "发货数量",
            arrivalQuantity: "到货数量",
            batchNo: "批次号",
            arrivalDate: "计划到货日期",
            stockOrgName: "到货库存组织名称",
            warehouseName: "到货仓库名称",
            locationName: "库位名称",
            poundNo: "磅单号",
            tareWeight: "皮重",
            grossWeight: "毛重",
            originPlace: "产地",
            carNo: "车牌号",
          },
        },
      }
    }
  },
}
