var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "0 15px" }, on: { click: _vm.toggleClick } },
    [
      _c("i", {
        staticClass: "hamburger",
        class: _vm.isActive ? "el-icon-s-unfold" : "el-icon-s-fold",
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }